export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_USER_2FA = "LOGIN_USER_2FA";
export const LOGIN_USER_2FA_SUCCESS = "LOGIN_USER_2FA_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const API_ERROR = "LOGIN_API_ERROR";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";

export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
