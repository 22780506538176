import { SET_LOGGED_IN_USER, CLEAR_USER } from "./actionTypes";

export const setLoggedInUser = (user, history) => {
  return {
    type: SET_LOGGED_IN_USER,
    payload: { user, history },
  };
};

export const clearUser = (user) => {
  return {
    type: CLEAR_USER,
    payload: user,
  };
};
