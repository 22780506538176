import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ClientData from "../../components/clients/ClientData";
import ClientFigureTab from "../../components/clients/ClientFigureTab";

interface Props {}

const TuentiEcuador = (props: Props) => {
  return (
    <Container fluid>
      <Row>
        {[...Array(4)].map((_, index) => (
          <Col lg={3} key={index}>
            <ClientFigureTab figureName="User Base" figureValue={4321} />
          </Col>
        ))}
      </Row>

      <Row>
        <Col lg={6}>
          <ClientData clientID="Tuenti Ecuador" />
        </Col>
      </Row>
    </Container>
  );
};

export default TuentiEcuador;
