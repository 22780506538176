import React from "react";
import { Container, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";

import { BiMenu, BiMenuAltRight, BiMenuAltLeft } from "react-icons/bi";

interface Props {}

const UiButtons = (props: Props) => {
  const [drp_link, setdrp_link] = useState(false);

  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Default buttons</Card.Title>
              <p className="card-title-desc">
                Bootstrap includes six predefined button styles, each serving
                its own semantic purpose.
              </p>
              <div className="d-flex flex-wrap gap-2">
                <Button variant="primary" className="btn btn-primary ">
                  Primary
                </Button>
                <Button variant="secondary" className="btn btn-secondary ">
                  Secondary
                </Button>
                <Button variant="success" className="btn btn-success ">
                  Success
                </Button>
                <Button variant="info" className="btn btn-info ">
                  Info
                </Button>
                <Button variant="warning" className="btn btn-warning ">
                  Warning
                </Button>
                <Button variant="danger" className="btn btn-danger ">
                  Danger
                </Button>
                <Button variant="dark" className="btn btn-dark ">
                  Dark
                </Button>
                <Button variant="link" className="btn btn-link ">
                  Link
                </Button>
                <Button variant="light" className="btn btn-light ">
                  Light
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title> buttons</Card.Title>
              <p className="mb-3 card-title-desc">
                Replace the default modifier classes with the{" "}
                <code className="highlighter-rouge">.btn--*</code> ones to
                remove all background images and colors on any button.
              </p>
              <div className="d-flex flex-wrap gap-2">
                <Button variant="outline-primary">Primary</Button>
                <Button variant="outline-secondary">Secondary</Button>
                <Button variant="outline-success">Success</Button>
                <Button variant="outline-info">Info</Button>
                <Button variant="outline-warning">Warning</Button>
                <Button variant="outline-danger">Danger</Button>
                <Button variant="outline-dark">Dark</Button>
                <Button variant="outline-light">Light</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Rounded buttons</Card.Title>
              <p className="card-title-desc">
                Use class <code>.btn-rounded</code> for button round border.
              </p>
              <div className="d-flex flex-wrap gap-2">
                <Button variant="primary" className="btn-rounded ">
                  Primary
                </Button>
                <Button variant="secondary" className="btn-rounded ">
                  Secondary
                </Button>
                <Button variant="success" className="btn-rounded ">
                  Success
                </Button>
                <Button variant="info" className="btn-rounded ">
                  Info
                </Button>
                <Button variant="warning" className="btn-rounded ">
                  Warning
                </Button>
                <Button variant="danger" className="btn-rounded ">
                  Danger
                </Button>
                <Button variant="dark" className="btn-rounded ">
                  Dark
                </Button>
                <Button variant="link" className="btn-rounded ">
                  Link
                </Button>
                <Button variant="light" className="btn-rounded ">
                  Light
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Buttons with icon</Card.Title>
              <p className="card-title-desc">Add icon in button.</p>

              <div className="d-flex flex-wrap gap-2">
                <button type="button" className="btn btn-primary ">
                  <i className="bx bx-smile font-size-16 align-middle me-2"></i>{" "}
                  Primary
                </button>
                <button type="button" className="btn btn-success ">
                  <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
                  Success
                </button>
                <button type="button" className="btn btn-warning ">
                  <i className="bx bx-error font-size-16 align-middle me-2"></i>{" "}
                  Warning
                </button>
                <button type="button" className="btn btn-danger ">
                  <i className="bx bx-block font-size-16 align-middle me-2"></i>{" "}
                  Danger
                </button>
                <button type="button" className="btn btn-dark ">
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                  Loading
                </button>
                <button type="button" className="btn btn-light ">
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                  Loading
                </button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Card.Title>Snip Buttons</Card.Title>

              <Row>
                <Col lg={4}>
                  <div className="mt-4">
                    <h5 className="font-size-15 mb-3">Example 1</h5>

                    <div>
                      <div
                        className="btn-group btn-group-example mb-3"
                        role="group"
                      >
                        <Button variant="outline-secondary" type="button">
                          Left
                        </Button>
                        <Button variant="outline-secondary" type="button">
                          Middle
                        </Button>
                        <Button variant="outline-secondary" type="button">
                          Right
                        </Button>
                      </div>

                      <div>
                        <div
                          className="btn-group btn-group-example mb-3"
                          role="group"
                        >
                          <button
                            type="button"
                            className="btn btn-primary w-xs"
                          >
                            <i className="mdi mdi-thumb-up"></i>
                          </button>
                          <button type="button" className="btn btn-danger w-xs">
                            <i className="mdi mdi-thumb-down"></i>
                          </button>
                        </div>
                      </div>

                      <div>
                        <div
                          className="btn-group btn-group-example"
                          role="group"
                        >
                          <button
                            type="button"
                            className="btn btn--secondary w-xs"
                          >
                            <i className="bx bx-menu-alt-right"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn--secondary w-xs"
                          >
                            <i className="bx bx-menu"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn--secondary w-xs"
                          >
                            <i className="bx bx-menu-alt-left"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <div className="col-lg-4">
                  <div className="mt-4">
                    <h5 className="font-size-15 mb-3">Example 2</h5>

                    <div className="d-flex flex-wrap gap-2">
                      <button
                        type="button"
                        className="btn btn-primary  btn-label"
                      >
                        <i className="bx bx-smile label-icon"></i> Primary
                      </button>
                      <button
                        type="button"
                        className="btn btn-success  btn-label"
                      >
                        <i className="bx bx-check-double label-icon"></i>{" "}
                        Success
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning  btn-label"
                      >
                        <i className="bx bx-error label-icon "></i> Warning
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger  btn-label"
                      >
                        <i className="bx bx-block label-icon "></i> Danger
                      </button>
                      <button type="button" className="btn btn-dark  btn-label">
                        <i className="bx bx-loader label-icon "></i> Dark
                      </button>
                      <button
                        type="button"
                        className="btn btn-light  btn-label"
                      >
                        <i className="bx bx-hourglass label-icon "></i> Light
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="mt-4">
                    <h5 className="font-size-15 mb-3">Example 3</h5>

                    <div className="d-flex flex-wrap gap-2">
                      <button type="button" className="btn btn-primary  w-sm">
                        <i className="mdi mdi-download d-block font-size-16"></i>{" "}
                        Download
                      </button>
                      <button type="button" className="btn btn-light  w-sm">
                        <i className="mdi mdi-upload d-block font-size-16"></i>{" "}
                        Upload
                      </button>
                      <button type="button" className="btn btn-success  w-sm">
                        <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}
                        Edit
                      </button>
                      <button type="button" className="btn btn-danger  w-sm">
                        <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Buttons Sizes</Card.Title>
              <p className="mb-3 card-title-desc">
                Add <code>.btn-lg</code> or <code>.btn-sm</code> for additional
                sizes.
              </p>

              <div className="d-flex flex-wrap gap-2 align-items-center">
                <Button variant="primary" className="btn btn-lg ">
                  Large button
                </Button>
                <Button variant="secondary" className="btn btn-lg ">
                  Large button
                </Button>
                <Button variant="primary" className="btn-sm">
                  Small button
                </Button>
                <Button variant="secondary" className=" btn-sm ">
                  Small button
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Buttons width</Card.Title>
              <p className="mb-3 card-title-desc">
                Add <code>.w-xs</code>, <code>.w-sm</code>, <code>.w-md</code>{" "}
                and <code> .w-lg</code> className for additional buttons width.
              </p>

              <div className="d-flex flex-wrap gap-2">
                <button type="button" className="btn btn-primary w-xs ">
                  Xs
                </button>
                <button type="button" className="btn btn-danger w-sm ">
                  Small
                </button>
                <button type="button" className="btn btn-warning w-md ">
                  Medium
                </button>
                <button type="button" className="btn btn-success w-lg ">
                  Large
                </button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Button tags</Card.Title>
              <p className="mb-3 card-title-desc">
                The <code className="highlighter-rouge">.btn</code>
                classes are designed to be used with the{" "}
                <code className="highlighter-rouge">&lt;button&gt;</code>{" "}
                element. However, you can also use these classes on{" "}
                <code className="highlighter-rouge">&lt;Link&gt;</code> or{" "}
                <code className="highlighter-rouge">&lt;input&gt;</code>{" "}
                elements (though some browsers may apply a slightly different
                rendering).
              </p>

              <div className="d-flex flex-wrap gap-2">
                <Link className="btn btn-primary " to="#" role="button">
                  Link
                </Link>
                <Button
                  variant="success"
                  className="btn btn-success "
                  type="submit"
                >
                  Button
                </Button>
                <input className="btn btn-info" type="button" value="Input" />
                <input
                  className="btn btn-danger"
                  type="submit"
                  value="Submit"
                />
                <input className="btn btn-warning" type="reset" value="Reset" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Toggle states</Card.Title>
              <p className="mb-3 card-title-desc">
                Add{" "}
                <code className="highlighter-rouge">
                  data-toggle=&quot;button&ldquo;
                </code>
                to toggle a button’s{" "}
                <code className="highlighter-rouge">active</code>
                state. If you’re pre-toggling a button, you must manually add
                the <code className="highlighter-rouge">.active</code> class
                <strong>and</strong>{" "}
                <code className="highlighter-rouge">
                  aria-pressed=&quot;true&ldquo;
                </code>{" "}
                to the
                <code className="highlighter-rouge">&lt;button&gt;</code>.
              </p>

              <div className="d-flex flex-wrap gap-2">
                <Button
                  variant="primary"
                  className="btn btn-primary "
                  data-toggle="button"
                  aria-pressed="false"
                >
                  Single toggle
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Block Buttons</Card.Title>
              <p className="mb-3 card-title-desc">
                Create block level buttons—those that span the full width of a
                parent—by adding{" "}
                <code className="highlighter-rouge">.btn-block</code>.
              </p>

              <div className="d-grid gap-2">
                <Button variant="primary" type="button" className="btn-lg">
                  Block level button
                </Button>
                <Button variant="secondary" type="button" className="btn-sm">
                  Block level button
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Checkbox & Radio Buttons</Card.Title>
              <p className="mb-3 card-title-desc">
                Bootstrap’s <code className="highlighter-rouge">.button</code>{" "}
                styles can be applied to other elements, such as{" "}
                <code className="highlighter-rouge">&lt;label&gt;</code>s, to
                provide checkbox or radio style button toggling. Add{" "}
                <code className="highlighter-rouge">
                  data-toggle=&quot;buttons&ldquo;
                </code>{" "}
                to a<code className="highlighter-rouge">.btn-group</code>{" "}
                containing those modified buttons to enable toggling in their
                respective styles.
              </p>

              <div className="d-flex flex-wrap gap-2">
                <div>
                  <div className="d-flex flex-wrap gap-3">
                    <div className="btn-group" role="group">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id="btncheck1"
                        autoComplete="off"
                        defaultChecked
                      />
                      <label className="btn btn-primary" htmlFor="btncheck1">
                        Checkbox 1
                      </label>

                      <input
                        type="checkbox"
                        className="btn-check"
                        id="btncheck2"
                        autoComplete="off"
                      />
                      <label className="btn btn-primary" htmlFor="btncheck2">
                        Checkbox 2
                      </label>

                      <input
                        type="checkbox"
                        className="btn-check"
                        id="btncheck3"
                        autoComplete="off"
                      />
                      <label className="btn btn-primary" htmlFor="btncheck3">
                        Checkbox 3
                      </label>
                    </div>

                    <div className="btn-group" role="group">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id="btncheck4"
                        autoComplete="off"
                        defaultChecked
                      />
                      <label className="btn btn--primary" htmlFor="btncheck4">
                        Checkbox 4
                      </label>

                      <input
                        type="checkbox"
                        className="btn-check"
                        id="btncheck5"
                        autoComplete="off"
                      />
                      <label className="btn btn--primary" htmlFor="btncheck5">
                        Checkbox 5
                      </label>

                      <input
                        type="checkbox"
                        className="btn-check"
                        id="btncheck6"
                        autoComplete="off"
                      />
                      <label className="btn btn--primary" htmlFor="btncheck6">
                        Checkbox 6
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex flex-wrap gap-3">
                    <div className="btn-group" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        defaultChecked
                      />
                      <label className="btn btn-secondary" htmlFor="btnradio1">
                        Radio 1
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                      />
                      <label className="btn btn-secondary" htmlFor="btnradio2">
                        Radio 2
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        autoComplete="off"
                      />
                      <label className="btn btn-secondary" htmlFor="btnradio3">
                        Radio 3
                      </label>
                    </div>

                    <div className="btn-group" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio4"
                        autoComplete="off"
                        defaultChecked
                      />
                      <label className="btn btn--secondary" htmlFor="btnradio4">
                        Radio 4
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio5"
                        autoComplete="off"
                      />
                      <label className="btn btn--secondary" htmlFor="btnradio5">
                        Radio 5
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio6"
                        autoComplete="off"
                      />
                      <label className="btn btn--secondary" htmlFor="btnradio6">
                        Radio 6
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Button group</Card.Title>
              <p className="mb-3 card-title-desc">
                Wrap a series of buttons with{" "}
                <code className="highlighter-rouge">.btn</code> in{" "}
                <code className="highlighter-rouge">.btn-group</code>.
              </p>

              <Row>
                <Col md={6}>
                  <div className="d-flex flex-wrap gap-2">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <Button variant="primary">Left</Button>
                      <Button variant="primary">Middle</Button>
                      <Button variant="primary">Right</Button>
                    </div>

                    <div className="btn-group">
                      <Button variant="outline-primary" active>
                        Left
                      </Button>
                      <Button variant="outline-primary">Middle</Button>
                      <Button variant="outline-primary">Right</Button>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap gap-3 mt-4 mt-md-0">
                    <div className="btn-group">
                      <Button variant="secondary" type="button">
                        <BiMenuAltRight />
                      </Button>
                      <Button variant="secondary" type="button">
                        <BiMenu />
                      </Button>
                      <Button variant="secondary" type="button">
                        <BiMenuAltLeft />
                      </Button>
                    </div>

                    <div className="btn-group">
                      <Button variant="outline-secondary" type="button">
                        <BiMenuAltRight />
                      </Button>
                      <Button variant="outline-secondary" type="button">
                        <BiMenu />
                      </Button>
                      <Button variant="outline-secondary" type="button">
                        <BiMenuAltLeft />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Button toolbar</Card.Title>
              <p className="mb-3 card-title-desc">
                Combine sets of button groups into button toolbars for more
                complex components.Use utility className as needed to space out
                groups, buttons, and more.
              </p>
              <div className="d-flex flex-wrap gap-4">
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group me-2"
                    role="group"
                    aria-label="First group"
                  >
                    <Button variant="secondary" className="btn btn-secondary">
                      1
                    </Button>
                    <Button variant="secondary" className="btn btn-secondary">
                      2
                    </Button>
                    <Button variant="secondary" className="btn btn-secondary">
                      3
                    </Button>
                    <Button variant="secondary" className="btn btn-secondary">
                      4
                    </Button>
                  </div>
                  <div
                    className="btn-group me-2"
                    role="group"
                    aria-label="Second group"
                  >
                    <Button variant="secondary" className="btn btn-secondary">
                      5
                    </Button>
                    <Button variant="secondary" className="btn btn-secondary">
                      6
                    </Button>
                    <Button variant="secondary" className="btn btn-secondary">
                      7
                    </Button>
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Third group"
                  >
                    <Button variant="secondary" className="btn btn-secondary">
                      8
                    </Button>
                  </div>
                </div>

                <div className="btn-toolbar">
                  <div className="btn-group me-2">
                    <Button variant="outline-secondary" type="button">
                      1
                    </Button>
                    <Button variant="outline-secondary" type="button">
                      2
                    </Button>
                    <Button variant="outline-secondary" type="button">
                      3
                    </Button>
                    <Button variant="outline-secondary" type="button">
                      4
                    </Button>
                  </div>
                  <div className="btn-group me-2">
                    <Button variant="outline-secondary" type="button">
                      5
                    </Button>
                    <Button variant="outline-secondary" type="button">
                      6
                    </Button>
                    <Button variant="outline-secondary" type="button">
                      7
                    </Button>
                  </div>
                  <div className="btn-group">
                    <Button variant="outline-secondary" type="button">
                      8
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Sizing</Card.Title>
              <p className="mb-3 card-title-desc">
                Instead of applying button sizing classes to every button in a
                group, just add{" "}
                <code className="highlighter-rouge">.btn-group-*</code> to each{" "}
                <code className="highlighter-rouge">.btn-group</code>, including
                each one when nesting multiple groups.
              </p>
              <div className="d-flex flex-wrap gap-3">
                <div className="btn-group btn-group-lg">
                  <button type="button" className="btn btn-primary">
                    Left
                  </button>
                  <button type="button" className="btn btn-primary">
                    Middle
                  </button>
                  <button type="button" className="btn btn-primary">
                    Right
                  </button>
                </div>

                <div className="btn-group btn-group-lg">
                  <Button variant="outline-secondary" type="button">
                    Left
                  </Button>
                  <Button variant="outline-secondary" type="button">
                    Middle
                  </Button>
                  <Button variant="outline-secondary" type="button">
                    Right
                  </Button>
                </div>
              </div>

              <br />

              <div className="d-flex flex-wrap gap-3">
                <div className="btn-group">
                  <button type="button" className="btn btn-secondary">
                    Left
                  </button>
                  <button type="button" className="btn btn-secondary">
                    Middle
                  </button>
                  <button type="button" className="btn btn-secondary">
                    Right
                  </button>
                </div>
                <div className="btn-group">
                  <Button variant="outline-secondary" type="button">
                    Left
                  </Button>
                  <Button variant="outline-secondary" type="button">
                    Middle
                  </Button>
                  <Button variant="outline-secondary" type="button">
                    Right
                  </Button>
                </div>
              </div>

              <br />

              <div className="d-flex flex-wrap gap-3">
                <div
                  className="btn-group btn-group-sm"
                  role="group"
                  aria-label="Basic example"
                >
                  <button type="button" className="btn btn-danger">
                    Left
                  </button>
                  <button type="button" className="btn btn-danger">
                    Middle
                  </button>
                  <button type="button" className="btn btn-danger">
                    Right
                  </button>
                </div>

                <div
                  className="btn-group btn-group-sm"
                  role="group"
                  aria-label="Basic example"
                >
                  <Button variant="outline-danger" type="button">
                    Left
                  </Button>
                  <Button variant="outline-danger" type="button">
                    Middle
                  </Button>
                  <Button variant="outline-danger" type="button">
                    Right
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Vertical variation</Card.Title>
              <p className="mb-3 card-title-desc">
                Make a set of buttons appear vertically stacked rather than
                horizontally.Split button dropdowns are not supported here.
              </p>
              <div className="d-flex flex-wrap gap-3 align-items-center">
                <div
                  className="btn-group-vertical"
                  role="group"
                  aria-label="Vertical button group"
                >
                  <Button
                    type="button"
                    variant="secondary"
                    className="btn btn-secondary"
                  >
                    Button
                  </Button>

                  <Dropdown>
                    <Dropdown.Toggle variant="secondary">
                      Dropdown <i className="mdi mdi-chevron-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Dropdown link</Dropdown.Item>
                      <Dropdown.Item>Dropdown link</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Button
                    variant="secondary"
                    type="button"
                    className="btn btn-secondary"
                  >
                    Button
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="btn btn-secondary"
                  >
                    Button
                  </Button>
                </div>

                <div className="btn-group-vertical">
                  <input
                    type="radio"
                    className="btn-check"
                    name="vbtn-radio"
                    id="vbtn-radio1"
                    autoComplete="off"
                    defaultChecked
                  />
                  <label className="btn btn--danger" htmlFor="vbtn-radio1">
                    Radio 1
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="vbtn-radio"
                    id="vbtn-radio2"
                    autoComplete="off"
                  />
                  <label className="btn btn--danger" htmlFor="vbtn-radio2">
                    Radio 2
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="vbtn-radio"
                    id="vbtn-radio3"
                    autoComplete="off"
                  />
                  <label className="btn btn--danger" htmlFor="vbtn-radio3">
                    Radio 3
                  </label>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UiButtons;
