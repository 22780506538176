import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const WelcomeUser = () => {
  const email = useSelector((state) => state.Profile.email);
  const firstname = useSelector((state) => state.Profile.firstname);
  const lastname = useSelector((state) => state.Profile.lastname);
  const photo = useSelector((state) => state.Profile.profileImage);

  const isLoggedIn = useSelector((state) => state.Login.isLoggedIn);
  return (
    <section className="welcome-user">
      <Card>
        <Card.Header>
          <h5 className="pt-4">
            <b>Welcome back !</b>
          </h5>
          <p>
            <small>Ceres Homepage</small>
          </p>
        </Card.Header>
        <Card.Body>
          <img src={photo} alt="" className="welcome-user--photo" />

          <Row>
            <Col lg={6}>
              {" "}
              <div className="welcome-user--details">
                <h5>
                  {firstname} {lastname}
                </h5>
                <p>
                  <small>DATA KRAKEN TEAM</small>
                </p>
              </div>
            </Col>

            <Col lg={6}>
              <Row>
                <Col lg={6}></Col>
              </Row>
              <Link to="profile" className="profile-link">
                View Profile
              </Link>
            </Col>
          </Row>

          {/* <p>Your Email is {email}</p>
          <p>Login status: {isLoggedIn.toString()}</p> */}
        </Card.Body>
      </Card>
    </section>
  );
};

export default WelcomeUser;
