import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  report_data: {},
  category: "accounts",
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_REPORT_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        report_data: action.payload.report_data,
      };
      break;

    case GET_REPORT_DATA_FAIL:
      state = {
        ...state,
        loading: false,
      };
      break;

    default:
      state = state;
      break;
  }
  return state;
};

export default reports;
