import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Row, Col, Card } from "react-bootstrap";
import { useTable, useSortBy, usePagination } from "react-table";

const DailyStatus = () => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    axios
      .get(`https://ceresapi.datakraken.net/api/v1/files`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("DS Call response: ", response);
        setStatus(response.data.payload);
      })

      .catch((error) => {
        console.error(error);
      });
  }, []);

  const data = React.useMemo(() => [...status], [status]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Client Name",
        accessor: "clientName", // accessor is the "key" in the data
        sortType: "basic",
      },
      {
        Header: "Process Id",
        accessor: "processId",
        sortType: "basic",
      },
      {
        Header: "Files Today",
        accessor: "filesToday",
      },
      {
        Header: "Latest Date In File",
        accessor: "latestDateInFile",
      },
      {
        Header: "Latest Modified Date",
        accessor: "latestModifiedDate",
      },
      {
        Header: "Average Time",
        accessor: "averageTime",
      },
      {
        Header: "Process Time",
        accessor: "processTime",
      },
      {
        Header: "Late",
        accessor: "late",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <section className="daily-status">
      <Card>
        {/* <Card.Header>
          {" "}
          <h2>All Processes Status</h2>
        </Card.Header> */}
        <Card.Body>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ↓"
                            : " ↑"
                          : " ↕"}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      console.log(cell);
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className:
                                cell.column.Header == "Late"
                                  ? cell.value == 1
                                    ? "bg-danger"
                                    : "bg-success"
                                  : "",
                              style: cell.column.style,
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Card.Body>
      </Card>
    </section>
  );
};

export default DailyStatus;
