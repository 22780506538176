import React, { useRef } from "react";
import { Row, Col, Card, Media } from "react-bootstrap";
import { Line, Scatter } from "react-chartjs-2";
// import { sample_data } from "./tuenti_argentina_data";

import axios from "axios";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { useState, useEffect } from "react";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

import { getReportData } from "../../store/reports/actions";
import { useDispatch } from "react-redux";

import { CustomRangesSelector } from "./DateRanges";

const TuentiArgentina = () => {
  const [displayData, setDisplayData] = useState({});
  const [category, setCategory] = useState("accounts");
  const [tuentiData, setTuentiData] = useState();
  const dispatchEvent = useDispatch();

  const data = {
    datasets: [
      {
        label: "Tuenti Argentina",
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
        pointBackgroundColor: function (ctx) {
          if (ctx.raw == null) {
            return;
          }

          if (
            ctx.raw.dayOfWeek == "Saturday" ||
            ctx.raw.dayOfWeek == "Sunday"
          ) {
            return "rgb(255,204,51)";
          } else {
            return "rgb(255, 99, 132)";
          }
        },

        data: displayData,
        spanGaps: true,
        showLine: true,
      },
    ],
  };

  const options_accounts = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: category,
    },
    animation: true,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };

  // Call inital  data
  useEffect(() => {
    console.log("use effect called TD", displayData);

    axios
      .get(
        `https://ceresapi.datakraken.net/api/v1/figures/daily/tuenti/argentina`,
        { withCredentials: true }
      )
      .then((response) => {
        setTuentiData(response.data.payload);
      })

      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -90),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (tuentiData) {
      setDisplayData(
        tuentiData.filter(
          (event) =>
            new Date(event.date) >= state[0].startDate &&
            new Date(event.date) <= state[0].endDate
        )
      );
    }
  }, [state, tuentiData]);

  return (
    <section className="reports-tuenti-argentina">
      <Row>
        {[...Array(4)].map((_, index) => (
          <Col lg={3} key={index}>
            <Card>
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <div className="pr-2">
                    <span className="avatar-title rounded-circle">
                      <AiIcons.AiFillHome />
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">User Base</h5>
                </div>
                <div className="text-muted mt-4">
                  <h4>
                    93,128
                    <i className="mdi mdi-chevron-up ms-1 text-success" />
                  </h4>
                  <div className="d-flex">
                    <span className={"badge badge-success mr-2"}>+ 0.5%</span>
                    <span className="ms-2 text-truncate">
                      From previous period
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Row className="pt-5">
        <Col lg={8}>
          <Card>
            <Card.Body>
              <h2>Category: {category}</h2>
              <h6>Start: {String(state[0].startDate)}</h6>
              <h6>End: {String(state[0].endDate)}</h6>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCategory("accounts")}
                >
                  Accounts
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCategory("smses")}
                >
                  Smses
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCategory("callsIn")}
                >
                  Calls In
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCategory("callsOut")}
                >
                  Calls Out
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCategory("dataSent")}
                >
                  Data Sent
                </button>
              </div>
              {/* Show Load data icon if no data */}
              {/* {displayData && displayData.length > 0 ? (
                <Line
                  data={data}
                  options={options_accounts}
                  key={Math.random()}
                />
              ) : (
                <div>LOADING</div>
              )} */}
              <Line
                data={data}
                options={options_accounts}
                key={Math.random()}
              />

              <DateRangePicker
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                maxDate={new Date()}
                minDate={new Date(2014, 0, 1)}
                editableDateInputs={true}
                staticRanges={CustomRangesSelector}
                inputRanges={[]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default TuentiArgentina;
