import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
} from "./actionTypes";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { getReportDataSuccess, getReportDataFail } from "./actions";

export function requestGetReportData(client) {
  console.log("client request: ", client);
  return axios.request({
    method: "get",
    url: "https://ceresapi.datakraken.net/api/v1/figures/daily/tuenti/argentina",
    withCredentials: true,
  });
}

function* handleGetReportData({ payload: { client } }) {
  try {
    const responseGetReportData = yield call(requestGetReportData, client);
    yield put(getReportDataSuccess(responseGetReportData.data.payload));
  } catch (error) {
    console.log("Something went wrong.", error);
    yield put(getReportDataFail(error));
  }
}

function* reportsSaga() {
  yield takeEvery(GET_REPORT_DATA, handleGetReportData);
}

export default reportsSaga;
