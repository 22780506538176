import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_2FA,
  LOGIN_USER_2FA_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  GOOGLE_LOGIN,
} from "./actionTypes";

export const loginUser = (bearer, history) => {
  return {
    type: LOGIN_USER,
    payload: { bearer, history },
  };
};

export const loginUserSuccess = (user) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const loginUser2FA = (history, authCode, user) => {
  return {
    type: LOGIN_USER_2FA,
    payload: { history, authCode, user },
  };
};

export const loginUser2FASuccess = (user) => {
  return {
    type: LOGIN_USER_2FA_SUCCESS,
    payload: { user },
  };
};

// TODO EXTRAS
export const googleLogin = (history) => {
  return {
    type: GOOGLE_LOGIN,
    payload: { history },
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};
