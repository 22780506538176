import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_2FA,
  LOGIN_USER_2FA_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GOOGLE_LOGIN,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  isLoggedIn: false,
  expiry: "",
};

const login = (state = initialState, action) => {
  console.log("this is the action", action);

  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        // isLoggedIn: true,
      };
      break;
    case LOGIN_USER_2FA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_USER_2FA_SUCCESS:
      state = {
        ...state,
        loading: false,
        expiry: action.payload.user.expiry * 1000,
        isLoggedIn: true,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isLoggedIn: false };

      break;

    // TODO Extra Reducer Functions
    // case GOOGLE_LOGIN:
    //   state = {
    //     ...state,
    //     loading: false,
    //     isLoggedIn: true,
    //   };
    //   break;

    // case API_ERROR:
    //   state = { ...state, error: action.payload, loading: false };
    //   break;
    default:
      state = state;
      break;
  }
  return state;
};

export default login;
