import React from "react";

type Props = {
  title: string;
};

const SidebarContentHeader = ({ title }: Props) => {
  return <li className="menu-title">{title}</li>;
};

export default SidebarContentHeader;
