import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
} from "./actionTypes";

export const getReportData = (client) => {
  return {
    type: GET_REPORT_DATA,
    payload: { client },
  };
};

export const getReportDataSuccess = (report_data) => {
  return {
    type: GET_REPORT_DATA_SUCCESS,
    payload: { report_data },
  };
};

export const getReportDataFail = (error) => {
  return {
    type: GET_REPORT_DATA_FAIL,
    payload: { error },
  };
};
