import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

import QRCode from "qrcode.react";

const MfaToken = () => {
  const location = useLocation();
  const history = useHistory();

  const onSubmit = (data) => {
    axios
      .put(
        "https://ceresapi.datakraken.net/api/auth/users/" +
          location.state.Email +
          "/accept_mfa" +
          "",
        {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: "Bearer " + location.state.Bearer,
          },
        }
      )

      // TODO Look into handeling response of API call, move to Redux Sagas
      .then((res) => {
        history.push({
          pathname: "/login/two-factor",
          state: {
            userDetails: {
              userEmail: location.state.Email,
            },
          },
        });
      })
      .catch(function (error) {
        history.push({
          pathname: "/login/two-factor",
          state: {
            userDetails: {
              userEmail: location.state.Email,
            },
          },
        });
      });
  };

  return (
    <section className="authentication--token">
      <div className="row card-header">
        <div className="col-12">
          <div className="text-white p-3">
            <h5 className="font-weight-bold my-4">Authentication token</h5>
          </div>
        </div>
      </div>

      <div className="card-body ">
        <Link to="/">
          <img
            src={logo}
            alt=""
            className="rounded-circle ceres-icon"
            height="34"
          />
        </Link>

        <div className="contents text-center py-5">
          <p>
            Here is your authentication token. Be sure to keep it safe in
            LastPass as you won't be seeing it again!
          </p>
          <QRCode
            value={`otpauth://totp/Ceres (${location.state.Email})?secret=${location.state.MFA_Token}`}
            className="my-5"
          />
          ,<p>{location.state.MFA_Token}</p>
        </div>

        <form
          className="form-horizontal d-flex justify-content-center align-items-center flex-column mt-3"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
          }}
        >
          <input
            type="submit"
            value="I understand. Proceed to Ceres."
            className="btn btn-primary  mb-1"
          />

          <h6 className="pt-3 text-center">
            <Link to="/login" className="text-muted">
              Use a different account
            </Link>
          </h6>
        </form>
      </div>
    </section>
  );
};

export default MfaToken;
