import { useState, useContext, useEffect } from "react";
import logo from "../../assets/images/logo.svg";

import { Row, Col, Card } from "react-bootstrap";

import WelcomeUser from "./WelcomeUser";
import HolidaySnapshot from "./HolidaySnapshot";

import axios from "axios";

const Homepage = () => {
  return (
    <section className="homepage">
      <Row>
        <Col xs={12} lg={4}>
          <WelcomeUser></WelcomeUser>
        </Col>
        <Col xs={12} lg={6}>
          {/* <HolidaySnapshot></HolidaySnapshot> */}
        </Col>
      </Row>
    </section>
  );
};

export default Homepage;
