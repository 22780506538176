import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  NavItem,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const AttendeesFilter = ({
  calendarEventsAPI,
  calendarEventsDisplay,
  setCalendarEventsDisplay,
}) => {
  const [teamFilter, setTeamFilter] = useState([]);
  const [allFilter, setAllFilter] = useState(false);

  function handleFilterChange(event) {
    console.log(event.target.value, event.target.name, event.target.checked);
    var filterValue = event.target.value;

    if (event.target.checked) {
      setTeamFilter((teamFilter) => [...teamFilter, event.target.value]);
    } else {
      setTeamFilter(
        teamFilter.filter((teamMember) => teamMember !== event.target.value)
      );
    }
  }

  const [team, setTeam] = useState();

  // Call inital data
  useEffect(() => {
    axios
      .get(`https://ceresapi.datakraken.net/api/v1/users/all`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Team res, ", response);
        setTeam(response.data.payload);
        setTeamFilter(team.map((member) => member["userEmail"]));
      })

      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (allFilter) {
      setTeamFilter([]);
    } else {
      console.log("he team", team);
      setTeamFilter(team && team.map((member) => member["userEmail"]));
    }
  }, [allFilter, team]);

  useEffect(() => {
    setCalendarEventsDisplay(
      calendarEventsAPI.filter(
        (event) =>
          event.eventAttendees &&
          event.eventAttendees.some((ai) => teamFilter.includes(ai))
      )
    );
  }, [teamFilter]);

  const filterEventType = (filterValue) => {
    console.log(filterValue);

    if (filterValue === "All")
      return setCalendarEventsDisplay(calendarEventsAPI);

    setCalendarEventsDisplay(
      calendarEventsAPI.filter(
        (event) =>
          event.eventAttendees && event.eventAttendees.includes(filterValue)
      )
    );

    console.log("Filtered: ", calendarEventsDisplay);
  };

  return (
    <section className="attendees-filter">
      <Card>
        <Card.Body>
          <Card.Title>
            <Row>
              <Col md={12} lg={7}>
                <h4 className="py-2">Team Members</h4>
              </Col>
              <Col md={12} lg={5} className="d-flex justify-content-end">
                <button
                  onClick={() => setAllFilter(!allFilter)}
                  className="attendees-filter--all__btn "
                >
                  {allFilter ? "Select All" : "Deselect All"}
                </button>
              </Col>
            </Row>
          </Card.Title>
          <fieldset
            value={teamFilter}
            onChange={(event) => handleFilterChange(event)}
            className="attendees-filter--toolbar"
            role="group"
            aria-label="..."
          >
            {team
              ? Object.keys(team).map((key) => (
                  <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        {team[key].userForename} {team[key].userSurname}
                      </Tooltip>
                    }
                  >
                    <div
                      key={team[key].userEmail}
                      className="attendees-filter--user"
                    >
                      <input
                        type="checkbox"
                        id={team[key].userEmail}
                        defaultValue={team[key].userEmail}
                        name="filter"
                        checked={
                          teamFilter && teamFilter.includes(team[key].userEmail)
                        }
                        className="attendees-filter--checkbox"
                      />
                      <label
                        htmlFor={team[key].userEmail}
                        className="attendees-filter--label"
                      >
                        <img
                          src={team[key].google_photo_url}
                          alt=""
                          className="attendees-filter--photo"
                        />
                      </label>
                    </div>
                  </OverlayTrigger>
                ))
              : "Loading..."}
          </fieldset>
        </Card.Body>
      </Card>
    </section>
  );
};

export default AttendeesFilter;
