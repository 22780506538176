export const ecuador_data = {
  request_made: 1634830845,
  request_latency: 260,
  payload: [
    {
      date: "2015-05-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 28,
      accounts: 11,
      dataSent: 1,
      dataReceived: 24,
      callsIn: 16,
      callsInDuration: 64,
      callsOut: 39,
      callsOutDuration: 80,
      smses: 108,
    },
    {
      date: "2015-05-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 51,
      accounts: 8,
      dataSent: 6,
      dataReceived: 75,
      callsIn: 19,
      callsInDuration: 92,
      callsOut: 21,
      callsOutDuration: 112,
      smses: 121,
    },
    {
      date: "2015-05-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 266,
      accounts: 9,
      dataSent: 25,
      dataReceived: 504,
      callsIn: 11,
      callsInDuration: 3,
      callsOut: 40,
      callsOutDuration: 22,
      smses: 17,
    },
    {
      date: "2015-05-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 196,
      accounts: 6,
      dataSent: 2,
      dataReceived: 15,
      callsIn: 11,
      callsInDuration: 8,
      callsOut: 20,
      callsOutDuration: 26,
      smses: 15,
    },
    {
      date: "2015-05-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 96,
      accounts: 6,
      dataSent: 2,
      dataReceived: 19,
      callsIn: 0,
      callsInDuration: 0,
      callsOut: 0,
      callsOutDuration: 0,
      smses: 2,
    },
    {
      date: "2015-05-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 153,
      accounts: 10,
      dataSent: 16,
      dataReceived: 233,
      callsIn: 7,
      callsInDuration: 4,
      callsOut: 7,
      callsOutDuration: 12,
      smses: 44,
    },
    {
      date: "2015-05-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 271,
      accounts: 19,
      dataSent: 63,
      dataReceived: 311,
      callsIn: 7,
      callsInDuration: 7,
      callsOut: 14,
      callsOutDuration: 12,
      smses: 4,
    },
    {
      date: "2015-05-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 661,
      accounts: 27,
      dataSent: 154,
      dataReceived: 954,
      callsIn: 52,
      callsInDuration: 123,
      callsOut: 75,
      callsOutDuration: 111,
      smses: 84,
    },
    {
      date: "2015-05-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1158,
      accounts: 34,
      dataSent: 134,
      dataReceived: 862,
      callsIn: 68,
      callsInDuration: 75,
      callsOut: 124,
      callsOutDuration: 126,
      smses: 211,
    },
    {
      date: "2015-05-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 650,
      accounts: 34,
      dataSent: 151,
      dataReceived: 1247,
      callsIn: 66,
      callsInDuration: 116,
      callsOut: 100,
      callsOutDuration: 104,
      smses: 309,
    },
    {
      date: "2015-05-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1188,
      accounts: 63,
      dataSent: 299,
      dataReceived: 915,
      callsIn: 97,
      callsInDuration: 112,
      callsOut: 188,
      callsOutDuration: 186,
      smses: 49,
    },
    {
      date: "2015-05-31",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2646,
      accounts: 110,
      dataSent: 586,
      dataReceived: 2717,
      callsIn: 152,
      callsInDuration: 64,
      callsOut: 286,
      callsOutDuration: 301,
      smses: 66,
    },
    {
      date: "2015-06-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5550,
      accounts: 192,
      dataSent: 1120,
      dataReceived: 6229,
      callsIn: 183,
      callsInDuration: 142,
      callsOut: 515,
      callsOutDuration: 553,
      smses: 99,
    },
    {
      date: "2015-06-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 8888,
      accounts: 268,
      dataSent: 1304,
      dataReceived: 7105,
      callsIn: 358,
      callsInDuration: 250,
      callsOut: 706,
      callsOutDuration: 692,
      smses: 145,
    },
    {
      date: "2015-06-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13299,
      accounts: 376,
      dataSent: 1774,
      dataReceived: 10021,
      callsIn: 530,
      callsInDuration: 404,
      callsOut: 1025,
      callsOutDuration: 904,
      smses: 251,
    },
    {
      date: "2015-06-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 18714,
      accounts: 503,
      dataSent: 2705,
      dataReceived: 13484,
      callsIn: 799,
      callsInDuration: 607,
      callsOut: 1339,
      callsOutDuration: 1205,
      smses: 364,
    },
    {
      date: "2015-06-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 28114,
      accounts: 673,
      dataSent: 3952,
      dataReceived: 21194,
      callsIn: 1034,
      callsInDuration: 781,
      callsOut: 1951,
      callsOutDuration: 1763,
      smses: 543,
    },
    {
      date: "2015-06-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 34812,
      accounts: 845,
      dataSent: 4748,
      dataReceived: 26623,
      callsIn: 1245,
      callsInDuration: 861,
      callsOut: 2097,
      callsOutDuration: 1705,
      smses: 553,
    },
    {
      date: "2015-06-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 38877,
      accounts: 883,
      dataSent: 4551,
      dataReceived: 27803,
      callsIn: 1177,
      callsInDuration: 764,
      callsOut: 1936,
      callsOutDuration: 1561,
      smses: 468,
    },
    {
      date: "2015-06-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 45545,
      accounts: 1054,
      dataSent: 5162,
      dataReceived: 29436,
      callsIn: 1433,
      callsInDuration: 1158,
      callsOut: 1977,
      callsOutDuration: 1889,
      smses: 526,
    },
    {
      date: "2015-06-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 53991,
      accounts: 1255,
      dataSent: 6213,
      dataReceived: 36796,
      callsIn: 1904,
      callsInDuration: 1578,
      callsOut: 2888,
      callsOutDuration: 2626,
      smses: 645,
    },
    {
      date: "2015-06-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 61344,
      accounts: 1442,
      dataSent: 6681,
      dataReceived: 38654,
      callsIn: 2265,
      callsInDuration: 1589,
      callsOut: 3390,
      callsOutDuration: 2991,
      smses: 854,
    },
    {
      date: "2015-06-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 74940,
      accounts: 1638,
      dataSent: 8420,
      dataReceived: 51419,
      callsIn: 2580,
      callsInDuration: 1834,
      callsOut: 3804,
      callsOutDuration: 3561,
      smses: 1026,
    },
    {
      date: "2015-06-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 82815,
      accounts: 1831,
      dataSent: 11672,
      dataReceived: 52671,
      callsIn: 3229,
      callsInDuration: 2473,
      callsOut: 4502,
      callsOutDuration: 3914,
      smses: 1236,
    },
    {
      date: "2015-06-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 84379,
      accounts: 2020,
      dataSent: 10811,
      dataReceived: 61774,
      callsIn: 3817,
      callsInDuration: 2433,
      callsOut: 5003,
      callsOutDuration: 4132,
      smses: 1113,
    },
    {
      date: "2015-06-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 88728,
      accounts: 2059,
      dataSent: 11254,
      dataReceived: 59158,
      callsIn: 3637,
      callsInDuration: 2253,
      callsOut: 4156,
      callsOutDuration: 3513,
      smses: 1133,
    },
    {
      date: "2015-06-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 103232,
      accounts: 2322,
      dataSent: 12381,
      dataReceived: 68346,
      callsIn: 4174,
      callsInDuration: 2989,
      callsOut: 4814,
      callsOutDuration: 4152,
      smses: 1265,
    },
    {
      date: "2015-06-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 112097,
      accounts: 2511,
      dataSent: 12936,
      dataReceived: 72314,
      callsIn: 4763,
      callsInDuration: 3423,
      callsOut: 5226,
      callsOutDuration: 4744,
      smses: 1376,
    },
    {
      date: "2015-06-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 117968,
      accounts: 2601,
      dataSent: 14293,
      dataReceived: 74919,
      callsIn: 5316,
      callsInDuration: 3933,
      callsOut: 5833,
      callsOutDuration: 5270,
      smses: 1566,
    },
    {
      date: "2015-06-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 126696,
      accounts: 2845,
      dataSent: 16103,
      dataReceived: 85800,
      callsIn: 5754,
      callsInDuration: 4211,
      callsOut: 6096,
      callsOutDuration: 5464,
      smses: 1759,
    },
    {
      date: "2015-06-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 139555,
      accounts: 3123,
      dataSent: 17106,
      dataReceived: 94762,
      callsIn: 6712,
      callsInDuration: 4983,
      callsOut: 7280,
      callsOutDuration: 6327,
      smses: 1850,
    },
    {
      date: "2015-06-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 192006,
      accounts: 3537,
      dataSent: 25169,
      dataReceived: 138744,
      callsIn: 10273,
      callsInDuration: 6555,
      callsOut: 10632,
      callsOutDuration: 8938,
      smses: 2579,
    },
    {
      date: "2015-06-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 143349,
      accounts: 3321,
      dataSent: 18842,
      dataReceived: 102972,
      callsIn: 5512,
      callsInDuration: 3669,
      callsOut: 5649,
      callsOutDuration: 5225,
      smses: 1637,
    },
    {
      date: "2015-06-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 162245,
      accounts: 3617,
      dataSent: 20133,
      dataReceived: 122923,
      callsIn: 7339,
      callsInDuration: 5233,
      callsOut: 7281,
      callsOutDuration: 6511,
      smses: 1952,
    },
    {
      date: "2015-06-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 170667,
      accounts: 3877,
      dataSent: 21888,
      dataReceived: 129628,
      callsIn: 8130,
      callsInDuration: 5618,
      callsOut: 8178,
      callsOutDuration: 7296,
      smses: 2226,
    },
    {
      date: "2015-06-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 177166,
      accounts: 3996,
      dataSent: 22816,
      dataReceived: 127827,
      callsIn: 8381,
      callsInDuration: 5789,
      callsOut: 8461,
      callsOutDuration: 7418,
      smses: 2208,
    },
    {
      date: "2015-06-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 180260,
      accounts: 4135,
      dataSent: 22775,
      dataReceived: 136854,
      callsIn: 8801,
      callsInDuration: 6525,
      callsOut: 8427,
      callsOutDuration: 7540,
      smses: 2280,
    },
    {
      date: "2015-06-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 187551,
      accounts: 4231,
      dataSent: 25043,
      dataReceived: 133905,
      callsIn: 10290,
      callsInDuration: 7434,
      callsOut: 9789,
      callsOutDuration: 8447,
      smses: 2362,
    },
    {
      date: "2015-06-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 183672,
      accounts: 4260,
      dataSent: 23676,
      dataReceived: 135952,
      callsIn: 10129,
      callsInDuration: 6174,
      callsOut: 9647,
      callsOutDuration: 7908,
      smses: 2376,
    },
    {
      date: "2015-06-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 174597,
      accounts: 4155,
      dataSent: 24917,
      dataReceived: 144063,
      callsIn: 7623,
      callsInDuration: 4936,
      callsOut: 6973,
      callsOutDuration: 6184,
      smses: 2041,
    },
    {
      date: "2015-06-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 204012,
      accounts: 4524,
      dataSent: 28413,
      dataReceived: 151833,
      callsIn: 10324,
      callsInDuration: 7199,
      callsOut: 9780,
      callsOutDuration: 8590,
      smses: 2604,
    },
    {
      date: "2015-06-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 210430,
      accounts: 4579,
      dataSent: 27083,
      dataReceived: 152222,
      callsIn: 10862,
      callsInDuration: 8091,
      callsOut: 10065,
      callsOutDuration: 9047,
      smses: 2923,
    },
    {
      date: "2015-07-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 214137,
      accounts: 4811,
      dataSent: 26509,
      dataReceived: 154618,
      callsIn: 11311,
      callsInDuration: 8016,
      callsOut: 10180,
      callsOutDuration: 9092,
      smses: 2742,
    },
    {
      date: "2015-07-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 225650,
      accounts: 4911,
      dataSent: 25828,
      dataReceived: 152157,
      callsIn: 12363,
      callsInDuration: 9134,
      callsOut: 10975,
      callsOutDuration: 9721,
      smses: 3103,
    },
    {
      date: "2015-07-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 241164,
      accounts: 5187,
      dataSent: 30674,
      dataReceived: 173704,
      callsIn: 14724,
      callsInDuration: 10360,
      callsOut: 13101,
      callsOutDuration: 11219,
      smses: 3216,
    },
    {
      date: "2015-07-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 228141,
      accounts: 5163,
      dataSent: 31557,
      dataReceived: 171827,
      callsIn: 14043,
      callsInDuration: 8602,
      callsOut: 12040,
      callsOutDuration: 9583,
      smses: 2800,
    },
    {
      date: "2015-07-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 226178,
      accounts: 5150,
      dataSent: 32933,
      dataReceived: 183311,
      callsIn: 10551,
      callsInDuration: 6715,
      callsOut: 9038,
      callsOutDuration: 7973,
      smses: 2740,
    },
    {
      date: "2015-07-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 227692,
      accounts: 5159,
      dataSent: 33332,
      dataReceived: 176581,
      callsIn: 11282,
      callsInDuration: 7842,
      callsOut: 9450,
      callsOutDuration: 8599,
      smses: 2726,
    },
    {
      date: "2015-07-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 248245,
      accounts: 5528,
      dataSent: 31796,
      dataReceived: 178338,
      callsIn: 13375,
      callsInDuration: 10128,
      callsOut: 11449,
      callsOutDuration: 10305,
      smses: 3281,
    },
    {
      date: "2015-07-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 248028,
      accounts: 5682,
      dataSent: 33618,
      dataReceived: 188732,
      callsIn: 14785,
      callsInDuration: 10864,
      callsOut: 11669,
      callsOutDuration: 10592,
      smses: 3440,
    },
    {
      date: "2015-07-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 250498,
      accounts: 5719,
      dataSent: 33555,
      dataReceived: 180333,
      callsIn: 15302,
      callsInDuration: 11832,
      callsOut: 11954,
      callsOutDuration: 10534,
      smses: 3560,
    },
    {
      date: "2015-07-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 254333,
      accounts: 5834,
      dataSent: 33831,
      dataReceived: 186146,
      callsIn: 17187,
      callsInDuration: 12615,
      callsOut: 13425,
      callsOutDuration: 11769,
      smses: 3786,
    },
    {
      date: "2015-07-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 263036,
      accounts: 5975,
      dataSent: 40701,
      dataReceived: 209910,
      callsIn: 17419,
      callsInDuration: 11453,
      callsOut: 13854,
      callsOutDuration: 11818,
      smses: 3884,
    },
    {
      date: "2015-07-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 256037,
      accounts: 5858,
      dataSent: 38881,
      dataReceived: 201013,
      callsIn: 12544,
      callsInDuration: 8210,
      callsOut: 9902,
      callsOutDuration: 8859,
      smses: 3031,
    },
    {
      date: "2015-07-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 272730,
      accounts: 6143,
      dataSent: 39288,
      dataReceived: 195291,
      callsIn: 16087,
      callsInDuration: 11948,
      callsOut: 12895,
      callsOutDuration: 11732,
      smses: 3803,
    },
    {
      date: "2015-07-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 295347,
      accounts: 6342,
      dataSent: 38746,
      dataReceived: 214428,
      callsIn: 16693,
      callsInDuration: 12600,
      callsOut: 13504,
      callsOutDuration: 12255,
      smses: 4074,
    },
    {
      date: "2015-07-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 297662,
      accounts: 6609,
      dataSent: 39912,
      dataReceived: 224387,
      callsIn: 17551,
      callsInDuration: 14047,
      callsOut: 14321,
      callsOutDuration: 12817,
      smses: 4031,
    },
    {
      date: "2015-07-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 299854,
      accounts: 6812,
      dataSent: 41559,
      dataReceived: 223620,
      callsIn: 18240,
      callsInDuration: 13462,
      callsOut: 15381,
      callsOutDuration: 13833,
      smses: 4341,
    },
    {
      date: "2015-07-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 317472,
      accounts: 7071,
      dataSent: 45693,
      dataReceived: 245806,
      callsIn: 20766,
      callsInDuration: 14459,
      callsOut: 17569,
      callsOutDuration: 15176,
      smses: 4604,
    },
    {
      date: "2015-07-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 318986,
      accounts: 7211,
      dataSent: 44933,
      dataReceived: 256498,
      callsIn: 21531,
      callsInDuration: 14053,
      callsOut: 17021,
      callsOutDuration: 14015,
      smses: 4360,
    },
    {
      date: "2015-07-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 309626,
      accounts: 7078,
      dataSent: 47194,
      dataReceived: 260274,
      callsIn: 14988,
      callsInDuration: 9341,
      callsOut: 11814,
      callsOutDuration: 10398,
      smses: 3578,
    },
    {
      date: "2015-07-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 339964,
      accounts: 7673,
      dataSent: 50486,
      dataReceived: 275002,
      callsIn: 20203,
      callsInDuration: 14405,
      callsOut: 16033,
      callsOutDuration: 14508,
      smses: 4679,
    },
    {
      date: "2015-07-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 358315,
      accounts: 7877,
      dataSent: 49938,
      dataReceived: 277023,
      callsIn: 20312,
      callsInDuration: 14392,
      callsOut: 17719,
      callsOutDuration: 15800,
      smses: 5187,
    },
    {
      date: "2015-07-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 368723,
      accounts: 8091,
      dataSent: 51276,
      dataReceived: 279344,
      callsIn: 21216,
      callsInDuration: 15640,
      callsOut: 17764,
      callsOutDuration: 16156,
      smses: 4815,
    },
    {
      date: "2015-07-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 374170,
      accounts: 8266,
      dataSent: 50948,
      dataReceived: 290580,
      callsIn: 21880,
      callsInDuration: 15769,
      callsOut: 18887,
      callsOutDuration: 16583,
      smses: 5403,
    },
    {
      date: "2015-07-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 391806,
      accounts: 8558,
      dataSent: 58461,
      dataReceived: 295055,
      callsIn: 27063,
      callsInDuration: 17836,
      callsOut: 21492,
      callsOutDuration: 17993,
      smses: 5814,
    },
    {
      date: "2015-07-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 378043,
      accounts: 8480,
      dataSent: 58902,
      dataReceived: 304498,
      callsIn: 25475,
      callsInDuration: 16022,
      callsOut: 20270,
      callsOutDuration: 16148,
      smses: 5262,
    },
    {
      date: "2015-07-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 371009,
      accounts: 8372,
      dataSent: 56662,
      dataReceived: 301957,
      callsIn: 18008,
      callsInDuration: 11691,
      callsOut: 14148,
      callsOutDuration: 12008,
      smses: 4180,
    },
    {
      date: "2015-07-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 394704,
      accounts: 8822,
      dataSent: 56391,
      dataReceived: 312324,
      callsIn: 23194,
      callsInDuration: 16686,
      callsOut: 18697,
      callsOutDuration: 16870,
      smses: 5439,
    },
    {
      date: "2015-07-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 405740,
      accounts: 9069,
      dataSent: 57491,
      dataReceived: 331835,
      callsIn: 23267,
      callsInDuration: 16976,
      callsOut: 19244,
      callsOutDuration: 17248,
      smses: 5270,
    },
    {
      date: "2015-07-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 419935,
      accounts: 9275,
      dataSent: 58567,
      dataReceived: 321568,
      callsIn: 24451,
      callsInDuration: 17421,
      callsOut: 20040,
      callsOutDuration: 17727,
      smses: 5905,
    },
    {
      date: "2015-07-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 432641,
      accounts: 9615,
      dataSent: 58779,
      dataReceived: 327229,
      callsIn: 26683,
      callsInDuration: 18264,
      callsOut: 22881,
      callsOutDuration: 20145,
      smses: 6238,
    },
    {
      date: "2015-07-31",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 439384,
      accounts: 9964,
      dataSent: 59799,
      dataReceived: 324189,
      callsIn: 32908,
      callsInDuration: 21641,
      callsOut: 26684,
      callsOutDuration: 22358,
      smses: 7199,
    },
    {
      date: "2015-08-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 454622,
      accounts: 10244,
      dataSent: 67719,
      dataReceived: 349875,
      callsIn: 32549,
      callsInDuration: 19258,
      callsOut: 26120,
      callsOutDuration: 21098,
      smses: 6563,
    },
    {
      date: "2015-08-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 454678,
      accounts: 10070,
      dataSent: 67128,
      dataReceived: 349786,
      callsIn: 23986,
      callsInDuration: 14517,
      callsOut: 18612,
      callsOutDuration: 15682,
      smses: 5248,
    },
    {
      date: "2015-08-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 480357,
      accounts: 10685,
      dataSent: 65417,
      dataReceived: 337505,
      callsIn: 30472,
      callsInDuration: 22090,
      callsOut: 24596,
      callsOutDuration: 21933,
      smses: 6941,
    },
    {
      date: "2015-08-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 508236,
      accounts: 11067,
      dataSent: 75281,
      dataReceived: 392162,
      callsIn: 30502,
      callsInDuration: 21261,
      callsOut: 25132,
      callsOutDuration: 22154,
      smses: 6972,
    },
    {
      date: "2015-08-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 498699,
      accounts: 11218,
      dataSent: 72049,
      dataReceived: 377942,
      callsIn: 31275,
      callsInDuration: 21343,
      callsOut: 26226,
      callsOutDuration: 23018,
      smses: 7021,
    },
    {
      date: "2015-08-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 508293,
      accounts: 11457,
      dataSent: 68064,
      dataReceived: 386354,
      callsIn: 32636,
      callsInDuration: 22730,
      callsOut: 27262,
      callsOutDuration: 23588,
      smses: 7422,
    },
    {
      date: "2015-08-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 538013,
      accounts: 11793,
      dataSent: 70486,
      dataReceived: 401967,
      callsIn: 39178,
      callsInDuration: 25929,
      callsOut: 31116,
      callsOutDuration: 27024,
      smses: 7932,
    },
    {
      date: "2015-08-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 539475,
      accounts: 11890,
      dataSent: 74620,
      dataReceived: 391503,
      callsIn: 38588,
      callsInDuration: 24103,
      callsOut: 28226,
      callsOutDuration: 23104,
      smses: 7443,
    },
    {
      date: "2015-08-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 508988,
      accounts: 11548,
      dataSent: 73979,
      dataReceived: 391640,
      callsIn: 29106,
      callsInDuration: 17743,
      callsOut: 20067,
      callsOutDuration: 16966,
      smses: 5830,
    },
    {
      date: "2015-08-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 518993,
      accounts: 11678,
      dataSent: 77713,
      dataReceived: 393771,
      callsIn: 28087,
      callsInDuration: 18972,
      callsOut: 20717,
      callsOutDuration: 18030,
      smses: 6214,
    },
    {
      date: "2015-08-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 545620,
      accounts: 12238,
      dataSent: 77399,
      dataReceived: 402716,
      callsIn: 35031,
      callsInDuration: 24958,
      callsOut: 26180,
      callsOutDuration: 23165,
      smses: 7700,
    },
    {
      date: "2015-08-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 558886,
      accounts: 12284,
      dataSent: 78338,
      dataReceived: 419702,
      callsIn: 36810,
      callsInDuration: 25736,
      callsOut: 26455,
      callsOutDuration: 23340,
      smses: 7934,
    },
    {
      date: "2015-08-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 570609,
      accounts: 12288,
      dataSent: 76362,
      dataReceived: 433391,
      callsIn: 37263,
      callsInDuration: 26732,
      callsOut: 26848,
      callsOutDuration: 24174,
      smses: 7745,
    },
    {
      date: "2015-08-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 583267,
      accounts: 12712,
      dataSent: 76443,
      dataReceived: 431295,
      callsIn: 44283,
      callsInDuration: 29649,
      callsOut: 31677,
      callsOutDuration: 26918,
      smses: 8682,
    },
    {
      date: "2015-08-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 588026,
      accounts: 12883,
      dataSent: 78315,
      dataReceived: 446731,
      callsIn: 44778,
      callsInDuration: 27486,
      callsOut: 29880,
      callsOutDuration: 23927,
      smses: 8042,
    },
    {
      date: "2015-08-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 554467,
      accounts: 12614,
      dataSent: 81192,
      dataReceived: 441886,
      callsIn: 32531,
      callsInDuration: 19752,
      callsOut: 21032,
      callsOutDuration: 17782,
      smses: 6303,
    },
    {
      date: "2015-08-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 597042,
      accounts: 13303,
      dataSent: 80923,
      dataReceived: 452351,
      callsIn: 41548,
      callsInDuration: 29138,
      callsOut: 28564,
      callsOutDuration: 25554,
      smses: 8370,
    },
    {
      date: "2015-08-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 608200,
      accounts: 13493,
      dataSent: 80745,
      dataReceived: 467832,
      callsIn: 40754,
      callsInDuration: 28711,
      callsOut: 28593,
      callsOutDuration: 25190,
      smses: 8712,
    },
    {
      date: "2015-08-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 625738,
      accounts: 13608,
      dataSent: 84483,
      dataReceived: 487386,
      callsIn: 41292,
      callsInDuration: 29470,
      callsOut: 29354,
      callsOutDuration: 26171,
      smses: 8862,
    },
    {
      date: "2015-08-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 639204,
      accounts: 13922,
      dataSent: 83689,
      dataReceived: 498647,
      callsIn: 42241,
      callsInDuration: 30536,
      callsOut: 30171,
      callsOutDuration: 26955,
      smses: 8777,
    },
    {
      date: "2015-08-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 657399,
      accounts: 14199,
      dataSent: 84983,
      dataReceived: 488635,
      callsIn: 49673,
      callsInDuration: 33389,
      callsOut: 34245,
      callsOutDuration: 29303,
      smses: 10000,
    },
    {
      date: "2015-08-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 642551,
      accounts: 14092,
      dataSent: 86903,
      dataReceived: 485532,
      callsIn: 49444,
      callsInDuration: 29532,
      callsOut: 32975,
      callsOutDuration: 26504,
      smses: 8825,
    },
    {
      date: "2015-08-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 619235,
      accounts: 13765,
      dataSent: 86890,
      dataReceived: 492654,
      callsIn: 33981,
      callsInDuration: 22249,
      callsOut: 22480,
      callsOutDuration: 19672,
      smses: 7205,
    },
    {
      date: "2015-08-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 657177,
      accounts: 14411,
      dataSent: 87377,
      dataReceived: 487800,
      callsIn: 44238,
      callsInDuration: 32313,
      callsOut: 29680,
      callsOutDuration: 26629,
      smses: 9274,
    },
    {
      date: "2015-08-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 683117,
      accounts: 14680,
      dataSent: 87934,
      dataReceived: 513241,
      callsIn: 44733,
      callsInDuration: 31012,
      callsOut: 30835,
      callsOutDuration: 27392,
      smses: 9164,
    },
    {
      date: "2015-08-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 668400,
      accounts: 14618,
      dataSent: 86635,
      dataReceived: 492950,
      callsIn: 44442,
      callsInDuration: 31386,
      callsOut: 30455,
      callsOutDuration: 27037,
      smses: 9704,
    },
    {
      date: "2015-08-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 686982,
      accounts: 14794,
      dataSent: 95357,
      dataReceived: 521483,
      callsIn: 45183,
      callsInDuration: 31031,
      callsOut: 31594,
      callsOutDuration: 28050,
      smses: 9601,
    },
    {
      date: "2015-08-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 706382,
      accounts: 15114,
      dataSent: 92445,
      dataReceived: 524919,
      callsIn: 53757,
      callsInDuration: 34695,
      callsOut: 37585,
      callsOutDuration: 31709,
      smses: 11105,
    },
    {
      date: "2015-08-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 674011,
      accounts: 15101,
      dataSent: 95628,
      dataReceived: 525654,
      callsIn: 52466,
      callsInDuration: 31173,
      callsOut: 35376,
      callsOutDuration: 28874,
      smses: 10201,
    },
    {
      date: "2015-08-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 645770,
      accounts: 14639,
      dataSent: 95147,
      dataReceived: 527012,
      callsIn: 38837,
      callsInDuration: 23409,
      callsOut: 26310,
      callsOutDuration: 22318,
      smses: 8015,
    },
    {
      date: "2015-08-31",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 706734,
      accounts: 15425,
      dataSent: 98967,
      dataReceived: 550748,
      callsIn: 47371,
      callsInDuration: 32312,
      callsOut: 34369,
      callsOutDuration: 30231,
      smses: 10373,
    },
    {
      date: "2015-09-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 721507,
      accounts: 15789,
      dataSent: 96191,
      dataReceived: 547767,
      callsIn: 49793,
      callsInDuration: 34984,
      callsOut: 35396,
      callsOutDuration: 31283,
      smses: 10666,
    },
    {
      date: "2015-09-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 721623,
      accounts: 15993,
      dataSent: 96002,
      dataReceived: 539395,
      callsIn: 52257,
      callsInDuration: 36023,
      callsOut: 35559,
      callsOutDuration: 31229,
      smses: 10199,
    },
    {
      date: "2015-09-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 735032,
      accounts: 16040,
      dataSent: 96802,
      dataReceived: 535303,
      callsIn: 53287,
      callsInDuration: 38613,
      callsOut: 35557,
      callsOutDuration: 31164,
      smses: 10339,
    },
    {
      date: "2015-09-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 755864,
      accounts: 16295,
      dataSent: 100820,
      dataReceived: 537025,
      callsIn: 61007,
      callsInDuration: 39493,
      callsOut: 39204,
      callsOutDuration: 32458,
      smses: 11569,
    },
    {
      date: "2015-09-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 715106,
      accounts: 16187,
      dataSent: 101065,
      dataReceived: 540353,
      callsIn: 57322,
      callsInDuration: 33321,
      callsOut: 38722,
      callsOutDuration: 31361,
      smses: 10497,
    },
    {
      date: "2015-09-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 700793,
      accounts: 15698,
      dataSent: 96721,
      dataReceived: 529694,
      callsIn: 41968,
      callsInDuration: 25379,
      callsOut: 27120,
      callsOutDuration: 23105,
      smses: 8378,
    },
    {
      date: "2015-09-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 753292,
      accounts: 16436,
      dataSent: 98861,
      dataReceived: 518844,
      callsIn: 52318,
      callsInDuration: 36224,
      callsOut: 36040,
      callsOutDuration: 32057,
      smses: 10874,
    },
    {
      date: "2015-09-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 742542,
      accounts: 16285,
      dataSent: 88237,
      dataReceived: 479814,
      callsIn: 53929,
      callsInDuration: 38607,
      callsOut: 34734,
      callsOutDuration: 30405,
      smses: 10390,
    },
    {
      date: "2015-09-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 737006,
      accounts: 16442,
      dataSent: 102740,
      dataReceived: 574591,
      callsIn: 54017,
      callsInDuration: 39820,
      callsOut: 35958,
      callsOutDuration: 31626,
      smses: 10319,
    },
    {
      date: "2015-09-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 756170,
      accounts: 16287,
      dataSent: 98262,
      dataReceived: 544725,
      callsIn: 53906,
      callsInDuration: 39314,
      callsOut: 34504,
      callsOutDuration: 30550,
      smses: 10343,
    },
    {
      date: "2015-09-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 756558,
      accounts: 16423,
      dataSent: 98777,
      dataReceived: 539725,
      callsIn: 63818,
      callsInDuration: 43565,
      callsOut: 39325,
      callsOutDuration: 32559,
      smses: 11569,
    },
    {
      date: "2015-09-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 749597,
      accounts: 16282,
      dataSent: 100649,
      dataReceived: 541662,
      callsIn: 63687,
      callsInDuration: 38174,
      callsOut: 37225,
      callsOutDuration: 30269,
      smses: 10501,
    },
    {
      date: "2015-09-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 710976,
      accounts: 15741,
      dataSent: 96460,
      dataReceived: 538107,
      callsIn: 45814,
      callsInDuration: 28514,
      callsOut: 26473,
      callsOutDuration: 23203,
      smses: 7872,
    },
    {
      date: "2015-09-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 755488,
      accounts: 16427,
      dataSent: 98701,
      dataReceived: 550186,
      callsIn: 54866,
      callsInDuration: 38687,
      callsOut: 34206,
      callsOutDuration: 30287,
      smses: 10475,
    },
    {
      date: "2015-09-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 774972,
      accounts: 16455,
      dataSent: 99183,
      dataReceived: 564191,
      callsIn: 58086,
      callsInDuration: 41570,
      callsOut: 35302,
      callsOutDuration: 31207,
      smses: 11020,
    },
    {
      date: "2015-09-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 805825,
      accounts: 16582,
      dataSent: 97736,
      dataReceived: 549768,
      callsIn: 58207,
      callsInDuration: 40840,
      callsOut: 33890,
      callsOutDuration: 29216,
      smses: 10559,
    },
    {
      date: "2015-09-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 799972,
      accounts: 16501,
      dataSent: 97309,
      dataReceived: 556273,
      callsIn: 58954,
      callsInDuration: 40311,
      callsOut: 35325,
      callsOutDuration: 30905,
      smses: 10479,
    },
    {
      date: "2015-09-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 796895,
      accounts: 16670,
      dataSent: 96672,
      dataReceived: 550157,
      callsIn: 67388,
      callsInDuration: 44969,
      callsOut: 40202,
      callsOutDuration: 33653,
      smses: 11732,
    },
    {
      date: "2015-09-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 800724,
      accounts: 16932,
      dataSent: 102448,
      dataReceived: 555834,
      callsIn: 67094,
      callsInDuration: 39601,
      callsOut: 39146,
      callsOutDuration: 31670,
      smses: 10860,
    },
    {
      date: "2015-09-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 768538,
      accounts: 16392,
      dataSent: 104689,
      dataReceived: 578347,
      callsIn: 47335,
      callsInDuration: 28325,
      callsOut: 26838,
      callsOutDuration: 22657,
      smses: 8141,
    },
    {
      date: "2015-09-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 821033,
      accounts: 16804,
      dataSent: 98470,
      dataReceived: 548616,
      callsIn: 57429,
      callsInDuration: 41240,
      callsOut: 33008,
      callsOutDuration: 29354,
      smses: 10527,
    },
    {
      date: "2015-09-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 839326,
      accounts: 16766,
      dataSent: 99324,
      dataReceived: 561433,
      callsIn: 56156,
      callsInDuration: 38821,
      callsOut: 33594,
      callsOutDuration: 30059,
      smses: 10456,
    },
    {
      date: "2015-09-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 857564,
      accounts: 16935,
      dataSent: 113485,
      dataReceived: 601596,
      callsIn: 55708,
      callsInDuration: 39154,
      callsOut: 33955,
      callsOutDuration: 30009,
      smses: 9615,
    },
    {
      date: "2015-09-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 841705,
      accounts: 16933,
      dataSent: 104988,
      dataReceived: 579471,
      callsIn: 58030,
      callsInDuration: 40571,
      callsOut: 35753,
      callsOutDuration: 32125,
      smses: 10620,
    },
    {
      date: "2015-09-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 849591,
      accounts: 17120,
      dataSent: 99751,
      dataReceived: 586398,
      callsIn: 66151,
      callsInDuration: 44513,
      callsOut: 40141,
      callsOutDuration: 34099,
      smses: 11147,
    },
    {
      date: "2015-09-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 850196,
      accounts: 17184,
      dataSent: 117084,
      dataReceived: 652094,
      callsIn: 65132,
      callsInDuration: 38175,
      callsOut: 40070,
      callsOutDuration: 31436,
      smses: 10597,
    },
    {
      date: "2015-09-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 800302,
      accounts: 16547,
      dataSent: 109325,
      dataReceived: 621194,
      callsIn: 45917,
      callsInDuration: 29533,
      callsOut: 28446,
      callsOutDuration: 24601,
      smses: 8612,
    },
    {
      date: "2015-09-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 886627,
      accounts: 17336,
      dataSent: 107420,
      dataReceived: 607928,
      callsIn: 56170,
      callsInDuration: 40779,
      callsOut: 36095,
      callsOutDuration: 31518,
      smses: 12593,
    },
    {
      date: "2015-09-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 892181,
      accounts: 17297,
      dataSent: 103782,
      dataReceived: 595042,
      callsIn: 56544,
      callsInDuration: 40868,
      callsOut: 35362,
      callsOutDuration: 31242,
      smses: 11324,
    },
    {
      date: "2015-09-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 895460,
      accounts: 17523,
      dataSent: 98597,
      dataReceived: 508603,
      callsIn: 58714,
      callsInDuration: 41594,
      callsOut: 39482,
      callsOutDuration: 34014,
      smses: 11526,
    },
    {
      date: "2015-10-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 925882,
      accounts: 17670,
      dataSent: 101372,
      dataReceived: 566277,
      callsIn: 61988,
      callsInDuration: 42946,
      callsOut: 42444,
      callsOutDuration: 37635,
      smses: 12003,
    },
    {
      date: "2015-10-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 956942,
      accounts: 18122,
      dataSent: 106244,
      dataReceived: 551129,
      callsIn: 74728,
      callsInDuration: 47520,
      callsOut: 49856,
      callsOutDuration: 41571,
      smses: 14271,
    },
    {
      date: "2015-10-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 922226,
      accounts: 18138,
      dataSent: 108405,
      dataReceived: 585614,
      callsIn: 74476,
      callsInDuration: 42228,
      callsOut: 50211,
      callsOutDuration: 39761,
      smses: 12854,
    },
    {
      date: "2015-10-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 893579,
      accounts: 17791,
      dataSent: 110269,
      dataReceived: 587601,
      callsIn: 53658,
      callsInDuration: 32715,
      callsOut: 36502,
      callsOutDuration: 31280,
      smses: 10143,
    },
    {
      date: "2015-10-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 961261,
      accounts: 18468,
      dataSent: 108960,
      dataReceived: 574468,
      callsIn: 66639,
      callsInDuration: 45978,
      callsOut: 46356,
      callsOutDuration: 39951,
      smses: 12686,
    },
    {
      date: "2015-10-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1003358,
      accounts: 18733,
      dataSent: 111738,
      dataReceived: 593265,
      callsIn: 66124,
      callsInDuration: 44806,
      callsOut: 46701,
      callsOutDuration: 41423,
      smses: 15052,
    },
    {
      date: "2015-10-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1007227,
      accounts: 18817,
      dataSent: 119884,
      dataReceived: 671546,
      callsIn: 67731,
      callsInDuration: 46997,
      callsOut: 47964,
      callsOutDuration: 42614,
      smses: 14607,
    },
    {
      date: "2015-10-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1020623,
      accounts: 19081,
      dataSent: 116853,
      dataReceived: 601773,
      callsIn: 78117,
      callsInDuration: 51910,
      callsOut: 52816,
      callsOutDuration: 45096,
      smses: 17740,
    },
    {
      date: "2015-10-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 911899,
      accounts: 18617,
      dataSent: 118991,
      dataReceived: 659114,
      callsIn: 68211,
      callsInDuration: 42650,
      callsOut: 45340,
      callsOutDuration: 38261,
      smses: 11927,
    },
    {
      date: "2015-10-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 913040,
      accounts: 18581,
      dataSent: 118706,
      dataReceived: 667560,
      callsIn: 67854,
      callsInDuration: 41106,
      callsOut: 45692,
      callsOutDuration: 36833,
      smses: 12636,
    },
    {
      date: "2015-10-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 906559,
      accounts: 18262,
      dataSent: 124759,
      dataReceived: 698637,
      callsIn: 53336,
      callsInDuration: 33078,
      callsOut: 35522,
      callsOutDuration: 30012,
      smses: 11246,
    },
    {
      date: "2015-10-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 953616,
      accounts: 18820,
      dataSent: 116810,
      dataReceived: 655909,
      callsIn: 68048,
      callsInDuration: 48732,
      callsOut: 45579,
      callsOutDuration: 39572,
      smses: 15227,
    },
    {
      date: "2015-10-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 963307,
      accounts: 18776,
      dataSent: 122054,
      dataReceived: 666345,
      callsIn: 58517,
      callsInDuration: 46312,
      callsOut: 45063,
      callsOutDuration: 39240,
      smses: 14719,
    },
    {
      date: "2015-10-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 972589,
      accounts: 18891,
      dataSent: 119185,
      dataReceived: 684244,
      callsIn: 58349,
      callsInDuration: 48848,
      callsOut: 44955,
      callsOutDuration: 40787,
      smses: 14536,
    },
    {
      date: "2015-10-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 982754,
      accounts: 19019,
      dataSent: 122509,
      dataReceived: 688710,
      callsIn: 60717,
      callsInDuration: 50549,
      callsOut: 47030,
      callsOutDuration: 41210,
      smses: 15544,
    },
    {
      date: "2015-10-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 957138,
      accounts: 19135,
      dataSent: 114750,
      dataReceived: 654449,
      callsIn: 71525,
      callsInDuration: 56830,
      callsOut: 53909,
      callsOutDuration: 46009,
      smses: 17126,
    },
    {
      date: "2015-10-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 934286,
      accounts: 19013,
      dataSent: 117555,
      dataReceived: 660084,
      callsIn: 71681,
      callsInDuration: 49042,
      callsOut: 52059,
      callsOutDuration: 41668,
      smses: 14545,
    },
    {
      date: "2015-10-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 913059,
      accounts: 18652,
      dataSent: 118788,
      dataReceived: 672019,
      callsIn: 50310,
      callsInDuration: 36309,
      callsOut: 37395,
      callsOutDuration: 31398,
      smses: 11468,
    },
    {
      date: "2015-10-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 961830,
      accounts: 19308,
      dataSent: 122793,
      dataReceived: 684652,
      callsIn: 71750,
      callsInDuration: 50206,
      callsOut: 47547,
      callsOutDuration: 41263,
      smses: 17948,
    },
    {
      date: "2015-10-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 977572,
      accounts: 19395,
      dataSent: 118640,
      dataReceived: 699094,
      callsIn: 71260,
      callsInDuration: 49966,
      callsOut: 49342,
      callsOutDuration: 42778,
      smses: 15384,
    },
    {
      date: "2015-10-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 951032,
      accounts: 19489,
      dataSent: 122293,
      dataReceived: 713083,
      callsIn: 70210,
      callsInDuration: 48563,
      callsOut: 49027,
      callsOutDuration: 42783,
      smses: 13461,
    },
    {
      date: "2015-10-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1005560,
      accounts: 19794,
      dataSent: 133617,
      dataReceived: 801555,
      callsIn: 71527,
      callsInDuration: 48714,
      callsOut: 50752,
      callsOutDuration: 44333,
      smses: 13966,
    },
    {
      date: "2015-10-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 994411,
      accounts: 19928,
      dataSent: 127804,
      dataReceived: 748406,
      callsIn: 80249,
      callsInDuration: 52304,
      callsOut: 57106,
      callsOutDuration: 47353,
      smses: 14966,
    },
    {
      date: "2015-10-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 965780,
      accounts: 20071,
      dataSent: 134001,
      dataReceived: 758907,
      callsIn: 83099,
      callsInDuration: 48063,
      callsOut: 56913,
      callsOutDuration: 44516,
      smses: 14350,
    },
    {
      date: "2015-10-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 921610,
      accounts: 19504,
      dataSent: 133965,
      dataReceived: 769385,
      callsIn: 59293,
      callsInDuration: 37057,
      callsOut: 41028,
      callsOutDuration: 34278,
      smses: 11560,
    },
    {
      date: "2015-10-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 969772,
      accounts: 20367,
      dataSent: 136490,
      dataReceived: 804248,
      callsIn: 73566,
      callsInDuration: 49623,
      callsOut: 51393,
      callsOutDuration: 44195,
      smses: 14476,
    },
    {
      date: "2015-10-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1015407,
      accounts: 20409,
      dataSent: 139922,
      dataReceived: 844792,
      callsIn: 74429,
      callsInDuration: 50371,
      callsOut: 52960,
      callsOutDuration: 45586,
      smses: 14436,
    },
    {
      date: "2015-10-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1031010,
      accounts: 20493,
      dataSent: 145990,
      dataReceived: 883663,
      callsIn: 74960,
      callsInDuration: 51935,
      callsOut: 53157,
      callsOutDuration: 45813,
      smses: 15014,
    },
    {
      date: "2015-10-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1039779,
      accounts: 20753,
      dataSent: 141126,
      dataReceived: 840323,
      callsIn: 77234,
      callsInDuration: 52462,
      callsOut: 55571,
      callsOutDuration: 47046,
      smses: 15134,
    },
    {
      date: "2015-10-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1079447,
      accounts: 21408,
      dataSent: 143507,
      dataReceived: 808947,
      callsIn: 97496,
      callsInDuration: 61025,
      callsOut: 69009,
      callsOutDuration: 55320,
      smses: 17937,
    },
    {
      date: "2015-10-31",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1044574,
      accounts: 21937,
      dataSent: 145478,
      dataReceived: 825667,
      callsIn: 100754,
      callsInDuration: 56320,
      callsOut: 68672,
      callsOutDuration: 53670,
      smses: 17219,
    },
    {
      date: "2015-11-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2028076,
      accounts: 21557,
      dataSent: 311012,
      dataReceived: 1708245,
      callsIn: 75060,
      callsInDuration: 42182,
      callsOut: 50960,
      callsOutDuration: 42408,
      smses: 13703,
    },
    {
      date: "2015-11-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2075366,
      accounts: 21559,
      dataSent: 312750,
      dataReceived: 1777266,
      callsIn: 69737,
      callsInDuration: 39998,
      callsOut: 46639,
      callsOutDuration: 38372,
      smses: 12352,
    },
    {
      date: "2015-11-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2057534,
      accounts: 21285,
      dataSent: 293397,
      dataReceived: 1738087,
      callsIn: 64558,
      callsInDuration: 39981,
      callsOut: 43936,
      callsOutDuration: 36744,
      smses: 12588,
    },
    {
      date: "2015-11-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2238804,
      accounts: 22386,
      dataSent: 283071,
      dataReceived: 1649106,
      callsIn: 83536,
      callsInDuration: 56899,
      callsOut: 59933,
      callsOutDuration: 50864,
      smses: 16933,
    },
    {
      date: "2015-11-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2348342,
      accounts: 23059,
      dataSent: 328143,
      dataReceived: 1876841,
      callsIn: 86128,
      callsInDuration: 59589,
      callsOut: 62499,
      callsOutDuration: 54446,
      smses: 17351,
    },
    {
      date: "2015-11-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2226662,
      accounts: 23168,
      dataSent: 301863,
      dataReceived: 1782317,
      callsIn: 96012,
      callsInDuration: 61520,
      callsOut: 69134,
      callsOutDuration: 56687,
      smses: 19061,
    },
    {
      date: "2015-11-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2269528,
      accounts: 23352,
      dataSent: 327315,
      dataReceived: 1901513,
      callsIn: 90950,
      callsInDuration: 54853,
      callsOut: 66651,
      callsOutDuration: 52756,
      smses: 16999,
    },
    {
      date: "2015-11-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2060206,
      accounts: 22394,
      dataSent: 340890,
      dataReceived: 2102403,
      callsIn: 70777,
      callsInDuration: 45444,
      callsOut: 48551,
      callsOutDuration: 40989,
      smses: 13251,
    },
    {
      date: "2015-11-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2128768,
      accounts: 23161,
      dataSent: 333059,
      dataReceived: 2107068,
      callsIn: 90977,
      callsInDuration: 62660,
      callsOut: 62066,
      callsOutDuration: 53427,
      smses: 17109,
    },
    {
      date: "2015-11-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2086414,
      accounts: 23058,
      dataSent: 300758,
      dataReceived: 1961811,
      callsIn: 89902,
      callsInDuration: 64187,
      callsOut: 62237,
      callsOutDuration: 53396,
      smses: 17766,
    },
    {
      date: "2015-11-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2289752,
      accounts: 24052,
      dataSent: 316223,
      dataReceived: 2001835,
      callsIn: 90755,
      callsInDuration: 63224,
      callsOut: 62310,
      callsOutDuration: 53195,
      smses: 17141,
    },
    {
      date: "2015-11-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2073938,
      accounts: 23708,
      dataSent: 303764,
      dataReceived: 1877447,
      callsIn: 93171,
      callsInDuration: 62062,
      callsOut: 63280,
      callsOutDuration: 52497,
      smses: 17236,
    },
    {
      date: "2015-11-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2087620,
      accounts: 24381,
      dataSent: 385559,
      dataReceived: 2470832,
      callsIn: 106592,
      callsInDuration: 70048,
      callsOut: 70015,
      callsOutDuration: 57092,
      smses: 18150,
    },
    {
      date: "2015-11-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2000002,
      accounts: 24256,
      dataSent: 373027,
      dataReceived: 2390365,
      callsIn: 107050,
      callsInDuration: 63275,
      callsOut: 71668,
      callsOutDuration: 55865,
      smses: 17314,
    },
    {
      date: "2015-11-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1865098,
      accounts: 23657,
      dataSent: 366709,
      dataReceived: 2408350,
      callsIn: 80549,
      callsInDuration: 48559,
      callsOut: 52516,
      callsOutDuration: 42764,
      smses: 14388,
    },
    {
      date: "2015-11-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1978424,
      accounts: 24369,
      dataSent: 324262,
      dataReceived: 2013038,
      callsIn: 97156,
      callsInDuration: 67544,
      callsOut: 66227,
      callsOutDuration: 56557,
      smses: 18357,
    },
    {
      date: "2015-11-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2160252,
      accounts: 25184,
      dataSent: 397129,
      dataReceived: 2529895,
      callsIn: 94688,
      callsInDuration: 63112,
      callsOut: 64595,
      callsOutDuration: 54641,
      smses: 18417,
    },
    {
      date: "2015-11-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2148042,
      accounts: 25772,
      dataSent: 376293,
      dataReceived: 2474379,
      callsIn: 95852,
      callsInDuration: 65776,
      callsOut: 65903,
      callsOutDuration: 56926,
      smses: 17974,
    },
    {
      date: "2015-11-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2262506,
      accounts: 25572,
      dataSent: 394895,
      dataReceived: 2343450,
      callsIn: 96970,
      callsInDuration: 65143,
      callsOut: 68081,
      callsOutDuration: 58215,
      smses: 18520,
    },
    {
      date: "2015-11-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2309564,
      accounts: 25559,
      dataSent: 378111,
      dataReceived: 2194907,
      callsIn: 112734,
      callsInDuration: 74045,
      callsOut: 76511,
      callsOutDuration: 62312,
      smses: 19739,
    },
    {
      date: "2015-11-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2406098,
      accounts: 25759,
      dataSent: 368822,
      dataReceived: 2141317,
      callsIn: 111853,
      callsInDuration: 64309,
      callsOut: 74670,
      callsOutDuration: 58708,
      smses: 18088,
    },
    {
      date: "2015-11-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2280994,
      accounts: 24760,
      dataSent: 324364,
      dataReceived: 1866677,
      callsIn: 81273,
      callsInDuration: 49272,
      callsOut: 53758,
      callsOutDuration: 44269,
      smses: 15123,
    },
    {
      date: "2015-11-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2593620,
      accounts: 26466,
      dataSent: 399432,
      dataReceived: 2383417,
      callsIn: 96230,
      callsInDuration: 67782,
      callsOut: 67603,
      callsOutDuration: 58001,
      smses: 18505,
    },
    {
      date: "2015-11-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2539542,
      accounts: 25712,
      dataSent: 330121,
      dataReceived: 1980277,
      callsIn: 98674,
      callsInDuration: 69058,
      callsOut: 67448,
      callsOutDuration: 57691,
      smses: 18756,
    },
    {
      date: "2015-11-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2620292,
      accounts: 26515,
      dataSent: 397199,
      dataReceived: 2323128,
      callsIn: 98458,
      callsInDuration: 69104,
      callsOut: 68331,
      callsOutDuration: 58043,
      smses: 18707,
    },
    {
      date: "2015-11-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2574688,
      accounts: 26298,
      dataSent: 397113,
      dataReceived: 2303364,
      callsIn: 101918,
      callsInDuration: 70209,
      callsOut: 69965,
      callsOutDuration: 59215,
      smses: 18511,
    },
    {
      date: "2015-11-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2523184,
      accounts: 26521,
      dataSent: 374032,
      dataReceived: 2125064,
      callsIn: 124896,
      callsInDuration: 79464,
      callsOut: 81936,
      callsOutDuration: 65530,
      smses: 21818,
    },
    {
      date: "2015-11-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2540424,
      accounts: 26916,
      dataSent: 400840,
      dataReceived: 2264349,
      callsIn: 120972,
      callsInDuration: 71157,
      callsOut: 81124,
      callsOutDuration: 62532,
      smses: 19604,
    },
    {
      date: "2015-11-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2429428,
      accounts: 26682,
      dataSent: 370487,
      dataReceived: 2174954,
      callsIn: 90368,
      callsInDuration: 55951,
      callsOut: 59431,
      callsOutDuration: 49037,
      smses: 17035,
    },
    {
      date: "2015-11-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2686428,
      accounts: 27467,
      dataSent: 368885,
      dataReceived: 2167386,
      callsIn: 107996,
      callsInDuration: 71424,
      callsOut: 76169,
      callsOutDuration: 64065,
      smses: 20442,
    },
    {
      date: "2015-12-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1400085,
      accounts: 28019,
      dataSent: 217206,
      dataReceived: 1254395,
      callsIn: 108204,
      callsInDuration: 73086,
      callsOut: 77119,
      callsOutDuration: 66422,
      smses: 20759,
    },
    {
      date: "2015-12-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1302805,
      accounts: 27786,
      dataSent: 197566,
      dataReceived: 1068154,
      callsIn: 113455,
      callsInDuration: 77237,
      callsOut: 77816,
      callsOutDuration: 65738,
      smses: 20368,
    },
    {
      date: "2015-12-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1340591,
      accounts: 27942,
      dataSent: 194421,
      dataReceived: 1105807,
      callsIn: 117843,
      callsInDuration: 78535,
      callsOut: 81542,
      callsOutDuration: 69106,
      smses: 20998,
    },
    {
      date: "2015-12-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1364619,
      accounts: 28401,
      dataSent: 197199,
      dataReceived: 1105778,
      callsIn: 134657,
      callsInDuration: 86610,
      callsOut: 89219,
      callsOutDuration: 72384,
      smses: 22454,
    },
    {
      date: "2015-12-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1329460,
      accounts: 28309,
      dataSent: 192537,
      dataReceived: 1066027,
      callsIn: 134747,
      callsInDuration: 77875,
      callsOut: 87741,
      callsOutDuration: 67644,
      smses: 21364,
    },
    {
      date: "2015-12-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1234144,
      accounts: 27267,
      dataSent: 165599,
      dataReceived: 947150,
      callsIn: 98695,
      callsInDuration: 60068,
      callsOut: 63419,
      callsOutDuration: 51492,
      smses: 16611,
    },
    {
      date: "2015-12-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1432044,
      accounts: 29028,
      dataSent: 223886,
      dataReceived: 1285150,
      callsIn: 112234,
      callsInDuration: 77303,
      callsOut: 76838,
      callsOutDuration: 65326,
      smses: 20460,
    },
    {
      date: "2015-12-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1380540,
      accounts: 28624,
      dataSent: 197574,
      dataReceived: 1139511,
      callsIn: 114756,
      callsInDuration: 80708,
      callsOut: 76194,
      callsOutDuration: 65631,
      smses: 21022,
    },
    {
      date: "2015-12-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1438725,
      accounts: 28594,
      dataSent: 197616,
      dataReceived: 1162250,
      callsIn: 114199,
      callsInDuration: 81275,
      callsOut: 77418,
      callsOutDuration: 67123,
      smses: 20789,
    },
    {
      date: "2015-12-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1436181,
      accounts: 28505,
      dataSent: 188780,
      dataReceived: 1081028,
      callsIn: 116883,
      callsInDuration: 80885,
      callsOut: 79767,
      callsOutDuration: 67435,
      smses: 20535,
    },
    {
      date: "2015-12-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1467300,
      accounts: 28758,
      dataSent: 196389,
      dataReceived: 1133141,
      callsIn: 132117,
      callsInDuration: 88871,
      callsOut: 89042,
      callsOutDuration: 73160,
      smses: 22487,
    },
    {
      date: "2015-12-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1244131,
      accounts: 28487,
      dataSent: 171390,
      dataReceived: 958479,
      callsIn: 133024,
      callsInDuration: 78769,
      callsOut: 85470,
      callsOutDuration: 65980,
      smses: 20491,
    },
    {
      date: "2015-12-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1334750,
      accounts: 27841,
      dataSent: 194835,
      dataReceived: 1105159,
      callsIn: 101989,
      callsInDuration: 61404,
      callsOut: 63827,
      callsOutDuration: 52128,
      smses: 17210,
    },
    {
      date: "2015-12-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1599520,
      accounts: 29600,
      dataSent: 220740,
      dataReceived: 1249419,
      callsIn: 118660,
      callsInDuration: 83020,
      callsOut: 81017,
      callsOutDuration: 69399,
      smses: 22012,
    },
    {
      date: "2015-12-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1508028,
      accounts: 29205,
      dataSent: 217572,
      dataReceived: 1241260,
      callsIn: 123351,
      callsInDuration: 84904,
      callsOut: 82646,
      callsOutDuration: 69645,
      smses: 22030,
    },
    {
      date: "2015-12-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1496217,
      accounts: 29314,
      dataSent: 208067,
      dataReceived: 1151475,
      callsIn: 125533,
      callsInDuration: 83864,
      callsOut: 84104,
      callsOutDuration: 71379,
      smses: 21691,
    },
    {
      date: "2015-12-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1511562,
      accounts: 29742,
      dataSent: 220193,
      dataReceived: 1215720,
      callsIn: 129608,
      callsInDuration: 86446,
      callsOut: 88366,
      callsOutDuration: 74209,
      smses: 21535,
    },
    {
      date: "2015-12-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1496685,
      accounts: 30073,
      dataSent: 213625,
      dataReceived: 1173782,
      callsIn: 147884,
      callsInDuration: 93098,
      callsOut: 100684,
      callsOutDuration: 81057,
      smses: 23432,
    },
    {
      date: "2015-12-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1456357,
      accounts: 29847,
      dataSent: 184928,
      dataReceived: 1035341,
      callsIn: 153615,
      callsInDuration: 86178,
      callsOut: 103517,
      callsOutDuration: 78482,
      smses: 22441,
    },
    {
      date: "2015-12-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1495266,
      accounts: 30112,
      dataSent: 237704,
      dataReceived: 1251484,
      callsIn: 116597,
      callsInDuration: 65994,
      callsOut: 77064,
      callsOutDuration: 61443,
      smses: 18792,
    },
    {
      date: "2015-12-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1581514,
      accounts: 31329,
      dataSent: 257197,
      dataReceived: 1470566,
      callsIn: 134052,
      callsInDuration: 87735,
      callsOut: 94985,
      callsOutDuration: 79058,
      smses: 23820,
    },
    {
      date: "2015-12-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1482600,
      accounts: 30857,
      dataSent: 227207,
      dataReceived: 1249764,
      callsIn: 142474,
      callsInDuration: 88788,
      callsOut: 100962,
      callsOutDuration: 82168,
      smses: 24392,
    },
    {
      date: "2015-12-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1557961,
      accounts: 31425,
      dataSent: 231263,
      dataReceived: 1266700,
      callsIn: 160564,
      callsInDuration: 96470,
      callsOut: 113024,
      callsOutDuration: 88081,
      smses: 24892,
    },
    {
      date: "2015-12-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1558672,
      accounts: 31994,
      dataSent: 211981,
      dataReceived: 1087776,
      callsIn: 165395,
      callsInDuration: 96763,
      callsOut: 115344,
      callsOutDuration: 91638,
      smses: 24249,
    },
    {
      date: "2015-12-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1749460,
      accounts: 32220,
      dataSent: 297618,
      dataReceived: 1482198,
      callsIn: 119045,
      callsInDuration: 72739,
      callsOut: 78852,
      callsOutDuration: 69016,
      smses: 21309,
    },
    {
      date: "2015-12-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1511301,
      accounts: 31504,
      dataSent: 240372,
      dataReceived: 1299559,
      callsIn: 122866,
      callsInDuration: 72112,
      callsOut: 84390,
      callsOutDuration: 66541,
      smses: 20019,
    },
    {
      date: "2015-12-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1495763,
      accounts: 30922,
      dataSent: 220744,
      dataReceived: 1239346,
      callsIn: 103475,
      callsInDuration: 62220,
      callsOut: 68525,
      callsOutDuration: 56223,
      smses: 18743,
    },
    {
      date: "2015-12-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1675802,
      accounts: 32940,
      dataSent: 271034,
      dataReceived: 1546516,
      callsIn: 129752,
      callsInDuration: 87017,
      callsOut: 90780,
      callsOutDuration: 76563,
      smses: 23405,
    },
    {
      date: "2015-12-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1607262,
      accounts: 32497,
      dataSent: 238597,
      dataReceived: 1337066,
      callsIn: 136026,
      callsInDuration: 88766,
      callsOut: 95455,
      callsOutDuration: 79987,
      smses: 24553,
    },
    {
      date: "2015-12-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1596287,
      accounts: 32882,
      dataSent: 237200,
      dataReceived: 1306784,
      callsIn: 156192,
      callsInDuration: 95106,
      callsOut: 109734,
      callsOutDuration: 87029,
      smses: 26143,
    },
    {
      date: "2015-12-31",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1663491,
      accounts: 33760,
      dataSent: 200276,
      dataReceived: 1005531,
      callsIn: 183089,
      callsInDuration: 99115,
      callsOut: 129217,
      callsOutDuration: 99680,
      smses: 30458,
    },
    {
      date: "2016-01-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1851795,
      accounts: 33093,
      dataSent: 331652,
      dataReceived: 1478725,
      callsIn: 132726,
      callsInDuration: 74347,
      callsOut: 86161,
      callsOutDuration: 74048,
      smses: 24449,
    },
    {
      date: "2016-01-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1521860,
      accounts: 32073,
      dataSent: 258533,
      dataReceived: 1339288,
      callsIn: 112812,
      callsInDuration: 65364,
      callsOut: 72817,
      callsOutDuration: 59005,
      smses: 20526,
    },
    {
      date: "2016-01-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1476215,
      accounts: 31721,
      dataSent: 255396,
      dataReceived: 1373444,
      callsIn: 95873,
      callsInDuration: 59708,
      callsOut: 63720,
      callsOutDuration: 54091,
      smses: 20048,
    },
    {
      date: "2016-01-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1558309,
      accounts: 32689,
      dataSent: 252997,
      dataReceived: 1380034,
      callsIn: 125163,
      callsInDuration: 86816,
      callsOut: 83317,
      callsOutDuration: 71749,
      smses: 23470,
    },
    {
      date: "2016-01-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1574169,
      accounts: 32787,
      dataSent: 254869,
      dataReceived: 1394465,
      callsIn: 126044,
      callsInDuration: 88740,
      callsOut: 85851,
      callsOutDuration: 75960,
      smses: 24754,
    },
    {
      date: "2016-01-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1560780,
      accounts: 32828,
      dataSent: 247643,
      dataReceived: 1361079,
      callsIn: 128382,
      callsInDuration: 86991,
      callsOut: 90321,
      callsOutDuration: 76769,
      smses: 25183,
    },
    {
      date: "2016-01-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1507601,
      accounts: 32338,
      dataSent: 215748,
      dataReceived: 1208044,
      callsIn: 124885,
      callsInDuration: 88420,
      callsOut: 87658,
      callsOutDuration: 76422,
      smses: 23082,
    },
    {
      date: "2016-01-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1624490,
      accounts: 32803,
      dataSent: 253510,
      dataReceived: 1425360,
      callsIn: 144385,
      callsInDuration: 98304,
      callsOut: 96136,
      callsOutDuration: 79246,
      smses: 24308,
    },
    {
      date: "2016-01-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1483389,
      accounts: 31966,
      dataSent: 203082,
      dataReceived: 1173105,
      callsIn: 145108,
      callsInDuration: 86915,
      callsOut: 96253,
      callsOutDuration: 75025,
      smses: 23858,
    },
    {
      date: "2016-01-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1563091,
      accounts: 32450,
      dataSent: 289632,
      dataReceived: 1626475,
      callsIn: 110340,
      callsInDuration: 66669,
      callsOut: 71546,
      callsOutDuration: 58827,
      smses: 19146,
    },
    {
      date: "2016-01-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1604920,
      accounts: 32678,
      dataSent: 255681,
      dataReceived: 1489299,
      callsIn: 127496,
      callsInDuration: 90113,
      callsOut: 87605,
      callsOutDuration: 74186,
      smses: 23643,
    },
    {
      date: "2016-01-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1608054,
      accounts: 32477,
      dataSent: 251856,
      dataReceived: 1425486,
      callsIn: 128280,
      callsInDuration: 91119,
      callsOut: 86670,
      callsOutDuration: 72958,
      smses: 23621,
    },
    {
      date: "2016-01-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1509328,
      accounts: 32105,
      dataSent: 208810,
      dataReceived: 1179860,
      callsIn: 127291,
      callsInDuration: 90073,
      callsOut: 85499,
      callsOutDuration: 72528,
      smses: 23630,
    },
    {
      date: "2016-01-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1697094,
      accounts: 33214,
      dataSent: 272584,
      dataReceived: 1530576,
      callsIn: 129250,
      callsInDuration: 91671,
      callsOut: 87153,
      callsOutDuration: 75419,
      smses: 22993,
    },
    {
      date: "2016-01-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1619292,
      accounts: 33023,
      dataSent: 250158,
      dataReceived: 1394464,
      callsIn: 155949,
      callsInDuration: 105253,
      callsOut: 100603,
      callsOutDuration: 81577,
      smses: 25894,
    },
    {
      date: "2016-01-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1596640,
      accounts: 32673,
      dataSent: 213455,
      dataReceived: 1193790,
      callsIn: 159024,
      callsInDuration: 94578,
      callsOut: 99755,
      callsOutDuration: 76961,
      smses: 24364,
    },
    {
      date: "2016-01-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1591783,
      accounts: 32591,
      dataSent: 255108,
      dataReceived: 1463981,
      callsIn: 114607,
      callsInDuration: 70071,
      callsOut: 70530,
      callsOutDuration: 56691,
      smses: 19121,
    },
    {
      date: "2016-01-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1658936,
      accounts: 33659,
      dataSent: 260559,
      dataReceived: 1471154,
      callsIn: 137309,
      callsInDuration: 95622,
      callsOut: 91496,
      callsOutDuration: 78034,
      smses: 24611,
    },
    {
      date: "2016-01-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1646703,
      accounts: 33510,
      dataSent: 259952,
      dataReceived: 1520558,
      callsIn: 136617,
      callsInDuration: 94182,
      callsOut: 90423,
      callsOutDuration: 75163,
      smses: 24928,
    },
    {
      date: "2016-01-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1605469,
      accounts: 33325,
      dataSent: 250996,
      dataReceived: 1492481,
      callsIn: 134288,
      callsInDuration: 92724,
      callsOut: 90640,
      callsOutDuration: 76258,
      smses: 23761,
    },
    {
      date: "2016-01-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1691494,
      accounts: 33570,
      dataSent: 259512,
      dataReceived: 1505369,
      callsIn: 133907,
      callsInDuration: 92423,
      callsOut: 92124,
      callsOutDuration: 77214,
      smses: 23868,
    },
    {
      date: "2016-01-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1673475,
      accounts: 33539,
      dataSent: 230564,
      dataReceived: 1360839,
      callsIn: 148782,
      callsInDuration: 96953,
      callsOut: 102180,
      callsOutDuration: 83619,
      smses: 25631,
    },
    {
      date: "2016-01-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1828452,
      accounts: 34480,
      dataSent: 242049,
      dataReceived: 1460431,
      callsIn: 149733,
      callsInDuration: 85906,
      callsOut: 103859,
      callsOutDuration: 80816,
      smses: 24340,
    },
    {
      date: "2016-01-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1581233,
      accounts: 32951,
      dataSent: 249224,
      dataReceived: 1504254,
      callsIn: 112232,
      callsInDuration: 69743,
      callsOut: 73333,
      callsOutDuration: 60123,
      smses: 19422,
    },
    {
      date: "2016-01-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1679374,
      accounts: 33934,
      dataSent: 234716,
      dataReceived: 1445462,
      callsIn: 137839,
      callsInDuration: 94568,
      callsOut: 91349,
      callsOutDuration: 77494,
      smses: 25269,
    },
    {
      date: "2016-01-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1743313,
      accounts: 34179,
      dataSent: 236217,
      dataReceived: 1462490,
      callsIn: 137884,
      callsInDuration: 95798,
      callsOut: 94064,
      callsOutDuration: 79084,
      smses: 24495,
    },
    {
      date: "2016-01-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1763210,
      accounts: 34431,
      dataSent: 261749,
      dataReceived: 1632703,
      callsIn: 137455,
      callsInDuration: 98011,
      callsOut: 93498,
      callsOutDuration: 79338,
      smses: 23705,
    },
    {
      date: "2016-01-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1680020,
      accounts: 34059,
      dataSent: 240796,
      dataReceived: 1515090,
      callsIn: 138075,
      callsInDuration: 97206,
      callsOut: 94051,
      callsOutDuration: 79734,
      smses: 24311,
    },
    {
      date: "2016-01-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1751115,
      accounts: 34697,
      dataSent: 247162,
      dataReceived: 1495341,
      callsIn: 168321,
      callsInDuration: 108258,
      callsOut: 109709,
      callsOutDuration: 87648,
      smses: 27675,
    },
    {
      date: "2016-01-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1641882,
      accounts: 34642,
      dataSent: 214971,
      dataReceived: 1283425,
      callsIn: 156921,
      callsInDuration: 92532,
      callsOut: 99970,
      callsOutDuration: 78234,
      smses: 22750,
    },
    {
      date: "2016-01-31",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3434238,
      accounts: 34473,
      dataSent: 555643,
      dataReceived: 3416148,
      callsIn: 123580,
      callsInDuration: 76213,
      callsOut: 78208,
      callsOutDuration: 64234,
      smses: 21160,
    },
    {
      date: "2016-02-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1696855,
      accounts: 35124,
      dataSent: 247114,
      dataReceived: 1520067,
      callsIn: 150354,
      callsInDuration: 102644,
      callsOut: 100497,
      callsOutDuration: 84984,
      smses: 25224,
    },
    {
      date: "2016-02-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1778809,
      accounts: 35592,
      dataSent: 258666,
      dataReceived: 1587419,
      callsIn: 152625,
      callsInDuration: 106617,
      callsOut: 102146,
      callsOutDuration: 87035,
      smses: 26410,
    },
    {
      date: "2016-02-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1751204,
      accounts: 35856,
      dataSent: 251550,
      dataReceived: 1565543,
      callsIn: 152164,
      callsInDuration: 106034,
      callsOut: 102095,
      callsOutDuration: 86626,
      smses: 26228,
    },
    {
      date: "2016-02-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1801475,
      accounts: 35713,
      dataSent: 255342,
      dataReceived: 1634199,
      callsIn: 154268,
      callsInDuration: 105419,
      callsOut: 103372,
      callsOutDuration: 88130,
      smses: 26621,
    },
    {
      date: "2016-02-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1854690,
      accounts: 37062,
      dataSent: 247046,
      dataReceived: 1471537,
      callsIn: 187037,
      callsInDuration: 122283,
      callsOut: 123411,
      callsOutDuration: 98092,
      smses: 28916,
    },
    {
      date: "2016-02-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1863291,
      accounts: 37369,
      dataSent: 259633,
      dataReceived: 1526721,
      callsIn: 181866,
      callsInDuration: 105197,
      callsOut: 116167,
      callsOutDuration: 90987,
      smses: 26883,
    },
    {
      date: "2016-02-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1903324,
      accounts: 36783,
      dataSent: 308248,
      dataReceived: 1743116,
      callsIn: 146199,
      callsInDuration: 80679,
      callsOut: 88271,
      callsOutDuration: 69611,
      smses: 22808,
    },
    {
      date: "2016-02-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1687457,
      accounts: 35103,
      dataSent: 267607,
      dataReceived: 1538693,
      callsIn: 129357,
      callsInDuration: 77474,
      callsOut: 76147,
      callsOutDuration: 62217,
      smses: 20347,
    },
    {
      date: "2016-02-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1631827,
      accounts: 34286,
      dataSent: 263996,
      dataReceived: 1548517,
      callsIn: 114392,
      callsInDuration: 72694,
      callsOut: 66567,
      callsOutDuration: 56284,
      smses: 18849,
    },
    {
      date: "2016-02-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1761760,
      accounts: 36249,
      dataSent: 262113,
      dataReceived: 1597926,
      callsIn: 153008,
      callsInDuration: 108152,
      callsOut: 95930,
      callsOutDuration: 81652,
      smses: 25027,
    },
    {
      date: "2016-02-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1769093,
      accounts: 36324,
      dataSent: 258458,
      dataReceived: 1555758,
      callsIn: 152620,
      callsInDuration: 108911,
      callsOut: 99475,
      callsOutDuration: 85433,
      smses: 26124,
    },
    {
      date: "2016-02-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1756861,
      accounts: 36469,
      dataSent: 251154,
      dataReceived: 1453184,
      callsIn: 178398,
      callsInDuration: 125763,
      callsOut: 110826,
      callsOutDuration: 91261,
      smses: 28378,
    },
    {
      date: "2016-02-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1737010,
      accounts: 36333,
      dataSent: 216300,
      dataReceived: 1271556,
      callsIn: 184365,
      callsInDuration: 113863,
      callsOut: 108544,
      callsOutDuration: 85329,
      smses: 26270,
    },
    {
      date: "2016-02-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1797892,
      accounts: 36419,
      dataSent: 303762,
      dataReceived: 1749447,
      callsIn: 144710,
      callsInDuration: 88344,
      callsOut: 86076,
      callsOutDuration: 70437,
      smses: 24911,
    },
    {
      date: "2016-02-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1823502,
      accounts: 36595,
      dataSent: 272883,
      dataReceived: 1606978,
      callsIn: 161700,
      callsInDuration: 115579,
      callsOut: 102152,
      callsOutDuration: 86459,
      smses: 26700,
    },
    {
      date: "2016-02-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1847111,
      accounts: 36832,
      dataSent: 269947,
      dataReceived: 1583679,
      callsIn: 158804,
      callsInDuration: 112521,
      callsOut: 101640,
      callsOutDuration: 86076,
      smses: 27101,
    },
    {
      date: "2016-02-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1885430,
      accounts: 37047,
      dataSent: 263105,
      dataReceived: 1604922,
      callsIn: 156740,
      callsInDuration: 110468,
      callsOut: 102172,
      callsOutDuration: 86695,
      smses: 26265,
    },
    {
      date: "2016-02-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1832355,
      accounts: 36913,
      dataSent: 258136,
      dataReceived: 1577097,
      callsIn: 156480,
      callsInDuration: 108148,
      callsOut: 104389,
      callsOutDuration: 89025,
      smses: 25948,
    },
    {
      date: "2016-02-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1816953,
      accounts: 37123,
      dataSent: 250392,
      dataReceived: 1517512,
      callsIn: 178004,
      callsInDuration: 119544,
      callsOut: 114677,
      callsOutDuration: 95118,
      smses: 28166,
    },
    {
      date: "2016-02-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1826996,
      accounts: 36928,
      dataSent: 226680,
      dataReceived: 1357929,
      callsIn: 175747,
      callsInDuration: 108370,
      callsOut: 110334,
      callsOutDuration: 87349,
      smses: 26524,
    },
    {
      date: "2016-02-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1878996,
      accounts: 36841,
      dataSent: 288993,
      dataReceived: 1734762,
      callsIn: 130155,
      callsInDuration: 79987,
      callsOut: 81973,
      callsOutDuration: 67943,
      smses: 21582,
    },
    {
      date: "2016-02-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1831194,
      accounts: 37204,
      dataSent: 258384,
      dataReceived: 1582839,
      callsIn: 156301,
      callsInDuration: 107231,
      callsOut: 104309,
      callsOutDuration: 89021,
      smses: 25824,
    },
    {
      date: "2016-02-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1876021,
      accounts: 36973,
      dataSent: 225122,
      dataReceived: 1344686,
      callsIn: 154979,
      callsInDuration: 107816,
      callsOut: 103139,
      callsOutDuration: 87759,
      smses: 26110,
    },
    {
      date: "2016-02-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1992981,
      accounts: 37791,
      dataSent: 294085,
      dataReceived: 1761975,
      callsIn: 155680,
      callsInDuration: 113528,
      callsOut: 101524,
      callsOutDuration: 87033,
      smses: 26724,
    },
    {
      date: "2016-02-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1837354,
      accounts: 37070,
      dataSent: 250191,
      dataReceived: 1519604,
      callsIn: 156971,
      callsInDuration: 111647,
      callsOut: 102916,
      callsOutDuration: 86158,
      smses: 26409,
    },
    {
      date: "2016-02-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1912404,
      accounts: 37486,
      dataSent: 239109,
      dataReceived: 1356677,
      callsIn: 181266,
      callsInDuration: 120968,
      callsOut: 116081,
      callsOutDuration: 93721,
      smses: 27995,
    },
    {
      date: "2016-02-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2029302,
      accounts: 38534,
      dataSent: 273902,
      dataReceived: 1589074,
      callsIn: 180816,
      callsInDuration: 109399,
      callsOut: 111981,
      callsOutDuration: 86187,
      smses: 26294,
    },
    {
      date: "2016-02-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1970778,
      accounts: 37291,
      dataSent: 266505,
      dataReceived: 1550214,
      callsIn: 131610,
      callsInDuration: 83935,
      callsOut: 83140,
      callsOutDuration: 68519,
      smses: 21578,
    },
    {
      date: "2016-02-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2085126,
      accounts: 38878,
      dataSent: 300561,
      dataReceived: 1736377,
      callsIn: 165315,
      callsInDuration: 114675,
      callsOut: 110025,
      callsOutDuration: 91747,
      smses: 26553,
    },
    {
      date: "2016-03-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1964604,
      accounts: 38612,
      dataSent: 272334,
      dataReceived: 1604886,
      callsIn: 161116,
      callsInDuration: 112614,
      callsOut: 110565,
      callsOutDuration: 91854,
      smses: 27580,
    },
    {
      date: "2016-03-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1961510,
      accounts: 38713,
      dataSent: 273208,
      dataReceived: 1633534,
      callsIn: 167913,
      callsInDuration: 117706,
      callsOut: 111592,
      callsOutDuration: 92595,
      smses: 27378,
    },
    {
      date: "2016-03-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1977997,
      accounts: 38725,
      dataSent: 276256,
      dataReceived: 1673838,
      callsIn: 164122,
      callsInDuration: 114166,
      callsOut: 108694,
      callsOutDuration: 92535,
      smses: 27253,
    },
    {
      date: "2016-03-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1969782,
      accounts: 39073,
      dataSent: 237820,
      dataReceived: 1407891,
      callsIn: 195277,
      callsInDuration: 128211,
      callsOut: 125662,
      callsOutDuration: 100613,
      smses: 28629,
    },
    {
      date: "2016-03-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2106425,
      accounts: 40043,
      dataSent: 279815,
      dataReceived: 1632142,
      callsIn: 197046,
      callsInDuration: 116436,
      callsOut: 123172,
      callsOutDuration: 95516,
      smses: 28094,
    },
    {
      date: "2016-03-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2145050,
      accounts: 39367,
      dataSent: 315061,
      dataReceived: 1862039,
      callsIn: 141388,
      callsInDuration: 85056,
      callsOut: 87897,
      callsOutDuration: 71741,
      smses: 22034,
    },
    {
      date: "2016-03-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2125033,
      accounts: 39700,
      dataSent: 276873,
      dataReceived: 1709210,
      callsIn: 169221,
      callsInDuration: 118021,
      callsOut: 112504,
      callsOutDuration: 94461,
      smses: 28361,
    },
    {
      date: "2016-03-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1970276,
      accounts: 39734,
      dataSent: 264181,
      dataReceived: 1573174,
      callsIn: 174785,
      callsInDuration: 122129,
      callsOut: 112853,
      callsOutDuration: 94858,
      smses: 30165,
    },
    {
      date: "2016-03-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1977079,
      accounts: 39764,
      dataSent: 268709,
      dataReceived: 1632553,
      callsIn: 166667,
      callsInDuration: 122068,
      callsOut: 111324,
      callsOutDuration: 94678,
      smses: 27259,
    },
    {
      date: "2016-03-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2093071,
      accounts: 39854,
      dataSent: 274137,
      dataReceived: 1674677,
      callsIn: 167783,
      callsInDuration: 121148,
      callsOut: 111756,
      callsOutDuration: 94563,
      smses: 27888,
    },
    {
      date: "2016-03-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2121354,
      accounts: 39982,
      dataSent: 252860,
      dataReceived: 1544665,
      callsIn: 188618,
      callsInDuration: 128748,
      callsOut: 123083,
      callsOutDuration: 100807,
      smses: 30194,
    },
    {
      date: "2016-03-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2134806,
      accounts: 40082,
      dataSent: 252077,
      dataReceived: 1517595,
      callsIn: 191429,
      callsInDuration: 116343,
      callsOut: 120471,
      callsOutDuration: 92733,
      smses: 27441,
    },
    {
      date: "2016-03-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2361850,
      accounts: 39777,
      dataSent: 253229,
      dataReceived: 1487796,
      callsIn: 137184,
      callsInDuration: 85006,
      callsOut: 87163,
      callsOutDuration: 70422,
      smses: 23040,
    },
    {
      date: "2016-03-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2267999,
      accounts: 40934,
      dataSent: 307240,
      dataReceived: 1875478,
      callsIn: 171478,
      callsInDuration: 120566,
      callsOut: 112674,
      callsOutDuration: 94512,
      smses: 27976,
    },
    {
      date: "2016-03-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2135087,
      accounts: 40448,
      dataSent: 283624,
      dataReceived: 1720266,
      callsIn: 170387,
      callsInDuration: 122303,
      callsOut: 113180,
      callsOutDuration: 94990,
      smses: 28012,
    },
    {
      date: "2016-03-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2124483,
      accounts: 40066,
      dataSent: 237312,
      dataReceived: 1519040,
      callsIn: 173005,
      callsInDuration: 122895,
      callsOut: 113240,
      callsOutDuration: 95709,
      smses: 28315,
    },
    {
      date: "2016-03-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2373806,
      accounts: 41136,
      dataSent: 307385,
      dataReceived: 1901346,
      callsIn: 174065,
      callsInDuration: 121876,
      callsOut: 114661,
      callsOutDuration: 96376,
      smses: 28143,
    },
    {
      date: "2016-03-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2262771,
      accounts: 41132,
      dataSent: 278027,
      dataReceived: 1695170,
      callsIn: 198592,
      callsInDuration: 132447,
      callsOut: 125909,
      callsOutDuration: 101867,
      smses: 29035,
    },
    {
      date: "2016-03-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2312519,
      accounts: 41335,
      dataSent: 275071,
      dataReceived: 1601645,
      callsIn: 199234,
      callsInDuration: 118926,
      callsOut: 124964,
      callsOutDuration: 96117,
      smses: 28413,
    },
    {
      date: "2016-03-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2202739,
      accounts: 40639,
      dataSent: 283169,
      dataReceived: 1691888,
      callsIn: 149793,
      callsInDuration: 90523,
      callsOut: 94724,
      callsOutDuration: 75278,
      smses: 25337,
    },
    {
      date: "2016-03-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2243508,
      accounts: 41606,
      dataSent: 293409,
      dataReceived: 1755780,
      callsIn: 174726,
      callsInDuration: 119628,
      callsOut: 115163,
      callsOutDuration: 97350,
      smses: 28963,
    },
    {
      date: "2016-03-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2223088,
      accounts: 41518,
      dataSent: 278899,
      dataReceived: 1686747,
      callsIn: 174447,
      callsInDuration: 120126,
      callsOut: 116739,
      callsOutDuration: 97845,
      smses: 28959,
    },
    {
      date: "2016-03-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2166951,
      accounts: 41652,
      dataSent: 282656,
      dataReceived: 1688724,
      callsIn: 177868,
      callsInDuration: 124958,
      callsOut: 121216,
      callsOutDuration: 100545,
      smses: 27888,
    },
    {
      date: "2016-03-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2202834,
      accounts: 41979,
      dataSent: 279452,
      dataReceived: 1628724,
      callsIn: 197080,
      callsInDuration: 128583,
      callsOut: 125343,
      callsOutDuration: 99343,
      smses: 29721,
    },
    {
      date: "2016-03-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2081765,
      accounts: 41330,
      dataSent: 276168,
      dataReceived: 1669861,
      callsIn: 144515,
      callsInDuration: 94300,
      callsOut: 91352,
      callsOutDuration: 75058,
      smses: 23845,
    },
    {
      date: "2016-03-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2228943,
      accounts: 41437,
      dataSent: 289022,
      dataReceived: 1700806,
      callsIn: 165254,
      callsInDuration: 98431,
      callsOut: 106900,
      callsOutDuration: 82047,
      smses: 26138,
    },
    {
      date: "2016-03-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2150574,
      accounts: 40880,
      dataSent: 299712,
      dataReceived: 1736374,
      callsIn: 137486,
      callsInDuration: 85272,
      callsOut: 87598,
      callsOutDuration: 70254,
      smses: 23736,
    },
    {
      date: "2016-03-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2176225,
      accounts: 42143,
      dataSent: 282653,
      dataReceived: 1656526,
      callsIn: 173738,
      callsInDuration: 118873,
      callsOut: 116175,
      callsOutDuration: 96406,
      smses: 29002,
    },
    {
      date: "2016-03-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2276695,
      accounts: 42013,
      dataSent: 301207,
      dataReceived: 1768006,
      callsIn: 167819,
      callsInDuration: 115549,
      callsOut: 112038,
      callsOutDuration: 93472,
      smses: 28507,
    },
    {
      date: "2016-03-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2194537,
      accounts: 41796,
      dataSent: 281187,
      dataReceived: 1748707,
      callsIn: 167242,
      callsInDuration: 117804,
      callsOut: 113013,
      callsOutDuration: 96288,
      smses: 27406,
    },
    {
      date: "2016-03-31",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2083074,
      accounts: 41823,
      dataSent: 265858,
      dataReceived: 1612078,
      callsIn: 173252,
      callsInDuration: 120713,
      callsOut: 117683,
      callsOutDuration: 98110,
      smses: 28243,
    },
    {
      date: "2016-04-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2323322,
      accounts: 42925,
      dataSent: 292072,
      dataReceived: 1839772,
      callsIn: 200111,
      callsInDuration: 129203,
      callsOut: 135691,
      callsOutDuration: 110010,
      smses: 31049,
    },
    {
      date: "2016-04-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2370964,
      accounts: 43105,
      dataSent: 304679,
      dataReceived: 1880025,
      callsIn: 201622,
      callsInDuration: 120043,
      callsOut: 133561,
      callsOutDuration: 102537,
      smses: 29852,
    },
    {
      date: "2016-04-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2199283,
      accounts: 41854,
      dataSent: 273631,
      dataReceived: 1678956,
      callsIn: 156256,
      callsInDuration: 94169,
      callsOut: 102658,
      callsOutDuration: 82927,
      smses: 24274,
    },
    {
      date: "2016-04-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 564766,
      accounts: 31498,
      dataSent: 63869,
      dataReceived: 426148,
      callsIn: 43332,
      callsInDuration: 34599,
      callsOut: 28895,
      callsOutDuration: 28027,
      smses: 9621,
    },
    {
      date: "2016-04-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 466582,
      accounts: 32885,
      dataSent: 88447,
      dataReceived: 558563,
      callsIn: 40805,
      callsInDuration: 36065,
      callsOut: 26919,
      callsOutDuration: 28058,
      smses: 8815,
    },
    {
      date: "2016-04-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2378986,
      accounts: 43692,
      dataSent: 308458,
      dataReceived: 1937190,
      callsIn: 177901,
      callsInDuration: 122416,
      callsOut: 122442,
      callsOutDuration: 102918,
      smses: 28427,
    },
    {
      date: "2016-04-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2346950,
      accounts: 43722,
      dataSent: 299782,
      dataReceived: 1857850,
      callsIn: 178062,
      callsInDuration: 124614,
      callsOut: 124517,
      callsOutDuration: 105981,
      smses: 29279,
    },
    {
      date: "2016-04-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2399012,
      accounts: 44184,
      dataSent: 296956,
      dataReceived: 1827519,
      callsIn: 211384,
      callsInDuration: 148550,
      callsOut: 132524,
      callsOutDuration: 107984,
      smses: 31749,
    },
    {
      date: "2016-04-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2371521,
      accounts: 44176,
      dataSent: 299944,
      dataReceived: 1831425,
      callsIn: 215123,
      callsInDuration: 132203,
      callsOut: 129916,
      callsOutDuration: 100241,
      smses: 29382,
    },
    {
      date: "2016-04-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2252928,
      accounts: 43221,
      dataSent: 302532,
      dataReceived: 1894994,
      callsIn: 158813,
      callsInDuration: 100270,
      callsOut: 97076,
      callsOutDuration: 78425,
      smses: 24582,
    },
    {
      date: "2016-04-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2337453,
      accounts: 44254,
      dataSent: 308993,
      dataReceived: 1935364,
      callsIn: 189895,
      callsInDuration: 132944,
      callsOut: 122150,
      callsOutDuration: 102402,
      smses: 29404,
    },
    {
      date: "2016-04-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2402306,
      accounts: 44199,
      dataSent: 303619,
      dataReceived: 1871456,
      callsIn: 187417,
      callsInDuration: 132871,
      callsOut: 121870,
      callsOutDuration: 102462,
      smses: 30174,
    },
    {
      date: "2016-04-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2314671,
      accounts: 44341,
      dataSent: 289150,
      dataReceived: 1766946,
      callsIn: 187688,
      callsInDuration: 132062,
      callsOut: 123447,
      callsOutDuration: 102705,
      smses: 29188,
    },
    {
      date: "2016-04-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2449173,
      accounts: 44958,
      dataSent: 309376,
      dataReceived: 1849098,
      callsIn: 189362,
      callsInDuration: 133088,
      callsOut: 125411,
      callsOutDuration: 105559,
      smses: 28849,
    },
    {
      date: "2016-04-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2348222,
      accounts: 45282,
      dataSent: 299588,
      dataReceived: 1814091,
      callsIn: 222691,
      callsInDuration: 151030,
      callsOut: 142562,
      callsOutDuration: 114794,
      smses: 32758,
    },
    {
      date: "2016-04-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2439644,
      accounts: 51958,
      dataSent: 278492,
      dataReceived: 1605152,
      callsIn: 300276,
      callsInDuration: 183400,
      callsOut: 197112,
      callsOutDuration: 157565,
      smses: 42320,
    },
    {
      date: "2016-04-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2394090,
      accounts: 45096,
      dataSent: 270365,
      dataReceived: 1622041,
      callsIn: 207432,
      callsInDuration: 121557,
      callsOut: 115931,
      callsOutDuration: 104873,
      smses: 9856,
    },
    {
      date: "2016-04-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2354654,
      accounts: 46630,
      dataSent: 317781,
      dataReceived: 2087144,
      callsIn: 238636,
      callsInDuration: 201203,
      callsOut: 146270,
      callsOutDuration: 151307,
      smses: 65141,
    },
    {
      date: "2016-04-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2450025,
      accounts: 46920,
      dataSent: 321366,
      dataReceived: 2071386,
      callsIn: 224631,
      callsInDuration: 185815,
      callsOut: 147669,
      callsOutDuration: 146700,
      smses: 73247,
    },
    {
      date: "2016-04-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2519885,
      accounts: 46872,
      dataSent: 321333,
      dataReceived: 2024298,
      callsIn: 222354,
      callsInDuration: 181404,
      callsOut: 150775,
      callsOutDuration: 144339,
      smses: 62780,
    },
    {
      date: "2016-04-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2556167,
      accounts: 48024,
      dataSent: 339282,
      dataReceived: 2148414,
      callsIn: 241181,
      callsInDuration: 191601,
      callsOut: 162982,
      callsOutDuration: 168288,
      smses: 63332,
    },
    {
      date: "2016-04-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2481487,
      accounts: 47989,
      dataSent: 326708,
      dataReceived: 2047534,
      callsIn: 219803,
      callsInDuration: 147411,
      callsOut: 162765,
      callsOutDuration: 160840,
      smses: 58193,
    },
    {
      date: "2016-04-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2426185,
      accounts: 46954,
      dataSent: 314539,
      dataReceived: 2005549,
      callsIn: 183294,
      callsInDuration: 137689,
      callsOut: 124325,
      callsOutDuration: 126146,
      smses: 47159,
    },
    {
      date: "2016-04-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 896429,
      accounts: 46223,
      dataSent: 115834,
      dataReceived: 745742,
      callsIn: 224625,
      callsInDuration: 178939,
      callsOut: 155037,
      callsOutDuration: 151647,
      smses: 52652,
    },
    {
      date: "2016-04-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2598090,
      accounts: 48033,
      dataSent: 301623,
      dataReceived: 1948465,
      callsIn: 217895,
      callsInDuration: 176377,
      callsOut: 145066,
      callsOutDuration: 142410,
      smses: 49848,
    },
    {
      date: "2016-04-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2626410,
      accounts: 48903,
      dataSent: 313822,
      dataReceived: 1974900,
      callsIn: 205107,
      callsInDuration: 153825,
      callsOut: 150196,
      callsOutDuration: 151334,
      smses: 50395,
    },
    {
      date: "2016-04-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2754261,
      accounts: 48935,
      dataSent: 374191,
      dataReceived: 2356761,
      callsIn: 203428,
      callsInDuration: 152287,
      callsOut: 152334,
      callsOutDuration: 150410,
      smses: 43863,
    },
    {
      date: "2016-04-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2633224,
      accounts: 48416,
      dataSent: 299736,
      dataReceived: 1860543,
      callsIn: 240039,
      callsInDuration: 171729,
      callsOut: 173766,
      callsOutDuration: 159447,
      smses: 46283,
    },
    {
      date: "2016-04-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2901606,
      accounts: 49986,
      dataSent: 384499,
      dataReceived: 2348583,
      callsIn: 247912,
      callsInDuration: 156209,
      callsOut: 172931,
      callsOutDuration: 151934,
      smses: 40653,
    },
    {
      date: "2016-05-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2493554,
      accounts: 48311,
      dataSent: 315970,
      dataReceived: 1996772,
      callsIn: 185677,
      callsInDuration: 110767,
      callsOut: 132778,
      callsOutDuration: 120556,
      smses: 33229,
    },
    {
      date: "2016-05-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2802236,
      accounts: 50421,
      dataSent: 381067,
      dataReceived: 2373039,
      callsIn: 226531,
      callsInDuration: 153543,
      callsOut: 166843,
      callsOutDuration: 149636,
      smses: 39721,
    },
    {
      date: "2016-05-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2599682,
      accounts: 49257,
      dataSent: 314048,
      dataReceived: 1964406,
      callsIn: 215731,
      callsInDuration: 147156,
      callsOut: 159475,
      callsOutDuration: 143857,
      smses: 39650,
    },
    {
      date: "2016-05-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 708316,
      accounts: 37905,
      dataSent: 72251,
      dataReceived: 466468,
      callsIn: 51719,
      callsInDuration: 41462,
      callsOut: 37848,
      callsOutDuration: 39075,
      smses: 12662,
    },
    {
      date: "2016-05-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2985212,
      accounts: 50570,
      dataSent: 344156,
      dataReceived: 2098540,
      callsIn: 210946,
      callsInDuration: 143829,
      callsOut: 157187,
      callsOutDuration: 140447,
      smses: 37311,
    },
    {
      date: "2016-05-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2966304,
      accounts: 51657,
      dataSent: 385876,
      dataReceived: 2364373,
      callsIn: 251562,
      callsInDuration: 166619,
      callsOut: 177067,
      callsOutDuration: 149860,
      smses: 39892,
    },
    {
      date: "2016-05-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2633374,
      accounts: 51178,
      dataSent: 338507,
      dataReceived: 2055566,
      callsIn: 260970,
      callsInDuration: 156668,
      callsOut: 179467,
      callsOutDuration: 144239,
      smses: 38120,
    },
    {
      date: "2016-05-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2665924,
      accounts: 50830,
      dataSent: 370777,
      dataReceived: 2107846,
      callsIn: 214458,
      callsInDuration: 138418,
      callsOut: 153765,
      callsOutDuration: 139564,
      smses: 35411,
    },
    {
      date: "2016-05-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1611215,
      accounts: 48376,
      dataSent: 197524,
      dataReceived: 1182333,
      callsIn: 226140,
      callsInDuration: 160186,
      callsOut: 160031,
      callsOutDuration: 141558,
      smses: 37561,
    },
    {
      date: "2016-05-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1378369,
      accounts: 47457,
      dataSent: 184108,
      dataReceived: 1103892,
      callsIn: 214316,
      callsInDuration: 156627,
      callsOut: 157207,
      callsOutDuration: 141855,
      smses: 35414,
    },
    {
      date: "2016-05-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1365006,
      accounts: 47571,
      dataSent: 183390,
      dataReceived: 1108452,
      callsIn: 217152,
      callsInDuration: 153810,
      callsOut: 156843,
      callsOutDuration: 138387,
      smses: 34998,
    },
    {
      date: "2016-05-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1367238,
      accounts: 47375,
      dataSent: 178192,
      dataReceived: 1110357,
      callsIn: 215367,
      callsInDuration: 158122,
      callsOut: 154276,
      callsOutDuration: 140067,
      smses: 35005,
    },
    {
      date: "2016-05-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1402562,
      accounts: 50410,
      dataSent: 184818,
      dataReceived: 1154433,
      callsIn: 247129,
      callsInDuration: 172434,
      callsOut: 173817,
      callsOutDuration: 151571,
      smses: 37324,
    },
    {
      date: "2016-05-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1378429,
      accounts: 50879,
      dataSent: 173862,
      dataReceived: 1098678,
      callsIn: 250995,
      callsInDuration: 161714,
      callsOut: 172075,
      callsOutDuration: 146336,
      smses: 34955,
    },
    {
      date: "2016-05-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 1348892,
      accounts: 45236,
      dataSent: 181872,
      dataReceived: 1107687,
      callsIn: 202180,
      callsInDuration: 132221,
      callsOut: 135248,
      callsOutDuration: 121877,
      smses: 29544,
    },
    {
      date: "2016-05-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 995920,
      accounts: 49533,
      dataSent: 118832,
      dataReceived: 771917,
      callsIn: 228419,
      callsInDuration: 169269,
      callsOut: 159384,
      callsOutDuration: 147505,
      smses: 33674,
    },
    {
      date: "2016-05-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1000235,
      accounts: 49013,
      dataSent: 114779,
      dataReceived: 778627,
      callsIn: 222885,
      callsInDuration: 166944,
      callsOut: 155088,
      callsOutDuration: 146449,
      smses: 33423,
    },
    {
      date: "2016-05-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 1020196,
      accounts: 56687,
      dataSent: 118030,
      dataReceived: 767529,
      callsIn: 270861,
      callsInDuration: 200485,
      callsOut: 199259,
      callsOutDuration: 187985,
      smses: 36789,
    },
    {
      date: "2016-05-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 1000667,
      accounts: 49898,
      dataSent: 114997,
      dataReceived: 731376,
      callsIn: 222359,
      callsInDuration: 168586,
      callsOut: 154000,
      callsOutDuration: 147320,
      smses: 31321,
    },
    {
      date: "2016-05-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 1043115,
      accounts: 53275,
      dataSent: 121746,
      dataReceived: 767851,
      callsIn: 258752,
      callsInDuration: 178560,
      callsOut: 175034,
      callsOutDuration: 153538,
      smses: 34095,
    },
    {
      date: "2016-05-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 997174,
      accounts: 53040,
      dataSent: 119039,
      dataReceived: 767110,
      callsIn: 258275,
      callsInDuration: 166226,
      callsOut: 171036,
      callsOutDuration: 144284,
      smses: 31800,
    },
    {
      date: "2016-05-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 928108,
      accounts: 45896,
      dataSent: 114114,
      dataReceived: 749416,
      callsIn: 197177,
      callsInDuration: 136929,
      callsOut: 131678,
      callsOutDuration: 122813,
      smses: 25826,
    },
    {
      date: "2016-05-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 948962,
      accounts: 51246,
      dataSent: 118727,
      dataReceived: 755649,
      callsIn: 230013,
      callsInDuration: 175832,
      callsOut: 160643,
      callsOutDuration: 152729,
      smses: 31820,
    },
    {
      date: "2016-05-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 942960,
      accounts: 50626,
      dataSent: 118584,
      dataReceived: 754075,
      callsIn: 224545,
      callsInDuration: 172403,
      callsOut: 150094,
      callsOutDuration: 139364,
      smses: 30071,
    },
    {
      date: "2016-05-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2914680,
      accounts: 63707,
      dataSent: 371323,
      dataReceived: 2496147,
      callsIn: 231222,
      callsInDuration: 179017,
      callsOut: 157998,
      callsOutDuration: 149341,
      smses: 34433,
    },
    {
      date: "2016-05-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2558466,
      accounts: 60753,
      dataSent: 325041,
      dataReceived: 2100168,
      callsIn: 259442,
      callsInDuration: 191054,
      callsOut: 179955,
      callsOutDuration: 162493,
      smses: 38081,
    },
    {
      date: "2016-05-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2639460,
      accounts: 58752,
      dataSent: 334951,
      dataReceived: 2161219,
      callsIn: 236280,
      callsInDuration: 166945,
      callsOut: 165965,
      callsOutDuration: 150005,
      smses: 33318,
    },
    {
      date: "2016-05-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2751556,
      accounts: 59087,
      dataSent: 344116,
      dataReceived: 2202586,
      callsIn: 243266,
      callsInDuration: 158022,
      callsOut: 171158,
      callsOutDuration: 144612,
      smses: 33808,
    },
    {
      date: "2016-05-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2863591,
      accounts: 58179,
      dataSent: 359313,
      dataReceived: 2336948,
      callsIn: 194107,
      callsInDuration: 135097,
      callsOut: 136892,
      callsOutDuration: 125419,
      smses: 29837,
    },
    {
      date: "2016-05-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3067457,
      accounts: 59974,
      dataSent: 364481,
      dataReceived: 2306471,
      callsIn: 237311,
      callsInDuration: 181373,
      callsOut: 174091,
      callsOutDuration: 164011,
      smses: 36381,
    },
    {
      date: "2016-05-31",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3157728,
      accounts: 60049,
      dataSent: 349186,
      dataReceived: 2206140,
      callsIn: 241145,
      callsInDuration: 182545,
      callsOut: 180119,
      callsOutDuration: 165021,
      smses: 37067,
    },
    {
      date: "2016-06-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3480566,
      accounts: 61202,
      dataSent: 435728,
      dataReceived: 2612641,
      callsIn: 252033,
      callsInDuration: 188022,
      callsOut: 185705,
      callsOutDuration: 170917,
      smses: 37879,
    },
    {
      date: "2016-06-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2960338,
      accounts: 60098,
      dataSent: 362866,
      dataReceived: 2290970,
      callsIn: 246826,
      callsInDuration: 193064,
      callsOut: 179466,
      callsOutDuration: 170638,
      smses: 37268,
    },
    {
      date: "2016-06-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3056589,
      accounts: 60858,
      dataSent: 389068,
      dataReceived: 2339743,
      callsIn: 291052,
      callsInDuration: 215416,
      callsOut: 201462,
      callsOutDuration: 178983,
      smses: 40197,
    },
    {
      date: "2016-06-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3032460,
      accounts: 60836,
      dataSent: 379066,
      dataReceived: 2306475,
      callsIn: 295032,
      callsInDuration: 186148,
      callsOut: 197696,
      callsOutDuration: 160322,
      smses: 36549,
    },
    {
      date: "2016-06-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2889835,
      accounts: 59115,
      dataSent: 379670,
      dataReceived: 2314921,
      callsIn: 220787,
      callsInDuration: 155646,
      callsOut: 148427,
      callsOutDuration: 137023,
      smses: 31810,
    },
    {
      date: "2016-06-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3057354,
      accounts: 60684,
      dataSent: 363429,
      dataReceived: 2178903,
      callsIn: 249639,
      callsInDuration: 194813,
      callsOut: 181021,
      callsOutDuration: 171195,
      smses: 36793,
    },
    {
      date: "2016-06-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3513782,
      accounts: 62100,
      dataSent: 429015,
      dataReceived: 2563473,
      callsIn: 248252,
      callsInDuration: 193284,
      callsOut: 181392,
      callsOutDuration: 169393,
      smses: 38635,
    },
    {
      date: "2016-06-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3216336,
      accounts: 60959,
      dataSent: 400526,
      dataReceived: 2369228,
      callsIn: 254089,
      callsInDuration: 200355,
      callsOut: 177242,
      callsOutDuration: 168511,
      smses: 37766,
    },
    {
      date: "2016-06-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3035272,
      accounts: 60773,
      dataSent: 379954,
      dataReceived: 2228388,
      callsIn: 254911,
      callsInDuration: 203490,
      callsOut: 176096,
      callsOutDuration: 167776,
      smses: 36483,
    },
    {
      date: "2016-06-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3220255,
      accounts: 61012,
      dataSent: 382238,
      dataReceived: 2299734,
      callsIn: 289747,
      callsInDuration: 211840,
      callsOut: 200475,
      callsOutDuration: 178505,
      smses: 38901,
    },
    {
      date: "2016-06-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 1537227,
      accounts: 55772,
      dataSent: 186156,
      dataReceived: 1139303,
      callsIn: 175412,
      callsInDuration: 120123,
      callsOut: 120105,
      callsOutDuration: 106179,
      smses: 24036,
    },
    {
      date: "2016-06-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2965340,
      accounts: 59409,
      dataSent: 370946,
      dataReceived: 2266434,
      callsIn: 220625,
      callsInDuration: 159071,
      callsOut: 149159,
      callsOutDuration: 138973,
      smses: 31922,
    },
    {
      date: "2016-06-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3126790,
      accounts: 60220,
      dataSent: 339407,
      dataReceived: 2043414,
      callsIn: 255839,
      callsInDuration: 205335,
      callsOut: 178683,
      callsOutDuration: 170904,
      smses: 37707,
    },
    {
      date: "2016-06-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3341648,
      accounts: 61547,
      dataSent: 399400,
      dataReceived: 2450036,
      callsIn: 255700,
      callsInDuration: 206178,
      callsOut: 180015,
      callsOutDuration: 170818,
      smses: 37595,
    },
    {
      date: "2016-06-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3292885,
      accounts: 61505,
      dataSent: 412172,
      dataReceived: 2485615,
      callsIn: 262055,
      callsInDuration: 209896,
      callsOut: 179624,
      callsOutDuration: 172903,
      smses: 37077,
    },
    {
      date: "2016-06-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3162227,
      accounts: 60855,
      dataSent: 355556,
      dataReceived: 2156551,
      callsIn: 270676,
      callsInDuration: 210023,
      callsOut: 185229,
      callsOutDuration: 176494,
      smses: 37679,
    },
    {
      date: "2016-06-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3452026,
      accounts: 62023,
      dataSent: 405304,
      dataReceived: 2433135,
      callsIn: 306092,
      callsInDuration: 220798,
      callsOut: 204667,
      callsOutDuration: 180022,
      smses: 38477,
    },
    {
      date: "2016-06-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3247749,
      accounts: 62003,
      dataSent: 392984,
      dataReceived: 2324587,
      callsIn: 313788,
      callsInDuration: 199228,
      callsOut: 206220,
      callsOutDuration: 172791,
      smses: 37245,
    },
    {
      date: "2016-06-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3098235,
      accounts: 60855,
      dataSent: 375229,
      dataReceived: 2122112,
      callsIn: 246933,
      callsInDuration: 169710,
      callsOut: 168517,
      callsOutDuration: 158351,
      smses: 35164,
    },
    {
      date: "2016-06-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3697439,
      accounts: 63388,
      dataSent: 426183,
      dataReceived: 2538736,
      callsIn: 265761,
      callsInDuration: 205950,
      callsOut: 184659,
      callsOutDuration: 177907,
      smses: 39577,
    },
    {
      date: "2016-06-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3155287,
      accounts: 61834,
      dataSent: 357416,
      dataReceived: 2199627,
      callsIn: 255976,
      callsInDuration: 203899,
      callsOut: 180348,
      callsOutDuration: 174913,
      smses: 36622,
    },
    {
      date: "2016-06-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3621729,
      accounts: 63066,
      dataSent: 445751,
      dataReceived: 2760091,
      callsIn: 252772,
      callsInDuration: 201528,
      callsOut: 178184,
      callsOutDuration: 173783,
      smses: 36663,
    },
    {
      date: "2016-06-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3210668,
      accounts: 62176,
      dataSent: 390654,
      dataReceived: 2371213,
      callsIn: 250231,
      callsInDuration: 204894,
      callsOut: 182483,
      callsOutDuration: 178258,
      smses: 37192,
    },
    {
      date: "2016-06-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3373504,
      accounts: 63179,
      dataSent: 401910,
      dataReceived: 2395430,
      callsIn: 297955,
      callsInDuration: 228802,
      callsOut: 204287,
      callsOutDuration: 188789,
      smses: 39773,
    },
    {
      date: "2016-06-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3350150,
      accounts: 63299,
      dataSent: 421378,
      dataReceived: 2440870,
      callsIn: 302804,
      callsInDuration: 200639,
      callsOut: 203500,
      callsOutDuration: 173810,
      smses: 38183,
    },
    {
      date: "2016-06-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3127082,
      accounts: 61202,
      dataSent: 405443,
      dataReceived: 2481695,
      callsIn: 220341,
      callsInDuration: 167124,
      callsOut: 148436,
      callsOutDuration: 145041,
      smses: 30604,
    },
    {
      date: "2016-06-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3299322,
      accounts: 63404,
      dataSent: 405359,
      dataReceived: 2487636,
      callsIn: 260179,
      callsInDuration: 214875,
      callsOut: 182916,
      callsOutDuration: 181338,
      smses: 37909,
    },
    {
      date: "2016-06-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3236081,
      accounts: 63208,
      dataSent: 400908,
      dataReceived: 2402874,
      callsIn: 257523,
      callsInDuration: 213358,
      callsOut: 184427,
      callsOutDuration: 181993,
      smses: 38380,
    },
    {
      date: "2016-06-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3279846,
      accounts: 63233,
      dataSent: 403361,
      dataReceived: 2443602,
      callsIn: 261036,
      callsInDuration: 212341,
      callsOut: 185778,
      callsOutDuration: 182709,
      smses: 37551,
    },
    {
      date: "2016-06-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3350843,
      accounts: 63482,
      dataSent: 401492,
      dataReceived: 2420163,
      callsIn: 269406,
      callsInDuration: 214529,
      callsOut: 191577,
      callsOutDuration: 185921,
      smses: 37316,
    },
    {
      date: "2016-07-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3368108,
      accounts: 65073,
      dataSent: 401797,
      dataReceived: 2411911,
      callsIn: 322584,
      callsInDuration: 241831,
      callsOut: 221935,
      callsOutDuration: 201474,
      smses: 42172,
    },
    {
      date: "2016-07-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3341020,
      accounts: 64843,
      dataSent: 403664,
      dataReceived: 2389622,
      callsIn: 333165,
      callsInDuration: 225624,
      callsOut: 216845,
      callsOutDuration: 185261,
      smses: 39795,
    },
    {
      date: "2016-07-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3226324,
      accounts: 63336,
      dataSent: 419929,
      dataReceived: 2546198,
      callsIn: 253245,
      callsInDuration: 182667,
      callsOut: 161347,
      callsOutDuration: 152493,
      smses: 33171,
    },
    {
      date: "2016-07-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3427700,
      accounts: 64934,
      dataSent: 417570,
      dataReceived: 2538336,
      callsIn: 292720,
      callsInDuration: 231041,
      callsOut: 198294,
      callsOutDuration: 190104,
      smses: 39684,
    },
    {
      date: "2016-07-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3222968,
      accounts: 64700,
      dataSent: 389090,
      dataReceived: 2394245,
      callsIn: 281523,
      callsInDuration: 224790,
      callsOut: 197766,
      callsOutDuration: 191724,
      smses: 39543,
    },
    {
      date: "2016-07-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3430070,
      accounts: 65013,
      dataSent: 432872,
      dataReceived: 2618059,
      callsIn: 274019,
      callsInDuration: 220922,
      callsOut: 196549,
      callsOutDuration: 191220,
      smses: 38908,
    },
    {
      date: "2016-07-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3461418,
      accounts: 65191,
      dataSent: 432192,
      dataReceived: 2588643,
      callsIn: 273249,
      callsInDuration: 220720,
      callsOut: 200012,
      callsOutDuration: 194635,
      smses: 38885,
    },
    {
      date: "2016-07-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3337828,
      accounts: 66228,
      dataSent: 488214,
      dataReceived: 2501524,
      callsIn: 328840,
      callsInDuration: 251950,
      callsOut: 223770,
      callsOutDuration: 205331,
      smses: 42190,
    },
    {
      date: "2016-07-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3328715,
      accounts: 66043,
      dataSent: 492613,
      dataReceived: 2535457,
      callsIn: 336183,
      callsInDuration: 234513,
      callsOut: 217370,
      callsOutDuration: 190828,
      smses: 39835,
    },
    {
      date: "2016-07-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3193593,
      accounts: 64498,
      dataSent: 408288,
      dataReceived: 2502738,
      callsIn: 269537,
      callsInDuration: 202974,
      callsOut: 179000,
      callsOutDuration: 172433,
      smses: 36160,
    },
    {
      date: "2016-07-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3370839,
      accounts: 65297,
      dataSent: 391985,
      dataReceived: 2414902,
      callsIn: 289625,
      callsInDuration: 236620,
      callsOut: 196574,
      callsOutDuration: 193644,
      smses: 39340,
    },
    {
      date: "2016-07-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3595830,
      accounts: 66229,
      dataSent: 475957,
      dataReceived: 2929830,
      callsIn: 280131,
      callsInDuration: 231374,
      callsOut: 195738,
      callsOutDuration: 195434,
      smses: 38531,
    },
    {
      date: "2016-07-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3343827,
      accounts: 65369,
      dataSent: 422044,
      dataReceived: 2564739,
      callsIn: 276998,
      callsInDuration: 225819,
      callsOut: 189011,
      callsOutDuration: 185917,
      smses: 37667,
    },
    {
      date: "2016-07-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3334036,
      accounts: 65476,
      dataSent: 415136,
      dataReceived: 2570788,
      callsIn: 280343,
      callsInDuration: 227897,
      callsOut: 197650,
      callsOutDuration: 194345,
      smses: 38148,
    },
    {
      date: "2016-07-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3288842,
      accounts: 66693,
      dataSent: 404992,
      dataReceived: 2462300,
      callsIn: 340379,
      callsInDuration: 259387,
      callsOut: 225629,
      callsOutDuration: 207230,
      smses: 43147,
    },
    {
      date: "2016-07-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3188945,
      accounts: 68025,
      dataSent: 357020,
      dataReceived: 2187470,
      callsIn: 358838,
      callsInDuration: 242134,
      callsOut: 229496,
      callsOutDuration: 196234,
      smses: 41159,
    },
    {
      date: "2016-07-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3209970,
      accounts: 65908,
      dataSent: 442226,
      dataReceived: 2773441,
      callsIn: 264485,
      callsInDuration: 192816,
      callsOut: 167886,
      callsOutDuration: 159381,
      smses: 33298,
    },
    {
      date: "2016-07-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3304714,
      accounts: 67351,
      dataSent: 427090,
      dataReceived: 2638700,
      callsIn: 303315,
      callsInDuration: 243227,
      callsOut: 202962,
      callsOutDuration: 198279,
      smses: 40545,
    },
    {
      date: "2016-07-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3392354,
      accounts: 67020,
      dataSent: 423269,
      dataReceived: 2672666,
      callsIn: 285830,
      callsInDuration: 236224,
      callsOut: 198829,
      callsOutDuration: 199130,
      smses: 40285,
    },
    {
      date: "2016-07-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3358389,
      accounts: 66934,
      dataSent: 424376,
      dataReceived: 2670679,
      callsIn: 284247,
      callsInDuration: 230885,
      callsOut: 202330,
      callsOutDuration: 200579,
      smses: 39783,
    },
    {
      date: "2016-07-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3468761,
      accounts: 67497,
      dataSent: 443064,
      dataReceived: 2731204,
      callsIn: 289060,
      callsInDuration: 232768,
      callsOut: 211191,
      callsOutDuration: 206968,
      smses: 39764,
    },
    {
      date: "2016-07-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3571056,
      accounts: 68463,
      dataSent: 452745,
      dataReceived: 2728763,
      callsIn: 335036,
      callsInDuration: 253034,
      callsOut: 231061,
      callsOutDuration: 209021,
      smses: 43322,
    },
    {
      date: "2016-07-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3601810,
      accounts: 68617,
      dataSent: 468698,
      dataReceived: 2704801,
      callsIn: 347913,
      callsInDuration: 237321,
      callsOut: 227218,
      callsOutDuration: 196144,
      smses: 41368,
    },
    {
      date: "2016-07-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3375605,
      accounts: 66595,
      dataSent: 483270,
      dataReceived: 2809563,
      callsIn: 269938,
      callsInDuration: 199658,
      callsOut: 172969,
      callsOutDuration: 164446,
      smses: 34115,
    },
    {
      date: "2016-07-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3503940,
      accounts: 67512,
      dataSent: 432044,
      dataReceived: 2743267,
      callsIn: 289440,
      callsInDuration: 230463,
      callsOut: 193071,
      callsOutDuration: 189377,
      smses: 39040,
    },
    {
      date: "2016-07-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3568033,
      accounts: 68023,
      dataSent: 411969,
      dataReceived: 2679513,
      callsIn: 293399,
      callsInDuration: 245091,
      callsOut: 199607,
      callsOutDuration: 199734,
      smses: 40145,
    },
    {
      date: "2016-07-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3590621,
      accounts: 68146,
      dataSent: 409879,
      dataReceived: 2686528,
      callsIn: 292620,
      callsInDuration: 243354,
      callsOut: 202145,
      callsOutDuration: 200678,
      smses: 40312,
    },
    {
      date: "2016-07-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3767858,
      accounts: 68529,
      dataSent: 406557,
      dataReceived: 2683060,
      callsIn: 293529,
      callsInDuration: 248467,
      callsOut: 207432,
      callsOutDuration: 209552,
      smses: 39942,
    },
    {
      date: "2016-07-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3824403,
      accounts: 68958,
      dataSent: 409429,
      dataReceived: 2670317,
      callsIn: 344486,
      callsInDuration: 268066,
      callsOut: 234482,
      callsOutDuration: 221638,
      smses: 42449,
    },
    {
      date: "2016-07-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3807032,
      accounts: 68992,
      dataSent: 379534,
      dataReceived: 2380684,
      callsIn: 352649,
      callsInDuration: 236805,
      callsOut: 236654,
      callsOutDuration: 202173,
      smses: 42004,
    },
    {
      date: "2016-07-31",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3859988,
      accounts: 68279,
      dataSent: 442762,
      dataReceived: 2807673,
      callsIn: 264226,
      callsInDuration: 202493,
      callsOut: 177727,
      callsOutDuration: 176782,
      smses: 34295,
    },
    {
      date: "2016-08-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4224860,
      accounts: 70083,
      dataSent: 423400,
      dataReceived: 2655280,
      callsIn: 308719,
      callsInDuration: 254363,
      callsOut: 219674,
      callsOutDuration: 218772,
      smses: 42545,
    },
    {
      date: "2016-08-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3705086,
      accounts: 69386,
      dataSent: 376280,
      dataReceived: 2343861,
      callsIn: 308959,
      callsInDuration: 255533,
      callsOut: 214547,
      callsOutDuration: 214724,
      smses: 41615,
    },
    {
      date: "2016-08-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3785656,
      accounts: 69780,
      dataSent: 363724,
      dataReceived: 2200506,
      callsIn: 302081,
      callsInDuration: 256939,
      callsOut: 205681,
      callsOutDuration: 209465,
      smses: 40690,
    },
    {
      date: "2016-08-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3967176,
      accounts: 71202,
      dataSent: 395132,
      dataReceived: 2377544,
      callsIn: 301384,
      callsInDuration: 252135,
      callsOut: 213399,
      callsOutDuration: 215389,
      smses: 40307,
    },
    {
      date: "2016-08-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3776571,
      accounts: 71984,
      dataSent: 363614,
      dataReceived: 2052732,
      callsIn: 352482,
      callsInDuration: 268552,
      callsOut: 241095,
      callsOutDuration: 224707,
      smses: 43853,
    },
    {
      date: "2016-08-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3748443,
      accounts: 72619,
      dataSent: 356087,
      dataReceived: 1988790,
      callsIn: 354791,
      callsInDuration: 241523,
      callsOut: 239895,
      callsOutDuration: 211598,
      smses: 41005,
    },
    {
      date: "2016-08-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3502073,
      accounts: 71422,
      dataSent: 328043,
      dataReceived: 1770847,
      callsIn: 267170,
      callsInDuration: 204409,
      callsOut: 184726,
      callsOutDuration: 183840,
      smses: 35316,
    },
    {
      date: "2016-08-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3499404,
      accounts: 73312,
      dataSent: 315391,
      dataReceived: 1657776,
      callsIn: 316694,
      callsInDuration: 260712,
      callsOut: 222354,
      callsOutDuration: 224590,
      smses: 41630,
    },
    {
      date: "2016-08-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3643531,
      accounts: 73197,
      dataSent: 322212,
      dataReceived: 1695763,
      callsIn: 304160,
      callsInDuration: 265540,
      callsOut: 215008,
      callsOutDuration: 225600,
      smses: 41076,
    },
    {
      date: "2016-08-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3540462,
      accounts: 72654,
      dataSent: 297718,
      dataReceived: 1572538,
      callsIn: 310841,
      callsInDuration: 265521,
      callsOut: 217371,
      callsOutDuration: 224650,
      smses: 40541,
    },
    {
      date: "2016-08-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3577330,
      accounts: 73602,
      dataSent: 300431,
      dataReceived: 1588240,
      callsIn: 335474,
      callsInDuration: 270537,
      callsOut: 232971,
      callsOutDuration: 231564,
      smses: 42428,
    },
    {
      date: "2016-08-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3525908,
      accounts: 73557,
      dataSent: 322494,
      dataReceived: 1751403,
      callsIn: 309739,
      callsInDuration: 233057,
      callsOut: 217175,
      callsOutDuration: 206611,
      smses: 39392,
    },
    {
      date: "2016-08-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3499510,
      accounts: 73677,
      dataSent: 329071,
      dataReceived: 1811132,
      callsIn: 323191,
      callsInDuration: 230012,
      callsOut: 224332,
      callsOutDuration: 206206,
      smses: 38710,
    },
    {
      date: "2016-08-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3263177,
      accounts: 72466,
      dataSent: 323284,
      dataReceived: 1805363,
      callsIn: 261600,
      callsInDuration: 200277,
      callsOut: 178581,
      callsOutDuration: 178598,
      smses: 35236,
    },
    {
      date: "2016-08-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3328851,
      accounts: 74217,
      dataSent: 320906,
      dataReceived: 1819528,
      callsIn: 318148,
      callsInDuration: 268001,
      callsOut: 222864,
      callsOutDuration: 226550,
      smses: 42167,
    },
    {
      date: "2016-08-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3367810,
      accounts: 73873,
      dataSent: 322022,
      dataReceived: 1874229,
      callsIn: 315890,
      callsInDuration: 266450,
      callsOut: 219516,
      callsOutDuration: 224875,
      smses: 42355,
    },
    {
      date: "2016-08-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3300269,
      accounts: 74048,
      dataSent: 302377,
      dataReceived: 1737269,
      callsIn: 315082,
      callsInDuration: 269208,
      callsOut: 221650,
      callsOutDuration: 226319,
      smses: 41401,
    },
    {
      date: "2016-08-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3609035,
      accounts: 74652,
      dataSent: 359816,
      dataReceived: 2092280,
      callsIn: 314003,
      callsInDuration: 270475,
      callsOut: 223592,
      callsOutDuration: 230843,
      smses: 42587,
    },
    {
      date: "2016-08-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3263888,
      accounts: 74211,
      dataSent: 328896,
      dataReceived: 1840367,
      callsIn: 362094,
      callsInDuration: 289764,
      callsOut: 246885,
      callsOutDuration: 238607,
      smses: 44335,
    },
    {
      date: "2016-08-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2565083,
      accounts: 72921,
      dataSent: 231068,
      dataReceived: 1352618,
      callsIn: 368835,
      callsInDuration: 257547,
      callsOut: 245345,
      callsOutDuration: 221481,
      smses: 42805,
    },
    {
      date: "2016-08-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3123305,
      accounts: 72631,
      dataSent: 332603,
      dataReceived: 1935205,
      callsIn: 272606,
      callsInDuration: 212227,
      callsOut: 186626,
      callsOutDuration: 189848,
      smses: 34406,
    },
    {
      date: "2016-08-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3171880,
      accounts: 73873,
      dataSent: 316575,
      dataReceived: 1850163,
      callsIn: 316978,
      callsInDuration: 267503,
      callsOut: 227414,
      callsOutDuration: 232424,
      smses: 40940,
    },
    {
      date: "2016-08-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3099690,
      accounts: 73372,
      dataSent: 294932,
      dataReceived: 1640254,
      callsIn: 308445,
      callsInDuration: 269022,
      callsOut: 220934,
      callsOutDuration: 231345,
      smses: 40641,
    },
    {
      date: "2016-08-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3073367,
      accounts: 73746,
      dataSent: 313324,
      dataReceived: 1913513,
      callsIn: 326270,
      callsInDuration: 286598,
      callsOut: 226958,
      callsOutDuration: 238692,
      smses: 44153,
    },
    {
      date: "2016-08-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2323934,
      accounts: 70766,
      dataSent: 246017,
      dataReceived: 1521217,
      callsIn: 328526,
      callsInDuration: 285292,
      callsOut: 227022,
      callsOutDuration: 234344,
      smses: 24451,
    },
    {
      date: "2016-08-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2902280,
      accounts: 73682,
      dataSent: 321713,
      dataReceived: 1864458,
      callsIn: 369991,
      callsInDuration: 300676,
      callsOut: 245968,
      callsOutDuration: 240224,
      smses: 43192,
    },
    {
      date: "2016-08-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2859589,
      accounts: 74752,
      dataSent: 277993,
      dataReceived: 1517433,
      callsIn: 378967,
      callsInDuration: 268000,
      callsOut: 248393,
      callsOutDuration: 223960,
      smses: 41511,
    },
    {
      date: "2016-08-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3044135,
      accounts: 71290,
      dataSent: 341561,
      dataReceived: 1847771,
      callsIn: 282049,
      callsInDuration: 227088,
      callsOut: 186666,
      callsOutDuration: 191146,
      smses: 35348,
    },
    {
      date: "2016-08-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2949215,
      accounts: 72129,
      dataSent: 292124,
      dataReceived: 1579023,
      callsIn: 329760,
      callsInDuration: 279426,
      callsOut: 225789,
      callsOutDuration: 229842,
      smses: 41014,
    },
    {
      date: "2016-08-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3028689,
      accounts: 72548,
      dataSent: 288338,
      dataReceived: 1560220,
      callsIn: 320133,
      callsInDuration: 282529,
      callsOut: 221824,
      callsOutDuration: 230788,
      smses: 41681,
    },
    {
      date: "2016-08-31",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2910610,
      accounts: 72183,
      dataSent: 269609,
      dataReceived: 1422526,
      callsIn: 331630,
      callsInDuration: 292449,
      callsOut: 232347,
      callsOutDuration: 243178,
      smses: 42735,
    },
    {
      date: "2016-09-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2919992,
      accounts: 72799,
      dataSent: 267356,
      dataReceived: 1422188,
      callsIn: 343857,
      callsInDuration: 286578,
      callsOut: 238718,
      callsOutDuration: 240990,
      smses: 42763,
    },
    {
      date: "2016-09-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3234742,
      accounts: 77476,
      dataSent: 304845,
      dataReceived: 1625687,
      callsIn: 399931,
      callsInDuration: 323769,
      callsOut: 267291,
      callsOutDuration: 258590,
      smses: 46264,
    },
    {
      date: "2016-09-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3081171,
      accounts: 76924,
      dataSent: 283642,
      dataReceived: 1525004,
      callsIn: 403602,
      callsInDuration: 281882,
      callsOut: 266248,
      callsOutDuration: 235869,
      smses: 44030,
    },
    {
      date: "2016-09-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2827202,
      accounts: 71774,
      dataSent: 285649,
      dataReceived: 1539216,
      callsIn: 304479,
      callsInDuration: 236155,
      callsOut: 203349,
      callsOutDuration: 202126,
      smses: 37301,
    },
    {
      date: "2016-09-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2965053,
      accounts: 74380,
      dataSent: 273472,
      dataReceived: 1453894,
      callsIn: 347669,
      callsInDuration: 299258,
      callsOut: 241527,
      callsOutDuration: 246996,
      smses: 43512,
    },
    {
      date: "2016-09-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3062868,
      accounts: 73940,
      dataSent: 306205,
      dataReceived: 1613804,
      callsIn: 332450,
      callsInDuration: 286876,
      callsOut: 236115,
      callsOutDuration: 242401,
      smses: 43094,
    },
    {
      date: "2016-09-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2963649,
      accounts: 73411,
      dataSent: 272408,
      dataReceived: 1427400,
      callsIn: 327602,
      callsInDuration: 297708,
      callsOut: 234856,
      callsOutDuration: 250039,
      smses: 42573,
    },
    {
      date: "2016-09-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3108028,
      accounts: 74818,
      dataSent: 282083,
      dataReceived: 1474700,
      callsIn: 340511,
      callsInDuration: 306337,
      callsOut: 240206,
      callsOutDuration: 250092,
      smses: 41882,
    },
    {
      date: "2016-09-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3110835,
      accounts: 76631,
      dataSent: 295579,
      dataReceived: 1538205,
      callsIn: 386770,
      callsInDuration: 323750,
      callsOut: 262364,
      callsOutDuration: 256232,
      smses: 45680,
    },
    {
      date: "2016-09-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3126490,
      accounts: 76804,
      dataSent: 306296,
      dataReceived: 1543096,
      callsIn: 395502,
      callsInDuration: 288281,
      callsOut: 261740,
      callsOutDuration: 237490,
      smses: 44011,
    },
    {
      date: "2016-09-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3043734,
      accounts: 72365,
      dataSent: 293177,
      dataReceived: 1498154,
      callsIn: 295529,
      callsInDuration: 241146,
      callsOut: 193198,
      callsOutDuration: 199870,
      smses: 36517,
    },
    {
      date: "2016-09-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2922779,
      accounts: 73069,
      dataSent: 221999,
      dataReceived: 1128187,
      callsIn: 343539,
      callsInDuration: 299715,
      callsOut: 220918,
      callsOutDuration: 223808,
      smses: 39616,
    },
    {
      date: "2016-09-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3429403,
      accounts: 75042,
      dataSent: 322078,
      dataReceived: 1693232,
      callsIn: 336868,
      callsInDuration: 306645,
      callsOut: 223661,
      callsOutDuration: 241249,
      smses: 42830,
    },
    {
      date: "2016-09-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3116036,
      accounts: 74121,
      dataSent: 276665,
      dataReceived: 1439041,
      callsIn: 328744,
      callsInDuration: 299066,
      callsOut: 224689,
      callsOutDuration: 239402,
      smses: 43591,
    },
    {
      date: "2016-09-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3043430,
      accounts: 73006,
      dataSent: 228969,
      dataReceived: 1200334,
      callsIn: 337306,
      callsInDuration: 299754,
      callsOut: 228277,
      callsOutDuration: 237231,
      smses: 40843,
    },
    {
      date: "2016-09-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3448960,
      accounts: 77860,
      dataSent: 322113,
      dataReceived: 1696411,
      callsIn: 393813,
      callsInDuration: 317681,
      callsOut: 262220,
      callsOutDuration: 250806,
      smses: 46234,
    },
    {
      date: "2016-09-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3136468,
      accounts: 78336,
      dataSent: 248367,
      dataReceived: 1282957,
      callsIn: 402666,
      callsInDuration: 295455,
      callsOut: 261014,
      callsOutDuration: 239787,
      smses: 44137,
    },
    {
      date: "2016-09-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3273905,
      accounts: 73853,
      dataSent: 336079,
      dataReceived: 1783983,
      callsIn: 299608,
      callsInDuration: 241044,
      callsOut: 194985,
      callsOutDuration: 202432,
      smses: 35557,
    },
    {
      date: "2016-09-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2889511,
      accounts: 74402,
      dataSent: 262060,
      dataReceived: 1436473,
      callsIn: 341956,
      callsInDuration: 304233,
      callsOut: 229984,
      callsOutDuration: 241451,
      smses: 41775,
    },
    {
      date: "2016-09-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3069650,
      accounts: 75113,
      dataSent: 267722,
      dataReceived: 1529291,
      callsIn: 334290,
      callsInDuration: 302355,
      callsOut: 231148,
      callsOutDuration: 244158,
      smses: 43057,
    },
    {
      date: "2016-09-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3166908,
      accounts: 75207,
      dataSent: 278937,
      dataReceived: 1642376,
      callsIn: 337739,
      callsInDuration: 303812,
      callsOut: 237904,
      callsOutDuration: 252414,
      smses: 42195,
    },
    {
      date: "2016-09-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3257123,
      accounts: 74929,
      dataSent: 277749,
      dataReceived: 1570690,
      callsIn: 335280,
      callsInDuration: 306075,
      callsOut: 235904,
      callsOutDuration: 248664,
      smses: 41671,
    },
    {
      date: "2016-09-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3132311,
      accounts: 77298,
      dataSent: 268340,
      dataReceived: 1472161,
      callsIn: 380728,
      callsInDuration: 316178,
      callsOut: 261854,
      callsOutDuration: 255533,
      smses: 44752,
    },
    {
      date: "2016-09-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3190135,
      accounts: 77461,
      dataSent: 277352,
      dataReceived: 1505188,
      callsIn: 384355,
      callsInDuration: 291095,
      callsOut: 258824,
      callsOutDuration: 238052,
      smses: 40529,
    },
    {
      date: "2016-09-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3073594,
      accounts: 73603,
      dataSent: 263961,
      dataReceived: 1423885,
      callsIn: 294462,
      callsInDuration: 248087,
      callsOut: 197595,
      callsOutDuration: 204366,
      smses: 34899,
    },
    {
      date: "2016-09-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 1541090,
      accounts: 75734,
      dataSent: 134419,
      dataReceived: 724754,
      callsIn: 353208,
      callsInDuration: 313341,
      callsOut: 241126,
      callsOutDuration: 249622,
      smses: 26920,
    },
    {
      date: "2016-09-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3142790,
      accounts: 75682,
      dataSent: 273171,
      dataReceived: 1457661,
      callsIn: 332692,
      callsInDuration: 312433,
      callsOut: 227283,
      callsOutDuration: 244003,
      smses: 41731,
    },
    {
      date: "2016-09-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3167727,
      accounts: 75696,
      dataSent: 263608,
      dataReceived: 1380241,
      callsIn: 333410,
      callsInDuration: 310605,
      callsOut: 231314,
      callsOutDuration: 242760,
      smses: 41604,
    },
    {
      date: "2016-09-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3232288,
      accounts: 75578,
      dataSent: 236677,
      dataReceived: 1242804,
      callsIn: 339972,
      callsInDuration: 313515,
      callsOut: 235205,
      callsOutDuration: 246255,
      smses: 41592,
    },
    {
      date: "2016-09-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3632088,
      accounts: 81271,
      dataSent: 275548,
      dataReceived: 1432345,
      callsIn: 412571,
      callsInDuration: 331609,
      callsOut: 277725,
      callsOutDuration: 258634,
      smses: 58958,
    },
    {
      date: "2016-10-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3331792,
      accounts: 81346,
      dataSent: 297977,
      dataReceived: 1553228,
      callsIn: 425440,
      callsInDuration: 311450,
      callsOut: 278671,
      callsOutDuration: 251693,
      smses: 45124,
    },
    {
      date: "2016-10-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3225942,
      accounts: 75799,
      dataSent: 294122,
      dataReceived: 1510198,
      callsIn: 326531,
      callsInDuration: 259639,
      callsOut: 210121,
      callsOutDuration: 208683,
      smses: 37847,
    },
    {
      date: "2016-10-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3288109,
      accounts: 79577,
      dataSent: 290544,
      dataReceived: 1498391,
      callsIn: 386130,
      callsInDuration: 333024,
      callsOut: 258742,
      callsOutDuration: 261313,
      smses: 45730,
    },
    {
      date: "2016-10-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3270468,
      accounts: 77989,
      dataSent: 295394,
      dataReceived: 1546359,
      callsIn: 367916,
      callsInDuration: 323558,
      callsOut: 249947,
      callsOutDuration: 256557,
      smses: 44824,
    },
    {
      date: "2016-10-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3228815,
      accounts: 77830,
      dataSent: 295224,
      dataReceived: 1518740,
      callsIn: 373463,
      callsInDuration: 336886,
      callsOut: 252385,
      callsOutDuration: 266019,
      smses: 44663,
    },
    {
      date: "2016-10-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3325902,
      accounts: 77570,
      dataSent: 290365,
      dataReceived: 1574037,
      callsIn: 366451,
      callsInDuration: 317891,
      callsOut: 253484,
      callsOutDuration: 258232,
      smses: 44102,
    },
    {
      date: "2016-10-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3504883,
      accounts: 81126,
      dataSent: 260329,
      dataReceived: 1387670,
      callsIn: 406326,
      callsInDuration: 344530,
      callsOut: 276317,
      callsOutDuration: 274714,
      smses: 47215,
    },
    {
      date: "2016-10-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3754197,
      accounts: 81527,
      dataSent: 350301,
      dataReceived: 1808888,
      callsIn: 415115,
      callsInDuration: 315664,
      callsOut: 271796,
      callsOutDuration: 250972,
      smses: 46360,
    },
    {
      date: "2016-10-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3293955,
      accounts: 77247,
      dataSent: 291443,
      dataReceived: 1506730,
      callsIn: 321485,
      callsInDuration: 261656,
      callsOut: 207791,
      callsOutDuration: 208632,
      smses: 37326,
    },
    {
      date: "2016-10-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3314910,
      accounts: 79079,
      dataSent: 286426,
      dataReceived: 1569401,
      callsIn: 370943,
      callsInDuration: 336990,
      callsOut: 247314,
      callsOutDuration: 256279,
      smses: 44702,
    },
    {
      date: "2016-10-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3367228,
      accounts: 78439,
      dataSent: 274131,
      dataReceived: 1535002,
      callsIn: 353259,
      callsInDuration: 319109,
      callsOut: 240575,
      callsOutDuration: 249785,
      smses: 44241,
    },
    {
      date: "2016-10-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3409405,
      accounts: 78916,
      dataSent: 274505,
      dataReceived: 1480185,
      callsIn: 359005,
      callsInDuration: 337274,
      callsOut: 241661,
      callsOutDuration: 254586,
      smses: 42046,
    },
    {
      date: "2016-10-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3509067,
      accounts: 79081,
      dataSent: 302931,
      dataReceived: 1653753,
      callsIn: 355087,
      callsInDuration: 327074,
      callsOut: 242061,
      callsOutDuration: 249057,
      smses: 42832,
    },
    {
      date: "2016-10-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3558485,
      accounts: 82943,
      dataSent: 299557,
      dataReceived: 1595388,
      callsIn: 418851,
      callsInDuration: 351502,
      callsOut: 278129,
      callsOutDuration: 266199,
      smses: 47287,
    },
    {
      date: "2016-10-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3384752,
      accounts: 83540,
      dataSent: 288686,
      dataReceived: 1520045,
      callsIn: 431900,
      callsInDuration: 318185,
      callsOut: 276550,
      callsOutDuration: 245196,
      smses: 44826,
    },
    {
      date: "2016-10-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3277671,
      accounts: 77333,
      dataSent: 281075,
      dataReceived: 1478972,
      callsIn: 324785,
      callsInDuration: 262149,
      callsOut: 206505,
      callsOutDuration: 205452,
      smses: 35604,
    },
    {
      date: "2016-10-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3375428,
      accounts: 79703,
      dataSent: 257300,
      dataReceived: 1427154,
      callsIn: 382338,
      callsInDuration: 339580,
      callsOut: 250781,
      callsOutDuration: 256879,
      smses: 42370,
    },
    {
      date: "2016-10-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3668224,
      accounts: 80005,
      dataSent: 334457,
      dataReceived: 1872127,
      callsIn: 364362,
      callsInDuration: 333354,
      callsOut: 243265,
      callsOutDuration: 258053,
      smses: 43646,
    },
    {
      date: "2016-10-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3311178,
      accounts: 79099,
      dataSent: 280379,
      dataReceived: 1583953,
      callsIn: 360350,
      callsInDuration: 331610,
      callsOut: 245855,
      callsOutDuration: 256615,
      smses: 44976,
    },
    {
      date: "2016-10-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3654677,
      accounts: 81891,
      dataSent: 333199,
      dataReceived: 1870893,
      callsIn: 372274,
      callsInDuration: 335646,
      callsOut: 257500,
      callsOutDuration: 265347,
      smses: 45089,
    },
    {
      date: "2016-10-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3428701,
      accounts: 83168,
      dataSent: 326988,
      dataReceived: 1832067,
      callsIn: 409528,
      callsInDuration: 341280,
      callsOut: 284857,
      callsOutDuration: 274336,
      smses: 46468,
    },
    {
      date: "2016-10-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3341501,
      accounts: 82193,
      dataSent: 336751,
      dataReceived: 1889354,
      callsIn: 406352,
      callsInDuration: 303247,
      callsOut: 283710,
      callsOutDuration: 257121,
      smses: 44205,
    },
    {
      date: "2016-10-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3151378,
      accounts: 78048,
      dataSent: 328040,
      dataReceived: 1854868,
      callsIn: 306694,
      callsInDuration: 262971,
      callsOut: 212405,
      callsOutDuration: 221517,
      smses: 36939,
    },
    {
      date: "2016-10-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3236647,
      accounts: 80424,
      dataSent: 328126,
      dataReceived: 1871461,
      callsIn: 371175,
      callsInDuration: 349937,
      callsOut: 251850,
      callsOutDuration: 267859,
      smses: 43844,
    },
    {
      date: "2016-10-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3196053,
      accounts: 80165,
      dataSent: 319216,
      dataReceived: 1846444,
      callsIn: 369309,
      callsInDuration: 352646,
      callsOut: 245990,
      callsOutDuration: 262168,
      smses: 43463,
    },
    {
      date: "2016-10-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3354758,
      accounts: 80720,
      dataSent: 330397,
      dataReceived: 1988087,
      callsIn: 374715,
      callsInDuration: 340653,
      callsOut: 252570,
      callsOutDuration: 258668,
      smses: 43775,
    },
    {
      date: "2016-10-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3226813,
      accounts: 80772,
      dataSent: 303614,
      dataReceived: 1729626,
      callsIn: 375128,
      callsInDuration: 353112,
      callsOut: 253113,
      callsOutDuration: 266717,
      smses: 41114,
    },
    {
      date: "2016-10-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3633485,
      accounts: 86227,
      dataSent: 365753,
      dataReceived: 2081453,
      callsIn: 438012,
      callsInDuration: 375071,
      callsOut: 289723,
      callsOutDuration: 277259,
      smses: 45957,
    },
    {
      date: "2016-10-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3295031,
      accounts: 87708,
      dataSent: 351672,
      dataReceived: 2004930,
      callsIn: 447652,
      callsInDuration: 343971,
      callsOut: 287671,
      callsOutDuration: 259914,
      smses: 44269,
    },
    {
      date: "2016-10-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3145537,
      accounts: 79449,
      dataSent: 333310,
      dataReceived: 1952570,
      callsIn: 331286,
      callsInDuration: 285942,
      callsOut: 215146,
      callsOutDuration: 222154,
      smses: 37252,
    },
    {
      date: "2016-10-31",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3380335,
      accounts: 85292,
      dataSent: 346286,
      dataReceived: 1977140,
      callsIn: 417568,
      callsInDuration: 359141,
      callsOut: 276778,
      callsOutDuration: 270030,
      smses: 45428,
    },
    {
      date: "2016-11-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3348455,
      accounts: 90321,
      dataSent: 350342,
      dataReceived: 2029664,
      callsIn: 471536,
      callsInDuration: 378018,
      callsOut: 311107,
      callsOutDuration: 294746,
      smses: 49926,
    },
    {
      date: "2016-11-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3358370,
      accounts: 82719,
      dataSent: 374155,
      dataReceived: 2082790,
      callsIn: 390307,
      callsInDuration: 311647,
      callsOut: 262773,
      callsOutDuration: 250577,
      smses: 42806,
    },
    {
      date: "2016-11-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2210700,
      accounts: 77347,
      dataSent: 251222,
      dataReceived: 1417396,
      callsIn: 337406,
      callsInDuration: 283008,
      callsOut: 224385,
      callsOutDuration: 224028,
      smses: 37817,
    },
    {
      date: "2016-11-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3294998,
      accounts: 80893,
      dataSent: 373945,
      dataReceived: 2056247,
      callsIn: 349304,
      callsInDuration: 283351,
      callsOut: 234342,
      callsOutDuration: 229583,
      smses: 41963,
    },
    {
      date: "2016-11-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3279721,
      accounts: 82103,
      dataSent: 351422,
      dataReceived: 1922698,
      callsIn: 370788,
      callsInDuration: 286188,
      callsOut: 250844,
      callsOutDuration: 237383,
      smses: 42866,
    },
    {
      date: "2016-11-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3258687,
      accounts: 80666,
      dataSent: 340669,
      dataReceived: 1903186,
      callsIn: 317051,
      callsInDuration: 268901,
      callsOut: 212470,
      callsOutDuration: 218420,
      smses: 38000,
    },
    {
      date: "2016-11-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3879706,
      accounts: 85263,
      dataSent: 429378,
      dataReceived: 2428016,
      callsIn: 399595,
      callsInDuration: 372713,
      callsOut: 273862,
      callsOutDuration: 285874,
      smses: 46829,
    },
    {
      date: "2016-11-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3460387,
      accounts: 85784,
      dataSent: 355533,
      dataReceived: 2129632,
      callsIn: 403768,
      callsInDuration: 380010,
      callsOut: 270194,
      callsOutDuration: 279716,
      smses: 47250,
    },
    {
      date: "2016-11-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3566430,
      accounts: 84313,
      dataSent: 401549,
      dataReceived: 2440761,
      callsIn: 396723,
      callsInDuration: 379680,
      callsOut: 271255,
      callsOutDuration: 286813,
      smses: 46137,
    },
    {
      date: "2016-11-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3332419,
      accounts: 83642,
      dataSent: 353795,
      dataReceived: 2142123,
      callsIn: 389545,
      callsInDuration: 366049,
      callsOut: 269770,
      callsOutDuration: 276855,
      smses: 44228,
    },
    {
      date: "2016-11-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3724080,
      accounts: 90176,
      dataSent: 422325,
      dataReceived: 2380727,
      callsIn: 456766,
      callsInDuration: 399810,
      callsOut: 304434,
      callsOutDuration: 297797,
      smses: 49725,
    },
    {
      date: "2016-11-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3503596,
      accounts: 92489,
      dataSent: 388852,
      dataReceived: 2180489,
      callsIn: 486538,
      callsInDuration: 368957,
      callsOut: 313296,
      callsOutDuration: 281896,
      smses: 48029,
    },
    {
      date: "2016-11-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3234511,
      accounts: 82809,
      dataSent: 373907,
      dataReceived: 2119777,
      callsIn: 349604,
      callsInDuration: 305187,
      callsOut: 228721,
      callsOutDuration: 237694,
      smses: 39117,
    },
    {
      date: "2016-11-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3331044,
      accounts: 86448,
      dataSent: 383788,
      dataReceived: 2195290,
      callsIn: 410439,
      callsInDuration: 380749,
      callsOut: 274069,
      callsOutDuration: 280295,
      smses: 47682,
    },
    {
      date: "2016-11-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3341258,
      accounts: 85743,
      dataSent: 402390,
      dataReceived: 2308743,
      callsIn: 404693,
      callsInDuration: 373701,
      callsOut: 272880,
      callsOutDuration: 279531,
      smses: 46229,
    },
    {
      date: "2016-11-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3256320,
      accounts: 86795,
      dataSent: 370976,
      dataReceived: 2082416,
      callsIn: 412294,
      callsInDuration: 382967,
      callsOut: 274995,
      callsOutDuration: 282779,
      smses: 46347,
    },
    {
      date: "2016-11-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3489545,
      accounts: 86857,
      dataSent: 401050,
      dataReceived: 2252092,
      callsIn: 418149,
      callsInDuration: 385542,
      callsOut: 280986,
      callsOutDuration: 288947,
      smses: 46116,
    },
    {
      date: "2016-11-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3485320,
      accounts: 91795,
      dataSent: 352772,
      dataReceived: 1977745,
      callsIn: 465126,
      callsInDuration: 398268,
      callsOut: 305570,
      callsOutDuration: 292834,
      smses: 48664,
    },
    {
      date: "2016-11-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3771770,
      accounts: 92191,
      dataSent: 417428,
      dataReceived: 2336515,
      callsIn: 477417,
      callsInDuration: 354951,
      callsOut: 305657,
      callsOutDuration: 272176,
      smses: 46729,
    },
    {
      date: "2016-11-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4010257,
      accounts: 84199,
      dataSent: 422477,
      dataReceived: 2365025,
      callsIn: 353498,
      callsInDuration: 294126,
      callsOut: 228865,
      callsOutDuration: 229790,
      smses: 38185,
    },
    {
      date: "2016-11-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3692582,
      accounts: 87604,
      dataSent: 412902,
      dataReceived: 2328434,
      callsIn: 415016,
      callsInDuration: 381456,
      callsOut: 282024,
      callsOutDuration: 289474,
      smses: 46891,
    },
    {
      date: "2016-11-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3364307,
      accounts: 86646,
      dataSent: 420540,
      dataReceived: 2368066,
      callsIn: 400115,
      callsInDuration: 383297,
      callsOut: 275054,
      callsOutDuration: 286488,
      smses: 47389,
    },
    {
      date: "2016-11-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2975919,
      accounts: 85988,
      dataSent: 365695,
      dataReceived: 2066900,
      callsIn: 401373,
      callsInDuration: 378510,
      callsOut: 271787,
      callsOutDuration: 284824,
      smses: 46832,
    },
    {
      date: "2016-11-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3137120,
      accounts: 88398,
      dataSent: 403462,
      dataReceived: 2239863,
      callsIn: 422675,
      callsInDuration: 406718,
      callsOut: 277701,
      callsOutDuration: 290844,
      smses: 47467,
    },
    {
      date: "2016-11-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3815547,
      accounts: 94345,
      dataSent: 454066,
      dataReceived: 2384780,
      callsIn: 488796,
      callsInDuration: 414028,
      callsOut: 322001,
      callsOutDuration: 305047,
      smses: 51743,
    },
    {
      date: "2016-11-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2828868,
      accounts: 93206,
      dataSent: 426104,
      dataReceived: 2232953,
      callsIn: 477295,
      callsInDuration: 368826,
      callsOut: 313088,
      callsOutDuration: 281060,
      smses: 48675,
    },
    {
      date: "2016-11-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2616867,
      accounts: 82488,
      dataSent: 386734,
      dataReceived: 2126372,
      callsIn: 376027,
      callsInDuration: 315068,
      callsOut: 244554,
      callsOutDuration: 240486,
      smses: 41557,
    },
    {
      date: "2016-11-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2131006,
      accounts: 88338,
      dataSent: 265620,
      dataReceived: 1576190,
      callsIn: 413945,
      callsInDuration: 389361,
      callsOut: 274551,
      callsOutDuration: 283486,
      smses: 45776,
    },
    {
      date: "2016-11-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2079522,
      accounts: 86863,
      dataSent: 321227,
      dataReceived: 1941599,
      callsIn: 396368,
      callsInDuration: 384760,
      callsOut: 269237,
      callsOutDuration: 280952,
      smses: 45491,
    },
    {
      date: "2016-11-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2545560,
      accounts: 88336,
      dataSent: 368325,
      dataReceived: 2187632,
      callsIn: 415502,
      callsInDuration: 394017,
      callsOut: 278365,
      callsOutDuration: 288585,
      smses: 46283,
    },
    {
      date: "2016-12-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2858868,
      accounts: 90640,
      dataSent: 438667,
      dataReceived: 2452668,
      callsIn: 435315,
      callsInDuration: 410667,
      callsOut: 294271,
      callsOutDuration: 306621,
      smses: 47528,
    },
    {
      date: "2016-12-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 2875633,
      accounts: 97779,
      dataSent: 435085,
      dataReceived: 2457825,
      callsIn: 520096,
      callsInDuration: 441739,
      callsOut: 336979,
      callsOutDuration: 321071,
      smses: 51666,
    },
    {
      date: "2016-12-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 2622998,
      accounts: 97501,
      dataSent: 425165,
      dataReceived: 2405674,
      callsIn: 520297,
      callsInDuration: 399830,
      callsOut: 330848,
      callsOutDuration: 298273,
      smses: 51388,
    },
    {
      date: "2016-12-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2530883,
      accounts: 86227,
      dataSent: 437272,
      dataReceived: 2403928,
      callsIn: 404314,
      callsInDuration: 337322,
      callsOut: 256478,
      callsOutDuration: 251082,
      smses: 42174,
    },
    {
      date: "2016-12-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2664203,
      accounts: 92906,
      dataSent: 415144,
      dataReceived: 2385708,
      callsIn: 457000,
      callsInDuration: 421294,
      callsOut: 297444,
      callsOutDuration: 306008,
      smses: 49162,
    },
    {
      date: "2016-12-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2569555,
      accounts: 89388,
      dataSent: 394685,
      dataReceived: 2252630,
      callsIn: 420178,
      callsInDuration: 408319,
      callsOut: 285208,
      callsOutDuration: 300067,
      smses: 48567,
    },
    {
      date: "2016-12-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3282373,
      accounts: 90918,
      dataSent: 454781,
      dataReceived: 2644276,
      callsIn: 428220,
      callsInDuration: 424090,
      callsOut: 293066,
      callsOutDuration: 311593,
      smses: 48378,
    },
    {
      date: "2016-12-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3022523,
      accounts: 92521,
      dataSent: 429616,
      dataReceived: 2403777,
      callsIn: 445707,
      callsInDuration: 433273,
      callsOut: 301791,
      callsOutDuration: 316226,
      smses: 49721,
    },
    {
      date: "2016-12-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3104446,
      accounts: 98269,
      dataSent: 373063,
      dataReceived: 2104775,
      callsIn: 510065,
      callsInDuration: 444481,
      callsOut: 341702,
      callsOutDuration: 325995,
      smses: 53381,
    },
    {
      date: "2016-12-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3439442,
      accounts: 98025,
      dataSent: 530348,
      dataReceived: 2942883,
      callsIn: 516817,
      callsInDuration: 395380,
      callsOut: 344525,
      callsOutDuration: 308359,
      smses: 50323,
    },
    {
      date: "2016-12-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2862748,
      accounts: 85530,
      dataSent: 434154,
      dataReceived: 2497277,
      callsIn: 389365,
      callsInDuration: 343077,
      callsOut: 257747,
      callsOutDuration: 265739,
      smses: 42985,
    },
    {
      date: "2016-12-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2896182,
      accounts: 93324,
      dataSent: 405395,
      dataReceived: 2387990,
      callsIn: 445945,
      callsInDuration: 429882,
      callsOut: 300332,
      callsOutDuration: 313060,
      smses: 50310,
    },
    {
      date: "2016-12-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3155517,
      accounts: 93274,
      dataSent: 475430,
      dataReceived: 2754465,
      callsIn: 443458,
      callsInDuration: 437914,
      callsOut: 298841,
      callsOutDuration: 315917,
      smses: 50907,
    },
    {
      date: "2016-12-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3071080,
      accounts: 94118,
      dataSent: 457542,
      dataReceived: 2638346,
      callsIn: 450918,
      callsInDuration: 452652,
      callsOut: 303997,
      callsOutDuration: 328549,
      smses: 50231,
    },
    {
      date: "2016-12-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2967835,
      accounts: 96353,
      dataSent: 434970,
      dataReceived: 2616547,
      callsIn: 475650,
      callsInDuration: 462907,
      callsOut: 313990,
      callsOutDuration: 332688,
      smses: 51206,
    },
    {
      date: "2016-12-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3104922,
      accounts: 103776,
      dataSent: 466417,
      dataReceived: 2691006,
      callsIn: 563115,
      callsInDuration: 485182,
      callsOut: 360139,
      callsOutDuration: 341120,
      smses: 55338,
    },
    {
      date: "2016-12-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3131602,
      accounts: 104839,
      dataSent: 474456,
      dataReceived: 2672053,
      callsIn: 582167,
      callsInDuration: 441844,
      callsOut: 369000,
      callsOutDuration: 328697,
      smses: 52387,
    },
    {
      date: "2016-12-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 2896618,
      accounts: 91325,
      dataSent: 436863,
      dataReceived: 2495966,
      callsIn: 433660,
      callsInDuration: 365422,
      callsOut: 278480,
      callsOutDuration: 276547,
      smses: 44666,
    },
    {
      date: "2016-12-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3047077,
      accounts: 100153,
      dataSent: 413138,
      dataReceived: 2382767,
      callsIn: 500735,
      callsInDuration: 472305,
      callsOut: 327481,
      callsOutDuration: 336314,
      smses: 52057,
    },
    {
      date: "2016-12-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3582827,
      accounts: 100277,
      dataSent: 501305,
      dataReceived: 2916893,
      callsIn: 501362,
      callsInDuration: 471008,
      callsOut: 335499,
      callsOutDuration: 344244,
      smses: 53209,
    },
    {
      date: "2016-12-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3333878,
      accounts: 101420,
      dataSent: 499238,
      dataReceived: 2889429,
      callsIn: 515993,
      callsInDuration: 468140,
      callsOut: 349604,
      callsOutDuration: 346099,
      smses: 54103,
    },
    {
      date: "2016-12-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3067542,
      accounts: 105019,
      dataSent: 446634,
      dataReceived: 2563871,
      callsIn: 553101,
      callsInDuration: 470968,
      callsOut: 374438,
      callsOutDuration: 357948,
      smses: 55087,
    },
    {
      date: "2016-12-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3169534,
      accounts: 110044,
      dataSent: 483331,
      dataReceived: 2670017,
      callsIn: 634032,
      callsInDuration: 482508,
      callsOut: 426448,
      callsOutDuration: 370594,
      smses: 58209,
    },
    {
      date: "2016-12-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3165969,
      accounts: 107842,
      dataSent: 489907,
      dataReceived: 2777011,
      callsIn: 604983,
      callsInDuration: 428191,
      callsOut: 405819,
      callsOutDuration: 344435,
      smses: 54749,
    },
    {
      date: "2016-12-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3173776,
      accounts: 91201,
      dataSent: 525561,
      dataReceived: 2994430,
      callsIn: 430590,
      callsInDuration: 369130,
      callsOut: 280512,
      callsOutDuration: 288906,
      smses: 46866,
    },
    {
      date: "2016-12-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2989653,
      accounts: 89113,
      dataSent: 448880,
      dataReceived: 2598318,
      callsIn: 397584,
      callsInDuration: 373862,
      callsOut: 264165,
      callsOutDuration: 280020,
      smses: 43196,
    },
    {
      date: "2016-12-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3107112,
      accounts: 97187,
      dataSent: 465060,
      dataReceived: 2673181,
      callsIn: 467186,
      callsInDuration: 452236,
      callsOut: 307374,
      callsOutDuration: 325913,
      smses: 52261,
    },
    {
      date: "2016-12-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3010797,
      accounts: 98758,
      dataSent: 437183,
      dataReceived: 2503828,
      callsIn: 486169,
      callsInDuration: 459768,
      callsOut: 326155,
      callsOutDuration: 340328,
      smses: 53465,
    },
    {
      date: "2016-12-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3414299,
      accounts: 100684,
      dataSent: 482368,
      dataReceived: 2751572,
      callsIn: 509518,
      callsInDuration: 461698,
      callsOut: 341018,
      callsOutDuration: 342836,
      smses: 54986,
    },
    {
      date: "2016-12-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3700895,
      accounts: 105546,
      dataSent: 523128,
      dataReceived: 2967666,
      callsIn: 576522,
      callsInDuration: 462350,
      callsOut: 384725,
      callsOutDuration: 356031,
      smses: 56609,
    },
    {
      date: "2016-12-31",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3675437,
      accounts: 109545,
      dataSent: 553488,
      dataReceived: 2858696,
      callsIn: 629133,
      callsInDuration: 411431,
      callsOut: 421373,
      callsOutDuration: 344853,
      smses: 57236,
    },
    {
      date: "2017-01-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3694978,
      accounts: 96358,
      dataSent: 571620,
      dataReceived: 3050457,
      callsIn: 496911,
      callsInDuration: 385946,
      callsOut: 305574,
      callsOutDuration: 310411,
      smses: 53881,
    },
    {
      date: "2017-01-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3113964,
      accounts: 89687,
      dataSent: 460169,
      dataReceived: 2623923,
      callsIn: 366468,
      callsInDuration: 338364,
      callsOut: 231412,
      callsOutDuration: 250790,
      smses: 40494,
    },
    {
      date: "2017-01-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3585464,
      accounts: 95048,
      dataSent: 489029,
      dataReceived: 2901381,
      callsIn: 439960,
      callsInDuration: 440794,
      callsOut: 288480,
      callsOutDuration: 315182,
      smses: 53048,
    },
    {
      date: "2017-01-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3381340,
      accounts: 94182,
      dataSent: 480078,
      dataReceived: 2843635,
      callsIn: 438904,
      callsInDuration: 450295,
      callsOut: 291330,
      callsOutDuration: 321733,
      smses: 51596,
    },
    {
      date: "2017-01-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3441837,
      accounts: 94521,
      dataSent: 459932,
      dataReceived: 2686187,
      callsIn: 440828,
      callsInDuration: 450762,
      callsOut: 291105,
      callsOutDuration: 318372,
      smses: 51127,
    },
    {
      date: "2017-01-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3465726,
      accounts: 99729,
      dataSent: 405613,
      dataReceived: 2386657,
      callsIn: 493195,
      callsInDuration: 453859,
      callsOut: 327247,
      callsOutDuration: 324767,
      smses: 54611,
    },
    {
      date: "2017-01-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3677045,
      accounts: 97241,
      dataSent: 520707,
      dataReceived: 3070075,
      callsIn: 474772,
      callsInDuration: 411581,
      callsOut: 317108,
      callsOutDuration: 307487,
      smses: 49884,
    },
    {
      date: "2017-01-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3063433,
      accounts: 87522,
      dataSent: 435786,
      dataReceived: 2551473,
      callsIn: 374248,
      callsInDuration: 371662,
      callsOut: 241840,
      callsOutDuration: 265409,
      smses: 41272,
    },
    {
      date: "2017-01-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3303377,
      accounts: 96739,
      dataSent: 446828,
      dataReceived: 2591504,
      callsIn: 460669,
      callsInDuration: 474033,
      callsOut: 304493,
      callsOutDuration: 331665,
      smses: 51209,
    },
    {
      date: "2017-01-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3254224,
      accounts: 95103,
      dataSent: 428277,
      dataReceived: 2521077,
      callsIn: 448748,
      callsInDuration: 461866,
      callsOut: 295085,
      callsOutDuration: 323981,
      smses: 50874,
    },
    {
      date: "2017-01-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3197478,
      accounts: 95627,
      dataSent: 383741,
      dataReceived: 2257249,
      callsIn: 449426,
      callsInDuration: 466678,
      callsOut: 294699,
      callsOutDuration: 324483,
      smses: 49823,
    },
    {
      date: "2017-01-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3660817,
      accounts: 96196,
      dataSent: 504764,
      dataReceived: 2948761,
      callsIn: 453501,
      callsInDuration: 464930,
      callsOut: 296633,
      callsOutDuration: 321290,
      smses: 49495,
    },
    {
      date: "2017-01-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3339343,
      accounts: 102247,
      dataSent: 474810,
      dataReceived: 2787172,
      callsIn: 519622,
      callsInDuration: 484247,
      callsOut: 330121,
      callsOutDuration: 330816,
      smses: 53886,
    },
    {
      date: "2017-01-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3209521,
      accounts: 101997,
      dataSent: 476874,
      dataReceived: 2764110,
      callsIn: 520199,
      callsInDuration: 445150,
      callsOut: 326888,
      callsOutDuration: 314388,
      smses: 51241,
    },
    {
      date: "2017-01-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3020346,
      accounts: 89538,
      dataSent: 451430,
      dataReceived: 2636021,
      callsIn: 406828,
      callsInDuration: 376886,
      callsOut: 254396,
      callsOutDuration: 268004,
      smses: 43390,
    },
    {
      date: "2017-01-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 2956332,
      accounts: 99419,
      dataSent: 399441,
      dataReceived: 2328996,
      callsIn: 480139,
      callsInDuration: 473646,
      callsOut: 308827,
      callsOutDuration: 326420,
      smses: 51979,
    },
    {
      date: "2017-01-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3390443,
      accounts: 97903,
      dataSent: 588950,
      dataReceived: 3645300,
      callsIn: 464503,
      callsInDuration: 476128,
      callsOut: 296336,
      callsOutDuration: 323061,
      smses: 49995,
    },
    {
      date: "2017-01-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3251379,
      accounts: 97280,
      dataSent: 493666,
      dataReceived: 3059305,
      callsIn: 459606,
      callsInDuration: 468734,
      callsOut: 295254,
      callsOutDuration: 320654,
      smses: 48599,
    },
    {
      date: "2017-01-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3318216,
      accounts: 97508,
      dataSent: 473842,
      dataReceived: 2914144,
      callsIn: 458513,
      callsInDuration: 467606,
      callsOut: 295021,
      callsOutDuration: 319931,
      smses: 49494,
    },
    {
      date: "2017-01-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3691600,
      accounts: 100609,
      dataSent: 517680,
      dataReceived: 3150980,
      callsIn: 496047,
      callsInDuration: 473031,
      callsOut: 320217,
      callsOutDuration: 328861,
      smses: 52613,
    },
    {
      date: "2017-01-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3597044,
      accounts: 100473,
      dataSent: 496608,
      dataReceived: 2937752,
      callsIn: 496202,
      callsInDuration: 415791,
      callsOut: 330800,
      callsOutDuration: 314464,
      smses: 51279,
    },
    {
      date: "2017-01-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3310800,
      accounts: 89622,
      dataSent: 454462,
      dataReceived: 2623968,
      callsIn: 390110,
      callsInDuration: 364756,
      callsOut: 256731,
      callsOutDuration: 269975,
      smses: 41728,
    },
    {
      date: "2017-01-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3410689,
      accounts: 97194,
      dataSent: 462171,
      dataReceived: 2735133,
      callsIn: 454004,
      callsInDuration: 458683,
      callsOut: 301851,
      callsOutDuration: 320545,
      smses: 49985,
    },
    {
      date: "2017-01-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3360603,
      accounts: 97093,
      dataSent: 450965,
      dataReceived: 2679440,
      callsIn: 458689,
      callsInDuration: 473029,
      callsOut: 293489,
      callsOutDuration: 316507,
      smses: 50059,
    },
    {
      date: "2017-01-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3328561,
      accounts: 97438,
      dataSent: 439124,
      dataReceived: 2619520,
      callsIn: 456586,
      callsInDuration: 477217,
      callsOut: 288582,
      callsOutDuration: 314801,
      smses: 48142,
    },
    {
      date: "2017-01-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3317543,
      accounts: 97505,
      dataSent: 449117,
      dataReceived: 2677846,
      callsIn: 458703,
      callsInDuration: 478294,
      callsOut: 288841,
      callsOutDuration: 317233,
      smses: 48602,
    },
    {
      date: "2017-01-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3561057,
      accounts: 104790,
      dataSent: 463466,
      dataReceived: 2795564,
      callsIn: 528961,
      callsInDuration: 499154,
      callsOut: 328991,
      callsOutDuration: 330929,
      smses: 52973,
    },
    {
      date: "2017-01-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3410856,
      accounts: 103205,
      dataSent: 436583,
      dataReceived: 2539675,
      callsIn: 526451,
      callsInDuration: 438647,
      callsOut: 329493,
      callsOutDuration: 309041,
      smses: 50063,
    },
    {
      date: "2017-01-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3331204,
      accounts: 89444,
      dataSent: 374821,
      dataReceived: 2210242,
      callsIn: 396804,
      callsInDuration: 378042,
      callsOut: 245578,
      callsOutDuration: 260847,
      smses: 41795,
    },
    {
      date: "2017-01-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3753045,
      accounts: 98528,
      dataSent: 485071,
      dataReceived: 3062779,
      callsIn: 462712,
      callsInDuration: 476483,
      callsOut: 293087,
      callsOutDuration: 321734,
      smses: 51079,
    },
    {
      date: "2017-01-31",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3467303,
      accounts: 98547,
      dataSent: 444999,
      dataReceived: 2795123,
      callsIn: 464705,
      callsInDuration: 473986,
      callsOut: 313110,
      callsOutDuration: 330226,
      smses: 50521,
    },
    {
      date: "2017-02-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3527981,
      accounts: 99505,
      dataSent: 452653,
      dataReceived: 2790313,
      callsIn: 476093,
      callsInDuration: 484110,
      callsOut: 309073,
      callsOutDuration: 329380,
      smses: 50985,
    },
    {
      date: "2017-02-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3520097,
      accounts: 100346,
      dataSent: 466275,
      dataReceived: 2892021,
      callsIn: 485896,
      callsInDuration: 490759,
      callsOut: 310316,
      callsOutDuration: 328412,
      smses: 51740,
    },
    {
      date: "2017-02-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3288995,
      accounts: 106654,
      dataSent: 451353,
      dataReceived: 2700785,
      callsIn: 564372,
      callsInDuration: 510292,
      callsOut: 359049,
      callsOutDuration: 350610,
      smses: 56631,
    },
    {
      date: "2017-02-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3342820,
      accounts: 105325,
      dataSent: 441874,
      dataReceived: 2604825,
      callsIn: 543944,
      callsInDuration: 456740,
      callsOut: 349194,
      callsOutDuration: 329452,
      smses: 52497,
    },
    {
      date: "2017-02-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3253889,
      accounts: 91913,
      dataSent: 386648,
      dataReceived: 2336369,
      callsIn: 418062,
      callsInDuration: 386643,
      callsOut: 266664,
      callsOutDuration: 275167,
      smses: 44209,
    },
    {
      date: "2017-02-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3591732,
      accounts: 101168,
      dataSent: 453931,
      dataReceived: 2775403,
      callsIn: 489853,
      callsInDuration: 492140,
      callsOut: 314816,
      callsOutDuration: 331187,
      smses: 51713,
    },
    {
      date: "2017-02-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3645389,
      accounts: 100289,
      dataSent: 461579,
      dataReceived: 2800968,
      callsIn: 478933,
      callsInDuration: 493980,
      callsOut: 311393,
      callsOutDuration: 330687,
      smses: 51935,
    },
    {
      date: "2017-02-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3487917,
      accounts: 100652,
      dataSent: 400360,
      dataReceived: 2421768,
      callsIn: 486298,
      callsInDuration: 497375,
      callsOut: 306649,
      callsOutDuration: 325090,
      smses: 52479,
    },
    {
      date: "2017-02-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3702152,
      accounts: 99738,
      dataSent: 457373,
      dataReceived: 2806128,
      callsIn: 480251,
      callsInDuration: 501491,
      callsOut: 306088,
      callsOutDuration: 327649,
      smses: 49270,
    },
    {
      date: "2017-02-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3661749,
      accounts: 106221,
      dataSent: 393928,
      dataReceived: 2340666,
      callsIn: 555569,
      callsInDuration: 517755,
      callsOut: 345590,
      callsOutDuration: 342774,
      smses: 53418,
    },
    {
      date: "2017-02-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3773116,
      accounts: 105483,
      dataSent: 448702,
      dataReceived: 2614685,
      callsIn: 550325,
      callsInDuration: 464873,
      callsOut: 336580,
      callsOutDuration: 315534,
      smses: 51779,
    },
    {
      date: "2017-02-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3442285,
      accounts: 91405,
      dataSent: 404614,
      dataReceived: 2400519,
      callsIn: 413281,
      callsInDuration: 399685,
      callsOut: 255125,
      callsOutDuration: 268446,
      smses: 42857,
    },
    {
      date: "2017-02-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3542827,
      accounts: 102290,
      dataSent: 402206,
      dataReceived: 2344482,
      callsIn: 500783,
      callsInDuration: 500941,
      callsOut: 313170,
      callsOutDuration: 322625,
      smses: 50624,
    },
    {
      date: "2017-02-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3952351,
      accounts: 105766,
      dataSent: 459137,
      dataReceived: 2599821,
      callsIn: 540360,
      callsInDuration: 508409,
      callsOut: 340951,
      callsOutDuration: 338898,
      smses: 59566,
    },
    {
      date: "2017-02-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3996120,
      accounts: 101682,
      dataSent: 408050,
      dataReceived: 2415515,
      callsIn: 497916,
      callsInDuration: 511495,
      callsOut: 311884,
      callsOutDuration: 332696,
      smses: 50084,
    },
    {
      date: "2017-02-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4245173,
      accounts: 103081,
      dataSent: 477610,
      dataReceived: 2854882,
      callsIn: 507097,
      callsInDuration: 519802,
      callsOut: 317611,
      callsOutDuration: 340693,
      smses: 49605,
    },
    {
      date: "2017-02-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3925010,
      accounts: 108354,
      dataSent: 412190,
      dataReceived: 2438430,
      callsIn: 560987,
      callsInDuration: 529615,
      callsOut: 347528,
      callsOutDuration: 343241,
      smses: 52857,
    },
    {
      date: "2017-02-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4496775,
      accounts: 107534,
      dataSent: 492660,
      dataReceived: 2884100,
      callsIn: 544181,
      callsInDuration: 469911,
      callsOut: 351843,
      callsOutDuration: 324505,
      smses: 49455,
    },
    {
      date: "2017-02-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3905436,
      accounts: 105835,
      dataSent: 417391,
      dataReceived: 2450974,
      callsIn: 542544,
      callsInDuration: 434120,
      callsOut: 400688,
      callsOutDuration: 328133,
      smses: 49158,
    },
    {
      date: "2017-02-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3735476,
      accounts: 104340,
      dataSent: 342302,
      dataReceived: 2070031,
      callsIn: 509786,
      callsInDuration: 494775,
      callsOut: 318634,
      callsOutDuration: 319788,
      smses: 51269,
    },
    {
      date: "2017-02-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4058455,
      accounts: 101919,
      dataSent: 501319,
      dataReceived: 3045702,
      callsIn: 481954,
      callsInDuration: 495964,
      callsOut: 308989,
      callsOutDuration: 321169,
      smses: 49925,
    },
    {
      date: "2017-02-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3836834,
      accounts: 101638,
      dataSent: 407154,
      dataReceived: 2507449,
      callsIn: 484376,
      callsInDuration: 498778,
      callsOut: 315593,
      callsOutDuration: 330534,
      smses: 50242,
    },
    {
      date: "2017-02-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4016937,
      accounts: 105066,
      dataSent: 473889,
      dataReceived: 2814344,
      callsIn: 520371,
      callsInDuration: 515891,
      callsOut: 333349,
      callsOutDuration: 339091,
      smses: 51164,
    },
    {
      date: "2017-02-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3863420,
      accounts: 114449,
      dataSent: 456776,
      dataReceived: 2691183,
      callsIn: 622241,
      callsInDuration: 557397,
      callsOut: 384529,
      callsOutDuration: 357911,
      smses: 55031,
    },
    {
      date: "2017-02-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3933225,
      accounts: 110873,
      dataSent: 471605,
      dataReceived: 2706677,
      callsIn: 606633,
      callsInDuration: 494052,
      callsOut: 369844,
      callsOutDuration: 332783,
      smses: 53525,
    },
    {
      date: "2017-02-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3744839,
      accounts: 100560,
      dataSent: 465302,
      dataReceived: 2612492,
      callsIn: 488221,
      callsInDuration: 397140,
      callsOut: 288366,
      callsOutDuration: 272650,
      smses: 46306,
    },
    {
      date: "2017-02-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3588190,
      accounts: 97539,
      dataSent: 454971,
      dataReceived: 2489962,
      callsIn: 441897,
      callsInDuration: 377734,
      callsOut: 262205,
      callsOutDuration: 249062,
      smses: 42907,
    },
    {
      date: "2017-02-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3721998,
      accounts: 96511,
      dataSent: 428819,
      dataReceived: 2483936,
      callsIn: 392376,
      callsInDuration: 373458,
      callsOut: 235559,
      callsOutDuration: 241718,
      smses: 40092,
    },
    {
      date: "2017-03-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3806346,
      accounts: 104896,
      dataSent: 431414,
      dataReceived: 2537939,
      callsIn: 504660,
      callsInDuration: 509014,
      callsOut: 319200,
      callsOutDuration: 321493,
      smses: 51845,
    },
    {
      date: "2017-03-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3966538,
      accounts: 106319,
      dataSent: 417907,
      dataReceived: 2461912,
      callsIn: 519396,
      callsInDuration: 534963,
      callsOut: 330203,
      callsOutDuration: 335644,
      smses: 53459,
    },
    {
      date: "2017-03-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3944271,
      accounts: 110899,
      dataSent: 452861,
      dataReceived: 2600100,
      callsIn: 567126,
      callsInDuration: 551938,
      callsOut: 361648,
      callsOutDuration: 351661,
      smses: 55473,
    },
    {
      date: "2017-03-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3972483,
      accounts: 107276,
      dataSent: 446426,
      dataReceived: 2564536,
      callsIn: 539910,
      callsInDuration: 480033,
      callsOut: 347298,
      callsOutDuration: 320659,
      smses: 51685,
    },
    {
      date: "2017-03-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3796425,
      accounts: 97682,
      dataSent: 418521,
      dataReceived: 2449585,
      callsIn: 417243,
      callsInDuration: 416635,
      callsOut: 266660,
      callsOutDuration: 274205,
      smses: 43196,
    },
    {
      date: "2017-03-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4208402,
      accounts: 105688,
      dataSent: 400963,
      dataReceived: 2337012,
      callsIn: 511566,
      callsInDuration: 514463,
      callsOut: 331423,
      callsOutDuration: 326688,
      smses: 51692,
    },
    {
      date: "2017-03-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4201474,
      accounts: 103938,
      dataSent: 446979,
      dataReceived: 2564837,
      callsIn: 493818,
      callsInDuration: 522110,
      callsOut: 322714,
      callsOutDuration: 330550,
      smses: 52387,
    },
    {
      date: "2017-03-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4315634,
      accounts: 106836,
      dataSent: 428846,
      dataReceived: 2476760,
      callsIn: 523392,
      callsInDuration: 537269,
      callsOut: 331483,
      callsOutDuration: 333654,
      smses: 54270,
    },
    {
      date: "2017-03-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3943923,
      accounts: 105713,
      dataSent: 492578,
      dataReceived: 2884246,
      callsIn: 511374,
      callsInDuration: 540652,
      callsOut: 323484,
      callsOutDuration: 332931,
      smses: 50080,
    },
    {
      date: "2017-03-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3554516,
      accounts: 110331,
      dataSent: 463008,
      dataReceived: 2665273,
      callsIn: 567564,
      callsInDuration: 552344,
      callsOut: 356225,
      callsOutDuration: 342850,
      smses: 54436,
    },
    {
      date: "2017-03-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3367129,
      accounts: 108415,
      dataSent: 416141,
      dataReceived: 2460235,
      callsIn: 549885,
      callsInDuration: 490342,
      callsOut: 344607,
      callsOutDuration: 316112,
      smses: 52524,
    },
    {
      date: "2017-03-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4112176,
      accounts: 98499,
      dataSent: 528571,
      dataReceived: 3140622,
      callsIn: 433522,
      callsInDuration: 427945,
      callsOut: 271497,
      callsOutDuration: 269525,
      smses: 44067,
    },
    {
      date: "2017-03-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3984527,
      accounts: 107823,
      dataSent: 458117,
      dataReceived: 2709985,
      callsIn: 519787,
      callsInDuration: 548853,
      callsOut: 330222,
      callsOutDuration: 336994,
      smses: 52984,
    },
    {
      date: "2017-03-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4188642,
      accounts: 106210,
      dataSent: 428858,
      dataReceived: 2493728,
      callsIn: 510531,
      callsInDuration: 545271,
      callsOut: 324273,
      callsOutDuration: 337539,
      smses: 51455,
    },
    {
      date: "2017-03-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3890318,
      accounts: 99918,
      dataSent: 437107,
      dataReceived: 2672673,
      callsIn: 482865,
      callsInDuration: 488753,
      callsOut: 312776,
      callsOutDuration: 312069,
      smses: 47449,
    },
    {
      date: "2017-03-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4282637,
      accounts: 106332,
      dataSent: 500199,
      dataReceived: 3127251,
      callsIn: 521026,
      callsInDuration: 544767,
      callsOut: 329542,
      callsOutDuration: 336641,
      smses: 50542,
    },
    {
      date: "2017-03-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3565842,
      accounts: 112538,
      dataSent: 494918,
      dataReceived: 2943706,
      callsIn: 585584,
      callsInDuration: 564415,
      callsOut: 363869,
      callsOutDuration: 353702,
      smses: 54404,
    },
    {
      date: "2017-03-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3412835,
      accounts: 111245,
      dataSent: 509523,
      dataReceived: 2966900,
      callsIn: 583491,
      callsInDuration: 508871,
      callsOut: 361434,
      callsOutDuration: 331967,
      smses: 52828,
    },
    {
      date: "2017-03-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3191292,
      accounts: 98016,
      dataSent: 471994,
      dataReceived: 2816056,
      callsIn: 449515,
      callsInDuration: 430552,
      callsOut: 278124,
      callsOutDuration: 280920,
      smses: 44897,
    },
    {
      date: "2017-03-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3357080,
      accounts: 109769,
      dataSent: 423160,
      dataReceived: 2570863,
      callsIn: 543482,
      callsInDuration: 539949,
      callsOut: 345330,
      callsOutDuration: 342317,
      smses: 54182,
    },
    {
      date: "2017-03-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3707195,
      accounts: 108365,
      dataSent: 476858,
      dataReceived: 2799665,
      callsIn: 523511,
      callsInDuration: 538914,
      callsOut: 339588,
      callsOutDuration: 345046,
      smses: 53104,
    },
    {
      date: "2017-03-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3843312,
      accounts: 107724,
      dataSent: 560371,
      dataReceived: 3267490,
      callsIn: 517424,
      callsInDuration: 541515,
      callsOut: 333713,
      callsOutDuration: 344758,
      smses: 52567,
    },
    {
      date: "2017-03-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 3837806,
      accounts: 108436,
      dataSent: 532104,
      dataReceived: 3126933,
      callsIn: 525147,
      callsInDuration: 535164,
      callsOut: 339207,
      callsOutDuration: 337997,
      smses: 51516,
    },
    {
      date: "2017-03-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3736231,
      accounts: 112195,
      dataSent: 542239,
      dataReceived: 3202779,
      callsIn: 562285,
      callsInDuration: 562311,
      callsOut: 359192,
      callsOutDuration: 348126,
      smses: 55146,
    },
    {
      date: "2017-03-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3652782,
      accounts: 112557,
      dataSent: 548125,
      dataReceived: 3191682,
      callsIn: 583097,
      callsInDuration: 508787,
      callsOut: 370992,
      callsOutDuration: 327896,
      smses: 52565,
    },
    {
      date: "2017-03-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3435383,
      accounts: 97581,
      dataSent: 528333,
      dataReceived: 3119214,
      callsIn: 444201,
      callsInDuration: 436149,
      callsOut: 278817,
      callsOutDuration: 276344,
      smses: 44380,
    },
    {
      date: "2017-03-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3644772,
      accounts: 108700,
      dataSent: 533709,
      dataReceived: 3197312,
      callsIn: 516840,
      callsInDuration: 551837,
      callsOut: 333435,
      callsOutDuration: 338352,
      smses: 53762,
    },
    {
      date: "2017-03-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3663964,
      accounts: 106126,
      dataSent: 498421,
      dataReceived: 2998617,
      callsIn: 497917,
      callsInDuration: 527377,
      callsOut: 323602,
      callsOutDuration: 327905,
      smses: 53195,
    },
    {
      date: "2017-03-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4442567,
      accounts: 106063,
      dataSent: 576140,
      dataReceived: 3426967,
      callsIn: 496710,
      callsInDuration: 551669,
      callsOut: 322695,
      callsOutDuration: 340343,
      smses: 50128,
    },
    {
      date: "2017-03-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4379452,
      accounts: 106634,
      dataSent: 533408,
      dataReceived: 3076943,
      callsIn: 502716,
      callsInDuration: 549312,
      callsOut: 328425,
      callsOutDuration: 339300,
      smses: 51420,
    },
    {
      date: "2017-03-31",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4484072,
      accounts: 113811,
      dataSent: 571449,
      dataReceived: 3307289,
      callsIn: 575115,
      callsInDuration: 567953,
      callsOut: 365161,
      callsOutDuration: 355284,
      smses: 54916,
    },
    {
      date: "2017-04-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4396789,
      accounts: 114671,
      dataSent: 547508,
      dataReceived: 3241003,
      callsIn: 588486,
      callsInDuration: 519904,
      callsOut: 374422,
      callsOutDuration: 343520,
      smses: 52597,
    },
    {
      date: "2017-04-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4478053,
      accounts: 110105,
      dataSent: 558171,
      dataReceived: 3254080,
      callsIn: 550862,
      callsInDuration: 461801,
      callsOut: 371317,
      callsOutDuration: 319845,
      smses: 50646,
    },
    {
      date: "2017-04-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4372309,
      accounts: 112102,
      dataSent: 542137,
      dataReceived: 3147736,
      callsIn: 555487,
      callsInDuration: 568851,
      callsOut: 345690,
      callsOutDuration: 352701,
      smses: 55619,
    },
    {
      date: "2017-04-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4410290,
      accounts: 111404,
      dataSent: 540738,
      dataReceived: 3155505,
      callsIn: 545443,
      callsInDuration: 557689,
      callsOut: 343964,
      callsOutDuration: 347704,
      smses: 53872,
    },
    {
      date: "2017-04-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4568846,
      accounts: 109855,
      dataSent: 558669,
      dataReceived: 3263987,
      callsIn: 533787,
      callsInDuration: 565229,
      callsOut: 339206,
      callsOutDuration: 350527,
      smses: 53485,
    },
    {
      date: "2017-04-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4551549,
      accounts: 109528,
      dataSent: 545978,
      dataReceived: 3136893,
      callsIn: 530073,
      callsInDuration: 561202,
      callsOut: 339005,
      callsOutDuration: 352016,
      smses: 52567,
    },
    {
      date: "2017-04-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4869007,
      accounts: 114969,
      dataSent: 588121,
      dataReceived: 3417764,
      callsIn: 588857,
      callsInDuration: 579649,
      callsOut: 372279,
      callsOutDuration: 364932,
      smses: 57049,
    },
    {
      date: "2017-04-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4507465,
      accounts: 113974,
      dataSent: 581285,
      dataReceived: 3291365,
      callsIn: 591730,
      callsInDuration: 533764,
      callsOut: 367935,
      callsOutDuration: 340572,
      smses: 54793,
    },
    {
      date: "2017-04-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4161770,
      accounts: 101067,
      dataSent: 539994,
      dataReceived: 3131830,
      callsIn: 464518,
      callsInDuration: 450242,
      callsOut: 284187,
      callsOutDuration: 280596,
      smses: 44204,
    },
    {
      date: "2017-04-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4469132,
      accounts: 112013,
      dataSent: 546854,
      dataReceived: 3157124,
      callsIn: 547534,
      callsInDuration: 569170,
      callsOut: 337203,
      callsOutDuration: 347612,
      smses: 53475,
    },
    {
      date: "2017-04-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4452122,
      accounts: 110652,
      dataSent: 549577,
      dataReceived: 3185783,
      callsIn: 538381,
      callsInDuration: 562897,
      callsOut: 338188,
      callsOutDuration: 346485,
      smses: 51711,
    },
    {
      date: "2017-04-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4440592,
      accounts: 110149,
      dataSent: 541057,
      dataReceived: 3151341,
      callsIn: 533163,
      callsInDuration: 560618,
      callsOut: 340326,
      callsOutDuration: 345350,
      smses: 51112,
    },
    {
      date: "2017-04-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4557326,
      accounts: 115448,
      dataSent: 570424,
      dataReceived: 3273213,
      callsIn: 596117,
      callsInDuration: 580585,
      callsOut: 371947,
      callsOutDuration: 359359,
      smses: 54715,
    },
    {
      date: "2017-04-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4284353,
      accounts: 102352,
      dataSent: 564558,
      dataReceived: 3176873,
      callsIn: 467750,
      callsInDuration: 464876,
      callsOut: 291997,
      callsOutDuration: 294241,
      smses: 45656,
    },
    {
      date: "2017-04-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4336425,
      accounts: 108444,
      dataSent: 568491,
      dataReceived: 3176791,
      callsIn: 532442,
      callsInDuration: 464971,
      callsOut: 333632,
      callsOutDuration: 309081,
      smses: 50294,
    },
    {
      date: "2017-04-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4226066,
      accounts: 99849,
      dataSent: 559812,
      dataReceived: 3161991,
      callsIn: 448484,
      callsInDuration: 433736,
      callsOut: 281857,
      callsOutDuration: 286385,
      smses: 45123,
    },
    {
      date: "2017-04-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4465466,
      accounts: 114086,
      dataSent: 562022,
      dataReceived: 3233406,
      callsIn: 565643,
      callsInDuration: 577774,
      callsOut: 354601,
      callsOutDuration: 359939,
      smses: 56702,
    },
    {
      date: "2017-04-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4502718,
      accounts: 113887,
      dataSent: 561043,
      dataReceived: 3310736,
      callsIn: 557682,
      callsInDuration: 584977,
      callsOut: 354538,
      callsOutDuration: 368247,
      smses: 54444,
    },
    {
      date: "2017-04-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4466673,
      accounts: 111085,
      dataSent: 572834,
      dataReceived: 3361579,
      callsIn: 537156,
      callsInDuration: 576686,
      callsOut: 337069,
      callsOutDuration: 357826,
      smses: 54167,
    },
    {
      date: "2017-04-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4536737,
      accounts: 113308,
      dataSent: 541244,
      dataReceived: 3154223,
      callsIn: 557726,
      callsInDuration: 575706,
      callsOut: 360602,
      callsOutDuration: 366736,
      smses: 54092,
    },
    {
      date: "2017-04-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4713670,
      accounts: 119279,
      dataSent: 600224,
      dataReceived: 3497088,
      callsIn: 622005,
      callsInDuration: 603097,
      callsOut: 391375,
      callsOutDuration: 379898,
      smses: 58792,
    },
    {
      date: "2017-04-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 3994057,
      accounts: 116524,
      dataSent: 509041,
      dataReceived: 2912719,
      callsIn: 599538,
      callsInDuration: 526006,
      callsOut: 343458,
      callsOutDuration: 308968,
      smses: 50208,
    },
    {
      date: "2017-04-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3796983,
      accounts: 101923,
      dataSent: 534522,
      dataReceived: 3066072,
      callsIn: 460468,
      callsInDuration: 454160,
      callsOut: 269486,
      callsOutDuration: 280322,
      smses: 43178,
    },
    {
      date: "2017-04-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4299573,
      accounts: 114599,
      dataSent: 540692,
      dataReceived: 3261443,
      callsIn: 557060,
      callsInDuration: 575862,
      callsOut: 344435,
      callsOutDuration: 355327,
      smses: 54670,
    },
    {
      date: "2017-04-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4234370,
      accounts: 112553,
      dataSent: 482289,
      dataReceived: 2883873,
      callsIn: 544760,
      callsInDuration: 563922,
      callsOut: 322343,
      callsOutDuration: 330062,
      smses: 51387,
    },
    {
      date: "2017-04-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4362901,
      accounts: 110769,
      dataSent: 579649,
      dataReceived: 3577600,
      callsIn: 531755,
      callsInDuration: 559221,
      callsOut: 333985,
      callsOutDuration: 346236,
      smses: 50317,
    },
    {
      date: "2017-04-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4371285,
      accounts: 111665,
      dataSent: 556364,
      dataReceived: 3390555,
      callsIn: 534980,
      callsInDuration: 564089,
      callsOut: 352980,
      callsOutDuration: 362152,
      smses: 53192,
    },
    {
      date: "2017-04-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4483080,
      accounts: 118032,
      dataSent: 577899,
      dataReceived: 3398835,
      callsIn: 607145,
      callsInDuration: 587039,
      callsOut: 402323,
      callsOutDuration: 381045,
      smses: 58004,
    },
    {
      date: "2017-04-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4939483,
      accounts: 117710,
      dataSent: 585868,
      dataReceived: 3465759,
      callsIn: 612826,
      callsInDuration: 524258,
      callsOut: 399134,
      callsOutDuration: 353749,
      smses: 55276,
    },
    {
      date: "2017-04-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4255506,
      accounts: 104706,
      dataSent: 573027,
      dataReceived: 3409649,
      callsIn: 476263,
      callsInDuration: 446893,
      callsOut: 311659,
      callsOutDuration: 304196,
      smses: 46777,
    },
    {
      date: "2017-05-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4111328,
      accounts: 104081,
      dataSent: 536187,
      dataReceived: 3216643,
      callsIn: 465466,
      callsInDuration: 470287,
      callsOut: 307512,
      callsOutDuration: 307380,
      smses: 47034,
    },
    {
      date: "2017-05-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4473018,
      accounts: 113709,
      dataSent: 559065,
      dataReceived: 3501151,
      callsIn: 548650,
      callsInDuration: 565812,
      callsOut: 362156,
      callsOutDuration: 353957,
      smses: 53488,
    },
    {
      date: "2017-05-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4577951,
      accounts: 114474,
      dataSent: 555644,
      dataReceived: 3425768,
      callsIn: 560338,
      callsInDuration: 592516,
      callsOut: 366635,
      callsOutDuration: 374633,
      smses: 63146,
    },
    {
      date: "2017-05-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4382747,
      accounts: 112885,
      dataSent: 546087,
      dataReceived: 3311818,
      callsIn: 537385,
      callsInDuration: 569260,
      callsOut: 347882,
      callsOutDuration: 354735,
      smses: 53629,
    },
    {
      date: "2017-05-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4443070,
      accounts: 117970,
      dataSent: 545402,
      dataReceived: 3314080,
      callsIn: 585975,
      callsInDuration: 574701,
      callsOut: 371288,
      callsOutDuration: 357434,
      smses: 55485,
    },
    {
      date: "2017-05-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4373804,
      accounts: 117462,
      dataSent: 542870,
      dataReceived: 3151540,
      callsIn: 580368,
      callsInDuration: 513183,
      callsOut: 372980,
      callsOutDuration: 339409,
      smses: 51865,
    },
    {
      date: "2017-05-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4303090,
      accounts: 102468,
      dataSent: 563556,
      dataReceived: 3354078,
      callsIn: 447369,
      callsInDuration: 449688,
      callsOut: 296165,
      callsOutDuration: 296476,
      smses: 45488,
    },
    {
      date: "2017-05-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4351567,
      accounts: 115470,
      dataSent: 543689,
      dataReceived: 3228857,
      callsIn: 550397,
      callsInDuration: 567916,
      callsOut: 357261,
      callsOutDuration: 355263,
      smses: 54334,
    },
    {
      date: "2017-05-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4296188,
      accounts: 113461,
      dataSent: 542560,
      dataReceived: 3288113,
      callsIn: 534230,
      callsInDuration: 565646,
      callsOut: 339836,
      callsOutDuration: 348367,
      smses: 53556,
    },
    {
      date: "2017-05-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4323018,
      accounts: 113488,
      dataSent: 539934,
      dataReceived: 3260022,
      callsIn: 530315,
      callsInDuration: 562317,
      callsOut: 338007,
      callsOutDuration: 339936,
      smses: 51130,
    },
    {
      date: "2017-05-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4314108,
      accounts: 113538,
      dataSent: 548041,
      dataReceived: 3338479,
      callsIn: 535728,
      callsInDuration: 567674,
      callsOut: 342634,
      callsOutDuration: 344909,
      smses: 51830,
    },
    {
      date: "2017-05-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4408030,
      accounts: 121841,
      dataSent: 571209,
      dataReceived: 3304473,
      callsIn: 627217,
      callsInDuration: 590006,
      callsOut: 386962,
      callsOutDuration: 360020,
      smses: 55905,
    },
    {
      date: "2017-05-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4387011,
      accounts: 123113,
      dataSent: 577528,
      dataReceived: 3315809,
      callsIn: 647056,
      callsInDuration: 533237,
      callsOut: 401293,
      callsOutDuration: 338975,
      smses: 54683,
    },
    {
      date: "2017-05-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4293201,
      accounts: 110665,
      dataSent: 582694,
      dataReceived: 3204169,
      callsIn: 514222,
      callsInDuration: 486254,
      callsOut: 328622,
      callsOutDuration: 325575,
      smses: 49027,
    },
    {
      date: "2017-05-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4276091,
      accounts: 115516,
      dataSent: 575881,
      dataReceived: 3368294,
      callsIn: 548158,
      callsInDuration: 574773,
      callsOut: 337998,
      callsOutDuration: 345822,
      smses: 52621,
    },
    {
      date: "2017-05-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4350670,
      accounts: 115547,
      dataSent: 519364,
      dataReceived: 3116554,
      callsIn: 543387,
      callsInDuration: 568922,
      callsOut: 337499,
      callsOutDuration: 338693,
      smses: 51505,
    },
    {
      date: "2017-05-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4805813,
      accounts: 114199,
      dataSent: 571601,
      dataReceived: 3379470,
      callsIn: 533954,
      callsInDuration: 564053,
      callsOut: 332523,
      callsOutDuration: 333963,
      smses: 51976,
    },
    {
      date: "2017-05-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4853130,
      accounts: 114057,
      dataSent: 604535,
      dataReceived: 3653204,
      callsIn: 532230,
      callsInDuration: 562671,
      callsOut: 328313,
      callsOutDuration: 329600,
      smses: 51848,
    },
    {
      date: "2017-05-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4520227,
      accounts: 119817,
      dataSent: 605670,
      dataReceived: 3571982,
      callsIn: 600378,
      callsInDuration: 590278,
      callsOut: 370712,
      callsOutDuration: 354794,
      smses: 54245,
    },
    {
      date: "2017-05-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4330011,
      accounts: 118281,
      dataSent: 597523,
      dataReceived: 3488689,
      callsIn: 584743,
      callsInDuration: 511725,
      callsOut: 373155,
      callsOutDuration: 332529,
      smses: 51678,
    },
    {
      date: "2017-05-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 3970996,
      accounts: 101886,
      dataSent: 557085,
      dataReceived: 3311849,
      callsIn: 442016,
      callsInDuration: 439244,
      callsOut: 289856,
      callsOutDuration: 287124,
      smses: 44201,
    },
    {
      date: "2017-05-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4694328,
      accounts: 113654,
      dataSent: 545410,
      dataReceived: 3385140,
      callsIn: 524954,
      callsInDuration: 558063,
      callsOut: 342014,
      callsOutDuration: 344899,
      smses: 50852,
    },
    {
      date: "2017-05-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4247624,
      accounts: 112772,
      dataSent: 551505,
      dataReceived: 3432539,
      callsIn: 518520,
      callsInDuration: 562464,
      callsOut: 334261,
      callsOutDuration: 343923,
      smses: 50828,
    },
    {
      date: "2017-05-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4279809,
      accounts: 112891,
      dataSent: 563251,
      dataReceived: 3407036,
      callsIn: 518613,
      callsInDuration: 578286,
      callsOut: 323379,
      callsOutDuration: 337495,
      smses: 48295,
    },
    {
      date: "2017-05-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4487316,
      accounts: 116358,
      dataSent: 585486,
      dataReceived: 3510442,
      callsIn: 565351,
      callsInDuration: 582769,
      callsOut: 353523,
      callsOutDuration: 349370,
      smses: 53008,
    },
    {
      date: "2017-05-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4222612,
      accounts: 110731,
      dataSent: 555829,
      dataReceived: 3270608,
      callsIn: 514560,
      callsInDuration: 506201,
      callsOut: 324683,
      callsOutDuration: 313236,
      smses: 47588,
    },
    {
      date: "2017-05-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4181040,
      accounts: 112234,
      dataSent: 591684,
      dataReceived: 3343235,
      callsIn: 536277,
      callsInDuration: 480217,
      callsOut: 342325,
      callsOutDuration: 309639,
      smses: 47390,
    },
    {
      date: "2017-05-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4141433,
      accounts: 113891,
      dataSent: 596037,
      dataReceived: 3389793,
      callsIn: 532653,
      callsInDuration: 569009,
      callsOut: 339226,
      callsOutDuration: 343290,
      smses: 51130,
    },
    {
      date: "2017-05-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4098938,
      accounts: 113046,
      dataSent: 567989,
      dataReceived: 3322162,
      callsIn: 523520,
      callsInDuration: 576091,
      callsOut: 332045,
      callsOutDuration: 341519,
      smses: 49968,
    },
    {
      date: "2017-05-31",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4123422,
      accounts: 114237,
      dataSent: 532426,
      dataReceived: 3149313,
      callsIn: 539607,
      callsInDuration: 580708,
      callsOut: 343941,
      callsOutDuration: 348124,
      smses: 51240,
    },
    {
      date: "2017-06-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4448478,
      accounts: 117242,
      dataSent: 614722,
      dataReceived: 3602271,
      callsIn: 564037,
      callsInDuration: 586465,
      callsOut: 360693,
      callsOutDuration: 359500,
      smses: 52064,
    },
    {
      date: "2017-06-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4410819,
      accounts: 120739,
      dataSent: 612192,
      dataReceived: 3597077,
      callsIn: 610196,
      callsInDuration: 596060,
      callsOut: 386528,
      callsOutDuration: 371630,
      smses: 55577,
    },
    {
      date: "2017-06-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4285856,
      accounts: 120545,
      dataSent: 628986,
      dataReceived: 3705004,
      callsIn: 606791,
      callsInDuration: 534499,
      callsOut: 390345,
      callsOutDuration: 356174,
      smses: 52945,
    },
    {
      date: "2017-06-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4156106,
      accounts: 105306,
      dataSent: 604170,
      dataReceived: 3620208,
      callsIn: 462008,
      callsInDuration: 469076,
      callsOut: 297431,
      callsOutDuration: 304636,
      smses: 43933,
    },
    {
      date: "2017-06-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4319211,
      accounts: 116663,
      dataSent: 622120,
      dataReceived: 3678230,
      callsIn: 549940,
      callsInDuration: 594883,
      callsOut: 356084,
      callsOutDuration: 371728,
      smses: 51917,
    },
    {
      date: "2017-06-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4366062,
      accounts: 113893,
      dataSent: 592776,
      dataReceived: 3778174,
      callsIn: 524515,
      callsInDuration: 587901,
      callsOut: 339070,
      callsOutDuration: 360661,
      smses: 52078,
    },
    {
      date: "2017-06-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4381038,
      accounts: 113500,
      dataSent: 567830,
      dataReceived: 3660389,
      callsIn: 520464,
      callsInDuration: 578092,
      callsOut: 344105,
      callsOutDuration: 366247,
      smses: 49830,
    },
    {
      date: "2017-06-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4522070,
      accounts: 115330,
      dataSent: 602012,
      dataReceived: 3750622,
      callsIn: 539743,
      callsInDuration: 600108,
      callsOut: 339992,
      callsOutDuration: 369730,
      smses: 48864,
    },
    {
      date: "2017-06-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4519941,
      accounts: 122023,
      dataSent: 585872,
      dataReceived: 3596460,
      callsIn: 611366,
      callsInDuration: 603636,
      callsOut: 384750,
      callsOutDuration: 381110,
      smses: 51773,
    },
    {
      date: "2017-06-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4750601,
      accounts: 122208,
      dataSent: 667436,
      dataReceived: 4012906,
      callsIn: 614631,
      callsInDuration: 544995,
      callsOut: 392338,
      callsOutDuration: 355922,
      smses: 51226,
    },
    {
      date: "2017-06-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4229229,
      accounts: 104267,
      dataSent: 588335,
      dataReceived: 3605097,
      callsIn: 449166,
      callsInDuration: 464508,
      callsOut: 286477,
      callsOutDuration: 299792,
      smses: 40431,
    },
    {
      date: "2017-06-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4445381,
      accounts: 115644,
      dataSent: 577376,
      dataReceived: 3455284,
      callsIn: 537960,
      callsInDuration: 599041,
      callsOut: 330608,
      callsOutDuration: 361021,
      smses: 47769,
    },
    {
      date: "2017-06-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4659086,
      accounts: 113855,
      dataSent: 581817,
      dataReceived: 3590810,
      callsIn: 524885,
      callsInDuration: 601020,
      callsOut: 327391,
      callsOutDuration: 368968,
      smses: 47035,
    },
    {
      date: "2017-06-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2732020,
      accounts: 89121,
      dataSent: 246231,
      dataReceived: 1562952,
      callsIn: 252431,
      callsInDuration: 242058,
      callsOut: 150716,
      callsOutDuration: 140848,
      smses: 20809,
    },
    {
      date: "2017-06-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4404963,
      accounts: 116014,
      dataSent: 625623,
      dataReceived: 4040716,
      callsIn: 544601,
      callsInDuration: 578913,
      callsOut: 337351,
      callsOutDuration: 353282,
      smses: 49727,
    },
    {
      date: "2017-06-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4306872,
      accounts: 124350,
      dataSent: 659995,
      dataReceived: 4186542,
      callsIn: 640866,
      callsInDuration: 609437,
      callsOut: 398672,
      callsOutDuration: 380613,
      smses: 56650,
    },
    {
      date: "2017-06-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4308167,
      accounts: 125476,
      dataSent: 697397,
      dataReceived: 4334449,
      callsIn: 652330,
      callsInDuration: 547554,
      callsOut: 414158,
      callsOutDuration: 364711,
      smses: 55431,
    },
    {
      date: "2017-06-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4009530,
      accounts: 111475,
      dataSent: 684238,
      dataReceived: 4179559,
      callsIn: 508103,
      callsInDuration: 494729,
      callsOut: 335814,
      callsOutDuration: 350049,
      smses: 49340,
    },
    {
      date: "2017-06-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4156991,
      accounts: 116957,
      dataSent: 661186,
      dataReceived: 4187361,
      callsIn: 548646,
      callsInDuration: 592610,
      callsOut: 352651,
      callsOutDuration: 374841,
      smses: 52349,
    },
    {
      date: "2017-06-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4212033,
      accounts: 115546,
      dataSent: 642256,
      dataReceived: 4156703,
      callsIn: 529888,
      callsInDuration: 579580,
      callsOut: 345432,
      callsOutDuration: 370484,
      smses: 53031,
    },
    {
      date: "2017-06-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4286165,
      accounts: 114427,
      dataSent: 655767,
      dataReceived: 4147781,
      callsIn: 524266,
      callsInDuration: 583153,
      callsOut: 346781,
      callsOutDuration: 376020,
      smses: 52476,
    },
    {
      date: "2017-06-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4313224,
      accounts: 115485,
      dataSent: 608950,
      dataReceived: 3785737,
      callsIn: 531254,
      callsInDuration: 583841,
      callsOut: 350814,
      callsOutDuration: 369746,
      smses: 51129,
    },
    {
      date: "2017-06-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3674166,
      accounts: 109547,
      dataSent: 592720,
      dataReceived: 3741073,
      callsIn: 468797,
      callsInDuration: 433802,
      callsOut: 300057,
      callsOutDuration: 276528,
      smses: 39525,
    },
    {
      date: "2017-06-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4537367,
      accounts: 123511,
      dataSent: 696638,
      dataReceived: 4303720,
      callsIn: 618269,
      callsInDuration: 546442,
      callsOut: 392774,
      callsOutDuration: 368360,
      smses: 54058,
    },
    {
      date: "2017-06-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4260202,
      accounts: 107161,
      dataSent: 646785,
      dataReceived: 4063646,
      callsIn: 469919,
      callsInDuration: 473269,
      callsOut: 304806,
      callsOutDuration: 318185,
      smses: 44762,
    },
    {
      date: "2017-06-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4465585,
      accounts: 116898,
      dataSent: 617720,
      dataReceived: 3982945,
      callsIn: 539135,
      callsInDuration: 591198,
      callsOut: 344478,
      callsOutDuration: 373237,
      smses: 50587,
    },
    {
      date: "2017-06-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5050518,
      accounts: 117079,
      dataSent: 660807,
      dataReceived: 4307528,
      callsIn: 537851,
      callsInDuration: 596984,
      callsOut: 344814,
      callsOutDuration: 375491,
      smses: 50735,
    },
    {
      date: "2017-06-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4633189,
      accounts: 117703,
      dataSent: 662674,
      dataReceived: 4322598,
      callsIn: 547264,
      callsInDuration: 598084,
      callsOut: 356063,
      callsOutDuration: 381201,
      smses: 51516,
    },
    {
      date: "2017-06-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4656033,
      accounts: 117470,
      dataSent: 620109,
      dataReceived: 4116890,
      callsIn: 538098,
      callsInDuration: 586953,
      callsOut: 354578,
      callsOutDuration: 378829,
      smses: 51915,
    },
    {
      date: "2017-06-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4937957,
      accounts: 129460,
      dataSent: 698594,
      dataReceived: 4506845,
      callsIn: 664353,
      callsInDuration: 642340,
      callsOut: 435335,
      callsOutDuration: 427801,
      smses: 58861,
    },
    {
      date: "2017-07-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4874315,
      accounts: 127225,
      dataSent: 724031,
      dataReceived: 4622906,
      callsIn: 1291514,
      callsInDuration: 1133744,
      callsOut: 427239,
      callsOutDuration: 395406,
      smses: 57457,
    },
    {
      date: "2017-07-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4378641,
      accounts: 110996,
      dataSent: 680504,
      dataReceived: 4315690,
      callsIn: 981968,
      callsInDuration: 961379,
      callsOut: 322992,
      callsOutDuration: 332937,
      smses: 46533,
    },
    {
      date: "2017-07-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4583492,
      accounts: 122517,
      dataSent: 614347,
      dataReceived: 3955173,
      callsIn: 1170002,
      callsInDuration: 1219214,
      callsOut: 385000,
      callsOutDuration: 399042,
      smses: 54205,
    },
    {
      date: "2017-07-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3987397,
      accounts: 121132,
      dataSent: 647952,
      dataReceived: 4259461,
      callsIn: 1131178,
      callsInDuration: 1247648,
      callsOut: 368893,
      callsOutDuration: 402438,
      smses: 53432,
    },
    {
      date: "2017-07-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4473314,
      accounts: 121149,
      dataSent: 638520,
      dataReceived: 4250240,
      callsIn: 1128280,
      callsInDuration: 1230552,
      callsOut: 371169,
      callsOutDuration: 400282,
      smses: 54234,
    },
    {
      date: "2017-07-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4726379,
      accounts: 121471,
      dataSent: 632049,
      dataReceived: 4248188,
      callsIn: 1125640,
      callsInDuration: 1244173,
      callsOut: 373741,
      callsOutDuration: 405494,
      smses: 52378,
    },
    {
      date: "2017-07-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5341164,
      accounts: 127988,
      dataSent: 753839,
      dataReceived: 5041969,
      callsIn: 1255136,
      callsInDuration: 1255999,
      callsOut: 420199,
      callsOutDuration: 424581,
      smses: 55979,
    },
    {
      date: "2017-07-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4970068,
      accounts: 127369,
      dataSent: 648661,
      dataReceived: 4242270,
      callsIn: 1265760,
      callsInDuration: 1135494,
      callsOut: 416613,
      callsOutDuration: 394423,
      smses: 53200,
    },
    {
      date: "2017-07-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4920563,
      accounts: 110873,
      dataSent: 750994,
      dataReceived: 4947463,
      callsIn: 976462,
      callsInDuration: 973244,
      callsOut: 318536,
      callsOutDuration: 327473,
      smses: 45700,
    },
    {
      date: "2017-07-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4793205,
      accounts: 122863,
      dataSent: 687534,
      dataReceived: 4483194,
      callsIn: 1153494,
      callsInDuration: 1257990,
      callsOut: 375544,
      callsOutDuration: 409053,
      smses: 53445,
    },
    {
      date: "2017-07-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4563694,
      accounts: 120918,
      dataSent: 604575,
      dataReceived: 4000655,
      callsIn: 1120380,
      callsInDuration: 1248279,
      callsOut: 367778,
      callsOutDuration: 403035,
      smses: 51893,
    },
    {
      date: "2017-07-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4939809,
      accounts: 121369,
      dataSent: 709090,
      dataReceived: 4703045,
      callsIn: 1121448,
      callsInDuration: 1252566,
      callsOut: 366786,
      callsOutDuration: 404490,
      smses: 52520,
    },
    {
      date: "2017-07-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4318463,
      accounts: 113224,
      dataSent: 629145,
      dataReceived: 4066525,
      callsIn: 938366,
      callsInDuration: 1079971,
      callsOut: 306981,
      callsOutDuration: 343829,
      smses: 43996,
    },
    {
      date: "2017-07-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4989734,
      accounts: 129649,
      dataSent: 679500,
      dataReceived: 4454817,
      callsIn: 1291280,
      callsInDuration: 1291602,
      callsOut: 420398,
      callsOutDuration: 423873,
      smses: 55868,
    },
    {
      date: "2017-07-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4402262,
      accounts: 122156,
      dataSent: 640801,
      dataReceived: 4095056,
      callsIn: 1104158,
      callsInDuration: 970543,
      callsOut: 354962,
      callsOutDuration: 325948,
      smses: 46617,
    },
    {
      date: "2017-07-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4622002,
      accounts: 113884,
      dataSent: 698136,
      dataReceived: 4501173,
      callsIn: 1009862,
      callsInDuration: 999417,
      callsOut: 329997,
      callsOutDuration: 333296,
      smses: 46931,
    },
    {
      date: "2017-07-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4801117,
      accounts: 124609,
      dataSent: 679304,
      dataReceived: 4477378,
      callsIn: 1171382,
      callsInDuration: 1280840,
      callsOut: 386870,
      callsOutDuration: 417919,
      smses: 52805,
    },
    {
      date: "2017-07-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4865331,
      accounts: 122568,
      dataSent: 672750,
      dataReceived: 4491128,
      callsIn: 1133474,
      callsInDuration: 1273604,
      callsOut: 375151,
      callsOutDuration: 411548,
      smses: 53270,
    },
    {
      date: "2017-07-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4766695,
      accounts: 122662,
      dataSent: 681770,
      dataReceived: 4504258,
      callsIn: 1132014,
      callsInDuration: 1287404,
      callsOut: 370214,
      callsOutDuration: 407397,
      smses: 52716,
    },
    {
      date: "2017-07-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4522259,
      accounts: 115882,
      dataSent: 620775,
      dataReceived: 4120380,
      callsIn: 980274,
      callsInDuration: 1115404,
      callsOut: 316676,
      callsOutDuration: 341570,
      smses: 49780,
    },
    {
      date: "2017-07-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5088112,
      accounts: 129331,
      dataSent: 737453,
      dataReceived: 4921929,
      callsIn: 1287968,
      callsInDuration: 1294489,
      callsOut: 429322,
      callsOutDuration: 419524,
      smses: 58598,
    },
    {
      date: "2017-07-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5121774,
      accounts: 128956,
      dataSent: 736977,
      dataReceived: 4827693,
      callsIn: 1279568,
      callsInDuration: 1123703,
      callsOut: 440701,
      callsOutDuration: 396979,
      smses: 56973,
    },
    {
      date: "2017-07-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4893698,
      accounts: 113267,
      dataSent: 728214,
      dataReceived: 4728538,
      callsIn: 990476,
      callsInDuration: 977761,
      callsOut: 344914,
      callsOutDuration: 338698,
      smses: 47626,
    },
    {
      date: "2017-07-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4849324,
      accounts: 121518,
      dataSent: 698885,
      dataReceived: 4560511,
      callsIn: 1111296,
      callsInDuration: 1195009,
      callsOut: 383035,
      callsOutDuration: 407497,
      smses: 52555,
    },
    {
      date: "2017-07-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4881192,
      accounts: 124731,
      dataSent: 716557,
      dataReceived: 4534487,
      callsIn: 1171200,
      callsInDuration: 1273033,
      callsOut: 392923,
      callsOutDuration: 422990,
      smses: 55205,
    },
    {
      date: "2017-07-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5040175,
      accounts: 123504,
      dataSent: 700154,
      dataReceived: 4719720,
      callsIn: 1141948,
      callsInDuration: 1276230,
      callsOut: 385051,
      callsOutDuration: 422447,
      smses: 54511,
    },
    {
      date: "2017-07-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5081281,
      accounts: 123787,
      dataSent: 708195,
      dataReceived: 4746214,
      callsIn: 1134818,
      callsInDuration: 1289101,
      callsOut: 381788,
      callsOutDuration: 423140,
      smses: 56098,
    },
    {
      date: "2017-07-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5140135,
      accounts: 130497,
      dataSent: 730489,
      dataReceived: 4798993,
      callsIn: 1291836,
      callsInDuration: 1309991,
      callsOut: 437740,
      callsOutDuration: 437583,
      smses: 59550,
    },
    {
      date: "2017-07-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5180085,
      accounts: 131432,
      dataSent: 744079,
      dataReceived: 4786144,
      callsIn: 1309556,
      callsInDuration: 1150786,
      callsOut: 444286,
      callsOutDuration: 404147,
      smses: 57671,
    },
    {
      date: "2017-07-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4917397,
      accounts: 114124,
      dataSent: 726320,
      dataReceived: 4832596,
      callsIn: 488732,
      callsInDuration: 498102,
      callsOut: 340573,
      callsOutDuration: 355044,
      smses: 48089,
    },
    {
      date: "2017-07-31",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5082346,
      accounts: 125036,
      dataSent: 689509,
      dataReceived: 4695164,
      callsIn: 573107,
      callsInDuration: 598909,
      callsOut: 395574,
      callsOutDuration: 409109,
      smses: 54516,
    },
    {
      date: "2017-08-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 1511159,
      accounts: 75047,
      dataSent: 188333,
      dataReceived: 1233959,
      callsIn: 96265,
      callsInDuration: 97302,
      callsOut: 65797,
      callsOutDuration: 66200,
      smses: 9720,
    },
    {
      date: "2017-08-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4369140,
      accounts: 129528,
      dataSent: 620764,
      dataReceived: 4332301,
      callsIn: 602470,
      callsInDuration: 624209,
      callsOut: 375272,
      callsOutDuration: 401553,
      smses: 53127,
    },
    {
      date: "2017-08-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5105579,
      accounts: 129976,
      dataSent: 690056,
      dataReceived: 4759154,
      callsIn: 605289,
      callsInDuration: 653380,
      callsOut: 391679,
      callsOutDuration: 432901,
      smses: 55368,
    },
    {
      date: "2017-08-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5271119,
      accounts: 135424,
      dataSent: 737312,
      dataReceived: 4972361,
      callsIn: 668858,
      callsInDuration: 661233,
      callsOut: 440361,
      callsOutDuration: 447245,
      smses: 58752,
    },
    {
      date: "2017-08-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4780329,
      accounts: 126005,
      dataSent: 713195,
      dataReceived: 4718931,
      callsIn: 565162,
      callsInDuration: 500681,
      callsOut: 376831,
      callsOutDuration: 350379,
      smses: 51425,
    },
    {
      date: "2017-08-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4916324,
      accounts: 117382,
      dataSent: 710751,
      dataReceived: 4790142,
      callsIn: 510540,
      callsInDuration: 507743,
      callsOut: 343706,
      callsOutDuration: 345456,
      smses: 51216,
    },
    {
      date: "2017-08-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4894761,
      accounts: 127654,
      dataSent: 644448,
      dataReceived: 4354601,
      callsIn: 584309,
      callsInDuration: 638886,
      callsOut: 391317,
      callsOutDuration: 412928,
      smses: 55316,
    },
    {
      date: "2017-08-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4928400,
      accounts: 127233,
      dataSent: 604523,
      dataReceived: 4058760,
      callsIn: 584020,
      callsInDuration: 651279,
      callsOut: 374731,
      callsOutDuration: 405190,
      smses: 53576,
    },
    {
      date: "2017-08-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5019826,
      accounts: 128227,
      dataSent: 625038,
      dataReceived: 4130484,
      callsIn: 599889,
      callsInDuration: 652499,
      callsOut: 384574,
      callsOutDuration: 400220,
      smses: 55010,
    },
    {
      date: "2017-08-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5522601,
      accounts: 132901,
      dataSent: 821516,
      dataReceived: 5524284,
      callsIn: 647798,
      callsInDuration: 677873,
      callsOut: 420599,
      callsOutDuration: 434170,
      smses: 58467,
    },
    {
      date: "2017-08-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5205629,
      accounts: 125888,
      dataSent: 716204,
      dataReceived: 4763736,
      callsIn: 586502,
      callsInDuration: 569788,
      callsOut: 390891,
      callsOutDuration: 397850,
      smses: 53495,
    },
    {
      date: "2017-08-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5235091,
      accounts: 126458,
      dataSent: 768645,
      dataReceived: 4918494,
      callsIn: 598200,
      callsInDuration: 538263,
      callsOut: 405585,
      callsOutDuration: 381316,
      smses: 54688,
    },
    {
      date: "2017-08-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5086003,
      accounts: 113886,
      dataSent: 730934,
      dataReceived: 4811724,
      callsIn: 486200,
      callsInDuration: 488773,
      callsOut: 327392,
      callsOutDuration: 339258,
      smses: 48186,
    },
    {
      date: "2017-08-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5281502,
      accounts: 128683,
      dataSent: 696757,
      dataReceived: 4640818,
      callsIn: 599610,
      callsInDuration: 648740,
      callsOut: 401019,
      callsOutDuration: 436862,
      smses: 56510,
    },
    {
      date: "2017-08-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5175547,
      accounts: 129110,
      dataSent: 708740,
      dataReceived: 4735580,
      callsIn: 597059,
      callsInDuration: 655570,
      callsOut: 397503,
      callsOutDuration: 438760,
      smses: 57258,
    },
    {
      date: "2017-08-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5364420,
      accounts: 133302,
      dataSent: 718557,
      dataReceived: 4785861,
      callsIn: 605230,
      callsInDuration: 664223,
      callsOut: 401324,
      callsOutDuration: 443571,
      smses: 56059,
    },
    {
      date: "2017-08-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5454751,
      accounts: 132192,
      dataSent: 717831,
      dataReceived: 4686461,
      callsIn: 588997,
      callsInDuration: 673730,
      callsOut: 404161,
      callsOutDuration: 451250,
      smses: 57211,
    },
    {
      date: "2017-08-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4765261,
      accounts: 134454,
      dataSent: 540188,
      dataReceived: 3600597,
      callsIn: 603994,
      callsInDuration: 578582,
      callsOut: 418461,
      callsOutDuration: 396590,
      smses: 54951,
    },
    {
      date: "2017-08-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5441783,
      accounts: 137193,
      dataSent: 864426,
      dataReceived: 5591955,
      callsIn: 648233,
      callsInDuration: 592827,
      callsOut: 453646,
      callsOutDuration: 426723,
      smses: 57226,
    },
    {
      date: "2017-08-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5058138,
      accounts: 118572,
      dataSent: 767505,
      dataReceived: 5171197,
      callsIn: 486565,
      callsInDuration: 510734,
      callsOut: 348290,
      callsOutDuration: 371266,
      smses: 50535,
    },
    {
      date: "2017-08-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5318673,
      accounts: 130992,
      dataSent: 695682,
      dataReceived: 4680165,
      callsIn: 573623,
      callsInDuration: 659868,
      callsOut: 406244,
      callsOutDuration: 442839,
      smses: 57729,
    },
    {
      date: "2017-08-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5471605,
      accounts: 129187,
      dataSent: 772187,
      dataReceived: 5238606,
      callsIn: 559895,
      callsInDuration: 661121,
      callsOut: 401675,
      callsOutDuration: 442095,
      smses: 56840,
    },
    {
      date: "2017-08-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5436807,
      accounts: 129747,
      dataSent: 776725,
      dataReceived: 5178410,
      callsIn: 562733,
      callsInDuration: 665645,
      callsOut: 404699,
      callsOutDuration: 464301,
      smses: 56674,
    },
    {
      date: "2017-08-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5347663,
      accounts: 132706,
      dataSent: 738590,
      dataReceived: 4977759,
      callsIn: 581242,
      callsInDuration: 679742,
      callsOut: 402067,
      callsOutDuration: 436928,
      smses: 56044,
    },
    {
      date: "2017-08-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5544155,
      accounts: 138487,
      dataSent: 786653,
      dataReceived: 5174575,
      callsIn: 645847,
      callsInDuration: 683579,
      callsOut: 449333,
      callsOutDuration: 470185,
      smses: 60968,
    },
    {
      date: "2017-08-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5502373,
      accounts: 136185,
      dataSent: 757146,
      dataReceived: 4807534,
      callsIn: 631998,
      callsInDuration: 587783,
      callsOut: 452901,
      callsOutDuration: 429235,
      smses: 58312,
    },
    {
      date: "2017-08-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5581904,
      accounts: 117363,
      dataSent: 814268,
      dataReceived: 5498625,
      callsIn: 482296,
      callsInDuration: 520662,
      callsOut: 348895,
      callsOutDuration: 377438,
      smses: 49930,
    },
    {
      date: "2017-08-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5405412,
      accounts: 131628,
      dataSent: 728416,
      dataReceived: 4919692,
      callsIn: 575123,
      callsInDuration: 675894,
      callsOut: 404659,
      callsOutDuration: 447751,
      smses: 56558,
    },
    {
      date: "2017-08-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5491106,
      accounts: 129937,
      dataSent: 765584,
      dataReceived: 5272614,
      callsIn: 560788,
      callsInDuration: 671804,
      callsOut: 400254,
      callsOutDuration: 457649,
      smses: 56747,
    },
    {
      date: "2017-08-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5421521,
      accounts: 130700,
      dataSent: 706243,
      dataReceived: 4866384,
      callsIn: 567423,
      callsInDuration: 675474,
      callsOut: 403526,
      callsOutDuration: 461124,
      smses: 56010,
    },
    {
      date: "2017-08-31",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5582037,
      accounts: 132649,
      dataSent: 813019,
      dataReceived: 5643184,
      callsIn: 583740,
      callsInDuration: 669110,
      callsOut: 415283,
      callsOutDuration: 463888,
      smses: 58491,
    },
    {
      date: "2017-09-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5614592,
      accounts: 140546,
      dataSent: 801450,
      dataReceived: 5468000,
      callsIn: 664033,
      callsInDuration: 692231,
      callsOut: 473459,
      callsOutDuration: 493011,
      smses: 64281,
    },
    {
      date: "2017-09-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5555682,
      accounts: 139093,
      dataSent: 816037,
      dataReceived: 5412286,
      callsIn: 652829,
      callsInDuration: 610759,
      callsOut: 484012,
      callsOutDuration: 462222,
      smses: 59067,
    },
    {
      date: "2017-09-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5222829,
      accounts: 121024,
      dataSent: 756026,
      dataReceived: 5126973,
      callsIn: 499313,
      callsInDuration: 533260,
      callsOut: 371245,
      callsOutDuration: 398317,
      smses: 52440,
    },
    {
      date: "2017-09-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5528517,
      accounts: 135177,
      dataSent: 749603,
      dataReceived: 5066194,
      callsIn: 601126,
      callsInDuration: 679543,
      callsOut: 433650,
      callsOutDuration: 481017,
      smses: 58254,
    },
    {
      date: "2017-09-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5542563,
      accounts: 133794,
      dataSent: 777148,
      dataReceived: 5327276,
      callsIn: 608089,
      callsInDuration: 679121,
      callsOut: 416484,
      callsOutDuration: 469414,
      smses: 57892,
    },
    {
      date: "2017-09-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5540469,
      accounts: 134613,
      dataSent: 754549,
      dataReceived: 5160671,
      callsIn: 613843,
      callsInDuration: 687972,
      callsOut: 418808,
      callsOutDuration: 476030,
      smses: 58272,
    },
    {
      date: "2017-09-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5598266,
      accounts: 134613,
      dataSent: 756956,
      dataReceived: 5204398,
      callsIn: 613862,
      callsInDuration: 692731,
      callsOut: 418534,
      callsOutDuration: 476114,
      smses: 56702,
    },
    {
      date: "2017-09-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5765106,
      accounts: 141759,
      dataSent: 735751,
      dataReceived: 5068914,
      callsIn: 684902,
      callsInDuration: 708041,
      callsOut: 461520,
      callsOutDuration: 484974,
      smses: 60580,
    },
    {
      date: "2017-09-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5920011,
      accounts: 139305,
      dataSent: 857708,
      dataReceived: 5767366,
      callsIn: 670959,
      callsInDuration: 622380,
      callsOut: 457877,
      callsOutDuration: 451426,
      smses: 58824,
    },
    {
      date: "2017-09-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5197199,
      accounts: 120802,
      dataSent: 728848,
      dataReceived: 5024719,
      callsIn: 514343,
      callsInDuration: 537934,
      callsOut: 355519,
      callsOutDuration: 385426,
      smses: 50425,
    },
    {
      date: "2017-09-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5559574,
      accounts: 134770,
      dataSent: 758206,
      dataReceived: 5170405,
      callsIn: 616256,
      callsInDuration: 691244,
      callsOut: 422746,
      callsOutDuration: 489974,
      smses: 56796,
    },
    {
      date: "2017-09-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5589640,
      accounts: 133483,
      dataSent: 719863,
      dataReceived: 5027007,
      callsIn: 604500,
      callsInDuration: 701449,
      callsOut: 405077,
      callsOutDuration: 473204,
      smses: 57449,
    },
    {
      date: "2017-09-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5633951,
      accounts: 133359,
      dataSent: 680594,
      dataReceived: 4703312,
      callsIn: 605793,
      callsInDuration: 672063,
      callsOut: 404014,
      callsOutDuration: 452202,
      smses: 55667,
    },
    {
      date: "2017-09-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5838363,
      accounts: 134707,
      dataSent: 796035,
      dataReceived: 5478405,
      callsIn: 618405,
      callsInDuration: 701088,
      callsOut: 408688,
      callsOutDuration: 469593,
      smses: 56207,
    },
    {
      date: "2017-09-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5688049,
      accounts: 144175,
      dataSent: 800305,
      dataReceived: 5307368,
      callsIn: 710191,
      callsInDuration: 720317,
      callsOut: 472662,
      callsOutDuration: 490436,
      smses: 61651,
    },
    {
      date: "2017-09-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5761255,
      accounts: 142459,
      dataSent: 852563,
      dataReceived: 5519059,
      callsIn: 707811,
      callsInDuration: 636892,
      callsOut: 480977,
      callsOutDuration: 465599,
      smses: 60464,
    },
    {
      date: "2017-09-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5220389,
      accounts: 125189,
      dataSent: 766023,
      dataReceived: 5151211,
      callsIn: 548676,
      callsInDuration: 553852,
      callsOut: 375078,
      callsOutDuration: 406399,
      smses: 53347,
    },
    {
      date: "2017-09-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5841045,
      accounts: 138174,
      dataSent: 796840,
      dataReceived: 5447764,
      callsIn: 643784,
      callsInDuration: 697633,
      callsOut: 432948,
      callsOutDuration: 487139,
      smses: 59122,
    },
    {
      date: "2017-09-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5632968,
      accounts: 137342,
      dataSent: 780142,
      dataReceived: 5273507,
      callsIn: 634597,
      callsInDuration: 696879,
      callsOut: 422085,
      callsOutDuration: 483041,
      smses: 60424,
    },
    {
      date: "2017-09-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5726263,
      accounts: 136568,
      dataSent: 777574,
      dataReceived: 5210265,
      callsIn: 622322,
      callsInDuration: 685250,
      callsOut: 417012,
      callsOutDuration: 469683,
      smses: 58764,
    },
    {
      date: "2017-09-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5667948,
      accounts: 135549,
      dataSent: 846270,
      dataReceived: 5727774,
      callsIn: 616186,
      callsInDuration: 694719,
      callsOut: 416618,
      callsOutDuration: 480184,
      smses: 57841,
    },
    {
      date: "2017-09-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5710732,
      accounts: 140985,
      dataSent: 748436,
      dataReceived: 4874847,
      callsIn: 672837,
      callsInDuration: 668941,
      callsOut: 469925,
      callsOutDuration: 494528,
      smses: 63374,
    },
    {
      date: "2017-09-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5973066,
      accounts: 138263,
      dataSent: 929784,
      dataReceived: 6086830,
      callsIn: 656840,
      callsInDuration: 610098,
      callsOut: 469235,
      callsOutDuration: 470299,
      smses: 61005,
    },
    {
      date: "2017-09-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5360844,
      accounts: 123142,
      dataSent: 822282,
      dataReceived: 5296340,
      callsIn: 528305,
      callsInDuration: 531649,
      callsOut: 374539,
      callsOutDuration: 404166,
      smses: 51001,
    },
    {
      date: "2017-09-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5646017,
      accounts: 138829,
      dataSent: 789649,
      dataReceived: 5211273,
      callsIn: 636778,
      callsInDuration: 690829,
      callsOut: 434917,
      callsOutDuration: 496274,
      smses: 59143,
    },
    {
      date: "2017-09-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5953494,
      accounts: 139485,
      dataSent: 853633,
      dataReceived: 5623879,
      callsIn: 638938,
      callsInDuration: 699637,
      callsOut: 431517,
      callsOutDuration: 480419,
      smses: 59712,
    },
    {
      date: "2017-09-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5703116,
      accounts: 134646,
      dataSent: 781546,
      dataReceived: 5183290,
      callsIn: 609336,
      callsInDuration: 707157,
      callsOut: 408312,
      callsOutDuration: 480008,
      smses: 57517,
    },
    {
      date: "2017-09-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5774589,
      accounts: 135816,
      dataSent: 818733,
      dataReceived: 5578433,
      callsIn: 620105,
      callsInDuration: 706942,
      callsOut: 419541,
      callsOutDuration: 489411,
      smses: 58479,
    },
    {
      date: "2017-09-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6150197,
      accounts: 143543,
      dataSent: 833147,
      dataReceived: 5652505,
      callsIn: 699205,
      callsInDuration: 708003,
      callsOut: 476026,
      callsOutDuration: 499696,
      smses: 62858,
    },
    {
      date: "2017-09-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5812140,
      accounts: 143897,
      dataSent: 881150,
      dataReceived: 5705704,
      callsIn: 712047,
      callsInDuration: 633686,
      callsOut: 495481,
      callsOutDuration: 476911,
      smses: 64019,
    },
    {
      date: "2017-10-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5327071,
      accounts: 126750,
      dataSent: 839790,
      dataReceived: 5524542,
      callsIn: 553204,
      callsInDuration: 559405,
      callsOut: 388558,
      callsOutDuration: 428640,
      smses: 53351,
    },
    {
      date: "2017-10-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5583629,
      accounts: 140138,
      dataSent: 837623,
      dataReceived: 5456452,
      callsIn: 654592,
      callsInDuration: 714206,
      callsOut: 456212,
      callsOutDuration: 518183,
      smses: 61221,
    },
    {
      date: "2017-10-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5602633,
      accounts: 138275,
      dataSent: 774019,
      dataReceived: 5180670,
      callsIn: 635586,
      callsInDuration: 710279,
      callsOut: 438829,
      callsOutDuration: 499027,
      smses: 60932,
    },
    {
      date: "2017-10-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6104770,
      accounts: 138140,
      dataSent: 771313,
      dataReceived: 4937878,
      callsIn: 637851,
      callsInDuration: 697111,
      callsOut: 428376,
      callsOutDuration: 484803,
      smses: 59731,
    },
    {
      date: "2017-10-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5798566,
      accounts: 137798,
      dataSent: 860245,
      dataReceived: 5678169,
      callsIn: 636894,
      callsInDuration: 696534,
      callsOut: 435880,
      callsOutDuration: 501207,
      smses: 61031,
    },
    {
      date: "2017-10-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6007598,
      accounts: 146185,
      dataSent: 883872,
      dataReceived: 5926475,
      callsIn: 721754,
      callsInDuration: 734650,
      callsOut: 497009,
      callsOutDuration: 530397,
      smses: 65147,
    },
    {
      date: "2017-10-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5742092,
      accounts: 143984,
      dataSent: 903438,
      dataReceived: 5838522,
      callsIn: 705620,
      callsInDuration: 640256,
      callsOut: 497489,
      callsOutDuration: 488164,
      smses: 62319,
    },
    {
      date: "2017-10-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5497581,
      accounts: 128001,
      dataSent: 899042,
      dataReceived: 5759138,
      callsIn: 557237,
      callsInDuration: 561554,
      callsOut: 382664,
      callsOutDuration: 414925,
      smses: 53606,
    },
    {
      date: "2017-10-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5334806,
      accounts: 127169,
      dataSent: 760476,
      dataReceived: 5068570,
      callsIn: 543803,
      callsInDuration: 596479,
      callsOut: 376163,
      callsOutDuration: 433228,
      smses: 52927,
    },
    {
      date: "2017-10-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5670085,
      accounts: 138805,
      dataSent: 811087,
      dataReceived: 5472595,
      callsIn: 638365,
      callsInDuration: 707022,
      callsOut: 431285,
      callsOutDuration: 491099,
      smses: 62244,
    },
    {
      date: "2017-10-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6540620,
      accounts: 138466,
      dataSent: 739222,
      dataReceived: 4761723,
      callsIn: 647481,
      callsInDuration: 677903,
      callsOut: 435763,
      callsOutDuration: 463931,
      smses: 63858,
    },
    {
      date: "2017-10-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4158307,
      accounts: 120571,
      dataSent: 517333,
      dataReceived: 3389377,
      callsIn: 473716,
      callsInDuration: 453744,
      callsOut: 317965,
      callsOutDuration: 309279,
      smses: 41296,
    },
    {
      date: "2017-10-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5951523,
      accounts: 146741,
      dataSent: 936442,
      dataReceived: 6303763,
      callsIn: 727519,
      callsInDuration: 745168,
      callsOut: 485042,
      callsOutDuration: 519213,
      smses: 65187,
    },
    {
      date: "2017-10-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5451491,
      accounts: 147721,
      dataSent: 829596,
      dataReceived: 5503844,
      callsIn: 735276,
      callsInDuration: 666295,
      callsOut: 497641,
      callsOutDuration: 449358,
      smses: 69621,
    },
    {
      date: "2017-10-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5219430,
      accounts: 128757,
      dataSent: 839315,
      dataReceived: 5537672,
      callsIn: 556662,
      callsInDuration: 584384,
      callsOut: 382480,
      callsOutDuration: 427858,
      smses: 56925,
    },
    {
      date: "2017-10-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5651890,
      accounts: 142977,
      dataSent: 864637,
      dataReceived: 5615071,
      callsIn: 670929,
      callsInDuration: 741769,
      callsOut: 451704,
      callsOutDuration: 513535,
      smses: 67613,
    },
    {
      date: "2017-10-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5615532,
      accounts: 140830,
      dataSent: 837344,
      dataReceived: 5533484,
      callsIn: 651839,
      callsInDuration: 749121,
      callsOut: 439131,
      callsOutDuration: 509370,
      smses: 66691,
    },
    {
      date: "2017-10-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5691351,
      accounts: 140985,
      dataSent: 809272,
      dataReceived: 5323275,
      callsIn: 655659,
      callsInDuration: 748504,
      callsOut: 438192,
      callsOutDuration: 508895,
      smses: 65165,
    },
    {
      date: "2017-10-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5388107,
      accounts: 140775,
      dataSent: 854025,
      dataReceived: 5583432,
      callsIn: 657139,
      callsInDuration: 748127,
      callsOut: 440965,
      callsOutDuration: 516581,
      smses: 63561,
    },
    {
      date: "2017-10-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5874132,
      accounts: 148431,
      dataSent: 866225,
      dataReceived: 5733275,
      callsIn: 731852,
      callsInDuration: 751139,
      callsOut: 491210,
      callsOutDuration: 529000,
      smses: 67539,
    },
    {
      date: "2017-10-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5923834,
      accounts: 146800,
      dataSent: 856661,
      dataReceived: 5680248,
      callsIn: 722174,
      callsInDuration: 670872,
      callsOut: 497431,
      callsOutDuration: 489838,
      smses: 63189,
    },
    {
      date: "2017-10-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5379229,
      accounts: 126575,
      dataSent: 840330,
      dataReceived: 5523992,
      callsIn: 544441,
      callsInDuration: 580377,
      callsOut: 379315,
      callsOutDuration: 430557,
      smses: 53860,
    },
    {
      date: "2017-10-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5742930,
      accounts: 139708,
      dataSent: 852040,
      dataReceived: 5589512,
      callsIn: 638541,
      callsInDuration: 720564,
      callsOut: 437558,
      callsOutDuration: 504939,
      smses: 62709,
    },
    {
      date: "2017-10-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6017469,
      accounts: 139820,
      dataSent: 847780,
      dataReceived: 5619583,
      callsIn: 641670,
      callsInDuration: 742442,
      callsOut: 431707,
      callsOutDuration: 508826,
      smses: 62238,
    },
    {
      date: "2017-10-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5830350,
      accounts: 144413,
      dataSent: 822626,
      dataReceived: 5398189,
      callsIn: 662786,
      callsInDuration: 734486,
      callsOut: 427525,
      callsOutDuration: 496268,
      smses: 62145,
    },
    {
      date: "2017-10-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5816075,
      accounts: 144790,
      dataSent: 816442,
      dataReceived: 5431109,
      callsIn: 666649,
      callsInDuration: 758990,
      callsOut: 430949,
      callsOutDuration: 503209,
      smses: 63945,
    },
    {
      date: "2017-10-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5967342,
      accounts: 152547,
      dataSent: 853025,
      dataReceived: 5581654,
      callsIn: 745176,
      callsInDuration: 757641,
      callsOut: 487775,
      callsOutDuration: 517432,
      smses: 68454,
    },
    {
      date: "2017-10-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6032901,
      accounts: 153148,
      dataSent: 933778,
      dataReceived: 6027183,
      callsIn: 758633,
      callsInDuration: 673425,
      callsOut: 509735,
      callsOutDuration: 492849,
      smses: 63828,
    },
    {
      date: "2017-10-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5373720,
      accounts: 129699,
      dataSent: 766637,
      dataReceived: 5112986,
      callsIn: 555875,
      callsInDuration: 592818,
      callsOut: 376879,
      callsOutDuration: 433601,
      smses: 51587,
    },
    {
      date: "2017-10-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6073688,
      accounts: 145248,
      dataSent: 935026,
      dataReceived: 6322950,
      callsIn: 669338,
      callsInDuration: 753800,
      callsOut: 440087,
      callsOutDuration: 514966,
      smses: 65089,
    },
    {
      date: "2017-10-31",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7331771,
      accounts: 149106,
      dataSent: 1029679,
      dataReceived: 6772593,
      callsIn: 699802,
      callsInDuration: 741262,
      callsOut: 445007,
      callsOutDuration: 467961,
      smses: 61139,
    },
    {
      date: "2017-11-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6976226,
      accounts: 154826,
      dataSent: 899739,
      dataReceived: 6025226,
      callsIn: 772184,
      callsInDuration: 766472,
      callsOut: 510170,
      callsOutDuration: 532141,
      smses: 67141,
    },
    {
      date: "2017-11-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6505288,
      accounts: 143066,
      dataSent: 876567,
      dataReceived: 5772085,
      callsIn: 667243,
      callsInDuration: 644422,
      callsOut: 446106,
      callsOutDuration: 462381,
      smses: 59084,
    },
    {
      date: "2017-11-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6237869,
      accounts: 135291,
      dataSent: 891630,
      dataReceived: 5600216,
      callsIn: 605214,
      callsInDuration: 605897,
      callsOut: 406907,
      callsOutDuration: 446458,
      smses: 56422,
    },
    {
      date: "2017-11-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6095783,
      accounts: 136443,
      dataSent: 901113,
      dataReceived: 5636738,
      callsIn: 623022,
      callsInDuration: 577489,
      callsOut: 422766,
      callsOutDuration: 425905,
      smses: 57691,
    },
    {
      date: "2017-11-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5686849,
      accounts: 127361,
      dataSent: 794548,
      dataReceived: 5047151,
      callsIn: 532295,
      callsInDuration: 556232,
      callsOut: 361564,
      callsOutDuration: 408105,
      smses: 52470,
    },
    {
      date: "2017-11-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5045492,
      accounts: 145034,
      dataSent: 825889,
      dataReceived: 5928558,
      callsIn: 672391,
      callsInDuration: 756547,
      callsOut: 449853,
      callsOutDuration: 500621,
      smses: 67040,
    },
    {
      date: "2017-11-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3450107,
      accounts: 126615,
      dataSent: 535586,
      dataReceived: 3872283,
      callsIn: 504706,
      callsInDuration: 503555,
      callsOut: 335736,
      callsOutDuration: 322956,
      smses: 47055,
    },
    {
      date: "2017-11-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4704586,
      accounts: 141778,
      dataSent: 684769,
      dataReceived: 4787334,
      callsIn: 643622,
      callsInDuration: 699869,
      callsOut: 421832,
      callsOutDuration: 471188,
      smses: 61124,
    },
    {
      date: "2017-11-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4366798,
      accounts: 144265,
      dataSent: 754866,
      dataReceived: 5341934,
      callsIn: 672084,
      callsInDuration: 773686,
      callsOut: 439590,
      callsOutDuration: 505769,
      smses: 61400,
    },
    {
      date: "2017-11-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4625141,
      accounts: 152629,
      dataSent: 895112,
      dataReceived: 6292016,
      callsIn: 753601,
      callsInDuration: 784364,
      callsOut: 494148,
      callsOutDuration: 517677,
      smses: 67592,
    },
    {
      date: "2017-11-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4391212,
      accounts: 150045,
      dataSent: 865603,
      dataReceived: 6082425,
      callsIn: 733979,
      callsInDuration: 687628,
      callsOut: 491710,
      callsOutDuration: 490087,
      smses: 62982,
    },
    {
      date: "2017-11-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4094574,
      accounts: 131113,
      dataSent: 797951,
      dataReceived: 5631657,
      callsIn: 567780,
      callsInDuration: 608413,
      callsOut: 379564,
      callsOutDuration: 422087,
      smses: 52657,
    },
    {
      date: "2017-11-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4318079,
      accounts: 145355,
      dataSent: 777957,
      dataReceived: 5471285,
      callsIn: 673101,
      callsInDuration: 773981,
      callsOut: 443649,
      callsOutDuration: 517733,
      smses: 62279,
    },
    {
      date: "2017-11-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4281249,
      accounts: 144217,
      dataSent: 810134,
      dataReceived: 5632141,
      callsIn: 668748,
      callsInDuration: 779733,
      callsOut: 429553,
      callsOutDuration: 501265,
      smses: 61888,
    },
    {
      date: "2017-11-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4343444,
      accounts: 146305,
      dataSent: 829188,
      dataReceived: 5641465,
      callsIn: 688047,
      callsInDuration: 772382,
      callsOut: 443670,
      callsOutDuration: 505503,
      smses: 61009,
    },
    {
      date: "2017-11-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4282591,
      accounts: 147211,
      dataSent: 764413,
      dataReceived: 5340241,
      callsIn: 694876,
      callsInDuration: 784004,
      callsOut: 445363,
      callsOutDuration: 518072,
      smses: 61356,
    },
    {
      date: "2017-11-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 4696526,
      accounts: 170269,
      dataSent: 914331,
      dataReceived: 6207092,
      callsIn: 901092,
      callsInDuration: 880548,
      callsOut: 590428,
      callsOutDuration: 612054,
      smses: 70397,
    },
    {
      date: "2017-11-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4359049,
      accounts: 152271,
      dataSent: 875598,
      dataReceived: 5934094,
      callsIn: 763038,
      callsInDuration: 695462,
      callsOut: 496941,
      callsOutDuration: 490125,
      smses: 61243,
    },
    {
      date: "2017-11-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4074890,
      accounts: 132677,
      dataSent: 811645,
      dataReceived: 5569469,
      callsIn: 583271,
      callsInDuration: 596150,
      callsOut: 384329,
      callsOutDuration: 432490,
      smses: 51791,
    },
    {
      date: "2017-11-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4270019,
      accounts: 146858,
      dataSent: 804410,
      dataReceived: 5631880,
      callsIn: 687107,
      callsInDuration: 757648,
      callsOut: 455487,
      callsOutDuration: 522226,
      smses: 62275,
    },
    {
      date: "2017-11-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 2847957,
      accounts: 117601,
      dataSent: 472031,
      dataReceived: 3421279,
      callsIn: 426640,
      callsInDuration: 478887,
      callsOut: 280679,
      callsOutDuration: 306444,
      smses: 52669,
    },
    {
      date: "2017-11-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 3776579,
      accounts: 144254,
      dataSent: 933673,
      dataReceived: 6593105,
      callsIn: 662145,
      callsInDuration: 764057,
      callsOut: 430092,
      callsOutDuration: 510301,
      smses: 58312,
    },
    {
      date: "2017-11-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 2701505,
      accounts: 144930,
      dataSent: 666881,
      dataReceived: 4676495,
      callsIn: 663659,
      callsInDuration: 716840,
      callsOut: 440200,
      callsOutDuration: 487812,
      smses: 58225,
    },
    {
      date: "2017-11-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 3946378,
      accounts: 153617,
      dataSent: 993252,
      dataReceived: 6881466,
      callsIn: 729975,
      callsInDuration: 768092,
      callsOut: 501585,
      callsOutDuration: 531562,
      smses: 63564,
    },
    {
      date: "2017-11-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 4946916,
      accounts: 153843,
      dataSent: 940873,
      dataReceived: 6342718,
      callsIn: 744708,
      callsInDuration: 702058,
      callsOut: 502070,
      callsOutDuration: 502219,
      smses: 60707,
    },
    {
      date: "2017-11-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4580267,
      accounts: 133551,
      dataSent: 794575,
      dataReceived: 5522341,
      callsIn: 581123,
      callsInDuration: 609000,
      callsOut: 382770,
      callsOutDuration: 434938,
      smses: 50686,
    },
    {
      date: "2017-11-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 4769236,
      accounts: 148622,
      dataSent: 846308,
      dataReceived: 5827850,
      callsIn: 669550,
      callsInDuration: 783422,
      callsOut: 456024,
      callsOutDuration: 531604,
      smses: 63246,
    },
    {
      date: "2017-11-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4577926,
      accounts: 141840,
      dataSent: 706910,
      dataReceived: 4963496,
      callsIn: 613074,
      callsInDuration: 754743,
      callsOut: 415108,
      callsOutDuration: 503332,
      smses: 59535,
    },
    {
      date: "2017-11-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4694234,
      accounts: 144704,
      dataSent: 760271,
      dataReceived: 5179109,
      callsIn: 659202,
      callsInDuration: 773450,
      callsOut: 425286,
      callsOutDuration: 514263,
      smses: 58821,
    },
    {
      date: "2017-11-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4909218,
      accounts: 148414,
      dataSent: 802099,
      dataReceived: 5667135,
      callsIn: 696840,
      callsInDuration: 794030,
      callsOut: 451096,
      callsOutDuration: 533653,
      smses: 63612,
    },
    {
      date: "2017-12-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5165971,
      accounts: 156429,
      dataSent: 913700,
      dataReceived: 6490661,
      callsIn: 790650,
      callsInDuration: 787330,
      callsOut: 516096,
      callsOutDuration: 550128,
      smses: 67702,
    },
    {
      date: "2017-12-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5150946,
      accounts: 156718,
      dataSent: 929448,
      dataReceived: 6480696,
      callsIn: 789215,
      callsInDuration: 703647,
      callsOut: 526651,
      callsOutDuration: 517181,
      smses: 65218,
    },
    {
      date: "2017-12-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4863347,
      accounts: 139653,
      dataSent: 860945,
      dataReceived: 6056779,
      callsIn: 619115,
      callsInDuration: 642900,
      callsOut: 417153,
      callsOutDuration: 483508,
      smses: 55508,
    },
    {
      date: "2017-12-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5043161,
      accounts: 148769,
      dataSent: 856971,
      dataReceived: 5981048,
      callsIn: 695116,
      callsInDuration: 783681,
      callsOut: 459648,
      callsOutDuration: 544660,
      smses: 62089,
    },
    {
      date: "2017-12-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4986670,
      accounts: 148406,
      dataSent: 827164,
      dataReceived: 5857999,
      callsIn: 703904,
      callsInDuration: 790781,
      callsOut: 457090,
      callsOutDuration: 530469,
      smses: 62907,
    },
    {
      date: "2017-12-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 4924034,
      accounts: 144925,
      dataSent: 819746,
      dataReceived: 5879727,
      callsIn: 672790,
      callsInDuration: 772904,
      callsOut: 444894,
      callsOutDuration: 530207,
      smses: 61474,
    },
    {
      date: "2017-12-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5055230,
      accounts: 149732,
      dataSent: 818373,
      dataReceived: 5783606,
      callsIn: 702513,
      callsInDuration: 801770,
      callsOut: 459796,
      callsOutDuration: 540662,
      smses: 62290,
    },
    {
      date: "2017-12-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5344069,
      accounts: 156604,
      dataSent: 872287,
      dataReceived: 6208663,
      callsIn: 782595,
      callsInDuration: 805966,
      callsOut: 506447,
      callsOutDuration: 549323,
      smses: 64117,
    },
    {
      date: "2017-12-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5384928,
      accounts: 155415,
      dataSent: 902286,
      dataReceived: 6478218,
      callsIn: 776196,
      callsInDuration: 721990,
      callsOut: 510708,
      callsOutDuration: 526860,
      smses: 62880,
    },
    {
      date: "2017-12-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4989302,
      accounts: 136183,
      dataSent: 828937,
      dataReceived: 6017851,
      callsIn: 598106,
      callsInDuration: 636555,
      callsOut: 399547,
      callsOutDuration: 461707,
      smses: 54528,
    },
    {
      date: "2017-12-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5219747,
      accounts: 148331,
      dataSent: 838889,
      dataReceived: 6017042,
      callsIn: 680243,
      callsInDuration: 790206,
      callsOut: 452597,
      callsOutDuration: 531718,
      smses: 61455,
    },
    {
      date: "2017-12-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5255177,
      accounts: 149146,
      dataSent: 836031,
      dataReceived: 5979293,
      callsIn: 693212,
      callsInDuration: 801726,
      callsOut: 445545,
      callsOutDuration: 532290,
      smses: 59838,
    },
    {
      date: "2017-12-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5529828,
      accounts: 148870,
      dataSent: 928776,
      dataReceived: 6666602,
      callsIn: 693054,
      callsInDuration: 791982,
      callsOut: 444101,
      callsOutDuration: 519234,
      smses: 59434,
    },
    {
      date: "2017-12-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6369487,
      accounts: 149325,
      dataSent: 1146407,
      dataReceived: 7906099,
      callsIn: 704417,
      callsInDuration: 795581,
      callsOut: 440332,
      callsOutDuration: 505825,
      smses: 59068,
    },
    {
      date: "2017-12-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6220660,
      accounts: 159635,
      dataSent: 1000826,
      dataReceived: 6921750,
      callsIn: 816636,
      callsInDuration: 817969,
      callsOut: 514693,
      callsOutDuration: 532755,
      smses: 64088,
    },
    {
      date: "2017-12-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6004262,
      accounts: 160634,
      dataSent: 967283,
      dataReceived: 6708330,
      callsIn: 838662,
      callsInDuration: 739270,
      callsOut: 536139,
      callsOutDuration: 520106,
      smses: 62658,
    },
    {
      date: "2017-12-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5464934,
      accounts: 142448,
      dataSent: 902975,
      dataReceived: 6522432,
      callsIn: 650938,
      callsInDuration: 635649,
      callsOut: 415983,
      callsOutDuration: 448162,
      smses: 53623,
    },
    {
      date: "2017-12-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5637849,
      accounts: 154334,
      dataSent: 881621,
      dataReceived: 6411064,
      callsIn: 742097,
      callsInDuration: 819251,
      callsOut: 475939,
      callsOutDuration: 537524,
      smses: 62011,
    },
    {
      date: "2017-12-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5667411,
      accounts: 153154,
      dataSent: 882570,
      dataReceived: 6368909,
      callsIn: 738401,
      callsInDuration: 820084,
      callsOut: 471349,
      callsOutDuration: 542354,
      smses: 63443,
    },
    {
      date: "2017-12-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5596901,
      accounts: 156136,
      dataSent: 866004,
      dataReceived: 6390417,
      callsIn: 765742,
      callsInDuration: 829052,
      callsOut: 491706,
      callsOutDuration: 553597,
      smses: 63410,
    },
    {
      date: "2017-12-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5701562,
      accounts: 161970,
      dataSent: 907909,
      dataReceived: 6600446,
      callsIn: 821032,
      callsInDuration: 828525,
      callsOut: 533532,
      callsOutDuration: 562610,
      smses: 64888,
    },
    {
      date: "2017-12-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5832118,
      accounts: 169066,
      dataSent: 924221,
      dataReceived: 6475382,
      callsIn: 905994,
      callsInDuration: 831288,
      callsOut: 600528,
      callsOutDuration: 587961,
      smses: 68344,
    },
    {
      date: "2017-12-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5817512,
      accounts: 164150,
      dataSent: 965686,
      dataReceived: 6934333,
      callsIn: 872766,
      callsInDuration: 748295,
      callsOut: 580509,
      callsOutDuration: 567421,
      smses: 65615,
    },
    {
      date: "2017-12-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5500902,
      accounts: 155856,
      dataSent: 970195,
      dataReceived: 6758747,
      callsIn: 763734,
      callsInDuration: 666001,
      callsOut: 508103,
      callsOutDuration: 510238,
      smses: 61439,
    },
    {
      date: "2017-12-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5338357,
      accounts: 137054,
      dataSent: 940345,
      dataReceived: 6610285,
      callsIn: 587816,
      callsInDuration: 646526,
      callsOut: 379343,
      callsOutDuration: 459024,
      smses: 55819,
    },
    {
      date: "2017-12-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5467096,
      accounts: 150590,
      dataSent: 890950,
      dataReceived: 6353490,
      callsIn: 693103,
      callsInDuration: 783361,
      callsOut: 433841,
      callsOutDuration: 520977,
      smses: 61157,
    },
    {
      date: "2017-12-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5530223,
      accounts: 150937,
      dataSent: 867763,
      dataReceived: 6050046,
      callsIn: 708587,
      callsInDuration: 784201,
      callsOut: 446406,
      callsOutDuration: 510814,
      smses: 63435,
    },
    {
      date: "2017-12-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 4070331,
      accounts: 152332,
      dataSent: 612890,
      dataReceived: 4314223,
      callsIn: 734639,
      callsInDuration: 801043,
      callsOut: 470429,
      callsOutDuration: 511016,
      smses: 67997,
    },
    {
      date: "2017-12-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5695150,
      accounts: 159228,
      dataSent: 984441,
      dataReceived: 6859753,
      callsIn: 811854,
      callsInDuration: 807016,
      callsOut: 532684,
      callsOutDuration: 582454,
      smses: 68703,
    },
    {
      date: "2017-12-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5520072,
      accounts: 158117,
      dataSent: 927987,
      dataReceived: 6494951,
      callsIn: 809227,
      callsInDuration: 736039,
      callsOut: 539935,
      callsOutDuration: 535436,
      smses: 67003,
    },
    {
      date: "2017-12-31",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5638466,
      accounts: 162228,
      dataSent: 1070824,
      dataReceived: 6618521,
      callsIn: 835626,
      callsInDuration: 678371,
      callsOut: 564658,
      callsOutDuration: 514472,
      smses: 71372,
    },
    {
      date: "2018-01-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5542664,
      accounts: 143299,
      dataSent: 1103893,
      dataReceived: 7450240,
      callsIn: 660186,
      callsInDuration: 684466,
      callsOut: 427379,
      callsOutDuration: 488533,
      smses: 70663,
    },
    {
      date: "2018-01-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3466818,
      accounts: 115924,
      dataSent: 575269,
      dataReceived: 3988624,
      callsIn: 357187,
      callsInDuration: 418590,
      callsOut: 258371,
      callsOutDuration: 275653,
      smses: 36622,
    },
    {
      date: "2018-01-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5706009,
      accounts: 146450,
      dataSent: 978904,
      dataReceived: 6971422,
      callsIn: 654800,
      callsInDuration: 773594,
      callsOut: 430527,
      callsOutDuration: 525507,
      smses: 66528,
    },
    {
      date: "2018-01-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5597622,
      accounts: 146977,
      dataSent: 890489,
      dataReceived: 6443114,
      callsIn: 656562,
      callsInDuration: 791366,
      callsOut: 435290,
      callsOutDuration: 532587,
      smses: 64796,
    },
    {
      date: "2018-01-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5484835,
      accounts: 151808,
      dataSent: 889172,
      dataReceived: 6487000,
      callsIn: 710245,
      callsInDuration: 795900,
      callsOut: 465045,
      callsOutDuration: 538774,
      smses: 65801,
    },
    {
      date: "2018-01-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5608509,
      accounts: 150590,
      dataSent: 938934,
      dataReceived: 6633161,
      callsIn: 706543,
      callsInDuration: 709467,
      callsOut: 476651,
      callsOutDuration: 511322,
      smses: 63876,
    },
    {
      date: "2018-01-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5174553,
      accounts: 130098,
      dataSent: 804655,
      dataReceived: 5864253,
      callsIn: 544744,
      callsInDuration: 617152,
      callsOut: 372081,
      callsOutDuration: 450103,
      smses: 53881,
    },
    {
      date: "2018-01-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5518014,
      accounts: 148198,
      dataSent: 912625,
      dataReceived: 6580768,
      callsIn: 676183,
      callsInDuration: 806462,
      callsOut: 443539,
      callsOutDuration: 546190,
      smses: 61600,
    },
    {
      date: "2018-01-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5416622,
      accounts: 146639,
      dataSent: 842616,
      dataReceived: 6107925,
      callsIn: 659110,
      callsInDuration: 810957,
      callsOut: 426699,
      callsOutDuration: 536809,
      smses: 61499,
    },
    {
      date: "2018-01-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5555906,
      accounts: 146621,
      dataSent: 873488,
      dataReceived: 6410947,
      callsIn: 666725,
      callsInDuration: 812932,
      callsOut: 430208,
      callsOutDuration: 531651,
      smses: 59082,
    },
    {
      date: "2018-01-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5399819,
      accounts: 146796,
      dataSent: 849206,
      dataReceived: 6240041,
      callsIn: 661502,
      callsInDuration: 811024,
      callsOut: 427912,
      callsOutDuration: 534471,
      smses: 58510,
    },
    {
      date: "2018-01-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5516148,
      accounts: 153768,
      dataSent: 885847,
      dataReceived: 6487619,
      callsIn: 740302,
      callsInDuration: 820948,
      callsOut: 474957,
      callsOutDuration: 536379,
      smses: 64100,
    },
    {
      date: "2018-01-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5447972,
      accounts: 151361,
      dataSent: 909909,
      dataReceived: 6548991,
      callsIn: 722018,
      callsInDuration: 720780,
      callsOut: 472585,
      callsOutDuration: 514155,
      smses: 60243,
    },
    {
      date: "2018-01-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5014063,
      accounts: 131365,
      dataSent: 840294,
      dataReceived: 6145891,
      callsIn: 552365,
      callsInDuration: 633012,
      callsOut: 362818,
      callsOutDuration: 448730,
      smses: 51167,
    },
    {
      date: "2018-01-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 3421581,
      accounts: 121806,
      dataSent: 497504,
      dataReceived: 3683534,
      callsIn: 447229,
      callsInDuration: 452848,
      callsOut: 285900,
      callsOutDuration: 287087,
      smses: 62954,
    },
    {
      date: "2018-01-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5007372,
      accounts: 148070,
      dataSent: 841542,
      dataReceived: 6266161,
      callsIn: 678064,
      callsInDuration: 798325,
      callsOut: 433165,
      callsOutDuration: 507238,
      smses: 63700,
    },
    {
      date: "2018-01-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5606632,
      accounts: 147944,
      dataSent: 917155,
      dataReceived: 6731544,
      callsIn: 676060,
      callsInDuration: 817067,
      callsOut: 431156,
      callsOutDuration: 519761,
      smses: 59965,
    },
    {
      date: "2018-01-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5793853,
      accounts: 146147,
      dataSent: 984324,
      dataReceived: 7255968,
      callsIn: 635602,
      callsInDuration: 806213,
      callsOut: 421074,
      callsOutDuration: 514455,
      smses: 59689,
    },
    {
      date: "2018-01-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5993922,
      accounts: 153036,
      dataSent: 974066,
      dataReceived: 7113316,
      callsIn: 703074,
      callsInDuration: 815835,
      callsOut: 466899,
      callsOutDuration: 536352,
      smses: 63786,
    },
    {
      date: "2018-01-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5730216,
      accounts: 149666,
      dataSent: 962346,
      dataReceived: 6938216,
      callsIn: 672467,
      callsInDuration: 721243,
      callsOut: 461800,
      callsOutDuration: 509664,
      smses: 61168,
    },
    {
      date: "2018-01-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5522926,
      accounts: 129901,
      dataSent: 882394,
      dataReceived: 6604919,
      callsIn: 515685,
      callsInDuration: 640272,
      callsOut: 358148,
      callsOutDuration: 437898,
      smses: 52203,
    },
    {
      date: "2018-01-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5839134,
      accounts: 147483,
      dataSent: 892685,
      dataReceived: 6448921,
      callsIn: 664747,
      callsInDuration: 813857,
      callsOut: 438282,
      callsOutDuration: 530625,
      smses: 61360,
    },
    {
      date: "2018-01-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5883757,
      accounts: 145592,
      dataSent: 877993,
      dataReceived: 6382277,
      callsIn: 653180,
      callsInDuration: 806207,
      callsOut: 429911,
      callsOutDuration: 536392,
      smses: 59994,
    },
    {
      date: "2018-01-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5781776,
      accounts: 147247,
      dataSent: 878326,
      dataReceived: 6455924,
      callsIn: 668428,
      callsInDuration: 825234,
      callsOut: 426675,
      callsOutDuration: 527919,
      smses: 58977,
    },
    {
      date: "2018-01-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5328319,
      accounts: 142822,
      dataSent: 824398,
      dataReceived: 6127851,
      callsIn: 610785,
      callsInDuration: 759915,
      callsOut: 379167,
      callsOutDuration: 466665,
      smses: 56919,
    },
    {
      date: "2018-01-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5908202,
      accounts: 155456,
      dataSent: 892845,
      dataReceived: 6675964,
      callsIn: 753498,
      callsInDuration: 838219,
      callsOut: 476558,
      callsOutDuration: 546424,
      smses: 63437,
    },
    {
      date: "2018-01-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5897575,
      accounts: 153129,
      dataSent: 955206,
      dataReceived: 7109520,
      callsIn: 744939,
      callsInDuration: 736149,
      callsOut: 486601,
      callsOutDuration: 516377,
      smses: 61859,
    },
    {
      date: "2018-01-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5445378,
      accounts: 132167,
      dataSent: 861987,
      dataReceived: 6570724,
      callsIn: 560740,
      callsInDuration: 645331,
      callsOut: 366991,
      callsOutDuration: 439891,
      smses: 52917,
    },
    {
      date: "2018-01-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5718171,
      accounts: 146945,
      dataSent: 827149,
      dataReceived: 6302680,
      callsIn: 668536,
      callsInDuration: 810745,
      callsOut: 426583,
      callsOutDuration: 520782,
      smses: 60144,
    },
    {
      date: "2018-01-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5874965,
      accounts: 147013,
      dataSent: 883630,
      dataReceived: 6777844,
      callsIn: 668255,
      callsInDuration: 821460,
      callsOut: 422333,
      callsOutDuration: 524527,
      smses: 61534,
    },
    {
      date: "2018-01-31",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5821212,
      accounts: 149127,
      dataSent: 849431,
      dataReceived: 6456430,
      callsIn: 681212,
      callsInDuration: 834593,
      callsOut: 433527,
      callsOutDuration: 527575,
      smses: 60114,
    },
    {
      date: "2018-02-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5502113,
      accounts: 150998,
      dataSent: 836684,
      dataReceived: 6310289,
      callsIn: 700236,
      callsInDuration: 828090,
      callsOut: 447644,
      callsOutDuration: 529801,
      smses: 61674,
    },
    {
      date: "2018-02-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5715749,
      accounts: 157884,
      dataSent: 875680,
      dataReceived: 6622341,
      callsIn: 772421,
      callsInDuration: 846207,
      callsOut: 491579,
      callsOutDuration: 549200,
      smses: 65968,
    },
    {
      date: "2018-02-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5734784,
      accounts: 152386,
      dataSent: 894658,
      dataReceived: 6710397,
      callsIn: 723901,
      callsInDuration: 750462,
      callsOut: 500037,
      callsOutDuration: 529278,
      smses: 61311,
    },
    {
      date: "2018-02-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5716150,
      accounts: 149706,
      dataSent: 863525,
      dataReceived: 6485179,
      callsIn: 700468,
      callsInDuration: 687754,
      callsOut: 519438,
      callsOutDuration: 518288,
      smses: 60408,
    },
    {
      date: "2018-02-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5720097,
      accounts: 151727,
      dataSent: 794585,
      dataReceived: 5954535,
      callsIn: 711138,
      callsInDuration: 842580,
      callsOut: 453466,
      callsOutDuration: 539166,
      smses: 64421,
    },
    {
      date: "2018-02-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5965698,
      accounts: 149636,
      dataSent: 931258,
      dataReceived: 7094076,
      callsIn: 683049,
      callsInDuration: 843661,
      callsOut: 433318,
      callsOutDuration: 529571,
      smses: 63990,
    },
    {
      date: "2018-02-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5519408,
      accounts: 150203,
      dataSent: 814603,
      dataReceived: 6255890,
      callsIn: 693725,
      callsInDuration: 849535,
      callsOut: 443777,
      callsOutDuration: 534438,
      smses: 63802,
    },
    {
      date: "2018-02-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5804568,
      accounts: 154282,
      dataSent: 861547,
      dataReceived: 6570720,
      callsIn: 734945,
      callsInDuration: 873530,
      callsOut: 463628,
      callsOutDuration: 540294,
      smses: 63229,
    },
    {
      date: "2018-02-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5440376,
      accounts: 155549,
      dataSent: 826548,
      dataReceived: 6173588,
      callsIn: 735100,
      callsInDuration: 743930,
      callsOut: 455260,
      callsOutDuration: 465168,
      smses: 66724,
    },
    {
      date: "2018-02-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5927630,
      accounts: 156045,
      dataSent: 947494,
      dataReceived: 6838924,
      callsIn: 788956,
      callsInDuration: 770743,
      callsOut: 497644,
      callsOutDuration: 520481,
      smses: 64832,
    },
    {
      date: "2018-02-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5450011,
      accounts: 139196,
      dataSent: 953955,
      dataReceived: 6633451,
      callsIn: 654179,
      callsInDuration: 646321,
      callsOut: 407030,
      callsOutDuration: 433987,
      smses: 58411,
    },
    {
      date: "2018-02-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5298821,
      accounts: 132743,
      dataSent: 907020,
      dataReceived: 6300165,
      callsIn: 601074,
      callsInDuration: 613039,
      callsOut: 364161,
      callsOutDuration: 404992,
      smses: 56481,
    },
    {
      date: "2018-02-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 4812563,
      accounts: 124785,
      dataSent: 839015,
      dataReceived: 6036659,
      callsIn: 514772,
      callsInDuration: 616642,
      callsOut: 311462,
      callsOutDuration: 388339,
      smses: 52595,
    },
    {
      date: "2018-02-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5838503,
      accounts: 154770,
      dataSent: 916781,
      dataReceived: 6496662,
      callsIn: 734072,
      callsInDuration: 837597,
      callsOut: 445635,
      callsOutDuration: 511083,
      smses: 71370,
    },
    {
      date: "2018-02-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5173342,
      accounts: 151417,
      dataSent: 798054,
      dataReceived: 5851023,
      callsIn: 697884,
      callsInDuration: 870104,
      callsOut: 422975,
      callsOutDuration: 524503,
      smses: 63573,
    },
    {
      date: "2018-02-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5546669,
      accounts: 156868,
      dataSent: 849650,
      dataReceived: 6182631,
      callsIn: 760399,
      callsInDuration: 882975,
      callsOut: 459934,
      callsOutDuration: 537684,
      smses: 64902,
    },
    {
      date: "2018-02-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5633534,
      accounts: 150405,
      dataSent: 864569,
      dataReceived: 6257630,
      callsIn: 707700,
      callsInDuration: 780721,
      callsOut: 440398,
      callsOutDuration: 505749,
      smses: 62754,
    },
    {
      date: "2018-02-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5165852,
      accounts: 131701,
      dataSent: 841022,
      dataReceived: 6109152,
      callsIn: 550455,
      callsInDuration: 690915,
      callsOut: 345323,
      callsOutDuration: 437381,
      smses: 53773,
    },
    {
      date: "2018-02-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5588999,
      accounts: 153880,
      dataSent: 827526,
      dataReceived: 6017397,
      callsIn: 724018,
      callsInDuration: 882793,
      callsOut: 440039,
      callsOutDuration: 540615,
      smses: 62194,
    },
    {
      date: "2018-02-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5333201,
      accounts: 152169,
      dataSent: 814151,
      dataReceived: 5921761,
      callsIn: 705737,
      callsInDuration: 868553,
      callsOut: 432073,
      callsOutDuration: 528880,
      smses: 64022,
    },
    {
      date: "2018-02-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5530108,
      accounts: 152688,
      dataSent: 828851,
      dataReceived: 6074999,
      callsIn: 702285,
      callsInDuration: 876601,
      callsOut: 430824,
      callsOutDuration: 535881,
      smses: 62204,
    },
    {
      date: "2018-02-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5528063,
      accounts: 152548,
      dataSent: 824542,
      dataReceived: 6274655,
      callsIn: 701724,
      callsInDuration: 868416,
      callsOut: 428660,
      callsOutDuration: 520314,
      smses: 62032,
    },
    {
      date: "2018-02-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5687262,
      accounts: 158646,
      dataSent: 873879,
      dataReceived: 6553172,
      callsIn: 764130,
      callsInDuration: 868976,
      callsOut: 476113,
      callsOutDuration: 548080,
      smses: 62688,
    },
    {
      date: "2018-02-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5584217,
      accounts: 154625,
      dataSent: 901616,
      dataReceived: 6677519,
      callsIn: 744290,
      callsInDuration: 774797,
      callsOut: 464751,
      callsOutDuration: 506051,
      smses: 62766,
    },
    {
      date: "2018-02-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 4743988,
      accounts: 136138,
      dataSent: 852722,
      dataReceived: 6526602,
      callsIn: 585196,
      callsInDuration: 682700,
      callsOut: 362437,
      callsOutDuration: 442113,
      smses: 54441,
    },
    {
      date: "2018-02-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5495511,
      accounts: 155862,
      dataSent: 845978,
      dataReceived: 6215206,
      callsIn: 728718,
      callsInDuration: 892441,
      callsOut: 444887,
      callsOutDuration: 544932,
      smses: 61427,
    },
    {
      date: "2018-02-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5583891,
      accounts: 154693,
      dataSent: 788002,
      dataReceived: 5882520,
      callsIn: 718643,
      callsInDuration: 890893,
      callsOut: 437289,
      callsOutDuration: 532729,
      smses: 61830,
    },
    {
      date: "2018-02-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5883152,
      accounts: 155940,
      dataSent: 923903,
      dataReceived: 6869095,
      callsIn: 738102,
      callsInDuration: 904653,
      callsOut: 446204,
      callsOutDuration: 553318,
      smses: 63402,
    },
    {
      date: "2018-03-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5410866,
      accounts: 157548,
      dataSent: 777432,
      dataReceived: 5859012,
      callsIn: 748602,
      callsInDuration: 890623,
      callsOut: 457355,
      callsOutDuration: 548003,
      smses: 63413,
    },
    {
      date: "2018-03-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5884801,
      accounts: 163288,
      dataSent: 956592,
      dataReceived: 7260184,
      callsIn: 813415,
      callsInDuration: 897010,
      callsOut: 496373,
      callsOutDuration: 561128,
      smses: 69651,
    },
    {
      date: "2018-03-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5626755,
      accounts: 157328,
      dataSent: 933924,
      dataReceived: 7062643,
      callsIn: 773903,
      callsInDuration: 789539,
      callsOut: 485825,
      callsOutDuration: 528856,
      smses: 66045,
    },
    {
      date: "2018-03-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5227524,
      accounts: 137447,
      dataSent: 879016,
      dataReceived: 6754482,
      callsIn: 590405,
      callsInDuration: 686756,
      callsOut: 371871,
      callsOutDuration: 448641,
      smses: 58471,
    },
    {
      date: "2018-03-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5690270,
      accounts: 159326,
      dataSent: 879142,
      dataReceived: 6721033,
      callsIn: 769916,
      callsInDuration: 900231,
      callsOut: 482774,
      callsOutDuration: 575261,
      smses: 68151,
    },
    {
      date: "2018-03-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5752078,
      accounts: 157843,
      dataSent: 862190,
      dataReceived: 6654072,
      callsIn: 752929,
      callsInDuration: 887457,
      callsOut: 471285,
      callsOutDuration: 565901,
      smses: 71592,
    },
    {
      date: "2018-03-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5795116,
      accounts: 157771,
      dataSent: 781599,
      dataReceived: 5873668,
      callsIn: 749916,
      callsInDuration: 868211,
      callsOut: 471125,
      callsOutDuration: 548642,
      smses: 71177,
    },
    {
      date: "2018-03-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6183585,
      accounts: 158535,
      dataSent: 1022848,
      dataReceived: 7626146,
      callsIn: 766299,
      callsInDuration: 893109,
      callsOut: 469446,
      callsOutDuration: 566302,
      smses: 74090,
    },
    {
      date: "2018-03-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5866479,
      accounts: 161942,
      dataSent: 921708,
      dataReceived: 6831382,
      callsIn: 803317,
      callsInDuration: 915081,
      callsOut: 488045,
      callsOutDuration: 568983,
      smses: 71999,
    },
    {
      date: "2018-03-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6033443,
      accounts: 160359,
      dataSent: 983437,
      dataReceived: 7275220,
      callsIn: 802217,
      callsInDuration: 796590,
      callsOut: 497705,
      callsOutDuration: 533239,
      smses: 72490,
    },
    {
      date: "2018-03-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5672142,
      accounts: 140350,
      dataSent: 910833,
      dataReceived: 6886427,
      callsIn: 611448,
      callsInDuration: 703994,
      callsOut: 372949,
      callsOutDuration: 457904,
      smses: 63145,
    },
    {
      date: "2018-03-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5877358,
      accounts: 156194,
      dataSent: 876330,
      dataReceived: 6570037,
      callsIn: 746078,
      callsInDuration: 910958,
      callsOut: 452660,
      callsOutDuration: 572000,
      smses: 73330,
    },
    {
      date: "2018-03-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6080734,
      accounts: 154567,
      dataSent: 904455,
      dataReceived: 6750816,
      callsIn: 719971,
      callsInDuration: 903679,
      callsOut: 443374,
      callsOutDuration: 573156,
      smses: 73767,
    },
    {
      date: "2018-03-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6181295,
      accounts: 153961,
      dataSent: 896529,
      dataReceived: 6759718,
      callsIn: 721315,
      callsInDuration: 908095,
      callsOut: 438723,
      callsOutDuration: 576785,
      smses: 70973,
    },
    {
      date: "2018-03-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6260346,
      accounts: 156433,
      dataSent: 898537,
      dataReceived: 6738367,
      callsIn: 738958,
      callsInDuration: 931515,
      callsOut: 448187,
      callsOutDuration: 581407,
      smses: 71772,
    },
    {
      date: "2018-03-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6457495,
      accounts: 165033,
      dataSent: 948656,
      dataReceived: 6965958,
      callsIn: 834920,
      callsInDuration: 943643,
      callsOut: 506134,
      callsOutDuration: 608442,
      smses: 77241,
    },
    {
      date: "2018-03-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6416873,
      accounts: 162210,
      dataSent: 986546,
      dataReceived: 7320419,
      callsIn: 817810,
      callsInDuration: 823899,
      callsOut: 501752,
      callsOutDuration: 545509,
      smses: 77464,
    },
    {
      date: "2018-03-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6110297,
      accounts: 144162,
      dataSent: 932247,
      dataReceived: 7044091,
      callsIn: 647601,
      callsInDuration: 729400,
      callsOut: 396157,
      callsOutDuration: 479260,
      smses: 68462,
    },
    {
      date: "2018-03-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6542110,
      accounts: 160634,
      dataSent: 917649,
      dataReceived: 6679213,
      callsIn: 764679,
      callsInDuration: 918827,
      callsOut: 465667,
      callsOutDuration: 582168,
      smses: 73454,
    },
    {
      date: "2018-03-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6538185,
      accounts: 158477,
      dataSent: 856483,
      dataReceived: 6331454,
      callsIn: 747632,
      callsInDuration: 910745,
      callsOut: 462585,
      callsOutDuration: 579238,
      smses: 76618,
    },
    {
      date: "2018-03-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 2375572,
      accounts: 113985,
      dataSent: 469065,
      dataReceived: 3550568,
      callsIn: 170260,
      callsInDuration: 349420,
      callsOut: 456004,
      callsOutDuration: 588029,
      smses: 75327,
    },
    {
      date: "2018-03-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6605365,
      accounts: 158085,
      dataSent: 899088,
      dataReceived: 6670834,
      callsIn: 732586,
      callsInDuration: 930375,
      callsOut: 449991,
      callsOutDuration: 558532,
      smses: 76603,
    },
    {
      date: "2018-03-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7008187,
      accounts: 163220,
      dataSent: 1030360,
      dataReceived: 7610675,
      callsIn: 788734,
      callsInDuration: 921840,
      callsOut: 490868,
      callsOutDuration: 567976,
      smses: 81541,
    },
    {
      date: "2018-03-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6726077,
      accounts: 163883,
      dataSent: 978517,
      dataReceived: 7274371,
      callsIn: 807789,
      callsInDuration: 831552,
      callsOut: 500091,
      callsOutDuration: 527852,
      smses: 81683,
    },
    {
      date: "2018-03-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6355096,
      accounts: 145256,
      dataSent: 948114,
      dataReceived: 7251701,
      callsIn: 637713,
      callsInDuration: 737247,
      callsOut: 395435,
      callsOutDuration: 470514,
      smses: 71277,
    },
    {
      date: "2018-03-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6558166,
      accounts: 160969,
      dataSent: 851122,
      dataReceived: 6349097,
      callsIn: 756952,
      callsInDuration: 943645,
      callsOut: 464461,
      callsOutDuration: 569993,
      smses: 78513,
    },
    {
      date: "2018-03-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7086004,
      accounts: 160889,
      dataSent: 1037932,
      dataReceived: 7560335,
      callsIn: 755189,
      callsInDuration: 947885,
      callsOut: 464078,
      callsOutDuration: 568684,
      smses: 81295,
    },
    {
      date: "2018-03-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7090418,
      accounts: 162271,
      dataSent: 977830,
      dataReceived: 7179787,
      callsIn: 769932,
      callsInDuration: 954641,
      callsOut: 473301,
      callsOutDuration: 577106,
      smses: 80721,
    },
    {
      date: "2018-03-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7210994,
      accounts: 168634,
      dataSent: 1025706,
      dataReceived: 7573011,
      callsIn: 835263,
      callsInDuration: 953873,
      callsOut: 511482,
      callsOutDuration: 573986,
      smses: 84748,
    },
    {
      date: "2018-03-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6763417,
      accounts: 148225,
      dataSent: 989995,
      dataReceived: 7374882,
      callsIn: 643559,
      callsInDuration: 768291,
      callsOut: 400086,
      callsOutDuration: 478785,
      smses: 72790,
    },
    {
      date: "2018-03-31",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6814161,
      accounts: 156163,
      dataSent: 1024207,
      dataReceived: 7564995,
      callsIn: 732322,
      callsInDuration: 765963,
      callsOut: 450372,
      callsOutDuration: 482257,
      smses: 79611,
    },
    {
      date: "2018-04-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6525497,
      accounts: 143139,
      dataSent: 997929,
      dataReceived: 7431047,
      callsIn: 618243,
      callsInDuration: 724521,
      callsOut: 381692,
      callsOutDuration: 449644,
      smses: 71694,
    },
    {
      date: "2018-04-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6662153,
      accounts: 164631,
      dataSent: 959163,
      dataReceived: 7244096,
      callsIn: 784832,
      callsInDuration: 966021,
      callsOut: 482858,
      callsOutDuration: 581921,
      smses: 82155,
    },
    {
      date: "2018-04-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6767620,
      accounts: 163239,
      dataSent: 1001060,
      dataReceived: 7536042,
      callsIn: 765662,
      callsInDuration: 961491,
      callsOut: 469468,
      callsOutDuration: 565740,
      smses: 81256,
    },
    {
      date: "2018-04-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6517277,
      accounts: 164579,
      dataSent: 957157,
      dataReceived: 7344778,
      callsIn: 772807,
      callsInDuration: 976463,
      callsOut: 472904,
      callsOutDuration: 569254,
      smses: 81256,
    },
    {
      date: "2018-04-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6778925,
      accounts: 164610,
      dataSent: 1014183,
      dataReceived: 7602936,
      callsIn: 779583,
      callsInDuration: 969954,
      callsOut: 484456,
      callsOutDuration: 580201,
      smses: 83749,
    },
    {
      date: "2018-04-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7072854,
      accounts: 171710,
      dataSent: 1053735,
      dataReceived: 7888860,
      callsIn: 856891,
      callsInDuration: 976444,
      callsOut: 512588,
      callsOutDuration: 562514,
      smses: 85344,
    },
    {
      date: "2018-04-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8516623,
      accounts: 167889,
      dataSent: 1086104,
      dataReceived: 8098724,
      callsIn: 825763,
      callsInDuration: 866215,
      callsOut: 524367,
      callsOutDuration: 549909,
      smses: 85386,
    },
    {
      date: "2018-04-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6705921,
      accounts: 150163,
      dataSent: 1074284,
      dataReceived: 8249683,
      callsIn: 653016,
      callsInDuration: 774769,
      callsOut: 406441,
      callsOutDuration: 461742,
      smses: 72799,
    },
    {
      date: "2018-04-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7090836,
      accounts: 167213,
      dataSent: 983267,
      dataReceived: 7459722,
      callsIn: 759115,
      callsInDuration: 1001915,
      callsOut: 489881,
      callsOutDuration: 592227,
      smses: 83870,
    },
    {
      date: "2018-04-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6825894,
      accounts: 165989,
      dataSent: 1086395,
      dataReceived: 8105895,
      callsIn: 748272,
      callsInDuration: 995649,
      callsOut: 483284,
      callsOutDuration: 585104,
      smses: 84491,
    },
    {
      date: "2018-04-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6974414,
      accounts: 165730,
      dataSent: 1042149,
      dataReceived: 7745880,
      callsIn: 743763,
      callsInDuration: 1010199,
      callsOut: 461848,
      callsOutDuration: 559967,
      smses: 82560,
    },
    {
      date: "2018-04-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7200367,
      accounts: 168098,
      dataSent: 1062145,
      dataReceived: 7911354,
      callsIn: 761404,
      callsInDuration: 1010344,
      callsOut: 479811,
      callsOutDuration: 573615,
      smses: 82345,
    },
    {
      date: "2018-04-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7440757,
      accounts: 173488,
      dataSent: 1073303,
      dataReceived: 8081455,
      callsIn: 824238,
      callsInDuration: 987188,
      callsOut: 520851,
      callsOutDuration: 575922,
      smses: 87190,
    },
    {
      date: "2018-04-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7333558,
      accounts: 172238,
      dataSent: 1109566,
      dataReceived: 8151186,
      callsIn: 815746,
      callsInDuration: 884386,
      callsOut: 525726,
      callsOutDuration: 547296,
      smses: 88486,
    },
    {
      date: "2018-04-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7034480,
      accounts: 154382,
      dataSent: 1054858,
      dataReceived: 7887149,
      callsIn: 651663,
      callsInDuration: 786071,
      callsOut: 417003,
      callsOutDuration: 470194,
      smses: 77266,
    },
    {
      date: "2018-04-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6985495,
      accounts: 167686,
      dataSent: 1071563,
      dataReceived: 8093621,
      callsIn: 712230,
      callsInDuration: 929327,
      callsOut: 461055,
      callsOutDuration: 550674,
      smses: 81890,
    },
    {
      date: "2018-04-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6497322,
      accounts: 155218,
      dataSent: 1052449,
      dataReceived: 7975850,
      callsIn: 538973,
      callsInDuration: 632228,
      callsOut: 357173,
      callsOutDuration: 394078,
      smses: 80535,
    },
    {
      date: "2018-04-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7764072,
      accounts: 155128,
      dataSent: 893834,
      dataReceived: 6681989,
      callsIn: 434942,
      callsInDuration: 615069,
      callsOut: 295663,
      callsOutDuration: 373558,
      smses: 89734,
    },
    {
      date: "2018-04-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6341428,
      accounts: 170120,
      dataSent: 1067287,
      dataReceived: 8082842,
      callsIn: 773464,
      callsInDuration: 1027056,
      callsOut: 506795,
      callsOutDuration: 608265,
      smses: 90467,
    },
    {
      date: "2018-04-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6583147,
      accounts: 178131,
      dataSent: 1095323,
      dataReceived: 8316511,
      callsIn: 854560,
      callsInDuration: 1030555,
      callsOut: 561049,
      callsOutDuration: 623085,
      smses: 96254,
    },
    {
      date: "2018-04-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6454299,
      accounts: 175492,
      dataSent: 1117662,
      dataReceived: 8318814,
      callsIn: 831876,
      callsInDuration: 920282,
      callsOut: 550836,
      callsOutDuration: 572703,
      smses: 92862,
    },
    {
      date: "2018-04-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6027865,
      accounts: 154131,
      dataSent: 1033477,
      dataReceived: 7933563,
      callsIn: 643287,
      callsInDuration: 805788,
      callsOut: 430322,
      callsOutDuration: 499290,
      smses: 76060,
    },
    {
      date: "2018-04-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6441075,
      accounts: 172926,
      dataSent: 1011725,
      dataReceived: 7573507,
      callsIn: 781523,
      callsInDuration: 1013181,
      callsOut: 518903,
      callsOutDuration: 613104,
      smses: 82339,
    },
    {
      date: "2018-04-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6459170,
      accounts: 170084,
      dataSent: 1020066,
      dataReceived: 7938321,
      callsIn: 759813,
      callsInDuration: 1021549,
      callsOut: 482585,
      callsOutDuration: 589171,
      smses: 88089,
    },
    {
      date: "2018-04-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6375364,
      accounts: 169371,
      dataSent: 989676,
      dataReceived: 7584616,
      callsIn: 752970,
      callsInDuration: 1003102,
      callsOut: 476827,
      callsOutDuration: 582851,
      smses: 85624,
    },
    {
      date: "2018-04-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6489769,
      accounts: 170272,
      dataSent: 1072790,
      dataReceived: 8180097,
      callsIn: 764215,
      callsInDuration: 1020420,
      callsOut: 479409,
      callsOutDuration: 591479,
      smses: 87079,
    },
    {
      date: "2018-04-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6526167,
      accounts: 182549,
      dataSent: 1069633,
      dataReceived: 8248307,
      callsIn: 890272,
      callsInDuration: 1050259,
      callsOut: 572704,
      callsOutDuration: 636852,
      smses: 98137,
    },
    {
      date: "2018-04-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6626999,
      accounts: 180770,
      dataSent: 1153257,
      dataReceived: 8664242,
      callsIn: 913479,
      callsInDuration: 943196,
      callsOut: 568519,
      callsOutDuration: 584301,
      smses: 93185,
    },
    {
      date: "2018-04-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6289483,
      accounts: 160460,
      dataSent: 1126650,
      dataReceived: 8603233,
      callsIn: 719608,
      callsInDuration: 802919,
      callsOut: 441981,
      callsOutDuration: 491643,
      smses: 80214,
    },
    {
      date: "2018-04-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6163784,
      accounts: 159483,
      dataSent: 993376,
      dataReceived: 7551105,
      callsIn: 694627,
      callsInDuration: 810184,
      callsOut: 430838,
      callsOutDuration: 489453,
      smses: 79893,
    },
    {
      date: "2018-05-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5839626,
      accounts: 156974,
      dataSent: 1024434,
      dataReceived: 7961759,
      callsIn: 672603,
      callsInDuration: 877373,
      callsOut: 417876,
      callsOutDuration: 521434,
      smses: 79423,
    },
    {
      date: "2018-05-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6672610,
      accounts: 176506,
      dataSent: 1013556,
      dataReceived: 7704361,
      callsIn: 843362,
      callsInDuration: 1029028,
      callsOut: 521175,
      callsOutDuration: 609687,
      smses: 91653,
    },
    {
      date: "2018-05-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6359792,
      accounts: 175102,
      dataSent: 1092584,
      dataReceived: 8275902,
      callsIn: 825821,
      callsInDuration: 1026652,
      callsOut: 511712,
      callsOutDuration: 603514,
      smses: 87183,
    },
    {
      date: "2018-05-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6790102,
      accounts: 182510,
      dataSent: 1148230,
      dataReceived: 8950766,
      callsIn: 910165,
      callsInDuration: 1040791,
      callsOut: 561929,
      callsOutDuration: 615640,
      smses: 95475,
    },
    {
      date: "2018-05-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6551976,
      accounts: 180835,
      dataSent: 1157144,
      dataReceived: 8902437,
      callsIn: 893149,
      callsInDuration: 941027,
      callsOut: 562176,
      callsOutDuration: 579592,
      smses: 91727,
    },
    {
      date: "2018-05-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6145146,
      accounts: 158513,
      dataSent: 1101939,
      dataReceived: 8719691,
      callsIn: 689510,
      callsInDuration: 835214,
      callsOut: 434572,
      callsOutDuration: 498803,
      smses: 77961,
    },
    {
      date: "2018-05-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6658757,
      accounts: 176684,
      dataSent: 1115287,
      dataReceived: 8645658,
      callsIn: 825380,
      callsInDuration: 1046037,
      callsOut: 526176,
      callsOutDuration: 617747,
      smses: 93142,
    },
    {
      date: "2018-05-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 3178416,
      accounts: 126754,
      dataSent: 465683,
      dataReceived: 3557824,
      callsIn: 288967,
      callsInDuration: 326533,
      callsOut: 175794,
      callsOutDuration: 183951,
      smses: 31813,
    },
    {
      date: "2018-05-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 91405,
      accounts: 5761,
      dataSent: 4604,
      dataReceived: 39364,
      callsIn: 0,
      callsInDuration: 0,
      callsOut: 15486,
      callsOutDuration: 22390,
      smses: 1142,
    },
    {
      date: "2018-05-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6403492,
      accounts: 178359,
      dataSent: 1188818,
      dataReceived: 9588888,
      callsIn: 831054,
      callsInDuration: 1011385,
      callsOut: 503756,
      callsOutDuration: 580262,
      smses: 88922,
    },
    {
      date: "2018-05-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7141045,
      accounts: 191247,
      dataSent: 1278277,
      dataReceived: 9968590,
      callsIn: 977666,
      callsInDuration: 1073742,
      callsOut: 587497,
      callsOutDuration: 631490,
      smses: 97300,
    },
    {
      date: "2018-05-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6865631,
      accounts: 189246,
      dataSent: 1243360,
      dataReceived: 9685577,
      callsIn: 965267,
      callsInDuration: 972052,
      callsOut: 593498,
      callsOutDuration: 598654,
      smses: 94409,
    },
    {
      date: "2018-05-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6720555,
      accounts: 172580,
      dataSent: 1278377,
      dataReceived: 9755417,
      callsIn: 789016,
      callsInDuration: 916714,
      callsOut: 496227,
      callsOutDuration: 586545,
      smses: 88144,
    },
    {
      date: "2018-05-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6852172,
      accounts: 179624,
      dataSent: 1181693,
      dataReceived: 9064592,
      callsIn: 836463,
      callsInDuration: 1063474,
      callsOut: 497743,
      callsOutDuration: 597876,
      smses: 89050,
    },
    {
      date: "2018-05-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6894256,
      accounts: 178945,
      dataSent: 1178732,
      dataReceived: 9001721,
      callsIn: 829038,
      callsInDuration: 1049668,
      callsOut: 494136,
      callsOutDuration: 590427,
      smses: 90923,
    },
    {
      date: "2018-05-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6905934,
      accounts: 178661,
      dataSent: 1189417,
      dataReceived: 9291311,
      callsIn: 828989,
      callsInDuration: 1074044,
      callsOut: 492275,
      callsOutDuration: 594954,
      smses: 89705,
    },
    {
      date: "2018-05-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6937835,
      accounts: 179102,
      dataSent: 1195272,
      dataReceived: 9515943,
      callsIn: 842253,
      callsInDuration: 1070981,
      callsOut: 500479,
      callsOutDuration: 598808,
      smses: 91464,
    },
    {
      date: "2018-05-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7079521,
      accounts: 187696,
      dataSent: 1268363,
      dataReceived: 10053208,
      callsIn: 928207,
      callsInDuration: 1084116,
      callsOut: 557990,
      callsOutDuration: 627210,
      smses: 97119,
    },
    {
      date: "2018-05-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6945749,
      accounts: 185488,
      dataSent: 1257421,
      dataReceived: 9883847,
      callsIn: 911407,
      callsInDuration: 976096,
      callsOut: 551690,
      callsOutDuration: 582947,
      smses: 92533,
    },
    {
      date: "2018-05-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6553849,
      accounts: 161502,
      dataSent: 1214375,
      dataReceived: 9666054,
      callsIn: 692352,
      callsInDuration: 863828,
      callsOut: 423270,
      callsOutDuration: 501720,
      smses: 79643,
    },
    {
      date: "2018-05-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7088404,
      accounts: 180939,
      dataSent: 1213193,
      dataReceived: 9613058,
      callsIn: 841134,
      callsInDuration: 1083454,
      callsOut: 517588,
      callsOutDuration: 627080,
      smses: 94519,
    },
    {
      date: "2018-05-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7093820,
      accounts: 179240,
      dataSent: 1149296,
      dataReceived: 8999645,
      callsIn: 822693,
      callsInDuration: 1074151,
      callsOut: 504482,
      callsOutDuration: 617290,
      smses: 93041,
    },
    {
      date: "2018-05-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7078816,
      accounts: 177412,
      dataSent: 1316440,
      dataReceived: 10332022,
      callsIn: 812390,
      callsInDuration: 1072140,
      callsOut: 504760,
      callsOutDuration: 617068,
      smses: 92514,
    },
    {
      date: "2018-05-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7306442,
      accounts: 186237,
      dataSent: 1280330,
      dataReceived: 10063169,
      callsIn: 910447,
      callsInDuration: 1088376,
      callsOut: 553263,
      callsOutDuration: 630711,
      smses: 98061,
    },
    {
      date: "2018-05-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6790212,
      accounts: 174609,
      dataSent: 1233938,
      dataReceived: 9851949,
      callsIn: 798836,
      callsInDuration: 937951,
      callsOut: 487092,
      callsOutDuration: 552765,
      smses: 86169,
    },
    {
      date: "2018-05-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6753482,
      accounts: 176051,
      dataSent: 1262496,
      dataReceived: 9807168,
      callsIn: 818956,
      callsInDuration: 890896,
      callsOut: 503050,
      callsOutDuration: 539587,
      smses: 88113,
    },
    {
      date: "2018-05-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6565040,
      accounts: 159317,
      dataSent: 1197062,
      dataReceived: 9548796,
      callsIn: 667988,
      callsInDuration: 835323,
      callsOut: 411405,
      callsOutDuration: 490676,
      smses: 81243,
    },
    {
      date: "2018-05-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7171933,
      accounts: 180556,
      dataSent: 1219617,
      dataReceived: 9610241,
      callsIn: 826126,
      callsInDuration: 1065398,
      callsOut: 503311,
      callsOutDuration: 611801,
      smses: 94556,
    },
    {
      date: "2018-05-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7215016,
      accounts: 179389,
      dataSent: 1227642,
      dataReceived: 9638601,
      callsIn: 817609,
      callsInDuration: 1070265,
      callsOut: 497877,
      callsOutDuration: 603286,
      smses: 93181,
    },
    {
      date: "2018-05-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7198978,
      accounts: 179830,
      dataSent: 1230860,
      dataReceived: 9845391,
      callsIn: 819965,
      callsInDuration: 1098524,
      callsOut: 496259,
      callsOutDuration: 615533,
      smses: 90335,
    },
    {
      date: "2018-05-31",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7297548,
      accounts: 167034,
      dataSent: 1258294,
      dataReceived: 9816519,
      callsIn: 678273,
      callsInDuration: 731508,
      callsOut: 527954,
      callsOutDuration: 621903,
      smses: 95328,
    },
    {
      date: "2018-06-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 5757882,
      accounts: 196443,
      dataSent: 1371220,
      dataReceived: 10583439,
      callsIn: 986645,
      callsInDuration: 1090024,
      callsOut: 610822,
      callsOutDuration: 645616,
      smses: 104447,
    },
    {
      date: "2018-06-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5733441,
      accounts: 191061,
      dataSent: 1360081,
      dataReceived: 10828581,
      callsIn: 940321,
      callsInDuration: 983077,
      callsOut: 584021,
      callsOutDuration: 604336,
      smses: 97616,
    },
    {
      date: "2018-06-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5351028,
      accounts: 167743,
      dataSent: 1253395,
      dataReceived: 10148636,
      callsIn: 727028,
      callsInDuration: 896332,
      callsOut: 448598,
      callsOutDuration: 524153,
      smses: 86789,
    },
    {
      date: "2018-06-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5724266,
      accounts: 186173,
      dataSent: 1256476,
      dataReceived: 10066003,
      callsIn: 864570,
      callsInDuration: 1105243,
      callsOut: 532473,
      callsOutDuration: 636808,
      smses: 97974,
    },
    {
      date: "2018-06-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5716188,
      accounts: 185703,
      dataSent: 1118356,
      dataReceived: 8903601,
      callsIn: 853882,
      callsInDuration: 1089618,
      callsOut: 520566,
      callsOutDuration: 612641,
      smses: 98597,
    },
    {
      date: "2018-06-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5910825,
      accounts: 185822,
      dataSent: 1416197,
      dataReceived: 11334540,
      callsIn: 852152,
      callsInDuration: 1104873,
      callsOut: 523325,
      callsOutDuration: 626462,
      smses: 96268,
    },
    {
      date: "2018-06-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 5756279,
      accounts: 186261,
      dataSent: 1368180,
      dataReceived: 10564765,
      callsIn: 863835,
      callsInDuration: 1110999,
      callsOut: 536196,
      callsOutDuration: 645276,
      smses: 96044,
    },
    {
      date: "2018-06-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6144472,
      accounts: 198501,
      dataSent: 1471599,
      dataReceived: 11096086,
      callsIn: 979693,
      callsInDuration: 1127707,
      callsOut: 598539,
      callsOutDuration: 656618,
      smses: 102404,
    },
    {
      date: "2018-06-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 5966434,
      accounts: 194008,
      dataSent: 1453973,
      dataReceived: 10945858,
      callsIn: 947868,
      callsInDuration: 995485,
      callsOut: 579195,
      callsOutDuration: 593060,
      smses: 98571,
    },
    {
      date: "2018-06-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5523426,
      accounts: 168513,
      dataSent: 1250908,
      dataReceived: 9805354,
      callsIn: 718031,
      callsInDuration: 904125,
      callsOut: 439111,
      callsOutDuration: 523915,
      smses: 80182,
    },
    {
      date: "2018-06-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 5907876,
      accounts: 188596,
      dataSent: 1351244,
      dataReceived: 10342706,
      callsIn: 866997,
      callsInDuration: 1129682,
      callsOut: 528252,
      callsOutDuration: 635764,
      smses: 96671,
    },
    {
      date: "2018-06-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 5938843,
      accounts: 189152,
      dataSent: 1282271,
      dataReceived: 9760982,
      callsIn: 863464,
      callsInDuration: 1123408,
      callsOut: 516048,
      callsOutDuration: 619188,
      smses: 96312,
    },
    {
      date: "2018-06-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6172624,
      accounts: 174260,
      dataSent: 1472456,
      dataReceived: 11278260,
      callsIn: 627432,
      callsInDuration: 1131926,
      callsOut: 464061,
      callsOutDuration: 634342,
      smses: 95480,
    },
    {
      date: "2018-06-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6076266,
      accounts: 170831,
      dataSent: 1433478,
      dataReceived: 11138444,
      callsIn: 573560,
      callsInDuration: 1111348,
      callsOut: 454978,
      callsOutDuration: 625229,
      smses: 95296,
    },
    {
      date: "2018-06-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6335745,
      accounts: 185889,
      dataSent: 1577639,
      dataReceived: 12153140,
      callsIn: 674456,
      callsInDuration: 1151606,
      callsOut: 545126,
      callsOutDuration: 668739,
      smses: 108916,
    },
    {
      date: "2018-06-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6147689,
      accounts: 179459,
      dataSent: 1498624,
      dataReceived: 11412525,
      callsIn: 630526,
      callsInDuration: 1016404,
      callsOut: 529775,
      callsOutDuration: 617187,
      smses: 104735,
    },
    {
      date: "2018-06-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5870615,
      accounts: 164405,
      dataSent: 1501279,
      dataReceived: 11597146,
      callsIn: 470784,
      callsInDuration: 926574,
      callsOut: 413120,
      callsOutDuration: 578007,
      smses: 92399,
    },
    {
      date: "2018-06-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6108400,
      accounts: 172579,
      dataSent: 1447532,
      dataReceived: 11164092,
      callsIn: 572927,
      callsInDuration: 1138946,
      callsOut: 458266,
      callsOutDuration: 649029,
      smses: 99852,
    },
    {
      date: "2018-06-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6225265,
      accounts: 171695,
      dataSent: 1447889,
      dataReceived: 11469906,
      callsIn: 566107,
      callsInDuration: 1126845,
      callsOut: 445396,
      callsOutDuration: 626983,
      smses: 100100,
    },
    {
      date: "2018-06-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6262750,
      accounts: 171842,
      dataSent: 1473126,
      dataReceived: 11580273,
      callsIn: 567738,
      callsInDuration: 1135074,
      callsOut: 452945,
      callsOutDuration: 648671,
      smses: 97444,
    },
    {
      date: "2018-06-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6361441,
      accounts: 172253,
      dataSent: 1530157,
      dataReceived: 11901328,
      callsIn: 568560,
      callsInDuration: 1121395,
      callsOut: 462773,
      callsOutDuration: 655863,
      smses: 100523,
    },
    {
      date: "2018-06-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6496808,
      accounts: 182383,
      dataSent: 1563340,
      dataReceived: 11971907,
      callsIn: 637459,
      callsInDuration: 1120990,
      callsOut: 530180,
      callsOutDuration: 671062,
      smses: 110541,
    },
    {
      date: "2018-06-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6261528,
      accounts: 175898,
      dataSent: 1547952,
      dataReceived: 11658851,
      callsIn: 589338,
      callsInDuration: 985272,
      callsOut: 507734,
      callsOutDuration: 611707,
      smses: 104062,
    },
    {
      date: "2018-06-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5861377,
      accounts: 166789,
      dataSent: 1407858,
      dataReceived: 10903887,
      callsIn: 429157,
      callsInDuration: 877882,
      callsOut: 376664,
      callsOutDuration: 528940,
      smses: 87971,
    },
    {
      date: "2018-06-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6343119,
      accounts: 172633,
      dataSent: 1410475,
      dataReceived: 10929015,
      callsIn: 557364,
      callsInDuration: 1099355,
      callsOut: 455788,
      callsOutDuration: 637445,
      smses: 99752,
    },
    {
      date: "2018-06-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6454803,
      accounts: 174490,
      dataSent: 1565868,
      dataReceived: 12358252,
      callsIn: 564029,
      callsInDuration: 1110084,
      callsOut: 455422,
      callsOutDuration: 637034,
      smses: 102319,
    },
    {
      date: "2018-06-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6502109,
      accounts: 175396,
      dataSent: 1493455,
      dataReceived: 11626556,
      callsIn: 572283,
      callsInDuration: 1163661,
      callsOut: 462955,
      callsOutDuration: 676429,
      smses: 102887,
    },
    {
      date: "2018-06-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6308152,
      accounts: 177093,
      dataSent: 1499734,
      dataReceived: 11825392,
      callsIn: 584599,
      callsInDuration: 1158348,
      callsOut: 479881,
      callsOutDuration: 684025,
      smses: 103653,
    },
    {
      date: "2018-06-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 6754987,
      accounts: 186556,
      dataSent: 1563990,
      dataReceived: 12191094,
      callsIn: 659340,
      callsInDuration: 1169913,
      callsOut: 547494,
      callsOutDuration: 705745,
      smses: 112335,
    },
    {
      date: "2018-06-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6647857,
      accounts: 180618,
      dataSent: 1671355,
      dataReceived: 12958648,
      callsIn: 596733,
      callsInDuration: 1004449,
      callsOut: 529189,
      callsOutDuration: 640837,
      smses: 109075,
    },
    {
      date: "2018-07-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6147285,
      accounts: 174593,
      dataSent: 1532488,
      dataReceived: 12133643,
      callsIn: 1105164,
      callsInDuration: 2431650,
      callsOut: 392285,
      callsOutDuration: 555550,
      smses: 93037,
    },
    {
      date: "2018-07-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6652213,
      accounts: 181374,
      dataSent: 1564820,
      dataReceived: 12417648,
      callsIn: 565089,
      callsInDuration: 1125783,
      callsOut: 489470,
      callsOutDuration: 689365,
      smses: 106059,
    },
    {
      date: "2018-07-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6638191,
      accounts: 181283,
      dataSent: 1569398,
      dataReceived: 12694719,
      callsIn: 540576,
      callsInDuration: 1105194,
      callsOut: 467426,
      callsOutDuration: 671922,
      smses: 103789,
    },
    {
      date: "2018-07-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6650727,
      accounts: 181890,
      dataSent: 1566094,
      dataReceived: 12152751,
      callsIn: 546473,
      callsInDuration: 1128304,
      callsOut: 474847,
      callsOutDuration: 684050,
      smses: 103628,
    },
    {
      date: "2018-07-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6686706,
      accounts: 182207,
      dataSent: 1609684,
      dataReceived: 12541012,
      callsIn: 549904,
      callsInDuration: 1125472,
      callsOut: 482241,
      callsOutDuration: 691009,
      smses: 104037,
    },
    {
      date: "2018-07-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7005825,
      accounts: 185825,
      dataSent: 1721025,
      dataReceived: 13735251,
      callsIn: 590119,
      callsInDuration: 1091124,
      callsOut: 530156,
      callsOutDuration: 685738,
      smses: 113327,
    },
    {
      date: "2018-07-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6800050,
      accounts: 184450,
      dataSent: 1747959,
      dataReceived: 13630099,
      callsIn: 528708,
      callsInDuration: 945692,
      callsOut: 511818,
      callsOutDuration: 630464,
      smses: 106306,
    },
    {
      date: "2018-07-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6310476,
      accounts: 177984,
      dataSent: 1621117,
      dataReceived: 12706940,
      callsIn: 395696,
      callsInDuration: 852946,
      callsOut: 382395,
      callsOutDuration: 550214,
      smses: 89385,
    },
    {
      date: "2018-07-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6848428,
      accounts: 185110,
      dataSent: 1483922,
      dataReceived: 11607877,
      callsIn: 537731,
      callsInDuration: 1099348,
      callsOut: 482748,
      callsOutDuration: 676737,
      smses: 104440,
    },
    {
      date: "2018-07-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6872751,
      accounts: 185668,
      dataSent: 1651427,
      dataReceived: 12947960,
      callsIn: 522334,
      callsInDuration: 1081118,
      callsOut: 458999,
      callsOutDuration: 660884,
      smses: 99785,
    },
    {
      date: "2018-07-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6913323,
      accounts: 186275,
      dataSent: 1778393,
      dataReceived: 13821872,
      callsIn: 530567,
      callsInDuration: 1097591,
      callsOut: 462349,
      callsOutDuration: 670034,
      smses: 101066,
    },
    {
      date: "2018-07-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6815653,
      accounts: 185271,
      dataSent: 1683227,
      dataReceived: 12767354,
      callsIn: 560794,
      callsInDuration: 1151812,
      callsOut: 478389,
      callsOutDuration: 692469,
      smses: 102845,
    },
    {
      date: "2018-07-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7475220,
      accounts: 190348,
      dataSent: 1836811,
      dataReceived: 14121881,
      callsIn: 661462,
      callsInDuration: 1186242,
      callsOut: 557381,
      callsOutDuration: 724249,
      smses: 115060,
    },
    {
      date: "2018-07-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7160194,
      accounts: 187301,
      dataSent: 1801922,
      dataReceived: 13686017,
      callsIn: 610560,
      callsInDuration: 1072826,
      callsOut: 524424,
      callsOutDuration: 665336,
      smses: 109777,
    },
    {
      date: "2018-07-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6516811,
      accounts: 180064,
      dataSent: 1666047,
      dataReceived: 12984026,
      callsIn: 450990,
      callsInDuration: 946855,
      callsOut: 391377,
      callsOutDuration: 575350,
      smses: 92654,
    },
    {
      date: "2018-07-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7024612,
      accounts: 187366,
      dataSent: 1661463,
      dataReceived: 12432483,
      callsIn: 610844,
      callsInDuration: 1229940,
      callsOut: 498132,
      callsOutDuration: 724350,
      smses: 108494,
    },
    {
      date: "2018-07-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7148453,
      accounts: 187815,
      dataSent: 1701236,
      dataReceived: 12641303,
      callsIn: 600224,
      callsInDuration: 1223895,
      callsOut: 482431,
      callsOutDuration: 708910,
      smses: 106041,
    },
    {
      date: "2018-07-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 6972677,
      accounts: 188351,
      dataSent: 1695891,
      dataReceived: 12652971,
      callsIn: 572447,
      callsInDuration: 1194589,
      callsOut: 460533,
      callsOutDuration: 691919,
      smses: 102435,
    },
    {
      date: "2018-07-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7129481,
      accounts: 188138,
      dataSent: 1738786,
      dataReceived: 13034035,
      callsIn: 586832,
      callsInDuration: 1203641,
      callsOut: 475686,
      callsOutDuration: 711185,
      smses: 102140,
    },
    {
      date: "2018-07-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7436283,
      accounts: 192843,
      dataSent: 1839947,
      dataReceived: 13852105,
      callsIn: 660889,
      callsInDuration: 1198955,
      callsOut: 551486,
      callsOutDuration: 733264,
      smses: 114816,
    },
    {
      date: "2018-07-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7232023,
      accounts: 190760,
      dataSent: 1885745,
      dataReceived: 14077710,
      callsIn: 596526,
      callsInDuration: 1047920,
      callsOut: 519091,
      callsOutDuration: 659501,
      smses: 107710,
    },
    {
      date: "2018-07-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6679626,
      accounts: 184311,
      dataSent: 1711542,
      dataReceived: 13082930,
      callsIn: 440688,
      callsInDuration: 941061,
      callsOut: 393092,
      callsOutDuration: 583160,
      smses: 92529,
    },
    {
      date: "2018-07-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7186414,
      accounts: 191462,
      dataSent: 1638743,
      dataReceived: 12555366,
      callsIn: 581089,
      callsInDuration: 1186257,
      callsOut: 488936,
      callsOutDuration: 719431,
      smses: 105261,
    },
    {
      date: "2018-07-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6973841,
      accounts: 192585,
      dataSent: 1778530,
      dataReceived: 13527701,
      callsIn: 611034,
      callsInDuration: 1213441,
      callsOut: 500354,
      callsOutDuration: 712142,
      smses: 107166,
    },
    {
      date: "2018-07-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7292086,
      accounts: 193187,
      dataSent: 1833862,
      dataReceived: 13893655,
      callsIn: 634302,
      callsInDuration: 1243726,
      callsOut: 514540,
      callsOutDuration: 733987,
      smses: 109397,
    },
    {
      date: "2018-07-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7391371,
      accounts: 193351,
      dataSent: 1837997,
      dataReceived: 14265845,
      callsIn: 649333,
      callsInDuration: 1262503,
      callsOut: 529998,
      callsOutDuration: 758241,
      smses: 112229,
    },
    {
      date: "2018-07-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7549169,
      accounts: 194999,
      dataSent: 1931615,
      dataReceived: 14904114,
      callsIn: 654515,
      callsInDuration: 1206196,
      callsOut: 550709,
      callsOutDuration: 740231,
      smses: 116447,
    },
    {
      date: "2018-07-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7219656,
      accounts: 193987,
      dataSent: 1865121,
      dataReceived: 14110706,
      callsIn: 619401,
      callsInDuration: 1073595,
      callsOut: 536496,
      callsOutDuration: 672757,
      smses: 114054,
    },
    {
      date: "2018-07-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 6785517,
      accounts: 188385,
      dataSent: 1798683,
      dataReceived: 13886884,
      callsIn: 464145,
      callsInDuration: 997812,
      callsOut: 405438,
      callsOutDuration: 622047,
      smses: 99284,
    },
    {
      date: "2018-07-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7312970,
      accounts: 195592,
      dataSent: 1777140,
      dataReceived: 13849689,
      callsIn: 620472,
      callsInDuration: 1264740,
      callsOut: 510663,
      callsOutDuration: 751989,
      smses: 113728,
    },
    {
      date: "2018-07-31",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7415217,
      accounts: 195816,
      dataSent: 1758499,
      dataReceived: 13709731,
      callsIn: 619695,
      callsInDuration: 1267818,
      callsOut: 511856,
      callsOutDuration: 746966,
      smses: 115986,
    },
    {
      date: "2018-08-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7580067,
      accounts: 197517,
      dataSent: 1965317,
      dataReceived: 15071450,
      callsIn: 634335,
      callsInDuration: 1301344,
      callsOut: 523000,
      callsOutDuration: 783418,
      smses: 112560,
    },
    {
      date: "2018-08-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7510920,
      accounts: 196865,
      dataSent: 1701494,
      dataReceived: 13146148,
      callsIn: 653462,
      callsInDuration: 1303664,
      callsOut: 536076,
      callsOutDuration: 779265,
      smses: 115598,
    },
    {
      date: "2018-08-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7685265,
      accounts: 201340,
      dataSent: 1943708,
      dataReceived: 14845088,
      callsIn: 715261,
      callsInDuration: 1296928,
      callsOut: 602474,
      callsOutDuration: 797952,
      smses: 125217,
    },
    {
      date: "2018-08-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7522849,
      accounts: 199102,
      dataSent: 1991855,
      dataReceived: 15129226,
      callsIn: 669895,
      callsInDuration: 1152157,
      callsOut: 584769,
      callsOutDuration: 726923,
      smses: 121760,
    },
    {
      date: "2018-08-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7003908,
      accounts: 193007,
      dataSent: 1867043,
      dataReceived: 14407316,
      callsIn: 496158,
      callsInDuration: 1025390,
      callsOut: 438056,
      callsOutDuration: 633214,
      smses: 106087,
    },
    {
      date: "2018-08-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7302493,
      accounts: 199341,
      dataSent: 1680831,
      dataReceived: 12940221,
      callsIn: 643586,
      callsInDuration: 1246345,
      callsOut: 529413,
      callsOutDuration: 733287,
      smses: 117149,
    },
    {
      date: "2018-08-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7398939,
      accounts: 200665,
      dataSent: 2019785,
      dataReceived: 15597294,
      callsIn: 633568,
      callsInDuration: 1271069,
      callsOut: 524689,
      callsOutDuration: 763725,
      smses: 117162,
    },
    {
      date: "2018-08-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7674363,
      accounts: 200688,
      dataSent: 1966630,
      dataReceived: 15070216,
      callsIn: 661083,
      callsInDuration: 1325690,
      callsOut: 536782,
      callsOutDuration: 783673,
      smses: 117860,
    },
    {
      date: "2018-08-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7795599,
      accounts: 202663,
      dataSent: 2012331,
      dataReceived: 15554301,
      callsIn: 701823,
      callsInDuration: 1339943,
      callsOut: 578143,
      callsOutDuration: 810696,
      smses: 120297,
    },
    {
      date: "2018-08-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7491053,
      accounts: 198598,
      dataSent: 2024410,
      dataReceived: 15049942,
      callsIn: 609018,
      callsInDuration: 1135484,
      callsOut: 516402,
      callsOutDuration: 696121,
      smses: 113386,
    },
    {
      date: "2018-08-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7496103,
      accounts: 199261,
      dataSent: 2063111,
      dataReceived: 15065998,
      callsIn: 596410,
      callsInDuration: 1078375,
      callsOut: 516563,
      callsOutDuration: 677006,
      smses: 113578,
    },
    {
      date: "2018-08-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7134466,
      accounts: 196980,
      dataSent: 1984291,
      dataReceived: 14480911,
      callsIn: 478144,
      callsInDuration: 1016642,
      callsOut: 411919,
      callsOutDuration: 616631,
      smses: 103019,
    },
    {
      date: "2018-08-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7671133,
      accounts: 203995,
      dataSent: 1972953,
      dataReceived: 14671175,
      callsIn: 647597,
      callsInDuration: 1329007,
      callsOut: 527556,
      callsOutDuration: 784463,
      smses: 117935,
    },
    {
      date: "2018-08-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7515138,
      accounts: 203226,
      dataSent: 1958316,
      dataReceived: 14482870,
      callsIn: 641220,
      callsInDuration: 1323923,
      callsOut: 513828,
      callsOutDuration: 769580,
      smses: 110914,
    },
    {
      date: "2018-08-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7692877,
      accounts: 203564,
      dataSent: 1980728,
      dataReceived: 15357896,
      callsIn: 657913,
      callsInDuration: 1341355,
      callsOut: 529920,
      callsOutDuration: 782264,
      smses: 112283,
    },
    {
      date: "2018-08-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7796898,
      accounts: 204250,
      dataSent: 1958665,
      dataReceived: 15232132,
      callsIn: 674445,
      callsInDuration: 1340010,
      callsOut: 545857,
      callsOutDuration: 784910,
      smses: 116826,
    },
    {
      date: "2018-08-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8006393,
      accounts: 207237,
      dataSent: 2084617,
      dataReceived: 15856445,
      callsIn: 664689,
      callsInDuration: 1245224,
      callsOut: 551097,
      callsOutDuration: 759307,
      smses: 114684,
    },
    {
      date: "2018-08-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7893327,
      accounts: 207039,
      dataSent: 2126836,
      dataReceived: 16174873,
      callsIn: 613024,
      callsInDuration: 1088781,
      callsOut: 534303,
      callsOutDuration: 689806,
      smses: 110822,
    },
    {
      date: "2018-08-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7338710,
      accounts: 200869,
      dataSent: 2021985,
      dataReceived: 15611664,
      callsIn: 453505,
      callsInDuration: 975105,
      callsOut: 402649,
      callsOutDuration: 611192,
      smses: 92867,
    },
    {
      date: "2018-08-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7885978,
      accounts: 208261,
      dataSent: 2046010,
      dataReceived: 15731619,
      callsIn: 594968,
      callsInDuration: 1258465,
      callsOut: 505967,
      callsOutDuration: 764239,
      smses: 107249,
    },
    {
      date: "2018-08-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7925190,
      accounts: 208788,
      dataSent: 2064278,
      dataReceived: 15808955,
      callsIn: 640463,
      callsInDuration: 1264313,
      callsOut: 529435,
      callsOutDuration: 766712,
      smses: 103956,
    },
    {
      date: "2018-08-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7913750,
      accounts: 209399,
      dataSent: 1897464,
      dataReceived: 14630998,
      callsIn: 711211,
      callsInDuration: 1271406,
      callsOut: 564545,
      callsOutDuration: 765815,
      smses: 104173,
    },
    {
      date: "2018-08-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8017376,
      accounts: 210538,
      dataSent: 2231114,
      dataReceived: 17021371,
      callsIn: 760644,
      callsInDuration: 1376206,
      callsOut: 590551,
      callsOutDuration: 821896,
      smses: 106603,
    },
    {
      date: "2018-08-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8534869,
      accounts: 213400,
      dataSent: 1925220,
      dataReceived: 14489403,
      callsIn: 793547,
      callsInDuration: 1305352,
      callsOut: 621452,
      callsOutDuration: 789117,
      smses: 117194,
    },
    {
      date: "2018-08-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7871710,
      accounts: 209541,
      dataSent: 2179375,
      dataReceived: 16017116,
      callsIn: 732237,
      callsInDuration: 1121379,
      callsOut: 601556,
      callsOutDuration: 707801,
      smses: 116591,
    },
    {
      date: "2018-08-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7630519,
      accounts: 203262,
      dataSent: 2015897,
      dataReceived: 15195771,
      callsIn: 550047,
      callsInDuration: 1056467,
      callsOut: 455608,
      callsOutDuration: 643212,
      smses: 98215,
    },
    {
      date: "2018-08-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8165989,
      accounts: 209396,
      dataSent: 1987519,
      dataReceived: 15142998,
      callsIn: 714906,
      callsInDuration: 1366905,
      callsOut: 561900,
      callsOutDuration: 804283,
      smses: 115709,
    },
    {
      date: "2018-08-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7761086,
      accounts: 208196,
      dataSent: 1883161,
      dataReceived: 14458108,
      callsIn: 706861,
      callsInDuration: 1354642,
      callsOut: 552285,
      callsOutDuration: 798314,
      smses: 114852,
    },
    {
      date: "2018-08-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7254331,
      accounts: 209023,
      dataSent: 1986062,
      dataReceived: 15312976,
      callsIn: 674720,
      callsInDuration: 1289557,
      callsOut: 515525,
      callsOutDuration: 753582,
      smses: 109643,
    },
    {
      date: "2018-08-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7565199,
      accounts: 208674,
      dataSent: 2041375,
      dataReceived: 16054955,
      callsIn: 730070,
      callsInDuration: 1380007,
      callsOut: 570957,
      callsOutDuration: 828310,
      smses: 113560,
    },
    {
      date: "2018-08-31",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7730137,
      accounts: 215296,
      dataSent: 2118107,
      dataReceived: 16526413,
      callsIn: 815356,
      callsInDuration: 1367572,
      callsOut: 654943,
      callsOutDuration: 854539,
      smses: 123022,
    },
    {
      date: "2018-09-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7633025,
      accounts: 211102,
      dataSent: 2176030,
      dataReceived: 16832146,
      callsIn: 776033,
      callsInDuration: 1235575,
      callsOut: 643536,
      callsOutDuration: 790738,
      smses: 123033,
    },
    {
      date: "2018-09-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7108718,
      accounts: 204230,
      dataSent: 2071735,
      dataReceived: 16299143,
      callsIn: 573297,
      callsInDuration: 1101485,
      callsOut: 478999,
      callsOutDuration: 686823,
      smses: 103899,
    },
    {
      date: "2018-09-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7593451,
      accounts: 212277,
      dataSent: 2059027,
      dataReceived: 15963386,
      callsIn: 759711,
      callsInDuration: 1414955,
      callsOut: 610866,
      callsOutDuration: 860282,
      smses: 124152,
    },
    {
      date: "2018-09-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7566210,
      accounts: 211846,
      dataSent: 1939212,
      dataReceived: 15246554,
      callsIn: 726470,
      callsInDuration: 1395493,
      callsOut: 580389,
      callsOutDuration: 842173,
      smses: 117576,
    },
    {
      date: "2018-09-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7626911,
      accounts: 213363,
      dataSent: 2148936,
      dataReceived: 16865197,
      callsIn: 737536,
      callsInDuration: 1374085,
      callsOut: 581582,
      callsOutDuration: 824685,
      smses: 118141,
    },
    {
      date: "2018-09-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7954767,
      accounts: 220757,
      dataSent: 2200842,
      dataReceived: 17233678,
      callsIn: 817219,
      callsInDuration: 1525504,
      callsOut: 656752,
      callsOutDuration: 945124,
      smses: 129052,
    },
    {
      date: "2018-09-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7976945,
      accounts: 218358,
      dataSent: 2226886,
      dataReceived: 17539201,
      callsIn: 815526,
      callsInDuration: 1411517,
      callsOut: 663957,
      callsOutDuration: 885518,
      smses: 129140,
    },
    {
      date: "2018-09-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7802575,
      accounts: 215147,
      dataSent: 2334018,
      dataReceived: 18143446,
      callsIn: 770307,
      callsInDuration: 1232475,
      callsOut: 632426,
      callsOutDuration: 781875,
      smses: 121920,
    },
    {
      date: "2018-09-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7376075,
      accounts: 208460,
      dataSent: 2069714,
      dataReceived: 16578007,
      callsIn: 587825,
      callsInDuration: 1113417,
      callsOut: 487897,
      callsOutDuration: 684316,
      smses: 103691,
    },
    {
      date: "2018-09-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8027117,
      accounts: 217144,
      dataSent: 2306937,
      dataReceived: 18571514,
      callsIn: 764129,
      callsInDuration: 1440149,
      callsOut: 603132,
      callsOutDuration: 879396,
      smses: 79243,
    },
    {
      date: "2018-09-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7932959,
      accounts: 215672,
      dataSent: 2183157,
      dataReceived: 18046045,
      callsIn: 745052,
      callsInDuration: 1440058,
      callsOut: 578361,
      callsOutDuration: 863605,
      smses: 73987,
    },
    {
      date: "2018-09-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7778341,
      accounts: 214398,
      dataSent: 2149312,
      dataReceived: 17295174,
      callsIn: 747214,
      callsInDuration: 1442590,
      callsOut: 569608,
      callsOutDuration: 847836,
      smses: 72253,
    },
    {
      date: "2018-09-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 7913537,
      accounts: 215479,
      dataSent: 2172989,
      dataReceived: 17503530,
      callsIn: 768084,
      callsInDuration: 1455539,
      callsOut: 585736,
      callsOutDuration: 864918,
      smses: 83180,
    },
    {
      date: "2018-09-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8029117,
      accounts: 222085,
      dataSent: 2225159,
      dataReceived: 17505576,
      callsIn: 837258,
      callsInDuration: 1451173,
      callsOut: 636943,
      callsOutDuration: 864573,
      smses: 98617,
    },
    {
      date: "2018-09-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7874800,
      accounts: 216501,
      dataSent: 2226038,
      dataReceived: 17538437,
      callsIn: 799773,
      callsInDuration: 1288400,
      callsOut: 625838,
      callsOutDuration: 794794,
      smses: 98725,
    },
    {
      date: "2018-09-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7389895,
      accounts: 211089,
      dataSent: 2027662,
      dataReceived: 16265236,
      callsIn: 613261,
      callsInDuration: 1138696,
      callsOut: 484180,
      callsOutDuration: 693182,
      smses: 89371,
    },
    {
      date: "2018-09-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8029308,
      accounts: 219718,
      dataSent: 2326676,
      dataReceived: 18308913,
      callsIn: 723251,
      callsInDuration: 1447038,
      callsOut: 573502,
      callsOutDuration: 853732,
      smses: 100507,
    },
    {
      date: "2018-09-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7799407,
      accounts: 217501,
      dataSent: 2167944,
      dataReceived: 17214821,
      callsIn: 694356,
      callsInDuration: 1461452,
      callsOut: 553383,
      callsOutDuration: 864078,
      smses: 99017,
    },
    {
      date: "2018-09-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 7807202,
      accounts: 217144,
      dataSent: 2069964,
      dataReceived: 16345402,
      callsIn: 688270,
      callsInDuration: 1443405,
      callsOut: 546892,
      callsOutDuration: 844956,
      smses: 96769,
    },
    {
      date: "2018-09-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8087054,
      accounts: 219924,
      dataSent: 2378023,
      dataReceived: 18714244,
      callsIn: 704389,
      callsInDuration: 1465441,
      callsOut: 564190,
      callsOutDuration: 882033,
      smses: 98212,
    },
    {
      date: "2018-09-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8156650,
      accounts: 220812,
      dataSent: 2337966,
      dataReceived: 18649754,
      callsIn: 766988,
      callsInDuration: 1444935,
      callsOut: 632845,
      callsOutDuration: 881830,
      smses: 105678,
    },
    {
      date: "2018-09-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7942839,
      accounts: 219631,
      dataSent: 2397474,
      dataReceived: 18587649,
      callsIn: 697115,
      callsInDuration: 1242499,
      callsOut: 608377,
      callsOutDuration: 799417,
      smses: 98354,
    },
    {
      date: "2018-09-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7292093,
      accounts: 212899,
      dataSent: 2221329,
      dataReceived: 17754498,
      callsIn: 520545,
      callsInDuration: 1130574,
      callsOut: 464257,
      callsOutDuration: 720223,
      smses: 86181,
    },
    {
      date: "2018-09-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7921965,
      accounts: 220766,
      dataSent: 2159506,
      dataReceived: 17237021,
      callsIn: 703183,
      callsInDuration: 1454694,
      callsOut: 577462,
      callsOutDuration: 876641,
      smses: 100229,
    },
    {
      date: "2018-09-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7902575,
      accounts: 221224,
      dataSent: 2274381,
      dataReceived: 18270096,
      callsIn: 696184,
      callsInDuration: 1466718,
      callsOut: 571046,
      callsOutDuration: 887263,
      smses: 97492,
    },
    {
      date: "2018-09-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8062458,
      accounts: 221351,
      dataSent: 2321420,
      dataReceived: 18213228,
      callsIn: 718517,
      callsInDuration: 1469660,
      callsOut: 585963,
      callsOutDuration: 892333,
      smses: 97467,
    },
    {
      date: "2018-09-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8145206,
      accounts: 222131,
      dataSent: 2314659,
      dataReceived: 18768615,
      callsIn: 710207,
      callsInDuration: 1482745,
      callsOut: 569678,
      callsOutDuration: 886763,
      smses: 100074,
    },
    {
      date: "2018-09-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8294084,
      accounts: 224835,
      dataSent: 2384331,
      dataReceived: 19295023,
      callsIn: 772432,
      callsInDuration: 1443543,
      callsOut: 632278,
      callsOutDuration: 894809,
      smses: 105767,
    },
    {
      date: "2018-09-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8097429,
      accounts: 223084,
      dataSent: 2446291,
      dataReceived: 19442843,
      callsIn: 733484,
      callsInDuration: 1296771,
      callsOut: 630909,
      callsOutDuration: 833327,
      smses: 102878,
    },
    {
      date: "2018-09-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7611938,
      accounts: 217033,
      dataSent: 2306475,
      dataReceived: 18949896,
      callsIn: 547202,
      callsInDuration: 1170304,
      callsOut: 476333,
      callsOutDuration: 736787,
      smses: 89373,
    },
    {
      date: "2018-10-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8212689,
      accounts: 226866,
      dataSent: 2380492,
      dataReceived: 18831571,
      callsIn: 723045,
      callsInDuration: 1485904,
      callsOut: 605247,
      callsOutDuration: 921794,
      smses: 102907,
    },
    {
      date: "2018-10-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 8118973,
      accounts: 225416,
      dataSent: 2407976,
      dataReceived: 19433551,
      callsIn: 710072,
      callsInDuration: 1504138,
      callsOut: 587333,
      callsOutDuration: 919526,
      smses: 101395,
    },
    {
      date: "2018-10-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8125041,
      accounts: 225342,
      dataSent: 2367311,
      dataReceived: 19342987,
      callsIn: 710910,
      callsInDuration: 1493026,
      callsOut: 584343,
      callsOutDuration: 900339,
      smses: 98712,
    },
    {
      date: "2018-10-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8134682,
      accounts: 225370,
      dataSent: 2394606,
      dataReceived: 19455482,
      callsIn: 725073,
      callsInDuration: 1508827,
      callsOut: 591855,
      callsOutDuration: 918256,
      smses: 100254,
    },
    {
      date: "2018-10-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8390035,
      accounts: 229034,
      dataSent: 2585057,
      dataReceived: 20669111,
      callsIn: 795268,
      callsInDuration: 1487996,
      callsOut: 676301,
      callsOutDuration: 953453,
      smses: 109882,
    },
    {
      date: "2018-10-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8268302,
      accounts: 228278,
      dataSent: 2615609,
      dataReceived: 20975335,
      callsIn: 724860,
      callsInDuration: 1299864,
      callsOut: 642337,
      callsOutDuration: 847843,
      smses: 105106,
    },
    {
      date: "2018-10-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7740084,
      accounts: 221161,
      dataSent: 2539008,
      dataReceived: 20255294,
      callsIn: 544839,
      callsInDuration: 1159580,
      callsOut: 489968,
      callsOutDuration: 736226,
      smses: 90877,
    },
    {
      date: "2018-10-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 7922102,
      accounts: 227402,
      dataSent: 2379724,
      dataReceived: 19217618,
      callsIn: 568601,
      callsInDuration: 1267001,
      callsOut: 492298,
      callsOutDuration: 789608,
      smses: 96562,
    },
    {
      date: "2018-10-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 8386886,
      accounts: 229996,
      dataSent: 2444826,
      dataReceived: 19206325,
      callsIn: 717003,
      callsInDuration: 1514369,
      callsOut: 593271,
      callsOutDuration: 930135,
      smses: 105942,
    },
    {
      date: "2018-10-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8347463,
      accounts: 229236,
      dataSent: 2480680,
      dataReceived: 19478887,
      callsIn: 707024,
      callsInDuration: 1520501,
      callsOut: 578568,
      callsOutDuration: 925045,
      smses: 102271,
    },
    {
      date: "2018-10-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8274416,
      accounts: 228610,
      dataSent: 2459038,
      dataReceived: 19624239,
      callsIn: 724115,
      callsInDuration: 1518717,
      callsOut: 590797,
      callsOutDuration: 922299,
      smses: 102042,
    },
    {
      date: "2018-10-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8473117,
      accounts: 231591,
      dataSent: 2554247,
      dataReceived: 19954355,
      callsIn: 801411,
      callsInDuration: 1520309,
      callsOut: 655463,
      callsOutDuration: 935129,
      smses: 108387,
    },
    {
      date: "2018-10-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8182670,
      accounts: 229481,
      dataSent: 2526562,
      dataReceived: 19751523,
      callsIn: 736117,
      callsInDuration: 1327494,
      callsOut: 630836,
      callsOutDuration: 852573,
      smses: 102027,
    },
    {
      date: "2018-10-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7705661,
      accounts: 223013,
      dataSent: 2369054,
      dataReceived: 18698929,
      callsIn: 544023,
      callsInDuration: 1194286,
      callsOut: 473437,
      callsOutDuration: 755628,
      smses: 86717,
    },
    {
      date: "2018-10-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8369832,
      accounts: 231785,
      dataSent: 2425198,
      dataReceived: 19134939,
      callsIn: 730139,
      callsInDuration: 1536850,
      callsOut: 600080,
      callsOutDuration: 939632,
      smses: 105948,
    },
    {
      date: "2018-10-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 8485970,
      accounts: 232553,
      dataSent: 2414083,
      dataReceived: 19254603,
      callsIn: 726857,
      callsInDuration: 1528738,
      callsOut: 588750,
      callsOutDuration: 931631,
      smses: 100227,
    },
    {
      date: "2018-10-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8484100,
      accounts: 233056,
      dataSent: 2301825,
      dataReceived: 17694971,
      callsIn: 724520,
      callsInDuration: 1495311,
      callsOut: 582908,
      callsOutDuration: 899532,
      smses: 99945,
    },
    {
      date: "2018-10-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8460568,
      accounts: 233991,
      dataSent: 2797157,
      dataReceived: 21574063,
      callsIn: 731047,
      callsInDuration: 1549765,
      callsOut: 593405,
      callsOutDuration: 944226,
      smses: 99421,
    },
    {
      date: "2018-10-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8509392,
      accounts: 235229,
      dataSent: 2678535,
      dataReceived: 20583249,
      callsIn: 801251,
      callsInDuration: 1517087,
      callsOut: 654275,
      callsOutDuration: 924393,
      smses: 108719,
    },
    {
      date: "2018-10-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8422366,
      accounts: 234756,
      dataSent: 2788460,
      dataReceived: 21117543,
      callsIn: 751934,
      callsInDuration: 1342402,
      callsOut: 641438,
      callsOutDuration: 856236,
      smses: 104001,
    },
    {
      date: "2018-10-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7759394,
      accounts: 227506,
      dataSent: 2505376,
      dataReceived: 19408496,
      callsIn: 564133,
      callsInDuration: 1215646,
      callsOut: 493139,
      callsOutDuration: 780321,
      smses: 87904,
    },
    {
      date: "2018-10-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8378306,
      accounts: 234433,
      dataSent: 2227485,
      dataReceived: 16992546,
      callsIn: 716750,
      callsInDuration: 1494467,
      callsOut: 596249,
      callsOutDuration: 909731,
      smses: 109895,
    },
    {
      date: "2018-10-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 7738039,
      accounts: 237130,
      dataSent: 2632570,
      dataReceived: 20055580,
      callsIn: 633600,
      callsInDuration: 1396055,
      callsOut: 526843,
      callsOutDuration: 848395,
      smses: 101162,
    },
    {
      date: "2018-10-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8538069,
      accounts: 236351,
      dataSent: 2572897,
      dataReceived: 19807916,
      callsIn: 721532,
      callsInDuration: 1522086,
      callsOut: 579984,
      callsOutDuration: 918141,
      smses: 101754,
    },
    {
      date: "2018-10-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8582996,
      accounts: 236768,
      dataSent: 2740770,
      dataReceived: 20778682,
      callsIn: 727513,
      callsInDuration: 1503753,
      callsOut: 586244,
      callsOutDuration: 901237,
      smses: 98425,
    },
    {
      date: "2018-10-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8790878,
      accounts: 239755,
      dataSent: 2848798,
      dataReceived: 21725138,
      callsIn: 816113,
      callsInDuration: 1522939,
      callsOut: 660438,
      callsOutDuration: 937959,
      smses: 107365,
    },
    {
      date: "2018-10-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8626780,
      accounts: 238572,
      dataSent: 2904411,
      dataReceived: 21661846,
      callsIn: 769482,
      callsInDuration: 1366080,
      callsOut: 648242,
      callsOutDuration: 878846,
      smses: 106059,
    },
    {
      date: "2018-10-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7922650,
      accounts: 230361,
      dataSent: 2688727,
      dataReceived: 20656655,
      callsIn: 554068,
      callsInDuration: 1231322,
      callsOut: 476686,
      callsOutDuration: 787734,
      smses: 87096,
    },
    {
      date: "2018-10-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8548292,
      accounts: 239512,
      dataSent: 2668236,
      dataReceived: 20039979,
      callsIn: 732392,
      callsInDuration: 1552057,
      callsOut: 589218,
      callsOutDuration: 947271,
      smses: 101363,
    },
    {
      date: "2018-10-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 8781611,
      accounts: 240029,
      dataSent: 2723671,
      dataReceived: 20637260,
      callsIn: 758137,
      callsInDuration: 1558312,
      callsOut: 613850,
      callsOutDuration: 964647,
      smses: 103838,
    },
    {
      date: "2018-10-31",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9147774,
      accounts: 244407,
      dataSent: 2914260,
      dataReceived: 21648207,
      callsIn: 891153,
      callsInDuration: 1570169,
      callsOut: 732453,
      callsOutDuration: 994357,
      smses: 114600,
    },
    {
      date: "2018-11-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8593105,
      accounts: 238015,
      dataSent: 2763114,
      dataReceived: 20933084,
      callsIn: 723896,
      callsInDuration: 1387811,
      callsOut: 614752,
      callsOutDuration: 905993,
      smses: 103455,
    },
    {
      date: "2018-11-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8608706,
      accounts: 236661,
      dataSent: 2759981,
      dataReceived: 20253680,
      callsIn: 659124,
      callsInDuration: 1221439,
      callsOut: 574531,
      callsOutDuration: 807214,
      smses: 106046,
    },
    {
      date: "2018-11-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8422769,
      accounts: 235131,
      dataSent: 2881575,
      dataReceived: 21004982,
      callsIn: 643421,
      callsInDuration: 1211444,
      callsOut: 564300,
      callsOutDuration: 813131,
      smses: 101180,
    },
    {
      date: "2018-11-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8013698,
      accounts: 230893,
      dataSent: 2698880,
      dataReceived: 20283196,
      callsIn: 528817,
      callsInDuration: 1184027,
      callsOut: 462619,
      callsOutDuration: 771516,
      smses: 91174,
    },
    {
      date: "2018-11-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8756671,
      accounts: 242770,
      dataSent: 2753757,
      dataReceived: 20382434,
      callsIn: 750413,
      callsInDuration: 1564670,
      callsOut: 620932,
      callsOutDuration: 982606,
      smses: 105613,
    },
    {
      date: "2018-11-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 8978913,
      accounts: 244937,
      dataSent: 2889568,
      dataReceived: 21385906,
      callsIn: 755841,
      callsInDuration: 1578068,
      callsOut: 625303,
      callsOutDuration: 986050,
      smses: 107596,
    },
    {
      date: "2018-11-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8969434,
      accounts: 244500,
      dataSent: 2864680,
      dataReceived: 21195945,
      callsIn: 745576,
      callsInDuration: 1588781,
      callsOut: 619062,
      callsOutDuration: 987082,
      smses: 102606,
    },
    {
      date: "2018-11-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8973286,
      accounts: 244339,
      dataSent: 2854095,
      dataReceived: 21366112,
      callsIn: 756333,
      callsInDuration: 1600945,
      callsOut: 617548,
      callsOutDuration: 984482,
      smses: 102755,
    },
    {
      date: "2018-11-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9277130,
      accounts: 248266,
      dataSent: 2657324,
      dataReceived: 19926998,
      callsIn: 842394,
      callsInDuration: 1585498,
      callsOut: 693064,
      callsOutDuration: 1017386,
      smses: 112045,
    },
    {
      date: "2018-11-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8954573,
      accounts: 245797,
      dataSent: 2532234,
      dataReceived: 19041151,
      callsIn: 774631,
      callsInDuration: 1409192,
      callsOut: 660147,
      callsOutDuration: 931231,
      smses: 108402,
    },
    {
      date: "2018-11-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8216316,
      accounts: 236585,
      dataSent: 2241295,
      dataReceived: 17247322,
      callsIn: 563584,
      callsInDuration: 1268632,
      callsOut: 490868,
      callsOutDuration: 826285,
      smses: 90932,
    },
    {
      date: "2018-11-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8883224,
      accounts: 246721,
      dataSent: 2229794,
      dataReceived: 16975763,
      callsIn: 765957,
      callsInDuration: 1603965,
      callsOut: 628382,
      callsOutDuration: 1007302,
      smses: 105230,
    },
    {
      date: "2018-11-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9172615,
      accounts: 247537,
      dataSent: 2227891,
      dataReceived: 16997430,
      callsIn: 748572,
      callsInDuration: 1614728,
      callsOut: 604874,
      callsOutDuration: 1001080,
      smses: 105814,
    },
    {
      date: "2018-11-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8842687,
      accounts: 247011,
      dataSent: 2243627,
      dataReceived: 16762973,
      callsIn: 754203,
      callsInDuration: 1626154,
      callsOut: 606178,
      callsOutDuration: 998266,
      smses: 102995,
    },
    {
      date: "2018-11-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9121957,
      accounts: 246695,
      dataSent: 2147746,
      dataReceived: 16829947,
      callsIn: 774459,
      callsInDuration: 1623949,
      callsOut: 627419,
      callsOutDuration: 1018317,
      smses: 104311,
    },
    {
      date: "2018-11-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9384571,
      accounts: 251107,
      dataSent: 2451537,
      dataReceived: 18573841,
      callsIn: 872625,
      callsInDuration: 1632540,
      callsOut: 710608,
      callsOutDuration: 1035945,
      smses: 112853,
    },
    {
      date: "2018-11-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8936660,
      accounts: 247094,
      dataSent: 2423250,
      dataReceived: 17912435,
      callsIn: 809060,
      callsInDuration: 1434277,
      callsOut: 682616,
      callsOutDuration: 940680,
      smses: 108428,
    },
    {
      date: "2018-11-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8349763,
      accounts: 239934,
      dataSent: 2225915,
      dataReceived: 16934805,
      callsIn: 595870,
      callsInDuration: 1310864,
      callsOut: 514106,
      callsOutDuration: 858747,
      smses: 92803,
    },
    {
      date: "2018-11-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8836591,
      accounts: 247603,
      dataSent: 2145006,
      dataReceived: 16127911,
      callsIn: 773885,
      callsInDuration: 1624947,
      callsOut: 627482,
      callsOutDuration: 1004533,
      smses: 109352,
    },
    {
      date: "2018-11-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 8916273,
      accounts: 249291,
      dataSent: 2192466,
      dataReceived: 16600334,
      callsIn: 762019,
      callsInDuration: 1597428,
      callsOut: 616650,
      callsOutDuration: 983299,
      smses: 103686,
    },
    {
      date: "2018-11-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9079313,
      accounts: 250285,
      dataSent: 2310580,
      dataReceived: 17378038,
      callsIn: 764998,
      callsInDuration: 1613231,
      callsOut: 618482,
      callsOutDuration: 995937,
      smses: 103303,
    },
    {
      date: "2018-11-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9145576,
      accounts: 250336,
      dataSent: 2276836,
      dataReceived: 17046285,
      callsIn: 771905,
      callsInDuration: 1601898,
      callsOut: 629598,
      callsOutDuration: 998219,
      smses: 101912,
    },
    {
      date: "2018-11-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9359295,
      accounts: 253519,
      dataSent: 2394444,
      dataReceived: 17852817,
      callsIn: 874563,
      callsInDuration: 1610320,
      callsOut: 720723,
      callsOutDuration: 1020338,
      smses: 110600,
    },
    {
      date: "2018-11-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9026634,
      accounts: 249446,
      dataSent: 2362881,
      dataReceived: 17462648,
      callsIn: 798657,
      callsInDuration: 1429671,
      callsOut: 667407,
      callsOutDuration: 926904,
      smses: 103220,
    },
    {
      date: "2018-11-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8312478,
      accounts: 241885,
      dataSent: 2065665,
      dataReceived: 15833592,
      callsIn: 587808,
      callsInDuration: 1289540,
      callsOut: 500367,
      callsOutDuration: 834361,
      smses: 87321,
    },
    {
      date: "2018-11-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9153967,
      accounts: 252065,
      dataSent: 2333357,
      dataReceived: 17488759,
      callsIn: 756961,
      callsInDuration: 1609387,
      callsOut: 606061,
      callsOutDuration: 987382,
      smses: 102648,
    },
    {
      date: "2018-11-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9041117,
      accounts: 250576,
      dataSent: 2086563,
      dataReceived: 15956988,
      callsIn: 752940,
      callsInDuration: 1596517,
      callsOut: 596590,
      callsOutDuration: 976229,
      smses: 101795,
    },
    {
      date: "2018-11-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9255867,
      accounts: 252861,
      dataSent: 2364618,
      dataReceived: 17865626,
      callsIn: 758476,
      callsInDuration: 1612338,
      callsOut: 600047,
      callsOutDuration: 989898,
      smses: 100638,
    },
    {
      date: "2018-11-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9155104,
      accounts: 251764,
      dataSent: 2388055,
      dataReceived: 17139200,
      callsIn: 776391,
      callsInDuration: 1626220,
      callsOut: 615294,
      callsOutDuration: 1004041,
      smses: 102001,
    },
    {
      date: "2018-11-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9336248,
      accounts: 255076,
      dataSent: 2349951,
      dataReceived: 17531282,
      callsIn: 888756,
      callsInDuration: 1638930,
      callsOut: 721101,
      callsOutDuration: 1042803,
      smses: 112878,
    },
    {
      date: "2018-12-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9358084,
      accounts: 254142,
      dataSent: 2741995,
      dataReceived: 20028089,
      callsIn: 829174,
      callsInDuration: 1455249,
      callsOut: 702368,
      callsOutDuration: 960402,
      smses: 108216,
    },
    {
      date: "2018-12-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8662513,
      accounts: 245268,
      dataSent: 2528033,
      dataReceived: 18979581,
      callsIn: 613177,
      callsInDuration: 1321358,
      callsOut: 529853,
      callsOutDuration: 892827,
      smses: 89315,
    },
    {
      date: "2018-12-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9191037,
      accounts: 254584,
      dataSent: 2347068,
      dataReceived: 17655093,
      callsIn: 790954,
      callsInDuration: 1610187,
      callsOut: 645374,
      callsOutDuration: 1003449,
      smses: 104672,
    },
    {
      date: "2018-12-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6890999,
      accounts: 249961,
      dataSent: 1542144,
      dataReceived: 11621743,
      callsIn: 700437,
      callsInDuration: 1317937,
      callsOut: 563920,
      callsOutDuration: 817858,
      smses: 113951,
    },
    {
      date: "2018-12-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8224884,
      accounts: 258225,
      dataSent: 2274425,
      dataReceived: 17704654,
      callsIn: 804801,
      callsInDuration: 1639536,
      callsOut: 649110,
      callsOutDuration: 1031635,
      smses: 112780,
    },
    {
      date: "2018-12-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 6046679,
      accounts: 258046,
      dataSent: 1866538,
      dataReceived: 14016591,
      callsIn: 834063,
      callsInDuration: 1647624,
      callsOut: 680725,
      callsOutDuration: 1039065,
      smses: 118737,
    },
    {
      date: "2018-12-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9280726,
      accounts: 257691,
      dataSent: 2508449,
      dataReceived: 18786135,
      callsIn: 842070,
      callsInDuration: 1575086,
      callsOut: 708101,
      callsOutDuration: 1027609,
      smses: 124031,
    },
    {
      date: "2018-12-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 6802282,
      accounts: 255603,
      dataSent: 1620555,
      dataReceived: 12190136,
      callsIn: 809307,
      callsInDuration: 1449851,
      callsOut: 684662,
      callsOutDuration: 945090,
      smses: 119277,
    },
    {
      date: "2018-12-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 5181384,
      accounts: 248655,
      dataSent: 1747086,
      dataReceived: 12924107,
      callsIn: 614173,
      callsInDuration: 1320819,
      callsOut: 526827,
      callsOutDuration: 856516,
      smses: 103053,
    },
    {
      date: "2018-12-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9295113,
      accounts: 259481,
      dataSent: 2424961,
      dataReceived: 18102519,
      callsIn: 802545,
      callsInDuration: 1661792,
      callsOut: 648731,
      callsOutDuration: 1030803,
      smses: 118425,
    },
    {
      date: "2018-12-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 6914061,
      accounts: 258586,
      dataSent: 1549623,
      dataReceived: 11774305,
      callsIn: 792431,
      callsInDuration: 1672017,
      callsOut: 635478,
      callsOutDuration: 1042706,
      smses: 120504,
    },
    {
      date: "2018-12-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 5316333,
      accounts: 231996,
      dataSent: 1588357,
      dataReceived: 11975896,
      callsIn: 739915,
      callsInDuration: 1522907,
      callsOut: 584214,
      callsOutDuration: 933756,
      smses: 106944,
    },
    {
      date: "2018-12-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8228784,
      accounts: 242689,
      dataSent: 2177370,
      dataReceived: 16330420,
      callsIn: 822110,
      callsInDuration: 1681039,
      callsOut: 654256,
      callsOutDuration: 1040308,
      smses: 117679,
    },
    {
      date: "2018-12-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 8402153,
      accounts: 257578,
      dataSent: 2212557,
      dataReceived: 16703111,
      callsIn: 940177,
      callsInDuration: 1699352,
      callsOut: 765920,
      callsOutDuration: 1087286,
      smses: 129930,
    },
    {
      date: "2018-12-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 8137207,
      accounts: 252813,
      dataSent: 2220755,
      dataReceived: 16512118,
      callsIn: 901323,
      callsInDuration: 1521791,
      callsOut: 752131,
      callsOutDuration: 1010875,
      smses: 128013,
    },
    {
      date: "2018-12-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 7623097,
      accounts: 217513,
      dataSent: 2091120,
      dataReceived: 16025846,
      callsIn: 663638,
      callsInDuration: 1353327,
      callsOut: 568153,
      callsOutDuration: 898599,
      smses: 108861,
    },
    {
      date: "2018-12-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 8019920,
      accounts: 246914,
      dataSent: 2039395,
      dataReceived: 15126299,
      callsIn: 848571,
      callsInDuration: 1722035,
      callsOut: 681056,
      callsOutDuration: 1063862,
      smses: 122531,
    },
    {
      date: "2018-12-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 8221191,
      accounts: 247169,
      dataSent: 2053783,
      dataReceived: 15507949,
      callsIn: 852255,
      callsInDuration: 1716603,
      callsOut: 680716,
      callsOutDuration: 1066455,
      smses: 122326,
    },
    {
      date: "2018-12-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 8998157,
      accounts: 257872,
      dataSent: 2279504,
      dataReceived: 16801287,
      callsIn: 889628,
      callsInDuration: 1730266,
      callsOut: 704926,
      callsOutDuration: 1064614,
      smses: 125244,
    },
    {
      date: "2018-12-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9582103,
      accounts: 268432,
      dataSent: 2654157,
      dataReceived: 19460087,
      callsIn: 962267,
      callsInDuration: 1728595,
      callsOut: 785961,
      callsOutDuration: 1106426,
      smses: 132448,
    },
    {
      date: "2018-12-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10208231,
      accounts: 273479,
      dataSent: 2925872,
      dataReceived: 21205880,
      callsIn: 1066541,
      callsInDuration: 1741401,
      callsOut: 882553,
      callsOutDuration: 1131924,
      smses: 139257,
    },
    {
      date: "2018-12-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9845725,
      accounts: 269009,
      dataSent: 2681852,
      dataReceived: 19711323,
      callsIn: 951131,
      callsInDuration: 1545940,
      callsOut: 808145,
      callsOutDuration: 1033682,
      smses: 135082,
    },
    {
      date: "2018-12-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9409038,
      accounts: 263276,
      dataSent: 2582174,
      dataReceived: 19205941,
      callsIn: 756285,
      callsInDuration: 1387761,
      callsOut: 651404,
      callsOutDuration: 918864,
      smses: 118029,
    },
    {
      date: "2018-12-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9643330,
      accounts: 265756,
      dataSent: 2679033,
      dataReceived: 19355849,
      callsIn: 848547,
      callsInDuration: 1389147,
      callsOut: 727535,
      callsOutDuration: 950737,
      smses: 118946,
    },
    {
      date: "2018-12-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9223507,
      accounts: 258527,
      dataSent: 2687428,
      dataReceived: 19981621,
      callsIn: 584686,
      callsInDuration: 1344622,
      callsOut: 499873,
      callsOutDuration: 889744,
      smses: 113611,
    },
    {
      date: "2018-12-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9307034,
      accounts: 262673,
      dataSent: 2534540,
      dataReceived: 18717260,
      callsIn: 793936,
      callsInDuration: 1621966,
      callsOut: 624509,
      callsOutDuration: 995991,
      smses: 120160,
    },
    {
      date: "2018-12-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9511825,
      accounts: 264562,
      dataSent: 2592974,
      dataReceived: 18935887,
      callsIn: 804083,
      callsInDuration: 1647795,
      callsOut: 638070,
      callsOutDuration: 1028471,
      smses: 123470,
    },
    {
      date: "2018-12-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9812580,
      accounts: 268410,
      dataSent: 2668808,
      dataReceived: 19382154,
      callsIn: 895621,
      callsInDuration: 1665973,
      callsOut: 724179,
      callsOutDuration: 1049244,
      smses: 134230,
    },
    {
      date: "2018-12-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9690484,
      accounts: 267086,
      dataSent: 2608288,
      dataReceived: 19143801,
      callsIn: 844629,
      callsInDuration: 1507368,
      callsOut: 708053,
      callsOutDuration: 983662,
      smses: 130426,
    },
    {
      date: "2018-12-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9459414,
      accounts: 262496,
      dataSent: 2509783,
      dataReceived: 18694342,
      callsIn: 714064,
      callsInDuration: 1358031,
      callsOut: 612503,
      callsOutDuration: 897653,
      smses: 119732,
    },
    {
      date: "2018-12-31",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10190769,
      accounts: 272342,
      dataSent: 3108092,
      dataReceived: 20345170,
      callsIn: 872462,
      callsInDuration: 1310230,
      callsOut: 770165,
      callsOutDuration: 941210,
      smses: 128778,
    },
    {
      date: "2019-01-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9763915,
      accounts: 264461,
      dataSent: 3059850,
      dataReceived: 22020645,
      callsIn: 630450,
      callsInDuration: 1388140,
      callsOut: 547029,
      callsOutDuration: 945987,
      smses: 133380,
    },
    {
      date: "2019-01-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9514276,
      accounts: 268434,
      dataSent: 2567730,
      dataReceived: 18895739,
      callsIn: 727009,
      callsInDuration: 1595632,
      callsOut: 586542,
      callsOutDuration: 985915,
      smses: 123872,
    },
    {
      date: "2019-01-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9948710,
      accounts: 272241,
      dataSent: 2754371,
      dataReceived: 19957614,
      callsIn: 742391,
      callsInDuration: 1641698,
      callsOut: 598866,
      callsOutDuration: 1016454,
      smses: 125675,
    },
    {
      date: "2019-01-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9975707,
      accounts: 272881,
      dataSent: 2727806,
      dataReceived: 20131923,
      callsIn: 803345,
      callsInDuration: 1644617,
      callsOut: 658541,
      callsOutDuration: 1033494,
      smses: 134348,
    },
    {
      date: "2019-01-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9599901,
      accounts: 268084,
      dataSent: 2752811,
      dataReceived: 20112636,
      callsIn: 726293,
      callsInDuration: 1458979,
      callsOut: 621553,
      callsOutDuration: 958373,
      smses: 125658,
    },
    {
      date: "2019-01-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9124990,
      accounts: 261427,
      dataSent: 2668874,
      dataReceived: 19963906,
      callsIn: 570826,
      callsInDuration: 1364599,
      callsOut: 500699,
      callsOutDuration: 901102,
      smses: 112713,
    },
    {
      date: "2019-01-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9980869,
      accounts: 274938,
      dataSent: 2680657,
      dataReceived: 19834424,
      callsIn: 767764,
      callsInDuration: 1683815,
      callsOut: 643110,
      callsOutDuration: 1066019,
      smses: 132259,
    },
    {
      date: "2019-01-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10050718,
      accounts: 274695,
      dataSent: 2722643,
      dataReceived: 20158898,
      callsIn: 760450,
      callsInDuration: 1689361,
      callsOut: 623380,
      callsOutDuration: 1048364,
      smses: 127164,
    },
    {
      date: "2019-01-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10136123,
      accounts: 275183,
      dataSent: 2730832,
      dataReceived: 20890294,
      callsIn: 771684,
      callsInDuration: 1697789,
      callsOut: 624835,
      callsOutDuration: 1047905,
      smses: 125718,
    },
    {
      date: "2019-01-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10127990,
      accounts: 275409,
      dataSent: 2721190,
      dataReceived: 20947859,
      callsIn: 772132,
      callsInDuration: 1679682,
      callsOut: 627488,
      callsOutDuration: 1040516,
      smses: 125603,
    },
    {
      date: "2019-01-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10454593,
      accounts: 280499,
      dataSent: 2923044,
      dataReceived: 22263908,
      callsIn: 859540,
      callsInDuration: 1681006,
      callsOut: 708486,
      callsOutDuration: 1070771,
      smses: 133285,
    },
    {
      date: "2019-01-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9759852,
      accounts: 273456,
      dataSent: 2797022,
      dataReceived: 21360781,
      callsIn: 781354,
      callsInDuration: 1488873,
      callsOut: 658980,
      callsOutDuration: 976240,
      smses: 125201,
    },
    {
      date: "2019-01-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9208808,
      accounts: 265446,
      dataSent: 2625189,
      dataReceived: 20576605,
      callsIn: 585878,
      callsInDuration: 1351455,
      callsOut: 506167,
      callsOutDuration: 882643,
      smses: 110558,
    },
    {
      date: "2019-01-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10167500,
      accounts: 277148,
      dataSent: 2728704,
      dataReceived: 20881920,
      callsIn: 786573,
      callsInDuration: 1712705,
      callsOut: 637960,
      callsOutDuration: 1055749,
      smses: 130115,
    },
    {
      date: "2019-01-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9835594,
      accounts: 276623,
      dataSent: 2556997,
      dataReceived: 19658055,
      callsIn: 793564,
      callsInDuration: 1714302,
      callsOut: 637576,
      callsOutDuration: 1054328,
      smses: 127407,
    },
    {
      date: "2019-01-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10376978,
      accounts: 277208,
      dataSent: 2672167,
      dataReceived: 20518834,
      callsIn: 792189,
      callsInDuration: 1740960,
      callsOut: 633845,
      callsOutDuration: 1076388,
      smses: 125519,
    },
    {
      date: "2019-01-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10311891,
      accounts: 276854,
      dataSent: 2638743,
      dataReceived: 20625804,
      callsIn: 810827,
      callsInDuration: 1741538,
      callsOut: 646899,
      callsOutDuration: 1083898,
      smses: 125275,
    },
    {
      date: "2019-01-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10281540,
      accounts: 277539,
      dataSent: 2675004,
      dataReceived: 20765569,
      callsIn: 881138,
      callsInDuration: 1735512,
      callsOut: 712529,
      callsOutDuration: 1092371,
      smses: 132617,
    },
    {
      date: "2019-01-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9848948,
      accounts: 274527,
      dataSent: 2669184,
      dataReceived: 20453337,
      callsIn: 795963,
      callsInDuration: 1500453,
      callsOut: 673419,
      callsOutDuration: 985582,
      smses: 125931,
    },
    {
      date: "2019-01-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9352032,
      accounts: 267300,
      dataSent: 2467755,
      dataReceived: 19083515,
      callsIn: 591582,
      callsInDuration: 1323255,
      callsOut: 511750,
      callsOutDuration: 860649,
      smses: 110678,
    },
    {
      date: "2019-01-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9856993,
      accounts: 277433,
      dataSent: 2732943,
      dataReceived: 21191420,
      callsIn: 788350,
      callsInDuration: 1696691,
      callsOut: 648144,
      callsOutDuration: 1067610,
      smses: 126309,
    },
    {
      date: "2019-01-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9919351,
      accounts: 276847,
      dataSent: 2524777,
      dataReceived: 20138591,
      callsIn: 772000,
      callsInDuration: 1665938,
      callsOut: 623243,
      callsOutDuration: 1023729,
      smses: 124869,
    },
    {
      date: "2019-01-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10233220,
      accounts: 276711,
      dataSent: 2345867,
      dataReceived: 19586573,
      callsIn: 738881,
      callsInDuration: 1619682,
      callsOut: 599428,
      callsOutDuration: 1011579,
      smses: 122924,
    },
    {
      date: "2019-01-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9140419,
      accounts: 273601,
      dataSent: 1939238,
      dataReceived: 14990046,
      callsIn: 772432,
      callsInDuration: 1688692,
      callsOut: 626954,
      callsOutDuration: 1058525,
      smses: 121814,
    },
    {
      date: "2019-01-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9457167,
      accounts: 281398,
      dataSent: 2407940,
      dataReceived: 18444264,
      callsIn: 794598,
      callsInDuration: 1577706,
      callsOut: 652083,
      callsOutDuration: 1011831,
      smses: 130401,
    },
    {
      date: "2019-01-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9662566,
      accounts: 274966,
      dataSent: 2487592,
      dataReceived: 18495925,
      callsIn: 782076,
      callsInDuration: 1458941,
      callsOut: 660093,
      callsOutDuration: 956369,
      smses: 126425,
    },
    {
      date: "2019-01-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9034081,
      accounts: 266221,
      dataSent: 2182942,
      dataReceived: 16716024,
      callsIn: 579406,
      callsInDuration: 1344054,
      callsOut: 502772,
      callsOutDuration: 882525,
      smses: 109779,
    },
    {
      date: "2019-01-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9845212,
      accounts: 279973,
      dataSent: 2370393,
      dataReceived: 18181225,
      callsIn: 774764,
      callsInDuration: 1694354,
      callsOut: 625154,
      callsOutDuration: 1055822,
      smses: 139397,
    },
    {
      date: "2019-01-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9798565,
      accounts: 276195,
      dataSent: 2332559,
      dataReceived: 17584341,
      callsIn: 777792,
      callsInDuration: 1697730,
      callsOut: 612749,
      callsOutDuration: 1037643,
      smses: 122764,
    },
    {
      date: "2019-01-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10015682,
      accounts: 276129,
      dataSent: 2340744,
      dataReceived: 17984435,
      callsIn: 782133,
      callsInDuration: 1710910,
      callsOut: 616328,
      callsOutDuration: 1059489,
      smses: 155297,
    },
    {
      date: "2019-01-31",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9792352,
      accounts: 274583,
      dataSent: 2214518,
      dataReceived: 16879330,
      callsIn: 616106,
      callsInDuration: 1306144,
      callsOut: 482592,
      callsOutDuration: 793682,
      smses: 103461,
    },
    {
      date: "2019-02-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7323370,
      accounts: 270928,
      dataSent: 2054109,
      dataReceived: 15780098,
      callsIn: 911264,
      callsInDuration: 1725003,
      callsOut: 742428,
      callsOutDuration: 1106847,
      smses: 134795,
    },
    {
      date: "2019-02-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9667847,
      accounts: 274611,
      dataSent: 2511518,
      dataReceived: 19216299,
      callsIn: 820802,
      callsInDuration: 1512455,
      callsOut: 688942,
      callsOutDuration: 994891,
      smses: 128357,
    },
    {
      date: "2019-02-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8985507,
      accounts: 264945,
      dataSent: 2292526,
      dataReceived: 17607549,
      callsIn: 600753,
      callsInDuration: 1360761,
      callsOut: 514992,
      callsOutDuration: 892051,
      smses: 110901,
    },
    {
      date: "2019-02-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9848101,
      accounts: 276377,
      dataSent: 2362518,
      dataReceived: 18375586,
      callsIn: 849457,
      callsInDuration: 1791679,
      callsOut: 688552,
      callsOutDuration: 1135000,
      smses: 133572,
    },
    {
      date: "2019-02-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9808261,
      accounts: 275843,
      dataSent: 2373533,
      dataReceived: 18584564,
      callsIn: 811789,
      callsInDuration: 1747983,
      callsOut: 648024,
      callsOutDuration: 1084397,
      smses: 128885,
    },
    {
      date: "2019-02-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9831101,
      accounts: 275858,
      dataSent: 2427291,
      dataReceived: 18883894,
      callsIn: 800483,
      callsInDuration: 1754019,
      callsOut: 639713,
      callsOutDuration: 1099395,
      smses: 127320,
    },
    {
      date: "2019-02-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9717639,
      accounts: 274820,
      dataSent: 2424115,
      dataReceived: 18613258,
      callsIn: 798902,
      callsInDuration: 1726947,
      callsOut: 638233,
      callsOutDuration: 1077236,
      smses: 124566,
    },
    {
      date: "2019-02-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10053775,
      accounts: 277601,
      dataSent: 2541780,
      dataReceived: 19234122,
      callsIn: 896191,
      callsInDuration: 1750753,
      callsOut: 713647,
      callsOutDuration: 1100768,
      smses: 135236,
    },
    {
      date: "2019-02-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9579472,
      accounts: 273033,
      dataSent: 2503382,
      dataReceived: 18838718,
      callsIn: 797097,
      callsInDuration: 1524064,
      callsOut: 660952,
      callsOutDuration: 1004437,
      smses: 127990,
    },
    {
      date: "2019-02-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8979024,
      accounts: 264393,
      dataSent: 2335452,
      dataReceived: 17604638,
      callsIn: 594508,
      callsInDuration: 1376259,
      callsOut: 502966,
      callsOutDuration: 895717,
      smses: 109576,
    },
    {
      date: "2019-02-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9753710,
      accounts: 276216,
      dataSent: 2313128,
      dataReceived: 17459409,
      callsIn: 815284,
      callsInDuration: 1756523,
      callsOut: 649568,
      callsOutDuration: 1098739,
      smses: 127866,
    },
    {
      date: "2019-02-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9912608,
      accounts: 275845,
      dataSent: 2474953,
      dataReceived: 18409205,
      callsIn: 807386,
      callsInDuration: 1747518,
      callsOut: 635504,
      callsOutDuration: 1073469,
      smses: 124469,
    },
    {
      date: "2019-02-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9877727,
      accounts: 277083,
      dataSent: 2416815,
      dataReceived: 18037433,
      callsIn: 823388,
      callsInDuration: 1774842,
      callsOut: 639387,
      callsOutDuration: 1090734,
      smses: 126972,
    },
    {
      date: "2019-02-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9680463,
      accounts: 277780,
      dataSent: 2267772,
      dataReceived: 16510142,
      callsIn: 887928,
      callsInDuration: 1757800,
      callsOut: 697551,
      callsOutDuration: 1094809,
      smses: 140197,
    },
    {
      date: "2019-02-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9849777,
      accounts: 283104,
      dataSent: 2601184,
      dataReceived: 19515739,
      callsIn: 926029,
      callsInDuration: 1806435,
      callsOut: 725039,
      callsOutDuration: 1126144,
      smses: 136225,
    },
    {
      date: "2019-02-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9903780,
      accounts: 278320,
      dataSent: 2625268,
      dataReceived: 19030742,
      callsIn: 825318,
      callsInDuration: 1525504,
      callsOut: 690240,
      callsOutDuration: 1025947,
      smses: 131257,
    },
    {
      date: "2019-02-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9115512,
      accounts: 268834,
      dataSent: 2393166,
      dataReceived: 17654306,
      callsIn: 602770,
      callsInDuration: 1340287,
      callsOut: 514194,
      callsOutDuration: 888991,
      smses: 111211,
    },
    {
      date: "2019-02-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9755080,
      accounts: 278063,
      dataSent: 2440372,
      dataReceived: 17852018,
      callsIn: 826270,
      callsInDuration: 1778467,
      callsOut: 654071,
      callsOutDuration: 1123354,
      smses: 129547,
    },
    {
      date: "2019-02-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9907710,
      accounts: 276602,
      dataSent: 2417138,
      dataReceived: 17893963,
      callsIn: 799891,
      callsInDuration: 1754425,
      callsOut: 626442,
      callsOutDuration: 1096638,
      smses: 126629,
    },
    {
      date: "2019-02-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9979067,
      accounts: 279367,
      dataSent: 2500260,
      dataReceived: 18652937,
      callsIn: 819437,
      callsInDuration: 1777837,
      callsOut: 647087,
      callsOutDuration: 1122049,
      smses: 125320,
    },
    {
      date: "2019-02-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9887595,
      accounts: 278274,
      dataSent: 2492394,
      dataReceived: 18586376,
      callsIn: 811958,
      callsInDuration: 1767633,
      callsOut: 645928,
      callsOutDuration: 1116062,
      smses: 124188,
    },
    {
      date: "2019-02-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10321404,
      accounts: 281416,
      dataSent: 2678785,
      dataReceived: 20612977,
      callsIn: 954118,
      callsInDuration: 1905460,
      callsOut: 772643,
      callsOutDuration: 1243266,
      smses: 136661,
    },
    {
      date: "2019-02-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9713232,
      accounts: 275994,
      dataSent: 2657003,
      dataReceived: 20436518,
      callsIn: 777603,
      callsInDuration: 1508476,
      callsOut: 646010,
      callsOutDuration: 1001836,
      smses: 123716,
    },
    {
      date: "2019-02-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9090335,
      accounts: 268206,
      dataSent: 2417567,
      dataReceived: 18149744,
      callsIn: 587440,
      callsInDuration: 1371062,
      callsOut: 497858,
      callsOutDuration: 908208,
      smses: 108197,
    },
    {
      date: "2019-02-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9911721,
      accounts: 281189,
      dataSent: 2510475,
      dataReceived: 18508835,
      callsIn: 839839,
      callsInDuration: 1769245,
      callsOut: 667595,
      callsOutDuration: 1115556,
      smses: 129017,
    },
    {
      date: "2019-02-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10245874,
      accounts: 281385,
      dataSent: 2546046,
      dataReceived: 18691623,
      callsIn: 833950,
      callsInDuration: 1764769,
      callsOut: 655280,
      callsOutDuration: 1111641,
      smses: 149926,
    },
    {
      date: "2019-02-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10433594,
      accounts: 283981,
      dataSent: 2679007,
      dataReceived: 19617582,
      callsIn: 854990,
      callsInDuration: 1807320,
      callsOut: 671808,
      callsOutDuration: 1138578,
      smses: 127784,
    },
    {
      date: "2019-02-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10477002,
      accounts: 285522,
      dataSent: 2662109,
      dataReceived: 19454630,
      callsIn: 887897,
      callsInDuration: 1829672,
      callsOut: 701812,
      callsOutDuration: 1155790,
      smses: 149751,
    },
    {
      date: "2019-03-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10800481,
      accounts: 289364,
      dataSent: 2840204,
      dataReceived: 20400166,
      callsIn: 996495,
      callsInDuration: 1849438,
      callsOut: 795961,
      callsOutDuration: 1198838,
      smses: 140683,
    },
    {
      date: "2019-03-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10484231,
      accounts: 286933,
      dataSent: 2850584,
      dataReceived: 20137055,
      callsIn: 901026,
      callsInDuration: 1585311,
      callsOut: 753212,
      callsOutDuration: 1068094,
      smses: 138278,
    },
    {
      date: "2019-03-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9803403,
      accounts: 278076,
      dataSent: 2847947,
      dataReceived: 19885210,
      callsIn: 679150,
      callsInDuration: 1311230,
      callsOut: 578962,
      callsOutDuration: 907073,
      smses: 125832,
    },
    {
      date: "2019-03-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9420990,
      accounts: 272287,
      dataSent: 2717160,
      dataReceived: 19214538,
      callsIn: 621331,
      callsInDuration: 1253553,
      callsOut: 527542,
      callsOutDuration: 867485,
      smses: 123145,
    },
    {
      date: "2019-03-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9014114,
      accounts: 266377,
      dataSent: 2635165,
      dataReceived: 19043368,
      callsIn: 544268,
      callsInDuration: 1277467,
      callsOut: 452336,
      callsOutDuration: 846108,
      smses: 109970,
    },
    {
      date: "2019-03-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10407960,
      accounts: 286305,
      dataSent: 2701933,
      dataReceived: 19685214,
      callsIn: 822335,
      callsInDuration: 1755200,
      callsOut: 658493,
      callsOutDuration: 1129220,
      smses: 132002,
    },
    {
      date: "2019-03-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10421864,
      accounts: 286783,
      dataSent: 2792266,
      dataReceived: 20050250,
      callsIn: 825729,
      callsInDuration: 1829966,
      callsOut: 660850,
      callsOutDuration: 1163499,
      smses: 142085,
    },
    {
      date: "2019-03-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10802996,
      accounts: 290152,
      dataSent: 2931524,
      dataReceived: 21244878,
      callsIn: 915267,
      callsInDuration: 1823796,
      callsOut: 738848,
      callsOutDuration: 1191363,
      smses: 140828,
    },
    {
      date: "2019-03-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10247709,
      accounts: 285816,
      dataSent: 2823956,
      dataReceived: 20377614,
      callsIn: 803344,
      callsInDuration: 1595410,
      callsOut: 678050,
      callsOutDuration: 1066255,
      smses: 129101,
    },
    {
      date: "2019-03-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9471624,
      accounts: 276956,
      dataSent: 2526118,
      dataReceived: 18346824,
      callsIn: 597051,
      callsInDuration: 1393692,
      callsOut: 515015,
      callsOutDuration: 934965,
      smses: 109997,
    },
    {
      date: "2019-03-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10431873,
      accounts: 288353,
      dataSent: 2809701,
      dataReceived: 20313784,
      callsIn: 843021,
      callsInDuration: 1836890,
      callsOut: 676201,
      callsOutDuration: 1166332,
      smses: 128498,
    },
    {
      date: "2019-03-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10518766,
      accounts: 288678,
      dataSent: 2596671,
      dataReceived: 19003788,
      callsIn: 834083,
      callsInDuration: 1817446,
      callsOut: 656184,
      callsOutDuration: 1149966,
      smses: 125693,
    },
    {
      date: "2019-03-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11482029,
      accounts: 295235,
      dataSent: 4302226,
      dataReceived: 19415165,
      callsIn: 864623,
      callsInDuration: 1954289,
      callsOut: 674214,
      callsOutDuration: 1233053,
      smses: 136121,
    },
    {
      date: "2019-03-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10485226,
      accounts: 288255,
      dataSent: 2721212,
      dataReceived: 19886438,
      callsIn: 920680,
      callsInDuration: 1855202,
      callsOut: 683038,
      callsOutDuration: 1153615,
      smses: 130856,
    },
    {
      date: "2019-03-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10825415,
      accounts: 293487,
      dataSent: 2958470,
      dataReceived: 21578199,
      callsIn: 1026733,
      callsInDuration: 1854525,
      callsOut: 772678,
      callsOutDuration: 1182194,
      smses: 139327,
    },
    {
      date: "2019-03-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10561554,
      accounts: 292050,
      dataSent: 2935052,
      dataReceived: 20936405,
      callsIn: 962527,
      callsInDuration: 1621970,
      callsOut: 751632,
      callsOutDuration: 1070938,
      smses: 135007,
    },
    {
      date: "2019-03-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9986665,
      accounts: 284116,
      dataSent: 2812762,
      dataReceived: 20307476,
      callsIn: 730057,
      callsInDuration: 1441215,
      callsOut: 583411,
      callsOutDuration: 966395,
      smses: 117999,
    },
    {
      date: "2019-03-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10550928,
      accounts: 291458,
      dataSent: 2788057,
      dataReceived: 20189837,
      callsIn: 939181,
      callsInDuration: 1866471,
      callsOut: 707256,
      callsOutDuration: 1180403,
      smses: 133370,
    },
    {
      date: "2019-03-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10537722,
      accounts: 291355,
      dataSent: 2669528,
      dataReceived: 19390598,
      callsIn: 928144,
      callsInDuration: 1854347,
      callsOut: 695478,
      callsOutDuration: 1170804,
      smses: 144201,
    },
    {
      date: "2019-03-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11022299,
      accounts: 294879,
      dataSent: 3011113,
      dataReceived: 22182103,
      callsIn: 954431,
      callsInDuration: 1872726,
      callsOut: 724194,
      callsOutDuration: 1175231,
      smses: 135217,
    },
    {
      date: "2019-03-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10635444,
      accounts: 292720,
      dataSent: 2937035,
      dataReceived: 21318435,
      callsIn: 953259,
      callsInDuration: 1821028,
      callsOut: 723796,
      callsOutDuration: 1150198,
      smses: 132997,
    },
    {
      date: "2019-03-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10762006,
      accounts: 294120,
      dataSent: 2874163,
      dataReceived: 21801769,
      callsIn: 975796,
      callsInDuration: 1848297,
      callsOut: 742229,
      callsOutDuration: 1187678,
      smses: 150070,
    },
    {
      date: "2019-03-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10352208,
      accounts: 291014,
      dataSent: 2712545,
      dataReceived: 20150698,
      callsIn: 890044,
      callsInDuration: 1615231,
      callsOut: 719427,
      callsOutDuration: 1076856,
      smses: 126910,
    },
    {
      date: "2019-03-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10719537,
      accounts: 297309,
      dataSent: 2679701,
      dataReceived: 19645548,
      callsIn: 951761,
      callsInDuration: 1513067,
      callsOut: 823585,
      callsOutDuration: 1067521,
      smses: 138768,
    },
    {
      date: "2019-03-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10234533,
      accounts: 291489,
      dataSent: 2347156,
      dataReceived: 17337098,
      callsIn: 918876,
      callsInDuration: 1854411,
      callsOut: 700952,
      callsOutDuration: 1167541,
      smses: 127366,
    },
    {
      date: "2019-03-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10665687,
      accounts: 295653,
      dataSent: 2808407,
      dataReceived: 20951449,
      callsIn: 900619,
      callsInDuration: 1829278,
      callsOut: 683923,
      callsOutDuration: 1149920,
      smses: 127327,
    },
    {
      date: "2019-03-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10623870,
      accounts: 294726,
      dataSent: 2751002,
      dataReceived: 20593534,
      callsIn: 898228,
      callsInDuration: 1891481,
      callsOut: 674484,
      callsOutDuration: 1176300,
      smses: 124801,
    },
    {
      date: "2019-03-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10611749,
      accounts: 294214,
      dataSent: 2763424,
      dataReceived: 20406915,
      callsIn: 895968,
      callsInDuration: 1869136,
      callsOut: 677290,
      callsOutDuration: 1181395,
      smses: 137112,
    },
    {
      date: "2019-03-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10890466,
      accounts: 297104,
      dataSent: 2828964,
      dataReceived: 21183799,
      callsIn: 1011332,
      callsInDuration: 1893931,
      callsOut: 776650,
      callsOutDuration: 1219238,
      smses: 137663,
    },
    {
      date: "2019-03-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10457520,
      accounts: 294576,
      dataSent: 2877699,
      dataReceived: 20915767,
      callsIn: 958010,
      callsInDuration: 1649040,
      callsOut: 764671,
      callsOutDuration: 1101180,
      smses: 136332,
    },
    {
      date: "2019-03-31",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9721514,
      accounts: 286208,
      dataSent: 2633561,
      dataReceived: 19599075,
      callsIn: 731977,
      callsInDuration: 1511872,
      callsOut: 592603,
      callsOutDuration: 1006208,
      smses: 112109,
    },
    {
      date: "2019-04-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10289503,
      accounts: 294405,
      dataSent: 2641137,
      dataReceived: 19433059,
      callsIn: 909231,
      callsInDuration: 1882582,
      callsOut: 710973,
      callsOutDuration: 1223204,
      smses: 127884,
    },
    {
      date: "2019-04-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10388711,
      accounts: 294863,
      dataSent: 2762777,
      dataReceived: 20471152,
      callsIn: 870802,
      callsInDuration: 1902513,
      callsOut: 686806,
      callsOutDuration: 1218636,
      smses: 127667,
    },
    {
      date: "2019-04-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10464249,
      accounts: 293069,
      dataSent: 2647175,
      dataReceived: 19633987,
      callsIn: 858795,
      callsInDuration: 1886495,
      callsOut: 674172,
      callsOutDuration: 1205425,
      smses: 125754,
    },
    {
      date: "2019-04-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10517160,
      accounts: 293163,
      dataSent: 2699586,
      dataReceived: 19949911,
      callsIn: 863953,
      callsInDuration: 1879489,
      callsOut: 682447,
      callsOutDuration: 1205575,
      smses: 124965,
    },
    {
      date: "2019-04-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11078929,
      accounts: 298373,
      dataSent: 2913815,
      dataReceived: 21796530,
      callsIn: 954554,
      callsInDuration: 1889782,
      callsOut: 763676,
      callsOutDuration: 1229374,
      smses: 156610,
    },
    {
      date: "2019-04-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10520261,
      accounts: 295194,
      dataSent: 2905841,
      dataReceived: 21217981,
      callsIn: 860559,
      callsInDuration: 1629017,
      callsOut: 725836,
      callsOutDuration: 1106846,
      smses: 131585,
    },
    {
      date: "2019-04-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9805606,
      accounts: 286806,
      dataSent: 2597567,
      dataReceived: 18972201,
      callsIn: 643094,
      callsInDuration: 1434996,
      callsOut: 566020,
      callsOutDuration: 988340,
      smses: 111555,
    },
    {
      date: "2019-04-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10750297,
      accounts: 298391,
      dataSent: 2803073,
      dataReceived: 20258436,
      callsIn: 866539,
      callsInDuration: 1875404,
      callsOut: 704813,
      callsOutDuration: 1226130,
      smses: 130461,
    },
    {
      date: "2019-04-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10645775,
      accounts: 296074,
      dataSent: 2459971,
      dataReceived: 18102040,
      callsIn: 862106,
      callsInDuration: 1871065,
      callsOut: 689849,
      callsOutDuration: 1206929,
      smses: 126867,
    },
    {
      date: "2019-04-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10757212,
      accounts: 300159,
      dataSent: 2958492,
      dataReceived: 22082974,
      callsIn: 864001,
      callsInDuration: 1876478,
      callsOut: 689122,
      callsOutDuration: 1208970,
      smses: 127840,
    },
    {
      date: "2019-04-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10817574,
      accounts: 300178,
      dataSent: 2926659,
      dataReceived: 21949944,
      callsIn: 881033,
      callsInDuration: 1895351,
      callsOut: 700653,
      callsOutDuration: 1211391,
      smses: 126253,
    },
    {
      date: "2019-04-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11098236,
      accounts: 304209,
      dataSent: 3108510,
      dataReceived: 23325245,
      callsIn: 974804,
      callsInDuration: 1912206,
      callsOut: 773168,
      callsOutDuration: 1238790,
      smses: 144186,
    },
    {
      date: "2019-04-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10920571,
      accounts: 303757,
      dataSent: 3125529,
      dataReceived: 23431362,
      callsIn: 887452,
      callsInDuration: 1672711,
      callsOut: 735926,
      callsOutDuration: 1123605,
      smses: 131995,
    },
    {
      date: "2019-04-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10246343,
      accounts: 297328,
      dataSent: 2828090,
      dataReceived: 21338811,
      callsIn: 669214,
      callsInDuration: 1490691,
      callsOut: 569176,
      callsOutDuration: 1002626,
      smses: 121879,
    },
    {
      date: "2019-04-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10881157,
      accounts: 305769,
      dataSent: 2789071,
      dataReceived: 20565097,
      callsIn: 913515,
      callsInDuration: 1939982,
      callsOut: 732946,
      callsOutDuration: 1250613,
      smses: 131553,
    },
    {
      date: "2019-04-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11126860,
      accounts: 307127,
      dataSent: 2826560,
      dataReceived: 20842250,
      callsIn: 916445,
      callsInDuration: 1970506,
      callsOut: 721391,
      callsOutDuration: 1256117,
      smses: 131584,
    },
    {
      date: "2019-04-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11196169,
      accounts: 307557,
      dataSent: 3077245,
      dataReceived: 22980240,
      callsIn: 924809,
      callsInDuration: 1972465,
      callsOut: 719918,
      callsOutDuration: 1245660,
      smses: 161962,
    },
    {
      date: "2019-04-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11289761,
      accounts: 310138,
      dataSent: 3058693,
      dataReceived: 22641673,
      callsIn: 994257,
      callsInDuration: 1911383,
      callsOut: 782849,
      callsOutDuration: 1237006,
      smses: 138738,
    },
    {
      date: "2019-04-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10260218,
      accounts: 297004,
      dataSent: 2869751,
      dataReceived: 21042151,
      callsIn: 676920,
      callsInDuration: 1466705,
      callsOut: 559809,
      callsOutDuration: 993245,
      smses: 82440,
    },
    {
      date: "2019-04-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10646756,
      accounts: 301787,
      dataSent: 3006376,
      dataReceived: 21439911,
      callsIn: 766851,
      callsInDuration: 1475435,
      callsOut: 643856,
      callsOutDuration: 1013125,
      smses: 123588,
    },
    {
      date: "2019-04-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10049890,
      accounts: 296809,
      dataSent: 2766876,
      dataReceived: 20360875,
      callsIn: 616172,
      callsInDuration: 1421051,
      callsOut: 532182,
      callsOutDuration: 981010,
      smses: 112746,
    },
    {
      date: "2019-04-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11110080,
      accounts: 311905,
      dataSent: 2845834,
      dataReceived: 21002427,
      callsIn: 889639,
      callsInDuration: 1909655,
      callsOut: 722463,
      callsOutDuration: 1259238,
      smses: 152207,
    },
    {
      date: "2019-04-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11265169,
      accounts: 312463,
      dataSent: 2880038,
      dataReceived: 21090398,
      callsIn: 890328,
      callsInDuration: 1916637,
      callsOut: 716003,
      callsOutDuration: 1246306,
      smses: 131048,
    },
    {
      date: "2019-04-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11665086,
      accounts: 313029,
      dataSent: 2812726,
      dataReceived: 20875921,
      callsIn: 842535,
      callsInDuration: 1865218,
      callsOut: 683714,
      callsOutDuration: 1193152,
      smses: 129743,
    },
    {
      date: "2019-04-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9811972,
      accounts: 321011,
      dataSent: 2863438,
      dataReceived: 21270008,
      callsIn: 900275,
      callsInDuration: 1877732,
      callsOut: 709555,
      callsOutDuration: 1193714,
      smses: 127215,
    },
    {
      date: "2019-04-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10162819,
      accounts: 328731,
      dataSent: 3713886,
      dataReceived: 28039037,
      callsIn: 981386,
      callsInDuration: 1909872,
      callsOut: 787092,
      callsOutDuration: 1246524,
      smses: 141058,
    },
    {
      date: "2019-04-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9567331,
      accounts: 321762,
      dataSent: 3537004,
      dataReceived: 26154789,
      callsIn: 886963,
      callsInDuration: 1699865,
      callsOut: 735032,
      callsOutDuration: 1138912,
      smses: 132838,
    },
    {
      date: "2019-04-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8676173,
      accounts: 312224,
      dataSent: 3098593,
      dataReceived: 23492002,
      callsIn: 666531,
      callsInDuration: 1519114,
      callsOut: 565691,
      callsOutDuration: 1035586,
      smses: 111980,
    },
    {
      date: "2019-04-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9742457,
      accounts: 325043,
      dataSent: 3232844,
      dataReceived: 23785249,
      callsIn: 897526,
      callsInDuration: 1943648,
      callsOut: 721344,
      callsOutDuration: 1257448,
      smses: 129848,
    },
    {
      date: "2019-04-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9651938,
      accounts: 327896,
      dataSent: 3302495,
      dataReceived: 26827782,
      callsIn: 918701,
      callsInDuration: 1962305,
      callsOut: 740759,
      callsOutDuration: 1279947,
      smses: 145562,
    },
    {
      date: "2019-05-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9100228,
      accounts: 317142,
      dataSent: 3086197,
      dataReceived: 24169442,
      callsIn: 801234,
      callsInDuration: 1801144,
      callsOut: 652325,
      callsOutDuration: 1168349,
      smses: 117775,
    },
    {
      date: "2019-05-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9984724,
      accounts: 329782,
      dataSent: 3766311,
      dataReceived: 27601489,
      callsIn: 969613,
      callsInDuration: 1989716,
      callsOut: 780498,
      callsOutDuration: 1286789,
      smses: 150389,
    },
    {
      date: "2019-05-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9220096,
      accounts: 318541,
      dataSent: 3411515,
      dataReceived: 25048684,
      callsIn: 803651,
      callsInDuration: 1652985,
      callsOut: 673928,
      callsOutDuration: 1122835,
      smses: 119493,
    },
    {
      date: "2019-05-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9500580,
      accounts: 321803,
      dataSent: 3588832,
      dataReceived: 25834090,
      callsIn: 821595,
      callsInDuration: 1581947,
      callsOut: 699677,
      callsOutDuration: 1095636,
      smses: 125094,
    },
    {
      date: "2019-05-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8696101,
      accounts: 313173,
      dataSent: 3319374,
      dataReceived: 23803077,
      callsIn: 649660,
      callsInDuration: 1449140,
      callsOut: 560944,
      callsOutDuration: 1010378,
      smses: 111554,
    },
    {
      date: "2019-05-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9847880,
      accounts: 329283,
      dataSent: 3391162,
      dataReceived: 24213385,
      callsIn: 920192,
      callsInDuration: 1950612,
      callsOut: 753672,
      callsOutDuration: 1281559,
      smses: 137972,
    },
    {
      date: "2019-05-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10032311,
      accounts: 331822,
      dataSent: 3299094,
      dataReceived: 24366191,
      callsIn: 893608,
      callsInDuration: 1915383,
      callsOut: 728988,
      callsOutDuration: 1243161,
      smses: 132092,
    },
    {
      date: "2019-05-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10141143,
      accounts: 334739,
      dataSent: 3781923,
      dataReceived: 28049061,
      callsIn: 906188,
      callsInDuration: 1978052,
      callsOut: 720876,
      callsOutDuration: 1260584,
      smses: 129249,
    },
    {
      date: "2019-05-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10046893,
      accounts: 331860,
      dataSent: 3805286,
      dataReceived: 27480331,
      callsIn: 912297,
      callsInDuration: 1952824,
      callsOut: 714190,
      callsOutDuration: 1200743,
      smses: 128261,
    },
    {
      date: "2019-05-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10350736,
      accounts: 339027,
      dataSent: 3966055,
      dataReceived: 28679745,
      callsIn: 1035574,
      callsInDuration: 1943021,
      callsOut: 818733,
      callsOutDuration: 1209029,
      smses: 175630,
    },
    {
      date: "2019-05-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9791032,
      accounts: 333253,
      dataSent: 3838362,
      dataReceived: 27416891,
      callsIn: 965550,
      callsInDuration: 1714422,
      callsOut: 791594,
      callsOutDuration: 1106617,
      smses: 133847,
    },
    {
      date: "2019-05-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9335283,
      accounts: 326982,
      dataSent: 3762418,
      dataReceived: 26833303,
      callsIn: 728160,
      callsInDuration: 1567429,
      callsOut: 630906,
      callsOutDuration: 1070398,
      smses: 119527,
    },
    {
      date: "2019-05-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9497132,
      accounts: 336723,
      dataSent: 3714278,
      dataReceived: 26712416,
      callsIn: 894093,
      callsInDuration: 1942249,
      callsOut: 689001,
      callsOutDuration: 1167241,
      smses: 132685,
    },
    {
      date: "2019-05-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 9664021,
      accounts: 336613,
      dataSent: 3650385,
      dataReceived: 26130548,
      callsIn: 890197,
      callsInDuration: 1944958,
      callsOut: 682231,
      callsOutDuration: 1171065,
      smses: 132872,
    },
    {
      date: "2019-05-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9784724,
      accounts: 336439,
      dataSent: 3577854,
      dataReceived: 26000994,
      callsIn: 898544,
      callsInDuration: 1943471,
      callsOut: 681821,
      callsOutDuration: 1157500,
      smses: 141918,
    },
    {
      date: "2019-05-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9971777,
      accounts: 337661,
      dataSent: 3742636,
      dataReceived: 26652559,
      callsIn: 919924,
      callsInDuration: 1961993,
      callsOut: 698489,
      callsOutDuration: 1178071,
      smses: 129917,
    },
    {
      date: "2019-05-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10220165,
      accounts: 340136,
      dataSent: 3944728,
      dataReceived: 28260285,
      callsIn: 1002588,
      callsInDuration: 1965620,
      callsOut: 770302,
      callsOutDuration: 1194347,
      smses: 139710,
    },
    {
      date: "2019-05-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9812025,
      accounts: 335537,
      dataSent: 3711141,
      dataReceived: 26152020,
      callsIn: 917752,
      callsInDuration: 1693216,
      callsOut: 738692,
      callsOutDuration: 1072785,
      smses: 133866,
    },
    {
      date: "2019-05-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8912433,
      accounts: 327874,
      dataSent: 3664658,
      dataReceived: 25704695,
      callsIn: 651653,
      callsInDuration: 1509174,
      callsOut: 538308,
      callsOutDuration: 953608,
      smses: 111087,
    },
    {
      date: "2019-05-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9763483,
      accounts: 340644,
      dataSent: 3560247,
      dataReceived: 25135470,
      callsIn: 912550,
      callsInDuration: 1959722,
      callsOut: 705936,
      callsOutDuration: 1173607,
      smses: 133767,
    },
    {
      date: "2019-05-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10102007,
      accounts: 341612,
      dataSent: 3850866,
      dataReceived: 27038785,
      callsIn: 894188,
      callsInDuration: 1928042,
      callsOut: 679884,
      callsOutDuration: 1145972,
      smses: 132102,
    },
    {
      date: "2019-05-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10017321,
      accounts: 340044,
      dataSent: 3884590,
      dataReceived: 27329026,
      callsIn: 895144,
      callsInDuration: 1986696,
      callsOut: 685193,
      callsOutDuration: 1194054,
      smses: 128186,
    },
    {
      date: "2019-05-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10176044,
      accounts: 340569,
      dataSent: 3880659,
      dataReceived: 27782773,
      callsIn: 959028,
      callsInDuration: 1956657,
      callsOut: 745022,
      callsOutDuration: 1196654,
      smses: 136865,
    },
    {
      date: "2019-05-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9367202,
      accounts: 328684,
      dataSent: 3722055,
      dataReceived: 25931507,
      callsIn: 811448,
      callsInDuration: 1677626,
      callsOut: 641617,
      callsOutDuration: 1043686,
      smses: 119004,
    },
    {
      date: "2019-05-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9485705,
      accounts: 331037,
      dataSent: 3797620,
      dataReceived: 26365360,
      callsIn: 814324,
      callsInDuration: 1582252,
      callsOut: 660434,
      callsOutDuration: 1008828,
      smses: 125001,
    },
    {
      date: "2019-05-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 8972672,
      accounts: 322507,
      dataSent: 3406493,
      dataReceived: 23146962,
      callsIn: 675764,
      callsInDuration: 1586504,
      callsOut: 555558,
      callsOutDuration: 1006984,
      smses: 113271,
    },
    {
      date: "2019-05-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10733100,
      accounts: 342057,
      dataSent: 3784588,
      dataReceived: 25842619,
      callsIn: 894540,
      callsInDuration: 1960357,
      callsOut: 681767,
      callsOutDuration: 1182627,
      smses: 130819,
    },
    {
      date: "2019-05-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10659576,
      accounts: 339706,
      dataSent: 3753917,
      dataReceived: 26342181,
      callsIn: 891200,
      callsInDuration: 1980456,
      callsOut: 671442,
      callsOutDuration: 1183288,
      smses: 129706,
    },
    {
      date: "2019-05-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10685546,
      accounts: 341553,
      dataSent: 3745679,
      dataReceived: 26679535,
      callsIn: 891915,
      callsInDuration: 1985964,
      callsOut: 662740,
      callsOutDuration: 1172085,
      smses: 125386,
    },
    {
      date: "2019-05-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10930110,
      accounts: 342556,
      dataSent: 3863908,
      dataReceived: 27810120,
      callsIn: 901646,
      callsInDuration: 1956586,
      callsOut: 683813,
      callsOutDuration: 1164168,
      smses: 130861,
    },
    {
      date: "2019-05-31",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11801502,
      accounts: 348169,
      dataSent: 4159991,
      dataReceived: 30168583,
      callsIn: 1038155,
      callsInDuration: 2005190,
      callsOut: 804883,
      callsOutDuration: 1223847,
      smses: 144833,
    },
    {
      date: "2019-06-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10955040,
      accounts: 342087,
      dataSent: 4092112,
      dataReceived: 29398232,
      callsIn: 963854,
      callsInDuration: 1743924,
      callsOut: 783394,
      callsOutDuration: 1113940,
      smses: 135436,
    },
    {
      date: "2019-06-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10461155,
      accounts: 328927,
      dataSent: 3588987,
      dataReceived: 25717477,
      callsIn: 689794,
      callsInDuration: 1563049,
      callsOut: 568242,
      callsOutDuration: 987844,
      smses: 111128,
    },
    {
      date: "2019-06-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13024199,
      accounts: 346225,
      dataSent: 3683367,
      dataReceived: 26131634,
      callsIn: 925762,
      callsInDuration: 1985560,
      callsOut: 713933,
      callsOutDuration: 1201712,
      smses: 136239,
    },
    {
      date: "2019-06-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11082277,
      accounts: 344813,
      dataSent: 3831952,
      dataReceived: 26882860,
      callsIn: 916587,
      callsInDuration: 2010519,
      callsOut: 701268,
      callsOutDuration: 1213304,
      smses: 138227,
    },
    {
      date: "2019-06-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11663483,
      accounts: 345077,
      dataSent: 3852325,
      dataReceived: 26832316,
      callsIn: 921250,
      callsInDuration: 2014859,
      callsOut: 702013,
      callsOutDuration: 1206598,
      smses: 131279,
    },
    {
      date: "2019-06-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11766873,
      accounts: 346087,
      dataSent: 3950324,
      dataReceived: 28278172,
      callsIn: 932482,
      callsInDuration: 2003546,
      callsOut: 710166,
      callsOutDuration: 1209759,
      smses: 133482,
    },
    {
      date: "2019-06-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 7534845,
      accounts: 338213,
      dataSent: 3720400,
      dataReceived: 27510137,
      callsIn: 1032507,
      callsInDuration: 2003948,
      callsOut: 818928,
      callsOutDuration: 1244303,
      smses: 165129,
    },
    {
      date: "2019-06-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 7929302,
      accounts: 368290,
      dataSent: 3538702,
      dataReceived: 26154490,
      callsIn: 932200,
      callsInDuration: 1729170,
      callsOut: 765329,
      callsOutDuration: 1109818,
      smses: 144682,
    },
    {
      date: "2019-06-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9017860,
      accounts: 326316,
      dataSent: 3800451,
      dataReceived: 26639561,
      callsIn: 674296,
      callsInDuration: 1543217,
      callsOut: 562504,
      callsOutDuration: 989730,
      smses: 116818,
    },
    {
      date: "2019-06-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10099426,
      accounts: 345752,
      dataSent: 3940495,
      dataReceived: 27366990,
      callsIn: 919323,
      callsInDuration: 2009788,
      callsOut: 716346,
      callsOutDuration: 1226224,
      smses: 134688,
    },
    {
      date: "2019-06-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10241041,
      accounts: 347234,
      dataSent: 4062100,
      dataReceived: 29452957,
      callsIn: 897530,
      callsInDuration: 1988024,
      callsOut: 693437,
      callsOutDuration: 1205434,
      smses: 131571,
    },
    {
      date: "2019-06-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10159631,
      accounts: 350495,
      dataSent: 4040759,
      dataReceived: 29069573,
      callsIn: 918667,
      callsInDuration: 2030305,
      callsOut: 710644,
      callsOutDuration: 1217608,
      smses: 128864,
    },
    {
      date: "2019-06-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10480904,
      accounts: 354284,
      dataSent: 4182903,
      dataReceived: 30220202,
      callsIn: 933701,
      callsInDuration: 2034907,
      callsOut: 725666,
      callsOutDuration: 1232900,
      smses: 129736,
    },
    {
      date: "2019-06-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11049137,
      accounts: 358826,
      dataSent: 4426544,
      dataReceived: 32679033,
      callsIn: 1061673,
      callsInDuration: 2002483,
      callsOut: 836383,
      callsOutDuration: 1251909,
      smses: 144234,
    },
    {
      date: "2019-06-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10419225,
      accounts: 354366,
      dataSent: 4324375,
      dataReceived: 30971424,
      callsIn: 986621,
      callsInDuration: 1725146,
      callsOut: 814384,
      callsOutDuration: 1126914,
      smses: 137664,
    },
    {
      date: "2019-06-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9808479,
      accounts: 344921,
      dataSent: 4047163,
      dataReceived: 28924677,
      callsIn: 723817,
      callsInDuration: 1581033,
      callsOut: 633606,
      callsOutDuration: 1070212,
      smses: 118081,
    },
    {
      date: "2019-06-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10259333,
      accounts: 357934,
      dataSent: 4140260,
      dataReceived: 29333378,
      callsIn: 930617,
      callsInDuration: 2032671,
      callsOut: 722739,
      callsOutDuration: 1239696,
      smses: 149005,
    },
    {
      date: "2019-06-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10401243,
      accounts: 356890,
      dataSent: 3792058,
      dataReceived: 27653681,
      callsIn: 917139,
      callsInDuration: 2006652,
      callsOut: 708799,
      callsOutDuration: 1218172,
      smses: 130633,
    },
    {
      date: "2019-06-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10710102,
      accounts: 362685,
      dataSent: 4499145,
      dataReceived: 32724970,
      callsIn: 919805,
      callsInDuration: 2026623,
      callsOut: 709745,
      callsOutDuration: 1224164,
      smses: 157729,
    },
    {
      date: "2019-06-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10521138,
      accounts: 359455,
      dataSent: 4170390,
      dataReceived: 30406720,
      callsIn: 943986,
      callsInDuration: 2046168,
      callsOut: 733032,
      callsOutDuration: 1235264,
      smses: 136993,
    },
    {
      date: "2019-06-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11125058,
      accounts: 366070,
      dataSent: 4520988,
      dataReceived: 33211153,
      callsIn: 1033977,
      callsInDuration: 2022710,
      callsOut: 821147,
      callsOutDuration: 1260897,
      smses: 147798,
    },
    {
      date: "2019-06-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10286466,
      accounts: 356665,
      dataSent: 4354726,
      dataReceived: 31496013,
      callsIn: 935936,
      callsInDuration: 1749054,
      callsOut: 777058,
      callsOutDuration: 1137691,
      smses: 133967,
    },
    {
      date: "2019-06-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9240231,
      accounts: 342847,
      dataSent: 3998063,
      dataReceived: 29052547,
      callsIn: 673734,
      callsInDuration: 1569538,
      callsOut: 577451,
      callsOutDuration: 1021420,
      smses: 116028,
    },
    {
      date: "2019-06-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10087728,
      accounts: 357828,
      dataSent: 4119779,
      dataReceived: 30385994,
      callsIn: 895821,
      callsInDuration: 1995662,
      callsOut: 708995,
      callsOutDuration: 1230643,
      smses: 132235,
    },
    {
      date: "2019-06-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10427265,
      accounts: 361130,
      dataSent: 4132239,
      dataReceived: 30361464,
      callsIn: 916720,
      callsInDuration: 2044341,
      callsOut: 720159,
      callsOutDuration: 1254126,
      smses: 132801,
    },
    {
      date: "2019-06-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10200109,
      accounts: 357906,
      dataSent: 3468362,
      dataReceived: 26187308,
      callsIn: 929516,
      callsInDuration: 2004298,
      callsOut: 723876,
      callsOutDuration: 1208632,
      smses: 133279,
    },
    {
      date: "2019-06-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10713481,
      accounts: 367160,
      dataSent: 4751175,
      dataReceived: 35959498,
      callsIn: 939305,
      callsInDuration: 2031008,
      callsOut: 745756,
      callsOutDuration: 1256987,
      smses: 134918,
    },
    {
      date: "2019-06-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10927342,
      accounts: 366942,
      dataSent: 4511525,
      dataReceived: 34221668,
      callsIn: 1068359,
      callsInDuration: 2036024,
      callsOut: 865712,
      callsOutDuration: 1294756,
      smses: 145941,
    },
    {
      date: "2019-06-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10382161,
      accounts: 361651,
      dataSent: 4541149,
      dataReceived: 33156896,
      callsIn: 956413,
      callsInDuration: 1728819,
      callsOut: 811954,
      callsOutDuration: 1151051,
      smses: 136085,
    },
    {
      date: "2019-06-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9349496,
      accounts: 348025,
      dataSent: 3677265,
      dataReceived: 27137388,
      callsIn: 702817,
      callsInDuration: 1514968,
      callsOut: 604692,
      callsOutDuration: 994559,
      smses: 115950,
    },
    {
      date: "2019-07-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10661618,
      accounts: 369160,
      dataSent: 4689132,
      dataReceived: 33967988,
      callsIn: 951314,
      callsInDuration: 1987263,
      callsOut: 764156,
      callsOutDuration: 1243338,
      smses: 137431,
    },
    {
      date: "2019-07-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10356140,
      accounts: 364500,
      dataSent: 4195046,
      dataReceived: 30469689,
      callsIn: 942338,
      callsInDuration: 2041722,
      callsOut: 749436,
      callsOutDuration: 1278142,
      smses: 136631,
    },
    {
      date: "2019-07-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11029165,
      accounts: 369316,
      dataSent: 4445187,
      dataReceived: 28494159,
      callsIn: 996037,
      callsInDuration: 2174284,
      callsOut: 784357,
      callsOutDuration: 1341630,
      smses: 139389,
    },
    {
      date: "2019-07-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10372092,
      accounts: 364493,
      dataSent: 4320313,
      dataReceived: 31654776,
      callsIn: 973419,
      callsInDuration: 2088970,
      callsOut: 764563,
      callsOutDuration: 1292223,
      smses: 132015,
    },
    {
      date: "2019-07-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10852943,
      accounts: 372387,
      dataSent: 4671301,
      dataReceived: 34252790,
      callsIn: 1089025,
      callsInDuration: 2052572,
      callsOut: 877744,
      callsOutDuration: 1300777,
      smses: 157926,
    },
    {
      date: "2019-07-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10418830,
      accounts: 367608,
      dataSent: 4645859,
      dataReceived: 34138305,
      callsIn: 953963,
      callsInDuration: 1763703,
      callsOut: 810890,
      callsOutDuration: 1164557,
      smses: 133241,
    },
    {
      date: "2019-07-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9331159,
      accounts: 351139,
      dataSent: 4168216,
      dataReceived: 30470075,
      callsIn: 682864,
      callsInDuration: 1575357,
      callsOut: 598340,
      callsOutDuration: 1033845,
      smses: 114508,
    },
    {
      date: "2019-07-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10068535,
      accounts: 367244,
      dataSent: 3965939,
      dataReceived: 28755876,
      callsIn: 949491,
      callsInDuration: 2052936,
      callsOut: 764903,
      callsOutDuration: 1273408,
      smses: 131860,
    },
    {
      date: "2019-07-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10418608,
      accounts: 371652,
      dataSent: 4816621,
      dataReceived: 34859334,
      callsIn: 942503,
      callsInDuration: 2060450,
      callsOut: 742485,
      callsOutDuration: 1278536,
      smses: 128246,
    },
    {
      date: "2019-07-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12229924,
      accounts: 368934,
      dataSent: 4307635,
      dataReceived: 31604860,
      callsIn: 942856,
      callsInDuration: 2044441,
      callsOut: 743249,
      callsOutDuration: 1256823,
      smses: 135776,
    },
    {
      date: "2019-07-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12312494,
      accounts: 372677,
      dataSent: 4562422,
      dataReceived: 33362964,
      callsIn: 947246,
      callsInDuration: 2031769,
      callsOut: 747462,
      callsOutDuration: 1246513,
      smses: 128700,
    },
    {
      date: "2019-07-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12456111,
      accounts: 372338,
      dataSent: 4682004,
      dataReceived: 34143676,
      callsIn: 1047365,
      callsInDuration: 2052153,
      callsOut: 833049,
      callsOutDuration: 1280646,
      smses: 137926,
    },
    {
      date: "2019-07-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 11967274,
      accounts: 364762,
      dataSent: 4587015,
      dataReceived: 32986274,
      callsIn: 931359,
      callsInDuration: 1766719,
      callsOut: 773687,
      callsOutDuration: 1138018,
      smses: 129778,
    },
    {
      date: "2019-07-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10856092,
      accounts: 349618,
      dataSent: 3855435,
      dataReceived: 27315870,
      callsIn: 680497,
      callsInDuration: 1527359,
      callsOut: 580126,
      callsOutDuration: 984854,
      smses: 112036,
    },
    {
      date: "2019-07-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12421013,
      accounts: 371493,
      dataSent: 4863603,
      dataReceived: 34919897,
      callsIn: 974395,
      callsInDuration: 2143764,
      callsOut: 768288,
      callsOutDuration: 1337198,
      smses: 139102,
    },
    {
      date: "2019-07-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12198428,
      accounts: 367487,
      dataSent: 4627185,
      dataReceived: 32767701,
      callsIn: 958370,
      callsInDuration: 2079149,
      callsOut: 748874,
      callsOutDuration: 1282954,
      smses: 130119,
    },
    {
      date: "2019-07-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12402050,
      accounts: 367054,
      dataSent: 4678899,
      dataReceived: 34130486,
      callsIn: 954886,
      callsInDuration: 2075004,
      callsOut: 745676,
      callsOutDuration: 1281301,
      smses: 124930,
    },
    {
      date: "2019-07-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12617387,
      accounts: 366564,
      dataSent: 4756586,
      dataReceived: 34909747,
      callsIn: 962277,
      callsInDuration: 2058403,
      callsOut: 758678,
      callsOutDuration: 1288347,
      smses: 135119,
    },
    {
      date: "2019-07-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11125852,
      accounts: 371051,
      dataSent: 4914054,
      dataReceived: 37278889,
      callsIn: 1070411,
      callsInDuration: 2057610,
      callsOut: 857911,
      callsOutDuration: 1310772,
      smses: 144155,
    },
    {
      date: "2019-07-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10852128,
      accounts: 366790,
      dataSent: 5089020,
      dataReceived: 37713400,
      callsIn: 957089,
      callsInDuration: 1751463,
      callsOut: 815617,
      callsOutDuration: 1178363,
      smses: 132337,
    },
    {
      date: "2019-07-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9838611,
      accounts: 352200,
      dataSent: 4618685,
      dataReceived: 33754241,
      callsIn: 686771,
      callsInDuration: 1573644,
      callsOut: 603291,
      callsOutDuration: 1062867,
      smses: 113515,
    },
    {
      date: "2019-07-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10460369,
      accounts: 365182,
      dataSent: 4008532,
      dataReceived: 29688210,
      callsIn: 937121,
      callsInDuration: 2019643,
      callsOut: 772466,
      callsOutDuration: 1285201,
      smses: 129366,
    },
    {
      date: "2019-07-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10837516,
      accounts: 372492,
      dataSent: 5249032,
      dataReceived: 40316394,
      callsIn: 932858,
      callsInDuration: 2039569,
      callsOut: 754532,
      callsOutDuration: 1300037,
      smses: 127329,
    },
    {
      date: "2019-07-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10774784,
      accounts: 369947,
      dataSent: 5076234,
      dataReceived: 38723100,
      callsIn: 967755,
      callsInDuration: 2099765,
      callsOut: 771516,
      callsOutDuration: 1332372,
      smses: 126205,
    },
    {
      date: "2019-07-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11048134,
      accounts: 374930,
      dataSent: 5232760,
      dataReceived: 40069832,
      callsIn: 1010861,
      callsInDuration: 2077709,
      callsOut: 814364,
      callsOutDuration: 1336342,
      smses: 130609,
    },
    {
      date: "2019-07-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11217432,
      accounts: 374835,
      dataSent: 5273240,
      dataReceived: 39955559,
      callsIn: 1028612,
      callsInDuration: 1961569,
      callsOut: 848973,
      callsOutDuration: 1299987,
      smses: 132002,
    },
    {
      date: "2019-07-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10968951,
      accounts: 371248,
      dataSent: 5276962,
      dataReceived: 39597150,
      callsIn: 921221,
      callsInDuration: 1729616,
      callsOut: 786650,
      callsOutDuration: 1172932,
      smses: 127702,
    },
    {
      date: "2019-07-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10170050,
      accounts: 360577,
      dataSent: 4989349,
      dataReceived: 37194061,
      callsIn: 681465,
      callsInDuration: 1581768,
      callsOut: 596625,
      callsOutDuration: 1070636,
      smses: 109028,
    },
    {
      date: "2019-07-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10722346,
      accounts: 374948,
      dataSent: 4968803,
      dataReceived: 37689273,
      callsIn: 949199,
      callsInDuration: 2084861,
      callsOut: 772695,
      callsOutDuration: 1352543,
      smses: 126562,
    },
    {
      date: "2019-07-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10983515,
      accounts: 378139,
      dataSent: 5025620,
      dataReceived: 37968941,
      callsIn: 961533,
      callsInDuration: 2100432,
      callsOut: 766570,
      callsOutDuration: 1349887,
      smses: 125640,
    },
    {
      date: "2019-07-31",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10253021,
      accounts: 375203,
      dataSent: 4809333,
      dataReceived: 36568449,
      callsIn: 991711,
      callsInDuration: 2142102,
      callsOut: 786682,
      callsOutDuration: 1370387,
      smses: 130861,
    },
    {
      date: "2019-08-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10800631,
      accounts: 374245,
      dataSent: 5057449,
      dataReceived: 38507729,
      callsIn: 999061,
      callsInDuration: 2128847,
      callsOut: 816273,
      callsOutDuration: 1388325,
      smses: 126320,
    },
    {
      date: "2019-08-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11093268,
      accounts: 379068,
      dataSent: 5260879,
      dataReceived: 39860104,
      callsIn: 1090932,
      callsInDuration: 2112565,
      callsOut: 905328,
      callsOutDuration: 1385561,
      smses: 135713,
    },
    {
      date: "2019-08-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10829475,
      accounts: 376535,
      dataSent: 5418461,
      dataReceived: 39801517,
      callsIn: 992752,
      callsInDuration: 1805446,
      callsOut: 869658,
      callsOutDuration: 1253118,
      smses: 131814,
    },
    {
      date: "2019-08-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9938793,
      accounts: 363351,
      dataSent: 4980496,
      dataReceived: 37355158,
      callsIn: 712210,
      callsInDuration: 1602229,
      callsOut: 638766,
      callsOutDuration: 1095214,
      smses: 115676,
    },
    {
      date: "2019-08-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10603635,
      accounts: 376694,
      dataSent: 4981627,
      dataReceived: 37530701,
      callsIn: 981036,
      callsInDuration: 2119628,
      callsOut: 820031,
      callsOutDuration: 1391169,
      smses: 129918,
    },
    {
      date: "2019-08-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11566733,
      accounts: 379309,
      dataSent: 5226599,
      dataReceived: 39599243,
      callsIn: 967149,
      callsInDuration: 2129135,
      callsOut: 813208,
      callsOutDuration: 1395002,
      smses: 127211,
    },
    {
      date: "2019-08-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11740710,
      accounts: 382692,
      dataSent: 5093682,
      dataReceived: 38589612,
      callsIn: 981883,
      callsInDuration: 2128148,
      callsOut: 839674,
      callsOutDuration: 1406060,
      smses: 126230,
    },
    {
      date: "2019-08-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12093878,
      accounts: 385858,
      dataSent: 5715990,
      dataReceived: 42912693,
      callsIn: 1064757,
      callsInDuration: 2152386,
      callsOut: 902791,
      callsOutDuration: 1428100,
      smses: 132230,
    },
    {
      date: "2019-08-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11582961,
      accounts: 374543,
      dataSent: 5202609,
      dataReceived: 39449268,
      callsIn: 890977,
      callsInDuration: 1792731,
      callsOut: 786816,
      callsOutDuration: 1236205,
      smses: 121723,
    },
    {
      date: "2019-08-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 11649709,
      accounts: 375866,
      dataSent: 5428530,
      dataReceived: 39647567,
      callsIn: 875431,
      callsInDuration: 1644649,
      callsOut: 801470,
      callsOutDuration: 1163339,
      smses: 128010,
    },
    {
      date: "2019-08-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11036653,
      accounts: 367405,
      dataSent: 5101527,
      dataReceived: 37439845,
      callsIn: 680453,
      callsInDuration: 1568880,
      callsOut: 634539,
      callsOutDuration: 1094962,
      smses: 112424,
    },
    {
      date: "2019-08-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11825610,
      accounts: 384429,
      dataSent: 5340387,
      dataReceived: 40612484,
      callsIn: 971061,
      callsInDuration: 2112839,
      callsOut: 820184,
      callsOutDuration: 1391833,
      smses: 129321,
    },
    {
      date: "2019-08-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12018098,
      accounts: 384996,
      dataSent: 5413215,
      dataReceived: 41203356,
      callsIn: 962845,
      callsInDuration: 2132591,
      callsOut: 805227,
      callsOutDuration: 1381004,
      smses: 127673,
    },
    {
      date: "2019-08-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12007605,
      accounts: 385937,
      dataSent: 5376423,
      dataReceived: 41672423,
      callsIn: 978040,
      callsInDuration: 2175408,
      callsOut: 810352,
      callsOutDuration: 1403465,
      smses: 126117,
    },
    {
      date: "2019-08-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12239107,
      accounts: 390674,
      dataSent: 5033406,
      dataReceived: 37450393,
      callsIn: 1009354,
      callsInDuration: 2155173,
      callsOut: 839395,
      callsOutDuration: 1386516,
      smses: 128760,
    },
    {
      date: "2019-08-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13036478,
      accounts: 399882,
      dataSent: 6254226,
      dataReceived: 47152137,
      callsIn: 1113402,
      callsInDuration: 2195572,
      callsOut: 937992,
      callsOutDuration: 1459087,
      smses: 139424,
    },
    {
      date: "2019-08-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12287791,
      accounts: 388733,
      dataSent: 5647370,
      dataReceived: 41359859,
      callsIn: 1004532,
      callsInDuration: 1842252,
      callsOut: 897913,
      callsOutDuration: 1280960,
      smses: 132883,
    },
    {
      date: "2019-08-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11699785,
      accounts: 385167,
      dataSent: 5001098,
      dataReceived: 36943740,
      callsIn: 735338,
      callsInDuration: 1640298,
      callsOut: 684697,
      callsOutDuration: 1146286,
      smses: 114393,
    },
    {
      date: "2019-08-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12374060,
      accounts: 395410,
      dataSent: 5766061,
      dataReceived: 43409413,
      callsIn: 993710,
      callsInDuration: 2154251,
      callsOut: 855716,
      callsOutDuration: 1437732,
      smses: 135659,
    },
    {
      date: "2019-08-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12348615,
      accounts: 392275,
      dataSent: 5532126,
      dataReceived: 42141429,
      callsIn: 1007724,
      callsInDuration: 2167152,
      callsOut: 849352,
      callsOutDuration: 1428521,
      smses: 132971,
    },
    {
      date: "2019-08-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12319072,
      accounts: 390935,
      dataSent: 5469547,
      dataReceived: 41832109,
      callsIn: 1010291,
      callsInDuration: 2187948,
      callsOut: 842643,
      callsOutDuration: 1422816,
      smses: 130013,
    },
    {
      date: "2019-08-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12563051,
      accounts: 391012,
      dataSent: 5495231,
      dataReceived: 41940551,
      callsIn: 1004430,
      callsInDuration: 2164841,
      callsOut: 842668,
      callsOutDuration: 1414567,
      smses: 126685,
    },
    {
      date: "2019-08-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12924219,
      accounts: 394209,
      dataSent: 5665977,
      dataReceived: 43169457,
      callsIn: 1097887,
      callsInDuration: 2155158,
      callsOut: 937177,
      callsOutDuration: 1436399,
      smses: 137802,
    },
    {
      date: "2019-08-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12782696,
      accounts: 393147,
      dataSent: 5755295,
      dataReceived: 42072620,
      callsIn: 985895,
      callsInDuration: 1829365,
      callsOut: 883946,
      callsOutDuration: 1278612,
      smses: 130715,
    },
    {
      date: "2019-08-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11710415,
      accounts: 378948,
      dataSent: 5024233,
      dataReceived: 36513779,
      callsIn: 723038,
      callsInDuration: 1637350,
      callsOut: 669877,
      callsOutDuration: 1146654,
      smses: 113758,
    },
    {
      date: "2019-08-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12404166,
      accounts: 389012,
      dataSent: 5656122,
      dataReceived: 42145987,
      callsIn: 1001573,
      callsInDuration: 2196371,
      callsOut: 841252,
      callsOutDuration: 1440503,
      smses: 127595,
    },
    {
      date: "2019-08-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12284960,
      accounts: 388882,
      dataSent: 5288889,
      dataReceived: 40764261,
      callsIn: 981251,
      callsInDuration: 2190715,
      callsOut: 818156,
      callsOutDuration: 1427257,
      smses: 125849,
    },
    {
      date: "2019-08-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12060715,
      accounts: 387997,
      dataSent: 5392831,
      dataReceived: 41276842,
      callsIn: 992155,
      callsInDuration: 2197177,
      callsOut: 819783,
      callsOutDuration: 1427625,
      smses: 125146,
    },
    {
      date: "2019-08-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11988659,
      accounts: 386685,
      dataSent: 5335302,
      dataReceived: 40283355,
      callsIn: 1012699,
      callsInDuration: 2192562,
      callsOut: 839945,
      callsOutDuration: 1421452,
      smses: 127188,
    },
    {
      date: "2019-08-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12443482,
      accounts: 390996,
      dataSent: 5546671,
      dataReceived: 41598291,
      callsIn: 1132463,
      callsInDuration: 2183384,
      callsOut: 957027,
      callsOutDuration: 1456837,
      smses: 136522,
    },
    {
      date: "2019-08-31",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12400748,
      accounts: 388382,
      dataSent: 5549992,
      dataReceived: 40668073,
      callsIn: 1038086,
      callsInDuration: 1885026,
      callsOut: 923026,
      callsOutDuration: 1312339,
      smses: 134369,
    },
    {
      date: "2019-09-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10894373,
      accounts: 373135,
      dataSent: 5001604,
      dataReceived: 37144608,
      callsIn: 754337,
      callsInDuration: 1675469,
      callsOut: 694226,
      callsOutDuration: 1157723,
      smses: 114178,
    },
    {
      date: "2019-09-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12019680,
      accounts: 389355,
      dataSent: 5187826,
      dataReceived: 38889967,
      callsIn: 1055803,
      callsInDuration: 2242718,
      callsOut: 892327,
      callsOutDuration: 1484458,
      smses: 132281,
    },
    {
      date: "2019-09-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12083536,
      accounts: 389414,
      dataSent: 5020565,
      dataReceived: 38432253,
      callsIn: 1032512,
      callsInDuration: 2222599,
      callsOut: 865566,
      callsOutDuration: 1461405,
      smses: 136895,
    },
    {
      date: "2019-09-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11701548,
      accounts: 384540,
      dataSent: 4266979,
      dataReceived: 32426208,
      callsIn: 1019836,
      callsInDuration: 2126704,
      callsOut: 861829,
      callsOutDuration: 1412143,
      smses: 129719,
    },
    {
      date: "2019-09-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11752666,
      accounts: 393351,
      dataSent: 5834792,
      dataReceived: 44820712,
      callsIn: 1036326,
      callsInDuration: 2178631,
      callsOut: 873926,
      callsOutDuration: 1450717,
      smses: 128406,
    },
    {
      date: "2019-09-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12417650,
      accounts: 397165,
      dataSent: 5758174,
      dataReceived: 44239623,
      callsIn: 1124302,
      callsInDuration: 2145981,
      callsOut: 975836,
      callsOutDuration: 1493048,
      smses: 141966,
    },
    {
      date: "2019-09-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12379086,
      accounts: 393454,
      dataSent: 6121550,
      dataReceived: 45543312,
      callsIn: 1006094,
      callsInDuration: 1819753,
      callsOut: 932199,
      callsOutDuration: 1338069,
      smses: 131178,
    },
    {
      date: "2019-09-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11403539,
      accounts: 379701,
      dataSent: 5493592,
      dataReceived: 41525196,
      callsIn: 744431,
      callsInDuration: 1633417,
      callsOut: 701511,
      callsOutDuration: 1185373,
      smses: 112354,
    },
    {
      date: "2019-09-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11766722,
      accounts: 392547,
      dataSent: 5470541,
      dataReceived: 41798369,
      callsIn: 1034152,
      callsInDuration: 2181145,
      callsOut: 884597,
      callsOutDuration: 1498641,
      smses: 135217,
    },
    {
      date: "2019-09-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11967185,
      accounts: 394312,
      dataSent: 5483463,
      dataReceived: 42557254,
      callsIn: 1020828,
      callsInDuration: 2206526,
      callsOut: 866230,
      callsOutDuration: 1499644,
      smses: 128957,
    },
    {
      date: "2019-09-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11980531,
      accounts: 395573,
      dataSent: 5547142,
      dataReceived: 42855401,
      callsIn: 1013547,
      callsInDuration: 2211148,
      callsOut: 854741,
      callsOutDuration: 1495984,
      smses: 127968,
    },
    {
      date: "2019-09-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12272877,
      accounts: 397317,
      dataSent: 5449637,
      dataReceived: 41933408,
      callsIn: 1021265,
      callsInDuration: 2193790,
      callsOut: 859685,
      callsOutDuration: 1481811,
      smses: 126907,
    },
    {
      date: "2019-09-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13023337,
      accounts: 405935,
      dataSent: 5949636,
      dataReceived: 45582136,
      callsIn: 1153169,
      callsInDuration: 2200885,
      callsOut: 995495,
      callsOutDuration: 1533340,
      smses: 142345,
    },
    {
      date: "2019-09-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12882760,
      accounts: 407289,
      dataSent: 6465322,
      dataReceived: 48744568,
      callsIn: 1021497,
      callsInDuration: 1865788,
      callsOut: 932552,
      callsOutDuration: 1352472,
      smses: 133495,
    },
    {
      date: "2019-09-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11740510,
      accounts: 393113,
      dataSent: 5493519,
      dataReceived: 40840909,
      callsIn: 748761,
      callsInDuration: 1643984,
      callsOut: 704740,
      callsOutDuration: 1196699,
      smses: 116172,
    },
    {
      date: "2019-09-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12138669,
      accounts: 397900,
      dataSent: 6113591,
      dataReceived: 46394243,
      callsIn: 1050902,
      callsInDuration: 2224923,
      callsOut: 897057,
      callsOutDuration: 1524950,
      smses: 135922,
    },
    {
      date: "2019-09-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11874611,
      accounts: 394942,
      dataSent: 5799467,
      dataReceived: 44538887,
      callsIn: 1019049,
      callsInDuration: 2206542,
      callsOut: 866305,
      callsOutDuration: 1516744,
      smses: 133300,
    },
    {
      date: "2019-09-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12092281,
      accounts: 395086,
      dataSent: 5599667,
      dataReceived: 43347376,
      callsIn: 1019141,
      callsInDuration: 2207264,
      callsOut: 863092,
      callsOutDuration: 1516140,
      smses: 131931,
    },
    {
      date: "2019-09-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12946736,
      accounts: 397502,
      dataSent: 6258213,
      dataReceived: 46991152,
      callsIn: 1026834,
      callsInDuration: 2176652,
      callsOut: 870595,
      callsOutDuration: 1507068,
      smses: 135959,
    },
    {
      date: "2019-09-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12858407,
      accounts: 399634,
      dataSent: 6410799,
      dataReceived: 47539069,
      callsIn: 1118930,
      callsInDuration: 2154024,
      callsOut: 965142,
      callsOutDuration: 1509073,
      smses: 147405,
    },
    {
      date: "2019-09-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12471092,
      accounts: 394777,
      dataSent: 6479198,
      dataReceived: 46571668,
      callsIn: 1006212,
      callsInDuration: 1834336,
      callsOut: 926374,
      callsOutDuration: 1359782,
      smses: 135405,
    },
    {
      date: "2019-09-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11283954,
      accounts: 382349,
      dataSent: 5778958,
      dataReceived: 43116490,
      callsIn: 733510,
      callsInDuration: 1621954,
      callsOut: 704484,
      callsOutDuration: 1203698,
      smses: 112885,
    },
    {
      date: "2019-09-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11756952,
      accounts: 394128,
      dataSent: 5580006,
      dataReceived: 42425496,
      callsIn: 1017590,
      callsInDuration: 2138323,
      callsOut: 878327,
      callsOutDuration: 1490545,
      smses: 133158,
    },
    {
      date: "2019-09-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12146838,
      accounts: 397772,
      dataSent: 6127378,
      dataReceived: 46355964,
      callsIn: 1016271,
      callsInDuration: 2207973,
      callsOut: 856163,
      callsOutDuration: 1504158,
      smses: 130205,
    },
    {
      date: "2019-09-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12270837,
      accounts: 399496,
      dataSent: 6180646,
      dataReceived: 48444631,
      callsIn: 1038788,
      callsInDuration: 2195592,
      callsOut: 873545,
      callsOutDuration: 1488271,
      smses: 129405,
    },
    {
      date: "2019-09-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12247000,
      accounts: 399042,
      dataSent: 6531306,
      dataReceived: 50215822,
      callsIn: 1061704,
      callsInDuration: 2190284,
      callsOut: 899607,
      callsOutDuration: 1492827,
      smses: 130708,
    },
    {
      date: "2019-09-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12673199,
      accounts: 402548,
      dataSent: 6721848,
      dataReceived: 52697386,
      callsIn: 1137431,
      callsInDuration: 2200435,
      callsOut: 977703,
      callsOutDuration: 1538848,
      smses: 139794,
    },
    {
      date: "2019-09-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12428942,
      accounts: 396148,
      dataSent: 6525972,
      dataReceived: 49627307,
      callsIn: 1018884,
      callsInDuration: 1866139,
      callsOut: 919707,
      callsOutDuration: 1354811,
      smses: 132514,
    },
    {
      date: "2019-09-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11241858,
      accounts: 382086,
      dataSent: 5552849,
      dataReceived: 41875964,
      callsIn: 743130,
      callsInDuration: 1679985,
      callsOut: 692097,
      callsOutDuration: 1223825,
      smses: 112662,
    },
    {
      date: "2019-09-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12773771,
      accounts: 400751,
      dataSent: 6567428,
      dataReceived: 51411831,
      callsIn: 1051113,
      callsInDuration: 2245228,
      callsOut: 893567,
      callsOutDuration: 1548953,
      smses: 140154,
    },
    {
      date: "2019-10-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13034634,
      accounts: 428771,
      dataSent: 6126794,
      dataReceived: 48007035,
      callsIn: 1052660,
      callsInDuration: 2250251,
      callsOut: 891199,
      callsOutDuration: 1542452,
      smses: 132828,
    },
    {
      date: "2019-10-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13623946,
      accounts: 432107,
      dataSent: 6455548,
      dataReceived: 51570456,
      callsIn: 1096909,
      callsInDuration: 2338384,
      callsOut: 926476,
      callsOutDuration: 1604654,
      smses: 178991,
    },
    {
      date: "2019-10-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12418247,
      accounts: 407635,
      dataSent: 6690488,
      dataReceived: 56655380,
      callsIn: 1097787,
      callsInDuration: 2498089,
      callsOut: 950157,
      callsOutDuration: 1764660,
      smses: 122278,
    },
    {
      date: "2019-10-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11830313,
      accounts: 399486,
      dataSent: 6084434,
      dataReceived: 48478382,
      callsIn: 957126,
      callsInDuration: 2221587,
      callsOut: 817913,
      callsOutDuration: 1545775,
      smses: 115194,
    },
    {
      date: "2019-10-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12663765,
      accounts: 412212,
      dataSent: 5935698,
      dataReceived: 46307213,
      callsIn: 976328,
      callsInDuration: 1985953,
      callsOut: 870389,
      callsOutDuration: 1425211,
      smses: 122840,
    },
    {
      date: "2019-10-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12243622,
      accounts: 404102,
      dataSent: 5108376,
      dataReceived: 39598200,
      callsIn: 743431,
      callsInDuration: 1806315,
      callsOut: 684504,
      callsOutDuration: 1294496,
      smses: 111650,
    },
    {
      date: "2019-10-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13230752,
      accounts: 412246,
      dataSent: 6906101,
      dataReceived: 55373290,
      callsIn: 963639,
      callsInDuration: 2215233,
      callsOut: 826385,
      callsOutDuration: 1546741,
      smses: 118353,
    },
    {
      date: "2019-10-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11852839,
      accounts: 395761,
      dataSent: 6242392,
      dataReceived: 50408016,
      callsIn: 848407,
      callsInDuration: 2185605,
      callsOut: 718437,
      callsOutDuration: 1501597,
      smses: 113235,
    },
    {
      date: "2019-10-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12005803,
      accounts: 393807,
      dataSent: 6289726,
      dataReceived: 49968613,
      callsIn: 760298,
      callsInDuration: 2034162,
      callsOut: 647335,
      callsOutDuration: 1415154,
      smses: 108058,
    },
    {
      date: "2019-10-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11856617,
      accounts: 398061,
      dataSent: 5914687,
      dataReceived: 46965476,
      callsIn: 883549,
      callsInDuration: 2158248,
      callsOut: 732256,
      callsOutDuration: 1462103,
      smses: 114689,
    },
    {
      date: "2019-10-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11873969,
      accounts: 397591,
      dataSent: 5746888,
      dataReceived: 44532499,
      callsIn: 840333,
      callsInDuration: 1966586,
      callsOut: 721514,
      callsOutDuration: 1352387,
      smses: 113657,
    },
    {
      date: "2019-10-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12296200,
      accounts: 401107,
      dataSent: 6634541,
      dataReceived: 50024020,
      callsIn: 940671,
      callsInDuration: 2069711,
      callsOut: 829966,
      callsOutDuration: 1471423,
      smses: 120359,
    },
    {
      date: "2019-10-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11337587,
      accounts: 384643,
      dataSent: 5622327,
      dataReceived: 43385012,
      callsIn: 633483,
      callsInDuration: 1694162,
      callsOut: 572946,
      callsOutDuration: 1211537,
      smses: 99999,
    },
    {
      date: "2019-10-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11324418,
      accounts: 397873,
      dataSent: 4054722,
      dataReceived: 31179431,
      callsIn: 974158,
      callsInDuration: 2165975,
      callsOut: 806912,
      callsOutDuration: 1472353,
      smses: 122225,
    },
    {
      date: "2019-10-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13363311,
      accounts: 426572,
      dataSent: 5148718,
      dataReceived: 39416064,
      callsIn: 1024553,
      callsInDuration: 2135809,
      callsOut: 847024,
      callsOutDuration: 1443787,
      smses: 130321,
    },
    {
      date: "2019-10-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13403151,
      accounts: 429036,
      dataSent: 6082158,
      dataReceived: 47566041,
      callsIn: 1033107,
      callsInDuration: 2210262,
      callsOut: 850447,
      callsOutDuration: 1487980,
      smses: 143838,
    },
    {
      date: "2019-10-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13068990,
      accounts: 425643,
      dataSent: 5816348,
      dataReceived: 44724277,
      callsIn: 1045815,
      callsInDuration: 2232208,
      callsOut: 866571,
      callsOutDuration: 1509533,
      smses: 130591,
    },
    {
      date: "2019-10-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13546923,
      accounts: 430076,
      dataSent: 6209616,
      dataReceived: 48047900,
      callsIn: 1150440,
      callsInDuration: 2191976,
      callsOut: 975744,
      callsOutDuration: 1521281,
      smses: 139506,
    },
    {
      date: "2019-10-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13205214,
      accounts: 424633,
      dataSent: 6126076,
      dataReceived: 46263835,
      callsIn: 1052906,
      callsInDuration: 1909495,
      callsOut: 929235,
      callsOutDuration: 1374524,
      smses: 130215,
    },
    {
      date: "2019-10-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12186450,
      accounts: 410947,
      dataSent: 5671366,
      dataReceived: 43140579,
      callsIn: 771971,
      callsInDuration: 1715791,
      callsOut: 697618,
      callsOutDuration: 1237825,
      smses: 111370,
    },
    {
      date: "2019-10-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13213148,
      accounts: 426866,
      dataSent: 5755451,
      dataReceived: 43570343,
      callsIn: 1051817,
      callsInDuration: 2217588,
      callsOut: 886005,
      callsOutDuration: 1514193,
      smses: 135507,
    },
    {
      date: "2019-10-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13122997,
      accounts: 425142,
      dataSent: 5229324,
      dataReceived: 39978418,
      callsIn: 1024149,
      callsInDuration: 2209906,
      callsOut: 856072,
      callsOutDuration: 1505659,
      smses: 133174,
    },
    {
      date: "2019-10-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13438397,
      accounts: 431251,
      dataSent: 6358112,
      dataReceived: 48391037,
      callsIn: 1025383,
      callsInDuration: 2199218,
      callsOut: 860379,
      callsOutDuration: 1494791,
      smses: 127877,
    },
    {
      date: "2019-10-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13382348,
      accounts: 429184,
      dataSent: 5628909,
      dataReceived: 42531772,
      callsIn: 1051455,
      callsInDuration: 2230330,
      callsOut: 874047,
      callsOutDuration: 1510588,
      smses: 128901,
    },
    {
      date: "2019-10-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13683848,
      accounts: 433946,
      dataSent: 6430616,
      dataReceived: 48577850,
      callsIn: 1176284,
      callsInDuration: 2232374,
      callsOut: 992505,
      callsOutDuration: 1541791,
      smses: 138457,
    },
    {
      date: "2019-10-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13352376,
      accounts: 430065,
      dataSent: 6149981,
      dataReceived: 45480890,
      callsIn: 1077668,
      callsInDuration: 1933654,
      callsOut: 968288,
      callsOutDuration: 1403770,
      smses: 141057,
    },
    {
      date: "2019-10-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12195842,
      accounts: 414443,
      dataSent: 5979941,
      dataReceived: 44328890,
      callsIn: 762534,
      callsInDuration: 1733355,
      callsOut: 693504,
      callsOutDuration: 1245984,
      smses: 112228,
    },
    {
      date: "2019-10-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12953334,
      accounts: 430532,
      dataSent: 5800750,
      dataReceived: 43243742,
      callsIn: 1038516,
      callsInDuration: 2236402,
      callsOut: 875222,
      callsOutDuration: 1538251,
      smses: 130245,
    },
    {
      date: "2019-10-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13217790,
      accounts: 429220,
      dataSent: 5705587,
      dataReceived: 42938118,
      callsIn: 1033238,
      callsInDuration: 2216746,
      callsOut: 862415,
      callsOutDuration: 1500268,
      smses: 141752,
    },
    {
      date: "2019-10-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13698050,
      accounts: 432763,
      dataSent: 5869406,
      dataReceived: 44275839,
      callsIn: 1080103,
      callsInDuration: 2248052,
      callsOut: 903264,
      callsOutDuration: 1528863,
      smses: 131329,
    },
    {
      date: "2019-10-31",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 14341176,
      accounts: 439546,
      dataSent: 6353269,
      dataReceived: 47370296,
      callsIn: 1300860,
      callsInDuration: 2283180,
      callsOut: 1119235,
      callsOutDuration: 1612124,
      smses: 148399,
    },
    {
      date: "2019-11-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13433131,
      accounts: 425058,
      dataSent: 5816378,
      dataReceived: 42938984,
      callsIn: 1042770,
      callsInDuration: 1928851,
      callsOut: 933422,
      callsOutDuration: 1396207,
      smses: 131558,
    },
    {
      date: "2019-11-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13488992,
      accounts: 423313,
      dataSent: 5927244,
      dataReceived: 41932613,
      callsIn: 960356,
      callsInDuration: 1706272,
      callsOut: 884956,
      callsOutDuration: 1278915,
      smses: 132560,
    },
    {
      date: "2019-11-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12756806,
      accounts: 413238,
      dataSent: 5824279,
      dataReceived: 40723587,
      callsIn: 757805,
      callsInDuration: 1539504,
      callsOut: 704835,
      callsOutDuration: 1147173,
      smses: 119647,
    },
    {
      date: "2019-11-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12111757,
      accounts: 409183,
      dataSent: 5227829,
      dataReceived: 37547177,
      callsIn: 788815,
      callsInDuration: 1752906,
      callsOut: 705141,
      callsOutDuration: 1253974,
      smses: 114746,
    },
    {
      date: "2019-11-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13418128,
      accounts: 433657,
      dataSent: 5898424,
      dataReceived: 43315839,
      callsIn: 1064650,
      callsInDuration: 2234881,
      callsOut: 906485,
      callsOutDuration: 1548639,
      smses: 136951,
    },
    {
      date: "2019-11-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13378272,
      accounts: 434722,
      dataSent: 5860378,
      dataReceived: 43325056,
      callsIn: 1050692,
      callsInDuration: 2250552,
      callsOut: 889643,
      callsOutDuration: 1551543,
      smses: 134141,
    },
    {
      date: "2019-11-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13590812,
      accounts: 436590,
      dataSent: 5889434,
      dataReceived: 44198346,
      callsIn: 1074616,
      callsInDuration: 2255141,
      callsOut: 913592,
      callsOutDuration: 1551568,
      smses: 134095,
    },
    {
      date: "2019-11-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14072197,
      accounts: 442117,
      dataSent: 6288685,
      dataReceived: 46435863,
      callsIn: 1203328,
      callsInDuration: 2282907,
      callsOut: 1027541,
      callsOutDuration: 1591261,
      smses: 138397,
    },
    {
      date: "2019-11-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13662189,
      accounts: 434722,
      dataSent: 6215166,
      dataReceived: 45817136,
      callsIn: 1059446,
      callsInDuration: 1927034,
      callsOut: 948237,
      callsOutDuration: 1396802,
      smses: 133454,
    },
    {
      date: "2019-11-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12265431,
      accounts: 418738,
      dataSent: 5556546,
      dataReceived: 40894514,
      callsIn: 777654,
      callsInDuration: 1735012,
      callsOut: 712514,
      callsOutDuration: 1263875,
      smses: 112137,
    },
    {
      date: "2019-11-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13482522,
      accounts: 439665,
      dataSent: 5963255,
      dataReceived: 44397276,
      callsIn: 1048663,
      callsInDuration: 2224850,
      callsOut: 901596,
      callsOutDuration: 1544548,
      smses: 135183,
    },
    {
      date: "2019-11-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13755596,
      accounts: 441811,
      dataSent: 5938853,
      dataReceived: 44333315,
      callsIn: 1046908,
      callsInDuration: 2233564,
      callsOut: 890565,
      callsOutDuration: 1539152,
      smses: 131160,
    },
    {
      date: "2019-11-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13748964,
      accounts: 441997,
      dataSent: 5988106,
      dataReceived: 44816867,
      callsIn: 1063409,
      callsInDuration: 2277787,
      callsOut: 893048,
      callsOutDuration: 1567127,
      smses: 130199,
    },
    {
      date: "2019-11-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13365606,
      accounts: 439721,
      dataSent: 5977502,
      dataReceived: 44288035,
      callsIn: 1094985,
      callsInDuration: 2288765,
      callsOut: 920027,
      callsOutDuration: 1573999,
      smses: 134579,
    },
    {
      date: "2019-11-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13704298,
      accounts: 439849,
      dataSent: 6065784,
      dataReceived: 45808460,
      callsIn: 1227935,
      callsInDuration: 2287282,
      callsOut: 1044365,
      callsOutDuration: 1575722,
      smses: 145948,
    },
    {
      date: "2019-11-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13173101,
      accounts: 428530,
      dataSent: 5448112,
      dataReceived: 39485248,
      callsIn: 1107848,
      callsInDuration: 1899678,
      callsOut: 987400,
      callsOutDuration: 1393134,
      smses: 135439,
    },
    {
      date: "2019-11-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12032453,
      accounts: 415270,
      dataSent: 5760082,
      dataReceived: 41329575,
      callsIn: 796672,
      callsInDuration: 1652586,
      callsOut: 733068,
      callsOutDuration: 1206340,
      smses: 114664,
    },
    {
      date: "2019-11-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13287576,
      accounts: 434072,
      dataSent: 5752249,
      dataReceived: 42369789,
      callsIn: 1066334,
      callsInDuration: 2237224,
      callsOut: 911887,
      callsOutDuration: 1555260,
      smses: 137270,
    },
    {
      date: "2019-11-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13274272,
      accounts: 429032,
      dataSent: 4892165,
      dataReceived: 36266786,
      callsIn: 1056168,
      callsInDuration: 2206838,
      callsOut: 889570,
      callsOutDuration: 1521189,
      smses: 135161,
    },
    {
      date: "2019-11-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13835811,
      accounts: 440392,
      dataSent: 5443616,
      dataReceived: 40144400,
      callsIn: 1077488,
      callsInDuration: 2221537,
      callsOut: 910959,
      callsOutDuration: 1521552,
      smses: 133246,
    },
    {
      date: "2019-11-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 14320164,
      accounts: 443080,
      dataSent: 6309703,
      dataReceived: 47410041,
      callsIn: 1092368,
      callsInDuration: 2240942,
      callsOut: 925684,
      callsOutDuration: 1549448,
      smses: 132128,
    },
    {
      date: "2019-11-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14403734,
      accounts: 444884,
      dataSent: 6328116,
      dataReceived: 47093747,
      callsIn: 1230745,
      callsInDuration: 2273684,
      callsOut: 1071119,
      callsOutDuration: 1619875,
      smses: 148158,
    },
    {
      date: "2019-11-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13359479,
      accounts: 434859,
      dataSent: 6027678,
      dataReceived: 44446780,
      callsIn: 1077350,
      callsInDuration: 1919017,
      callsOut: 973944,
      callsOutDuration: 1415831,
      smses: 135878,
    },
    {
      date: "2019-11-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11986703,
      accounts: 418873,
      dataSent: 5529543,
      dataReceived: 39918077,
      callsIn: 780996,
      callsInDuration: 1689749,
      callsOut: 715738,
      callsOutDuration: 1234628,
      smses: 114560,
    },
    {
      date: "2019-11-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13034001,
      accounts: 438512,
      dataSent: 5865102,
      dataReceived: 42776992,
      callsIn: 1080555,
      callsInDuration: 2258112,
      callsOut: 908305,
      callsOutDuration: 1552536,
      smses: 132610,
    },
    {
      date: "2019-11-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13158860,
      accounts: 437840,
      dataSent: 5726654,
      dataReceived: 42061492,
      callsIn: 1068056,
      callsInDuration: 2250557,
      callsOut: 884967,
      callsOutDuration: 1539610,
      smses: 131292,
    },
    {
      date: "2019-11-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 16029866,
      accounts: 443490,
      dataSent: 6030804,
      dataReceived: 44781056,
      callsIn: 1071219,
      callsInDuration: 2246223,
      callsOut: 894738,
      callsOutDuration: 1548425,
      smses: 131231,
    },
    {
      date: "2019-11-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13365717,
      accounts: 439996,
      dataSent: 5916718,
      dataReceived: 43477975,
      callsIn: 1089243,
      callsInDuration: 2263248,
      callsOut: 915495,
      callsOutDuration: 1558573,
      smses: 131598,
    },
    {
      date: "2019-11-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13904032,
      accounts: 446372,
      dataSent: 6259396,
      dataReceived: 45821758,
      callsIn: 1284792,
      callsInDuration: 2306752,
      callsOut: 1105704,
      callsOutDuration: 1632300,
      smses: 147487,
    },
    {
      date: "2019-11-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13379794,
      accounts: 439728,
      dataSent: 5678405,
      dataReceived: 40697798,
      callsIn: 1163476,
      callsInDuration: 1964531,
      callsOut: 1038282,
      callsOutDuration: 1446362,
      smses: 137580,
    },
    {
      date: "2019-12-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12437661,
      accounts: 429137,
      dataSent: 6127248,
      dataReceived: 44707274,
      callsIn: 830696,
      callsInDuration: 1663311,
      callsOut: 759987,
      callsOutDuration: 1222066,
      smses: 112257,
    },
    {
      date: "2019-12-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13131767,
      accounts: 442033,
      dataSent: 5916667,
      dataReceived: 43624398,
      callsIn: 1118516,
      callsInDuration: 2296971,
      callsOut: 952925,
      callsOutDuration: 1598106,
      smses: 131771,
    },
    {
      date: "2019-12-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13190197,
      accounts: 441264,
      dataSent: 6028260,
      dataReceived: 44641244,
      callsIn: 1094618,
      callsInDuration: 2267907,
      callsOut: 925395,
      callsOutDuration: 1576276,
      smses: 132937,
    },
    {
      date: "2019-12-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13381759,
      accounts: 442718,
      dataSent: 6260093,
      dataReceived: 45161666,
      callsIn: 1126388,
      callsInDuration: 2287393,
      callsOut: 956134,
      callsOutDuration: 1598364,
      smses: 132560,
    },
    {
      date: "2019-12-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13586062,
      accounts: 443712,
      dataSent: 6353086,
      dataReceived: 46619313,
      callsIn: 1186852,
      callsInDuration: 2283588,
      callsOut: 1025048,
      callsOutDuration: 1621187,
      smses: 138747,
    },
    {
      date: "2019-12-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13628429,
      accounts: 442798,
      dataSent: 6314987,
      dataReceived: 46805757,
      callsIn: 1185595,
      callsInDuration: 2160248,
      callsOut: 1043540,
      callsOutDuration: 1553837,
      smses: 134975,
    },
    {
      date: "2019-12-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13235830,
      accounts: 437313,
      dataSent: 6293183,
      dataReceived: 45667946,
      callsIn: 1091833,
      callsInDuration: 1892242,
      callsOut: 995484,
      callsOutDuration: 1403618,
      smses: 131079,
    },
    {
      date: "2019-12-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12258917,
      accounts: 424235,
      dataSent: 5804573,
      dataReceived: 41542263,
      callsIn: 812494,
      callsInDuration: 1737704,
      callsOut: 754716,
      callsOutDuration: 1284519,
      smses: 113405,
    },
    {
      date: "2019-12-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13339720,
      accounts: 443344,
      dataSent: 6010892,
      dataReceived: 44206919,
      callsIn: 1090996,
      callsInDuration: 2261665,
      callsOut: 933351,
      callsOutDuration: 1575389,
      smses: 140551,
    },
    {
      date: "2019-12-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13504082,
      accounts: 443813,
      dataSent: 5894369,
      dataReceived: 44272874,
      callsIn: 1095168,
      callsInDuration: 2279968,
      callsOut: 928943,
      callsOutDuration: 1576499,
      smses: 138710,
    },
    {
      date: "2019-12-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13637321,
      accounts: 444467,
      dataSent: 5787406,
      dataReceived: 44358440,
      callsIn: 1105594,
      callsInDuration: 2253199,
      callsOut: 932775,
      callsOutDuration: 1559384,
      smses: 128096,
    },
    {
      date: "2019-12-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13783124,
      accounts: 446610,
      dataSent: 6265209,
      dataReceived: 47108176,
      callsIn: 1134160,
      callsInDuration: 2296801,
      callsOut: 955810,
      callsOutDuration: 1588563,
      smses: 130311,
    },
    {
      date: "2019-12-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14325701,
      accounts: 452552,
      dataSent: 6535460,
      dataReceived: 48485862,
      callsIn: 1287821,
      callsInDuration: 2297520,
      callsOut: 1110716,
      callsOutDuration: 1632350,
      smses: 143688,
    },
    {
      date: "2019-12-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13812840,
      accounts: 444254,
      dataSent: 6225101,
      dataReceived: 45560263,
      callsIn: 1183526,
      callsInDuration: 2005105,
      callsOut: 1057571,
      callsOutDuration: 1478603,
      smses: 134961,
    },
    {
      date: "2019-12-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12393984,
      accounts: 424864,
      dataSent: 4873058,
      dataReceived: 35357061,
      callsIn: 870160,
      callsInDuration: 1785074,
      callsOut: 795399,
      callsOutDuration: 1306355,
      smses: 115029,
    },
    {
      date: "2019-12-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13333306,
      accounts: 445684,
      dataSent: 5877841,
      dataReceived: 43635731,
      callsIn: 1154261,
      callsInDuration: 2298315,
      callsOut: 978990,
      callsOutDuration: 1600329,
      smses: 131702,
    },
    {
      date: "2019-12-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13514616,
      accounts: 444181,
      dataSent: 5835069,
      dataReceived: 43290895,
      callsIn: 1156910,
      callsInDuration: 2306164,
      callsOut: 980372,
      callsOutDuration: 1606042,
      smses: 147182,
    },
    {
      date: "2019-12-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13681863,
      accounts: 444071,
      dataSent: 6122045,
      dataReceived: 45527773,
      callsIn: 1195637,
      callsInDuration: 2306295,
      callsOut: 1026450,
      callsOutDuration: 1617661,
      smses: 162988,
    },
    {
      date: "2019-12-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13899312,
      accounts: 447904,
      dataSent: 6294539,
      dataReceived: 46638111,
      callsIn: 1257253,
      callsInDuration: 2340491,
      callsOut: 1086142,
      callsOutDuration: 1656399,
      smses: 135934,
    },
    {
      date: "2019-12-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14532802,
      accounts: 457569,
      dataSent: 6793952,
      dataReceived: 49966850,
      callsIn: 1420286,
      callsInDuration: 2347688,
      callsOut: 1252870,
      callsOutDuration: 1706306,
      smses: 149568,
    },
    {
      date: "2019-12-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13995335,
      accounts: 449561,
      dataSent: 6432900,
      dataReceived: 46763553,
      callsIn: 1252179,
      callsInDuration: 2013641,
      callsOut: 1140297,
      callsOutDuration: 1513166,
      smses: 138659,
    },
    {
      date: "2019-12-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12911508,
      accounts: 437038,
      dataSent: 5832647,
      dataReceived: 42716953,
      callsIn: 960630,
      callsInDuration: 1789162,
      callsOut: 893723,
      callsOutDuration: 1344014,
      smses: 118376,
    },
    {
      date: "2019-12-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13922127,
      accounts: 452647,
      dataSent: 6314488,
      dataReceived: 46007222,
      callsIn: 1375452,
      callsInDuration: 2319948,
      callsOut: 1217040,
      callsOutDuration: 1698306,
      smses: 143457,
    },
    {
      date: "2019-12-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14195811,
      accounts: 451188,
      dataSent: 6445205,
      dataReceived: 47338291,
      callsIn: 1335985,
      callsInDuration: 2057484,
      callsOut: 1187983,
      callsOutDuration: 1555813,
      smses: 131216,
    },
    {
      date: "2019-12-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12862800,
      accounts: 432613,
      dataSent: 6139069,
      dataReceived: 43898537,
      callsIn: 775051,
      callsInDuration: 1683112,
      callsOut: 720960,
      callsOutDuration: 1273736,
      smses: 112403,
    },
    {
      date: "2019-12-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12810661,
      accounts: 437448,
      dataSent: 5822238,
      dataReceived: 43337527,
      callsIn: 1061436,
      callsInDuration: 2116250,
      callsOut: 890478,
      callsOutDuration: 1469765,
      smses: 123442,
    },
    {
      date: "2019-12-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13543328,
      accounts: 444656,
      dataSent: 6192985,
      dataReceived: 45944389,
      callsIn: 1148351,
      callsInDuration: 2136592,
      callsOut: 987793,
      callsOutDuration: 1531143,
      smses: 141531,
    },
    {
      date: "2019-12-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13593694,
      accounts: 444185,
      dataSent: 6030270,
      dataReceived: 44531774,
      callsIn: 1081090,
      callsInDuration: 1935550,
      callsOut: 971742,
      callsOutDuration: 1419319,
      smses: 139289,
    },
    {
      date: "2019-12-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12907612,
      accounts: 436328,
      dataSent: 5828555,
      dataReceived: 42665213,
      callsIn: 859247,
      callsInDuration: 1710186,
      callsOut: 789326,
      callsOutDuration: 1267824,
      smses: 123082,
    },
    {
      date: "2019-12-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13128624,
      accounts: 439994,
      dataSent: 5854688,
      dataReceived: 43496636,
      callsIn: 1078490,
      callsInDuration: 1974892,
      callsOut: 959942,
      callsOutDuration: 1451120,
      smses: 128959,
    },
    {
      date: "2019-12-31",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14383247,
      accounts: 452026,
      dataSent: 7384120,
      dataReceived: 48532769,
      callsIn: 1178659,
      callsInDuration: 1746311,
      callsOut: 1115191,
      callsOutDuration: 1420900,
      smses: 132223,
    },
    {
      date: "2020-01-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13447346,
      accounts: 434728,
      dataSent: 6611381,
      dataReceived: 46123326,
      callsIn: 776717,
      callsInDuration: 1725830,
      callsOut: 736668,
      callsOutDuration: 1326435,
      smses: 130500,
    },
    {
      date: "2020-01-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12822043,
      accounts: 440701,
      dataSent: 5881135,
      dataReceived: 43746858,
      callsIn: 941566,
      callsInDuration: 2074600,
      callsOut: 810673,
      callsOutDuration: 1451116,
      smses: 123449,
    },
    {
      date: "2020-01-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13185821,
      accounts: 445667,
      dataSent: 6020160,
      dataReceived: 44962895,
      callsIn: 1024303,
      callsInDuration: 2111915,
      callsOut: 883394,
      callsOutDuration: 1480942,
      smses: 132128,
    },
    {
      date: "2020-01-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12864847,
      accounts: 439561,
      dataSent: 5773539,
      dataReceived: 43660984,
      callsIn: 947122,
      callsInDuration: 1878397,
      callsOut: 849447,
      callsOutDuration: 1360343,
      smses: 127931,
    },
    {
      date: "2020-01-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12019646,
      accounts: 426995,
      dataSent: 5412173,
      dataReceived: 40246647,
      callsIn: 714312,
      callsInDuration: 1672827,
      callsOut: 666801,
      callsOutDuration: 1230272,
      smses: 109383,
    },
    {
      date: "2020-01-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13572036,
      accounts: 455653,
      dataSent: 6155950,
      dataReceived: 46228703,
      callsIn: 1084348,
      callsInDuration: 2230630,
      callsOut: 951870,
      callsOutDuration: 1588262,
      smses: 141489,
    },
    {
      date: "2020-01-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13314206,
      accounts: 452319,
      dataSent: 6034496,
      dataReceived: 46405347,
      callsIn: 1023053,
      callsInDuration: 2253661,
      callsOut: 879032,
      callsOutDuration: 1571517,
      smses: 128963,
    },
    {
      date: "2020-01-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13351315,
      accounts: 452481,
      dataSent: 6058119,
      dataReceived: 47166920,
      callsIn: 1028027,
      callsInDuration: 2233211,
      callsOut: 878520,
      callsOutDuration: 1552048,
      smses: 128381,
    },
    {
      date: "2020-01-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13273206,
      accounts: 453779,
      dataSent: 6137063,
      dataReceived: 47703422,
      callsIn: 1044352,
      callsInDuration: 2241204,
      callsOut: 892304,
      callsOutDuration: 1554440,
      smses: 126820,
    },
    {
      date: "2020-01-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13900559,
      accounts: 456993,
      dataSent: 6460965,
      dataReceived: 50050636,
      callsIn: 1148722,
      callsInDuration: 2222466,
      callsOut: 993536,
      callsOutDuration: 1568609,
      smses: 135779,
    },
    {
      date: "2020-01-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13302902,
      accounts: 447785,
      dataSent: 6315311,
      dataReceived: 48105687,
      callsIn: 1042926,
      callsInDuration: 1941858,
      callsOut: 936889,
      callsOutDuration: 1418691,
      smses: 128287,
    },
    {
      date: "2020-01-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12148672,
      accounts: 432073,
      dataSent: 5728901,
      dataReceived: 42892542,
      callsIn: 752762,
      callsInDuration: 1707412,
      callsOut: 695796,
      callsOutDuration: 1241791,
      smses: 109545,
    },
    {
      date: "2020-01-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13526763,
      accounts: 456967,
      dataSent: 6355072,
      dataReceived: 47460048,
      callsIn: 1065900,
      callsInDuration: 2227712,
      callsOut: 915876,
      callsOutDuration: 1549579,
      smses: 134021,
    },
    {
      date: "2020-01-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13500842,
      accounts: 455308,
      dataSent: 5907035,
      dataReceived: 44744048,
      callsIn: 1049509,
      callsInDuration: 2279540,
      callsOut: 890591,
      callsOutDuration: 1568392,
      smses: 131729,
    },
    {
      date: "2020-01-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13654155,
      accounts: 464700,
      dataSent: 6805854,
      dataReceived: 53453888,
      callsIn: 1061200,
      callsInDuration: 2286497,
      callsOut: 915997,
      callsOutDuration: 1594135,
      smses: 132154,
    },
    {
      date: "2020-01-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13686662,
      accounts: 456481,
      dataSent: 6318391,
      dataReceived: 49511243,
      callsIn: 1086631,
      callsInDuration: 2283006,
      callsOut: 928273,
      callsOutDuration: 1590650,
      smses: 131457,
    },
    {
      date: "2020-01-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14279411,
      accounts: 461532,
      dataSent: 6582298,
      dataReceived: 52387188,
      callsIn: 1202979,
      callsInDuration: 2264306,
      callsOut: 1045009,
      callsOutDuration: 1617319,
      smses: 142004,
    },
    {
      date: "2020-01-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13552185,
      accounts: 451806,
      dataSent: 6529657,
      dataReceived: 50583426,
      callsIn: 1059634,
      callsInDuration: 1939284,
      callsOut: 964403,
      callsOutDuration: 1442357,
      smses: 131448,
    },
    {
      date: "2020-01-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12513257,
      accounts: 436864,
      dataSent: 5924934,
      dataReceived: 45716534,
      callsIn: 767239,
      callsInDuration: 1709454,
      callsOut: 722188,
      callsOutDuration: 1274491,
      smses: 114374,
    },
    {
      date: "2020-01-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13586534,
      accounts: 459082,
      dataSent: 6326272,
      dataReceived: 49120551,
      callsIn: 1083337,
      callsInDuration: 2266894,
      callsOut: 944176,
      callsOutDuration: 1603672,
      smses: 136722,
    },
    {
      date: "2020-01-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13487435,
      accounts: 456833,
      dataSent: 6184542,
      dataReceived: 48479377,
      callsIn: 1056909,
      callsInDuration: 2262305,
      callsOut: 904259,
      callsOutDuration: 1578020,
      smses: 128640,
    },
    {
      date: "2020-01-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13671553,
      accounts: 456421,
      dataSent: 6282888,
      dataReceived: 49116463,
      callsIn: 1056470,
      callsInDuration: 2260184,
      callsOut: 902284,
      callsOutDuration: 1564590,
      smses: 133662,
    },
    {
      date: "2020-01-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13667494,
      accounts: 456009,
      dataSent: 6258152,
      dataReceived: 49175322,
      callsIn: 1066027,
      callsInDuration: 2280997,
      callsOut: 904945,
      callsOutDuration: 1572922,
      smses: 130503,
    },
    {
      date: "2020-01-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14048301,
      accounts: 458746,
      dataSent: 6019853,
      dataReceived: 47087045,
      callsIn: 1182290,
      callsInDuration: 2250784,
      callsOut: 1009530,
      callsOutDuration: 1581453,
      smses: 135226,
    },
    {
      date: "2020-01-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13816010,
      accounts: 454605,
      dataSent: 7010471,
      dataReceived: 54831901,
      callsIn: 1034862,
      callsInDuration: 1921807,
      callsOut: 926044,
      callsOutDuration: 1405183,
      smses: 126892,
    },
    {
      date: "2020-01-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12408929,
      accounts: 434671,
      dataSent: 5758993,
      dataReceived: 44690954,
      callsIn: 750139,
      callsInDuration: 1688347,
      callsOut: 691522,
      callsOutDuration: 1230230,
      smses: 112865,
    },
    {
      date: "2020-01-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13572140,
      accounts: 456147,
      dataSent: 6354372,
      dataReceived: 50099736,
      callsIn: 1063093,
      callsInDuration: 2269959,
      callsOut: 908180,
      callsOutDuration: 1571636,
      smses: 129376,
    },
    {
      date: "2020-01-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13495156,
      accounts: 453451,
      dataSent: 6198052,
      dataReceived: 49116496,
      callsIn: 1037262,
      callsInDuration: 2236441,
      callsOut: 876035,
      callsOutDuration: 1530447,
      smses: 126749,
    },
    {
      date: "2020-01-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13730614,
      accounts: 456512,
      dataSent: 6207258,
      dataReceived: 48992063,
      callsIn: 1045282,
      callsInDuration: 2254089,
      callsOut: 879994,
      callsOutDuration: 1547310,
      smses: 128233,
    },
    {
      date: "2020-01-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13705598,
      accounts: 454969,
      dataSent: 5921229,
      dataReceived: 46874927,
      callsIn: 1060988,
      callsInDuration: 2223879,
      callsOut: 893820,
      callsOutDuration: 1535855,
      smses: 125469,
    },
    {
      date: "2020-01-31",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 16414826,
      accounts: 463175,
      dataSent: 7114858,
      dataReceived: 56927231,
      callsIn: 1190699,
      callsInDuration: 2271668,
      callsOut: 1019993,
      callsOutDuration: 1606720,
      smses: 136267,
    },
    {
      date: "2020-02-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14590441,
      accounts: 449688,
      dataSent: 6565064,
      dataReceived: 51193149,
      callsIn: 1053401,
      callsInDuration: 1915487,
      callsOut: 946699,
      callsOutDuration: 1397809,
      smses: 126957,
    },
    {
      date: "2020-02-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 13308519,
      accounts: 434262,
      dataSent: 5793001,
      dataReceived: 45816244,
      callsIn: 776782,
      callsInDuration: 1696286,
      callsOut: 718114,
      callsOutDuration: 1246100,
      smses: 108269,
    },
    {
      date: "2020-02-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 14616626,
      accounts: 456142,
      dataSent: 6190784,
      dataReceived: 49286390,
      callsIn: 1093883,
      callsInDuration: 2274527,
      callsOut: 939715,
      callsOutDuration: 1586977,
      smses: 130695,
    },
    {
      date: "2020-02-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14693494,
      accounts: 456927,
      dataSent: 6291491,
      dataReceived: 49725461,
      callsIn: 1076582,
      callsInDuration: 2244292,
      callsOut: 926761,
      callsOutDuration: 1566961,
      smses: 128254,
    },
    {
      date: "2020-02-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 14644013,
      accounts: 454366,
      dataSent: 6249235,
      dataReceived: 48732225,
      callsIn: 1085631,
      callsInDuration: 2276439,
      callsOut: 923687,
      callsOutDuration: 1579751,
      smses: 126343,
    },
    {
      date: "2020-02-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 14990714,
      accounts: 456315,
      dataSent: 6374191,
      dataReceived: 50035632,
      callsIn: 1093438,
      callsInDuration: 2253598,
      callsOut: 949955,
      callsOutDuration: 1603198,
      smses: 129086,
    },
    {
      date: "2020-02-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 15493925,
      accounts: 461379,
      dataSent: 6684622,
      dataReceived: 52608362,
      callsIn: 1197515,
      callsInDuration: 2257185,
      callsOut: 1044391,
      callsOutDuration: 1618007,
      smses: 137587,
    },
    {
      date: "2020-02-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14629076,
      accounts: 451809,
      dataSent: 6402519,
      dataReceived: 49595620,
      callsIn: 1047545,
      callsInDuration: 1917064,
      callsOut: 951192,
      callsOutDuration: 1422157,
      smses: 129434,
    },
    {
      date: "2020-02-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 13469337,
      accounts: 436906,
      dataSent: 5444314,
      dataReceived: 41949356,
      callsIn: 770642,
      callsInDuration: 1705072,
      callsOut: 716904,
      callsOutDuration: 1257496,
      smses: 109838,
    },
    {
      date: "2020-02-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 15269440,
      accounts: 460642,
      dataSent: 6618964,
      dataReceived: 51467938,
      callsIn: 1087546,
      callsInDuration: 2272437,
      callsOut: 939186,
      callsOutDuration: 1603605,
      smses: 128507,
    },
    {
      date: "2020-02-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14009067,
      accounts: 456686,
      dataSent: 6151197,
      dataReceived: 48530713,
      callsIn: 1080560,
      callsInDuration: 2269023,
      callsOut: 916779,
      callsOutDuration: 1564953,
      smses: 125293,
    },
    {
      date: "2020-02-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13606595,
      accounts: 456483,
      dataSent: 6281827,
      dataReceived: 48774403,
      callsIn: 1085546,
      callsInDuration: 2283047,
      callsOut: 913068,
      callsOutDuration: 1572571,
      smses: 123656,
    },
    {
      date: "2020-02-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13507037,
      accounts: 457225,
      dataSent: 6394993,
      dataReceived: 49552976,
      callsIn: 1116599,
      callsInDuration: 2279823,
      callsOut: 940840,
      callsOutDuration: 1582007,
      smses: 126536,
    },
    {
      date: "2020-02-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 15038809,
      accounts: 468198,
      dataSent: 7059260,
      dataReceived: 53297381,
      callsIn: 1304790,
      callsInDuration: 2251815,
      callsOut: 1134363,
      callsOutDuration: 1615855,
      smses: 151082,
    },
    {
      date: "2020-02-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14548988,
      accounts: 455277,
      dataSent: 6278423,
      dataReceived: 47429096,
      callsIn: 1058552,
      callsInDuration: 1933425,
      callsOut: 946638,
      callsOutDuration: 1419568,
      smses: 131806,
    },
    {
      date: "2020-02-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 13495759,
      accounts: 442979,
      dataSent: 5844943,
      dataReceived: 44906447,
      callsIn: 772653,
      callsInDuration: 1694126,
      callsOut: 713201,
      callsOutDuration: 1244110,
      smses: 108387,
    },
    {
      date: "2020-02-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 14042174,
      accounts: 452425,
      dataSent: 4997367,
      dataReceived: 38415905,
      callsIn: 1107903,
      callsInDuration: 2242259,
      callsOut: 939922,
      callsOutDuration: 1552687,
      smses: 131207,
    },
    {
      date: "2020-02-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14325704,
      accounts: 461742,
      dataSent: 6290817,
      dataReceived: 49121626,
      callsIn: 1099899,
      callsInDuration: 2236487,
      callsOut: 929270,
      callsOutDuration: 1548548,
      smses: 130429,
    },
    {
      date: "2020-02-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 14453312,
      accounts: 458276,
      dataSent: 5778659,
      dataReceived: 45503098,
      callsIn: 1102566,
      callsInDuration: 2239071,
      callsOut: 927863,
      callsOutDuration: 1551904,
      smses: 130248,
    },
    {
      date: "2020-02-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 14860070,
      accounts: 461795,
      dataSent: 6830302,
      dataReceived: 53494199,
      callsIn: 1123119,
      callsInDuration: 2318975,
      callsOut: 947340,
      callsOutDuration: 1621663,
      smses: 127745,
    },
    {
      date: "2020-02-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 15086168,
      accounts: 461099,
      dataSent: 6424036,
      dataReceived: 50368006,
      callsIn: 1229830,
      callsInDuration: 2262604,
      callsOut: 1056519,
      callsOutDuration: 1612062,
      smses: 139319,
    },
    {
      date: "2020-02-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 15070747,
      accounts: 456198,
      dataSent: 6521963,
      dataReceived: 49276834,
      callsIn: 1082723,
      callsInDuration: 1872844,
      callsOut: 987818,
      callsOutDuration: 1402858,
      smses: 137769,
    },
    {
      date: "2020-02-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 14262288,
      accounts: 442162,
      dataSent: 6455318,
      dataReceived: 47922634,
      callsIn: 822054,
      callsInDuration: 1543427,
      callsOut: 777766,
      callsOutDuration: 1178949,
      smses: 125955,
    },
    {
      date: "2020-02-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13457170,
      accounts: 427945,
      dataSent: 6037685,
      dataReceived: 44184019,
      callsIn: 746168,
      callsInDuration: 1529726,
      callsOut: 681635,
      callsOutDuration: 1136989,
      smses: 121710,
    },
    {
      date: "2020-02-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12603376,
      accounts: 423436,
      dataSent: 5484577,
      dataReceived: 41962283,
      callsIn: 686065,
      callsInDuration: 1551365,
      callsOut: 621705,
      callsOutDuration: 1126506,
      smses: 105881,
    },
    {
      date: "2020-02-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 14121321,
      accounts: 454283,
      dataSent: 5225323,
      dataReceived: 41160523,
      callsIn: 1068397,
      callsInDuration: 2146391,
      callsOut: 899770,
      callsOutDuration: 1477144,
      smses: 136883,
    },
    {
      date: "2020-02-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 15481224,
      accounts: 464788,
      dataSent: 6565357,
      dataReceived: 51076941,
      callsIn: 1107500,
      callsInDuration: 2270990,
      callsOut: 931346,
      callsOutDuration: 1562803,
      smses: 136410,
    },
    {
      date: "2020-02-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14956763,
      accounts: 462012,
      dataSent: 6188646,
      dataReceived: 48067757,
      callsIn: 1194237,
      callsInDuration: 2266212,
      callsOut: 1021414,
      callsOutDuration: 1599776,
      smses: 144130,
    },
    {
      date: "2020-02-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14523073,
      accounts: 453682,
      dataSent: 6388965,
      dataReceived: 50340671,
      callsIn: 1043143,
      callsInDuration: 2033601,
      callsOut: 935739,
      callsOutDuration: 1490366,
      smses: 134792,
    },
    {
      date: "2020-03-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12962958,
      accounts: 432433,
      dataSent: 5514245,
      dataReceived: 43852314,
      callsIn: 745473,
      callsInDuration: 1710196,
      callsOut: 686633,
      callsOutDuration: 1248386,
      smses: 112672,
    },
    {
      date: "2020-03-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 14225198,
      accounts: 454298,
      dataSent: 5981670,
      dataReceived: 46313578,
      callsIn: 1098011,
      callsInDuration: 2292373,
      callsOut: 939379,
      callsOutDuration: 1610803,
      smses: 129965,
    },
    {
      date: "2020-03-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14272886,
      accounts: 455355,
      dataSent: 5812207,
      dataReceived: 44601662,
      callsIn: 1077926,
      callsInDuration: 2267602,
      callsOut: 914097,
      callsOutDuration: 1580518,
      smses: 129549,
    },
    {
      date: "2020-03-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 14694310,
      accounts: 458828,
      dataSent: 5805033,
      dataReceived: 45446078,
      callsIn: 1082461,
      callsInDuration: 2204861,
      callsOut: 916856,
      callsOutDuration: 1538926,
      smses: 129017,
    },
    {
      date: "2020-03-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 15137440,
      accounts: 459415,
      dataSent: 6491825,
      dataReceived: 50155434,
      callsIn: 1070656,
      callsInDuration: 2216300,
      callsOut: 904563,
      callsOutDuration: 1537810,
      smses: 125054,
    },
    {
      date: "2020-03-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 15057717,
      accounts: 462761,
      dataSent: 6654790,
      dataReceived: 51869004,
      callsIn: 1166932,
      callsInDuration: 2245631,
      callsOut: 1010725,
      callsOutDuration: 1609427,
      smses: 135044,
    },
    {
      date: "2020-03-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14590028,
      accounts: 456004,
      dataSent: 6370756,
      dataReceived: 48446624,
      callsIn: 1032117,
      callsInDuration: 1876118,
      callsOut: 961724,
      callsOutDuration: 1419064,
      smses: 130957,
    },
    {
      date: "2020-03-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 13554775,
      accounts: 442550,
      dataSent: 5861579,
      dataReceived: 44079157,
      callsIn: 773419,
      callsInDuration: 1658587,
      callsOut: 733211,
      callsOutDuration: 1258412,
      smses: 115697,
    },
    {
      date: "2020-03-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 14452332,
      accounts: 458602,
      dataSent: 6219961,
      dataReceived: 47742233,
      callsIn: 1068571,
      callsInDuration: 2257580,
      callsOut: 930251,
      callsOutDuration: 1624523,
      smses: 128303,
    },
    {
      date: "2020-03-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14459517,
      accounts: 457904,
      dataSent: 6207508,
      dataReceived: 46893149,
      callsIn: 1073554,
      callsInDuration: 2273755,
      callsOut: 912520,
      callsOutDuration: 1597775,
      smses: 124203,
    },
    {
      date: "2020-03-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 14453352,
      accounts: 457726,
      dataSent: 6073907,
      dataReceived: 46113972,
      callsIn: 1057909,
      callsInDuration: 2244406,
      callsOut: 892293,
      callsOutDuration: 1572035,
      smses: 123687,
    },
    {
      date: "2020-03-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 14699290,
      accounts: 458311,
      dataSent: 6047128,
      dataReceived: 45339877,
      callsIn: 1113254,
      callsInDuration: 2377652,
      callsOut: 946534,
      callsOutDuration: 1675139,
      smses: 123620,
    },
    {
      date: "2020-03-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14623610,
      accounts: 456088,
      dataSent: 6337656,
      dataReceived: 47659436,
      callsIn: 1129335,
      callsInDuration: 2271369,
      callsOut: 958921,
      callsOutDuration: 1615053,
      smses: 128095,
    },
    {
      date: "2020-03-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13991843,
      accounts: 448469,
      dataSent: 5808816,
      dataReceived: 44879043,
      callsIn: 989986,
      callsInDuration: 1993831,
      callsOut: 894242,
      callsOutDuration: 1469395,
      smses: 118507,
    },
    {
      date: "2020-03-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12752471,
      accounts: 427576,
      dataSent: 5052127,
      dataReceived: 38196274,
      callsIn: 730011,
      callsInDuration: 1828692,
      callsOut: 676765,
      callsOutDuration: 1339882,
      smses: 105377,
    },
    {
      date: "2020-03-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 15086499,
      accounts: 439161,
      dataSent: 6104629,
      dataReceived: 47617175,
      callsIn: 1117131,
      callsInDuration: 2541995,
      callsOut: 959666,
      callsOutDuration: 1789613,
      smses: 123677,
    },
    {
      date: "2020-03-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11924517,
      accounts: 405218,
      dataSent: 5463989,
      dataReceived: 41922517,
      callsIn: 765362,
      callsInDuration: 2236001,
      callsOut: 660714,
      callsOutDuration: 1557856,
      smses: 103346,
    },
    {
      date: "2020-03-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11014928,
      accounts: 382464,
      dataSent: 5361134,
      dataReceived: 41484987,
      callsIn: 584399,
      callsInDuration: 2079924,
      callsOut: 499033,
      callsOutDuration: 1400688,
      smses: 88607,
    },
    {
      date: "2020-03-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 8803346,
      accounts: 363399,
      dataSent: 5209332,
      dataReceived: 37569568,
      callsIn: 523784,
      callsInDuration: 1982627,
      callsOut: 450219,
      callsOutDuration: 1338822,
      smses: 82504,
    },
    {
      date: "2020-03-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10805070,
      accounts: 376872,
      dataSent: 6113881,
      dataReceived: 43481194,
      callsIn: 521100,
      callsInDuration: 2017628,
      callsOut: 452792,
      callsOutDuration: 1371780,
      smses: 85972,
    },
    {
      date: "2020-03-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10333285,
      accounts: 367047,
      dataSent: 5955744,
      dataReceived: 42304307,
      callsIn: 472381,
      callsInDuration: 1964252,
      callsOut: 422317,
      callsOutDuration: 1337069,
      smses: 81490,
    },
    {
      date: "2020-03-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10052650,
      accounts: 359501,
      dataSent: 5738118,
      dataReceived: 39432415,
      callsIn: 429784,
      callsInDuration: 1940097,
      callsOut: 378464,
      callsOutDuration: 1305008,
      smses: 77836,
    },
    {
      date: "2020-03-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11386526,
      accounts: 360953,
      dataSent: 7031967,
      dataReceived: 51658861,
      callsIn: 503844,
      callsInDuration: 2078169,
      callsOut: 426089,
      callsOutDuration: 1365714,
      smses: 82552,
    },
    {
      date: "2020-03-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11466310,
      accounts: 368897,
      dataSent: 6354363,
      dataReceived: 45834394,
      callsIn: 500952,
      callsInDuration: 2133426,
      callsOut: 417706,
      callsOutDuration: 1394793,
      smses: 81218,
    },
    {
      date: "2020-03-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10501528,
      accounts: 345884,
      dataSent: 5376203,
      dataReceived: 37796676,
      callsIn: 447783,
      callsInDuration: 2021212,
      callsOut: 363231,
      callsOutDuration: 1285631,
      smses: 114169,
    },
    {
      date: "2020-03-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10555722,
      accounts: 350633,
      dataSent: 6969902,
      dataReceived: 50184341,
      callsIn: 453734,
      callsInDuration: 2030295,
      callsOut: 368033,
      callsOutDuration: 1294963,
      smses: 75360,
    },
    {
      date: "2020-03-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10231639,
      accounts: 345616,
      dataSent: 6075260,
      dataReceived: 41936395,
      callsIn: 465481,
      callsInDuration: 2048955,
      callsOut: 381763,
      callsOutDuration: 1320376,
      smses: 76657,
    },
    {
      date: "2020-03-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10001164,
      accounts: 342172,
      dataSent: 5886025,
      dataReceived: 40281003,
      callsIn: 436924,
      callsInDuration: 1983635,
      callsOut: 371618,
      callsOutDuration: 1302070,
      smses: 74562,
    },
    {
      date: "2020-03-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9531581,
      accounts: 332389,
      dataSent: 5640995,
      dataReceived: 38286034,
      callsIn: 395486,
      callsInDuration: 1942203,
      callsOut: 336309,
      callsOutDuration: 1275505,
      smses: 68646,
    },
    {
      date: "2020-03-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9811760,
      accounts: 335180,
      dataSent: 5120387,
      dataReceived: 34114717,
      callsIn: 486208,
      callsInDuration: 2053721,
      callsOut: 396727,
      callsOutDuration: 1302297,
      smses: 73198,
    },
    {
      date: "2020-03-31",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10088710,
      accounts: 341891,
      dataSent: 6314939,
      dataReceived: 42871806,
      callsIn: 488826,
      callsInDuration: 2086148,
      callsOut: 401938,
      callsOutDuration: 1324205,
      smses: 73322,
    },
    {
      date: "2020-04-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 9927476,
      accounts: 337505,
      dataSent: 5310330,
      dataReceived: 35132573,
      callsIn: 506752,
      callsInDuration: 2153511,
      callsOut: 420749,
      callsOutDuration: 1373050,
      smses: 74941,
    },
    {
      date: "2020-04-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 9977215,
      accounts: 337578,
      dataSent: 6667040,
      dataReceived: 47809769,
      callsIn: 512141,
      callsInDuration: 2183069,
      callsOut: 420602,
      callsOutDuration: 1388546,
      smses: 73462,
    },
    {
      date: "2020-04-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 9651655,
      accounts: 334384,
      dataSent: 5814440,
      dataReceived: 41949306,
      callsIn: 521073,
      callsInDuration: 2141083,
      callsOut: 431288,
      callsOutDuration: 1365105,
      smses: 73486,
    },
    {
      date: "2020-04-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9511363,
      accounts: 329821,
      dataSent: 5822742,
      dataReceived: 42156622,
      callsIn: 479106,
      callsInDuration: 2020443,
      callsOut: 408537,
      callsOutDuration: 1315001,
      smses: 69052,
    },
    {
      date: "2020-04-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9310772,
      accounts: 323530,
      dataSent: 5603926,
      dataReceived: 39782790,
      callsIn: 417150,
      callsInDuration: 1908073,
      callsOut: 354183,
      callsOutDuration: 1227348,
      smses: 69182,
    },
    {
      date: "2020-04-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 9940265,
      accounts: 332720,
      dataSent: 6321428,
      dataReceived: 45623056,
      callsIn: 500406,
      callsInDuration: 2080473,
      callsOut: 408435,
      callsOutDuration: 1292717,
      smses: 72861,
    },
    {
      date: "2020-04-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11945492,
      accounts: 381700,
      dataSent: 6975024,
      dataReceived: 50447607,
      callsIn: 540321,
      callsInDuration: 2110914,
      callsOut: 419736,
      callsOutDuration: 1310565,
      smses: 77493,
    },
    {
      date: "2020-04-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12418766,
      accounts: 365842,
      dataSent: 7416161,
      dataReceived: 53590858,
      callsIn: 545618,
      callsInDuration: 2110187,
      callsOut: 413572,
      callsOutDuration: 1289024,
      smses: 69244,
    },
    {
      date: "2020-04-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13161850,
      accounts: 398126,
      dataSent: 7066867,
      dataReceived: 50069332,
      callsIn: 550056,
      callsInDuration: 2091380,
      callsOut: 414547,
      callsOutDuration: 1280317,
      smses: 69183,
    },
    {
      date: "2020-04-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12467613,
      accounts: 391229,
      dataSent: 6766858,
      dataReceived: 47680979,
      callsIn: 501305,
      callsInDuration: 1948305,
      callsOut: 385683,
      callsOutDuration: 1226087,
      smses: 64371,
    },
    {
      date: "2020-04-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 11497400,
      accounts: 374345,
      dataSent: 6412474,
      dataReceived: 45299303,
      callsIn: 380544,
      callsInDuration: 1830657,
      callsOut: 287535,
      callsOutDuration: 1121675,
      smses: 58823,
    },
    {
      date: "2020-04-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11137550,
      accounts: 370766,
      dataSent: 6011264,
      dataReceived: 41882297,
      callsIn: 358148,
      callsInDuration: 1803554,
      callsOut: 270424,
      callsOutDuration: 1106885,
      smses: 59045,
    },
    {
      date: "2020-04-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11973131,
      accounts: 390262,
      dataSent: 6306623,
      dataReceived: 43267158,
      callsIn: 566685,
      callsInDuration: 2102290,
      callsOut: 417302,
      callsOutDuration: 1256537,
      smses: 72213,
    },
    {
      date: "2020-04-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11738565,
      accounts: 385413,
      dataSent: 6370675,
      dataReceived: 43381197,
      callsIn: 561977,
      callsInDuration: 2071859,
      callsOut: 407999,
      callsOutDuration: 1238864,
      smses: 69246,
    },
    {
      date: "2020-04-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11734725,
      accounts: 382150,
      dataSent: 6271474,
      dataReceived: 42419640,
      callsIn: 572432,
      callsInDuration: 2143824,
      callsOut: 414577,
      callsOutDuration: 1266012,
      smses: 66637,
    },
    {
      date: "2020-04-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11747852,
      accounts: 384436,
      dataSent: 6292562,
      dataReceived: 43109758,
      callsIn: 588185,
      callsInDuration: 2129862,
      callsOut: 422238,
      callsOutDuration: 1262408,
      smses: 66134,
    },
    {
      date: "2020-04-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11780653,
      accounts: 385826,
      dataSent: 6374272,
      dataReceived: 44052848,
      callsIn: 597700,
      callsInDuration: 2100057,
      callsOut: 439264,
      callsOutDuration: 1260233,
      smses: 67642,
    },
    {
      date: "2020-04-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10851958,
      accounts: 370595,
      dataSent: 6021074,
      dataReceived: 42532703,
      callsIn: 431998,
      callsInDuration: 1861977,
      callsOut: 323180,
      callsOutDuration: 1122161,
      smses: 82947,
    },
    {
      date: "2020-04-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10673780,
      accounts: 365779,
      dataSent: 5537196,
      dataReceived: 38473347,
      callsIn: 391701,
      callsInDuration: 1826133,
      callsOut: 298108,
      callsOutDuration: 1120779,
      smses: 60154,
    },
    {
      date: "2020-04-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11904779,
      accounts: 384765,
      dataSent: 6949494,
      dataReceived: 48446516,
      callsIn: 624235,
      callsInDuration: 2160187,
      callsOut: 459451,
      callsOutDuration: 1300081,
      smses: 67814,
    },
    {
      date: "2020-04-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11339304,
      accounts: 375038,
      dataSent: 5967534,
      dataReceived: 42456951,
      callsIn: 575332,
      callsInDuration: 2126504,
      callsOut: 424717,
      callsOutDuration: 1263321,
      smses: 66615,
    },
    {
      date: "2020-04-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11363848,
      accounts: 374788,
      dataSent: 5774001,
      dataReceived: 41833756,
      callsIn: 553823,
      callsInDuration: 2130996,
      callsOut: 425852,
      callsOutDuration: 1280449,
      smses: 64826,
    },
    {
      date: "2020-04-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11357357,
      accounts: 373562,
      dataSent: 6021704,
      dataReceived: 42157265,
      callsIn: 551888,
      callsInDuration: 2145432,
      callsOut: 424481,
      callsOutDuration: 1271957,
      smses: 66986,
    },
    {
      date: "2020-04-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11340129,
      accounts: 374273,
      dataSent: 6404544,
      dataReceived: 43842968,
      callsIn: 572389,
      callsInDuration: 2138555,
      callsOut: 439002,
      callsOutDuration: 1274822,
      smses: 66691,
    },
    {
      date: "2020-04-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10582808,
      accounts: 360714,
      dataSent: 6293428,
      dataReceived: 43846362,
      callsIn: 417145,
      callsInDuration: 1903432,
      callsOut: 330667,
      callsOutDuration: 1149988,
      smses: 57820,
    },
    {
      date: "2020-04-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10148887,
      accounts: 354014,
      dataSent: 5994023,
      dataReceived: 42155361,
      callsIn: 367265,
      callsInDuration: 1839033,
      callsOut: 295907,
      callsOutDuration: 1133650,
      smses: 57658,
    },
    {
      date: "2020-04-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11074050,
      accounts: 371255,
      dataSent: 6213392,
      dataReceived: 42622888,
      callsIn: 579743,
      callsInDuration: 2176368,
      callsOut: 445073,
      callsOutDuration: 1284883,
      smses: 68878,
    },
    {
      date: "2020-04-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11061009,
      accounts: 370380,
      dataSent: 6188161,
      dataReceived: 42587386,
      callsIn: 566618,
      callsInDuration: 2161745,
      callsOut: 431780,
      callsOutDuration: 1287623,
      smses: 67293,
    },
    {
      date: "2020-04-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11061677,
      accounts: 370881,
      dataSent: 6162377,
      dataReceived: 42201131,
      callsIn: 576340,
      callsInDuration: 2179639,
      callsOut: 437377,
      callsOutDuration: 1277879,
      smses: 68385,
    },
    {
      date: "2020-04-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11386619,
      accounts: 379283,
      dataSent: 6643647,
      dataReceived: 44728577,
      callsIn: 595176,
      callsInDuration: 2249732,
      callsOut: 460491,
      callsOutDuration: 1333724,
      smses: 68309,
    },
    {
      date: "2020-05-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11198522,
      accounts: 373246,
      dataSent: 6348346,
      dataReceived: 43950756,
      callsIn: 569715,
      callsInDuration: 2060138,
      callsOut: 454821,
      callsOutDuration: 1263578,
      smses: 68103,
    },
    {
      date: "2020-05-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 10580333,
      accounts: 361937,
      dataSent: 6368543,
      dataReceived: 44134505,
      callsIn: 453233,
      callsInDuration: 1949415,
      callsOut: 361771,
      callsOutDuration: 1191813,
      smses: 64718,
    },
    {
      date: "2020-05-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10193249,
      accounts: 354237,
      dataSent: 5988337,
      dataReceived: 41441409,
      callsIn: 398960,
      callsInDuration: 1897489,
      callsOut: 317807,
      callsOutDuration: 1151044,
      smses: 62039,
    },
    {
      date: "2020-05-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11288816,
      accounts: 374741,
      dataSent: 6255216,
      dataReceived: 42209114,
      callsIn: 645171,
      callsInDuration: 2275089,
      callsOut: 508272,
      callsOutDuration: 1362391,
      smses: 73756,
    },
    {
      date: "2020-05-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11138593,
      accounts: 373127,
      dataSent: 6329899,
      dataReceived: 42485721,
      callsIn: 635051,
      callsInDuration: 2284418,
      callsOut: 494441,
      callsOutDuration: 1379969,
      smses: 72937,
    },
    {
      date: "2020-05-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11044963,
      accounts: 373123,
      dataSent: 6285003,
      dataReceived: 42583312,
      callsIn: 640427,
      callsInDuration: 2252230,
      callsOut: 500413,
      callsOutDuration: 1360255,
      smses: 71781,
    },
    {
      date: "2020-05-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10711399,
      accounts: 363795,
      dataSent: 6100133,
      dataReceived: 41863512,
      callsIn: 646682,
      callsInDuration: 2239498,
      callsOut: 506652,
      callsOutDuration: 1361241,
      smses: 74681,
    },
    {
      date: "2020-05-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10602603,
      accounts: 360443,
      dataSent: 6762353,
      dataReceived: 45558113,
      callsIn: 689760,
      callsInDuration: 2234866,
      callsOut: 545024,
      callsOutDuration: 1381013,
      smses: 75756,
    },
    {
      date: "2020-05-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9892798,
      accounts: 338402,
      dataSent: 6532642,
      dataReceived: 44463838,
      callsIn: 546897,
      callsInDuration: 1954854,
      callsOut: 446215,
      callsOutDuration: 1227063,
      smses: 69235,
    },
    {
      date: "2020-05-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10163379,
      accounts: 334231,
      dataSent: 7186624,
      dataReceived: 46652226,
      callsIn: 548160,
      callsInDuration: 1980689,
      callsOut: 494844,
      callsOutDuration: 1422508,
      smses: 73851,
    },
    {
      date: "2020-05-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10072128,
      accounts: 339266,
      dataSent: 6106582,
      dataReceived: 41877396,
      callsIn: 645038,
      callsInDuration: 2195277,
      callsOut: 507113,
      callsOutDuration: 1334640,
      smses: 75043,
    },
    {
      date: "2020-05-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10190926,
      accounts: 338707,
      dataSent: 6162398,
      dataReceived: 42017852,
      callsIn: 644780,
      callsInDuration: 2194003,
      callsOut: 503545,
      callsOutDuration: 1333471,
      smses: 75061,
    },
    {
      date: "2020-05-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10270074,
      accounts: 339364,
      dataSent: 6187908,
      dataReceived: 42112865,
      callsIn: 649812,
      callsInDuration: 2217310,
      callsOut: 507767,
      callsOutDuration: 1352458,
      smses: 74240,
    },
    {
      date: "2020-05-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10349616,
      accounts: 340497,
      dataSent: 6260996,
      dataReceived: 42581979,
      callsIn: 645435,
      callsInDuration: 2209423,
      callsOut: 511341,
      callsOutDuration: 1373423,
      smses: 75300,
    },
    {
      date: "2020-05-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 10578795,
      accounts: 343638,
      dataSent: 6564957,
      dataReceived: 44280382,
      callsIn: 683082,
      callsInDuration: 2172731,
      callsOut: 554548,
      callsOutDuration: 1371305,
      smses: 78016,
    },
    {
      date: "2020-05-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9997146,
      accounts: 333846,
      dataSent: 6329476,
      dataReceived: 44527229,
      callsIn: 532447,
      callsInDuration: 1951934,
      callsOut: 443791,
      callsOutDuration: 1247238,
      smses: 70737,
    },
    {
      date: "2020-05-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9508964,
      accounts: 323377,
      dataSent: 6089317,
      dataReceived: 42798727,
      callsIn: 437061,
      callsInDuration: 1851960,
      callsOut: 368186,
      callsOutDuration: 1178332,
      smses: 65832,
    },
    {
      date: "2020-05-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10548004,
      accounts: 344313,
      dataSent: 6351253,
      dataReceived: 43496251,
      callsIn: 689517,
      callsInDuration: 2245012,
      callsOut: 558024,
      callsOutDuration: 1400888,
      smses: 78604,
    },
    {
      date: "2020-05-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10599933,
      accounts: 344156,
      dataSent: 6144680,
      dataReceived: 42264891,
      callsIn: 667043,
      callsInDuration: 2227930,
      callsOut: 534515,
      callsOutDuration: 1378114,
      smses: 75927,
    },
    {
      date: "2020-05-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 10847595,
      accounts: 350354,
      dataSent: 6454061,
      dataReceived: 44004882,
      callsIn: 699646,
      callsInDuration: 2221026,
      callsOut: 562655,
      callsOutDuration: 1389467,
      smses: 76123,
    },
    {
      date: "2020-05-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 10669879,
      accounts: 348019,
      dataSent: 5931577,
      dataReceived: 40202996,
      callsIn: 694708,
      callsInDuration: 2202822,
      callsOut: 556585,
      callsOutDuration: 1371982,
      smses: 77507,
    },
    {
      date: "2020-05-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11292787,
      accounts: 355718,
      dataSent: 7287278,
      dataReceived: 50043073,
      callsIn: 744753,
      callsInDuration: 2165531,
      callsOut: 593863,
      callsOutDuration: 1382463,
      smses: 80889,
    },
    {
      date: "2020-05-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 9927942,
      accounts: 343770,
      dataSent: 6389912,
      dataReceived: 45133276,
      callsIn: 601400,
      callsInDuration: 1901378,
      callsOut: 496044,
      callsOutDuration: 1236207,
      smses: 74777,
    },
    {
      date: "2020-05-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 9816660,
      accounts: 332897,
      dataSent: 6087013,
      dataReceived: 43440636,
      callsIn: 480143,
      callsInDuration: 1838095,
      callsOut: 395739,
      callsOutDuration: 1191100,
      smses: 67910,
    },
    {
      date: "2020-05-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 10767849,
      accounts: 350685,
      dataSent: 6277487,
      dataReceived: 44357024,
      callsIn: 740429,
      callsInDuration: 2223900,
      callsOut: 578652,
      callsOutDuration: 1382940,
      smses: 77926,
    },
    {
      date: "2020-05-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 10937660,
      accounts: 353618,
      dataSent: 6248507,
      dataReceived: 43695346,
      callsIn: 753889,
      callsInDuration: 2254421,
      callsOut: 578322,
      callsOutDuration: 1398846,
      smses: 79554,
    },
    {
      date: "2020-05-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 11072954,
      accounts: 355589,
      dataSent: 5876884,
      dataReceived: 41433746,
      callsIn: 747383,
      callsInDuration: 2247503,
      callsOut: 575642,
      callsOutDuration: 1393182,
      smses: 81387,
    },
    {
      date: "2020-05-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 11716414,
      accounts: 362871,
      dataSent: 6836465,
      dataReceived: 48684997,
      callsIn: 752691,
      callsInDuration: 2242186,
      callsOut: 580016,
      callsOutDuration: 1392186,
      smses: 78296,
    },
    {
      date: "2020-05-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 11552122,
      accounts: 364078,
      dataSent: 6846768,
      dataReceived: 48107463,
      callsIn: 790020,
      callsInDuration: 2217808,
      callsOut: 618476,
      callsOutDuration: 1412839,
      smses: 82071,
    },
    {
      date: "2020-05-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 11174187,
      accounts: 357758,
      dataSent: 6751406,
      dataReceived: 48218712,
      callsIn: 692386,
      callsInDuration: 1988982,
      callsOut: 569778,
      callsOutDuration: 1311202,
      smses: 77405,
    },
    {
      date: "2020-05-31",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10304838,
      accounts: 344011,
      dataSent: 6196495,
      dataReceived: 44769219,
      callsIn: 524187,
      callsInDuration: 1852855,
      callsOut: 435157,
      callsOutDuration: 1214837,
      smses: 71774,
    },
    {
      date: "2020-06-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 11916659,
      accounts: 371466,
      dataSent: 6921458,
      dataReceived: 48294898,
      callsIn: 865939,
      callsInDuration: 2291538,
      callsOut: 694838,
      callsOutDuration: 1492224,
      smses: 87172,
    },
    {
      date: "2020-06-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 11823671,
      accounts: 369647,
      dataSent: 6662934,
      dataReceived: 46743610,
      callsIn: 801773,
      callsInDuration: 2263126,
      callsOut: 630970,
      callsOutDuration: 1456608,
      smses: 84295,
    },
    {
      date: "2020-06-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12016710,
      accounts: 374676,
      dataSent: 6710719,
      dataReceived: 48729531,
      callsIn: 811686,
      callsInDuration: 2296307,
      callsOut: 639304,
      callsOutDuration: 1469956,
      smses: 85528,
    },
    {
      date: "2020-06-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12259196,
      accounts: 379731,
      dataSent: 6822112,
      dataReceived: 48583622,
      callsIn: 826408,
      callsInDuration: 2283783,
      callsOut: 648282,
      callsOutDuration: 1462241,
      smses: 84670,
    },
    {
      date: "2020-06-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12372374,
      accounts: 381665,
      dataSent: 7035483,
      dataReceived: 50032139,
      callsIn: 845476,
      callsInDuration: 2212325,
      callsOut: 677777,
      callsOutDuration: 1448367,
      smses: 89211,
    },
    {
      date: "2020-06-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12038160,
      accounts: 380761,
      dataSent: 6724844,
      dataReceived: 49447806,
      callsIn: 757566,
      callsInDuration: 1952601,
      callsOut: 642428,
      callsOutDuration: 1338496,
      smses: 84157,
    },
    {
      date: "2020-06-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10796502,
      accounts: 360243,
      dataSent: 6193697,
      dataReceived: 45490244,
      callsIn: 528249,
      callsInDuration: 1780126,
      callsOut: 457207,
      callsOutDuration: 1214188,
      smses: 75195,
    },
    {
      date: "2020-06-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12258792,
      accounts: 384736,
      dataSent: 6727780,
      dataReceived: 47870249,
      callsIn: 846105,
      callsInDuration: 2219212,
      callsOut: 680624,
      callsOutDuration: 1456861,
      smses: 88499,
    },
    {
      date: "2020-06-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12310637,
      accounts: 384128,
      dataSent: 6629665,
      dataReceived: 47407695,
      callsIn: 815513,
      callsInDuration: 2205737,
      callsOut: 650792,
      callsOutDuration: 1435435,
      smses: 83573,
    },
    {
      date: "2020-06-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12317234,
      accounts: 385943,
      dataSent: 6732030,
      dataReceived: 47971905,
      callsIn: 825989,
      callsInDuration: 2230933,
      callsOut: 655775,
      callsOutDuration: 1452013,
      smses: 88107,
    },
    {
      date: "2020-06-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12245267,
      accounts: 386529,
      dataSent: 6638354,
      dataReceived: 47542893,
      callsIn: 817973,
      callsInDuration: 2164445,
      callsOut: 648326,
      callsOutDuration: 1432097,
      smses: 89669,
    },
    {
      date: "2020-06-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 12347487,
      accounts: 392515,
      dataSent: 6972070,
      dataReceived: 49385634,
      callsIn: 858769,
      callsInDuration: 2173176,
      callsOut: 698050,
      callsOutDuration: 1464098,
      smses: 90545,
    },
    {
      date: "2020-06-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12417168,
      accounts: 392360,
      dataSent: 6760225,
      dataReceived: 49161132,
      callsIn: 779637,
      callsInDuration: 1926112,
      callsOut: 666953,
      callsOutDuration: 1365448,
      smses: 88920,
    },
    {
      date: "2020-06-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 10900556,
      accounts: 369962,
      dataSent: 5701231,
      dataReceived: 40961072,
      callsIn: 525251,
      callsInDuration: 1720051,
      callsOut: 457520,
      callsOutDuration: 1191833,
      smses: 77525,
    },
    {
      date: "2020-06-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13015051,
      accounts: 399124,
      dataSent: 7577131,
      dataReceived: 53765981,
      callsIn: 856900,
      callsInDuration: 2211351,
      callsOut: 702178,
      callsOutDuration: 1509843,
      smses: 94239,
    },
    {
      date: "2020-06-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12668777,
      accounts: 396861,
      dataSent: 6890135,
      dataReceived: 49220299,
      callsIn: 836603,
      callsInDuration: 2204026,
      callsOut: 678965,
      callsOutDuration: 1500734,
      smses: 91608,
    },
    {
      date: "2020-06-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12586538,
      accounts: 396255,
      dataSent: 6885774,
      dataReceived: 49445173,
      callsIn: 829254,
      callsInDuration: 2223700,
      callsOut: 670849,
      callsOutDuration: 1507889,
      smses: 90343,
    },
    {
      date: "2020-06-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 12825896,
      accounts: 400128,
      dataSent: 6265086,
      dataReceived: 44358937,
      callsIn: 837275,
      callsInDuration: 2122430,
      callsOut: 680517,
      callsOutDuration: 1437795,
      smses: 86993,
    },
    {
      date: "2020-06-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13626676,
      accounts: 410959,
      dataSent: 8317577,
      dataReceived: 60679868,
      callsIn: 910467,
      callsInDuration: 2184219,
      callsOut: 754243,
      callsOutDuration: 1528715,
      smses: 93953,
    },
    {
      date: "2020-06-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13256766,
      accounts: 411849,
      dataSent: 7368986,
      dataReceived: 53999214,
      callsIn: 857775,
      callsInDuration: 1919561,
      callsOut: 752890,
      callsOutDuration: 1417601,
      smses: 95523,
    },
    {
      date: "2020-06-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12039687,
      accounts: 387764,
      dataSent: 7288216,
      dataReceived: 51015379,
      callsIn: 619450,
      callsInDuration: 1741695,
      callsOut: 584208,
      callsOutDuration: 1377381,
      smses: 84183,
    },
    {
      date: "2020-06-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12597710,
      accounts: 401615,
      dataSent: 6995894,
      dataReceived: 50984490,
      callsIn: 820301,
      callsInDuration: 2160423,
      callsOut: 681858,
      callsOutDuration: 1495068,
      smses: 89949,
    },
    {
      date: "2020-06-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 12604091,
      accounts: 402893,
      dataSent: 6823039,
      dataReceived: 49886039,
      callsIn: 806331,
      callsInDuration: 2144412,
      callsOut: 666611,
      callsOutDuration: 1479384,
      smses: 88115,
    },
    {
      date: "2020-06-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 12930001,
      accounts: 408213,
      dataSent: 6605251,
      dataReceived: 48392955,
      callsIn: 812514,
      callsInDuration: 2159229,
      callsOut: 667229,
      callsOutDuration: 1485666,
      smses: 87694,
    },
    {
      date: "2020-06-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13151487,
      accounts: 410014,
      dataSent: 7933957,
      dataReceived: 57977525,
      callsIn: 822330,
      callsInDuration: 2198976,
      callsOut: 670850,
      callsOutDuration: 1521804,
      smses: 87546,
    },
    {
      date: "2020-06-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13112050,
      accounts: 411322,
      dataSent: 7383495,
      dataReceived: 54176232,
      callsIn: 872970,
      callsInDuration: 2176376,
      callsOut: 719557,
      callsOutDuration: 1532606,
      smses: 92622,
    },
    {
      date: "2020-06-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12994401,
      accounts: 411382,
      dataSent: 7305009,
      dataReceived: 54456045,
      callsIn: 811097,
      callsInDuration: 1918065,
      callsOut: 708342,
      callsOutDuration: 1407998,
      smses: 89986,
    },
    {
      date: "2020-06-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11332302,
      accounts: 385571,
      dataSent: 6668289,
      dataReceived: 49203550,
      callsIn: 538002,
      callsInDuration: 1730164,
      callsOut: 478767,
      callsOutDuration: 1260092,
      smses: 74778,
    },
    {
      date: "2020-06-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 12905596,
      accounts: 410491,
      dataSent: 7300105,
      dataReceived: 52420184,
      callsIn: 855821,
      callsInDuration: 2227585,
      callsOut: 712989,
      callsOutDuration: 1560622,
      smses: 92341,
    },
    {
      date: "2020-06-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13122766,
      accounts: 413482,
      dataSent: 7253152,
      dataReceived: 52635789,
      callsIn: 851813,
      callsInDuration: 2197757,
      callsOut: 708681,
      callsOutDuration: 1544139,
      smses: 98317,
    },
    {
      date: "2020-07-01",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13191790,
      accounts: 415317,
      dataSent: 7280627,
      dataReceived: 52272661,
      callsIn: 877637,
      callsInDuration: 2306842,
      callsOut: 725647,
      callsOutDuration: 1602228,
      smses: 99905,
    },
    {
      date: "2020-07-02",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13137602,
      accounts: 415208,
      dataSent: 7313893,
      dataReceived: 52994718,
      callsIn: 875692,
      callsInDuration: 2265927,
      callsOut: 725733,
      callsOutDuration: 1588509,
      smses: 94745,
    },
    {
      date: "2020-07-03",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13645661,
      accounts: 421277,
      dataSent: 7322756,
      dataReceived: 53484655,
      callsIn: 926754,
      callsInDuration: 2247898,
      callsOut: 782229,
      callsOutDuration: 1595095,
      smses: 97063,
    },
    {
      date: "2020-07-04",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 12853617,
      accounts: 419202,
      dataSent: 5895998,
      dataReceived: 43017181,
      callsIn: 849314,
      callsInDuration: 1942701,
      callsOut: 762632,
      callsOutDuration: 1465563,
      smses: 93705,
    },
    {
      date: "2020-07-05",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 11858966,
      accounts: 406012,
      dataSent: 7602932,
      dataReceived: 56930497,
      callsIn: 588867,
      callsInDuration: 1734161,
      callsOut: 540124,
      callsOutDuration: 1306237,
      smses: 80654,
    },
    {
      date: "2020-07-06",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13433303,
      accounts: 422900,
      dataSent: 7343931,
      dataReceived: 53872004,
      callsIn: 887796,
      callsInDuration: 2245593,
      callsOut: 763846,
      callsOutDuration: 1630176,
      smses: 97332,
    },
    {
      date: "2020-07-07",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13465041,
      accounts: 421993,
      dataSent: 7439845,
      dataReceived: 54614069,
      callsIn: 862095,
      callsInDuration: 2238769,
      callsOut: 735777,
      callsOutDuration: 1614509,
      smses: 94421,
    },
    {
      date: "2020-07-08",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13437566,
      accounts: 422095,
      dataSent: 7462931,
      dataReceived: 55148688,
      callsIn: 872976,
      callsInDuration: 2276772,
      callsOut: 735882,
      callsOutDuration: 1622064,
      smses: 94088,
    },
    {
      date: "2020-07-09",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13434946,
      accounts: 422413,
      dataSent: 7476358,
      dataReceived: 55460955,
      callsIn: 877836,
      callsInDuration: 2258757,
      callsOut: 732636,
      callsOutDuration: 1605000,
      smses: 89565,
    },
    {
      date: "2020-07-10",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 13742539,
      accounts: 428631,
      dataSent: 7728418,
      dataReceived: 57479776,
      callsIn: 932412,
      callsInDuration: 2229066,
      callsOut: 787637,
      callsOutDuration: 1606535,
      smses: 95115,
    },
    {
      date: "2020-07-11",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13713764,
      accounts: 429240,
      dataSent: 7417259,
      dataReceived: 56471231,
      callsIn: 856845,
      callsInDuration: 1954657,
      callsOut: 768079,
      callsOutDuration: 1471038,
      smses: 93940,
    },
    {
      date: "2020-07-12",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12294518,
      accounts: 408322,
      dataSent: 6242824,
      dataReceived: 46556822,
      callsIn: 601184,
      callsInDuration: 1660007,
      callsOut: 550190,
      callsOutDuration: 1246839,
      smses: 79019,
    },
    {
      date: "2020-07-13",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13695545,
      accounts: 430434,
      dataSent: 8273443,
      dataReceived: 61783870,
      callsIn: 880872,
      callsInDuration: 2199693,
      callsOut: 744197,
      callsOutDuration: 1569820,
      smses: 91792,
    },
    {
      date: "2020-07-14",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14110672,
      accounts: 434366,
      dataSent: 7425721,
      dataReceived: 55768348,
      callsIn: 891875,
      callsInDuration: 2279069,
      callsOut: 747898,
      callsOutDuration: 1620368,
      smses: 103508,
    },
    {
      date: "2020-07-15",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13578831,
      accounts: 428783,
      dataSent: 6947311,
      dataReceived: 51157213,
      callsIn: 887374,
      callsInDuration: 2305774,
      callsOut: 742630,
      callsOutDuration: 1622996,
      smses: 93530,
    },
    {
      date: "2020-07-16",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 14093701,
      accounts: 435058,
      dataSent: 7505598,
      dataReceived: 55663914,
      callsIn: 896839,
      callsInDuration: 2271570,
      callsOut: 755108,
      callsOutDuration: 1628718,
      smses: 93586,
    },
    {
      date: "2020-07-17",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14341611,
      accounts: 440724,
      dataSent: 7961600,
      dataReceived: 59219861,
      callsIn: 950261,
      callsInDuration: 2289546,
      callsOut: 808269,
      callsOutDuration: 1658001,
      smses: 98186,
    },
    {
      date: "2020-07-18",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14231804,
      accounts: 441915,
      dataSent: 8695311,
      dataReceived: 66210610,
      callsIn: 883757,
      callsInDuration: 1977912,
      callsOut: 798906,
      callsOutDuration: 1507726,
      smses: 94872,
    },
    {
      date: "2020-07-19",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12695378,
      accounts: 419550,
      dataSent: 7219220,
      dataReceived: 54207164,
      callsIn: 612250,
      callsInDuration: 1754634,
      callsOut: 563742,
      callsOutDuration: 1329278,
      smses: 81578,
    },
    {
      date: "2020-07-20",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13639665,
      accounts: 433477,
      dataSent: 7476788,
      dataReceived: 55890351,
      callsIn: 908148,
      callsInDuration: 2299497,
      callsOut: 775554,
      callsOutDuration: 1648074,
      smses: 94757,
    },
    {
      date: "2020-07-21",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13911944,
      accounts: 435280,
      dataSent: 7530526,
      dataReceived: 56633021,
      callsIn: 889238,
      callsInDuration: 2283271,
      callsOut: 750526,
      callsOutDuration: 1629287,
      smses: 96662,
    },
    {
      date: "2020-07-22",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13693895,
      accounts: 433233,
      dataSent: 7506965,
      dataReceived: 56021291,
      callsIn: 884447,
      callsInDuration: 2304479,
      callsOut: 743302,
      callsOutDuration: 1635928,
      smses: 91598,
    },
    {
      date: "2020-07-23",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13416161,
      accounts: 434778,
      dataSent: 7522782,
      dataReceived: 56524461,
      callsIn: 902487,
      callsInDuration: 2299255,
      callsOut: 759849,
      callsOutDuration: 1648651,
      smses: 91893,
    },
    {
      date: "2020-07-24",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14085857,
      accounts: 439164,
      dataSent: 7708257,
      dataReceived: 58165268,
      callsIn: 951547,
      callsInDuration: 2257242,
      callsOut: 800201,
      callsOutDuration: 1617038,
      smses: 93694,
    },
    {
      date: "2020-07-25",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14070980,
      accounts: 441585,
      dataSent: 7651478,
      dataReceived: 57999975,
      callsIn: 884206,
      callsInDuration: 1969837,
      callsOut: 783286,
      callsOutDuration: 1478899,
      smses: 91791,
    },
    {
      date: "2020-07-26",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12454720,
      accounts: 418124,
      dataSent: 6752258,
      dataReceived: 50619335,
      callsIn: 617626,
      callsInDuration: 1774572,
      callsOut: 558019,
      callsOutDuration: 1317185,
      smses: 81861,
    },
    {
      date: "2020-07-27",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13518438,
      accounts: 437521,
      dataSent: 7626875,
      dataReceived: 56827485,
      callsIn: 916295,
      callsInDuration: 2342248,
      callsOut: 761695,
      callsOutDuration: 1653719,
      smses: 94798,
    },
    {
      date: "2020-07-28",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 13842678,
      accounts: 442716,
      dataSent: 7852399,
      dataReceived: 57581219,
      callsIn: 900110,
      callsInDuration: 2319490,
      callsOut: 746572,
      callsOutDuration: 1638335,
      smses: 91827,
    },
    {
      date: "2020-07-29",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 13689649,
      accounts: 437541,
      dataSent: 7827749,
      dataReceived: 58827731,
      callsIn: 896821,
      callsInDuration: 2335484,
      callsOut: 738813,
      callsOutDuration: 1642291,
      smses: 88875,
    },
    {
      date: "2020-07-30",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 13669709,
      accounts: 438257,
      dataSent: 7838420,
      dataReceived: 57475709,
      callsIn: 902772,
      callsInDuration: 2345505,
      callsOut: 740811,
      callsOutDuration: 1647284,
      smses: 89879,
    },
    {
      date: "2020-07-31",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14039242,
      accounts: 444269,
      dataSent: 8001558,
      dataReceived: 58697279,
      callsIn: 980448,
      callsInDuration: 2328964,
      callsOut: 819453,
      callsOutDuration: 1676895,
      smses: 94711,
    },
    {
      date: "2020-08-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14105662,
      accounts: 446069,
      dataSent: 7803174,
      dataReceived: 57910549,
      callsIn: 922228,
      callsInDuration: 2029747,
      callsOut: 824142,
      callsOutDuration: 1546785,
      smses: 92344,
    },
    {
      date: "2020-08-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12740284,
      accounts: 425542,
      dataSent: 7274665,
      dataReceived: 53161692,
      callsIn: 649999,
      callsInDuration: 1808672,
      callsOut: 589669,
      callsOutDuration: 1361120,
      smses: 79541,
    },
    {
      date: "2020-08-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13911973,
      accounts: 443483,
      dataSent: 7670183,
      dataReceived: 56076396,
      callsIn: 959921,
      callsInDuration: 2346805,
      callsOut: 801237,
      callsOutDuration: 1674131,
      smses: 87399,
    },
    {
      date: "2020-08-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14201476,
      accounts: 443818,
      dataSent: 7838470,
      dataReceived: 57223112,
      callsIn: 932487,
      callsInDuration: 2339908,
      callsOut: 775085,
      callsOutDuration: 1664454,
      smses: 88021,
    },
    {
      date: "2020-08-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 14278777,
      accounts: 444668,
      dataSent: 7500589,
      dataReceived: 55052268,
      callsIn: 929767,
      callsInDuration: 2330830,
      callsOut: 771507,
      callsOutDuration: 1646824,
      smses: 89005,
    },
    {
      date: "2020-08-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 14423415,
      accounts: 449487,
      dataSent: 8192858,
      dataReceived: 59922529,
      callsIn: 940738,
      callsInDuration: 2367467,
      callsOut: 782678,
      callsOutDuration: 1693158,
      smses: 88804,
    },
    {
      date: "2020-08-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 14676666,
      accounts: 452482,
      dataSent: 8262769,
      dataReceived: 60643505,
      callsIn: 1003405,
      callsInDuration: 2334189,
      callsOut: 850148,
      callsOutDuration: 1695574,
      smses: 92421,
    },
    {
      date: "2020-08-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14682233,
      accounts: 453803,
      dataSent: 8000231,
      dataReceived: 60536918,
      callsIn: 924967,
      callsInDuration: 2006287,
      callsOut: 824246,
      callsOutDuration: 1523929,
      smses: 88132,
    },
    {
      date: "2020-08-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 13577603,
      accounts: 433983,
      dataSent: 7408488,
      dataReceived: 55219223,
      callsIn: 663987,
      callsInDuration: 1732895,
      callsOut: 605508,
      callsOutDuration: 1325692,
      smses: 78564,
    },
    {
      date: "2020-08-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13808788,
      accounts: 442386,
      dataSent: 7460539,
      dataReceived: 55096827,
      callsIn: 788910,
      callsInDuration: 2009685,
      callsOut: 691223,
      callsOutDuration: 1492098,
      smses: 84003,
    },
    {
      date: "2020-08-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14080708,
      accounts: 447050,
      dataSent: 7615048,
      dataReceived: 55949442,
      callsIn: 932475,
      callsInDuration: 2329242,
      callsOut: 775725,
      callsOutDuration: 1648339,
      smses: 88000,
    },
    {
      date: "2020-08-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 14165863,
      accounts: 446356,
      dataSent: 7067757,
      dataReceived: 51566662,
      callsIn: 931979,
      callsInDuration: 2327568,
      callsOut: 768553,
      callsOutDuration: 1633843,
      smses: 91350,
    },
    {
      date: "2020-08-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 16725972,
      accounts: 457028,
      dataSent: 6976421,
      dataReceived: 50730450,
      callsIn: 969193,
      callsInDuration: 2381004,
      callsOut: 800786,
      callsOutDuration: 1685713,
      smses: 96511,
    },
    {
      date: "2020-08-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 15530362,
      accounts: 462357,
      dataSent: 9009046,
      dataReceived: 67514524,
      callsIn: 1010475,
      callsInDuration: 2285552,
      callsOut: 850607,
      callsOutDuration: 1645162,
      smses: 95268,
    },
    {
      date: "2020-08-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 13470378,
      accounts: 454166,
      dataSent: 7642637,
      dataReceived: 58420607,
      callsIn: 676545,
      callsInDuration: 1552755,
      callsOut: 607785,
      callsOutDuration: 1177954,
      smses: 59769,
    },
    {
      date: "2020-08-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 13485575,
      accounts: 438388,
      dataSent: 7459563,
      dataReceived: 55382417,
      callsIn: 653498,
      callsInDuration: 1793920,
      callsOut: 596805,
      callsOutDuration: 1355224,
      smses: 77721,
    },
    {
      date: "2020-08-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 14804351,
      accounts: 457325,
      dataSent: 7929016,
      dataReceived: 57693905,
      callsIn: 970800,
      callsInDuration: 2387416,
      callsOut: 816824,
      callsOutDuration: 1718881,
      smses: 90612,
    },
    {
      date: "2020-08-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 14810774,
      accounts: 457659,
      dataSent: 7937592,
      dataReceived: 59136295,
      callsIn: 944176,
      callsInDuration: 2347513,
      callsOut: 786247,
      callsOutDuration: 1680577,
      smses: 88593,
    },
    {
      date: "2020-08-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 14852142,
      accounts: 457699,
      dataSent: 8041640,
      dataReceived: 59432487,
      callsIn: 944928,
      callsInDuration: 2348970,
      callsOut: 784206,
      callsOutDuration: 1665337,
      smses: 87177,
    },
    {
      date: "2020-08-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 15135921,
      accounts: 463622,
      dataSent: 8141581,
      dataReceived: 59589502,
      callsIn: 958502,
      callsInDuration: 2363822,
      callsOut: 797416,
      callsOutDuration: 1698770,
      smses: 89704,
    },
    {
      date: "2020-08-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 15978449,
      accounts: 466079,
      dataSent: 8412224,
      dataReceived: 62759029,
      callsIn: 995348,
      callsInDuration: 2308776,
      callsOut: 841941,
      callsOutDuration: 1681427,
      smses: 91496,
    },
    {
      date: "2020-08-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 15833572,
      accounts: 465561,
      dataSent: 8055034,
      dataReceived: 60812513,
      callsIn: 913237,
      callsInDuration: 1982964,
      callsOut: 821705,
      callsOutDuration: 1515501,
      smses: 92243,
    },
    {
      date: "2020-08-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 14267667,
      accounts: 444357,
      dataSent: 7440528,
      dataReceived: 58093147,
      callsIn: 652497,
      callsInDuration: 1732523,
      callsOut: 600993,
      callsOutDuration: 1327780,
      smses: 78752,
    },
    {
      date: "2020-08-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 15189953,
      accounts: 460558,
      dataSent: 7808177,
      dataReceived: 58248394,
      callsIn: 970794,
      callsInDuration: 2376882,
      callsOut: 802153,
      callsOutDuration: 1693424,
      smses: 90503,
    },
    {
      date: "2020-08-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 15380099,
      accounts: 460737,
      dataSent: 7846125,
      dataReceived: 57910723,
      callsIn: 950736,
      callsInDuration: 2347173,
      callsOut: 781677,
      callsOutDuration: 1658126,
      smses: 85533,
    },
    {
      date: "2020-08-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 15858739,
      accounts: 463296,
      dataSent: 7907176,
      dataReceived: 58866314,
      callsIn: 951176,
      callsInDuration: 2371246,
      callsOut: 782060,
      callsOutDuration: 1676728,
      smses: 87273,
    },
    {
      date: "2020-08-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 15966106,
      accounts: 465930,
      dataSent: 7889890,
      dataReceived: 58777339,
      callsIn: 952567,
      callsInDuration: 2377535,
      callsOut: 783728,
      callsOutDuration: 1694499,
      smses: 88228,
    },
    {
      date: "2020-08-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 16750245,
      accounts: 475466,
      dataSent: 8476886,
      dataReceived: 62705720,
      callsIn: 1027786,
      callsInDuration: 2363680,
      callsOut: 856191,
      callsOutDuration: 1699080,
      smses: 91525,
    },
    {
      date: "2020-08-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 16532531,
      accounts: 470274,
      dataSent: 8073864,
      dataReceived: 61659040,
      callsIn: 945869,
      callsInDuration: 2024835,
      callsOut: 836724,
      callsOutDuration: 1534234,
      smses: 88232,
    },
    {
      date: "2020-08-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 14956852,
      accounts: 450568,
      dataSent: 7475764,
      dataReceived: 55590001,
      callsIn: 676912,
      callsInDuration: 1797896,
      callsOut: 615277,
      callsOutDuration: 1357562,
      smses: 75474,
    },
    {
      date: "2020-08-31",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 16114402,
      accounts: 467999,
      dataSent: 7841635,
      dataReceived: 57576891,
      callsIn: 1004122,
      callsInDuration: 2430253,
      callsOut: 838734,
      callsOutDuration: 1745619,
      smses: 91506,
    },
    {
      date: "2020-09-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 16326910,
      accounts: 467976,
      dataSent: 7920561,
      dataReceived: 58503523,
      callsIn: 980389,
      callsInDuration: 2401453,
      callsOut: 822025,
      callsOutDuration: 1730076,
      smses: 89560,
    },
    {
      date: "2020-09-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 16439003,
      accounts: 469229,
      dataSent: 7461331,
      dataReceived: 54187869,
      callsIn: 993106,
      callsInDuration: 2381385,
      callsOut: 827307,
      callsOutDuration: 1708444,
      smses: 89968,
    },
    {
      date: "2020-09-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 16559106,
      accounts: 475996,
      dataSent: 8999451,
      dataReceived: 66182084,
      callsIn: 1002746,
      callsInDuration: 2444222,
      callsOut: 835293,
      callsOutDuration: 1778044,
      smses: 88600,
    },
    {
      date: "2020-09-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 16727230,
      accounts: 475587,
      dataSent: 8456050,
      dataReceived: 61473157,
      callsIn: 1078617,
      callsInDuration: 2360410,
      callsOut: 906870,
      callsOutDuration: 1737920,
      smses: 90489,
    },
    {
      date: "2020-09-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 16731479,
      accounts: 476107,
      dataSent: 8219057,
      dataReceived: 61127243,
      callsIn: 983833,
      callsInDuration: 2014410,
      callsOut: 893514,
      callsOutDuration: 1566765,
      smses: 89813,
    },
    {
      date: "2020-09-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 14943879,
      accounts: 452677,
      dataSent: 7595220,
      dataReceived: 55039905,
      callsIn: 702385,
      callsInDuration: 1785644,
      callsOut: 648785,
      callsOutDuration: 1374590,
      smses: 78227,
    },
    {
      date: "2020-09-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 16166772,
      accounts: 470090,
      dataSent: 7991853,
      dataReceived: 57671633,
      callsIn: 1009095,
      callsInDuration: 2392473,
      callsOut: 855427,
      callsOutDuration: 1734403,
      smses: 91782,
    },
    {
      date: "2020-09-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 16410832,
      accounts: 471212,
      dataSent: 7936325,
      dataReceived: 57360956,
      callsIn: 992382,
      callsInDuration: 2385092,
      callsOut: 830564,
      callsOutDuration: 1720544,
      smses: 86706,
    },
    {
      date: "2020-09-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 16303255,
      accounts: 473835,
      dataSent: 8406893,
      dataReceived: 61697025,
      callsIn: 986655,
      callsInDuration: 2383927,
      callsOut: 818662,
      callsOutDuration: 1713142,
      smses: 86024,
    },
    {
      date: "2020-09-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 16573607,
      accounts: 474720,
      dataSent: 8358048,
      dataReceived: 59558969,
      callsIn: 993487,
      callsInDuration: 2407853,
      callsOut: 832763,
      callsOutDuration: 1729712,
      smses: 87402,
    },
    {
      date: "2020-09-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 29540283,
      accounts: 486419,
      dataSent: 8260744,
      dataReceived: 59412959,
      callsIn: 1046825,
      callsInDuration: 2306653,
      callsOut: 900858,
      callsOutDuration: 1706245,
      smses: 90099,
    },
    {
      date: "2020-09-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 19660517,
      accounts: 483332,
      dataSent: 8175136,
      dataReceived: 59555704,
      callsIn: 988654,
      callsInDuration: 2027582,
      callsOut: 895008,
      callsOutDuration: 1570503,
      smses: 91000,
    },
    {
      date: "2020-09-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 17630493,
      accounts: 457977,
      dataSent: 6383630,
      dataReceived: 46126570,
      callsIn: 708986,
      callsInDuration: 1750790,
      callsOut: 658034,
      callsOutDuration: 1354190,
      smses: 75679,
    },
    {
      date: "2020-09-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 13927509,
      accounts: 464177,
      dataSent: 6341615,
      dataReceived: 47242397,
      callsIn: 835842,
      callsInDuration: 1979830,
      callsOut: 719237,
      callsOutDuration: 1456169,
      smses: 87726,
    },
    {
      date: "2020-09-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 19151874,
      accounts: 479603,
      dataSent: 8297160,
      dataReceived: 60500216,
      callsIn: 1003846,
      callsInDuration: 2408605,
      callsOut: 847609,
      callsOutDuration: 1756919,
      smses: 90396,
    },
    {
      date: "2020-09-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 19099937,
      accounts: 481111,
      dataSent: 8401369,
      dataReceived: 61361480,
      callsIn: 1014631,
      callsInDuration: 2416397,
      callsOut: 851002,
      callsOutDuration: 1753363,
      smses: 90954,
    },
    {
      date: "2020-09-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 19426671,
      accounts: 485102,
      dataSent: 8480913,
      dataReceived: 62666970,
      callsIn: 1015810,
      callsInDuration: 2386929,
      callsOut: 848153,
      callsOutDuration: 1744106,
      smses: 87940,
    },
    {
      date: "2020-09-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 19905982,
      accounts: 488519,
      dataSent: 8700349,
      dataReceived: 63501243,
      callsIn: 1081698,
      callsInDuration: 2344917,
      callsOut: 931057,
      callsOutDuration: 1747197,
      smses: 93089,
    },
    {
      date: "2020-09-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 20132359,
      accounts: 491104,
      dataSent: 8510675,
      dataReceived: 63740793,
      callsIn: 996965,
      callsInDuration: 2016647,
      callsOut: 911622,
      callsOutDuration: 1577653,
      smses: 89329,
    },
    {
      date: "2020-09-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 18283517,
      accounts: 473730,
      dataSent: 7995549,
      dataReceived: 58294776,
      callsIn: 736401,
      callsInDuration: 1793065,
      callsOut: 687291,
      callsOutDuration: 1406013,
      smses: 81276,
    },
    {
      date: "2020-09-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 19019464,
      accounts: 485645,
      dataSent: 8218584,
      dataReceived: 59397631,
      callsIn: 1012749,
      callsInDuration: 2386417,
      callsOut: 866343,
      callsOutDuration: 1749049,
      smses: 94157,
    },
    {
      date: "2020-09-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 19277315,
      accounts: 485275,
      dataSent: 8276973,
      dataReceived: 60904691,
      callsIn: 990699,
      callsInDuration: 2366753,
      callsOut: 838375,
      callsOutDuration: 1729151,
      smses: 91659,
    },
    {
      date: "2020-09-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 19143729,
      accounts: 485128,
      dataSent: 7982568,
      dataReceived: 59844004,
      callsIn: 983253,
      callsInDuration: 2393000,
      callsOut: 821860,
      callsOutDuration: 1729950,
      smses: 88067,
    },
    {
      date: "2020-09-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 19157969,
      accounts: 487222,
      dataSent: 8724907,
      dataReceived: 63626884,
      callsIn: 1000479,
      callsInDuration: 2373376,
      callsOut: 826678,
      callsOutDuration: 1704441,
      smses: 87367,
    },
    {
      date: "2020-09-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 24443823,
      accounts: 493470,
      dataSent: 8625171,
      dataReceived: 63470904,
      callsIn: 1089545,
      callsInDuration: 2349706,
      callsOut: 912205,
      callsOutDuration: 1719890,
      smses: 92912,
    },
    {
      date: "2020-09-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 19728133,
      accounts: 494049,
      dataSent: 8277564,
      dataReceived: 63168590,
      callsIn: 1005338,
      callsInDuration: 2035615,
      callsOut: 891686,
      callsOutDuration: 1556957,
      smses: 90006,
    },
    {
      date: "2020-09-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 18072955,
      accounts: 477892,
      dataSent: 7333621,
      dataReceived: 54335337,
      callsIn: 737158,
      callsInDuration: 1758522,
      callsOut: 671792,
      callsOutDuration: 1361937,
      smses: 81465,
    },
    {
      date: "2020-09-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 19361987,
      accounts: 491066,
      dataSent: 8423156,
      dataReceived: 61481405,
      callsIn: 1018675,
      callsInDuration: 2409917,
      callsOut: 843205,
      callsOutDuration: 1731948,
      smses: 90636,
    },
    {
      date: "2020-09-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 19234889,
      accounts: 487095,
      dataSent: 8253619,
      dataReceived: 60542759,
      callsIn: 1012465,
      callsInDuration: 2407205,
      callsOut: 827467,
      callsOutDuration: 1732549,
      smses: 89464,
    },
    {
      date: "2020-09-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 19465583,
      accounts: 500304,
      dataSent: 7674471,
      dataReceived: 55504640,
      callsIn: 1044621,
      callsInDuration: 2467491,
      callsOut: 865537,
      callsOutDuration: 1781446,
      smses: 92323,
    },
    {
      date: "2020-10-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 19613939,
      accounts: 495111,
      dataSent: 9331041,
      dataReceived: 67906925,
      callsIn: 1050556,
      callsInDuration: 2436799,
      callsOut: 871778,
      callsOutDuration: 1774521,
      smses: 89086,
    },
    {
      date: "2020-10-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 20151131,
      accounts: 503040,
      dataSent: 8587500,
      dataReceived: 63125003,
      callsIn: 1138425,
      callsInDuration: 2404805,
      callsOut: 961126,
      callsOutDuration: 1774682,
      smses: 96221,
    },
    {
      date: "2020-10-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 20215282,
      accounts: 505155,
      dataSent: 8452152,
      dataReceived: 63652250,
      callsIn: 1045905,
      callsInDuration: 2063288,
      callsOut: 941074,
      callsOutDuration: 1614384,
      smses: 94817,
    },
    {
      date: "2020-10-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 18563568,
      accounts: 488720,
      dataSent: 7847278,
      dataReceived: 58429866,
      callsIn: 760345,
      callsInDuration: 1813517,
      callsOut: 701342,
      callsOutDuration: 1418566,
      smses: 83389,
    },
    {
      date: "2020-10-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 22039298,
      accounts: 501340,
      dataSent: 8233603,
      dataReceived: 60446686,
      callsIn: 1065044,
      callsInDuration: 2451801,
      callsOut: 900522,
      callsOutDuration: 1804104,
      smses: 92428,
    },
    {
      date: "2020-10-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 22491997,
      accounts: 503507,
      dataSent: 8537028,
      dataReceived: 61597811,
      callsIn: 1061973,
      callsInDuration: 2476185,
      callsOut: 897075,
      callsOutDuration: 1814520,
      smses: 95253,
    },
    {
      date: "2020-10-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 23136426,
      accounts: 508213,
      dataSent: 8940148,
      dataReceived: 65626641,
      callsIn: 1078455,
      callsInDuration: 2455715,
      callsOut: 913157,
      callsOutDuration: 1820756,
      smses: 93312,
    },
    {
      date: "2020-10-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 23395737,
      accounts: 509795,
      dataSent: 8890691,
      dataReceived: 67361346,
      callsIn: 1123966,
      callsInDuration: 2436099,
      callsOut: 957697,
      callsOutDuration: 1827807,
      smses: 94281,
    },
    {
      date: "2020-10-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 23829986,
      accounts: 511444,
      dataSent: 8614365,
      dataReceived: 66653192,
      callsIn: 1018976,
      callsInDuration: 2116040,
      callsOut: 911181,
      callsOutDuration: 1648968,
      smses: 92624,
    },
    {
      date: "2020-10-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 21249984,
      accounts: 501304,
      dataSent: 7295938,
      dataReceived: 54744424,
      callsIn: 990907,
      callsInDuration: 1979340,
      callsOut: 912452,
      callsOutDuration: 1586419,
      smses: 92896,
    },
    {
      date: "2020-10-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20476808,
      accounts: 494227,
      dataSent: 7827503,
      dataReceived: 59267142,
      callsIn: 756164,
      callsInDuration: 1767556,
      callsOut: 707965,
      callsOutDuration: 1413244,
      smses: 84781,
    },
    {
      date: "2020-10-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 22843538,
      accounts: 512430,
      dataSent: 8456736,
      dataReceived: 62877658,
      callsIn: 1077240,
      callsInDuration: 2484976,
      callsOut: 929442,
      callsOutDuration: 1863260,
      smses: 94946,
    },
    {
      date: "2020-10-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 23213618,
      accounts: 513551,
      dataSent: 8817826,
      dataReceived: 68556404,
      callsIn: 1052821,
      callsInDuration: 2439602,
      callsOut: 905881,
      callsOutDuration: 1834945,
      smses: 97805,
    },
    {
      date: "2020-10-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 23285047,
      accounts: 515894,
      dataSent: 8760145,
      dataReceived: 66390089,
      callsIn: 1057052,
      callsInDuration: 2469722,
      callsOut: 912760,
      callsOutDuration: 1865047,
      smses: 94479,
    },
    {
      date: "2020-10-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 23380200,
      accounts: 519600,
      dataSent: 9126958,
      dataReceived: 68644699,
      callsIn: 1086914,
      callsInDuration: 2487902,
      callsOut: 944210,
      callsOutDuration: 1902897,
      smses: 96581,
    },
    {
      date: "2020-10-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 24520950,
      accounts: 526525,
      dataSent: 9217217,
      dataReceived: 69910242,
      callsIn: 1155763,
      callsInDuration: 2433608,
      callsOut: 1034533,
      callsOutDuration: 1897805,
      smses: 101063,
    },
    {
      date: "2020-10-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 20183587,
      accounts: 508659,
      dataSent: 7397879,
      dataReceived: 56850337,
      callsIn: 1077524,
      callsInDuration: 2087078,
      callsOut: 1004851,
      callsOutDuration: 1689458,
      smses: 98251,
    },
    {
      date: "2020-10-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 22381995,
      accounts: 505965,
      dataSent: 8427649,
      dataReceived: 64394994,
      callsIn: 776838,
      callsInDuration: 1838553,
      callsOut: 746566,
      callsOutDuration: 1499466,
      smses: 84247,
    },
    {
      date: "2020-10-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 23072378,
      accounts: 518629,
      dataSent: 8879560,
      dataReceived: 67402197,
      callsIn: 1076022,
      callsInDuration: 2430760,
      callsOut: 954308,
      callsOutDuration: 1880156,
      smses: 98930,
    },
    {
      date: "2020-10-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 22887091,
      accounts: 518201,
      dataSent: 8906989,
      dataReceived: 68724172,
      callsIn: 1074929,
      callsInDuration: 2432180,
      callsOut: 942041,
      callsOutDuration: 1878303,
      smses: 97580,
    },
    {
      date: "2020-10-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 23548317,
      accounts: 520221,
      dataSent: 9238456,
      dataReceived: 70930071,
      callsIn: 1056881,
      callsInDuration: 2458147,
      callsOut: 938620,
      callsOutDuration: 1894197,
      smses: 97329,
    },
    {
      date: "2020-10-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 23923887,
      accounts: 521700,
      dataSent: 9445543,
      dataReceived: 71179317,
      callsIn: 1068644,
      callsInDuration: 2453341,
      callsOut: 942244,
      callsOutDuration: 1891885,
      smses: 95885,
    },
    {
      date: "2020-10-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 23480625,
      accounts: 535237,
      dataSent: 9241083,
      dataReceived: 70416338,
      callsIn: 1146632,
      callsInDuration: 2412283,
      callsOut: 1021016,
      callsOutDuration: 1870021,
      smses: 100442,
    },
    {
      date: "2020-10-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 19869456,
      accounts: 521095,
      dataSent: 7434832,
      dataReceived: 58158760,
      callsIn: 1061462,
      callsInDuration: 2073501,
      callsOut: 995381,
      callsOutDuration: 1686582,
      smses: 100341,
    },
    {
      date: "2020-10-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 21515659,
      accounts: 522330,
      dataSent: 8381214,
      dataReceived: 64032969,
      callsIn: 775750,
      callsInDuration: 1818135,
      callsOut: 742368,
      callsOutDuration: 1483567,
      smses: 88121,
    },
    {
      date: "2020-10-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 22232285,
      accounts: 532504,
      dataSent: 8110538,
      dataReceived: 60016066,
      callsIn: 1066665,
      callsInDuration: 2452293,
      callsOut: 937730,
      callsOutDuration: 1875097,
      smses: 98732,
    },
    {
      date: "2020-10-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 23011461,
      accounts: 536657,
      dataSent: 9803122,
      dataReceived: 73609854,
      callsIn: 1043242,
      callsInDuration: 2461964,
      callsOut: 905131,
      callsOutDuration: 1865206,
      smses: 94381,
    },
    {
      date: "2020-10-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 23473925,
      accounts: 532925,
      dataSent: 8352822,
      dataReceived: 62477167,
      callsIn: 1064539,
      callsInDuration: 2441065,
      callsOut: 922246,
      callsOutDuration: 1855914,
      smses: 94978,
    },
    {
      date: "2020-10-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 23891491,
      accounts: 540768,
      dataSent: 10053223,
      dataReceived: 76259298,
      callsIn: 1089445,
      callsInDuration: 2490217,
      callsOut: 951055,
      callsOutDuration: 1918063,
      smses: 94737,
    },
    {
      date: "2020-10-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 24588236,
      accounts: 546033,
      dataSent: 9587248,
      dataReceived: 72107179,
      callsIn: 1218094,
      callsInDuration: 2458847,
      callsOut: 1076823,
      callsOutDuration: 1922328,
      smses: 100985,
    },
    {
      date: "2020-10-31",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 25175579,
      accounts: 550386,
      dataSent: 9418099,
      dataReceived: 73107303,
      callsIn: 1148850,
      callsInDuration: 2084242,
      callsOut: 1080468,
      callsOutDuration: 1727068,
      smses: 100895,
    },
    {
      date: "2020-11-01",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 22748606,
      accounts: 529464,
      dataSent: 8771798,
      dataReceived: 68070165,
      callsIn: 823425,
      callsInDuration: 1782717,
      callsOut: 789059,
      callsOutDuration: 1488390,
      smses: 94161,
    },
    {
      date: "2020-11-02",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 22112300,
      accounts: 525062,
      dataSent: 8436456,
      dataReceived: 64829738,
      callsIn: 823743,
      callsInDuration: 1831670,
      callsOut: 777049,
      callsOutDuration: 1502441,
      smses: 87284,
    },
    {
      date: "2020-11-03",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21662864,
      accounts: 523305,
      dataSent: 8092399,
      dataReceived: 62836160,
      callsIn: 810112,
      callsInDuration: 1949484,
      callsOut: 752634,
      callsOutDuration: 1571366,
      smses: 88104,
    },
    {
      date: "2020-11-04",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 22274947,
      accounts: 536155,
      dataSent: 8418552,
      dataReceived: 63013653,
      callsIn: 1068769,
      callsInDuration: 2456826,
      callsOut: 942019,
      callsOutDuration: 1893605,
      smses: 98021,
    },
    {
      date: "2020-11-05",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21669329,
      accounts: 540399,
      dataSent: 8706172,
      dataReceived: 65829957,
      callsIn: 1102267,
      callsInDuration: 2490845,
      callsOut: 997602,
      callsOutDuration: 1972694,
      smses: 120642,
    },
    {
      date: "2020-11-06",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 23917899,
      accounts: 547942,
      dataSent: 9510575,
      dataReceived: 72245822,
      callsIn: 1166874,
      callsInDuration: 2485936,
      callsOut: 1044388,
      callsOutDuration: 1956653,
      smses: 104138,
    },
    {
      date: "2020-11-07",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 19813742,
      accounts: 528834,
      dataSent: 7349737,
      dataReceived: 58053013,
      callsIn: 1046861,
      callsInDuration: 2107905,
      callsOut: 991649,
      callsOutDuration: 1730108,
      smses: 99256,
    },
    {
      date: "2020-11-08",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 21429758,
      accounts: 529932,
      dataSent: 8504469,
      dataReceived: 64599514,
      callsIn: 775237,
      callsInDuration: 1881613,
      callsOut: 746601,
      callsOutDuration: 1542920,
      smses: 84576,
    },
    {
      date: "2020-11-09",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 23127690,
      accounts: 544108,
      dataSent: 8222018,
      dataReceived: 61062911,
      callsIn: 1102369,
      callsInDuration: 2450629,
      callsOut: 974304,
      callsOutDuration: 1889997,
      smses: 99062,
    },
    {
      date: "2020-11-10",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21359200,
      accounts: 540491,
      dataSent: 8984317,
      dataReceived: 68394547,
      callsIn: 973225,
      callsInDuration: 2140339,
      callsOut: 853832,
      callsOutDuration: 1654380,
      smses: 87139,
    },
    {
      date: "2020-11-11",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 24407678,
      accounts: 563053,
      dataSent: 9633892,
      dataReceived: 72988006,
      callsIn: 1093752,
      callsInDuration: 2559103,
      callsOut: 955476,
      callsOutDuration: 1974369,
      smses: 104493,
    },
    {
      date: "2020-11-12",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 23668501,
      accounts: 545953,
      dataSent: 9337952,
      dataReceived: 71729255,
      callsIn: 1080287,
      callsInDuration: 2473182,
      callsOut: 942025,
      callsOutDuration: 1901940,
      smses: 94502,
    },
    {
      date: "2020-11-13",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 24745220,
      accounts: 552725,
      dataSent: 9708669,
      dataReceived: 73751890,
      callsIn: 1172279,
      callsInDuration: 2499728,
      callsOut: 1034209,
      callsOutDuration: 1950618,
      smses: 103325,
    },
    {
      date: "2020-11-14",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 19604438,
      accounts: 534242,
      dataSent: 7611631,
      dataReceived: 58445203,
      callsIn: 1047093,
      callsInDuration: 2014266,
      callsOut: 973205,
      callsOutDuration: 1643282,
      smses: 98164,
    },
    {
      date: "2020-11-15",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 21782363,
      accounts: 534709,
      dataSent: 7752921,
      dataReceived: 59291533,
      callsIn: 791317,
      callsInDuration: 1817865,
      callsOut: 754748,
      callsOutDuration: 1487292,
      smses: 80698,
    },
    {
      date: "2020-11-16",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 23540228,
      accounts: 551894,
      dataSent: 10231843,
      dataReceived: 77010467,
      callsIn: 1107263,
      callsInDuration: 2507897,
      callsOut: 967037,
      callsOutDuration: 1938592,
      smses: 99955,
    },
    {
      date: "2020-11-17",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 23613213,
      accounts: 547115,
      dataSent: 9458765,
      dataReceived: 74128317,
      callsIn: 1068724,
      callsInDuration: 2426295,
      callsOut: 930172,
      callsOutDuration: 1883516,
      smses: 97241,
    },
    {
      date: "2020-11-18",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 23555948,
      accounts: 547383,
      dataSent: 9349948,
      dataReceived: 72000915,
      callsIn: 1075105,
      callsInDuration: 2507246,
      callsOut: 940008,
      callsOutDuration: 1946204,
      smses: 98490,
    },
    {
      date: "2020-11-19",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 23804325,
      accounts: 548055,
      dataSent: 9340510,
      dataReceived: 71854544,
      callsIn: 1082977,
      callsInDuration: 2490088,
      callsOut: 939117,
      callsOutDuration: 1929143,
      smses: 97209,
    },
    {
      date: "2020-11-20",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22454409,
      accounts: 553144,
      dataSent: 9490373,
      dataReceived: 72638753,
      callsIn: 1180224,
      callsInDuration: 2469702,
      callsOut: 1036538,
      callsOutDuration: 1930322,
      smses: 103713,
    },
    {
      date: "2020-11-21",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 22527071,
      accounts: 558029,
      dataSent: 9501075,
      dataReceived: 73525234,
      callsIn: 1074136,
      callsInDuration: 2112337,
      callsOut: 1011707,
      callsOutDuration: 1734242,
      smses: 100570,
    },
    {
      date: "2020-11-22",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20213126,
      accounts: 539709,
      dataSent: 8626639,
      dataReceived: 65504290,
      callsIn: 788828,
      callsInDuration: 1856186,
      callsOut: 758855,
      callsOutDuration: 1527405,
      smses: 86253,
    },
    {
      date: "2020-11-23",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20818937,
      accounts: 546543,
      dataSent: 8078783,
      dataReceived: 60775535,
      callsIn: 1072344,
      callsInDuration: 2480712,
      callsOut: 948810,
      callsOutDuration: 1922849,
      smses: 94279,
    },
    {
      date: "2020-11-24",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21296686,
      accounts: 552860,
      dataSent: 9416837,
      dataReceived: 70567712,
      callsIn: 1064868,
      callsInDuration: 2476350,
      callsOut: 923503,
      callsOutDuration: 1892250,
      smses: 102590,
    },
    {
      date: "2020-11-25",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 22404579,
      accounts: 554327,
      dataSent: 10340160,
      dataReceived: 79213819,
      callsIn: 1076930,
      callsInDuration: 2567767,
      callsOut: 927639,
      callsOutDuration: 1964512,
      smses: 95708,
    },
    {
      date: "2020-11-26",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21695263,
      accounts: 549351,
      dataSent: 8466938,
      dataReceived: 64188941,
      callsIn: 1083102,
      callsInDuration: 2477802,
      callsOut: 937660,
      callsOutDuration: 1897084,
      smses: 98521,
    },
    {
      date: "2020-11-27",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22205363,
      accounts: 560503,
      dataSent: 10571689,
      dataReceived: 81103906,
      callsIn: 1199633,
      callsInDuration: 2484683,
      callsOut: 1045153,
      callsOutDuration: 1927209,
      smses: 100817,
    },
    {
      date: "2020-11-28",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 22541630,
      accounts: 559770,
      dataSent: 9370820,
      dataReceived: 73154289,
      callsIn: 1113855,
      callsInDuration: 2157552,
      callsOut: 1025395,
      callsOutDuration: 1740366,
      smses: 99422,
    },
    {
      date: "2020-11-29",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20282630,
      accounts: 541572,
      dataSent: 8558753,
      dataReceived: 66414068,
      callsIn: 821741,
      callsInDuration: 1902809,
      callsOut: 772329,
      callsOutDuration: 1548069,
      smses: 85629,
    },
    {
      date: "2020-11-30",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 21491186,
      accounts: 552188,
      dataSent: 9322938,
      dataReceived: 68853942,
      callsIn: 1125538,
      callsInDuration: 2577175,
      callsOut: 987734,
      callsOutDuration: 1977671,
      smses: 101312,
    },
    {
      date: "2020-12-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21608721,
      accounts: 550907,
      dataSent: 9467704,
      dataReceived: 72629706,
      callsIn: 1099703,
      callsInDuration: 2549370,
      callsOut: 965970,
      callsOutDuration: 1965594,
      smses: 96663,
    },
    {
      date: "2020-12-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 21500987,
      accounts: 548726,
      dataSent: 9260542,
      dataReceived: 71011351,
      callsIn: 1093674,
      callsInDuration: 2554643,
      callsOut: 953493,
      callsOutDuration: 1948251,
      smses: 93784,
    },
    {
      date: "2020-12-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 22304571,
      accounts: 553668,
      dataSent: 9628301,
      dataReceived: 72888435,
      callsIn: 1116467,
      callsInDuration: 2475815,
      callsOut: 976063,
      callsOutDuration: 1916069,
      smses: 97778,
    },
    {
      date: "2020-12-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22845039,
      accounts: 563163,
      dataSent: 9968037,
      dataReceived: 75552357,
      callsIn: 1204419,
      callsInDuration: 2493593,
      callsOut: 1095848,
      callsOutDuration: 1994468,
      smses: 102570,
    },
    {
      date: "2020-12-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 23297415,
      accounts: 565598,
      dataSent: 9691436,
      dataReceived: 75358258,
      callsIn: 1127311,
      callsInDuration: 2142226,
      callsOut: 1067280,
      callsOutDuration: 1784189,
      smses: 100410,
    },
    {
      date: "2020-12-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20748680,
      accounts: 548125,
      dataSent: 8810279,
      dataReceived: 69797344,
      callsIn: 839505,
      callsInDuration: 1822445,
      callsOut: 812625,
      callsOutDuration: 1539430,
      smses: 89456,
    },
    {
      date: "2020-12-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 21833144,
      accounts: 555830,
      dataSent: 9166347,
      dataReceived: 71997822,
      callsIn: 1068141,
      callsInDuration: 2383820,
      callsOut: 978663,
      callsOutDuration: 1897907,
      smses: 98074,
    },
    {
      date: "2020-12-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 22230996,
      accounts: 559078,
      dataSent: 9520663,
      dataReceived: 73154637,
      callsIn: 1112659,
      callsInDuration: 2563419,
      callsOut: 978803,
      callsOutDuration: 1981261,
      smses: 97684,
    },
    {
      date: "2020-12-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 22449480,
      accounts: 561008,
      dataSent: 9647479,
      dataReceived: 74972437,
      callsIn: 1141128,
      callsInDuration: 2581846,
      callsOut: 984278,
      callsOutDuration: 1983226,
      smses: 101392,
    },
    {
      date: "2020-12-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 22520852,
      accounts: 560123,
      dataSent: 9663688,
      dataReceived: 72855194,
      callsIn: 1172519,
      callsInDuration: 2570023,
      callsOut: 1006803,
      callsOutDuration: 1985371,
      smses: 99796,
    },
    {
      date: "2020-12-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22805180,
      accounts: 566196,
      dataSent: 9730609,
      dataReceived: 74342568,
      callsIn: 1260171,
      callsInDuration: 2560348,
      callsOut: 1098365,
      callsOutDuration: 1992887,
      smses: 103627,
    },
    {
      date: "2020-12-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 23811172,
      accounts: 570732,
      dataSent: 9960436,
      dataReceived: 76910158,
      callsIn: 1176629,
      callsInDuration: 2202238,
      callsOut: 1092929,
      callsOutDuration: 1810453,
      smses: 103291,
    },
    {
      date: "2020-12-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 21746651,
      accounts: 553654,
      dataSent: 9112841,
      dataReceived: 70080098,
      callsIn: 858525,
      callsInDuration: 1924067,
      callsOut: 819281,
      callsOutDuration: 1585807,
      smses: 88463,
    },
    {
      date: "2020-12-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 21940607,
      accounts: 560861,
      dataSent: 9508066,
      dataReceived: 70890960,
      callsIn: 1174867,
      callsInDuration: 2605803,
      callsOut: 1029805,
      callsOutDuration: 2025415,
      smses: 100707,
    },
    {
      date: "2020-12-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 22106421,
      accounts: 562109,
      dataSent: 9445613,
      dataReceived: 70771092,
      callsIn: 1185196,
      callsInDuration: 2604566,
      callsOut: 1027963,
      callsOutDuration: 2007234,
      smses: 98764,
    },
    {
      date: "2020-12-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 23183020,
      accounts: 567685,
      dataSent: 9829368,
      dataReceived: 75318212,
      callsIn: 1212825,
      callsInDuration: 2615867,
      callsOut: 1045709,
      callsOutDuration: 2032362,
      smses: 102132,
    },
    {
      date: "2020-12-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 23178670,
      accounts: 568262,
      dataSent: 9923181,
      dataReceived: 75418889,
      callsIn: 1209610,
      callsInDuration: 2605185,
      callsOut: 1047087,
      callsOutDuration: 2018164,
      smses: 103133,
    },
    {
      date: "2020-12-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 23570653,
      accounts: 575443,
      dataSent: 10027072,
      dataReceived: 75229010,
      callsIn: 1355546,
      callsInDuration: 2590870,
      callsOut: 1194069,
      callsOutDuration: 2054133,
      smses: 109613,
    },
    {
      date: "2020-12-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 24812069,
      accounts: 583227,
      dataSent: 10082589,
      dataReceived: 76462296,
      callsIn: 1306517,
      callsInDuration: 2246509,
      callsOut: 1206379,
      callsOutDuration: 1872168,
      smses: 104654,
    },
    {
      date: "2020-12-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20883720,
      accounts: 561775,
      dataSent: 8655654,
      dataReceived: 67748787,
      callsIn: 981564,
      callsInDuration: 1976887,
      callsOut: 921059,
      callsOutDuration: 1632274,
      smses: 91782,
    },
    {
      date: "2020-12-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 23299981,
      accounts: 573843,
      dataSent: 9634907,
      dataReceived: 73640235,
      callsIn: 1322093,
      callsInDuration: 2713028,
      callsOut: 1153278,
      callsOutDuration: 2112279,
      smses: 103137,
    },
    {
      date: "2020-12-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 22444711,
      accounts: 569768,
      dataSent: 9333371,
      dataReceived: 71147639,
      callsIn: 1292537,
      callsInDuration: 2609702,
      callsOut: 1120803,
      callsOutDuration: 2038257,
      smses: 104131,
    },
    {
      date: "2020-12-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 23791577,
      accounts: 579370,
      dataSent: 9709330,
      dataReceived: 76817606,
      callsIn: 1392168,
      callsInDuration: 2571237,
      callsOut: 1214883,
      callsOutDuration: 2022960,
      smses: 105335,
    },
    {
      date: "2020-12-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 25093423,
      accounts: 587493,
      dataSent: 10458579,
      dataReceived: 78528667,
      callsIn: 1490410,
      callsInDuration: 2363395,
      callsOut: 1341316,
      callsOutDuration: 1970998,
      smses: 99748,
    },
    {
      date: "2020-12-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 21049989,
      accounts: 556615,
      dataSent: 9135841,
      dataReceived: 71096585,
      callsIn: 838164,
      callsInDuration: 1868129,
      callsOut: 783737,
      callsOutDuration: 1565048,
      smses: 86786,
    },
    {
      date: "2020-12-26",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 21779551,
      accounts: 562967,
      dataSent: 9082872,
      dataReceived: 71457432,
      callsIn: 1007429,
      callsInDuration: 1959832,
      callsOut: 902240,
      callsOutDuration: 1579789,
      smses: 88498,
    },
    {
      date: "2020-12-27",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20064125,
      accounts: 552044,
      dataSent: 8427782,
      dataReceived: 66283301,
      callsIn: 806753,
      callsInDuration: 1846973,
      callsOut: 746378,
      callsOutDuration: 1502174,
      smses: 86238,
    },
    {
      date: "2020-12-28",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 21467703,
      accounts: 574580,
      dataSent: 9368835,
      dataReceived: 73247812,
      callsIn: 1197943,
      callsInDuration: 2487216,
      callsOut: 1035849,
      callsOutDuration: 1935511,
      smses: 100898,
    },
    {
      date: "2020-12-29",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21355963,
      accounts: 576180,
      dataSent: 9580484,
      dataReceived: 78524919,
      callsIn: 1226954,
      callsInDuration: 2403664,
      callsOut: 1060893,
      callsOutDuration: 1888067,
      smses: 102033,
    },
    {
      date: "2020-12-30",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 22507431,
      accounts: 582668,
      dataSent: 9654479,
      dataReceived: 78634784,
      callsIn: 1312855,
      callsInDuration: 2514606,
      callsOut: 1144154,
      callsOutDuration: 1989585,
      smses: 107162,
    },
    {
      date: "2020-12-31",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 24251549,
      accounts: 595085,
      dataSent: 11209392,
      dataReceived: 80461274,
      callsIn: 1456173,
      callsInDuration: 2198122,
      callsOut: 1356106,
      callsOutDuration: 1922041,
      smses: 107340,
    },
    {
      date: "2021-01-01",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 20268832,
      accounts: 554085,
      dataSent: 9910717,
      dataReceived: 75480578,
      callsIn: 776111,
      callsInDuration: 1843216,
      callsOut: 732700,
      callsOutDuration: 1561889,
      smses: 86170,
    },
    {
      date: "2021-01-02",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 19764663,
      accounts: 554856,
      dataSent: 8815738,
      dataReceived: 69100089,
      callsIn: 844357,
      callsInDuration: 1798525,
      callsOut: 772981,
      callsOutDuration: 1472755,
      smses: 88660,
    },
    {
      date: "2021-01-03",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 19072610,
      accounts: 547704,
      dataSent: 8549002,
      dataReceived: 66578361,
      callsIn: 715521,
      callsInDuration: 1737853,
      callsOut: 666048,
      callsOutDuration: 1413801,
      smses: 88349,
    },
    {
      date: "2021-01-04",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20695773,
      accounts: 567958,
      dataSent: 9164655,
      dataReceived: 70248462,
      callsIn: 1091801,
      callsInDuration: 2487606,
      callsOut: 960351,
      callsOutDuration: 1957574,
      smses: 101881,
    },
    {
      date: "2021-01-05",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20625475,
      accounts: 569681,
      dataSent: 9242760,
      dataReceived: 70446938,
      callsIn: 1110995,
      callsInDuration: 2542903,
      callsOut: 976099,
      callsOutDuration: 1994907,
      smses: 100473,
    },
    {
      date: "2021-01-06",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 21352991,
      accounts: 575431,
      dataSent: 9983630,
      dataReceived: 75847252,
      callsIn: 1144524,
      callsInDuration: 2565569,
      callsOut: 1003243,
      callsOutDuration: 2021502,
      smses: 99513,
    },
    {
      date: "2021-01-07",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 19891122,
      accounts: 574202,
      dataSent: 9800619,
      dataReceived: 74778344,
      callsIn: 1116364,
      callsInDuration: 2561518,
      callsOut: 977241,
      callsOutDuration: 1997862,
      smses: 97650,
    },
    {
      date: "2021-01-08",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 19882393,
      accounts: 574945,
      dataSent: 9674411,
      dataReceived: 74165535,
      callsIn: 1179908,
      callsInDuration: 2539430,
      callsOut: 1030001,
      callsOutDuration: 1981755,
      smses: 101795,
    },
    {
      date: "2021-01-09",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 20807432,
      accounts: 583282,
      dataSent: 9847592,
      dataReceived: 77637862,
      callsIn: 1094689,
      callsInDuration: 2153644,
      callsOut: 1004285,
      callsOutDuration: 1752662,
      smses: 101427,
    },
    {
      date: "2021-01-10",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 19140127,
      accounts: 563465,
      dataSent: 9214957,
      dataReceived: 71131965,
      callsIn: 827539,
      callsInDuration: 1925708,
      callsOut: 779869,
      callsOutDuration: 1587534,
      smses: 90056,
    },
    {
      date: "2021-01-11",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 19596204,
      accounts: 573809,
      dataSent: 9414330,
      dataReceived: 72385112,
      callsIn: 1146267,
      callsInDuration: 2587945,
      callsOut: 1002059,
      callsOutDuration: 2016616,
      smses: 99775,
    },
    {
      date: "2021-01-12",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 18855341,
      accounts: 568470,
      dataSent: 9174234,
      dataReceived: 70504996,
      callsIn: 1108099,
      callsInDuration: 2570950,
      callsOut: 959434,
      callsOutDuration: 1987201,
      smses: 99584,
    },
    {
      date: "2021-01-13",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 19249821,
      accounts: 572889,
      dataSent: 9506281,
      dataReceived: 73287470,
      callsIn: 1115991,
      callsInDuration: 2599326,
      callsOut: 969445,
      callsOutDuration: 2020541,
      smses: 100008,
    },
    {
      date: "2021-01-14",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 20370017,
      accounts: 578647,
      dataSent: 9986144,
      dataReceived: 77009260,
      callsIn: 1112308,
      callsInDuration: 2568831,
      callsOut: 971593,
      callsOutDuration: 1998998,
      smses: 98101,
    },
    {
      date: "2021-01-15",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 21020843,
      accounts: 583469,
      dataSent: 10142977,
      dataReceived: 78032512,
      callsIn: 1212962,
      callsInDuration: 2568256,
      callsOut: 1068860,
      callsOutDuration: 2018597,
      smses: 102771,
    },
    {
      date: "2021-01-16",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 20740048,
      accounts: 582779,
      dataSent: 9664848,
      dataReceived: 75363334,
      callsIn: 1044496,
      callsInDuration: 1865958,
      callsOut: 963910,
      callsOutDuration: 1541765,
      smses: 103021,
    },
    {
      date: "2021-01-17",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 19081180,
      accounts: 566658,
      dataSent: 8913212,
      dataReceived: 68453138,
      callsIn: 809435,
      callsInDuration: 1826163,
      callsOut: 766737,
      callsOutDuration: 1520633,
      smses: 92556,
    },
    {
      date: "2021-01-18",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 18623451,
      accounts: 572363,
      dataSent: 9366842,
      dataReceived: 71846888,
      callsIn: 1129356,
      callsInDuration: 2572666,
      callsOut: 985277,
      callsOutDuration: 1996588,
      smses: 102289,
    },
    {
      date: "2021-01-19",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 19869486,
      accounts: 573112,
      dataSent: 9748912,
      dataReceived: 74395085,
      callsIn: 1107984,
      callsInDuration: 2565349,
      callsOut: 964736,
      callsOutDuration: 2005819,
      smses: 98622,
    },
    {
      date: "2021-01-20",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 19845525,
      accounts: 576524,
      dataSent: 9882159,
      dataReceived: 75954509,
      callsIn: 1129229,
      callsInDuration: 2593876,
      callsOut: 987300,
      callsOutDuration: 2025970,
      smses: 101578,
    },
    {
      date: "2021-01-21",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 19148579,
      accounts: 572470,
      dataSent: 9431356,
      dataReceived: 72791277,
      callsIn: 1118723,
      callsInDuration: 2581816,
      callsOut: 979803,
      callsOutDuration: 2018934,
      smses: 99535,
    },
    {
      date: "2021-01-22",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 20108298,
      accounts: 578264,
      dataSent: 10022086,
      dataReceived: 77056965,
      callsIn: 1183879,
      callsInDuration: 2532997,
      callsOut: 1044712,
      callsOutDuration: 2003724,
      smses: 105301,
    },
    {
      date: "2021-01-23",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 19216885,
      accounts: 573688,
      dataSent: 8326695,
      dataReceived: 64638001,
      callsIn: 1065826,
      callsInDuration: 1921775,
      callsOut: 990424,
      callsOutDuration: 1605618,
      smses: 101830,
    },
    {
      date: "2021-01-24",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 16478782,
      accounts: 553831,
      dataSent: 8750427,
      dataReceived: 67851006,
      callsIn: 785570,
      callsInDuration: 1828641,
      callsOut: 740886,
      callsOutDuration: 1511216,
      smses: 90019,
    },
    {
      date: "2021-01-25",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 19448372,
      accounts: 576061,
      dataSent: 9637575,
      dataReceived: 73469904,
      callsIn: 1133136,
      callsInDuration: 2563253,
      callsOut: 991119,
      callsOutDuration: 2004364,
      smses: 100241,
    },
    {
      date: "2021-01-26",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20077523,
      accounts: 575435,
      dataSent: 9860319,
      dataReceived: 75923435,
      callsIn: 1108497,
      callsInDuration: 2548884,
      callsOut: 958057,
      callsOutDuration: 1972641,
      smses: 96222,
    },
    {
      date: "2021-01-27",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 20270678,
      accounts: 575582,
      dataSent: 9750214,
      dataReceived: 81903115,
      callsIn: 1086397,
      callsInDuration: 2508826,
      callsOut: 927293,
      callsOutDuration: 1935151,
      smses: 91170,
    },
    {
      date: "2021-01-28",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 19569480,
      accounts: 571380,
      dataSent: 8991728,
      dataReceived: 71961712,
      callsIn: 1082145,
      callsInDuration: 2443903,
      callsOut: 923335,
      callsOutDuration: 1874640,
      smses: 92934,
    },
    {
      date: "2021-01-29",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 20745829,
      accounts: 583417,
      dataSent: 10616056,
      dataReceived: 84783888,
      callsIn: 1201246,
      callsInDuration: 2495017,
      callsOut: 1049050,
      callsOutDuration: 1951264,
      smses: 99297,
    },
    {
      date: "2021-01-30",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 18889045,
      accounts: 570668,
      dataSent: 7828963,
      dataReceived: 62353292,
      callsIn: 1138335,
      callsInDuration: 2217265,
      callsOut: 1042476,
      callsOutDuration: 1815147,
      smses: 96780,
    },
    {
      date: "2021-01-31",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 17218410,
      accounts: 552842,
      dataSent: 7094108,
      dataReceived: 55647720,
      callsIn: 863580,
      callsInDuration: 1856690,
      callsOut: 817915,
      callsOutDuration: 1527827,
      smses: 87782,
    },
    {
      date: "2021-02-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20146151,
      accounts: 585452,
      dataSent: 10638133,
      dataReceived: 84121509,
      callsIn: 1161279,
      callsInDuration: 2543876,
      callsOut: 1037571,
      callsOutDuration: 1992426,
      smses: 100110,
    },
    {
      date: "2021-02-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20026148,
      accounts: 580470,
      dataSent: 9779119,
      dataReceived: 76775656,
      callsIn: 1148634,
      callsInDuration: 2555952,
      callsOut: 1021150,
      callsOutDuration: 2009034,
      smses: 97855,
    },
    {
      date: "2021-02-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 20171041,
      accounts: 582077,
      dataSent: 9792151,
      dataReceived: 77066211,
      callsIn: 1151955,
      callsInDuration: 2565114,
      callsOut: 1015786,
      callsOutDuration: 2006736,
      smses: 100002,
    },
    {
      date: "2021-02-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 20004947,
      accounts: 580924,
      dataSent: 9947711,
      dataReceived: 80092220,
      callsIn: 1136945,
      callsInDuration: 2547431,
      callsOut: 1006521,
      callsOutDuration: 2003185,
      smses: 98248,
    },
    {
      date: "2021-02-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 20635102,
      accounts: 589221,
      dataSent: 10070057,
      dataReceived: 79554511,
      callsIn: 1227018,
      callsInDuration: 2584208,
      callsOut: 1111351,
      callsOutDuration: 2051717,
      smses: 104022,
    },
    {
      date: "2021-02-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 21366016,
      accounts: 596950,
      dataSent: 9835947,
      dataReceived: 78909762,
      callsIn: 1107948,
      callsInDuration: 1921759,
      callsOut: 1055009,
      callsOutDuration: 1618024,
      smses: 105181,
    },
    {
      date: "2021-02-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 22012008,
      accounts: 620176,
      dataSent: 9940275,
      dataReceived: 80067252,
      callsIn: 1353118,
      callsInDuration: 2153240,
      callsOut: 1384628,
      callsOutDuration: 1899729,
      smses: 128363,
    },
    {
      date: "2021-02-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20265322,
      accounts: 590667,
      dataSent: 9356147,
      dataReceived: 74020228,
      callsIn: 1173020,
      callsInDuration: 2599851,
      callsOut: 1026215,
      callsOutDuration: 2017952,
      smses: 101773,
    },
    {
      date: "2021-02-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20476060,
      accounts: 591539,
      dataSent: 10229194,
      dataReceived: 80765454,
      callsIn: 1158156,
      callsInDuration: 2560252,
      callsOut: 1007927,
      callsOutDuration: 1990499,
      smses: 102077,
    },
    {
      date: "2021-02-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 19763759,
      accounts: 587248,
      dataSent: 9241614,
      dataReceived: 71717311,
      callsIn: 1182542,
      callsInDuration: 2596016,
      callsOut: 1025748,
      callsOutDuration: 2015325,
      smses: 98467,
    },
    {
      date: "2021-02-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21096180,
      accounts: 592372,
      dataSent: 10866036,
      dataReceived: 85493187,
      callsIn: 1181145,
      callsInDuration: 2618643,
      callsOut: 1022621,
      callsOutDuration: 2043884,
      smses: 98869,
    },
    {
      date: "2021-02-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 21871416,
      accounts: 599253,
      dataSent: 10662943,
      dataReceived: 84071443,
      callsIn: 1308597,
      callsInDuration: 2610654,
      callsOut: 1145686,
      callsOutDuration: 2064674,
      smses: 105084,
    },
    {
      date: "2021-02-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 22621515,
      accounts: 605008,
      dataSent: 10699136,
      dataReceived: 84424879,
      callsIn: 1261757,
      callsInDuration: 2267828,
      callsOut: 1156378,
      callsOutDuration: 1868538,
      smses: 104871,
    },
    {
      date: "2021-02-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20492362,
      accounts: 588768,
      dataSent: 10086332,
      dataReceived: 78953044,
      callsIn: 968523,
      callsInDuration: 1848305,
      callsOut: 915136,
      callsOutDuration: 1558737,
      smses: 104837,
    },
    {
      date: "2021-02-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 19952084,
      accounts: 573716,
      dataSent: 9561650,
      dataReceived: 76572121,
      callsIn: 891442,
      callsInDuration: 1884699,
      callsOut: 809519,
      callsOutDuration: 1534286,
      smses: 92369,
    },
    {
      date: "2021-02-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 18473234,
      accounts: 564533,
      dataSent: 8143530,
      dataReceived: 65782133,
      callsIn: 820627,
      callsInDuration: 1811129,
      callsOut: 740110,
      callsOutDuration: 1457539,
      smses: 90409,
    },
    {
      date: "2021-02-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 20665192,
      accounts: 593183,
      dataSent: 10905690,
      dataReceived: 84541763,
      callsIn: 1152813,
      callsInDuration: 2577034,
      callsOut: 989929,
      callsOutDuration: 1998767,
      smses: 105666,
    },
    {
      date: "2021-02-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 20358377,
      accounts: 588614,
      dataSent: 10058358,
      dataReceived: 76954956,
      callsIn: 1150466,
      callsInDuration: 2624146,
      callsOut: 977169,
      callsOutDuration: 2020017,
      smses: 103599,
    },
    {
      date: "2021-02-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 21204838,
      accounts: 592079,
      dataSent: 10196851,
      dataReceived: 79380989,
      callsIn: 1207463,
      callsInDuration: 2569038,
      callsOut: 1043362,
      callsOutDuration: 2016827,
      smses: 107814,
    },
    {
      date: "2021-02-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 21113948,
      accounts: 595312,
      dataSent: 10310382,
      dataReceived: 82300833,
      callsIn: 1107920,
      callsInDuration: 2224697,
      callsOut: 1005779,
      callsOutDuration: 1793260,
      smses: 99661,
    },
    {
      date: "2021-02-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 19254041,
      accounts: 578323,
      dataSent: 8772050,
      dataReceived: 68536727,
      callsIn: 816886,
      callsInDuration: 1936144,
      callsOut: 760872,
      callsOutDuration: 1568464,
      smses: 88220,
    },
    {
      date: "2021-02-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 21195920,
      accounts: 595870,
      dataSent: 10717659,
      dataReceived: 81920584,
      callsIn: 1141516,
      callsInDuration: 2586145,
      callsOut: 983758,
      callsOutDuration: 2000531,
      smses: 103140,
    },
    {
      date: "2021-02-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20748590,
      accounts: 590476,
      dataSent: 9195687,
      dataReceived: 71914306,
      callsIn: 1131523,
      callsInDuration: 2571316,
      callsOut: 963861,
      callsOutDuration: 1991497,
      smses: 95528,
    },
    {
      date: "2021-02-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 20691048,
      accounts: 597688,
      dataSent: 10063855,
      dataReceived: 78054201,
      callsIn: 1128664,
      callsInDuration: 2549494,
      callsOut: 947184,
      callsOutDuration: 1935006,
      smses: 94726,
    },
    {
      date: "2021-02-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 22241235,
      accounts: 600873,
      dataSent: 10459043,
      dataReceived: 83201424,
      callsIn: 1134391,
      callsInDuration: 2571609,
      callsOut: 959237,
      callsOutDuration: 1965478,
      smses: 95473,
    },
    {
      date: "2021-02-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22076964,
      accounts: 606142,
      dataSent: 11397776,
      dataReceived: 90891111,
      callsIn: 1236089,
      callsInDuration: 2565959,
      callsOut: 1058961,
      callsOutDuration: 1990979,
      smses: 99985,
    },
    {
      date: "2021-02-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 22910066,
      accounts: 611050,
      dataSent: 10660432,
      dataReceived: 82424800,
      callsIn: 1157062,
      callsInDuration: 2226664,
      callsOut: 1041780,
      callsOutDuration: 1803451,
      smses: 100494,
    },
    {
      date: "2021-02-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20521051,
      accounts: 591809,
      dataSent: 9718133,
      dataReceived: 74617147,
      callsIn: 858811,
      callsInDuration: 1944918,
      callsOut: 791564,
      callsOutDuration: 1598214,
      smses: 89110,
    },
    {
      date: "2021-03-01",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20935341,
      accounts: 598422,
      dataSent: 9709109,
      dataReceived: 73815800,
      callsIn: 1170406,
      callsInDuration: 2627851,
      callsOut: 1006610,
      callsOutDuration: 2041976,
      smses: 99986,
    },
    {
      date: "2021-03-02",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21543253,
      accounts: 596777,
      dataSent: 9317788,
      dataReceived: 70598958,
      callsIn: 1139863,
      callsInDuration: 2552717,
      callsOut: 959087,
      callsOutDuration: 1951678,
      smses: 100220,
    },
    {
      date: "2021-03-03",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 22598430,
      accounts: 602722,
      dataSent: 11080924,
      dataReceived: 84401955,
      callsIn: 1181998,
      callsInDuration: 2610095,
      callsOut: 992759,
      callsOutDuration: 1988178,
      smses: 100244,
    },
    {
      date: "2021-03-04",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21409070,
      accounts: 600459,
      dataSent: 9445414,
      dataReceived: 71153756,
      callsIn: 1179609,
      callsInDuration: 2572785,
      callsOut: 1009965,
      callsOutDuration: 1998012,
      smses: 96440,
    },
    {
      date: "2021-03-05",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 21224858,
      accounts: 605100,
      dataSent: 10973618,
      dataReceived: 84254484,
      callsIn: 1268942,
      callsInDuration: 2584117,
      callsOut: 1107813,
      callsOutDuration: 2039736,
      smses: 103468,
    },
    {
      date: "2021-03-06",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 19138616,
      accounts: 611986,
      dataSent: 8409692,
      dataReceived: 64934561,
      callsIn: 1198295,
      callsInDuration: 2233060,
      callsOut: 1102536,
      callsOutDuration: 1844374,
      smses: 101986,
    },
    {
      date: "2021-03-07",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 18189446,
      accounts: 579607,
      dataSent: 9280245,
      dataReceived: 72376184,
      callsIn: 846883,
      callsInDuration: 1937113,
      callsOut: 798161,
      callsOutDuration: 1594202,
      smses: 91445,
    },
    {
      date: "2021-03-08",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 21039302,
      accounts: 602439,
      dataSent: 10554701,
      dataReceived: 79502880,
      callsIn: 1238619,
      callsInDuration: 2584678,
      callsOut: 1066717,
      callsOutDuration: 2015598,
      smses: 109619,
    },
    {
      date: "2021-03-09",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20684564,
      accounts: 595341,
      dataSent: 10015324,
      dataReceived: 77842868,
      callsIn: 1193437,
      callsInDuration: 2658174,
      callsOut: 1010844,
      callsOutDuration: 2032511,
      smses: 99877,
    },
    {
      date: "2021-03-10",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 20590501,
      accounts: 598154,
      dataSent: 9232450,
      dataReceived: 71648679,
      callsIn: 1212134,
      callsInDuration: 2613834,
      callsOut: 1028634,
      callsOutDuration: 2003333,
      smses: 100126,
    },
    {
      date: "2021-03-11",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21515238,
      accounts: 606557,
      dataSent: 11037855,
      dataReceived: 84912469,
      callsIn: 1207350,
      callsInDuration: 2636777,
      callsOut: 1023163,
      callsOutDuration: 2023891,
      smses: 96221,
    },
    {
      date: "2021-03-12",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22289842,
      accounts: 612331,
      dataSent: 11004253,
      dataReceived: 84863634,
      callsIn: 1306265,
      callsInDuration: 2634106,
      callsOut: 1120319,
      callsOutDuration: 2039368,
      smses: 102884,
    },
    {
      date: "2021-03-13",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 22388416,
      accounts: 613361,
      dataSent: 10834204,
      dataReceived: 82835633,
      callsIn: 1185061,
      callsInDuration: 2217900,
      callsOut: 1086668,
      callsOutDuration: 1802067,
      smses: 101402,
    },
    {
      date: "2021-03-14",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20667643,
      accounts: 597296,
      dataSent: 9860599,
      dataReceived: 75633859,
      callsIn: 880547,
      callsInDuration: 1985897,
      callsOut: 824524,
      callsOutDuration: 1610134,
      smses: 91695,
    },
    {
      date: "2021-03-15",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 21126008,
      accounts: 605534,
      dataSent: 9810582,
      dataReceived: 74387861,
      callsIn: 1218627,
      callsInDuration: 2667334,
      callsOut: 1060861,
      callsOutDuration: 2058943,
      smses: 102157,
    },
    {
      date: "2021-03-16",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21581712,
      accounts: 609295,
      dataSent: 10927478,
      dataReceived: 83647120,
      callsIn: 1200926,
      callsInDuration: 2674170,
      callsOut: 1040602,
      callsOutDuration: 2065274,
      smses: 101835,
    },
    {
      date: "2021-03-17",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 21245831,
      accounts: 606590,
      dataSent: 10428530,
      dataReceived: 78496871,
      callsIn: 602714,
      callsInDuration: 1621832,
      callsOut: 1039847,
      callsOutDuration: 2058319,
      smses: 99681,
    },
    {
      date: "2021-03-18",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21753822,
      accounts: 609157,
      dataSent: 10648465,
      dataReceived: 81385482,
      callsIn: 1145456,
      callsInDuration: 2472491,
      callsOut: 1037057,
      callsOutDuration: 2062577,
      smses: 98029,
    },
    {
      date: "2021-03-19",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22658648,
      accounts: 627097,
      dataSent: 10491681,
      dataReceived: 80021914,
      callsIn: 1308991,
      callsInDuration: 2622572,
      callsOut: 1204396,
      callsOutDuration: 2148402,
      smses: 160623,
    },
    {
      date: "2021-03-20",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 22883149,
      accounts: 621768,
      dataSent: 10986289,
      dataReceived: 86406501,
      callsIn: 1146300,
      callsInDuration: 2138534,
      callsOut: 1054896,
      callsOutDuration: 1743437,
      smses: 105185,
    },
    {
      date: "2021-03-21",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20399455,
      accounts: 600436,
      dataSent: 9817514,
      dataReceived: 75579983,
      callsIn: 869794,
      callsInDuration: 1937154,
      callsOut: 822475,
      callsOutDuration: 1576128,
      smses: 89574,
    },
    {
      date: "2021-03-22",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20919742,
      accounts: 609464,
      dataSent: 9768874,
      dataReceived: 74937223,
      callsIn: 1205896,
      callsInDuration: 2645926,
      callsOut: 1045989,
      callsOutDuration: 2043717,
      smses: 101206,
    },
    {
      date: "2021-03-23",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21411117,
      accounts: 613116,
      dataSent: 11023159,
      dataReceived: 84089875,
      callsIn: 1183295,
      callsInDuration: 2630329,
      callsOut: 1021731,
      callsOutDuration: 2014054,
      smses: 95828,
    },
    {
      date: "2021-03-24",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 21226373,
      accounts: 611891,
      dataSent: 10759934,
      dataReceived: 82496921,
      callsIn: 1196267,
      callsInDuration: 2688989,
      callsOut: 1020683,
      callsOutDuration: 2039759,
      smses: 98086,
    },
    {
      date: "2021-03-25",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21030414,
      accounts: 609991,
      dataSent: 10459198,
      dataReceived: 80666162,
      callsIn: 1194234,
      callsInDuration: 2656672,
      callsOut: 1019410,
      callsOutDuration: 2024593,
      smses: 96751,
    },
    {
      date: "2021-03-26",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22234370,
      accounts: 619082,
      dataSent: 10929442,
      dataReceived: 84748805,
      callsIn: 1274839,
      callsInDuration: 2599936,
      callsOut: 1098132,
      callsOutDuration: 1999051,
      smses: 101529,
    },
    {
      date: "2021-03-27",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 22345462,
      accounts: 620770,
      dataSent: 10864895,
      dataReceived: 84992128,
      callsIn: 1182452,
      callsInDuration: 2265441,
      callsOut: 1067525,
      callsOutDuration: 1812449,
      smses: 99677,
    },
    {
      date: "2021-03-28",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 20091154,
      accounts: 599560,
      dataSent: 9883235,
      dataReceived: 76556606,
      callsIn: 847027,
      callsInDuration: 1998435,
      callsOut: 786033,
      callsOutDuration: 1589497,
      smses: 85930,
    },
    {
      date: "2021-03-29",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20416878,
      accounts: 609234,
      dataSent: 10101648,
      dataReceived: 79558537,
      callsIn: 1193803,
      callsInDuration: 2707085,
      callsOut: 1011918,
      callsOutDuration: 2054141,
      smses: 96331,
    },
    {
      date: "2021-03-30",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21138851,
      accounts: 611205,
      dataSent: 10436430,
      dataReceived: 81798654,
      callsIn: 1191159,
      callsInDuration: 2688579,
      callsOut: 1002931,
      callsOutDuration: 2039570,
      smses: 96581,
    },
    {
      date: "2021-03-31",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 21291620,
      accounts: 611769,
      dataSent: 10592732,
      dataReceived: 81137659,
      callsIn: 1230054,
      callsInDuration: 2738528,
      callsOut: 1042797,
      callsOutDuration: 2086422,
      smses: 98200,
    },
    {
      date: "2021-04-01",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21601504,
      accounts: 614874,
      dataSent: 11019548,
      dataReceived: 83463512,
      callsIn: 1252853,
      callsInDuration: 2648827,
      callsOut: 1083551,
      callsOutDuration: 2041844,
      smses: 97282,
    },
    {
      date: "2021-04-02",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 19122360,
      accounts: 589662,
      dataSent: 9916977,
      dataReceived: 75715487,
      callsIn: 880688,
      callsInDuration: 2084823,
      callsOut: 801288,
      callsOutDuration: 1633781,
      smses: 84149,
    },
    {
      date: "2021-04-03",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 20165018,
      accounts: 601414,
      dataSent: 9575983,
      dataReceived: 74268079,
      callsIn: 985236,
      callsInDuration: 2086826,
      callsOut: 901968,
      callsOutDuration: 1664171,
      smses: 90326,
    },
    {
      date: "2021-04-04",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 17117234,
      accounts: 574760,
      dataSent: 8172390,
      dataReceived: 63894705,
      callsIn: 747172,
      callsInDuration: 1867811,
      callsOut: 697941,
      callsOutDuration: 1472213,
      smses: 79929,
    },
    {
      date: "2021-04-05",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20571467,
      accounts: 609085,
      dataSent: 10844744,
      dataReceived: 83926922,
      callsIn: 1184111,
      callsInDuration: 2745002,
      callsOut: 1038682,
      callsOutDuration: 2119090,
      smses: 101174,
    },
    {
      date: "2021-04-06",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20634844,
      accounts: 603733,
      dataSent: 9732249,
      dataReceived: 76317283,
      callsIn: 1169792,
      callsInDuration: 2578931,
      callsOut: 1026440,
      callsOutDuration: 1984863,
      smses: 98128,
    },
    {
      date: "2021-04-07",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 20909420,
      accounts: 603646,
      dataSent: 10246371,
      dataReceived: 79147079,
      callsIn: 1170079,
      callsInDuration: 2550298,
      callsOut: 1017135,
      callsOutDuration: 1958192,
      smses: 93702,
    },
    {
      date: "2021-04-08",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 21462459,
      accounts: 608640,
      dataSent: 11549532,
      dataReceived: 86422832,
      callsIn: 1177756,
      callsInDuration: 2614194,
      callsOut: 1027612,
      callsOutDuration: 2014803,
      smses: 99702,
    },
    {
      date: "2021-04-09",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 21951326,
      accounts: 608796,
      dataSent: 11691595,
      dataReceived: 87762295,
      callsIn: 1258389,
      callsInDuration: 2649153,
      callsOut: 1102380,
      callsOutDuration: 2068736,
      smses: 104128,
    },
    {
      date: "2021-04-10",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 21970380,
      accounts: 610487,
      dataSent: 10448037,
      dataReceived: 79427482,
      callsIn: 1223087,
      callsInDuration: 2296645,
      callsOut: 1132385,
      callsOutDuration: 1858069,
      smses: 101261,
    },
    {
      date: "2021-04-11",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 21553816,
      accounts: 622303,
      dataSent: 9778262,
      dataReceived: 73731286,
      callsIn: 1163385,
      callsInDuration: 2010731,
      callsOut: 1140272,
      callsOutDuration: 1685282,
      smses: 107508,
    },
    {
      date: "2021-04-12",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 6750340,
      accounts: 452850,
      dataSent: 1959403,
      dataReceived: 14408163,
      callsIn: 360112,
      callsInDuration: 656727,
      callsOut: 315788,
      callsOutDuration: 523578,
      smses: 28092,
    },
    {
      date: "2021-04-13",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20654072,
      accounts: 616362,
      dataSent: 11453113,
      dataReceived: 85298914,
      callsIn: 1193341,
      callsInDuration: 2530134,
      callsOut: 1041754,
      callsOutDuration: 1952363,
      smses: 96152,
    },
    {
      date: "2021-04-14",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 21415883,
      accounts: 614822,
      dataSent: 11725255,
      dataReceived: 92504080,
      callsIn: 1200600,
      callsInDuration: 2695074,
      callsOut: 1039318,
      callsOutDuration: 2071333,
      smses: 100133,
    },
    {
      date: "2021-04-15",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 20799854,
      accounts: 608825,
      dataSent: 10639251,
      dataReceived: 83526188,
      callsIn: 1215512,
      callsInDuration: 2702194,
      callsOut: 1063822,
      callsOutDuration: 2089330,
      smses: 101756,
    },
    {
      date: "2021-04-16",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 21895682,
      accounts: 614788,
      dataSent: 10890405,
      dataReceived: 86289641,
      callsIn: 1315897,
      callsInDuration: 2698850,
      callsOut: 1154777,
      callsOutDuration: 2114925,
      smses: 101448,
    },
    {
      date: "2021-04-17",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 21385077,
      accounts: 610845,
      dataSent: 10689872,
      dataReceived: 84989487,
      callsIn: 1204549,
      callsInDuration: 2326878,
      callsOut: 1104580,
      callsOutDuration: 1883913,
      smses: 98667,
    },
    {
      date: "2021-04-18",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 19118507,
      accounts: 594399,
      dataSent: 9577502,
      dataReceived: 75876795,
      callsIn: 862246,
      callsInDuration: 1993557,
      callsOut: 832737,
      callsOutDuration: 1628602,
      smses: 85360,
    },
    {
      date: "2021-04-19",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 20503841,
      accounts: 602980,
      dataSent: 10207797,
      dataReceived: 80151381,
      callsIn: 1205387,
      callsInDuration: 2731271,
      callsOut: 1061311,
      callsOutDuration: 2126391,
      smses: 97403,
    },
    {
      date: "2021-04-20",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 20398800,
      accounts: 602716,
      dataSent: 9962603,
      dataReceived: 78965178,
      callsIn: 1196957,
      callsInDuration: 2631333,
      callsOut: 1053136,
      callsOutDuration: 2043319,
      smses: 98435,
    },
    {
      date: "2021-04-21",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 20492204,
      accounts: 597099,
      dataSent: 8638403,
      dataReceived: 66986739,
      callsIn: 1182468,
      callsInDuration: 2679865,
      callsOut: 1037316,
      callsOutDuration: 2080465,
      smses: 96115,
    },
    {
      date: "2021-04-22",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 23197840,
      accounts: 612845,
      dataSent: 11447668,
      dataReceived: 89550636,
      callsIn: 1246224,
      callsInDuration: 2728836,
      callsOut: 1100067,
      callsOutDuration: 2133652,
      smses: 100795,
    },
    {
      date: "2021-04-23",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 24116768,
      accounts: 617576,
      dataSent: 11411363,
      dataReceived: 91109930,
      callsIn: 1426993,
      callsInDuration: 2770504,
      callsOut: 1281152,
      callsOutDuration: 2189003,
      smses: 105453,
    },
    {
      date: "2021-04-24",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 14564756,
      accounts: 520672,
      dataSent: 8632014,
      dataReceived: 70488936,
      callsIn: 562063,
      callsInDuration: 1980160,
      callsOut: 494777,
      callsOutDuration: 1502572,
      smses: 64867,
    },
    {
      date: "2021-04-25",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 12575395,
      accounts: 492700,
      dataSent: 8018546,
      dataReceived: 63167856,
      callsIn: 469623,
      callsInDuration: 1828182,
      callsOut: 436839,
      callsOutDuration: 1416217,
      smses: 62786,
    },
    {
      date: "2021-04-26",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 21810924,
      accounts: 602587,
      dataSent: 10801338,
      dataReceived: 80491500,
      callsIn: 1270888,
      callsInDuration: 2744247,
      callsOut: 1115314,
      callsOutDuration: 2125121,
      smses: 105718,
    },
    {
      date: "2021-04-27",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 21685239,
      accounts: 600713,
      dataSent: 10785743,
      dataReceived: 81594134,
      callsIn: 1213734,
      callsInDuration: 2765148,
      callsOut: 1051364,
      callsOutDuration: 2115483,
      smses: 101016,
    },
    {
      date: "2021-04-28",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 21932252,
      accounts: 600271,
      dataSent: 10549835,
      dataReceived: 82015121,
      callsIn: 1232971,
      callsInDuration: 2790066,
      callsOut: 1070730,
      callsOutDuration: 2150480,
      smses: 96750,
    },
    {
      date: "2021-04-29",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 22514117,
      accounts: 606237,
      dataSent: 11115052,
      dataReceived: 84375195,
      callsIn: 1296536,
      callsInDuration: 2773942,
      callsOut: 1137849,
      callsOutDuration: 2162737,
      smses: 100980,
    },
    {
      date: "2021-04-30",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 22716953,
      accounts: 606408,
      dataSent: 10508698,
      dataReceived: 82403336,
      callsIn: 1282874,
      callsInDuration: 2467583,
      callsOut: 1169226,
      callsOutDuration: 1980120,
      smses: 100206,
    },
    {
      date: "2021-05-01",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 15942810,
      accounts: 523946,
      dataSent: 9039710,
      dataReceived: 73880496,
      callsIn: 557503,
      callsInDuration: 1868325,
      callsOut: 514485,
      callsOutDuration: 1455111,
      smses: 63825,
    },
    {
      date: "2021-05-02",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 14000751,
      accounts: 504583,
      dataSent: 8362550,
      dataReceived: 66328745,
      callsIn: 480474,
      callsInDuration: 1759948,
      callsOut: 448828,
      callsOutDuration: 1366680,
      smses: 60874,
    },
    {
      date: "2021-05-03",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 22400589,
      accounts: 605720,
      dataSent: 10768120,
      dataReceived: 79288873,
      callsIn: 1314472,
      callsInDuration: 2743711,
      callsOut: 1174690,
      callsOutDuration: 2167304,
      smses: 101408,
    },
    {
      date: "2021-05-04",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 25152708,
      accounts: 607428,
      dataSent: 10759038,
      dataReceived: 83733226,
      callsIn: 1276086,
      callsInDuration: 2763569,
      callsOut: 1131035,
      callsOutDuration: 2178091,
      smses: 96660,
    },
    {
      date: "2021-05-05",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 26638848,
      accounts: 610205,
      dataSent: 10569807,
      dataReceived: 80824427,
      callsIn: 1280352,
      callsInDuration: 2799839,
      callsOut: 1123066,
      callsOutDuration: 2190650,
      smses: 96523,
    },
    {
      date: "2021-05-06",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 28278688,
      accounts: 619658,
      dataSent: 11572372,
      dataReceived: 88251102,
      callsIn: 1323095,
      callsInDuration: 2744259,
      callsOut: 1185533,
      callsOutDuration: 2198034,
      smses: 101287,
    },
    {
      date: "2021-05-07",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 30299987,
      accounts: 628946,
      dataSent: 11783442,
      dataReceived: 91875826,
      callsIn: 1573918,
      callsInDuration: 2796471,
      callsOut: 1474893,
      callsOutDuration: 2322502,
      smses: 106869,
    },
    {
      date: "2021-05-08",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 17746450,
      accounts: 539021,
      dataSent: 9414049,
      dataReceived: 77047800,
      callsIn: 645313,
      callsInDuration: 1889700,
      callsOut: 602940,
      callsOutDuration: 1515132,
      smses: 67489,
    },
    {
      date: "2021-05-09",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 17710009,
      accounts: 531793,
      dataSent: 9547357,
      dataReceived: 72510863,
      callsIn: 651899,
      callsInDuration: 1920373,
      callsOut: 676435,
      callsOutDuration: 1713413,
      smses: 69624,
    },
    {
      date: "2021-05-10",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 27031314,
      accounts: 612133,
      dataSent: 10599559,
      dataReceived: 81404106,
      callsIn: 1316974,
      callsInDuration: 2730858,
      callsOut: 1202889,
      callsOutDuration: 2198000,
      smses: 99692,
    },
    {
      date: "2021-05-11",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 25493243,
      accounts: 604216,
      dataSent: 10134195,
      dataReceived: 79476239,
      callsIn: 1238185,
      callsInDuration: 2692362,
      callsOut: 1113576,
      callsOutDuration: 2141780,
      smses: 94406,
    },
    {
      date: "2021-05-12",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 26373151,
      accounts: 605685,
      dataSent: 10701050,
      dataReceived: 81928708,
      callsIn: 1236109,
      callsInDuration: 2715465,
      callsOut: 1103133,
      callsOutDuration: 2156346,
      smses: 92683,
    },
    {
      date: "2021-05-13",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 26690622,
      accounts: 609917,
      dataSent: 10613200,
      dataReceived: 82132725,
      callsIn: 1244555,
      callsInDuration: 2684036,
      callsOut: 1109582,
      callsOutDuration: 2130568,
      smses: 92507,
    },
    {
      date: "2021-05-14",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 29478705,
      accounts: 622462,
      dataSent: 11565033,
      dataReceived: 91481190,
      callsIn: 1460618,
      callsInDuration: 2695438,
      callsOut: 1336844,
      callsOutDuration: 2204418,
      smses: 104462,
    },
    {
      date: "2021-05-15",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 17710148,
      accounts: 535966,
      dataSent: 9047062,
      dataReceived: 74948154,
      callsIn: 620536,
      callsInDuration: 1895351,
      callsOut: 572180,
      callsOutDuration: 1513568,
      smses: 68215,
    },
    {
      date: "2021-05-16",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 15838147,
      accounts: 512864,
      dataSent: 8004146,
      dataReceived: 65711183,
      callsIn: 498082,
      callsInDuration: 1747663,
      callsOut: 478255,
      callsOutDuration: 1399790,
      smses: 62229,
    },
    {
      date: "2021-05-17",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 26863989,
      accounts: 609066,
      dataSent: 10531352,
      dataReceived: 80639038,
      callsIn: 1290371,
      callsInDuration: 2668041,
      callsOut: 1169320,
      callsOutDuration: 2141823,
      smses: 103719,
    },
    {
      date: "2021-05-18",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 27053024,
      accounts: 607986,
      dataSent: 10498958,
      dataReceived: 80847907,
      callsIn: 1241422,
      callsInDuration: 2662261,
      callsOut: 1120765,
      callsOutDuration: 2148253,
      smses: 93135,
    },
    {
      date: "2021-05-19",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 27011835,
      accounts: 606399,
      dataSent: 10526198,
      dataReceived: 82017568,
      callsIn: 1204936,
      callsInDuration: 2641872,
      callsOut: 1082176,
      callsOutDuration: 2103293,
      smses: 88550,
    },
    {
      date: "2021-05-20",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 27238517,
      accounts: 610370,
      dataSent: 10748722,
      dataReceived: 85610059,
      callsIn: 1245975,
      callsInDuration: 2676583,
      callsOut: 1106141,
      callsOutDuration: 2138814,
      smses: 91044,
    },
    {
      date: "2021-05-21",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 28397656,
      accounts: 616972,
      dataSent: 10831989,
      dataReceived: 85112783,
      callsIn: 1364518,
      callsInDuration: 2650499,
      callsOut: 1244083,
      callsOutDuration: 2151210,
      smses: 99706,
    },
    {
      date: "2021-05-22",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 28215326,
      accounts: 613785,
      dataSent: 10723888,
      dataReceived: 86694911,
      callsIn: 1192996,
      callsInDuration: 2218752,
      callsOut: 1131779,
      callsOutDuration: 1870857,
      smses: 93464,
    },
    {
      date: "2021-05-23",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 23287899,
      accounts: 588786,
      dataSent: 9296485,
      dataReceived: 76213211,
      callsIn: 845800,
      callsInDuration: 1891763,
      callsOut: 826718,
      callsOutDuration: 1605535,
      smses: 82085,
    },
    {
      date: "2021-05-24",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 24898857,
      accounts: 596066,
      dataSent: 9304852,
      dataReceived: 76376832,
      callsIn: 977395,
      callsInDuration: 2175567,
      callsOut: 907474,
      callsOutDuration: 1774609,
      smses: 82634,
    },
    {
      date: "2021-05-25",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 27004971,
      accounts: 613936,
      dataSent: 10504468,
      dataReceived: 82607142,
      callsIn: 1223717,
      callsInDuration: 2659361,
      callsOut: 1090088,
      callsOutDuration: 2119876,
      smses: 93883,
    },
    {
      date: "2021-05-26",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 26577406,
      accounts: 614252,
      dataSent: 10471969,
      dataReceived: 83589110,
      callsIn: 1216702,
      callsInDuration: 2612765,
      callsOut: 1069721,
      callsOutDuration: 2052519,
      smses: 89563,
    },
    {
      date: "2021-05-27",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 26568073,
      accounts: 612354,
      dataSent: 10449359,
      dataReceived: 82677950,
      callsIn: 1217224,
      callsInDuration: 2647922,
      callsOut: 1072472,
      callsOutDuration: 2090213,
      smses: 91577,
    },
    {
      date: "2021-05-28",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 28461834,
      accounts: 620996,
      dataSent: 10920260,
      dataReceived: 86485277,
      callsIn: 1319166,
      callsInDuration: 2622884,
      callsOut: 1182906,
      callsOutDuration: 2092241,
      smses: 95624,
    },
    {
      date: "2021-05-29",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 27849983,
      accounts: 620962,
      dataSent: 10738467,
      dataReceived: 87035629,
      callsIn: 1180535,
      callsInDuration: 2246039,
      callsOut: 1111565,
      callsOutDuration: 1864683,
      smses: 92838,
    },
    {
      date: "2021-05-30",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 23921258,
      accounts: 597918,
      dataSent: 9010251,
      dataReceived: 72542752,
      callsIn: 841709,
      callsInDuration: 1937836,
      callsOut: 806986,
      callsOutDuration: 1605264,
      smses: 81152,
    },
    {
      date: "2021-05-31",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 26052910,
      accounts: 618738,
      dataSent: 11003049,
      dataReceived: 85932553,
      callsIn: 1246674,
      callsInDuration: 2671495,
      callsOut: 1115668,
      callsOutDuration: 2114934,
      smses: 93820,
    },
    {
      date: "2021-06-01",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 26257718,
      accounts: 621147,
      dataSent: 10780235,
      dataReceived: 83336259,
      callsIn: 1271928,
      callsInDuration: 2610562,
      callsOut: 1136007,
      callsOutDuration: 2092751,
      smses: 90993,
    },
    {
      date: "2021-06-02",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 25015737,
      accounts: 614768,
      dataSent: 9542149,
      dataReceived: 74507048,
      callsIn: 1231452,
      callsInDuration: 2640049,
      callsOut: 1074534,
      callsOutDuration: 2064275,
      smses: 90665,
    },
    {
      date: "2021-06-03",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 25479275,
      accounts: 620102,
      dataSent: 10947169,
      dataReceived: 86797833,
      callsIn: 1233329,
      callsInDuration: 2568292,
      callsOut: 1083160,
      callsOutDuration: 2033472,
      smses: 92077,
    },
    {
      date: "2021-06-04",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 26801173,
      accounts: 627157,
      dataSent: 10898091,
      dataReceived: 90685204,
      callsIn: 1336958,
      callsInDuration: 2576597,
      callsOut: 1209924,
      callsOutDuration: 2089599,
      smses: 98593,
    },
    {
      date: "2021-06-05",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 26815309,
      accounts: 625101,
      dataSent: 10671815,
      dataReceived: 86425769,
      callsIn: 1210749,
      callsInDuration: 2199113,
      callsOut: 1149298,
      callsOutDuration: 1851086,
      smses: 95615,
    },
    {
      date: "2021-06-06",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 23506286,
      accounts: 603470,
      dataSent: 8986860,
      dataReceived: 73853657,
      callsIn: 855828,
      callsInDuration: 1855522,
      callsOut: 831677,
      callsOutDuration: 1549263,
      smses: 80570,
    },
    {
      date: "2021-06-07",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 25518559,
      accounts: 622592,
      dataSent: 11075439,
      dataReceived: 89022874,
      callsIn: 1221091,
      callsInDuration: 2611786,
      callsOut: 1076918,
      callsOutDuration: 2059681,
      smses: 95847,
    },
    {
      date: "2021-06-08",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 25279648,
      accounts: 620579,
      dataSent: 10576039,
      dataReceived: 90378466,
      callsIn: 1208021,
      callsInDuration: 2602146,
      callsOut: 1057348,
      callsOutDuration: 2051541,
      smses: 96026,
    },
    {
      date: "2021-06-09",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 25666238,
      accounts: 622172,
      dataSent: 10599975,
      dataReceived: 84989198,
      callsIn: 1235677,
      callsInDuration: 2678557,
      callsOut: 1072636,
      callsOutDuration: 2086755,
      smses: 96181,
    },
    {
      date: "2021-06-10",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 25524827,
      accounts: 620101,
      dataSent: 10577890,
      dataReceived: 85302919,
      callsIn: 1246204,
      callsInDuration: 2640556,
      callsOut: 1085317,
      callsOutDuration: 2062480,
      smses: 90390,
    },
    {
      date: "2021-06-11",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 26601124,
      accounts: 626758,
      dataSent: 10992168,
      dataReceived: 89616421,
      callsIn: 1323319,
      callsInDuration: 2582487,
      callsOut: 1162427,
      callsOutDuration: 2037370,
      smses: 98573,
    },
    {
      date: "2021-06-12",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 26870721,
      accounts: 627186,
      dataSent: 10723584,
      dataReceived: 88455276,
      callsIn: 1220311,
      callsInDuration: 2215267,
      callsOut: 1141740,
      callsOutDuration: 1843754,
      smses: 97480,
    },
    {
      date: "2021-06-13",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 23230456,
      accounts: 605099,
      dataSent: 9761608,
      dataReceived: 82215772,
      callsIn: 846749,
      callsInDuration: 1861818,
      callsOut: 818331,
      callsOutDuration: 1552645,
      smses: 78378,
    },
    {
      date: "2021-06-14",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 24688550,
      accounts: 618695,
      dataSent: 10340259,
      dataReceived: 83146551,
      callsIn: 1210507,
      callsInDuration: 2648386,
      callsOut: 1079112,
      callsOutDuration: 2101895,
      smses: 96686,
    },
    {
      date: "2021-06-15",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 23538954,
      accounts: 616634,
      dataSent: 9850227,
      dataReceived: 79323479,
      callsIn: 1223405,
      callsInDuration: 2667322,
      callsOut: 1067460,
      callsOutDuration: 2092734,
      smses: 91022,
    },
    {
      date: "2021-06-16",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 25354557,
      accounts: 622544,
      dataSent: 10287608,
      dataReceived: 82382617,
      callsIn: 1248429,
      callsInDuration: 2662697,
      callsOut: 1081803,
      callsOutDuration: 2086554,
      smses: 94361,
    },
    {
      date: "2021-06-17",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 25521985,
      accounts: 621674,
      dataSent: 10484000,
      dataReceived: 87102249,
      callsIn: 1253172,
      callsInDuration: 2653018,
      callsOut: 1081280,
      callsOutDuration: 2073677,
      smses: 91074,
    },
    {
      date: "2021-06-18",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 27341766,
      accounts: 631173,
      dataSent: 10836915,
      dataReceived: 89685344,
      callsIn: 1373254,
      callsInDuration: 2604329,
      callsOut: 1207863,
      callsOutDuration: 2072310,
      smses: 96243,
    },
    {
      date: "2021-06-19",
      dayOfWeek: "Saturday",
      clientId: 2,
      dataSessions: 27623438,
      accounts: 632373,
      dataSent: 10723910,
      dataReceived: 90861261,
      callsIn: 1276946,
      callsInDuration: 2212201,
      callsOut: 1193921,
      callsOutDuration: 1844013,
      smses: 97106,
    },
    {
      date: "2021-06-20",
      dayOfWeek: "Sunday",
      clientId: 2,
      dataSessions: 21401426,
      accounts: 644278,
      dataSent: 8706043,
      dataReceived: 73646296,
      callsIn: 928025,
      callsInDuration: 1805399,
      callsOut: 921005,
      callsOutDuration: 1586378,
      smses: 86300,
    },
    {
      date: "2021-06-21",
      dayOfWeek: "Monday",
      clientId: 2,
      dataSessions: 25192773,
      accounts: 627586,
      dataSent: 10575660,
      dataReceived: 87957534,
      callsIn: 1212123,
      callsInDuration: 2607618,
      callsOut: 1046255,
      callsOutDuration: 2022551,
      smses: 117349,
    },
    {
      date: "2021-06-22",
      dayOfWeek: "Tuesday",
      clientId: 2,
      dataSessions: 25009643,
      accounts: 621681,
      dataSent: 10157010,
      dataReceived: 83158644,
      callsIn: 1197927,
      callsInDuration: 2583428,
      callsOut: 1037267,
      callsOutDuration: 2023992,
      smses: 106719,
    },
    {
      date: "2021-06-23",
      dayOfWeek: "Wednesday",
      clientId: 2,
      dataSessions: 24894043,
      accounts: 618400,
      dataSent: 9971945,
      dataReceived: 84751513,
      callsIn: 1201297,
      callsInDuration: 2492572,
      callsOut: 1044174,
      callsOutDuration: 1934452,
      smses: 100617,
    },
    {
      date: "2021-06-24",
      dayOfWeek: "Thursday",
      clientId: 2,
      dataSessions: 24342758,
      accounts: 614623,
      dataSent: 8371807,
      dataReceived: 69613820,
      callsIn: 6064,
      callsInDuration: 17565,
      callsOut: 5715,
      callsOutDuration: 13744,
      smses: 100108,
    },
    {
      date: "2021-06-25",
      dayOfWeek: "Friday",
      clientId: 2,
      dataSessions: 115072,
      accounts: 25068,
      dataSent: 6704,
      dataReceived: 59598,
      callsIn: 0,
      callsInDuration: 0,
      callsOut: 0,
      callsOutDuration: 0,
      smses: 2872,
    },
  ],
};
