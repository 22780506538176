import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import AuthCode from "react-auth-code-input";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { loginUser2FA } from "../../store/authentication/login/actions";

const TwoFactorAuth = () => {
  const history = useHistory();
  const [authCode, setAuthCode] = useState("");
  const dispatchEvent = useDispatch();
  const location = useLocation();

  const once = (data) => {
    setAuthCode(data);
  };

  const onSubmit = (data) => {
    // Request input auth code validation
    dispatchEvent(loginUser2FA(history, authCode, location.state.userDetails));
  };

  return (
    <section className="two-factor">
      <div className="row card-header">
        <div className="col-12">
          <div className="text-white p-3">
            <h5 className="font-weight-bold my-4">Two Factor Authenticator</h5>
          </div>
        </div>
      </div>

      <div className="card-body">
        <Link to="/">
          <img
            src={logo}
            alt=""
            className="rounded-circle ceres-icon"
            height="34"
          />
        </Link>
        <form
          className="form-horizontal mt-3"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
          }}
        >
          <p>Enter the code to proceed.</p>

          <div className="code d-flex justify-content-center align-items-center flex-column py-3">
            <AuthCode
              characters={6}
              name="AuthCode"
              className="form-control text-center "
              allowedCharacters="^[0-9]"
              inputStyle={{
                width: "3.0rem",
                height: "3.5rem",
                borderRadius: "10px",
                textAlign: "center",
                marginRight: "1rem",
                border: "1px solid #ced4da",
                textTransform: "uppercase",
              }}
              onChange={once}
            />

            <input
              type="submit"
              value="Proceed"
              className="btn btn-primary mt-5 mb-1 mr-auto"
            />
          </div>

          <h6 className="pt-3 text-center">
            <Link to="/login" className="text-muted">
              Use a different account
            </Link>
          </h6>
        </form>
      </div>
    </section>
  );
};

export default TwoFactorAuth;
