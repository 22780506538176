import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import { LOGIN_USER, LOGIN_USER_2FA, LOGOUT_USER } from "./actionTypes";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { loginUser2FASuccess, logoutUserSuccess } from "./actions";
import { setLoggedInUser, clearUser } from "../profile/actions";
import pt from "date-fns/esm/locale/pt/index.js";

export function requestLoginUser(bearer) {
  return axios.request({
    method: "post",
    url: "https://ceresapi.datakraken.net/api/auth/login",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: "Bearer " + bearer,
    },
  });
}

export function requestGetMFA(bearer, email) {
  return axios.request({
    method: "get",
    url: `https://ceresapi.datakraken.net/api/auth/users/${email}/get_mfa`,
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: "Bearer " + bearer,
    },
  });
}

export function requestValidateMFA(authCode, email) {
  return axios.request({
    method: "post",
    url: `https://ceresapi.datakraken.net/api/auth/validate_mfa/${email}/${authCode}`,
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    withCredentials: true,
  });
}

function* handleLoginUser({ payload: { bearer, history } }) {
  try {
    // Login to Google for the backend
    const responseLoginUser = yield call(requestLoginUser, bearer);

    try {
      // Test if MFA accepted
      const responseGetMFA = yield call(
        requestGetMFA,
        bearer,
        responseLoginUser.data.userDetails.userEmail
      );

      history.push({
        // Show MFA page for new user
        pathname: "/login/mfa-token",
        state: {
          MFA_Token: responseGetMFA.data.mfaSecret,
          Bearer: bearer,
          Email: responseLoginUser.data.userDetails.userEmail,
        },
      });
    } catch (error) {
      if ((error.response.status = 400)) {
        // Redirect user to two factor when status = 400 (existing user)
        history.push({
          pathname: "/login/two-factor",
          state: {
            userDetails: responseLoginUser.data.userDetails,
          },
        });
      } else {
        console.log("Something went wrong.", error);
      }
    }
  } catch (error) {
    console.log("Something went wrong.", error);
  }
}

function* handleLoginUser2FA({ payload: { history, authCode, user } }) {
  try {
    // Test if correct code
    const responseValidateMFA = yield call(
      requestValidateMFA,
      authCode,
      user.userEmail
    );

    if (responseValidateMFA.data.payload === "AUTH_SUCCESS") {
      // Set User profile saga
      yield put(setLoggedInUser(user));

      // Set User login saga
      yield put(loginUser2FASuccess(user));

      // Direct to homepage
      history.push("/homepage");

      // Auto logout after 12 hours
      yield delay(12 * 60 * 60 * 1000);

      yield put(logoutUserSuccess());
    } else {
      alert("You've entered the wrong code, doughnut!!!");
    }
  } catch (error) {
    console.log("Something went wrong.", error);
  }
}

function* handleLogoutUser({ payload: { history } }) {
  yield put(clearUser());
  yield put(logoutUserSuccess());

  history.push("/login");
}

function* authenticationSaga() {
  yield takeEvery(LOGIN_USER, handleLoginUser);
  yield takeEvery(LOGIN_USER_2FA, handleLoginUser2FA);
  yield takeEvery(LOGOUT_USER, handleLogoutUser);
}

export default authenticationSaga;
