import {
  SIDEBAR_LINK_CHANGE,
  ROUTE_CHANGE,
  SIDEBAR_COLLAPSE_TOGGLE,
} from "./actionTypes";

const initialState = {
  activeNav: "",
  collapsed: false,
};

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case SIDEBAR_LINK_CHANGE:
      state = {
        ...state,
        activeNav: action.payload,
      };
      break;

    case SIDEBAR_COLLAPSE_TOGGLE:
      state = {
        ...state,
        collapsed: true,
        collapsed: !state.collapsed,
      };
      break;
    default:
      state = state;
      break;
  }
  return state;
};

export default sidebar;
