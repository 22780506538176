import { Switch, Route, BrowserRouter } from "react-router-dom";

import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path="/login" component={AuthLayout} />
          <Route component={MainLayout} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
