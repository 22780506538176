import React, { useState } from "react";
import logo from "../assets/images/logo.svg";
import fullscreenIcon from "../assets/images/icons/fullscreen.svg";
import notificationIcon from "../assets/images/icons/notification.svg";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";

import { useSelector, useDispatch } from "react-redux";

import { logoutUser } from "../store/authentication/login/actions";
import { sidebarCollapseToggle } from "../store/sidebar/actions";

import { BiMenu, BiFullscreen, BiBell } from "react-icons/bi";

import DateRangeSelector from "./DateRangeSelector";
const Topbar = () => {
  const email = useSelector((state) => state.Profile.email);
  const firstname = useSelector((state) => state.Profile.firstname);
  const lastname = useSelector((state) => state.Profile.lastname);
  const profileImage = useSelector((state) => state.Profile.profileImage);

  const collapsed = useSelector((state) => state.Sidebar.collapsed);

  const history = useHistory();
  const [showDateSelector, setShowDateSelector] = useState(false);
  const dispatchEvent = useDispatch();

  const signOut = () => {
    dispatchEvent(logoutUser(history));
  };

  return (
    <section className={`topbar ${collapsed ? "collapsed" : ""}`}>
      <div className="d-flex justify-content-between">
        <ul className="mr-auto topbar--left ">
          <li className="d-flex justify-content-center align-items-center">
            <BiMenu
              className="menu--toggle"
              onClick={() => dispatchEvent(sidebarCollapseToggle())}
            />
          </li>

          {/* Date Selector In Progress */}
          {/* <li
            className={`px-5 topbar--date-selector-wapper ${
              showDateSelector ? "active" : "hidden"
            }`}
          >
            <div onClick={() => setShowDateSelector(!showDateSelector)}>
              Open {String(showDateSelector)}
            </div>

            <DateRangeSelector className="topbar--date-selector"></DateRangeSelector>
          </li> */}
        </ul>

        <ul className="ml-auto topbar--right">
          <li>
            <Dropdown className="notification--toggle">
              <Dropdown.Toggle id="dropdown--notification">
                <BiBell className="notification--icon" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/profile">
                  Notification Item 1
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/profile">
                  Notification Item 2
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/profile">
                  Notification Item 3
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item as={Link} to="/action-2">
                  View More
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="px-4">
            <button
              type="button"
              onClick={() => {
                toggleFullscreen();
              }}
              className="fullscreen--toggle"
            >
              <BiFullscreen className="fullscreen--toggle--icon" />
            </button>
          </li>
          <li>
            <Dropdown className="profile--toggle">
              <Dropdown.Toggle id="dropdown--profile">
                <div className="row mt-3">
                  <div className="col">
                    <img
                      src={profileImage}
                      alt=""
                      className="topbar--profile--icon"
                    />
                  </div>
                  <div className="col mt-2">
                    <p className="text-dark"> {firstname}</p>
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/profile">
                  Profile - Coming soon!
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item as={Link} to="/action-2" onClick={signOut}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </section>
  );
};

function toggleFullscreen() {
  if (
    !document.fullscreenElement &&
    /* alternative standard method */ !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
}

export default Topbar;
