import { useState, useContext } from "react";

import logo from "../../assets/images/logo.svg";
import { useHistory } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";

import { loginUser } from "../../store/authentication/login/actions";

import { Link } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const dispatchEvent = useDispatch();

  const onGoogleLogin = async (response) => {
    dispatchEvent(loginUser(response.tokenId, history));
    console.log("Google Login Response", response);
  };

  const onGoogleLoginFail = async (res) => {
    alert("Error logging in with google ", res);
  };

  return (
    <section className="login">
      <div className="row card-header">
        <div className="col-12 col-lg-6">
          <div className="text-white p-3">
            <h5 className="font-weight-bold">Welcome Back !</h5>
            <p>Sign in to continue to Ceres.</p>
          </div>
        </div>
        <div className="col-6 align-self-center">
          {/* <img src={logo} alt="" className="img-fluid " /> */}
        </div>
      </div>

      <div className="card-body">
        <Link to="/">
          <img
            src={logo}
            alt=""
            className="rounded-circle ceres-icon"
            height="34"
          />
        </Link>

        <div className="mt-4 py-3 text-center">
          <GoogleLogin
            clientId="467253081931-k2t4ohabk58fjjvc2m37tfs3dpb046q7.apps.googleusercontent.com"
            buttonText="Continue with Google"
            onSuccess={onGoogleLogin}
            onFailure={onGoogleLoginFail}
            cookiePolicy={"single_host_origin"}
            className="login--google"
          />{" "}
        </div>
      </div>

      <div className="pb-3 pt-5 text-center">
        <Link to="/pages-forgot-pwd" className="text-muted">
          <i className="mdi mdi-lock me-1" /> Forgot your password?
        </Link>
      </div>
    </section>
  );
};

export default Login;
