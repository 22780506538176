import React, { useState, useEffect, createContext, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import moment from "moment";

import { Row, Col, Card } from "react-bootstrap";

import axios from "axios";

import { useSelector, useDispatch } from "react-redux";

import EventView from "./EventView";
import AttendeesFilter from "./AttendeesFilter";
import UpcomingEvents from "./UpcomingEvents";
import UpcomingHolidays from "./UpcomingHolidays";

const Calendar = () => {
  const EPOCH_90_DAYS_MS = 7776000000;

  const email = useSelector((state) => state.Profile.email);

  const [selectedEvent, setSelectedEvent] = useState([]);
  const [show, setShow] = useState(false);
  const [showWeekends, setShowWeekends] = useState(false);

  const [myEvents, setMyEvents] = useState([]);
  const [teamHolidays, setTeamHolidays] = useState([]);

  const [calendarEventsDisplay, setCalendarEventsDisplay] = useState();
  const [calendarEventsAPI, setCalendarEventsAPI] = useState([]);

  // Set intial calendar view call UB and LB
  const [calendarViewStart, setCalendarViewStart] = useState(
    Math.floor((new Date().getTime() - EPOCH_90_DAYS_MS) / 1000)
  );
  const [calendarViewEnd, setCalendarViewEnd] = useState(
    Math.floor((new Date().getTime() + EPOCH_90_DAYS_MS) / 1000)
  );

  const handleEventClick = (arg) => {
    setShow(true);
    setSelectedEvent(arg.event);
  };

  const handleDateClick = (arg) => {
    alert("You clicked on the date: " + arg.dateStr);
  };

  // Style and transfrom events on calendar display
  const handleEventDataTransform = (event) => {
    if (event.eventType == "Annual Leave") {
      event.className = "event--al";
      event.allDay = true;

      // AL is more than 1 day, add extra day to display holiday event on final day
      if (
        event.end - event.start >= 86400000 &&
        event.end % 10 != 1 // prevent re-add day
      ) {
        event.end = event.end + 86400001;
      }
    } else if (event.eventType == "Birthday") {
      event.className = "event--bd";
      event.allDay = true;
    } else if (event.eventType == "Bank Holiday") {
      event.className = "event--holiday";
      event.allDay = true;
    } else if (event.eventAttendees && event.eventAttendees.length == 1) {
      event.className = "event--single";
      event.backgroundColor = "#292929";
      event.borderColor = "#292929";
    } else {
      event.className = "event--meeting";
      event.backgroundColor = "#C0162E";
      event.borderColor = "#C0162E";
    }

    return event;
  };

  const handleDatesSet = (event) => {
    if (event.start.getTime() / 1000 < calendarViewStart) {
      setCalendarViewStart(
        Math.floor(event.start.getTime() - EPOCH_90_DAYS_MS) / 1000
      );
    } else if (event.end.getTime() / 1000 > calendarViewEnd) {
      setCalendarViewEnd(
        Math.floor(event.end.getTime() + EPOCH_90_DAYS_MS) / 1000
      );
    }
  };

  // Call inital calendar data
  useEffect(() => {
    axios
      .get(
        `https://ceresapi.datakraken.net/api/v1/calendar/events/1?start_date=${calendarViewStart}&end_date=${calendarViewEnd}&page_size=6000`,
        { withCredentials: true }
      )
      .then((response) => {
        console.log("CALENDAR API Call response: ", response);
        setCalendarEventsAPI(response.data.payload);
      })

      .catch((error) => {
        console.error(error);
      });
  }, [calendarViewStart, calendarViewEnd]);

  // Set all widget displays from calendar call
  useEffect(() => {
    setCalendarEventsDisplay(calendarEventsAPI);
    console.log("CALENDAR EVENTS API: ", calendarEventsAPI);

    setTeamHolidays(
      calendarEventsAPI.filter(
        (event) => event.eventType == "Annual Leave" && event.start > Date.now()
      )
    );

    setMyEvents(
      calendarEventsAPI.filter(
        (event) =>
          event.eventAttendees &&
          event.eventAttendees.includes(email) &&
          event.start > Date.now()
      )
    );
  }, [calendarEventsAPI]);

  return (
    <section className="calendar">
      <Row>
        <Col xs={12} lg={9}>
          <Card>
            <Card.Body>
              <FullCalendar
                plugins={[
                  BootstrapTheme,
                  dayGridPlugin,
                  interactionPlugin,
                  timeGridPlugin,
                  listPlugin,
                ]}
                height="113vh"
                initialView="dayGridWeek"
                customButtons={{
                  toggleWeekends: {
                    text: "weekends",
                    click: function () {
                      setShowWeekends(!showWeekends);
                    },
                  },
                }}
                firstDay="1"
                hiddenDays={showWeekends ? [] : [6, 0]}
                columnHeaderFormat={{
                  dayGridWeek: "long",
                  month: "numeric",
                  day: "numeric",
                  omitCommas: true,
                }}
                handleWindowResize={true}
                headerToolbar={{
                  left: "prev,next today toggleWeekends",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek,timeGridDay",
                }}
                events={calendarEventsDisplay}
                timeFormat="H:mm"
                eventTimeFormat={{
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                }}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                eventDataTransform={handleEventDataTransform}
                datesSet={handleDatesSet}
                eventDidMount={function (event, element) {}}
                dayHeaderFormat={function (args) {
                  if (args.date.year != 1970) {
                    return moment(args.date).format("ddd D MMM");
                  } else {
                    return moment(args.date).format("ddd");
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} lg={3}>
          <AttendeesFilter
            calendarEventsDisplay={calendarEventsDisplay}
            calendarEventsAPI={calendarEventsAPI}
            setCalendarEventsDisplay={setCalendarEventsDisplay}
          />

          <UpcomingEvents myEvents={myEvents} />
          <UpcomingHolidays teamHolidays={teamHolidays} />
        </Col>
      </Row>

      <EventView selectedEvent={selectedEvent} show={show} setShow={setShow} />
    </section>
  );
};

export default Calendar;
