import React from "react";
import { Card } from "react-bootstrap";

const UpcomingEvents = ({ myEvents }) => {
  return (
    <section className="calendar-upcoming-events">
      <Card>
        <Card.Body>
          <h4 className="pb-4">My Upcoming Events</h4>
          <ol className="pl-3">
            {myEvents.slice(0, 5).map((event) => (
              <li className="pl-3">
                <h5>
                  <u>{event.title}</u>
                </h5>
                <p>
                  {String(
                    new Date(event.start).toLocaleString("en-GB", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  )}{" "}
                  {String(
                    new Date(event.start).toLocaleTimeString("en-GB", {
                      timeStyle: "short",
                      hourCycle: "h12",
                    })
                  )}
                  {" - "}
                  {String(
                    new Date(event.end).toLocaleTimeString("en-GB", {
                      timeStyle: "short",
                      hourCycle: "h12",
                    })
                  )}
                </p>
              </li>
            ))}
          </ol>
        </Card.Body>
      </Card>
    </section>
  );
};

export default UpcomingEvents;
