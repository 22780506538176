import React, { useState, useEffect } from "react";
import SidebarContent from "./SidebarContent";
import { IconContext } from "react-icons";

import { Link, NavLink } from "react-router-dom";

import MetisMenu from "@metismenu/react";
import "metismenujs/dist/metismenujs.css";

import { useSelector, useDispatch } from "react-redux";
import ImgCeresLogo from "../../assets/images/ceres-logo.svg";

function Navbar() {
  const collapsed = useSelector((state) => state.Sidebar.collapsed);

  return (
    <>
      <section className={`sidebar ${collapsed ? "collapsed" : ""}`}>
        <div className="logo">
          <NavLink to="/homepage" className="menu-bars">
            <img
              src={ImgCeresLogo}
              alt="ceres-logo"
              className="sidebar--logo"
            />
          </NavLink>
        </div>

        <SidebarContent />
      </section>
    </>
  );
}

export default Navbar;
