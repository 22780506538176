import React from "react";
import Login from "../pages/authentication/Login";
import TwoFactorAuth from "../pages/authentication/TwoFactorAuth";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { AuthRoutes } from "../routes/AllRoutes";

const Authentication = ({ component }) => {
  return (
    <section className="authentication my-5 pt-5">
      <div className="container d-flex justify-content-center align-items-center flex-column ">
        <div className="card overflow-hidden">
          {AuthRoutes.map((route, idx) => (
            <Route
              path={route.path}
              exact
              component={route.component}
              key={idx}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Authentication;
