import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";

interface Props {
  clientID: string;
}

const ClientData = ({ clientID }: Props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://ceresapi.datakraken.net/api/v1/clients/${clientID}`, {
        withCredentials: true,
      })

      .then((response) => {
        console.log(response);
        setData(response.data.payload);
      })

      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <h2>Client Data</h2>
        </Card.Title>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Info</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((key: any) => {
              return (
                <tr>
                  <td>{key}</td>
                  <td>{data[key]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ClientData;
