export const sample_data = {
  request_made: 1634830779,
  request_latency: 267,
  payload: [
    {
      date: "2015-07-31",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 9864,
      accounts: 5850,
      dataSent: 11617,
      dataReceived: 70651,
      callsIn: 2868,
      callsInDuration: 4445,
      callsOut: 3496,
      callsOutDuration: 3929,
      smses: 99302,
    },
    {
      date: "2015-08-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 70186,
      accounts: 9651,
      dataSent: 35192,
      dataReceived: 205735,
      callsIn: 20673,
      callsInDuration: 23990,
      callsOut: 22952,
      callsOutDuration: 21471,
      smses: 1134417,
    },
    {
      date: "2015-08-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 67182,
      accounts: 9139,
      dataSent: 32012,
      dataReceived: 194841,
      callsIn: 14283,
      callsInDuration: 19859,
      callsOut: 16468,
      callsOutDuration: 16212,
      smses: 1065136,
    },
    {
      date: "2015-08-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 81652,
      accounts: 9800,
      dataSent: 34198,
      dataReceived: 207495,
      callsIn: 23739,
      callsInDuration: 31951,
      callsOut: 22610,
      callsOutDuration: 25004,
      smses: 1184017,
    },
    {
      date: "2015-08-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 80871,
      accounts: 9664,
      dataSent: 30009,
      dataReceived: 178326,
      callsIn: 23746,
      callsInDuration: 31931,
      callsOut: 22502,
      callsOutDuration: 24744,
      smses: 1193424,
    },
    {
      date: "2015-08-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 86515,
      accounts: 10467,
      dataSent: 41481,
      dataReceived: 243373,
      callsIn: 25458,
      callsInDuration: 32212,
      callsOut: 22866,
      callsOutDuration: 26010,
      smses: 1450224,
    },
    {
      date: "2015-08-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 84713,
      accounts: 10118,
      dataSent: 39092,
      dataReceived: 240023,
      callsIn: 25013,
      callsInDuration: 32246,
      callsOut: 24536,
      callsOutDuration: 26760,
      smses: 1283279,
    },
    {
      date: "2015-08-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 88656,
      accounts: 10389,
      dataSent: 40614,
      dataReceived: 248066,
      callsIn: 28781,
      callsInDuration: 33516,
      callsOut: 28646,
      callsOutDuration: 29252,
      smses: 1313559,
    },
    {
      date: "2015-08-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 78063,
      accounts: 10058,
      dataSent: 36410,
      dataReceived: 234163,
      callsIn: 22286,
      callsInDuration: 25590,
      callsOut: 24608,
      callsOutDuration: 24299,
      smses: 989675,
    },
    {
      date: "2015-08-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 76176,
      accounts: 9847,
      dataSent: 32652,
      dataReceived: 203298,
      callsIn: 18612,
      callsInDuration: 20644,
      callsOut: 21273,
      callsOutDuration: 19048,
      smses: 693207,
    },
    {
      date: "2015-08-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 96725,
      accounts: 10536,
      dataSent: 39049,
      dataReceived: 232775,
      callsIn: 23978,
      callsInDuration: 30276,
      callsOut: 23200,
      callsOutDuration: 26634,
      smses: 1157605,
    },
    {
      date: "2015-08-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 89963,
      accounts: 10320,
      dataSent: 33280,
      dataReceived: 195501,
      callsIn: 25171,
      callsInDuration: 32086,
      callsOut: 23982,
      callsOutDuration: 26261,
      smses: 1213564,
    },
    {
      date: "2015-08-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 87349,
      accounts: 10716,
      dataSent: 29869,
      dataReceived: 187607,
      callsIn: 24150,
      callsInDuration: 30875,
      callsOut: 24666,
      callsOutDuration: 26826,
      smses: 1078133,
    },
    {
      date: "2015-08-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 87530,
      accounts: 10519,
      dataSent: 42426,
      dataReceived: 267474,
      callsIn: 25095,
      callsInDuration: 31668,
      callsOut: 23643,
      callsOutDuration: 27483,
      smses: 1367718,
    },
    {
      date: "2015-08-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 91651,
      accounts: 10612,
      dataSent: 41877,
      dataReceived: 256017,
      callsIn: 28586,
      callsInDuration: 33342,
      callsOut: 27477,
      callsOutDuration: 28907,
      smses: 1386551,
    },
    {
      date: "2015-08-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 83294,
      accounts: 10327,
      dataSent: 37773,
      dataReceived: 229020,
      callsIn: 25928,
      callsInDuration: 26536,
      callsOut: 26417,
      callsOutDuration: 24405,
      smses: 1307904,
    },
    {
      date: "2015-08-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 78977,
      accounts: 10004,
      dataSent: 37361,
      dataReceived: 229667,
      callsIn: 17698,
      callsInDuration: 20922,
      callsOut: 20235,
      callsOutDuration: 19737,
      smses: 857730,
    },
    {
      date: "2015-08-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 71204,
      accounts: 9595,
      dataSent: 33271,
      dataReceived: 208447,
      callsIn: 15016,
      callsInDuration: 20969,
      callsOut: 16975,
      callsOutDuration: 17556,
      smses: 805321,
    },
    {
      date: "2015-08-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 91112,
      accounts: 10619,
      dataSent: 38980,
      dataReceived: 230957,
      callsIn: 26364,
      callsInDuration: 32918,
      callsOut: 24840,
      callsOutDuration: 26844,
      smses: 1229168,
    },
    {
      date: "2015-08-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 91652,
      accounts: 10676,
      dataSent: 38754,
      dataReceived: 243974,
      callsIn: 25769,
      callsInDuration: 32580,
      callsOut: 24307,
      callsOutDuration: 26922,
      smses: 1188703,
    },
    {
      date: "2015-08-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 93778,
      accounts: 10893,
      dataSent: 40229,
      dataReceived: 255503,
      callsIn: 25801,
      callsInDuration: 33991,
      callsOut: 24089,
      callsOutDuration: 27376,
      smses: 1234919,
    },
    {
      date: "2015-08-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 96054,
      accounts: 10905,
      dataSent: 40867,
      dataReceived: 245694,
      callsIn: 28783,
      callsInDuration: 34626,
      callsOut: 28406,
      callsOutDuration: 29113,
      smses: 1205717,
    },
    {
      date: "2015-08-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 84883,
      accounts: 10505,
      dataSent: 38013,
      dataReceived: 239849,
      callsIn: 22404,
      callsInDuration: 25938,
      callsOut: 24203,
      callsOutDuration: 22796,
      smses: 935942,
    },
    {
      date: "2015-08-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 75262,
      accounts: 9947,
      dataSent: 35087,
      dataReceived: 221186,
      callsIn: 14488,
      callsInDuration: 18132,
      callsOut: 16986,
      callsOutDuration: 15737,
      smses: 737522,
    },
    {
      date: "2015-08-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 92617,
      accounts: 10831,
      dataSent: 35269,
      dataReceived: 206850,
      callsIn: 25187,
      callsInDuration: 31220,
      callsOut: 23646,
      callsOutDuration: 26275,
      smses: 1450730,
    },
    {
      date: "2015-08-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 109621,
      accounts: 11284,
      dataSent: 39445,
      dataReceived: 228308,
      callsIn: 26879,
      callsInDuration: 33784,
      callsOut: 23770,
      callsOutDuration: 26807,
      smses: 1502039,
    },
    {
      date: "2015-08-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 95556,
      accounts: 11244,
      dataSent: 40892,
      dataReceived: 245728,
      callsIn: 26079,
      callsInDuration: 33101,
      callsOut: 23555,
      callsOutDuration: 24987,
      smses: 1456091,
    },
    {
      date: "2015-08-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 102566,
      accounts: 11138,
      dataSent: 34778,
      dataReceived: 207598,
      callsIn: 25552,
      callsInDuration: 32274,
      callsOut: 23863,
      callsOutDuration: 26615,
      smses: 1439469,
    },
    {
      date: "2015-08-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 109629,
      accounts: 11596,
      dataSent: 46791,
      dataReceived: 277450,
      callsIn: 28956,
      callsInDuration: 34267,
      callsOut: 27368,
      callsOutDuration: 27780,
      smses: 1328180,
    },
    {
      date: "2015-08-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 90274,
      accounts: 10806,
      dataSent: 38406,
      dataReceived: 229285,
      callsIn: 22931,
      callsInDuration: 25505,
      callsOut: 24948,
      callsOutDuration: 22331,
      smses: 1054994,
    },
    {
      date: "2015-08-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 87729,
      accounts: 10698,
      dataSent: 36824,
      dataReceived: 227239,
      callsIn: 15614,
      callsInDuration: 20381,
      callsOut: 17797,
      callsOutDuration: 16157,
      smses: 796526,
    },
    {
      date: "2015-08-31",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 99333,
      accounts: 11097,
      dataSent: 34964,
      dataReceived: 208121,
      callsIn: 25778,
      callsInDuration: 30631,
      callsOut: 23043,
      callsOutDuration: 24963,
      smses: 1484642,
    },
    {
      date: "2015-09-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 97994,
      accounts: 11472,
      dataSent: 45740,
      dataReceived: 275259,
      callsIn: 25203,
      callsInDuration: 34012,
      callsOut: 22999,
      callsOutDuration: 25745,
      smses: 1250945,
    },
    {
      date: "2015-09-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 92221,
      accounts: 10987,
      dataSent: 40352,
      dataReceived: 247231,
      callsIn: 25563,
      callsInDuration: 34144,
      callsOut: 23649,
      callsOutDuration: 26718,
      smses: 1417202,
    },
    {
      date: "2015-09-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 99613,
      accounts: 11059,
      dataSent: 41531,
      dataReceived: 244835,
      callsIn: 25932,
      callsInDuration: 33142,
      callsOut: 24450,
      callsOutDuration: 26626,
      smses: 1349707,
    },
    {
      date: "2015-09-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 100159,
      accounts: 11410,
      dataSent: 36484,
      dataReceived: 208709,
      callsIn: 28986,
      callsInDuration: 34319,
      callsOut: 28841,
      callsOutDuration: 29283,
      smses: 1213490,
    },
    {
      date: "2015-09-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 162012,
      accounts: 11423,
      dataSent: 33600,
      dataReceived: 185841,
      callsIn: 25240,
      callsInDuration: 27950,
      callsOut: 26809,
      callsOutDuration: 25063,
      smses: 1171516,
    },
    {
      date: "2015-09-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 131848,
      accounts: 10949,
      dataSent: 32337,
      dataReceived: 189823,
      callsIn: 16108,
      callsInDuration: 20100,
      callsOut: 18420,
      callsOutDuration: 17344,
      smses: 870421,
    },
    {
      date: "2015-09-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 128883,
      accounts: 11554,
      dataSent: 30384,
      dataReceived: 176110,
      callsIn: 26275,
      callsInDuration: 33023,
      callsOut: 24296,
      callsOutDuration: 27132,
      smses: 1433989,
    },
    {
      date: "2015-09-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 298541,
      accounts: 11736,
      dataSent: 32287,
      dataReceived: 187706,
      callsIn: 27433,
      callsInDuration: 34239,
      callsOut: 24665,
      callsOutDuration: 27104,
      smses: 1279982,
    },
    {
      date: "2015-09-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 101987,
      accounts: 11163,
      dataSent: 42185,
      dataReceived: 267117,
      callsIn: 27348,
      callsInDuration: 34359,
      callsOut: 23907,
      callsOutDuration: 26776,
      smses: 1587126,
    },
    {
      date: "2015-09-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 108423,
      accounts: 11456,
      dataSent: 46184,
      dataReceived: 274757,
      callsIn: 28511,
      callsInDuration: 35108,
      callsOut: 26569,
      callsOutDuration: 28713,
      smses: 1453350,
    },
    {
      date: "2015-09-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 102791,
      accounts: 11375,
      dataSent: 42867,
      dataReceived: 257712,
      callsIn: 30097,
      callsInDuration: 36617,
      callsOut: 28423,
      callsOutDuration: 29455,
      smses: 1537025,
    },
    {
      date: "2015-09-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 93847,
      accounts: 11054,
      dataSent: 42870,
      dataReceived: 252479,
      callsIn: 24386,
      callsInDuration: 28697,
      callsOut: 25068,
      callsOutDuration: 24961,
      smses: 1278828,
    },
    {
      date: "2015-09-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 86727,
      accounts: 10557,
      dataSent: 40828,
      dataReceived: 241534,
      callsIn: 16751,
      callsInDuration: 19868,
      callsOut: 18110,
      callsOutDuration: 17103,
      smses: 1209349,
    },
    {
      date: "2015-09-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 103573,
      accounts: 11543,
      dataSent: 43466,
      dataReceived: 263560,
      callsIn: 27996,
      callsInDuration: 35457,
      callsOut: 24452,
      callsOutDuration: 26994,
      smses: 1430966,
    },
    {
      date: "2015-09-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 102060,
      accounts: 11517,
      dataSent: 43946,
      dataReceived: 272765,
      callsIn: 28670,
      callsInDuration: 34975,
      callsOut: 24846,
      callsOutDuration: 26463,
      smses: 1882891,
    },
    {
      date: "2015-09-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 111799,
      accounts: 11950,
      dataSent: 47333,
      dataReceived: 298275,
      callsIn: 30950,
      callsInDuration: 37996,
      callsOut: 27209,
      callsOutDuration: 28179,
      smses: 1816806,
    },
    {
      date: "2015-09-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 106677,
      accounts: 11779,
      dataSent: 45726,
      dataReceived: 283012,
      callsIn: 28750,
      callsInDuration: 37147,
      callsOut: 26144,
      callsOutDuration: 28722,
      smses: 1550037,
    },
    {
      date: "2015-09-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 107734,
      accounts: 11937,
      dataSent: 48101,
      dataReceived: 282396,
      callsIn: 30479,
      callsInDuration: 34567,
      callsOut: 30108,
      callsOutDuration: 30524,
      smses: 1492549,
    },
    {
      date: "2015-09-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 96456,
      accounts: 11486,
      dataSent: 37863,
      dataReceived: 229990,
      callsIn: 24608,
      callsInDuration: 26704,
      callsOut: 27043,
      callsOutDuration: 25410,
      smses: 1176748,
    },
    {
      date: "2015-09-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 84645,
      accounts: 11553,
      dataSent: 41343,
      dataReceived: 247238,
      callsIn: 15958,
      callsInDuration: 19427,
      callsOut: 19154,
      callsOutDuration: 17546,
      smses: 1000355,
    },
    {
      date: "2015-09-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 103264,
      accounts: 11863,
      dataSent: 48481,
      dataReceived: 283261,
      callsIn: 26782,
      callsInDuration: 33653,
      callsOut: 25243,
      callsOutDuration: 26682,
      smses: 1764625,
    },
    {
      date: "2015-09-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 105366,
      accounts: 11737,
      dataSent: 47305,
      dataReceived: 273340,
      callsIn: 26795,
      callsInDuration: 35679,
      callsOut: 24785,
      callsOutDuration: 27329,
      smses: 1680708,
    },
    {
      date: "2015-09-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 106181,
      accounts: 12030,
      dataSent: 48248,
      dataReceived: 292523,
      callsIn: 27018,
      callsInDuration: 34302,
      callsOut: 24410,
      callsOutDuration: 26790,
      smses: 1689892,
    },
    {
      date: "2015-09-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 109708,
      accounts: 11977,
      dataSent: 47905,
      dataReceived: 299386,
      callsIn: 26935,
      callsInDuration: 35449,
      callsOut: 26369,
      callsOutDuration: 29243,
      smses: 1417995,
    },
    {
      date: "2015-09-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 108153,
      accounts: 12025,
      dataSent: 48751,
      dataReceived: 297660,
      callsIn: 29097,
      callsInDuration: 34970,
      callsOut: 29494,
      callsOutDuration: 30184,
      smses: 1388332,
    },
    {
      date: "2015-09-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 96808,
      accounts: 11536,
      dataSent: 46797,
      dataReceived: 275077,
      callsIn: 23981,
      callsInDuration: 27720,
      callsOut: 26104,
      callsOutDuration: 23842,
      smses: 1121345,
    },
    {
      date: "2015-09-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 89850,
      accounts: 11070,
      dataSent: 43011,
      dataReceived: 247377,
      callsIn: 17616,
      callsInDuration: 21119,
      callsOut: 19833,
      callsOutDuration: 18559,
      smses: 1041459,
    },
    {
      date: "2015-09-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 105868,
      accounts: 11778,
      dataSent: 45362,
      dataReceived: 277924,
      callsIn: 26538,
      callsInDuration: 34260,
      callsOut: 24354,
      callsOutDuration: 26850,
      smses: 1729311,
    },
    {
      date: "2015-09-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 107214,
      accounts: 11876,
      dataSent: 44592,
      dataReceived: 268906,
      callsIn: 27615,
      callsInDuration: 34937,
      callsOut: 25135,
      callsOutDuration: 27661,
      smses: 1798682,
    },
    {
      date: "2015-09-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 109365,
      accounts: 12020,
      dataSent: 39841,
      dataReceived: 236514,
      callsIn: 27208,
      callsInDuration: 34994,
      callsOut: 25940,
      callsOutDuration: 27726,
      smses: 1501185,
    },
    {
      date: "2015-10-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 125397,
      accounts: 12678,
      dataSent: 56317,
      dataReceived: 315106,
      callsIn: 28252,
      callsInDuration: 35114,
      callsOut: 27283,
      callsOutDuration: 29355,
      smses: 1551238,
    },
    {
      date: "2015-10-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 112710,
      accounts: 12286,
      dataSent: 48472,
      dataReceived: 280470,
      callsIn: 29453,
      callsInDuration: 35168,
      callsOut: 28715,
      callsOutDuration: 29324,
      smses: 1883366,
    },
    {
      date: "2015-10-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 98592,
      accounts: 11761,
      dataSent: 40995,
      dataReceived: 236471,
      callsIn: 24061,
      callsInDuration: 27394,
      callsOut: 26473,
      callsOutDuration: 24712,
      smses: 1369970,
    },
    {
      date: "2015-10-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 151833,
      accounts: 11799,
      dataSent: 28531,
      dataReceived: 170249,
      callsIn: 16841,
      callsInDuration: 20263,
      callsOut: 19521,
      callsOutDuration: 18331,
      smses: 1043000,
    },
    {
      date: "2015-10-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 114379,
      accounts: 12552,
      dataSent: 52124,
      dataReceived: 308671,
      callsIn: 28295,
      callsInDuration: 34688,
      callsOut: 27076,
      callsOutDuration: 29527,
      smses: 2006421,
    },
    {
      date: "2015-10-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 110028,
      accounts: 12257,
      dataSent: 46750,
      dataReceived: 287807,
      callsIn: 29506,
      callsInDuration: 37065,
      callsOut: 27206,
      callsOutDuration: 29637,
      smses: 1967407,
    },
    {
      date: "2015-10-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 111218,
      accounts: 12357,
      dataSent: 44168,
      dataReceived: 251976,
      callsIn: 30500,
      callsInDuration: 37432,
      callsOut: 28229,
      callsOutDuration: 30737,
      smses: 2028631,
    },
    {
      date: "2015-10-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 94328,
      accounts: 12568,
      dataSent: 35657,
      dataReceived: 211000,
      callsIn: 21150,
      callsInDuration: 25855,
      callsOut: 20264,
      callsOutDuration: 22231,
      smses: 1386735,
    },
    {
      date: "2015-10-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 120061,
      accounts: 12912,
      dataSent: 53411,
      dataReceived: 319338,
      callsIn: 34467,
      callsInDuration: 38080,
      callsOut: 31578,
      callsOutDuration: 32362,
      smses: 2092373,
    },
    {
      date: "2015-10-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 101496,
      accounts: 12057,
      dataSent: 47328,
      dataReceived: 283829,
      callsIn: 26479,
      callsInDuration: 29614,
      callsOut: 28563,
      callsOutDuration: 26577,
      smses: 2096078,
    },
    {
      date: "2015-10-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 91221,
      accounts: 11408,
      dataSent: 37495,
      dataReceived: 230638,
      callsIn: 17830,
      callsInDuration: 21715,
      callsOut: 21130,
      callsOutDuration: 19972,
      smses: 1404468,
    },
    {
      date: "2015-10-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 94459,
      accounts: 11965,
      dataSent: 48455,
      dataReceived: 302930,
      callsIn: 18464,
      callsInDuration: 22649,
      callsOut: 19614,
      callsOutDuration: 19770,
      smses: 1788071,
    },
    {
      date: "2015-10-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 111455,
      accounts: 12512,
      dataSent: 49696,
      dataReceived: 291863,
      callsIn: 31178,
      callsInDuration: 37440,
      callsOut: 29197,
      callsOutDuration: 31174,
      smses: 1988330,
    },
    {
      date: "2015-10-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 113289,
      accounts: 12631,
      dataSent: 49852,
      dataReceived: 292606,
      callsIn: 32963,
      callsInDuration: 38963,
      callsOut: 29385,
      callsOutDuration: 31947,
      smses: 2383912,
    },
    {
      date: "2015-10-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 117283,
      accounts: 12858,
      dataSent: 51115,
      dataReceived: 311465,
      callsIn: 32671,
      callsInDuration: 40051,
      callsOut: 29102,
      callsOutDuration: 32053,
      smses: 2433247,
    },
    {
      date: "2015-10-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 116128,
      accounts: 12801,
      dataSent: 51818,
      dataReceived: 306070,
      callsIn: 35312,
      callsInDuration: 38490,
      callsOut: 33293,
      callsOutDuration: 33297,
      smses: 2135523,
    },
    {
      date: "2015-10-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 102825,
      accounts: 12277,
      dataSent: 48480,
      dataReceived: 265605,
      callsIn: 29602,
      callsInDuration: 30802,
      callsOut: 30741,
      callsOutDuration: 28415,
      smses: 2222618,
    },
    {
      date: "2015-10-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 94475,
      accounts: 11865,
      dataSent: 46248,
      dataReceived: 254874,
      callsIn: 20211,
      callsInDuration: 24470,
      callsOut: 23992,
      callsOutDuration: 26140,
      smses: 2003388,
    },
    {
      date: "2015-10-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 100138,
      accounts: 12477,
      dataSent: 46037,
      dataReceived: 267453,
      callsIn: 29518,
      callsInDuration: 36843,
      callsOut: 27059,
      callsOutDuration: 29055,
      smses: 2440512,
    },
    {
      date: "2015-10-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 105958,
      accounts: 12716,
      dataSent: 47559,
      dataReceived: 289549,
      callsIn: 29958,
      callsInDuration: 36626,
      callsOut: 27479,
      callsOutDuration: 29578,
      smses: 2417107,
    },
    {
      date: "2015-10-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 106407,
      accounts: 12700,
      dataSent: 44873,
      dataReceived: 273499,
      callsIn: 31216,
      callsInDuration: 37220,
      callsOut: 28420,
      callsOutDuration: 30086,
      smses: 2401059,
    },
    {
      date: "2015-10-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 133544,
      accounts: 13339,
      dataSent: 49761,
      dataReceived: 308658,
      callsIn: 31220,
      callsInDuration: 37560,
      callsOut: 28656,
      callsOutDuration: 30846,
      smses: 2524082,
    },
    {
      date: "2015-10-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 104507,
      accounts: 12894,
      dataSent: 45380,
      dataReceived: 273212,
      callsIn: 33368,
      callsInDuration: 37053,
      callsOut: 31648,
      callsOutDuration: 32442,
      smses: 2047384,
    },
    {
      date: "2015-10-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 113546,
      accounts: 13005,
      dataSent: 47501,
      dataReceived: 288087,
      callsIn: 24835,
      callsInDuration: 27567,
      callsOut: 27583,
      callsOutDuration: 25301,
      smses: 1425739,
    },
    {
      date: "2015-10-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 95791,
      accounts: 12288,
      dataSent: 43349,
      dataReceived: 266673,
      callsIn: 20768,
      callsInDuration: 23465,
      callsOut: 23852,
      callsOutDuration: 21177,
      smses: 1215803,
    },
    {
      date: "2015-10-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 102526,
      accounts: 12628,
      dataSent: 45152,
      dataReceived: 280983,
      callsIn: 27281,
      callsInDuration: 37272,
      callsOut: 26579,
      callsOutDuration: 29687,
      smses: 1606875,
    },
    {
      date: "2015-10-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 104454,
      accounts: 12886,
      dataSent: 46639,
      dataReceived: 291767,
      callsIn: 29987,
      callsInDuration: 36757,
      callsOut: 28451,
      callsOutDuration: 31357,
      smses: 1730112,
    },
    {
      date: "2015-10-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 110134,
      accounts: 13136,
      dataSent: 46360,
      dataReceived: 302737,
      callsIn: 30363,
      callsInDuration: 37403,
      callsOut: 28795,
      callsOutDuration: 29915,
      smses: 1895773,
    },
    {
      date: "2015-10-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 105093,
      accounts: 12926,
      dataSent: 42352,
      dataReceived: 271622,
      callsIn: 29413,
      callsInDuration: 37442,
      callsOut: 27971,
      callsOutDuration: 30895,
      smses: 1879908,
    },
    {
      date: "2015-10-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 148159,
      accounts: 13877,
      dataSent: 54389,
      dataReceived: 346254,
      callsIn: 33524,
      callsInDuration: 38580,
      callsOut: 33028,
      callsOutDuration: 33057,
      smses: 1700964,
    },
    {
      date: "2015-10-31",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 97181,
      accounts: 12669,
      dataSent: 39080,
      dataReceived: 236289,
      callsIn: 26919,
      callsInDuration: 29630,
      callsOut: 29929,
      callsOutDuration: 27185,
      smses: 1576422,
    },
    {
      date: "2015-11-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 100927,
      accounts: 12929,
      dataSent: 54043,
      dataReceived: 338417,
      callsIn: 18401,
      callsInDuration: 22599,
      callsOut: 21669,
      callsOutDuration: 19876,
      smses: 1215414,
    },
    {
      date: "2015-11-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 107831,
      accounts: 13164,
      dataSent: 46172,
      dataReceived: 275239,
      callsIn: 28987,
      callsInDuration: 36724,
      callsOut: 28717,
      callsOutDuration: 31712,
      smses: 1502730,
    },
    {
      date: "2015-11-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 120803,
      accounts: 13885,
      dataSent: 56754,
      dataReceived: 360453,
      callsIn: 29745,
      callsInDuration: 38072,
      callsOut: 29101,
      callsOutDuration: 31800,
      smses: 1617277,
    },
    {
      date: "2015-11-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 108094,
      accounts: 13365,
      dataSent: 49401,
      dataReceived: 302559,
      callsIn: 30817,
      callsInDuration: 39588,
      callsOut: 29691,
      callsOutDuration: 32971,
      smses: 2026894,
    },
    {
      date: "2015-11-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 121679,
      accounts: 13939,
      dataSent: 55094,
      dataReceived: 328648,
      callsIn: 32696,
      callsInDuration: 39810,
      callsOut: 32402,
      callsOutDuration: 33997,
      smses: 2182979,
    },
    {
      date: "2015-11-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 119523,
      accounts: 13885,
      dataSent: 55772,
      dataReceived: 344369,
      callsIn: 36570,
      callsInDuration: 39098,
      callsOut: 36854,
      callsOutDuration: 37059,
      smses: 2268626,
    },
    {
      date: "2015-11-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 103871,
      accounts: 13330,
      dataSent: 47576,
      dataReceived: 295252,
      callsIn: 30114,
      callsInDuration: 31254,
      callsOut: 33068,
      callsOutDuration: 29227,
      smses: 2064547,
    },
    {
      date: "2015-11-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 145444,
      accounts: 13452,
      dataSent: 42392,
      dataReceived: 259659,
      callsIn: 20118,
      callsInDuration: 22889,
      callsOut: 24088,
      callsOutDuration: 22000,
      smses: 1703756,
    },
    {
      date: "2015-11-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 113233,
      accounts: 13864,
      dataSent: 50987,
      dataReceived: 316985,
      callsIn: 32393,
      callsInDuration: 37876,
      callsOut: 32514,
      callsOutDuration: 33696,
      smses: 2261459,
    },
    {
      date: "2015-11-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 114621,
      accounts: 13940,
      dataSent: 52951,
      dataReceived: 323445,
      callsIn: 33606,
      callsInDuration: 40255,
      callsOut: 32834,
      callsOutDuration: 35910,
      smses: 2319829,
    },
    {
      date: "2015-11-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 126287,
      accounts: 14664,
      dataSent: 48917,
      dataReceived: 307601,
      callsIn: 33316,
      callsInDuration: 39585,
      callsOut: 33734,
      callsOutDuration: 35805,
      smses: 2145557,
    },
    {
      date: "2015-11-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 145000,
      accounts: 14845,
      dataSent: 63488,
      dataReceived: 387911,
      callsIn: 33321,
      callsInDuration: 41224,
      callsOut: 33525,
      callsOutDuration: 35880,
      smses: 2383335,
    },
    {
      date: "2015-11-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 124402,
      accounts: 14463,
      dataSent: 57420,
      dataReceived: 346315,
      callsIn: 36403,
      callsInDuration: 40414,
      callsOut: 38082,
      callsOutDuration: 37615,
      smses: 1990335,
    },
    {
      date: "2015-11-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 111277,
      accounts: 13882,
      dataSent: 52596,
      dataReceived: 329172,
      callsIn: 31122,
      callsInDuration: 32644,
      callsOut: 34414,
      callsOutDuration: 30782,
      smses: 1724914,
    },
    {
      date: "2015-11-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 99516,
      accounts: 13017,
      dataSent: 45308,
      dataReceived: 273927,
      callsIn: 21104,
      callsInDuration: 22966,
      callsOut: 25368,
      callsOutDuration: 23327,
      smses: 1515683,
    },
    {
      date: "2015-11-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 121668,
      accounts: 14383,
      dataSent: 56199,
      dataReceived: 348431,
      callsIn: 32138,
      callsInDuration: 39456,
      callsOut: 32193,
      callsOutDuration: 33451,
      smses: 1675167,
    },
    {
      date: "2015-11-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 118350,
      accounts: 14162,
      dataSent: 53059,
      dataReceived: 332495,
      callsIn: 32433,
      callsInDuration: 39316,
      callsOut: 31468,
      callsOutDuration: 34204,
      smses: 1763304,
    },
    {
      date: "2015-11-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 127273,
      accounts: 14430,
      dataSent: 55820,
      dataReceived: 360165,
      callsIn: 33414,
      callsInDuration: 40611,
      callsOut: 31997,
      callsOutDuration: 34436,
      smses: 1777571,
    },
    {
      date: "2015-11-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 126073,
      accounts: 14567,
      dataSent: 62760,
      dataReceived: 400847,
      callsIn: 32266,
      callsInDuration: 40850,
      callsOut: 31902,
      callsOutDuration: 34546,
      smses: 1701871,
    },
    {
      date: "2015-11-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 123728,
      accounts: 14659,
      dataSent: 60234,
      dataReceived: 371504,
      callsIn: 36810,
      callsInDuration: 41036,
      callsOut: 38801,
      callsOutDuration: 38183,
      smses: 1594155,
    },
    {
      date: "2015-11-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 112895,
      accounts: 14126,
      dataSent: 52714,
      dataReceived: 338674,
      callsIn: 28565,
      callsInDuration: 31051,
      callsOut: 33853,
      callsOutDuration: 29909,
      smses: 1261896,
    },
    {
      date: "2015-11-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 107992,
      accounts: 13787,
      dataSent: 49002,
      dataReceived: 307665,
      callsIn: 21231,
      callsInDuration: 24272,
      callsOut: 26401,
      callsOutDuration: 23470,
      smses: 1044546,
    },
    {
      date: "2015-11-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 122546,
      accounts: 14572,
      dataSent: 51881,
      dataReceived: 322091,
      callsIn: 30945,
      callsInDuration: 38989,
      callsOut: 32813,
      callsOutDuration: 35943,
      smses: 1452752,
    },
    {
      date: "2015-11-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 129956,
      accounts: 15015,
      dataSent: 59395,
      dataReceived: 361845,
      callsIn: 32417,
      callsInDuration: 41200,
      callsOut: 34212,
      callsOutDuration: 36919,
      smses: 1256270,
    },
    {
      date: "2015-11-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 127180,
      accounts: 14902,
      dataSent: 55464,
      dataReceived: 340634,
      callsIn: 32423,
      callsInDuration: 40819,
      callsOut: 34195,
      callsOutDuration: 36296,
      smses: 1313011,
    },
    {
      date: "2015-11-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 133210,
      accounts: 14952,
      dataSent: 57731,
      dataReceived: 346509,
      callsIn: 35468,
      callsInDuration: 42301,
      callsOut: 37640,
      callsOutDuration: 38722,
      smses: 1395251,
    },
    {
      date: "2015-11-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 110286,
      accounts: 13988,
      dataSent: 51545,
      dataReceived: 325236,
      callsIn: 24802,
      callsInDuration: 30493,
      callsOut: 28622,
      callsOutDuration: 28703,
      smses: 1177575,
    },
    {
      date: "2015-11-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 112962,
      accounts: 14111,
      dataSent: 50456,
      dataReceived: 298177,
      callsIn: 28195,
      callsInDuration: 31101,
      callsOut: 33828,
      callsOutDuration: 31761,
      smses: 1193740,
    },
    {
      date: "2015-11-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 110323,
      accounts: 13798,
      dataSent: 51997,
      dataReceived: 311997,
      callsIn: 19392,
      callsInDuration: 22655,
      callsOut: 25524,
      callsOutDuration: 23182,
      smses: 1014240,
    },
    {
      date: "2015-11-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 118599,
      accounts: 14048,
      dataSent: 41447,
      dataReceived: 246656,
      callsIn: 33603,
      callsInDuration: 42042,
      callsOut: 35352,
      callsOutDuration: 38480,
      smses: 1323683,
    },
    {
      date: "2015-12-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 139050,
      accounts: 15312,
      dataSent: 63557,
      dataReceived: 393811,
      callsIn: 34388,
      callsInDuration: 43485,
      callsOut: 35274,
      callsOutDuration: 38254,
      smses: 1481084,
    },
    {
      date: "2015-12-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 126023,
      accounts: 14762,
      dataSent: 53685,
      dataReceived: 316401,
      callsIn: 34609,
      callsInDuration: 43332,
      callsOut: 36339,
      callsOutDuration: 39237,
      smses: 1583257,
    },
    {
      date: "2015-12-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 132700,
      accounts: 15012,
      dataSent: 55184,
      dataReceived: 334847,
      callsIn: 36060,
      callsInDuration: 43135,
      callsOut: 38017,
      callsOutDuration: 39561,
      smses: 1696404,
    },
    {
      date: "2015-12-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 169358,
      accounts: 15754,
      dataSent: 54634,
      dataReceived: 332000,
      callsIn: 39587,
      callsInDuration: 44157,
      callsOut: 42903,
      callsOutDuration: 42597,
      smses: 1687409,
    },
    {
      date: "2015-12-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 136982,
      accounts: 14987,
      dataSent: 62258,
      dataReceived: 382387,
      callsIn: 31484,
      callsInDuration: 34781,
      callsOut: 37322,
      callsOutDuration: 34465,
      smses: 1414294,
    },
    {
      date: "2015-12-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 118563,
      accounts: 13971,
      dataSent: 55936,
      dataReceived: 340770,
      callsIn: 23139,
      callsInDuration: 27896,
      callsOut: 28976,
      callsOutDuration: 26364,
      smses: 1271723,
    },
    {
      date: "2015-12-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 121850,
      accounts: 14311,
      dataSent: 49059,
      dataReceived: 330849,
      callsIn: 22945,
      callsInDuration: 26971,
      callsOut: 28327,
      callsOutDuration: 26993,
      smses: 1037579,
    },
    {
      date: "2015-12-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 119422,
      accounts: 14041,
      dataSent: 51565,
      dataReceived: 341224,
      callsIn: 20724,
      callsInDuration: 26253,
      callsOut: 25046,
      callsOutDuration: 24892,
      smses: 1005168,
    },
    {
      date: "2015-12-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 151376,
      accounts: 14874,
      dataSent: 56949,
      dataReceived: 373824,
      callsIn: 37700,
      callsInDuration: 45559,
      callsOut: 39854,
      callsOutDuration: 40985,
      smses: 1421174,
    },
    {
      date: "2015-12-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 156291,
      accounts: 14824,
      dataSent: 53957,
      dataReceived: 364184,
      callsIn: 36681,
      callsInDuration: 45093,
      callsOut: 38924,
      callsOutDuration: 41264,
      smses: 1594699,
    },
    {
      date: "2015-12-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 160503,
      accounts: 15635,
      dataSent: 60630,
      dataReceived: 373387,
      callsIn: 41282,
      callsInDuration: 46469,
      callsOut: 42338,
      callsOutDuration: 42968,
      smses: 1744080,
    },
    {
      date: "2015-12-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 127673,
      accounts: 14350,
      dataSent: 49516,
      dataReceived: 308309,
      callsIn: 30141,
      callsInDuration: 34544,
      callsOut: 34231,
      callsOutDuration: 31959,
      smses: 1399902,
    },
    {
      date: "2015-12-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 123085,
      accounts: 14198,
      dataSent: 53796,
      dataReceived: 329217,
      callsIn: 21625,
      callsInDuration: 26547,
      callsOut: 27529,
      callsOutDuration: 26255,
      smses: 1000514,
    },
    {
      date: "2015-12-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 157386,
      accounts: 15247,
      dataSent: 57861,
      dataReceived: 338470,
      callsIn: 36669,
      callsInDuration: 44599,
      callsOut: 38050,
      callsOutDuration: 40925,
      smses: 1614113,
    },
    {
      date: "2015-12-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 159767,
      accounts: 15208,
      dataSent: 57522,
      dataReceived: 350184,
      callsIn: 37502,
      callsInDuration: 45937,
      callsOut: 36581,
      callsOutDuration: 41153,
      smses: 1988021,
    },
    {
      date: "2015-12-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 162124,
      accounts: 15982,
      dataSent: 61827,
      dataReceived: 374986,
      callsIn: 38669,
      callsInDuration: 46790,
      callsOut: 37882,
      callsOutDuration: 42297,
      smses: 1998391,
    },
    {
      date: "2015-12-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 161970,
      accounts: 15500,
      dataSent: 63065,
      dataReceived: 380135,
      callsIn: 38852,
      callsInDuration: 47279,
      callsOut: 38974,
      callsOutDuration: 41626,
      smses: 2077616,
    },
    {
      date: "2015-12-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 162052,
      accounts: 15703,
      dataSent: 65550,
      dataReceived: 385942,
      callsIn: 43149,
      callsInDuration: 48606,
      callsOut: 45052,
      callsOutDuration: 45575,
      smses: 1944937,
    },
    {
      date: "2015-12-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 152618,
      accounts: 15284,
      dataSent: 63321,
      dataReceived: 375815,
      callsIn: 34730,
      callsInDuration: 37737,
      callsOut: 40368,
      callsOutDuration: 37362,
      smses: 1691664,
    },
    {
      date: "2015-12-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 132324,
      accounts: 14590,
      dataSent: 58001,
      dataReceived: 348751,
      callsIn: 23817,
      callsInDuration: 27359,
      callsOut: 29309,
      callsOutDuration: 27034,
      smses: 1339360,
    },
    {
      date: "2015-12-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 152767,
      accounts: 15541,
      dataSent: 60211,
      dataReceived: 369503,
      callsIn: 39717,
      callsInDuration: 46517,
      callsOut: 41587,
      callsOutDuration: 43173,
      smses: 2050222,
    },
    {
      date: "2015-12-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 155923,
      accounts: 15815,
      dataSent: 64597,
      dataReceived: 387722,
      callsIn: 40777,
      callsInDuration: 47807,
      callsOut: 42329,
      callsOutDuration: 43204,
      smses: 2193862,
    },
    {
      date: "2015-12-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 152183,
      accounts: 15985,
      dataSent: 63111,
      dataReceived: 383757,
      callsIn: 45005,
      callsInDuration: 49498,
      callsOut: 48337,
      callsOutDuration: 47801,
      smses: 1907122,
    },
    {
      date: "2015-12-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 152473,
      accounts: 15687,
      dataSent: 58890,
      dataReceived: 361138,
      callsIn: 35306,
      callsInDuration: 38279,
      callsOut: 42869,
      callsOutDuration: 41196,
      smses: 1082403,
    },
    {
      date: "2015-12-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 140226,
      accounts: 14865,
      dataSent: 63345,
      dataReceived: 370516,
      callsIn: 24945,
      callsInDuration: 30043,
      callsOut: 34960,
      callsOutDuration: 35390,
      smses: 642156,
    },
    {
      date: "2015-12-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 132819,
      accounts: 14742,
      dataSent: 57596,
      dataReceived: 351185,
      callsIn: 26610,
      callsInDuration: 29361,
      callsOut: 32937,
      callsOutDuration: 30334,
      smses: 1021852,
    },
    {
      date: "2015-12-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 131924,
      accounts: 14305,
      dataSent: 54579,
      dataReceived: 348267,
      callsIn: 20752,
      callsInDuration: 24265,
      callsOut: 26493,
      callsOutDuration: 25147,
      smses: 847554,
    },
    {
      date: "2015-12-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 149008,
      accounts: 15201,
      dataSent: 60046,
      dataReceived: 367365,
      callsIn: 35463,
      callsInDuration: 42971,
      callsOut: 37843,
      callsOutDuration: 40157,
      smses: 1339571,
    },
    {
      date: "2015-12-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 151714,
      accounts: 15436,
      dataSent: 58802,
      dataReceived: 369789,
      callsIn: 34893,
      callsInDuration: 43916,
      callsOut: 39468,
      callsOutDuration: 42754,
      smses: 1263096,
    },
    {
      date: "2015-12-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 157977,
      accounts: 15589,
      dataSent: 59761,
      dataReceived: 352137,
      callsIn: 39141,
      callsInDuration: 46179,
      callsOut: 43893,
      callsOutDuration: 45069,
      smses: 1448621,
    },
    {
      date: "2015-12-31",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 158524,
      accounts: 15686,
      dataSent: 49953,
      dataReceived: 289010,
      callsIn: 34519,
      callsInDuration: 38754,
      callsOut: 40869,
      callsOutDuration: 41662,
      smses: 995206,
    },
    {
      date: "2016-01-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 166871,
      accounts: 15601,
      dataSent: 78775,
      dataReceived: 457929,
      callsIn: 24651,
      callsInDuration: 29500,
      callsOut: 33681,
      callsOutDuration: 33885,
      smses: 640069,
    },
    {
      date: "2016-01-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 143742,
      accounts: 14671,
      dataSent: 58563,
      dataReceived: 363271,
      callsIn: 24490,
      callsInDuration: 28755,
      callsOut: 29870,
      callsOutDuration: 28580,
      smses: 794762,
    },
    {
      date: "2016-01-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 136582,
      accounts: 14257,
      dataSent: 56050,
      dataReceived: 356000,
      callsIn: 18555,
      callsInDuration: 24058,
      callsOut: 23982,
      callsOutDuration: 23424,
      smses: 576129,
    },
    {
      date: "2016-01-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 156224,
      accounts: 14637,
      dataSent: 60661,
      dataReceived: 375185,
      callsIn: 32066,
      callsInDuration: 41380,
      callsOut: 34182,
      callsOutDuration: 37929,
      smses: 1100265,
    },
    {
      date: "2016-01-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 162074,
      accounts: 14959,
      dataSent: 59110,
      dataReceived: 371916,
      callsIn: 34095,
      callsInDuration: 43125,
      callsOut: 35138,
      callsOutDuration: 39131,
      smses: 1506620,
    },
    {
      date: "2016-01-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 155779,
      accounts: 15171,
      dataSent: 60087,
      dataReceived: 375806,
      callsIn: 33948,
      callsInDuration: 41985,
      callsOut: 34943,
      callsOutDuration: 38837,
      smses: 1846089,
    },
    {
      date: "2016-01-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 158611,
      accounts: 15184,
      dataSent: 61827,
      dataReceived: 387374,
      callsIn: 33661,
      callsInDuration: 43821,
      callsOut: 34547,
      callsOutDuration: 38843,
      smses: 2048378,
    },
    {
      date: "2016-01-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 153199,
      accounts: 15359,
      dataSent: 62384,
      dataReceived: 385926,
      callsIn: 34897,
      callsInDuration: 42070,
      callsOut: 36199,
      callsOutDuration: 38939,
      smses: 1871558,
    },
    {
      date: "2016-01-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 138543,
      accounts: 15038,
      dataSent: 58751,
      dataReceived: 373373,
      callsIn: 28263,
      callsInDuration: 33370,
      callsOut: 33450,
      callsOutDuration: 32389,
      smses: 1567929,
    },
    {
      date: "2016-01-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 132318,
      accounts: 14330,
      dataSent: 56210,
      dataReceived: 359704,
      callsIn: 20692,
      callsInDuration: 25610,
      callsOut: 26305,
      callsOutDuration: 25290,
      smses: 1347570,
    },
    {
      date: "2016-01-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 149526,
      accounts: 15323,
      dataSent: 61052,
      dataReceived: 374936,
      callsIn: 33467,
      callsInDuration: 42144,
      callsOut: 35694,
      callsOutDuration: 39297,
      smses: 1974705,
    },
    {
      date: "2016-01-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 147099,
      accounts: 15433,
      dataSent: 58419,
      dataReceived: 367618,
      callsIn: 32545,
      callsInDuration: 42450,
      callsOut: 34254,
      callsOutDuration: 37986,
      smses: 2020513,
    },
    {
      date: "2016-01-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 164605,
      accounts: 16365,
      dataSent: 62726,
      dataReceived: 392288,
      callsIn: 33742,
      callsInDuration: 42467,
      callsOut: 34645,
      callsOutDuration: 38121,
      smses: 2043905,
    },
    {
      date: "2016-01-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 150436,
      accounts: 15573,
      dataSent: 63645,
      dataReceived: 397124,
      callsIn: 35691,
      callsInDuration: 42875,
      callsOut: 35771,
      callsOutDuration: 39101,
      smses: 2221349,
    },
    {
      date: "2016-01-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 156902,
      accounts: 15616,
      dataSent: 63078,
      dataReceived: 410857,
      callsIn: 36591,
      callsInDuration: 44613,
      callsOut: 37880,
      callsOutDuration: 39796,
      smses: 2029689,
    },
    {
      date: "2016-01-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 146870,
      accounts: 15192,
      dataSent: 60370,
      dataReceived: 383942,
      callsIn: 30596,
      callsInDuration: 34461,
      callsOut: 35271,
      callsOutDuration: 32882,
      smses: 1902537,
    },
    {
      date: "2016-01-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 131361,
      accounts: 14501,
      dataSent: 56986,
      dataReceived: 351593,
      callsIn: 21226,
      callsInDuration: 26094,
      callsOut: 27679,
      callsOutDuration: 26190,
      smses: 1313157,
    },
    {
      date: "2016-01-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 146380,
      accounts: 15134,
      dataSent: 57360,
      dataReceived: 354291,
      callsIn: 33589,
      callsInDuration: 41779,
      callsOut: 34478,
      callsOutDuration: 37858,
      smses: 1905037,
    },
    {
      date: "2016-01-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 149616,
      accounts: 15830,
      dataSent: 69025,
      dataReceived: 435366,
      callsIn: 32763,
      callsInDuration: 42941,
      callsOut: 33771,
      callsOutDuration: 38016,
      smses: 1884241,
    },
    {
      date: "2016-01-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 134616,
      accounts: 15680,
      dataSent: 64907,
      dataReceived: 411246,
      callsIn: 32987,
      callsInDuration: 43564,
      callsOut: 34267,
      callsOutDuration: 38385,
      smses: 1953336,
    },
    {
      date: "2016-01-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 127683,
      accounts: 15520,
      dataSent: 65974,
      dataReceived: 409249,
      callsIn: 33480,
      callsInDuration: 42505,
      callsOut: 34659,
      callsOutDuration: 39692,
      smses: 1927674,
    },
    {
      date: "2016-01-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 132087,
      accounts: 15869,
      dataSent: 67193,
      dataReceived: 437462,
      callsIn: 35581,
      callsInDuration: 43259,
      callsOut: 39072,
      callsOutDuration: 42130,
      smses: 1759143,
    },
    {
      date: "2016-01-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 127233,
      accounts: 15386,
      dataSent: 63623,
      dataReceived: 407248,
      callsIn: 29582,
      callsInDuration: 34237,
      callsOut: 34589,
      callsOutDuration: 32804,
      smses: 1608107,
    },
    {
      date: "2016-01-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 118086,
      accounts: 14832,
      dataSent: 61742,
      dataReceived: 390592,
      callsIn: 21606,
      callsInDuration: 26696,
      callsOut: 27010,
      callsOutDuration: 25979,
      smses: 1421143,
    },
    {
      date: "2016-01-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 140724,
      accounts: 15674,
      dataSent: 63845,
      dataReceived: 405773,
      callsIn: 32029,
      callsInDuration: 42560,
      callsOut: 34059,
      callsOutDuration: 39810,
      smses: 1828588,
    },
    {
      date: "2016-01-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 145022,
      accounts: 15962,
      dataSent: 66031,
      dataReceived: 421719,
      callsIn: 31479,
      callsInDuration: 41784,
      callsOut: 33053,
      callsOutDuration: 38854,
      smses: 1789426,
    },
    {
      date: "2016-01-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 151922,
      accounts: 16477,
      dataSent: 65912,
      dataReceived: 408527,
      callsIn: 32150,
      callsInDuration: 42951,
      callsOut: 33517,
      callsOutDuration: 38783,
      smses: 1979144,
    },
    {
      date: "2016-01-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 150477,
      accounts: 16025,
      dataSent: 70428,
      dataReceived: 449700,
      callsIn: 32338,
      callsInDuration: 43668,
      callsOut: 33228,
      callsOutDuration: 38744,
      smses: 1882012,
    },
    {
      date: "2016-01-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 145476,
      accounts: 15753,
      dataSent: 64017,
      dataReceived: 407086,
      callsIn: 35022,
      callsInDuration: 43908,
      callsOut: 36083,
      callsOutDuration: 39193,
      smses: 1750887,
    },
    {
      date: "2016-01-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 133070,
      accounts: 15518,
      dataSent: 59289,
      dataReceived: 396348,
      callsIn: 28727,
      callsInDuration: 34620,
      callsOut: 32570,
      callsOutDuration: 32164,
      smses: 1504606,
    },
    {
      date: "2016-01-31",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 119644,
      accounts: 14524,
      dataSent: 47582,
      dataReceived: 303508,
      callsIn: 21552,
      callsInDuration: 28260,
      callsOut: 26481,
      callsOutDuration: 26510,
      smses: 1190667,
    },
    {
      date: "2016-02-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 147843,
      accounts: 16539,
      dataSent: 71536,
      dataReceived: 449722,
      callsIn: 33189,
      callsInDuration: 43546,
      callsOut: 34529,
      callsOutDuration: 41004,
      smses: 1532502,
    },
    {
      date: "2016-02-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 147744,
      accounts: 16394,
      dataSent: 62554,
      dataReceived: 397248,
      callsIn: 33758,
      callsInDuration: 43585,
      callsOut: 34548,
      callsOutDuration: 41131,
      smses: 1816566,
    },
    {
      date: "2016-02-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 158554,
      accounts: 16962,
      dataSent: 74255,
      dataReceived: 478984,
      callsIn: 33638,
      callsInDuration: 43252,
      callsOut: 35389,
      callsOutDuration: 41511,
      smses: 1726802,
    },
    {
      date: "2016-02-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 143988,
      accounts: 16434,
      dataSent: 63633,
      dataReceived: 422758,
      callsIn: 34709,
      callsInDuration: 45707,
      callsOut: 38024,
      callsOutDuration: 42923,
      smses: 1770592,
    },
    {
      date: "2016-02-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 152419,
      accounts: 16750,
      dataSent: 68463,
      dataReceived: 432727,
      callsIn: 38394,
      callsInDuration: 46750,
      callsOut: 42108,
      callsOutDuration: 45938,
      smses: 1895545,
    },
    {
      date: "2016-02-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 144512,
      accounts: 16260,
      dataSent: 65564,
      dataReceived: 429857,
      callsIn: 31510,
      callsInDuration: 36828,
      callsOut: 38483,
      callsOutDuration: 36892,
      smses: 1523720,
    },
    {
      date: "2016-02-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 129074,
      accounts: 15618,
      dataSent: 64487,
      dataReceived: 408560,
      callsIn: 22886,
      callsInDuration: 27737,
      callsOut: 29603,
      callsOutDuration: 28432,
      smses: 1114802,
    },
    {
      date: "2016-02-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 133880,
      accounts: 15438,
      dataSent: 62244,
      dataReceived: 397795,
      callsIn: 22982,
      callsInDuration: 29390,
      callsOut: 28374,
      callsOutDuration: 28338,
      smses: 1250041,
    },
    {
      date: "2016-02-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 128411,
      accounts: 15263,
      dataSent: 57395,
      dataReceived: 374709,
      callsIn: 23987,
      callsInDuration: 31369,
      callsOut: 28238,
      callsOutDuration: 29063,
      smses: 1290656,
    },
    {
      date: "2016-02-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 148673,
      accounts: 15879,
      dataSent: 52276,
      dataReceived: 333779,
      callsIn: 36821,
      callsInDuration: 45578,
      callsOut: 38899,
      callsOutDuration: 44237,
      smses: 1896596,
    },
    {
      date: "2016-02-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 162337,
      accounts: 17483,
      dataSent: 83343,
      dataReceived: 516153,
      callsIn: 36557,
      callsInDuration: 47187,
      callsOut: 37371,
      callsOutDuration: 43490,
      smses: 1943133,
    },
    {
      date: "2016-02-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 153506,
      accounts: 16872,
      dataSent: 70543,
      dataReceived: 448609,
      callsIn: 39345,
      callsInDuration: 48641,
      callsOut: 41387,
      callsOutDuration: 45987,
      smses: 2055331,
    },
    {
      date: "2016-02-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 139514,
      accounts: 16438,
      dataSent: 65439,
      dataReceived: 425096,
      callsIn: 30548,
      callsInDuration: 36576,
      callsOut: 36107,
      callsOutDuration: 35313,
      smses: 1636050,
    },
    {
      date: "2016-02-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 131495,
      accounts: 15733,
      dataSent: 63066,
      dataReceived: 401806,
      callsIn: 22420,
      callsInDuration: 28634,
      callsOut: 28391,
      callsOutDuration: 28017,
      smses: 1084058,
    },
    {
      date: "2016-02-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 147673,
      accounts: 16731,
      dataSent: 67272,
      dataReceived: 428444,
      callsIn: 36310,
      callsInDuration: 46280,
      callsOut: 38464,
      callsOutDuration: 43432,
      smses: 2149262,
    },
    {
      date: "2016-02-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 144575,
      accounts: 16803,
      dataSent: 65349,
      dataReceived: 424515,
      callsIn: 36772,
      callsInDuration: 46624,
      callsOut: 38055,
      callsOutDuration: 42586,
      smses: 2086336,
    },
    {
      date: "2016-02-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 157662,
      accounts: 17252,
      dataSent: 71299,
      dataReceived: 478570,
      callsIn: 37312,
      callsInDuration: 47716,
      callsOut: 38583,
      callsOutDuration: 43731,
      smses: 2205977,
    },
    {
      date: "2016-02-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 153404,
      accounts: 17039,
      dataSent: 69668,
      dataReceived: 453239,
      callsIn: 36708,
      callsInDuration: 48526,
      callsOut: 38700,
      callsOutDuration: 44011,
      smses: 1885610,
    },
    {
      date: "2016-02-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 156415,
      accounts: 17292,
      dataSent: 66842,
      dataReceived: 435264,
      callsIn: 38296,
      callsInDuration: 47974,
      callsOut: 41408,
      callsOutDuration: 45434,
      smses: 2144417,
    },
    {
      date: "2016-02-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 150015,
      accounts: 17501,
      dataSent: 66753,
      dataReceived: 428440,
      callsIn: 32842,
      callsInDuration: 37797,
      callsOut: 36754,
      callsOutDuration: 36255,
      smses: 1517062,
    },
    {
      date: "2016-02-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 131049,
      accounts: 15441,
      dataSent: 47611,
      dataReceived: 311927,
      callsIn: 22857,
      callsInDuration: 28459,
      callsOut: 28774,
      callsOutDuration: 27647,
      smses: 1196976,
    },
    {
      date: "2016-02-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 165949,
      accounts: 17548,
      dataSent: 64610,
      dataReceived: 403952,
      callsIn: 36973,
      callsInDuration: 46962,
      callsOut: 37164,
      callsOutDuration: 42148,
      smses: 1834036,
    },
    {
      date: "2016-02-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 172213,
      accounts: 18247,
      dataSent: 70816,
      dataReceived: 453177,
      callsIn: 37516,
      callsInDuration: 49246,
      callsOut: 35960,
      callsOutDuration: 41448,
      smses: 1901196,
    },
    {
      date: "2016-02-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 191810,
      accounts: 18644,
      dataSent: 83481,
      dataReceived: 541694,
      callsIn: 36539,
      callsInDuration: 48921,
      callsOut: 38137,
      callsOutDuration: 44194,
      smses: 1696005,
    },
    {
      date: "2016-02-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 150604,
      accounts: 17530,
      dataSent: 65917,
      dataReceived: 418273,
      callsIn: 36719,
      callsInDuration: 51053,
      callsOut: 39096,
      callsOutDuration: 44986,
      smses: 1665328,
    },
    {
      date: "2016-02-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 160993,
      accounts: 17788,
      dataSent: 71853,
      dataReceived: 454468,
      callsIn: 37988,
      callsInDuration: 48876,
      callsOut: 40713,
      callsOutDuration: 44640,
      smses: 1779291,
    },
    {
      date: "2016-02-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 155535,
      accounts: 17735,
      dataSent: 74947,
      dataReceived: 476184,
      callsIn: 32410,
      callsInDuration: 39282,
      callsOut: 36583,
      callsOutDuration: 34158,
      smses: 1551891,
    },
    {
      date: "2016-02-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 132286,
      accounts: 16423,
      dataSent: 61200,
      dataReceived: 403200,
      callsIn: 22304,
      callsInDuration: 30855,
      callsOut: 27254,
      callsOutDuration: 26781,
      smses: 1114370,
    },
    {
      date: "2016-02-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 161069,
      accounts: 17628,
      dataSent: 58801,
      dataReceived: 351968,
      callsIn: 37410,
      callsInDuration: 49170,
      callsOut: 38816,
      callsOutDuration: 43278,
      smses: 1880145,
    },
    {
      date: "2016-03-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 214614,
      accounts: 18729,
      dataSent: 70450,
      dataReceived: 426900,
      callsIn: 36039,
      callsInDuration: 50021,
      callsOut: 37071,
      callsOutDuration: 42365,
      smses: 1725188,
    },
    {
      date: "2016-03-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 202542,
      accounts: 18814,
      dataSent: 77106,
      dataReceived: 488415,
      callsIn: 36940,
      callsInDuration: 50101,
      callsOut: 37169,
      callsOutDuration: 43097,
      smses: 1838196,
    },
    {
      date: "2016-03-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 164568,
      accounts: 18586,
      dataSent: 71254,
      dataReceived: 433622,
      callsIn: 37383,
      callsInDuration: 51925,
      callsOut: 38593,
      callsOutDuration: 43705,
      smses: 1579491,
    },
    {
      date: "2016-03-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 172040,
      accounts: 18229,
      dataSent: 74410,
      dataReceived: 492332,
      callsIn: 40927,
      callsInDuration: 52287,
      callsOut: 43712,
      callsOutDuration: 45995,
      smses: 1867922,
    },
    {
      date: "2016-03-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 147837,
      accounts: 17662,
      dataSent: 69245,
      dataReceived: 456575,
      callsIn: 33685,
      callsInDuration: 39134,
      callsOut: 39186,
      callsOutDuration: 36227,
      smses: 1438850,
    },
    {
      date: "2016-03-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 136443,
      accounts: 16717,
      dataSent: 62215,
      dataReceived: 420461,
      callsIn: 22645,
      callsInDuration: 29460,
      callsOut: 28594,
      callsOutDuration: 26854,
      smses: 1128223,
    },
    {
      date: "2016-03-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 162485,
      accounts: 18063,
      dataSent: 70807,
      dataReceived: 439585,
      callsIn: 39652,
      callsInDuration: 50670,
      callsOut: 40905,
      callsOutDuration: 45027,
      smses: 2003889,
    },
    {
      date: "2016-03-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 163497,
      accounts: 18275,
      dataSent: 72162,
      dataReceived: 467075,
      callsIn: 38710,
      callsInDuration: 49893,
      callsOut: 40564,
      callsOutDuration: 44416,
      smses: 1695819,
    },
    {
      date: "2016-03-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 168355,
      accounts: 18557,
      dataSent: 73669,
      dataReceived: 508781,
      callsIn: 38186,
      callsInDuration: 49859,
      callsOut: 39790,
      callsOutDuration: 43975,
      smses: 1762782,
    },
    {
      date: "2016-03-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 165903,
      accounts: 18623,
      dataSent: 73612,
      dataReceived: 502606,
      callsIn: 39093,
      callsInDuration: 49347,
      callsOut: 40089,
      callsOutDuration: 43933,
      smses: 1980268,
    },
    {
      date: "2016-03-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 165503,
      accounts: 18904,
      dataSent: 74223,
      dataReceived: 496043,
      callsIn: 42279,
      callsInDuration: 50933,
      callsOut: 44237,
      callsOutDuration: 46374,
      smses: 1919431,
    },
    {
      date: "2016-03-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 146908,
      accounts: 18126,
      dataSent: 70090,
      dataReceived: 459916,
      callsIn: 33115,
      callsInDuration: 36596,
      callsOut: 38369,
      callsOutDuration: 35360,
      smses: 1481423,
    },
    {
      date: "2016-03-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 141692,
      accounts: 17402,
      dataSent: 64304,
      dataReceived: 419197,
      callsIn: 22450,
      callsInDuration: 29398,
      callsOut: 28791,
      callsOutDuration: 26476,
      smses: 1044162,
    },
    {
      date: "2016-03-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 162557,
      accounts: 18840,
      dataSent: 64828,
      dataReceived: 410670,
      callsIn: 38873,
      callsInDuration: 49627,
      callsOut: 39891,
      callsOutDuration: 43393,
      smses: 2018896,
    },
    {
      date: "2016-03-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 183048,
      accounts: 19929,
      dataSent: 82539,
      dataReceived: 544211,
      callsIn: 40148,
      callsInDuration: 52388,
      callsOut: 40417,
      callsOutDuration: 44012,
      smses: 2089648,
    },
    {
      date: "2016-03-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 157379,
      accounts: 18832,
      dataSent: 60659,
      dataReceived: 421246,
      callsIn: 39783,
      callsInDuration: 51749,
      callsOut: 41436,
      callsOutDuration: 44949,
      smses: 1953267,
    },
    {
      date: "2016-03-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 190088,
      accounts: 20114,
      dataSent: 86619,
      dataReceived: 590475,
      callsIn: 40343,
      callsInDuration: 52581,
      callsOut: 42264,
      callsOutDuration: 46180,
      smses: 2087034,
    },
    {
      date: "2016-03-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 166977,
      accounts: 19384,
      dataSent: 74603,
      dataReceived: 513859,
      callsIn: 41686,
      callsInDuration: 52484,
      callsOut: 45143,
      callsOutDuration: 47124,
      smses: 1953876,
    },
    {
      date: "2016-03-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 154240,
      accounts: 18648,
      dataSent: 69211,
      dataReceived: 486225,
      callsIn: 33947,
      callsInDuration: 38493,
      callsOut: 40391,
      callsOutDuration: 37838,
      smses: 1652053,
    },
    {
      date: "2016-03-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 136411,
      accounts: 17410,
      dataSent: 60398,
      dataReceived: 414481,
      callsIn: 22361,
      callsInDuration: 30062,
      callsOut: 28753,
      callsOutDuration: 27604,
      smses: 1322991,
    },
    {
      date: "2016-03-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 161656,
      accounts: 18627,
      dataSent: 57388,
      dataReceived: 377225,
      callsIn: 39198,
      callsInDuration: 50583,
      callsOut: 40638,
      callsOutDuration: 44383,
      smses: 2329474,
    },
    {
      date: "2016-03-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 194593,
      accounts: 20434,
      dataSent: 87366,
      dataReceived: 584259,
      callsIn: 41467,
      callsInDuration: 52269,
      callsOut: 41630,
      callsOutDuration: 46357,
      smses: 2270410,
    },
    {
      date: "2016-03-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 183731,
      accounts: 20003,
      dataSent: 79248,
      dataReceived: 525861,
      callsIn: 43841,
      callsInDuration: 54083,
      callsOut: 45488,
      callsOutDuration: 46931,
      smses: 2400686,
    },
    {
      date: "2016-03-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 152949,
      accounts: 18495,
      dataSent: 69025,
      dataReceived: 487202,
      callsIn: 29530,
      callsInDuration: 35898,
      callsOut: 34715,
      callsOutDuration: 33441,
      smses: 1852686,
    },
    {
      date: "2016-03-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 147890,
      accounts: 17682,
      dataSent: 64890,
      dataReceived: 459908,
      callsIn: 22597,
      callsInDuration: 29788,
      callsOut: 27723,
      callsOutDuration: 27507,
      smses: 1564301,
    },
    {
      date: "2016-03-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 153378,
      accounts: 18093,
      dataSent: 67761,
      dataReceived: 447520,
      callsIn: 28532,
      callsInDuration: 34159,
      callsOut: 33539,
      callsOutDuration: 31922,
      smses: 1892833,
    },
    {
      date: "2016-03-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 149737,
      accounts: 17875,
      dataSent: 54314,
      dataReceived: 362567,
      callsIn: 22691,
      callsInDuration: 29742,
      callsOut: 27573,
      callsOutDuration: 26479,
      smses: 1382301,
    },
    {
      date: "2016-03-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 202921,
      accounts: 20341,
      dataSent: 73311,
      dataReceived: 462142,
      callsIn: 38005,
      callsInDuration: 49629,
      callsOut: 38578,
      callsOutDuration: 42888,
      smses: 1524870,
    },
    {
      date: "2016-03-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 277971,
      accounts: 20479,
      dataSent: 82890,
      dataReceived: 543975,
      callsIn: 38595,
      callsInDuration: 50612,
      callsOut: 39163,
      callsOutDuration: 42818,
      smses: 1609516,
    },
    {
      date: "2016-03-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 179281,
      accounts: 19416,
      dataSent: 74252,
      dataReceived: 484037,
      callsIn: 38072,
      callsInDuration: 51225,
      callsOut: 39383,
      callsOutDuration: 43730,
      smses: 1505525,
    },
    {
      date: "2016-03-31",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 188621,
      accounts: 19948,
      dataSent: 71024,
      dataReceived: 468737,
      callsIn: 39030,
      callsInDuration: 50262,
      callsOut: 39579,
      callsOutDuration: 42960,
      smses: 1500075,
    },
    {
      date: "2016-04-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 203551,
      accounts: 20459,
      dataSent: 92402,
      dataReceived: 634540,
      callsIn: 42528,
      callsInDuration: 51929,
      callsOut: 45034,
      callsOutDuration: 46148,
      smses: 1471147,
    },
    {
      date: "2016-04-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 155062,
      accounts: 18009,
      dataSent: 56332,
      dataReceived: 406955,
      callsIn: 30576,
      callsInDuration: 37598,
      callsOut: 36409,
      callsOutDuration: 34346,
      smses: 1360921,
    },
    {
      date: "2016-04-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 34756,
      accounts: 11216,
      dataSent: 22117,
      dataReceived: 139805,
      callsIn: 5160,
      callsInDuration: 9080,
      callsOut: 6996,
      callsOutDuration: 7515,
      smses: 77059,
    },
    {
      date: "2016-04-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 186813,
      accounts: 20185,
      dataSent: 80034,
      dataReceived: 531046,
      callsIn: 38207,
      callsInDuration: 50209,
      callsOut: 38675,
      callsOutDuration: 43156,
      smses: 1900508,
    },
    {
      date: "2016-04-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 176932,
      accounts: 19789,
      dataSent: 75259,
      dataReceived: 484897,
      callsIn: 39505,
      callsInDuration: 51055,
      callsOut: 39578,
      callsOutDuration: 44674,
      smses: 2131805,
    },
    {
      date: "2016-04-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 174491,
      accounts: 19771,
      dataSent: 68707,
      dataReceived: 438999,
      callsIn: 40057,
      callsInDuration: 52146,
      callsOut: 39701,
      callsOutDuration: 44626,
      smses: 2168258,
    },
    {
      date: "2016-04-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 197495,
      accounts: 20742,
      dataSent: 81445,
      dataReceived: 531519,
      callsIn: 41464,
      callsInDuration: 54392,
      callsOut: 41579,
      callsOutDuration: 46159,
      smses: 1995869,
    },
    {
      date: "2016-04-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 206788,
      accounts: 21398,
      dataSent: 94529,
      dataReceived: 598430,
      callsIn: 44828,
      callsInDuration: 53577,
      callsOut: 45397,
      callsOutDuration: 47606,
      smses: 2136889,
    },
    {
      date: "2016-04-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 169926,
      accounts: 19644,
      dataSent: 76049,
      dataReceived: 506747,
      callsIn: 34456,
      callsInDuration: 41579,
      callsOut: 39263,
      callsOutDuration: 38434,
      smses: 1516353,
    },
    {
      date: "2016-04-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 155227,
      accounts: 18347,
      dataSent: 66539,
      dataReceived: 449742,
      callsIn: 21941,
      callsInDuration: 30112,
      callsOut: 26918,
      callsOutDuration: 27225,
      smses: 1093320,
    },
    {
      date: "2016-04-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 175750,
      accounts: 20343,
      dataSent: 79715,
      dataReceived: 496624,
      callsIn: 40121,
      callsInDuration: 51424,
      callsOut: 39647,
      callsOutDuration: 43164,
      smses: 2112799,
    },
    {
      date: "2016-04-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 178369,
      accounts: 20401,
      dataSent: 77656,
      dataReceived: 517761,
      callsIn: 39269,
      callsInDuration: 51838,
      callsOut: 39088,
      callsOutDuration: 44837,
      smses: 2032874,
    },
    {
      date: "2016-04-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 180567,
      accounts: 20542,
      dataSent: 79968,
      dataReceived: 521309,
      callsIn: 38907,
      callsInDuration: 53150,
      callsOut: 39017,
      callsOutDuration: 43474,
      smses: 1816707,
    },
    {
      date: "2016-04-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 193084,
      accounts: 20853,
      dataSent: 82574,
      dataReceived: 540807,
      callsIn: 40342,
      callsInDuration: 52926,
      callsOut: 40963,
      callsOutDuration: 45094,
      smses: 1689671,
    },
    {
      date: "2016-04-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 196107,
      accounts: 20998,
      dataSent: 92679,
      dataReceived: 629883,
      callsIn: 42907,
      callsInDuration: 55096,
      callsOut: 44253,
      callsOutDuration: 46161,
      smses: 1680240,
    },
    {
      date: "2016-04-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 174726,
      accounts: 19799,
      dataSent: 75484,
      dataReceived: 523093,
      callsIn: 34734,
      callsInDuration: 42051,
      callsOut: 38776,
      callsOutDuration: 36684,
      smses: 1421599,
    },
    {
      date: "2016-04-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 144221,
      accounts: 18383,
      dataSent: 58557,
      dataReceived: 414963,
      callsIn: 21464,
      callsInDuration: 31408,
      callsOut: 26652,
      callsOutDuration: 27335,
      smses: 1067591,
    },
    {
      date: "2016-04-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 185310,
      accounts: 20826,
      dataSent: 78191,
      dataReceived: 510798,
      callsIn: 38095,
      callsInDuration: 51586,
      callsOut: 38628,
      callsOutDuration: 43656,
      smses: 1606739,
    },
    {
      date: "2016-04-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 182620,
      accounts: 20053,
      dataSent: 65095,
      dataReceived: 459363,
      callsIn: 37396,
      callsInDuration: 51947,
      callsOut: 38420,
      callsOutDuration: 43838,
      smses: 1625087,
    },
    {
      date: "2016-04-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 206043,
      accounts: 21813,
      dataSent: 99330,
      dataReceived: 669224,
      callsIn: 40080,
      callsInDuration: 54266,
      callsOut: 40631,
      callsOutDuration: 43960,
      smses: 1738155,
    },
    {
      date: "2016-04-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 186370,
      accounts: 20925,
      dataSent: 81857,
      dataReceived: 557323,
      callsIn: 39628,
      callsInDuration: 52920,
      callsOut: 39403,
      callsOutDuration: 45140,
      smses: 1674981,
    },
    {
      date: "2016-04-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 190319,
      accounts: 21094,
      dataSent: 88320,
      dataReceived: 582104,
      callsIn: 43549,
      callsInDuration: 54966,
      callsOut: 43559,
      callsOutDuration: 46554,
      smses: 2061942,
    },
    {
      date: "2016-04-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 167331,
      accounts: 20022,
      dataSent: 77581,
      dataReceived: 517419,
      callsIn: 34053,
      callsInDuration: 39394,
      callsOut: 39278,
      callsOutDuration: 37967,
      smses: 1556874,
    },
    {
      date: "2016-04-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 154104,
      accounts: 18653,
      dataSent: 64425,
      dataReceived: 463526,
      callsIn: 22637,
      callsInDuration: 31010,
      callsOut: 27072,
      callsOutDuration: 27097,
      smses: 1242043,
    },
    {
      date: "2016-04-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 175051,
      accounts: 20468,
      dataSent: 77730,
      dataReceived: 517221,
      callsIn: 36306,
      callsInDuration: 49807,
      callsOut: 36080,
      callsOutDuration: 41609,
      smses: 1996749,
    },
    {
      date: "2016-04-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 190255,
      accounts: 20683,
      dataSent: 80560,
      dataReceived: 523771,
      callsIn: 38588,
      callsInDuration: 52286,
      callsOut: 39771,
      callsOutDuration: 42084,
      smses: 2093279,
    },
    {
      date: "2016-04-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 185690,
      accounts: 20704,
      dataSent: 77956,
      dataReceived: 521689,
      callsIn: 38809,
      callsInDuration: 54100,
      callsOut: 37840,
      callsOutDuration: 43984,
      smses: 2113422,
    },
    {
      date: "2016-04-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 187096,
      accounts: 20833,
      dataSent: 79424,
      dataReceived: 521862,
      callsIn: 39231,
      callsInDuration: 54898,
      callsOut: 39335,
      callsOutDuration: 45213,
      smses: 2169082,
    },
    {
      date: "2016-04-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 177239,
      accounts: 20756,
      dataSent: 79614,
      dataReceived: 513856,
      callsIn: 42172,
      callsInDuration: 55345,
      callsOut: 43920,
      callsOutDuration: 46584,
      smses: 2007398,
    },
    {
      date: "2016-04-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 166135,
      accounts: 19914,
      dataSent: 62841,
      dataReceived: 413935,
      callsIn: 34859,
      callsInDuration: 41814,
      callsOut: 40188,
      callsOutDuration: 37757,
      smses: 1665997,
    },
    {
      date: "2016-05-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 171752,
      accounts: 19950,
      dataSent: 78621,
      dataReceived: 536814,
      callsIn: 22733,
      callsInDuration: 32814,
      callsOut: 27822,
      callsOutDuration: 28726,
      smses: 1187490,
    },
    {
      date: "2016-05-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 182767,
      accounts: 20887,
      dataSent: 80106,
      dataReceived: 503621,
      callsIn: 38757,
      callsInDuration: 51830,
      callsOut: 39102,
      callsOutDuration: 43180,
      smses: 2068321,
    },
    {
      date: "2016-05-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 188103,
      accounts: 21142,
      dataSent: 81419,
      dataReceived: 519648,
      callsIn: 39187,
      callsInDuration: 52268,
      callsOut: 39583,
      callsOutDuration: 44575,
      smses: 1802867,
    },
    {
      date: "2016-05-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 187485,
      accounts: 21106,
      dataSent: 76008,
      dataReceived: 498697,
      callsIn: 39702,
      callsInDuration: 55331,
      callsOut: 40767,
      callsOutDuration: 46530,
      smses: 2129654,
    },
    {
      date: "2016-05-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 196617,
      accounts: 21491,
      dataSent: 83946,
      dataReceived: 538161,
      callsIn: 41029,
      callsInDuration: 55297,
      callsOut: 41801,
      callsOutDuration: 47407,
      smses: 1984096,
    },
    {
      date: "2016-05-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 219042,
      accounts: 22441,
      dataSent: 89007,
      dataReceived: 548261,
      callsIn: 44942,
      callsInDuration: 55046,
      callsOut: 45771,
      callsOutDuration: 48379,
      smses: 2149331,
    },
    {
      date: "2016-05-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 188572,
      accounts: 20766,
      dataSent: 79632,
      dataReceived: 536611,
      callsIn: 37534,
      callsInDuration: 45184,
      callsOut: 41527,
      callsOutDuration: 39501,
      smses: 1808089,
    },
    {
      date: "2016-05-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 158089,
      accounts: 19687,
      dataSent: 72746,
      dataReceived: 499612,
      callsIn: 24082,
      callsInDuration: 33141,
      callsOut: 29732,
      callsOutDuration: 29175,
      smses: 1246012,
    },
    {
      date: "2016-05-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 189955,
      accounts: 21274,
      dataSent: 80087,
      dataReceived: 525559,
      callsIn: 41774,
      callsInDuration: 54480,
      callsOut: 41844,
      callsOutDuration: 47070,
      smses: 2051140,
    },
    {
      date: "2016-05-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 186588,
      accounts: 20918,
      dataSent: 67534,
      dataReceived: 436307,
      callsIn: 41665,
      callsInDuration: 55944,
      callsOut: 40764,
      callsOutDuration: 47057,
      smses: 2276832,
    },
    {
      date: "2016-05-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 226435,
      accounts: 22836,
      dataSent: 100928,
      dataReceived: 679584,
      callsIn: 40771,
      callsInDuration: 54655,
      callsOut: 40478,
      callsOutDuration: 46019,
      smses: 2375454,
    },
    {
      date: "2016-05-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 185299,
      accounts: 21623,
      dataSent: 85379,
      dataReceived: 572243,
      callsIn: 42084,
      callsInDuration: 56755,
      callsOut: 41577,
      callsOutDuration: 46079,
      smses: 2283845,
    },
    {
      date: "2016-05-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 201281,
      accounts: 21980,
      dataSent: 91606,
      dataReceived: 638502,
      callsIn: 44929,
      callsInDuration: 55685,
      callsOut: 45601,
      callsOutDuration: 47434,
      smses: 2277873,
    },
    {
      date: "2016-05-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 179795,
      accounts: 20812,
      dataSent: 78747,
      dataReceived: 549657,
      callsIn: 35344,
      callsInDuration: 41879,
      callsOut: 40176,
      callsOutDuration: 38599,
      smses: 1848621,
    },
    {
      date: "2016-05-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 160009,
      accounts: 19215,
      dataSent: 66326,
      dataReceived: 465868,
      callsIn: 22782,
      callsInDuration: 33463,
      callsOut: 27904,
      callsOutDuration: 28708,
      smses: 1208760,
    },
    {
      date: "2016-05-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 191127,
      accounts: 20680,
      dataSent: 66221,
      dataReceived: 450674,
      callsIn: 39802,
      callsInDuration: 54952,
      callsOut: 40431,
      callsOutDuration: 47133,
      smses: 2372047,
    },
    {
      date: "2016-05-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 238543,
      accounts: 23241,
      dataSent: 96481,
      dataReceived: 690437,
      callsIn: 40683,
      callsInDuration: 53959,
      callsOut: 41384,
      callsOutDuration: 47516,
      smses: 2087302,
    },
    {
      date: "2016-05-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 205576,
      accounts: 22272,
      dataSent: 87944,
      dataReceived: 605446,
      callsIn: 40857,
      callsInDuration: 56808,
      callsOut: 42115,
      callsOutDuration: 48165,
      smses: 2135898,
    },
    {
      date: "2016-05-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 201316,
      accounts: 21903,
      dataSent: 82901,
      dataReceived: 562241,
      callsIn: 41506,
      callsInDuration: 57741,
      callsOut: 42925,
      callsOutDuration: 48961,
      smses: 2185045,
    },
    {
      date: "2016-05-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 207288,
      accounts: 23203,
      dataSent: 87739,
      dataReceived: 588759,
      callsIn: 45188,
      callsInDuration: 59874,
      callsOut: 47249,
      callsOutDuration: 50827,
      smses: 2194051,
    },
    {
      date: "2016-05-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 187650,
      accounts: 21352,
      dataSent: 85059,
      dataReceived: 574980,
      callsIn: 35734,
      callsInDuration: 43189,
      callsOut: 41101,
      callsOutDuration: 39869,
      smses: 1729972,
    },
    {
      date: "2016-05-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 159999,
      accounts: 19772,
      dataSent: 68795,
      dataReceived: 473561,
      callsIn: 24103,
      callsInDuration: 32877,
      callsOut: 29168,
      callsOutDuration: 29502,
      smses: 1591893,
    },
    {
      date: "2016-05-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 181579,
      accounts: 21562,
      dataSent: 73713,
      dataReceived: 483417,
      callsIn: 40884,
      callsInDuration: 55111,
      callsOut: 41975,
      callsOutDuration: 48317,
      smses: 2322532,
    },
    {
      date: "2016-05-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 213294,
      accounts: 22984,
      dataSent: 104432,
      dataReceived: 675034,
      callsIn: 45389,
      callsInDuration: 59003,
      callsOut: 46300,
      callsOutDuration: 50373,
      smses: 2280300,
    },
    {
      date: "2016-05-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 168147,
      accounts: 20055,
      dataSent: 72259,
      dataReceived: 483907,
      callsIn: 26647,
      callsInDuration: 37581,
      callsOut: 31130,
      callsOutDuration: 31586,
      smses: 1999668,
    },
    {
      date: "2016-05-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 196196,
      accounts: 21799,
      dataSent: 88820,
      dataReceived: 579907,
      callsIn: 41125,
      callsInDuration: 57130,
      callsOut: 41482,
      callsOutDuration: 46601,
      smses: 2287922,
    },
    {
      date: "2016-05-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 197122,
      accounts: 22015,
      dataSent: 90446,
      dataReceived: 593796,
      callsIn: 43397,
      callsInDuration: 59200,
      callsOut: 44469,
      callsOutDuration: 48113,
      smses: 2485293,
    },
    {
      date: "2016-05-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 181155,
      accounts: 20875,
      dataSent: 74512,
      dataReceived: 520888,
      callsIn: 32756,
      callsInDuration: 41586,
      callsOut: 38341,
      callsOutDuration: 37686,
      smses: 1883584,
    },
    {
      date: "2016-05-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 162903,
      accounts: 19484,
      dataSent: 68312,
      dataReceived: 477157,
      callsIn: 21839,
      callsInDuration: 33574,
      callsOut: 26540,
      callsOutDuration: 28240,
      smses: 1346214,
    },
    {
      date: "2016-05-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 193809,
      accounts: 21800,
      dataSent: 75547,
      dataReceived: 484535,
      callsIn: 39301,
      callsInDuration: 53960,
      callsOut: 39768,
      callsOutDuration: 46400,
      smses: 2018711,
    },
    {
      date: "2016-05-31",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 197803,
      accounts: 23287,
      dataSent: 69295,
      dataReceived: 445415,
      callsIn: 40354,
      callsInDuration: 56065,
      callsOut: 40474,
      callsOutDuration: 47236,
      smses: 2481635,
    },
    {
      date: "2016-06-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 246191,
      accounts: 23737,
      dataSent: 107024,
      dataReceived: 684963,
      callsIn: 40920,
      callsInDuration: 58200,
      callsOut: 42232,
      callsOutDuration: 49087,
      smses: 2253989,
    },
    {
      date: "2016-06-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 250773,
      accounts: 23717,
      dataSent: 94538,
      dataReceived: 616707,
      callsIn: 41481,
      callsInDuration: 58226,
      callsOut: 42476,
      callsOutDuration: 49130,
      smses: 2664430,
    },
    {
      date: "2016-06-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 210320,
      accounts: 23062,
      dataSent: 93586,
      dataReceived: 604076,
      callsIn: 47110,
      callsInDuration: 61451,
      callsOut: 48946,
      callsOutDuration: 52572,
      smses: 2504661,
    },
    {
      date: "2016-06-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 182016,
      accounts: 21683,
      dataSent: 82091,
      dataReceived: 537180,
      callsIn: 34882,
      callsInDuration: 44252,
      callsOut: 41159,
      callsOutDuration: 40526,
      smses: 1925903,
    },
    {
      date: "2016-06-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 169423,
      accounts: 20284,
      dataSent: 70803,
      dataReceived: 462006,
      callsIn: 23984,
      callsInDuration: 34144,
      callsOut: 29863,
      callsOutDuration: 30472,
      smses: 1486895,
    },
    {
      date: "2016-06-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 205492,
      accounts: 22732,
      dataSent: 84201,
      dataReceived: 571261,
      callsIn: 44195,
      callsInDuration: 58389,
      callsOut: 44428,
      callsOutDuration: 49996,
      smses: 2635260,
    },
    {
      date: "2016-06-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 205679,
      accounts: 22875,
      dataSent: 84575,
      dataReceived: 570007,
      callsIn: 43395,
      callsInDuration: 56416,
      callsOut: 43916,
      callsOutDuration: 49754,
      smses: 2422512,
    },
    {
      date: "2016-06-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 193164,
      accounts: 22135,
      dataSent: 68345,
      dataReceived: 458038,
      callsIn: 43771,
      callsInDuration: 58400,
      callsOut: 45671,
      callsOutDuration: 53308,
      smses: 2373623,
    },
    {
      date: "2016-06-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 253011,
      accounts: 24679,
      dataSent: 105328,
      dataReceived: 724005,
      callsIn: 44719,
      callsInDuration: 58872,
      callsOut: 45843,
      callsOutDuration: 53297,
      smses: 2689066,
    },
    {
      date: "2016-06-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 201922,
      accounts: 23665,
      dataSent: 91966,
      dataReceived: 599034,
      callsIn: 49327,
      callsInDuration: 61151,
      callsOut: 50355,
      callsOutDuration: 54882,
      smses: 2470575,
    },
    {
      date: "2016-06-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 189567,
      accounts: 22210,
      dataSent: 78750,
      dataReceived: 536226,
      callsIn: 39621,
      callsInDuration: 46296,
      callsOut: 43854,
      callsOutDuration: 42429,
      smses: 2409452,
    },
    {
      date: "2016-06-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 172046,
      accounts: 20338,
      dataSent: 61115,
      dataReceived: 415444,
      callsIn: 25241,
      callsInDuration: 33146,
      callsOut: 31074,
      callsOutDuration: 31265,
      smses: 1523792,
    },
    {
      date: "2016-06-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 210836,
      accounts: 23104,
      dataSent: 70032,
      dataReceived: 477677,
      callsIn: 43408,
      callsInDuration: 59411,
      callsOut: 43551,
      callsOutDuration: 50112,
      smses: 2411071,
    },
    {
      date: "2016-06-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 237669,
      accounts: 24557,
      dataSent: 103356,
      dataReceived: 725890,
      callsIn: 45093,
      callsInDuration: 61577,
      callsOut: 44704,
      callsOutDuration: 52210,
      smses: 2451504,
    },
    {
      date: "2016-06-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 209832,
      accounts: 22757,
      dataSent: 71604,
      dataReceived: 512120,
      callsIn: 46057,
      callsInDuration: 60652,
      callsOut: 46511,
      callsOutDuration: 52570,
      smses: 2623473,
    },
    {
      date: "2016-06-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 209699,
      accounts: 25107,
      dataSent: 95562,
      dataReceived: 621578,
      callsIn: 51189,
      callsInDuration: 62249,
      callsOut: 52084,
      callsOutDuration: 55815,
      smses: 2602442,
    },
    {
      date: "2016-06-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 206211,
      accounts: 22589,
      dataSent: 85137,
      dataReceived: 583064,
      callsIn: 36010,
      callsInDuration: 45509,
      callsOut: 40684,
      callsOutDuration: 40098,
      smses: 2195613,
    },
    {
      date: "2016-06-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 201745,
      accounts: 22698,
      dataSent: 88071,
      dataReceived: 577441,
      callsIn: 39037,
      callsInDuration: 43112,
      callsOut: 45474,
      callsOutDuration: 42063,
      smses: 2122364,
    },
    {
      date: "2016-06-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 195607,
      accounts: 21995,
      dataSent: 82977,
      dataReceived: 581008,
      callsIn: 28380,
      callsInDuration: 36722,
      callsOut: 35875,
      callsOutDuration: 38100,
      smses: 1565400,
    },
    {
      date: "2016-06-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 188477,
      accounts: 20843,
      dataSent: 76064,
      dataReceived: 519409,
      callsIn: 24856,
      callsInDuration: 35069,
      callsOut: 29260,
      callsOutDuration: 30176,
      smses: 1649560,
    },
    {
      date: "2016-06-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 230194,
      accounts: 23579,
      dataSent: 88113,
      dataReceived: 607162,
      callsIn: 43837,
      callsInDuration: 57191,
      callsOut: 43343,
      callsOutDuration: 49018,
      smses: 2413147,
    },
    {
      date: "2016-06-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 219025,
      accounts: 23232,
      dataSent: 85616,
      dataReceived: 593511,
      callsIn: 44955,
      callsInDuration: 59999,
      callsOut: 44199,
      callsOutDuration: 51607,
      smses: 2558179,
    },
    {
      date: "2016-06-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 240190,
      accounts: 24491,
      dataSent: 94434,
      dataReceived: 662287,
      callsIn: 46304,
      callsInDuration: 61668,
      callsOut: 44655,
      callsOutDuration: 52027,
      smses: 2554308,
    },
    {
      date: "2016-06-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 233361,
      accounts: 24879,
      dataSent: 93846,
      dataReceived: 646973,
      callsIn: 47999,
      callsInDuration: 59885,
      callsOut: 48995,
      callsOutDuration: 53369,
      smses: 2603072,
    },
    {
      date: "2016-06-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 225247,
      accounts: 23282,
      dataSent: 99111,
      dataReceived: 679744,
      callsIn: 36413,
      callsInDuration: 44663,
      callsOut: 40415,
      callsOutDuration: 41200,
      smses: 2118059,
    },
    {
      date: "2016-06-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 175460,
      accounts: 20842,
      dataSent: 76703,
      dataReceived: 521259,
      callsIn: 23924,
      callsInDuration: 30839,
      callsOut: 28462,
      callsOutDuration: 28578,
      smses: 1718625,
    },
    {
      date: "2016-06-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 215833,
      accounts: 23078,
      dataSent: 92447,
      dataReceived: 663620,
      callsIn: 40665,
      callsInDuration: 57536,
      callsOut: 39991,
      callsOutDuration: 47020,
      smses: 2517550,
    },
    {
      date: "2016-06-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 229758,
      accounts: 23858,
      dataSent: 97362,
      dataReceived: 649776,
      callsIn: 43625,
      callsInDuration: 62975,
      callsOut: 42326,
      callsOutDuration: 49915,
      smses: 2509413,
    },
    {
      date: "2016-06-29",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 231125,
      accounts: 23738,
      dataSent: 87413,
      dataReceived: 569956,
      callsIn: 44830,
      callsInDuration: 62636,
      callsOut: 44750,
      callsOutDuration: 53249,
      smses: 2266121,
    },
    {
      date: "2016-06-30",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 271556,
      accounts: 26216,
      dataSent: 105756,
      dataReceived: 712694,
      callsIn: 45498,
      callsInDuration: 63137,
      callsOut: 45601,
      callsOutDuration: 54005,
      smses: 2382945,
    },
    {
      date: "2016-07-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 278324,
      accounts: 26399,
      dataSent: 132369,
      dataReceived: 912078,
      callsIn: 49369,
      callsInDuration: 62193,
      callsOut: 51134,
      callsOutDuration: 56323,
      smses: 2555341,
    },
    {
      date: "2016-07-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 209283,
      accounts: 23330,
      dataSent: 92466,
      dataReceived: 641322,
      callsIn: 36947,
      callsInDuration: 45110,
      callsOut: 42972,
      callsOutDuration: 42618,
      smses: 2184110,
    },
    {
      date: "2016-07-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 181240,
      accounts: 21738,
      dataSent: 80579,
      dataReceived: 566674,
      callsIn: 24572,
      callsInDuration: 36012,
      callsOut: 30456,
      callsOutDuration: 31970,
      smses: 1781832,
    },
    {
      date: "2016-07-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 234421,
      accounts: 24567,
      dataSent: 99249,
      dataReceived: 669092,
      callsIn: 43311,
      callsInDuration: 61278,
      callsOut: 45813,
      callsOutDuration: 54466,
      smses: 2610880,
    },
    {
      date: "2016-07-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 229416,
      accounts: 24873,
      dataSent: 98809,
      dataReceived: 659014,
      callsIn: 46213,
      callsInDuration: 64001,
      callsOut: 47583,
      callsOutDuration: 56353,
      smses: 2628281,
    },
    {
      date: "2016-07-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 239468,
      accounts: 25415,
      dataSent: 104790,
      dataReceived: 694095,
      callsIn: 49392,
      callsInDuration: 66697,
      callsOut: 51297,
      callsOutDuration: 57408,
      smses: 2683404,
    },
    {
      date: "2016-07-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 245712,
      accounts: 25936,
      dataSent: 110604,
      dataReceived: 707694,
      callsIn: 55541,
      callsInDuration: 67788,
      callsOut: 58483,
      callsOutDuration: 62061,
      smses: 2624246,
    },
    {
      date: "2016-07-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 213946,
      accounts: 24170,
      dataSent: 98298,
      dataReceived: 662674,
      callsIn: 38417,
      callsInDuration: 47385,
      callsOut: 45296,
      callsOutDuration: 46296,
      smses: 2114412,
    },
    {
      date: "2016-07-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 208590,
      accounts: 23793,
      dataSent: 98297,
      dataReceived: 665808,
      callsIn: 36856,
      callsInDuration: 45163,
      callsOut: 45028,
      callsOutDuration: 41621,
      smses: 2003944,
    },
    {
      date: "2016-07-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 200722,
      accounts: 22745,
      dataSent: 87626,
      dataReceived: 617269,
      callsIn: 26905,
      callsInDuration: 35911,
      callsOut: 33960,
      callsOutDuration: 32674,
      smses: 1719066,
    },
    {
      date: "2016-07-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 234543,
      accounts: 24942,
      dataSent: 100797,
      dataReceived: 663874,
      callsIn: 48088,
      callsInDuration: 63782,
      callsOut: 47963,
      callsOutDuration: 56331,
      smses: 2582134,
    },
    {
      date: "2016-07-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 243784,
      accounts: 25610,
      dataSent: 107479,
      dataReceived: 707201,
      callsIn: 49018,
      callsInDuration: 65109,
      callsOut: 49276,
      callsOutDuration: 56859,
      smses: 2663109,
    },
    {
      date: "2016-07-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 238365,
      accounts: 25859,
      dataSent: 100879,
      dataReceived: 691586,
      callsIn: 48993,
      callsInDuration: 63998,
      callsOut: 49492,
      callsOutDuration: 56756,
      smses: 2908132,
    },
    {
      date: "2016-07-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 263025,
      accounts: 26431,
      dataSent: 93855,
      dataReceived: 647603,
      callsIn: 50635,
      callsInDuration: 68245,
      callsOut: 50550,
      callsOutDuration: 57232,
      smses: 2740255,
    },
    {
      date: "2016-07-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 268069,
      accounts: 26313,
      dataSent: 94851,
      dataReceived: 643885,
      callsIn: 53646,
      callsInDuration: 66158,
      callsOut: 56068,
      callsOutDuration: 61053,
      smses: 2668834,
    },
    {
      date: "2016-07-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 239411,
      accounts: 25122,
      dataSent: 85387,
      dataReceived: 576398,
      callsIn: 40971,
      callsInDuration: 49675,
      callsOut: 48025,
      callsOutDuration: 47271,
      smses: 2170236,
    },
    {
      date: "2016-07-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 216693,
      accounts: 23532,
      dataSent: 77211,
      dataReceived: 535637,
      callsIn: 28105,
      callsInDuration: 38124,
      callsOut: 35582,
      callsOutDuration: 36709,
      smses: 1908499,
    },
    {
      date: "2016-07-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 248340,
      accounts: 25133,
      dataSent: 81429,
      dataReceived: 554072,
      callsIn: 46858,
      callsInDuration: 65452,
      callsOut: 48135,
      callsOutDuration: 56278,
      smses: 2726216,
    },
    {
      date: "2016-07-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 252808,
      accounts: 25595,
      dataSent: 80316,
      dataReceived: 541976,
      callsIn: 49124,
      callsInDuration: 66279,
      callsOut: 49543,
      callsOutDuration: 57283,
      smses: 2824314,
    },
    {
      date: "2016-07-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 339052,
      accounts: 28340,
      dataSent: 112424,
      dataReceived: 736223,
      callsIn: 55932,
      callsInDuration: 68926,
      callsOut: 57839,
      callsOutDuration: 63008,
      smses: 2943140,
    },
    {
      date: "2016-07-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 309123,
      accounts: 26414,
      dataSent: 85789,
      dataReceived: 578600,
      callsIn: 49858,
      callsInDuration: 65012,
      callsOut: 50860,
      callsOutDuration: 57251,
      smses: 2817677,
    },
    {
      date: "2016-07-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 324878,
      accounts: 27790,
      dataSent: 101194,
      dataReceived: 686162,
      callsIn: 50048,
      callsInDuration: 64968,
      callsOut: 52415,
      callsOutDuration: 57379,
      smses: 2833196,
    },
    {
      date: "2016-07-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 263388,
      accounts: 25727,
      dataSent: 91623,
      dataReceived: 632314,
      callsIn: 41445,
      callsInDuration: 49681,
      callsOut: 47952,
      callsOutDuration: 46230,
      smses: 2394627,
    },
    {
      date: "2016-07-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 237026,
      accounts: 24182,
      dataSent: 82665,
      dataReceived: 587069,
      callsIn: 28496,
      callsInDuration: 39118,
      callsOut: 34675,
      callsOutDuration: 34785,
      smses: 2079237,
    },
    {
      date: "2016-07-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 273538,
      accounts: 25389,
      dataSent: 88319,
      dataReceived: 640513,
      callsIn: 43170,
      callsInDuration: 60915,
      callsOut: 43792,
      callsOutDuration: 52926,
      smses: 2664283,
    },
    {
      date: "2016-07-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 277283,
      accounts: 25752,
      dataSent: 77127,
      dataReceived: 549413,
      callsIn: 44037,
      callsInDuration: 64364,
      callsOut: 44471,
      callsOutDuration: 54559,
      smses: 2746974,
    },
    {
      date: "2016-07-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 305982,
      accounts: 27527,
      dataSent: 107218,
      dataReceived: 800938,
      callsIn: 47152,
      callsInDuration: 68199,
      callsOut: 47672,
      callsOutDuration: 56923,
      smses: 2653600,
    },
    {
      date: "2016-07-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 278844,
      accounts: 26431,
      dataSent: 82622,
      dataReceived: 607191,
      callsIn: 48223,
      callsInDuration: 66760,
      callsOut: 50324,
      callsOutDuration: 57917,
      smses: 2738240,
    },
    {
      date: "2016-07-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 321604,
      accounts: 28268,
      dataSent: 106018,
      dataReceived: 779114,
      callsIn: 51262,
      callsInDuration: 68659,
      callsOut: 54871,
      callsOutDuration: 59252,
      smses: 2698940,
    },
    {
      date: "2016-07-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 273377,
      accounts: 26183,
      dataSent: 88622,
      dataReceived: 613867,
      callsIn: 41981,
      callsInDuration: 52109,
      callsOut: 48046,
      callsOutDuration: 46801,
      smses: 2038019,
    },
    {
      date: "2016-07-31",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 233717,
      accounts: 24001,
      dataSent: 67219,
      dataReceived: 467156,
      callsIn: 27403,
      callsInDuration: 40386,
      callsOut: 33533,
      callsOutDuration: 34765,
      smses: 1623318,
    },
    {
      date: "2016-08-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 319571,
      accounts: 28072,
      dataSent: 119635,
      dataReceived: 825075,
      callsIn: 46973,
      callsInDuration: 67841,
      callsOut: 48566,
      callsOutDuration: 58133,
      smses: 2291598,
    },
    {
      date: "2016-08-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 305491,
      accounts: 27226,
      dataSent: 95724,
      dataReceived: 666747,
      callsIn: 48705,
      callsInDuration: 69770,
      callsOut: 49779,
      callsOutDuration: 59892,
      smses: 2374362,
    },
    {
      date: "2016-08-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 314076,
      accounts: 27708,
      dataSent: 96976,
      dataReceived: 692856,
      callsIn: 48743,
      callsInDuration: 67305,
      callsOut: 51977,
      callsOutDuration: 58185,
      smses: 2393361,
    },
    {
      date: "2016-08-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 328618,
      accounts: 28669,
      dataSent: 103109,
      dataReceived: 695246,
      callsIn: 52896,
      callsInDuration: 70693,
      callsOut: 54155,
      callsOutDuration: 61217,
      smses: 2965362,
    },
    {
      date: "2016-08-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 327870,
      accounts: 28927,
      dataSent: 104521,
      dataReceived: 709744,
      callsIn: 56577,
      callsInDuration: 72528,
      callsOut: 59234,
      callsOutDuration: 64327,
      smses: 2895099,
    },
    {
      date: "2016-08-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 295507,
      accounts: 27874,
      dataSent: 99254,
      dataReceived: 671231,
      callsIn: 46620,
      callsInDuration: 52608,
      callsOut: 53849,
      callsOutDuration: 50755,
      smses: 2514961,
    },
    {
      date: "2016-08-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 277093,
      accounts: 26505,
      dataSent: 91382,
      dataReceived: 608429,
      callsIn: 31666,
      callsInDuration: 40054,
      callsOut: 37562,
      callsOutDuration: 36090,
      smses: 1977447,
    },
    {
      date: "2016-08-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 321262,
      accounts: 28747,
      dataSent: 102545,
      dataReceived: 697624,
      callsIn: 51611,
      callsInDuration: 69712,
      callsOut: 51651,
      callsOutDuration: 60796,
      smses: 2994255,
    },
    {
      date: "2016-08-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 325417,
      accounts: 29219,
      dataSent: 104056,
      dataReceived: 728807,
      callsIn: 52548,
      callsInDuration: 71570,
      callsOut: 53828,
      callsOutDuration: 62791,
      smses: 3130371,
    },
    {
      date: "2016-08-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 344484,
      accounts: 29477,
      dataSent: 107922,
      dataReceived: 746602,
      callsIn: 53393,
      callsInDuration: 72154,
      callsOut: 54382,
      callsOutDuration: 63692,
      smses: 3061731,
    },
    {
      date: "2016-08-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 312808,
      accounts: 29108,
      dataSent: 105382,
      dataReceived: 722655,
      callsIn: 53671,
      callsInDuration: 71131,
      callsOut: 54037,
      callsOutDuration: 62667,
      smses: 2945425,
    },
    {
      date: "2016-08-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 312077,
      accounts: 29507,
      dataSent: 106222,
      dataReceived: 744021,
      callsIn: 58487,
      callsInDuration: 74391,
      callsOut: 61104,
      callsOutDuration: 66375,
      smses: 3177119,
    },
    {
      date: "2016-08-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 282561,
      accounts: 28111,
      dataSent: 95968,
      dataReceived: 697505,
      callsIn: 47897,
      callsInDuration: 57791,
      callsOut: 54064,
      callsOutDuration: 51455,
      smses: 2625330,
    },
    {
      date: "2016-08-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 265547,
      accounts: 26536,
      dataSent: 88726,
      dataReceived: 647019,
      callsIn: 32233,
      callsInDuration: 40669,
      callsOut: 40152,
      callsOutDuration: 39421,
      smses: 2088533,
    },
    {
      date: "2016-08-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 245132,
      accounts: 25808,
      dataSent: 78399,
      dataReceived: 541649,
      callsIn: 32537,
      callsInDuration: 44954,
      callsOut: 37471,
      callsOutDuration: 38338,
      smses: 2267820,
    },
    {
      date: "2016-08-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 339322,
      accounts: 30056,
      dataSent: 109354,
      dataReceived: 737292,
      callsIn: 52591,
      callsInDuration: 73687,
      callsOut: 52554,
      callsOutDuration: 61298,
      smses: 3080789,
    },
    {
      date: "2016-08-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 318478,
      accounts: 28511,
      dataSent: 81067,
      dataReceived: 573825,
      callsIn: 53682,
      callsInDuration: 73619,
      callsOut: 53829,
      callsOutDuration: 63523,
      smses: 3289995,
    },
    {
      date: "2016-08-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 460336,
      accounts: 31329,
      dataSent: 140172,
      dataReceived: 958782,
      callsIn: 55167,
      callsInDuration: 76729,
      callsOut: 54468,
      callsOutDuration: 62336,
      smses: 2855422,
    },
    {
      date: "2016-08-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 305352,
      accounts: 29494,
      dataSent: 109359,
      dataReceived: 764695,
      callsIn: 57275,
      callsInDuration: 76886,
      callsOut: 58658,
      callsOutDuration: 63903,
      smses: 2762974,
    },
    {
      date: "2016-08-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 286035,
      accounts: 27930,
      dataSent: 94512,
      dataReceived: 675421,
      callsIn: 46385,
      callsInDuration: 56378,
      callsOut: 51237,
      callsOutDuration: 49594,
      smses: 1876152,
    },
    {
      date: "2016-08-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 263981,
      accounts: 26432,
      dataSent: 88047,
      dataReceived: 631704,
      callsIn: 32695,
      callsInDuration: 45332,
      callsOut: 38334,
      callsOutDuration: 37524,
      smses: 1225035,
    },
    {
      date: "2016-08-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 311178,
      accounts: 29105,
      dataSent: 128851,
      dataReceived: 910911,
      callsIn: 50855,
      callsInDuration: 72225,
      callsOut: 49199,
      callsOutDuration: 57408,
      smses: 2699063,
    },
    {
      date: "2016-08-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 291855,
      accounts: 28822,
      dataSent: 131144,
      dataReceived: 941021,
      callsIn: 51660,
      callsInDuration: 73158,
      callsOut: 48846,
      callsOutDuration: 56293,
      smses: 2839280,
    },
    {
      date: "2016-08-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 277263,
      accounts: 27565,
      dataSent: 100865,
      dataReceived: 770220,
      callsIn: 52154,
      callsInDuration: 73722,
      callsOut: 49883,
      callsOutDuration: 59152,
      smses: 3011450,
    },
    {
      date: "2016-08-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 355393,
      accounts: 31057,
      dataSent: 175679,
      dataReceived: 1264533,
      callsIn: 52860,
      callsInDuration: 69405,
      callsOut: 50971,
      callsOutDuration: 58244,
      smses: 2900686,
    },
    {
      date: "2016-08-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 294638,
      accounts: 29355,
      dataSent: 146829,
      dataReceived: 1041288,
      callsIn: 55117,
      callsInDuration: 73274,
      callsOut: 54583,
      callsOutDuration: 59363,
      smses: 2865861,
    },
    {
      date: "2016-08-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 263702,
      accounts: 26613,
      dataSent: 128870,
      dataReceived: 930937,
      callsIn: 39682,
      callsInDuration: 54735,
      callsOut: 43908,
      callsOutDuration: 43922,
      smses: 1855704,
    },
    {
      date: "2016-08-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 235047,
      accounts: 25236,
      dataSent: 111813,
      dataReceived: 829705,
      callsIn: 28381,
      callsInDuration: 41107,
      callsOut: 32991,
      callsOutDuration: 33226,
      smses: 1255950,
    },
    {
      date: "2016-08-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 275120,
      accounts: 28026,
      dataSent: 129413,
      dataReceived: 928929,
      callsIn: 49017,
      callsInDuration: 70009,
      callsOut: 46844,
      callsOutDuration: 56454,
      smses: 2638455,
    },
    {
      date: "2016-08-30",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 273990,
      accounts: 28119,
      dataSent: 123328,
      dataReceived: 875272,
      callsIn: 50041,
      callsInDuration: 73288,
      callsOut: 49199,
      callsOutDuration: 58160,
      smses: 2707567,
    },
    {
      date: "2016-08-31",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 279069,
      accounts: 27619,
      dataSent: 101141,
      dataReceived: 709624,
      callsIn: 50695,
      callsInDuration: 73092,
      callsOut: 49330,
      callsOutDuration: 59082,
      smses: 2676782,
    },
    {
      date: "2016-09-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 346945,
      accounts: 31056,
      dataSent: 179017,
      dataReceived: 1277976,
      callsIn: 49156,
      callsInDuration: 70017,
      callsOut: 48912,
      callsOutDuration: 57135,
      smses: 2564766,
    },
    {
      date: "2016-09-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 300595,
      accounts: 29608,
      dataSent: 149908,
      dataReceived: 1045793,
      callsIn: 54139,
      callsInDuration: 71736,
      callsOut: 55370,
      callsOutDuration: 61040,
      smses: 2658024,
    },
    {
      date: "2016-09-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 265902,
      accounts: 27894,
      dataSent: 134349,
      dataReceived: 943320,
      callsIn: 43023,
      callsInDuration: 51999,
      callsOut: 48196,
      callsOutDuration: 46586,
      smses: 2131277,
    },
    {
      date: "2016-09-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 242757,
      accounts: 25749,
      dataSent: 115733,
      dataReceived: 832587,
      callsIn: 28467,
      callsInDuration: 40566,
      callsOut: 33845,
      callsOutDuration: 33833,
      smses: 1653108,
    },
    {
      date: "2016-09-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 281334,
      accounts: 28119,
      dataSent: 126619,
      dataReceived: 884826,
      callsIn: 47776,
      callsInDuration: 68205,
      callsOut: 46513,
      callsOutDuration: 54251,
      smses: 2739324,
    },
    {
      date: "2016-09-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 314984,
      accounts: 29846,
      dataSent: 135910,
      dataReceived: 945325,
      callsIn: 50414,
      callsInDuration: 72852,
      callsOut: 48453,
      callsOutDuration: 57515,
      smses: 2978641,
    },
    {
      date: "2016-09-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 325914,
      accounts: 30731,
      dataSent: 116827,
      dataReceived: 844913,
      callsIn: 52373,
      callsInDuration: 74065,
      callsOut: 51729,
      callsOutDuration: 61769,
      smses: 2859268,
    },
    {
      date: "2016-09-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 380521,
      accounts: 32898,
      dataSent: 189747,
      dataReceived: 1317229,
      callsIn: 56703,
      callsInDuration: 76606,
      callsOut: 55778,
      callsOutDuration: 64350,
      smses: 2921118,
    },
    {
      date: "2016-09-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 308415,
      accounts: 31168,
      dataSent: 147687,
      dataReceived: 1034775,
      callsIn: 61577,
      callsInDuration: 78669,
      callsOut: 62246,
      callsOutDuration: 67074,
      smses: 3090633,
    },
    {
      date: "2016-09-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 285595,
      accounts: 29888,
      dataSent: 141850,
      dataReceived: 956585,
      callsIn: 50380,
      callsInDuration: 58262,
      callsOut: 55031,
      callsOutDuration: 51274,
      smses: 2577082,
    },
    {
      date: "2016-09-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 258046,
      accounts: 28227,
      dataSent: 124626,
      dataReceived: 872928,
      callsIn: 33780,
      callsInDuration: 44388,
      callsOut: 39651,
      callsOutDuration: 38562,
      smses: 1938441,
    },
    {
      date: "2016-09-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 307701,
      accounts: 29953,
      dataSent: 134552,
      dataReceived: 953269,
      callsIn: 52636,
      callsInDuration: 75253,
      callsOut: 49463,
      callsOutDuration: 59033,
      smses: 3163462,
    },
    {
      date: "2016-09-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 318139,
      accounts: 31540,
      dataSent: 149636,
      dataReceived: 1051907,
      callsIn: 54541,
      callsInDuration: 75350,
      callsOut: 51044,
      callsOutDuration: 59774,
      smses: 3069752,
    },
    {
      date: "2016-09-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 362332,
      accounts: 31441,
      dataSent: 134972,
      dataReceived: 939457,
      callsIn: 56449,
      callsInDuration: 77107,
      callsOut: 52225,
      callsOutDuration: 61170,
      smses: 3263313,
    },
    {
      date: "2016-09-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 365621,
      accounts: 34025,
      dataSent: 136450,
      dataReceived: 962267,
      callsIn: 56727,
      callsInDuration: 77858,
      callsOut: 54131,
      callsOutDuration: 61958,
      smses: 3181268,
    },
    {
      date: "2016-09-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 609461,
      accounts: 34137,
      dataSent: 11987,
      dataReceived: 79160,
      callsIn: 61407,
      callsInDuration: 76057,
      callsOut: 58701,
      callsOutDuration: 62673,
      smses: 3079216,
    },
    {
      date: "2016-09-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 576568,
      accounts: 32779,
      dataSent: 4627,
      dataReceived: 17922,
      callsIn: 47528,
      callsInDuration: 58490,
      callsOut: 50732,
      callsOutDuration: 48210,
      smses: 2536472,
    },
    {
      date: "2016-09-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 541034,
      accounts: 31532,
      dataSent: 4461,
      dataReceived: 18351,
      callsIn: 32300,
      callsInDuration: 44658,
      callsOut: 35685,
      callsOutDuration: 34716,
      smses: 2065327,
    },
    {
      date: "2016-09-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 598279,
      accounts: 33642,
      dataSent: 5053,
      dataReceived: 21090,
      callsIn: 53235,
      callsInDuration: 72681,
      callsOut: 48601,
      callsOutDuration: 58411,
      smses: 2963448,
    },
    {
      date: "2016-09-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 594655,
      accounts: 34759,
      dataSent: 92604,
      dataReceived: 745152,
      callsIn: 55901,
      callsInDuration: 75215,
      callsOut: 53114,
      callsOutDuration: 60811,
      smses: 2678826,
    },
    {
      date: "2016-09-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 567230,
      accounts: 35009,
      dataSent: 179077,
      dataReceived: 1382096,
      callsIn: 57791,
      callsInDuration: 72772,
      callsOut: 56451,
      callsOutDuration: 61612,
      smses: 2316251,
    },
    {
      date: "2016-09-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 541343,
      accounts: 35095,
      dataSent: 140185,
      dataReceived: 1085275,
      callsIn: 54214,
      callsInDuration: 74487,
      callsOut: 51961,
      callsOutDuration: 60867,
      smses: 3009407,
    },
    {
      date: "2016-09-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 621873,
      accounts: 35514,
      dataSent: 134343,
      dataReceived: 1018143,
      callsIn: 59152,
      callsInDuration: 75710,
      callsOut: 58706,
      callsOutDuration: 64396,
      smses: 2832104,
    },
    {
      date: "2016-09-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 319674,
      accounts: 31264,
      dataSent: 155580,
      dataReceived: 1160233,
      callsIn: 47253,
      callsInDuration: 57088,
      callsOut: 51735,
      callsOutDuration: 51545,
      smses: 2559577,
    },
    {
      date: "2016-09-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 293688,
      accounts: 29536,
      dataSent: 129114,
      dataReceived: 949331,
      callsIn: 33709,
      callsInDuration: 44536,
      callsOut: 38879,
      callsOutDuration: 38513,
      smses: 1861191,
    },
    {
      date: "2016-09-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 400684,
      accounts: 34376,
      dataSent: 158202,
      dataReceived: 1173617,
      callsIn: 52239,
      callsInDuration: 74796,
      callsOut: 52229,
      callsOutDuration: 62005,
      smses: 2405146,
    },
    {
      date: "2016-09-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 418036,
      accounts: 34653,
      dataSent: 158449,
      dataReceived: 1171725,
      callsIn: 54968,
      callsInDuration: 79644,
      callsOut: 52827,
      callsOutDuration: 64184,
      smses: 3092375,
    },
    {
      date: "2016-09-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 350225,
      accounts: 33236,
      dataSent: 162358,
      dataReceived: 1205117,
      callsIn: 53886,
      callsInDuration: 76197,
      callsOut: 51547,
      callsOutDuration: 60589,
      smses: 3099779,
    },
    {
      date: "2016-09-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 361293,
      accounts: 32930,
      dataSent: 159916,
      dataReceived: 1173186,
      callsIn: 54204,
      callsInDuration: 79551,
      callsOut: 53333,
      callsOutDuration: 63902,
      smses: 2297216,
    },
    {
      date: "2016-09-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 368451,
      accounts: 33355,
      dataSent: 145435,
      dataReceived: 1080809,
      callsIn: 58692,
      callsInDuration: 80325,
      callsOut: 60807,
      callsOutDuration: 68834,
      smses: 2727065,
    },
    {
      date: "2016-10-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 387459,
      accounts: 34858,
      dataSent: 227406,
      dataReceived: 1661474,
      callsIn: 47873,
      callsInDuration: 59798,
      callsOut: 55851,
      callsOutDuration: 53432,
      smses: 2160554,
    },
    {
      date: "2016-10-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 315786,
      accounts: 30005,
      dataSent: 141233,
      dataReceived: 1070464,
      callsIn: 30368,
      callsInDuration: 45145,
      callsOut: 36900,
      callsOutDuration: 39267,
      smses: 1686675,
    },
    {
      date: "2016-10-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 357243,
      accounts: 32607,
      dataSent: 140517,
      dataReceived: 1007616,
      callsIn: 54034,
      callsInDuration: 77814,
      callsOut: 55453,
      callsOutDuration: 65797,
      smses: 2476634,
    },
    {
      date: "2016-10-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 568970,
      accounts: 36621,
      dataSent: 140757,
      dataReceived: 1022987,
      callsIn: 56316,
      callsInDuration: 78741,
      callsOut: 57305,
      callsOutDuration: 68531,
      smses: 2960728,
    },
    {
      date: "2016-10-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 359486,
      accounts: 35411,
      dataSent: 131042,
      dataReceived: 1001130,
      callsIn: 54991,
      callsInDuration: 78818,
      callsOut: 54635,
      callsOutDuration: 65942,
      smses: 2639173,
    },
    {
      date: "2016-10-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 423879,
      accounts: 36544,
      dataSent: 216234,
      dataReceived: 1597862,
      callsIn: 60010,
      callsInDuration: 82809,
      callsOut: 59811,
      callsOutDuration: 70060,
      smses: 2975807,
    },
    {
      date: "2016-10-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 370151,
      accounts: 34740,
      dataSent: 174426,
      dataReceived: 1334988,
      callsIn: 65241,
      callsInDuration: 83558,
      callsOut: 66354,
      callsOutDuration: 72126,
      smses: 3333007,
    },
    {
      date: "2016-10-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 344695,
      accounts: 33594,
      dataSent: 161974,
      dataReceived: 1218713,
      callsIn: 53328,
      callsInDuration: 63404,
      callsOut: 59611,
      callsOutDuration: 57671,
      smses: 2411323,
    },
    {
      date: "2016-10-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 323884,
      accounts: 32580,
      dataSent: 163129,
      dataReceived: 1167259,
      callsIn: 38843,
      callsInDuration: 48292,
      callsOut: 46193,
      callsOutDuration: 42759,
      smses: 2132005,
    },
    {
      date: "2016-10-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 315805,
      accounts: 31150,
      dataSent: 133586,
      dataReceived: 1018325,
      callsIn: 35137,
      callsInDuration: 49038,
      callsOut: 39456,
      callsOutDuration: 40777,
      smses: 2034412,
    },
    {
      date: "2016-10-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 510812,
      accounts: 37196,
      dataSent: 154002,
      dataReceived: 1177783,
      callsIn: 59999,
      callsInDuration: 81265,
      callsOut: 59715,
      callsOutDuration: 68758,
      smses: 2327056,
    },
    {
      date: "2016-10-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 520376,
      accounts: 37367,
      dataSent: 171485,
      dataReceived: 1302975,
      callsIn: 61302,
      callsInDuration: 82079,
      callsOut: 59469,
      callsOutDuration: 68735,
      smses: 2516952,
    },
    {
      date: "2016-10-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 444126,
      accounts: 35183,
      dataSent: 179275,
      dataReceived: 1364108,
      callsIn: 61760,
      callsInDuration: 84519,
      callsOut: 60684,
      callsOutDuration: 70627,
      smses: 2312477,
    },
    {
      date: "2016-10-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 477374,
      accounts: 37916,
      dataSent: 187102,
      dataReceived: 1384826,
      callsIn: 67649,
      callsInDuration: 85946,
      callsOut: 68611,
      callsOutDuration: 74016,
      smses: 2439187,
    },
    {
      date: "2016-10-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 355927,
      accounts: 33674,
      dataSent: 164586,
      dataReceived: 1242477,
      callsIn: 54345,
      callsInDuration: 64057,
      callsOut: 60203,
      callsOutDuration: 58298,
      smses: 2590012,
    },
    {
      date: "2016-10-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 338123,
      accounts: 32869,
      dataSent: 152620,
      dataReceived: 1130637,
      callsIn: 39255,
      callsInDuration: 52791,
      callsOut: 48486,
      callsOutDuration: 55638,
      smses: 1920797,
    },
    {
      date: "2016-10-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 457586,
      accounts: 37018,
      dataSent: 128046,
      dataReceived: 970564,
      callsIn: 57852,
      callsInDuration: 79669,
      callsOut: 57028,
      callsOutDuration: 66817,
      smses: 2577774,
    },
    {
      date: "2016-10-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 538153,
      accounts: 37394,
      dataSent: 191400,
      dataReceived: 1460077,
      callsIn: 56880,
      callsInDuration: 79395,
      callsOut: 54576,
      callsOutDuration: 66448,
      smses: 2802751,
    },
    {
      date: "2016-10-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 359926,
      accounts: 34277,
      dataSent: 163492,
      dataReceived: 1234868,
      callsIn: 56892,
      callsInDuration: 79986,
      callsOut: 54816,
      callsOutDuration: 64752,
      smses: 3070388,
    },
    {
      date: "2016-10-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 363662,
      accounts: 34476,
      dataSent: 153257,
      dataReceived: 1164523,
      callsIn: 59684,
      callsInDuration: 81208,
      callsOut: 56833,
      callsOutDuration: 67650,
      smses: 3016107,
    },
    {
      date: "2016-10-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 375592,
      accounts: 34300,
      dataSent: 134899,
      dataReceived: 991004,
      callsIn: 66260,
      callsInDuration: 82814,
      callsOut: 65722,
      callsOutDuration: 70523,
      smses: 3224808,
    },
    {
      date: "2016-10-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 426204,
      accounts: 37750,
      dataSent: 205944,
      dataReceived: 1512877,
      callsIn: 52028,
      callsInDuration: 62653,
      callsOut: 56939,
      callsOutDuration: 54669,
      smses: 2256609,
    },
    {
      date: "2016-10-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 364434,
      accounts: 34209,
      dataSent: 168006,
      dataReceived: 1238151,
      callsIn: 35522,
      callsInDuration: 47696,
      callsOut: 41884,
      callsOutDuration: 41598,
      smses: 1737587,
    },
    {
      date: "2016-10-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 388139,
      accounts: 35558,
      dataSent: 168077,
      dataReceived: 1239588,
      callsIn: 58080,
      callsInDuration: 83467,
      callsOut: 55954,
      callsOutDuration: 66764,
      smses: 3031700,
    },
    {
      date: "2016-10-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 404994,
      accounts: 36533,
      dataSent: 177623,
      dataReceived: 1352647,
      callsIn: 59506,
      callsInDuration: 84842,
      callsOut: 53975,
      callsOutDuration: 66389,
      smses: 3185636,
    },
    {
      date: "2016-10-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 362655,
      accounts: 35718,
      dataSent: 166039,
      dataReceived: 1229551,
      callsIn: 58973,
      callsInDuration: 86408,
      callsOut: 55497,
      callsOutDuration: 66854,
      smses: 2835741,
    },
    {
      date: "2016-10-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 394307,
      accounts: 36469,
      dataSent: 175991,
      dataReceived: 1302908,
      callsIn: 61071,
      callsInDuration: 85986,
      callsOut: 58037,
      callsOutDuration: 69017,
      smses: 3119138,
    },
    {
      date: "2016-10-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 397865,
      accounts: 36782,
      dataSent: 182737,
      dataReceived: 1328755,
      callsIn: 65893,
      callsInDuration: 86718,
      callsOut: 65123,
      callsOutDuration: 71714,
      smses: 3183705,
    },
    {
      date: "2016-10-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 410670,
      accounts: 37198,
      dataSent: 197067,
      dataReceived: 1441779,
      callsIn: 54730,
      callsInDuration: 66170,
      callsOut: 58222,
      callsOutDuration: 55888,
      smses: 2565969,
    },
    {
      date: "2016-10-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 342355,
      accounts: 34613,
      dataSent: 159415,
      dataReceived: 1189915,
      callsIn: 36504,
      callsInDuration: 48945,
      callsOut: 42997,
      callsOutDuration: 42424,
      smses: 1780197,
    },
    {
      date: "2016-10-31",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 356491,
      accounts: 35111,
      dataSent: 132249,
      dataReceived: 974942,
      callsIn: 61187,
      callsInDuration: 84845,
      callsOut: 58880,
      callsOutDuration: 68535,
      smses: 2830400,
    },
    {
      date: "2016-11-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 452563,
      accounts: 38456,
      dataSent: 229859,
      dataReceived: 1709988,
      callsIn: 59248,
      callsInDuration: 87170,
      callsOut: 56008,
      callsOutDuration: 67902,
      smses: 2834811,
    },
    {
      date: "2016-11-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 404228,
      accounts: 37310,
      dataSent: 186099,
      dataReceived: 1377242,
      callsIn: 60982,
      callsInDuration: 86450,
      callsOut: 57272,
      callsOutDuration: 70411,
      smses: 2979875,
    },
    {
      date: "2016-11-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 417344,
      accounts: 37500,
      dataSent: 189721,
      dataReceived: 1397346,
      callsIn: 64361,
      callsInDuration: 86981,
      callsOut: 61836,
      callsOutDuration: 71504,
      smses: 3044589,
    },
    {
      date: "2016-11-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 412331,
      accounts: 37350,
      dataSent: 178944,
      dataReceived: 1321366,
      callsIn: 71151,
      callsInDuration: 91193,
      callsOut: 70955,
      callsOutDuration: 76218,
      smses: 3033294,
    },
    {
      date: "2016-11-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 374128,
      accounts: 35965,
      dataSent: 160105,
      dataReceived: 1198707,
      callsIn: 57872,
      callsInDuration: 68577,
      callsOut: 62208,
      callsOutDuration: 59184,
      smses: 2422432,
    },
    {
      date: "2016-11-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 378196,
      accounts: 35934,
      dataSent: 162451,
      dataReceived: 1216135,
      callsIn: 38993,
      callsInDuration: 51388,
      callsOut: 44973,
      callsOutDuration: 43544,
      smses: 1783725,
    },
    {
      date: "2016-11-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 424008,
      accounts: 36833,
      dataSent: 164187,
      dataReceived: 1206260,
      callsIn: 62175,
      callsInDuration: 87403,
      callsOut: 59155,
      callsOutDuration: 70758,
      smses: 2991827,
    },
    {
      date: "2016-11-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 446681,
      accounts: 37059,
      dataSent: 162078,
      dataReceived: 1210668,
      callsIn: 64012,
      callsInDuration: 89600,
      callsOut: 59941,
      callsOutDuration: 71209,
      smses: 3147108,
    },
    {
      date: "2016-11-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 497273,
      accounts: 39821,
      dataSent: 208766,
      dataReceived: 1621001,
      callsIn: 64753,
      callsInDuration: 87868,
      callsOut: 61246,
      callsOutDuration: 72024,
      smses: 3105481,
    },
    {
      date: "2016-11-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 481659,
      accounts: 40074,
      dataSent: 193982,
      dataReceived: 1468158,
      callsIn: 64485,
      callsInDuration: 86131,
      callsOut: 62903,
      callsOutDuration: 72192,
      smses: 3075832,
    },
    {
      date: "2016-11-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 493624,
      accounts: 40383,
      dataSent: 214948,
      dataReceived: 1571184,
      callsIn: 70757,
      callsInDuration: 91406,
      callsOut: 69447,
      callsOutDuration: 76298,
      smses: 3180905,
    },
    {
      date: "2016-11-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 443501,
      accounts: 38762,
      dataSent: 189476,
      dataReceived: 1378841,
      callsIn: 56714,
      callsInDuration: 67678,
      callsOut: 61299,
      callsOutDuration: 58495,
      smses: 2532418,
    },
    {
      date: "2016-11-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 451819,
      accounts: 38143,
      dataSent: 192182,
      dataReceived: 1424630,
      callsIn: 35844,
      callsInDuration: 49980,
      callsOut: 42410,
      callsOutDuration: 42300,
      smses: 1916614,
    },
    {
      date: "2016-11-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 422650,
      accounts: 37387,
      dataSent: 159614,
      dataReceived: 1172857,
      callsIn: 63796,
      callsInDuration: 86184,
      callsOut: 60060,
      callsOutDuration: 71529,
      smses: 3601666,
    },
    {
      date: "2016-11-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 579854,
      accounts: 41035,
      dataSent: 213709,
      dataReceived: 1611462,
      callsIn: 62178,
      callsInDuration: 84396,
      callsOut: 61123,
      callsOutDuration: 70100,
      smses: 1911917,
    },
    {
      date: "2016-11-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 532880,
      accounts: 39653,
      dataSent: 184055,
      dataReceived: 1364980,
      callsIn: 59920,
      callsInDuration: 87980,
      callsOut: 60110,
      callsOutDuration: 72475,
      smses: 670388,
    },
    {
      date: "2016-11-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 494586,
      accounts: 39070,
      dataSent: 209876,
      dataReceived: 1523064,
      callsIn: 58992,
      callsInDuration: 83975,
      callsOut: 60756,
      callsOutDuration: 72686,
      smses: 679615,
    },
    {
      date: "2016-11-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 422634,
      accounts: 37682,
      dataSent: 174028,
      dataReceived: 1262306,
      callsIn: 61280,
      callsInDuration: 87579,
      callsOut: 67776,
      callsOutDuration: 75155,
      smses: 463709,
    },
    {
      date: "2016-11-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 397647,
      accounts: 36491,
      dataSent: 154440,
      dataReceived: 1126302,
      callsIn: 51434,
      callsInDuration: 63509,
      callsOut: 59722,
      callsOutDuration: 56326,
      smses: 333998,
    },
    {
      date: "2016-11-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 436478,
      accounts: 37651,
      dataSent: 180695,
      dataReceived: 1348368,
      callsIn: 34359,
      callsInDuration: 50108,
      callsOut: 41522,
      callsOutDuration: 41443,
      smses: 110790,
    },
    {
      date: "2016-11-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 444192,
      accounts: 36643,
      dataSent: 154020,
      dataReceived: 1166912,
      callsIn: 54147,
      callsInDuration: 84342,
      callsOut: 57302,
      callsOutDuration: 68964,
      smses: 396291,
    },
    {
      date: "2016-11-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 487250,
      accounts: 39386,
      dataSent: 230235,
      dataReceived: 1696496,
      callsIn: 54828,
      callsInDuration: 85180,
      callsOut: 58268,
      callsOutDuration: 69302,
      smses: 306185,
    },
    {
      date: "2016-11-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 414717,
      accounts: 36741,
      dataSent: 175117,
      dataReceived: 1248324,
      callsIn: 56103,
      callsInDuration: 86063,
      callsOut: 59330,
      callsOutDuration: 69958,
      smses: 322010,
    },
    {
      date: "2016-11-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 437816,
      accounts: 37853,
      dataSent: 194184,
      dataReceived: 1349001,
      callsIn: 57252,
      callsInDuration: 86043,
      callsOut: 60664,
      callsOutDuration: 71791,
      smses: 176273,
    },
    {
      date: "2016-11-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 397815,
      accounts: 36821,
      dataSent: 177610,
      dataReceived: 1228571,
      callsIn: 62858,
      callsInDuration: 86272,
      callsOut: 67733,
      callsOutDuration: 72901,
      smses: 118499,
    },
    {
      date: "2016-11-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 416394,
      accounts: 36365,
      dataSent: 179046,
      dataReceived: 1264966,
      callsIn: 49693,
      callsInDuration: 65686,
      callsOut: 57686,
      callsOutDuration: 56713,
      smses: 79630,
    },
    {
      date: "2016-11-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 394666,
      accounts: 34707,
      dataSent: 172155,
      dataReceived: 1232675,
      callsIn: 35905,
      callsInDuration: 50629,
      callsOut: 43635,
      callsOutDuration: 42434,
      smses: 64076,
    },
    {
      date: "2016-11-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 376164,
      accounts: 34205,
      dataSent: 150529,
      dataReceived: 1145248,
      callsIn: 34977,
      callsInDuration: 54168,
      callsOut: 39150,
      callsOutDuration: 42313,
      smses: 67093,
    },
    {
      date: "2016-11-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 458167,
      accounts: 36834,
      dataSent: 176963,
      dataReceived: 1278627,
      callsIn: 55952,
      callsInDuration: 86343,
      callsOut: 59053,
      callsOutDuration: 70469,
      smses: 97252,
    },
    {
      date: "2016-11-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 410192,
      accounts: 36435,
      dataSent: 141641,
      dataReceived: 991450,
      callsIn: 58298,
      callsInDuration: 88069,
      callsOut: 59918,
      callsOutDuration: 71067,
      smses: 100018,
    },
    {
      date: "2016-12-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 514938,
      accounts: 39249,
      dataSent: 227013,
      dataReceived: 1611118,
      callsIn: 59335,
      callsInDuration: 87737,
      callsOut: 61428,
      callsOutDuration: 72728,
      smses: 105264,
    },
    {
      date: "2016-12-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 402205,
      accounts: 35649,
      dataSent: 141664,
      dataReceived: 973698,
      callsIn: 63523,
      callsInDuration: 88545,
      callsOut: 67425,
      callsOutDuration: 74569,
      smses: 101587,
    },
    {
      date: "2016-12-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 468947,
      accounts: 37994,
      dataSent: 164886,
      dataReceived: 1179212,
      callsIn: 51164,
      callsInDuration: 64898,
      callsOut: 57988,
      callsOutDuration: 56476,
      smses: 76518,
    },
    {
      date: "2016-12-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 438953,
      accounts: 36948,
      dataSent: 162514,
      dataReceived: 1168563,
      callsIn: 34949,
      callsInDuration: 52141,
      callsOut: 41668,
      callsOutDuration: 42427,
      smses: 63587,
    },
    {
      date: "2016-12-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 489493,
      accounts: 38606,
      dataSent: 178683,
      dataReceived: 1267094,
      callsIn: 58677,
      callsInDuration: 87474,
      callsOut: 61543,
      callsOutDuration: 73330,
      smses: 103731,
    },
    {
      date: "2016-12-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 497505,
      accounts: 39127,
      dataSent: 185442,
      dataReceived: 1297013,
      callsIn: 61266,
      callsInDuration: 90631,
      callsOut: 64467,
      callsOutDuration: 74256,
      smses: 112505,
    },
    {
      date: "2016-12-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 505469,
      accounts: 40175,
      dataSent: 193976,
      dataReceived: 1346457,
      callsIn: 69527,
      callsInDuration: 95220,
      callsOut: 73742,
      callsOutDuration: 79592,
      smses: 116518,
    },
    {
      date: "2016-12-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 465254,
      accounts: 38487,
      dataSent: 177184,
      dataReceived: 1261408,
      callsIn: 43319,
      callsInDuration: 61820,
      callsOut: 49453,
      callsOutDuration: 51852,
      smses: 69528,
    },
    {
      date: "2016-12-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 476645,
      accounts: 38422,
      dataSent: 183596,
      dataReceived: 1357482,
      callsIn: 43801,
      callsInDuration: 59775,
      callsOut: 48127,
      callsOutDuration: 49712,
      smses: 69564,
    },
    {
      date: "2016-12-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 463338,
      accounts: 37885,
      dataSent: 167915,
      dataReceived: 1219928,
      callsIn: 48881,
      callsInDuration: 60643,
      callsOut: 54805,
      callsOutDuration: 52721,
      smses: 69772,
    },
    {
      date: "2016-12-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 446025,
      accounts: 37138,
      dataSent: 173399,
      dataReceived: 1279912,
      callsIn: 34656,
      callsInDuration: 48953,
      callsOut: 39986,
      callsOutDuration: 40620,
      smses: 55586,
    },
    {
      date: "2016-12-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 490232,
      accounts: 39340,
      dataSent: 203508,
      dataReceived: 1411593,
      callsIn: 60756,
      callsInDuration: 88120,
      callsOut: 62594,
      callsOutDuration: 74227,
      smses: 88826,
    },
    {
      date: "2016-12-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 501120,
      accounts: 39565,
      dataSent: 192138,
      dataReceived: 1404637,
      callsIn: 58722,
      callsInDuration: 87790,
      callsOut: 60506,
      callsOutDuration: 73677,
      smses: 85891,
    },
    {
      date: "2016-12-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 516758,
      accounts: 40272,
      dataSent: 212403,
      dataReceived: 1506310,
      callsIn: 59998,
      callsInDuration: 89867,
      callsOut: 61836,
      callsOutDuration: 74095,
      smses: 101011,
    },
    {
      date: "2016-12-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 501924,
      accounts: 39517,
      dataSent: 198429,
      dataReceived: 1437001,
      callsIn: 61027,
      callsInDuration: 89227,
      callsOut: 63705,
      callsOutDuration: 72862,
      smses: 89753,
    },
    {
      date: "2016-12-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 504728,
      accounts: 40292,
      dataSent: 214698,
      dataReceived: 1535860,
      callsIn: 66547,
      callsInDuration: 90000,
      callsOut: 71034,
      callsOutDuration: 75835,
      smses: 103321,
    },
    {
      date: "2016-12-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 470325,
      accounts: 39147,
      dataSent: 173908,
      dataReceived: 1261370,
      callsIn: 54455,
      callsInDuration: 68573,
      callsOut: 63444,
      callsOutDuration: 59545,
      smses: 69283,
    },
    {
      date: "2016-12-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 422140,
      accounts: 37530,
      dataSent: 168584,
      dataReceived: 1294290,
      callsIn: 35317,
      callsInDuration: 52451,
      callsOut: 43155,
      callsOutDuration: 44492,
      smses: 61716,
    },
    {
      date: "2016-12-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 470000,
      accounts: 37895,
      dataSent: 173407,
      dataReceived: 1232917,
      callsIn: 60675,
      callsInDuration: 88210,
      callsOut: 65087,
      callsOutDuration: 76612,
      smses: 94131,
    },
    {
      date: "2016-12-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 501874,
      accounts: 39830,
      dataSent: 201135,
      dataReceived: 1466473,
      callsIn: 62482,
      callsInDuration: 89812,
      callsOut: 66621,
      callsOutDuration: 75860,
      smses: 98605,
    },
    {
      date: "2016-12-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 536421,
      accounts: 40756,
      dataSent: 192603,
      dataReceived: 1456556,
      callsIn: 64736,
      callsInDuration: 93522,
      callsOut: 69965,
      callsOutDuration: 79338,
      smses: 82882,
    },
    {
      date: "2016-12-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 506542,
      accounts: 40126,
      dataSent: 194041,
      dataReceived: 1456995,
      callsIn: 66238,
      callsInDuration: 94228,
      callsOut: 71505,
      callsOutDuration: 80101,
      smses: 95693,
    },
    {
      date: "2016-12-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 524954,
      accounts: 41030,
      dataSent: 191173,
      dataReceived: 1423463,
      callsIn: 71039,
      callsInDuration: 91080,
      callsOut: 79899,
      callsOutDuration: 82289,
      smses: 91145,
    },
    {
      date: "2016-12-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 482757,
      accounts: 39403,
      dataSent: 183110,
      dataReceived: 1354408,
      callsIn: 53876,
      callsInDuration: 67367,
      callsOut: 65581,
      callsOutDuration: 66406,
      smses: 68559,
    },
    {
      date: "2016-12-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 473048,
      accounts: 39094,
      dataSent: 154227,
      dataReceived: 1227052,
      callsIn: 37953,
      callsInDuration: 54857,
      callsOut: 50449,
      callsOutDuration: 54375,
      smses: 64697,
    },
    {
      date: "2016-12-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 513843,
      accounts: 40368,
      dataSent: 196588,
      dataReceived: 1498606,
      callsIn: 51021,
      callsInDuration: 79863,
      callsOut: 57313,
      callsOutDuration: 69224,
      smses: 78326,
    },
    {
      date: "2016-12-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 532078,
      accounts: 40413,
      dataSent: 181721,
      dataReceived: 1426695,
      callsIn: 54772,
      callsInDuration: 83071,
      callsOut: 61270,
      callsOutDuration: 72547,
      smses: 85223,
    },
    {
      date: "2016-12-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 538598,
      accounts: 41019,
      dataSent: 179847,
      dataReceived: 1357042,
      callsIn: 57682,
      callsInDuration: 85688,
      callsOut: 64961,
      callsOutDuration: 74989,
      smses: 87512,
    },
    {
      date: "2016-12-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 541729,
      accounts: 41653,
      dataSent: 194862,
      dataReceived: 1468833,
      callsIn: 60327,
      callsInDuration: 86856,
      callsOut: 69159,
      callsOutDuration: 78845,
      smses: 88019,
    },
    {
      date: "2016-12-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 540792,
      accounts: 41681,
      dataSent: 193551,
      dataReceived: 1451377,
      callsIn: 61187,
      callsInDuration: 81581,
      callsOut: 73210,
      callsOutDuration: 77958,
      smses: 88114,
    },
    {
      date: "2016-12-31",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 530581,
      accounts: 41720,
      dataSent: 190187,
      dataReceived: 1454483,
      callsIn: 50026,
      callsInDuration: 63348,
      callsOut: 64662,
      callsOutDuration: 65795,
      smses: 71251,
    },
    {
      date: "2017-01-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 495600,
      accounts: 39444,
      dataSent: 207904,
      dataReceived: 1475820,
      callsIn: 40133,
      callsInDuration: 52916,
      callsOut: 56431,
      callsOutDuration: 57579,
      smses: 71448,
    },
    {
      date: "2017-01-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 509817,
      accounts: 40056,
      dataSent: 192742,
      dataReceived: 1452930,
      callsIn: 48208,
      callsInDuration: 74235,
      callsOut: 57746,
      callsOutDuration: 68470,
      smses: 75788,
    },
    {
      date: "2017-01-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 529123,
      accounts: 40829,
      dataSent: 202905,
      dataReceived: 1516876,
      callsIn: 50977,
      callsInDuration: 80076,
      callsOut: 57665,
      callsOutDuration: 69476,
      smses: 79228,
    },
    {
      date: "2017-01-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 521263,
      accounts: 40631,
      dataSent: 205202,
      dataReceived: 1536594,
      callsIn: 52387,
      callsInDuration: 81746,
      callsOut: 60518,
      callsOutDuration: 73861,
      smses: 77208,
    },
    {
      date: "2017-01-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 519655,
      accounts: 40493,
      dataSent: 191252,
      dataReceived: 1474330,
      callsIn: 56027,
      callsInDuration: 85677,
      callsOut: 63617,
      callsOutDuration: 75113,
      smses: 77356,
    },
    {
      date: "2017-01-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 526471,
      accounts: 40731,
      dataSent: 198193,
      dataReceived: 1506162,
      callsIn: 57477,
      callsInDuration: 82465,
      callsOut: 65990,
      callsOutDuration: 74284,
      smses: 80755,
    },
    {
      date: "2017-01-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 488699,
      accounts: 40106,
      dataSent: 194403,
      dataReceived: 1473102,
      callsIn: 47505,
      callsInDuration: 60959,
      callsOut: 58658,
      callsOutDuration: 57043,
      smses: 73617,
    },
    {
      date: "2017-01-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 483326,
      accounts: 39531,
      dataSent: 190678,
      dataReceived: 1467360,
      callsIn: 32634,
      callsInDuration: 47469,
      callsOut: 42499,
      callsOutDuration: 43805,
      smses: 61009,
    },
    {
      date: "2017-01-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 527015,
      accounts: 40654,
      dataSent: 208277,
      dataReceived: 1562906,
      callsIn: 53351,
      callsInDuration: 80006,
      callsOut: 61272,
      callsOutDuration: 73512,
      smses: 97465,
    },
    {
      date: "2017-01-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 513164,
      accounts: 39684,
      dataSent: 182710,
      dataReceived: 1378293,
      callsIn: 52318,
      callsInDuration: 79663,
      callsOut: 59607,
      callsOutDuration: 70666,
      smses: 79969,
    },
    {
      date: "2017-01-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 550669,
      accounts: 40834,
      dataSent: 210819,
      dataReceived: 1540859,
      callsIn: 53075,
      callsInDuration: 81333,
      callsOut: 59054,
      callsOutDuration: 72242,
      smses: 78787,
    },
    {
      date: "2017-01-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 544682,
      accounts: 41171,
      dataSent: 203885,
      dataReceived: 1577852,
      callsIn: 52493,
      callsInDuration: 79773,
      callsOut: 58762,
      callsOutDuration: 71318,
      smses: 75572,
    },
    {
      date: "2017-01-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 549520,
      accounts: 40304,
      dataSent: 206730,
      dataReceived: 1581457,
      callsIn: 54625,
      callsInDuration: 81313,
      callsOut: 61878,
      callsOutDuration: 70720,
      smses: 76848,
    },
    {
      date: "2017-01-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 534787,
      accounts: 40159,
      dataSent: 182975,
      dataReceived: 1368138,
      callsIn: 45827,
      callsInDuration: 61112,
      callsOut: 55330,
      callsOutDuration: 57067,
      smses: 69424,
    },
    {
      date: "2017-01-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 482588,
      accounts: 38435,
      dataSent: 190326,
      dataReceived: 1417304,
      callsIn: 31976,
      callsInDuration: 47915,
      callsOut: 40997,
      callsOutDuration: 42707,
      smses: 60804,
    },
    {
      date: "2017-01-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 544328,
      accounts: 40143,
      dataSent: 207614,
      dataReceived: 1520688,
      callsIn: 50056,
      callsInDuration: 78217,
      callsOut: 56205,
      callsOutDuration: 69107,
      smses: 74122,
    },
    {
      date: "2017-01-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 562080,
      accounts: 39879,
      dataSent: 196281,
      dataReceived: 1441706,
      callsIn: 50629,
      callsInDuration: 78577,
      callsOut: 56610,
      callsOutDuration: 67722,
      smses: 73443,
    },
    {
      date: "2017-01-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 546139,
      accounts: 39700,
      dataSent: 209304,
      dataReceived: 1564641,
      callsIn: 50652,
      callsInDuration: 77526,
      callsOut: 56889,
      callsOutDuration: 68455,
      smses: 71681,
    },
    {
      date: "2017-01-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 558851,
      accounts: 41055,
      dataSent: 218223,
      dataReceived: 1692951,
      callsIn: 51871,
      callsInDuration: 79692,
      callsOut: 56936,
      callsOutDuration: 68346,
      smses: 76939,
    },
    {
      date: "2017-01-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 568020,
      accounts: 41133,
      dataSent: 224585,
      dataReceived: 1738493,
      callsIn: 54850,
      callsInDuration: 79575,
      callsOut: 61772,
      callsOutDuration: 70179,
      smses: 82145,
    },
    {
      date: "2017-01-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 522876,
      accounts: 38673,
      dataSent: 180276,
      dataReceived: 1402150,
      callsIn: 45800,
      callsInDuration: 57877,
      callsOut: 55225,
      callsOutDuration: 55852,
      smses: 64755,
    },
    {
      date: "2017-01-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 472771,
      accounts: 37939,
      dataSent: 175510,
      dataReceived: 1352026,
      callsIn: 32988,
      callsInDuration: 47201,
      callsOut: 42151,
      callsOutDuration: 43623,
      smses: 57470,
    },
    {
      date: "2017-01-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 521666,
      accounts: 38677,
      dataSent: 195566,
      dataReceived: 1497474,
      callsIn: 50010,
      callsInDuration: 78638,
      callsOut: 55265,
      callsOutDuration: 67643,
      smses: 73118,
    },
    {
      date: "2017-01-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 499382,
      accounts: 39303,
      dataSent: 205237,
      dataReceived: 1590987,
      callsIn: 50381,
      callsInDuration: 80590,
      callsOut: 54591,
      callsOutDuration: 68021,
      smses: 73086,
    },
    {
      date: "2017-01-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 514937,
      accounts: 39632,
      dataSent: 211392,
      dataReceived: 1592194,
      callsIn: 50056,
      callsInDuration: 79397,
      callsOut: 54574,
      callsOutDuration: 67489,
      smses: 71586,
    },
    {
      date: "2017-01-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 485196,
      accounts: 39469,
      dataSent: 212471,
      dataReceived: 1615098,
      callsIn: 50652,
      callsInDuration: 81210,
      callsOut: 54612,
      callsOutDuration: 68878,
      smses: 72194,
    },
    {
      date: "2017-01-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 473025,
      accounts: 38644,
      dataSent: 189813,
      dataReceived: 1430554,
      callsIn: 53206,
      callsInDuration: 80748,
      callsOut: 59038,
      callsOutDuration: 71386,
      smses: 75288,
    },
    {
      date: "2017-01-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 495985,
      accounts: 40006,
      dataSent: 221629,
      dataReceived: 1676448,
      callsIn: 44816,
      callsInDuration: 59270,
      callsOut: 53476,
      callsOutDuration: 55468,
      smses: 63875,
    },
    {
      date: "2017-01-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 419375,
      accounts: 35914,
      dataSent: 165352,
      dataReceived: 1244650,
      callsIn: 31991,
      callsInDuration: 46062,
      callsOut: 41097,
      callsOutDuration: 42103,
      smses: 55988,
    },
    {
      date: "2017-01-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 513152,
      accounts: 40378,
      dataSent: 216666,
      dataReceived: 1754504,
      callsIn: 50314,
      callsInDuration: 82032,
      callsOut: 56030,
      callsOutDuration: 70037,
      smses: 74603,
    },
    {
      date: "2017-01-31",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 496528,
      accounts: 39813,
      dataSent: 160541,
      dataReceived: 1343203,
      callsIn: 51041,
      callsInDuration: 82650,
      callsOut: 56465,
      callsOutDuration: 71083,
      smses: 75030,
    },
    {
      date: "2017-02-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 568479,
      accounts: 42076,
      dataSent: 270851,
      dataReceived: 2138037,
      callsIn: 51745,
      callsInDuration: 84633,
      callsOut: 57579,
      callsOutDuration: 73931,
      smses: 77087,
    },
    {
      date: "2017-02-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 491126,
      accounts: 39223,
      dataSent: 186670,
      dataReceived: 1474967,
      callsIn: 52888,
      callsInDuration: 87794,
      callsOut: 58492,
      callsOutDuration: 73665,
      smses: 72164,
    },
    {
      date: "2017-02-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 544717,
      accounts: 41157,
      dataSent: 222031,
      dataReceived: 1674202,
      callsIn: 56865,
      callsInDuration: 86396,
      callsOut: 64782,
      callsOutDuration: 76513,
      smses: 76669,
    },
    {
      date: "2017-02-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 532479,
      accounts: 41231,
      dataSent: 220141,
      dataReceived: 1740518,
      callsIn: 45111,
      callsInDuration: 64064,
      callsOut: 55272,
      callsOutDuration: 58935,
      smses: 67487,
    },
    {
      date: "2017-02-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 466375,
      accounts: 38496,
      dataSent: 186046,
      dataReceived: 1467534,
      callsIn: 32868,
      callsInDuration: 51279,
      callsOut: 41313,
      callsOutDuration: 44490,
      smses: 60350,
    },
    {
      date: "2017-02-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 517104,
      accounts: 38736,
      dataSent: 168372,
      dataReceived: 1250353,
      callsIn: 54031,
      callsInDuration: 88362,
      callsOut: 61110,
      callsOutDuration: 77545,
      smses: 79669,
    },
    {
      date: "2017-02-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 584831,
      accounts: 43221,
      dataSent: 258590,
      dataReceived: 2028346,
      callsIn: 55336,
      callsInDuration: 87248,
      callsOut: 60495,
      callsOutDuration: 75248,
      smses: 73794,
    },
    {
      date: "2017-02-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 460204,
      accounts: 39275,
      dataSent: 170990,
      dataReceived: 1366822,
      callsIn: 55058,
      callsInDuration: 88198,
      callsOut: 60603,
      callsOutDuration: 75068,
      smses: 77182,
    },
    {
      date: "2017-02-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 558327,
      accounts: 42626,
      dataSent: 237808,
      dataReceived: 1865533,
      callsIn: 54915,
      callsInDuration: 88951,
      callsOut: 59294,
      callsOutDuration: 75410,
      smses: 74641,
    },
    {
      date: "2017-02-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 569140,
      accounts: 42241,
      dataSent: 247209,
      dataReceived: 1955075,
      callsIn: 57468,
      callsInDuration: 89444,
      callsOut: 63930,
      callsOutDuration: 76822,
      smses: 81681,
    },
    {
      date: "2017-02-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 484979,
      accounts: 39432,
      dataSent: 186617,
      dataReceived: 1403884,
      callsIn: 47712,
      callsInDuration: 63400,
      callsOut: 55663,
      callsOutDuration: 57722,
      smses: 69283,
    },
    {
      date: "2017-02-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 476735,
      accounts: 38967,
      dataSent: 189348,
      dataReceived: 1452086,
      callsIn: 32102,
      callsInDuration: 51467,
      callsOut: 39794,
      callsOutDuration: 42433,
      smses: 56172,
    },
    {
      date: "2017-02-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 565249,
      accounts: 41725,
      dataSent: 209627,
      dataReceived: 1585155,
      callsIn: 52827,
      callsInDuration: 87606,
      callsOut: 58879,
      callsOutDuration: 74575,
      smses: 77182,
    },
    {
      date: "2017-02-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 559696,
      accounts: 41771,
      dataSent: 227967,
      dataReceived: 1746684,
      callsIn: 55704,
      callsInDuration: 88214,
      callsOut: 60950,
      callsOutDuration: 76310,
      smses: 77148,
    },
    {
      date: "2017-02-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 518190,
      accounts: 40087,
      dataSent: 182345,
      dataReceived: 1388712,
      callsIn: 57447,
      callsInDuration: 92820,
      callsOut: 61652,
      callsOutDuration: 76889,
      smses: 79328,
    },
    {
      date: "2017-02-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 591510,
      accounts: 43172,
      dataSent: 220469,
      dataReceived: 1706840,
      callsIn: 59012,
      callsInDuration: 93668,
      callsOut: 62752,
      callsOutDuration: 77935,
      smses: 78445,
    },
    {
      date: "2017-02-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 580260,
      accounts: 42825,
      dataSent: 251124,
      dataReceived: 2009564,
      callsIn: 61701,
      callsInDuration: 95191,
      callsOut: 66835,
      callsOutDuration: 79092,
      smses: 80573,
    },
    {
      date: "2017-02-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 478838,
      accounts: 39351,
      dataSent: 184358,
      dataReceived: 1425549,
      callsIn: 48312,
      callsInDuration: 69059,
      callsOut: 57670,
      callsOutDuration: 59170,
      smses: 69654,
    },
    {
      date: "2017-02-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 879607,
      accounts: 43150,
      dataSent: 78167,
      dataReceived: 584570,
      callsIn: 36485,
      callsInDuration: 54466,
      callsOut: 45032,
      callsOutDuration: 46171,
      smses: 56897,
    },
    {
      date: "2017-02-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 723471,
      accounts: 44609,
      dataSent: 257086,
      dataReceived: 1921737,
      callsIn: 56755,
      callsInDuration: 92556,
      callsOut: 61900,
      callsOutDuration: 78550,
      smses: 77619,
    },
    {
      date: "2017-02-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 601992,
      accounts: 43338,
      dataSent: 265347,
      dataReceived: 2068728,
      callsIn: 59555,
      callsInDuration: 97547,
      callsOut: 63808,
      callsOutDuration: 78522,
      smses: 77520,
    },
    {
      date: "2017-02-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 518143,
      accounts: 40421,
      dataSent: 180625,
      dataReceived: 1462717,
      callsIn: 60335,
      callsInDuration: 97218,
      callsOut: 64827,
      callsOutDuration: 81945,
      smses: 77580,
    },
    {
      date: "2017-02-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 640126,
      accounts: 44608,
      dataSent: 265523,
      dataReceived: 2129206,
      callsIn: 61210,
      callsInDuration: 96769,
      callsOut: 65896,
      callsOutDuration: 81142,
      smses: 77670,
    },
    {
      date: "2017-02-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 509419,
      accounts: 42504,
      dataSent: 217121,
      dataReceived: 1683859,
      callsIn: 67491,
      callsInDuration: 97975,
      callsOut: 75162,
      callsOutDuration: 86138,
      smses: 87174,
    },
    {
      date: "2017-02-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 474382,
      accounts: 40358,
      dataSent: 215726,
      dataReceived: 1611211,
      callsIn: 53808,
      callsInDuration: 72441,
      callsOut: 64235,
      callsOutDuration: 65674,
      smses: 71734,
    },
    {
      date: "2017-02-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 459453,
      accounts: 39185,
      dataSent: 216042,
      dataReceived: 1700387,
      callsIn: 38605,
      callsInDuration: 53297,
      callsOut: 48316,
      callsOutDuration: 47524,
      smses: 61869,
    },
    {
      date: "2017-02-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 470237,
      accounts: 38104,
      dataSent: 202838,
      dataReceived: 1554864,
      callsIn: 38668,
      callsInDuration: 58690,
      callsOut: 47575,
      callsOutDuration: 50977,
      smses: 63116,
    },
    {
      date: "2017-02-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 464386,
      accounts: 36869,
      dataSent: 143434,
      dataReceived: 1101479,
      callsIn: 38603,
      callsInDuration: 61183,
      callsOut: 44085,
      callsOutDuration: 49222,
      smses: 63622,
    },
    {
      date: "2017-03-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 632691,
      accounts: 43581,
      dataSent: 258965,
      dataReceived: 1950474,
      callsIn: 62387,
      callsInDuration: 99774,
      callsOut: 68130,
      callsOutDuration: 85491,
      smses: 81364,
    },
    {
      date: "2017-03-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 560059,
      accounts: 42551,
      dataSent: 237486,
      dataReceived: 1841091,
      callsIn: 62411,
      callsInDuration: 99988,
      callsOut: 67326,
      callsOutDuration: 84971,
      smses: 84607,
    },
    {
      date: "2017-03-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 534115,
      accounts: 42249,
      dataSent: 222948,
      dataReceived: 1711980,
      callsIn: 64829,
      callsInDuration: 102144,
      callsOut: 69792,
      callsOutDuration: 86229,
      smses: 81523,
    },
    {
      date: "2017-03-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 452940,
      accounts: 40504,
      dataSent: 198287,
      dataReceived: 1491843,
      callsIn: 51889,
      callsInDuration: 73974,
      callsOut: 59160,
      callsOutDuration: 60946,
      smses: 65747,
    },
    {
      date: "2017-03-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 457862,
      accounts: 38932,
      dataSent: 188098,
      dataReceived: 1430020,
      callsIn: 35061,
      callsInDuration: 53654,
      callsOut: 43031,
      callsOutDuration: 46314,
      smses: 55099,
    },
    {
      date: "2017-03-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 530333,
      accounts: 42278,
      dataSent: 211317,
      dataReceived: 1629638,
      callsIn: 62925,
      callsInDuration: 99424,
      callsOut: 68169,
      callsOutDuration: 84329,
      smses: 78307,
    },
    {
      date: "2017-03-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 502508,
      accounts: 41651,
      dataSent: 207079,
      dataReceived: 1560680,
      callsIn: 60635,
      callsInDuration: 99685,
      callsOut: 66147,
      callsOutDuration: 83606,
      smses: 80461,
    },
    {
      date: "2017-03-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 531227,
      accounts: 42490,
      dataSent: 209264,
      dataReceived: 1559381,
      callsIn: 63693,
      callsInDuration: 101060,
      callsOut: 69029,
      callsOutDuration: 83918,
      smses: 86157,
    },
    {
      date: "2017-03-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 488052,
      accounts: 46003,
      dataSent: 203159,
      dataReceived: 1560505,
      callsIn: 64452,
      callsInDuration: 101076,
      callsOut: 68953,
      callsOutDuration: 85458,
      smses: 85305,
    },
    {
      date: "2017-03-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 675454,
      accounts: 47263,
      dataSent: 293464,
      dataReceived: 2209455,
      callsIn: 68466,
      callsInDuration: 100378,
      callsOut: 73696,
      callsOutDuration: 84571,
      smses: 86999,
    },
    {
      date: "2017-03-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 558917,
      accounts: 42664,
      dataSent: 244960,
      dataReceived: 1806672,
      callsIn: 52553,
      callsInDuration: 71666,
      callsOut: 60469,
      callsOutDuration: 60626,
      smses: 68873,
    },
    {
      date: "2017-03-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 449390,
      accounts: 39238,
      dataSent: 184959,
      dataReceived: 1397469,
      callsIn: 37233,
      callsInDuration: 57120,
      callsOut: 44735,
      callsOutDuration: 46031,
      smses: 57183,
    },
    {
      date: "2017-03-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 489614,
      accounts: 42211,
      dataSent: 198398,
      dataReceived: 1461841,
      callsIn: 61871,
      callsInDuration: 98956,
      callsOut: 66545,
      callsOutDuration: 82986,
      smses: 78571,
    },
    {
      date: "2017-03-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 489200,
      accounts: 41949,
      dataSent: 187182,
      dataReceived: 1383589,
      callsIn: 61162,
      callsInDuration: 99584,
      callsOut: 65197,
      callsOutDuration: 80338,
      smses: 81642,
    },
    {
      date: "2017-03-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 605400,
      accounts: 46723,
      dataSent: 46558,
      dataReceived: 360362,
      callsIn: 59232,
      callsInDuration: 96931,
      callsOut: 62918,
      callsOutDuration: 77639,
      smses: 82772,
    },
    {
      date: "2017-03-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 719424,
      accounts: 48251,
      dataSent: 312901,
      dataReceived: 2405926,
      callsIn: 60986,
      callsInDuration: 99605,
      callsOut: 64265,
      callsOutDuration: 79759,
      smses: 78677,
    },
    {
      date: "2017-03-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 540676,
      accounts: 44717,
      dataSent: 247254,
      dataReceived: 1860673,
      callsIn: 68340,
      callsInDuration: 100563,
      callsOut: 73240,
      callsOutDuration: 85679,
      smses: 82152,
    },
    {
      date: "2017-03-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 483531,
      accounts: 42565,
      dataSent: 225805,
      dataReceived: 1640130,
      callsIn: 54524,
      callsInDuration: 74745,
      callsOut: 63208,
      callsOutDuration: 63220,
      smses: 65667,
    },
    {
      date: "2017-03-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 481338,
      accounts: 40514,
      dataSent: 200519,
      dataReceived: 1520202,
      callsIn: 36433,
      callsInDuration: 56730,
      callsOut: 45021,
      callsOutDuration: 46092,
      smses: 57472,
    },
    {
      date: "2017-03-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 486273,
      accounts: 42762,
      dataSent: 176766,
      dataReceived: 1382828,
      callsIn: 61157,
      callsInDuration: 99870,
      callsOut: 67180,
      callsOutDuration: 82403,
      smses: 79483,
    },
    {
      date: "2017-03-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 536589,
      accounts: 46992,
      dataSent: 225771,
      dataReceived: 1725140,
      callsIn: 61119,
      callsInDuration: 100433,
      callsOut: 66193,
      callsOutDuration: 81417,
      smses: 85508,
    },
    {
      date: "2017-03-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 500757,
      accounts: 43946,
      dataSent: 209990,
      dataReceived: 1591337,
      callsIn: 61375,
      callsInDuration: 102449,
      callsOut: 68042,
      callsOutDuration: 84882,
      smses: 82276,
    },
    {
      date: "2017-03-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 525339,
      accounts: 46497,
      dataSent: 201285,
      dataReceived: 1513088,
      callsIn: 68404,
      callsInDuration: 103125,
      callsOut: 75375,
      callsOutDuration: 86739,
      smses: 88069,
    },
    {
      date: "2017-03-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 548166,
      accounts: 45970,
      dataSent: 248491,
      dataReceived: 1889161,
      callsIn: 49224,
      callsInDuration: 70996,
      callsOut: 56570,
      callsOutDuration: 60227,
      smses: 67282,
    },
    {
      date: "2017-03-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 472688,
      accounts: 45832,
      dataSent: 230459,
      dataReceived: 1752552,
      callsIn: 51768,
      callsInDuration: 68916,
      callsOut: 61493,
      callsOutDuration: 60855,
      smses: 69101,
    },
    {
      date: "2017-03-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 429890,
      accounts: 40663,
      dataSent: 194148,
      dataReceived: 1530500,
      callsIn: 35672,
      callsInDuration: 57021,
      callsOut: 43235,
      callsOutDuration: 45132,
      smses: 58501,
    },
    {
      date: "2017-03-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 489242,
      accounts: 45953,
      dataSent: 247443,
      dataReceived: 1914690,
      callsIn: 63322,
      callsInDuration: 103111,
      callsOut: 69915,
      callsOutDuration: 86223,
      smses: 84895,
    },
    {
      date: "2017-03-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 636133,
      accounts: 47339,
      dataSent: 305135,
      dataReceived: 2378837,
      callsIn: 62054,
      callsInDuration: 103993,
      callsOut: 66393,
      callsOutDuration: 84597,
      smses: 81823,
    },
    {
      date: "2017-03-29",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 624268,
      accounts: 47270,
      dataSent: 294502,
      dataReceived: 2295720,
      callsIn: 64185,
      callsInDuration: 107649,
      callsOut: 68159,
      callsOutDuration: 87510,
      smses: 86080,
    },
    {
      date: "2017-03-30",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 626341,
      accounts: 48365,
      dataSent: 269962,
      dataReceived: 2107780,
      callsIn: 63383,
      callsInDuration: 105869,
      callsOut: 68335,
      callsOutDuration: 85252,
      smses: 86994,
    },
    {
      date: "2017-03-31",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 740931,
      accounts: 51756,
      dataSent: 282138,
      dataReceived: 2155909,
      callsIn: 68025,
      callsInDuration: 101319,
      callsOut: 74344,
      callsOutDuration: 85639,
      smses: 91300,
    },
    {
      date: "2017-04-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 642217,
      accounts: 50227,
      dataSent: 382266,
      dataReceived: 2871819,
      callsIn: 56854,
      callsInDuration: 78421,
      callsOut: 66080,
      callsOutDuration: 67373,
      smses: 74882,
    },
    {
      date: "2017-04-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 574414,
      accounts: 44010,
      dataSent: 259813,
      dataReceived: 2047270,
      callsIn: 35370,
      callsInDuration: 58494,
      callsOut: 43970,
      callsOutDuration: 48529,
      smses: 63452,
    },
    {
      date: "2017-04-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 680431,
      accounts: 48652,
      dataSent: 287391,
      dataReceived: 2218067,
      callsIn: 63761,
      callsInDuration: 102634,
      callsOut: 69538,
      callsOutDuration: 87360,
      smses: 90647,
    },
    {
      date: "2017-04-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 666822,
      accounts: 51893,
      dataSent: 289818,
      dataReceived: 2225592,
      callsIn: 65972,
      callsInDuration: 106379,
      callsOut: 69766,
      callsOutDuration: 85186,
      smses: 91846,
    },
    {
      date: "2017-04-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 746494,
      accounts: 52345,
      dataSent: 366940,
      dataReceived: 2793436,
      callsIn: 71948,
      callsInDuration: 110944,
      callsOut: 76160,
      callsOutDuration: 92062,
      smses: 95598,
    },
    {
      date: "2017-04-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 528151,
      accounts: 45155,
      dataSent: 257974,
      dataReceived: 2018989,
      callsIn: 51650,
      callsInDuration: 92874,
      callsOut: 55891,
      callsOutDuration: 68896,
      smses: 73346,
    },
    {
      date: "2017-04-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 629321,
      accounts: 49260,
      dataSent: 319024,
      dataReceived: 2416108,
      callsIn: 75825,
      callsInDuration: 107904,
      callsOut: 83257,
      callsOutDuration: 94140,
      smses: 97651,
    },
    {
      date: "2017-04-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 572057,
      accounts: 47582,
      dataSent: 308907,
      dataReceived: 2440634,
      callsIn: 60226,
      callsInDuration: 82373,
      callsOut: 69036,
      callsOutDuration: 70838,
      smses: 79217,
    },
    {
      date: "2017-04-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 518923,
      accounts: 43871,
      dataSent: 254324,
      dataReceived: 2066743,
      callsIn: 36154,
      callsInDuration: 60319,
      callsOut: 43512,
      callsOutDuration: 49447,
      smses: 63709,
    },
    {
      date: "2017-04-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 619641,
      accounts: 48393,
      dataSent: 299557,
      dataReceived: 2351172,
      callsIn: 66954,
      callsInDuration: 105690,
      callsOut: 73138,
      callsOutDuration: 88885,
      smses: 98800,
    },
    {
      date: "2017-04-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 707341,
      accounts: 49005,
      dataSent: 283832,
      dataReceived: 2160114,
      callsIn: 69722,
      callsInDuration: 109188,
      callsOut: 76375,
      callsOutDuration: 92185,
      smses: 92613,
    },
    {
      date: "2017-04-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 683105,
      accounts: 50502,
      dataSent: 335206,
      dataReceived: 2593386,
      callsIn: 75952,
      callsInDuration: 111916,
      callsOut: 84237,
      callsOutDuration: 96736,
      smses: 104271,
    },
    {
      date: "2017-04-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 536969,
      accounts: 46937,
      dataSent: 294281,
      dataReceived: 2358695,
      callsIn: 60090,
      callsInDuration: 85000,
      callsOut: 67161,
      callsOutDuration: 72880,
      smses: 80346,
    },
    {
      date: "2017-04-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 507149,
      accounts: 45319,
      dataSent: 283944,
      dataReceived: 2247168,
      callsIn: 43060,
      callsInDuration: 64548,
      callsOut: 52250,
      callsOutDuration: 54263,
      smses: 70198,
    },
    {
      date: "2017-04-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 542181,
      accounts: 45977,
      dataSent: 288416,
      dataReceived: 2195498,
      callsIn: 53476,
      callsInDuration: 71980,
      callsOut: 62214,
      callsOutDuration: 61519,
      smses: 74296,
    },
    {
      date: "2017-04-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 522281,
      accounts: 44703,
      dataSent: 266220,
      dataReceived: 2053126,
      callsIn: 38496,
      callsInDuration: 59782,
      callsOut: 46627,
      callsOutDuration: 49085,
      smses: 68547,
    },
    {
      date: "2017-04-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 597646,
      accounts: 48764,
      dataSent: 307937,
      dataReceived: 2385179,
      callsIn: 65359,
      callsInDuration: 103758,
      callsOut: 70480,
      callsOutDuration: 87816,
      smses: 92659,
    },
    {
      date: "2017-04-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 589739,
      accounts: 48642,
      dataSent: 313295,
      dataReceived: 2464729,
      callsIn: 65249,
      callsInDuration: 106940,
      callsOut: 69055,
      callsOutDuration: 86018,
      smses: 90593,
    },
    {
      date: "2017-04-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 590048,
      accounts: 48699,
      dataSent: 303915,
      dataReceived: 2482018,
      callsIn: 66940,
      callsInDuration: 109898,
      callsOut: 70682,
      callsOutDuration: 88276,
      smses: 90126,
    },
    {
      date: "2017-04-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 589421,
      accounts: 49652,
      dataSent: 322484,
      dataReceived: 2585012,
      callsIn: 70438,
      callsInDuration: 110658,
      callsOut: 73716,
      callsOutDuration: 89614,
      smses: 91225,
    },
    {
      date: "2017-04-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 598773,
      accounts: 49721,
      dataSent: 328561,
      dataReceived: 2590553,
      callsIn: 76617,
      callsInDuration: 113763,
      callsOut: 82116,
      callsOutDuration: 93602,
      smses: 95103,
    },
    {
      date: "2017-04-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 553193,
      accounts: 47263,
      dataSent: 293650,
      dataReceived: 2263775,
      callsIn: 60314,
      callsInDuration: 82536,
      callsOut: 70331,
      callsOutDuration: 69522,
      smses: 77779,
    },
    {
      date: "2017-04-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 510239,
      accounts: 45534,
      dataSent: 280400,
      dataReceived: 2187324,
      callsIn: 39422,
      callsInDuration: 62554,
      callsOut: 48489,
      callsOutDuration: 51003,
      smses: 61725,
    },
    {
      date: "2017-04-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 611524,
      accounts: 49219,
      dataSent: 308549,
      dataReceived: 2514858,
      callsIn: 66735,
      callsInDuration: 109954,
      callsOut: 70425,
      callsOutDuration: 87139,
      smses: 92287,
    },
    {
      date: "2017-04-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 579686,
      accounts: 48519,
      dataSent: 313674,
      dataReceived: 2483774,
      callsIn: 65808,
      callsInDuration: 107883,
      callsOut: 67998,
      callsOutDuration: 83304,
      smses: 93525,
    },
    {
      date: "2017-04-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 593590,
      accounts: 48690,
      dataSent: 296688,
      dataReceived: 2276175,
      callsIn: 68507,
      callsInDuration: 112496,
      callsOut: 72258,
      callsOutDuration: 89416,
      smses: 93109,
    },
    {
      date: "2017-04-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 592835,
      accounts: 49673,
      dataSent: 316936,
      dataReceived: 2515804,
      callsIn: 70605,
      callsInDuration: 115023,
      callsOut: 73659,
      callsOutDuration: 89611,
      smses: 91670,
    },
    {
      date: "2017-04-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 632568,
      accounts: 51151,
      dataSent: 330679,
      dataReceived: 2618277,
      callsIn: 79576,
      callsInDuration: 116901,
      callsOut: 84268,
      callsOutDuration: 95284,
      smses: 98141,
    },
    {
      date: "2017-04-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 574439,
      accounts: 48929,
      dataSent: 307430,
      dataReceived: 2391342,
      callsIn: 65054,
      callsInDuration: 85424,
      callsOut: 75256,
      callsOutDuration: 74162,
      smses: 81849,
    },
    {
      date: "2017-04-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 532496,
      accounts: 46783,
      dataSent: 224065,
      dataReceived: 1783578,
      callsIn: 48230,
      callsInDuration: 68400,
      callsOut: 58428,
      callsOutDuration: 56273,
      smses: 70192,
    },
    {
      date: "2017-05-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 538255,
      accounts: 49361,
      dataSent: 340589,
      dataReceived: 2749656,
      callsIn: 40292,
      callsInDuration: 64351,
      callsOut: 47305,
      callsOutDuration: 50280,
      smses: 69640,
    },
    {
      date: "2017-05-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 635357,
      accounts: 50387,
      dataSent: 308047,
      dataReceived: 2598417,
      callsIn: 68996,
      callsInDuration: 109219,
      callsOut: 74723,
      callsOutDuration: 91254,
      smses: 101930,
    },
    {
      date: "2017-05-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 689158,
      accounts: 52339,
      dataSent: 318266,
      dataReceived: 2620620,
      callsIn: 78624,
      callsInDuration: 125412,
      callsOut: 83228,
      callsOutDuration: 102772,
      smses: 155375,
    },
    {
      date: "2017-05-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 653183,
      accounts: 51264,
      dataSent: 325333,
      dataReceived: 2623617,
      callsIn: 74785,
      callsInDuration: 122329,
      callsOut: 78011,
      callsOutDuration: 96810,
      smses: 104063,
    },
    {
      date: "2017-05-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 666977,
      accounts: 51394,
      dataSent: 336795,
      dataReceived: 2655503,
      callsIn: 82152,
      callsInDuration: 120048,
      callsOut: 88968,
      callsOutDuration: 99092,
      smses: 107539,
    },
    {
      date: "2017-05-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 619961,
      accounts: 49653,
      dataSent: 311309,
      dataReceived: 2465905,
      callsIn: 66168,
      callsInDuration: 88092,
      callsOut: 75170,
      callsOutDuration: 74010,
      smses: 84790,
    },
    {
      date: "2017-05-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 550142,
      accounts: 46752,
      dataSent: 263390,
      dataReceived: 2199045,
      callsIn: 42148,
      callsInDuration: 64996,
      callsOut: 50857,
      callsOutDuration: 54102,
      smses: 66918,
    },
    {
      date: "2017-05-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 657128,
      accounts: 51628,
      dataSent: 300635,
      dataReceived: 2460376,
      callsIn: 69031,
      callsInDuration: 113056,
      callsOut: 74721,
      callsOutDuration: 94506,
      smses: 97649,
    },
    {
      date: "2017-05-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 653284,
      accounts: 54386,
      dataSent: 353472,
      dataReceived: 2876678,
      callsIn: 73054,
      callsInDuration: 120122,
      callsOut: 78335,
      callsOutDuration: 98480,
      smses: 97974,
    },
    {
      date: "2017-05-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 637431,
      accounts: 53154,
      dataSent: 313739,
      dataReceived: 2563302,
      callsIn: 73662,
      callsInDuration: 122111,
      callsOut: 78666,
      callsOutDuration: 96925,
      smses: 96574,
    },
    {
      date: "2017-05-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 695859,
      accounts: 55763,
      dataSent: 352160,
      dataReceived: 2896021,
      callsIn: 71022,
      callsInDuration: 115520,
      callsOut: 75285,
      callsOutDuration: 91963,
      smses: 94950,
    },
    {
      date: "2017-05-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 614309,
      accounts: 52039,
      dataSent: 344388,
      dataReceived: 2785787,
      callsIn: 76395,
      callsInDuration: 113804,
      callsOut: 81515,
      callsOutDuration: 94079,
      smses: 100099,
    },
    {
      date: "2017-05-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 576110,
      accounts: 49918,
      dataSent: 309776,
      dataReceived: 2509121,
      callsIn: 63154,
      callsInDuration: 86340,
      callsOut: 73014,
      callsOutDuration: 72605,
      smses: 81046,
    },
    {
      date: "2017-05-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 536429,
      accounts: 47275,
      dataSent: 280333,
      dataReceived: 2279999,
      callsIn: 42300,
      callsInDuration: 67355,
      callsOut: 50228,
      callsOutDuration: 52593,
      smses: 66045,
    },
    {
      date: "2017-05-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 639631,
      accounts: 52129,
      dataSent: 320067,
      dataReceived: 2583639,
      callsIn: 70952,
      callsInDuration: 119758,
      callsOut: 75352,
      callsOutDuration: 95314,
      smses: 94894,
    },
    {
      date: "2017-05-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 635341,
      accounts: 52254,
      dataSent: 281242,
      dataReceived: 2316753,
      callsIn: 72022,
      callsInDuration: 118591,
      callsOut: 75691,
      callsOutDuration: 95414,
      smses: 95567,
    },
    {
      date: "2017-05-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 675809,
      accounts: 56424,
      dataSent: 399068,
      dataReceived: 3270366,
      callsIn: 72900,
      callsInDuration: 119554,
      callsOut: 77676,
      callsOutDuration: 97889,
      smses: 100191,
    },
    {
      date: "2017-05-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 633699,
      accounts: 52109,
      dataSent: 331697,
      dataReceived: 2731529,
      callsIn: 71435,
      callsInDuration: 118839,
      callsOut: 75437,
      callsOutDuration: 91426,
      smses: 96866,
    },
    {
      date: "2017-05-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 654999,
      accounts: 52706,
      dataSent: 346875,
      dataReceived: 2777640,
      callsIn: 77309,
      callsInDuration: 115761,
      callsOut: 82690,
      callsOutDuration: 96703,
      smses: 98452,
    },
    {
      date: "2017-05-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 582722,
      accounts: 50743,
      dataSent: 313526,
      dataReceived: 2540898,
      callsIn: 62560,
      callsInDuration: 87566,
      callsOut: 72283,
      callsOutDuration: 73681,
      smses: 80033,
    },
    {
      date: "2017-05-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 525658,
      accounts: 47483,
      dataSent: 269713,
      dataReceived: 2183767,
      callsIn: 42812,
      callsInDuration: 69915,
      callsOut: 50301,
      callsOutDuration: 53911,
      smses: 65493,
    },
    {
      date: "2017-05-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 650517,
      accounts: 53229,
      dataSent: 308814,
      dataReceived: 2550827,
      callsIn: 72724,
      callsInDuration: 117806,
      callsOut: 76393,
      callsOutDuration: 96484,
      smses: 94362,
    },
    {
      date: "2017-05-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1402139,
      accounts: 58660,
      dataSent: 172028,
      dataReceived: 1390143,
      callsIn: 74610,
      callsInDuration: 122359,
      callsOut: 77446,
      callsOutDuration: 95142,
      smses: 98788,
    },
    {
      date: "2017-05-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1233755,
      accounts: 58895,
      dataSent: 386672,
      dataReceived: 3100210,
      callsIn: 79457,
      callsInDuration: 119908,
      callsOut: 83096,
      callsOutDuration: 99229,
      smses: 99556,
    },
    {
      date: "2017-05-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 597857,
      accounts: 48708,
      dataSent: 282791,
      dataReceived: 2390923,
      callsIn: 46640,
      callsInDuration: 78301,
      callsOut: 52957,
      callsOutDuration: 59105,
      smses: 73581,
    },
    {
      date: "2017-05-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 706878,
      accounts: 53720,
      dataSent: 367299,
      dataReceived: 2935681,
      callsIn: 78819,
      callsInDuration: 116733,
      callsOut: 84317,
      callsOutDuration: 97926,
      smses: 100340,
    },
    {
      date: "2017-05-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 612320,
      accounts: 50927,
      dataSent: 318678,
      dataReceived: 2664958,
      callsIn: 61574,
      callsInDuration: 87843,
      callsOut: 69603,
      callsOutDuration: 72478,
      smses: 81368,
    },
    {
      date: "2017-05-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 557809,
      accounts: 47971,
      dataSent: 285170,
      dataReceived: 2341272,
      callsIn: 39628,
      callsInDuration: 65232,
      callsOut: 48259,
      callsOutDuration: 53083,
      smses: 66372,
    },
    {
      date: "2017-05-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 675665,
      accounts: 53439,
      dataSent: 332787,
      dataReceived: 2736857,
      callsIn: 69700,
      callsInDuration: 115448,
      callsOut: 74652,
      callsOutDuration: 96009,
      smses: 91966,
    },
    {
      date: "2017-05-30",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 659871,
      accounts: 53673,
      dataSent: 341402,
      dataReceived: 2861870,
      callsIn: 72188,
      callsInDuration: 122297,
      callsOut: 76651,
      callsOutDuration: 96444,
      smses: 93769,
    },
    {
      date: "2017-05-31",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 669915,
      accounts: 53841,
      dataSent: 277086,
      dataReceived: 2306361,
      callsIn: 72489,
      callsInDuration: 121838,
      callsOut: 78168,
      callsOutDuration: 98500,
      smses: 94848,
    },
    {
      date: "2017-06-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 700772,
      accounts: 58142,
      dataSent: 426266,
      dataReceived: 3634488,
      callsIn: 74381,
      callsInDuration: 123714,
      callsOut: 80708,
      callsOutDuration: 102190,
      smses: 102469,
    },
    {
      date: "2017-06-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 686207,
      accounts: 55100,
      dataSent: 374351,
      dataReceived: 3070320,
      callsIn: 82108,
      callsInDuration: 122777,
      callsOut: 89798,
      callsOutDuration: 105237,
      smses: 102076,
    },
    {
      date: "2017-06-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 610811,
      accounts: 52256,
      dataSent: 330359,
      dataReceived: 2698490,
      callsIn: 67241,
      callsInDuration: 89954,
      callsOut: 77514,
      callsOutDuration: 79807,
      smses: 81621,
    },
    {
      date: "2017-06-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 572973,
      accounts: 49742,
      dataSent: 278407,
      dataReceived: 2341128,
      callsIn: 43560,
      callsInDuration: 69873,
      callsOut: 52370,
      callsOutDuration: 56635,
      smses: 68187,
    },
    {
      date: "2017-06-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 691342,
      accounts: 56833,
      dataSent: 357080,
      dataReceived: 2953581,
      callsIn: 74029,
      callsInDuration: 122174,
      callsOut: 79785,
      callsOutDuration: 100409,
      smses: 99840,
    },
    {
      date: "2017-06-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 661462,
      accounts: 54915,
      dataSent: 346204,
      dataReceived: 3032353,
      callsIn: 76295,
      callsInDuration: 122290,
      callsOut: 82055,
      callsOutDuration: 103608,
      smses: 101313,
    },
    {
      date: "2017-06-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 660686,
      accounts: 55001,
      dataSent: 360791,
      dataReceived: 3115685,
      callsIn: 77602,
      callsInDuration: 127210,
      callsOut: 83070,
      callsOutDuration: 103781,
      smses: 102385,
    },
    {
      date: "2017-06-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 662985,
      accounts: 54462,
      dataSent: 347675,
      dataReceived: 2985310,
      callsIn: 76862,
      callsInDuration: 125791,
      callsOut: 82695,
      callsOutDuration: 102855,
      smses: 104722,
    },
    {
      date: "2017-06-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 654478,
      accounts: 55464,
      dataSent: 365402,
      dataReceived: 3072783,
      callsIn: 86657,
      callsInDuration: 125868,
      callsOut: 95061,
      callsOutDuration: 109080,
      smses: 105753,
    },
    {
      date: "2017-06-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 600509,
      accounts: 53496,
      dataSent: 335402,
      dataReceived: 2725432,
      callsIn: 72457,
      callsInDuration: 96516,
      callsOut: 83824,
      callsOutDuration: 83912,
      smses: 83057,
    },
    {
      date: "2017-06-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 556316,
      accounts: 50744,
      dataSent: 298964,
      dataReceived: 2450254,
      callsIn: 47085,
      callsInDuration: 71160,
      callsOut: 55679,
      callsOutDuration: 57898,
      smses: 67294,
    },
    {
      date: "2017-06-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 677345,
      accounts: 54533,
      dataSent: 336131,
      dataReceived: 2732952,
      callsIn: 77675,
      callsInDuration: 125387,
      callsOut: 81853,
      callsOutDuration: 102471,
      smses: 100383,
    },
    {
      date: "2017-06-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 652602,
      accounts: 54777,
      dataSent: 344410,
      dataReceived: 2957828,
      callsIn: 78060,
      callsInDuration: 126753,
      callsOut: 81875,
      callsOutDuration: 104269,
      smses: 99633,
    },
    {
      date: "2017-06-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 675429,
      accounts: 54996,
      dataSent: 352049,
      dataReceived: 2908352,
      callsIn: 79228,
      callsInDuration: 129515,
      callsOut: 85045,
      callsOutDuration: 102488,
      smses: 100656,
    },
    {
      date: "2017-06-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 681470,
      accounts: 55120,
      dataSent: 350107,
      dataReceived: 2894164,
      callsIn: 81364,
      callsInDuration: 130674,
      callsOut: 86290,
      callsOutDuration: 106725,
      smses: 104569,
    },
    {
      date: "2017-06-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 688797,
      accounts: 56411,
      dataSent: 385840,
      dataReceived: 3173997,
      callsIn: 88969,
      callsInDuration: 130735,
      callsOut: 94518,
      callsOutDuration: 109144,
      smses: 109776,
    },
    {
      date: "2017-06-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 620508,
      accounts: 53556,
      dataSent: 331966,
      dataReceived: 2698395,
      callsIn: 71159,
      callsInDuration: 94271,
      callsOut: 81069,
      callsOutDuration: 80901,
      smses: 86066,
    },
    {
      date: "2017-06-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 588820,
      accounts: 52064,
      dataSent: 282746,
      dataReceived: 2361846,
      callsIn: 48558,
      callsInDuration: 76253,
      callsOut: 59446,
      callsOutDuration: 72063,
      smses: 78000,
    },
    {
      date: "2017-06-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 671818,
      accounts: 57897,
      dataSent: 405910,
      dataReceived: 3254026,
      callsIn: 79061,
      callsInDuration: 122071,
      callsOut: 84978,
      callsOutDuration: 102313,
      smses: 99375,
    },
    {
      date: "2017-06-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 561187,
      accounts: 50173,
      dataSent: 304760,
      dataReceived: 2409152,
      callsIn: 49346,
      callsInDuration: 84399,
      callsOut: 55004,
      callsOutDuration: 61375,
      smses: 68169,
    },
    {
      date: "2017-06-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 665485,
      accounts: 55308,
      dataSent: 334374,
      dataReceived: 2692590,
      callsIn: 77359,
      callsInDuration: 124105,
      callsOut: 80817,
      callsOutDuration: 99316,
      smses: 96884,
    },
    {
      date: "2017-06-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 705922,
      accounts: 59081,
      dataSent: 397156,
      dataReceived: 3243104,
      callsIn: 78635,
      callsInDuration: 129918,
      callsOut: 81548,
      callsOutDuration: 99836,
      smses: 99015,
    },
    {
      date: "2017-06-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 692424,
      accounts: 56551,
      dataSent: 382366,
      dataReceived: 3080263,
      callsIn: 85596,
      callsInDuration: 130003,
      callsOut: 91150,
      callsOutDuration: 105849,
      smses: 100404,
    },
    {
      date: "2017-06-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 635263,
      accounts: 53476,
      dataSent: 328246,
      dataReceived: 2717417,
      callsIn: 68821,
      callsInDuration: 95303,
      callsOut: 77745,
      callsOutDuration: 80072,
      smses: 82797,
    },
    {
      date: "2017-06-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 590737,
      accounts: 51142,
      dataSent: 308771,
      dataReceived: 2499589,
      callsIn: 46505,
      callsInDuration: 74781,
      callsOut: 54941,
      callsOutDuration: 60029,
      smses: 71883,
    },
    {
      date: "2017-06-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 684156,
      accounts: 56088,
      dataSent: 368104,
      dataReceived: 3046474,
      callsIn: 76460,
      callsInDuration: 126260,
      callsOut: 79676,
      callsOutDuration: 101922,
      smses: 99070,
    },
    {
      date: "2017-06-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 659251,
      accounts: 55479,
      dataSent: 361126,
      dataReceived: 2985431,
      callsIn: 73938,
      callsInDuration: 129600,
      callsOut: 75210,
      callsOutDuration: 97995,
      smses: 99040,
    },
    {
      date: "2017-06-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 489258,
      accounts: 56551,
      dataSent: 360400,
      dataReceived: 3020453,
      callsIn: 77639,
      callsInDuration: 132623,
      callsOut: 79381,
      callsOutDuration: 102881,
      smses: 100537,
    },
    {
      date: "2017-06-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 438436,
      accounts: 57114,
      dataSent: 365362,
      dataReceived: 3017791,
      callsIn: 79325,
      callsInDuration: 133552,
      callsOut: 82899,
      callsOutDuration: 103728,
      smses: 100509,
    },
    {
      date: "2017-06-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 440934,
      accounts: 57469,
      dataSent: 310686,
      dataReceived: 2456699,
      callsIn: 87683,
      callsInDuration: 134827,
      callsOut: 93997,
      callsOutDuration: 109938,
      smses: 104632,
    },
    {
      date: "2017-07-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 434908,
      accounts: 59986,
      dataSent: 451047,
      dataReceived: 3660803,
      callsIn: 73351,
      callsInDuration: 99107,
      callsOut: 82060,
      callsOutDuration: 81593,
      smses: 83655,
    },
    {
      date: "2017-07-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 360131,
      accounts: 52007,
      dataSent: 305997,
      dataReceived: 2479699,
      callsIn: 48924,
      callsInDuration: 76571,
      callsOut: 56229,
      callsOutDuration: 59454,
      smses: 70586,
    },
    {
      date: "2017-07-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 425984,
      accounts: 56391,
      dataSent: 352547,
      dataReceived: 2870835,
      callsIn: 78073,
      callsInDuration: 130173,
      callsOut: 82080,
      callsOutDuration: 103250,
      smses: 102480,
    },
    {
      date: "2017-07-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 434706,
      accounts: 56558,
      dataSent: 365633,
      dataReceived: 2928565,
      callsIn: 79987,
      callsInDuration: 134146,
      callsOut: 82520,
      callsOutDuration: 107260,
      smses: 99029,
    },
    {
      date: "2017-07-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 429962,
      accounts: 55890,
      dataSent: 344896,
      dataReceived: 2799210,
      callsIn: 80889,
      callsInDuration: 137525,
      callsOut: 84208,
      callsOutDuration: 106404,
      smses: 106145,
    },
    {
      date: "2017-07-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 442010,
      accounts: 57894,
      dataSent: 388667,
      dataReceived: 3243997,
      callsIn: 81650,
      callsInDuration: 133431,
      callsOut: 84371,
      callsOutDuration: 104675,
      smses: 104604,
    },
    {
      date: "2017-07-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 441261,
      accounts: 57010,
      dataSent: 394686,
      dataReceived: 3157483,
      callsIn: 91790,
      callsInDuration: 136300,
      callsOut: 97714,
      callsOutDuration: 111464,
      smses: 110486,
    },
    {
      date: "2017-07-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 398894,
      accounts: 54795,
      dataSent: 356491,
      dataReceived: 2873918,
      callsIn: 73029,
      callsInDuration: 98282,
      callsOut: 83282,
      callsOutDuration: 82851,
      smses: 85503,
    },
    {
      date: "2017-07-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 357614,
      accounts: 51720,
      dataSent: 320588,
      dataReceived: 2598982,
      callsIn: 49115,
      callsInDuration: 72708,
      callsOut: 58145,
      callsOutDuration: 60651,
      smses: 70956,
    },
    {
      date: "2017-07-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 421355,
      accounts: 56324,
      dataSent: 369904,
      dataReceived: 3009170,
      callsIn: 80403,
      callsInDuration: 131111,
      callsOut: 84738,
      callsOutDuration: 107072,
      smses: 100883,
    },
    {
      date: "2017-07-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 431535,
      accounts: 57035,
      dataSent: 384046,
      dataReceived: 3141839,
      callsIn: 81859,
      callsInDuration: 134220,
      callsOut: 87465,
      callsOutDuration: 109459,
      smses: 97797,
    },
    {
      date: "2017-07-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 430725,
      accounts: 56618,
      dataSent: 364056,
      dataReceived: 3033611,
      callsIn: 84218,
      callsInDuration: 135035,
      callsOut: 88125,
      callsOutDuration: 110637,
      smses: 98707,
    },
    {
      date: "2017-07-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 458057,
      accounts: 57922,
      dataSent: 383748,
      dataReceived: 3157956,
      callsIn: 86306,
      callsInDuration: 135295,
      callsOut: 89126,
      callsOutDuration: 107696,
      smses: 99439,
    },
    {
      date: "2017-07-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 447586,
      accounts: 57822,
      dataSent: 398826,
      dataReceived: 3223790,
      callsIn: 89928,
      callsInDuration: 132982,
      callsOut: 95782,
      callsOutDuration: 112527,
      smses: 102194,
    },
    {
      date: "2017-07-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 403519,
      accounts: 54852,
      dataSent: 358045,
      dataReceived: 2883516,
      callsIn: 72718,
      callsInDuration: 102135,
      callsOut: 82157,
      callsOutDuration: 83492,
      smses: 86188,
    },
    {
      date: "2017-07-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 363313,
      accounts: 52546,
      dataSent: 328335,
      dataReceived: 2618177,
      callsIn: 50828,
      callsInDuration: 77815,
      callsOut: 59244,
      callsOutDuration: 61192,
      smses: 71625,
    },
    {
      date: "2017-07-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 408646,
      accounts: 56782,
      dataSent: 349473,
      dataReceived: 2935851,
      callsIn: 78771,
      callsInDuration: 128699,
      callsOut: 84050,
      callsOutDuration: 106764,
      smses: 92791,
    },
    {
      date: "2017-07-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 664837,
      accounts: 62428,
      dataSent: 356359,
      dataReceived: 2934677,
      callsIn: 79655,
      callsInDuration: 130882,
      callsOut: 82928,
      callsOutDuration: 104218,
      smses: 83366,
    },
    {
      date: "2017-07-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 677975,
      accounts: 59318,
      dataSent: 372020,
      dataReceived: 3060696,
      callsIn: 82167,
      callsInDuration: 131774,
      callsOut: 86491,
      callsOutDuration: 104457,
      smses: 86080,
    },
    {
      date: "2017-07-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 709083,
      accounts: 60274,
      dataSent: 385933,
      dataReceived: 3086805,
      callsIn: 93497,
      callsInDuration: 140312,
      callsOut: 98017,
      callsOutDuration: 111249,
      smses: 96309,
    },
    {
      date: "2017-07-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 707573,
      accounts: 60916,
      dataSent: 377628,
      dataReceived: 3085866,
      callsIn: 90722,
      callsInDuration: 133476,
      callsOut: 93915,
      callsOutDuration: 108226,
      smses: 86933,
    },
    {
      date: "2017-07-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 667943,
      accounts: 60038,
      dataSent: 333635,
      dataReceived: 2677715,
      callsIn: 72963,
      callsInDuration: 95907,
      callsOut: 80053,
      callsOutDuration: 80586,
      smses: 73187,
    },
    {
      date: "2017-07-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 640045,
      accounts: 60858,
      dataSent: 386903,
      dataReceived: 3158089,
      callsIn: 51221,
      callsInDuration: 77115,
      callsOut: 58741,
      callsOutDuration: 60010,
      smses: 64697,
    },
    {
      date: "2017-07-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 656264,
      accounts: 59481,
      dataSent: 292042,
      dataReceived: 2464746,
      callsIn: 76492,
      callsInDuration: 132049,
      callsOut: 78870,
      callsOutDuration: 103399,
      smses: 77986,
    },
    {
      date: "2017-07-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 810318,
      accounts: 64236,
      dataSent: 454737,
      dataReceived: 3757215,
      callsIn: 76274,
      callsInDuration: 132023,
      callsOut: 77537,
      callsOutDuration: 98942,
      smses: 95445,
    },
    {
      date: "2017-07-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 727743,
      accounts: 59845,
      dataSent: 369917,
      dataReceived: 3106127,
      callsIn: 76675,
      callsInDuration: 132120,
      callsOut: 77833,
      callsOutDuration: 101484,
      smses: 95303,
    },
    {
      date: "2017-07-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 742283,
      accounts: 60561,
      dataSent: 349929,
      dataReceived: 2959392,
      callsIn: 78825,
      callsInDuration: 133319,
      callsOut: 82266,
      callsOutDuration: 103538,
      smses: 97642,
    },
    {
      date: "2017-07-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 842154,
      accounts: 65223,
      dataSent: 474007,
      dataReceived: 3912071,
      callsIn: 86354,
      callsInDuration: 131525,
      callsOut: 90949,
      callsOutDuration: 107055,
      smses: 105755,
    },
    {
      date: "2017-07-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 680742,
      accounts: 58870,
      dataSent: 353493,
      dataReceived: 2956106,
      callsIn: 68043,
      callsInDuration: 93854,
      callsOut: 76956,
      callsOutDuration: 79170,
      smses: 89990,
    },
    {
      date: "2017-07-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 670960,
      accounts: 59059,
      dataSent: 286819,
      dataReceived: 2473888,
      callsIn: 43826,
      callsInDuration: 72876,
      callsOut: 50374,
      callsOutDuration: 56058,
      smses: 75685,
    },
    {
      date: "2017-07-31",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 828428,
      accounts: 64787,
      dataSent: 306348,
      dataReceived: 2608958,
      callsIn: 76440,
      callsInDuration: 130793,
      callsOut: 79664,
      callsOutDuration: 105377,
      smses: 102711,
    },
    {
      date: "2017-08-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 830068,
      accounts: 65006,
      dataSent: 504541,
      dataReceived: 4444490,
      callsIn: 79132,
      callsInDuration: 135917,
      callsOut: 80044,
      callsOutDuration: 104625,
      smses: 100807,
    },
    {
      date: "2017-08-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 800928,
      accounts: 62186,
      dataSent: 405314,
      dataReceived: 3428661,
      callsIn: 83437,
      callsInDuration: 139807,
      callsOut: 84282,
      callsOutDuration: 107316,
      smses: 102995,
    },
    {
      date: "2017-08-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 795482,
      accounts: 61511,
      dataSent: 359315,
      dataReceived: 2934994,
      callsIn: 85688,
      callsInDuration: 142566,
      callsOut: 87121,
      callsOutDuration: 108757,
      smses: 101821,
    },
    {
      date: "2017-08-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 864042,
      accounts: 66663,
      dataSent: 521669,
      dataReceived: 4377786,
      callsIn: 94690,
      callsInDuration: 140682,
      callsOut: 99031,
      callsOutDuration: 114382,
      smses: 108085,
    },
    {
      date: "2017-08-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 746089,
      accounts: 60751,
      dataSent: 396861,
      dataReceived: 3311334,
      callsIn: 79188,
      callsInDuration: 105296,
      callsOut: 86316,
      callsOutDuration: 84589,
      smses: 85687,
    },
    {
      date: "2017-08-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 689893,
      accounts: 58548,
      dataSent: 361906,
      dataReceived: 2945066,
      callsIn: 55450,
      callsInDuration: 79668,
      callsOut: 62631,
      callsOutDuration: 62169,
      smses: 76250,
    },
    {
      date: "2017-08-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 808011,
      accounts: 63015,
      dataSent: 394098,
      dataReceived: 3288357,
      callsIn: 85949,
      callsInDuration: 139595,
      callsOut: 89811,
      callsOutDuration: 108556,
      smses: 103366,
    },
    {
      date: "2017-08-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 824126,
      accounts: 63827,
      dataSent: 419779,
      dataReceived: 3464963,
      callsIn: 85826,
      callsInDuration: 139723,
      callsOut: 86024,
      callsOutDuration: 106345,
      smses: 102161,
    },
    {
      date: "2017-08-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1546293,
      accounts: 68291,
      dataSent: 317723,
      dataReceived: 2663361,
      callsIn: 85572,
      callsInDuration: 141117,
      callsOut: 86523,
      callsOutDuration: 107454,
      smses: 99738,
    },
    {
      date: "2017-08-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 908002,
      accounts: 68052,
      dataSent: 452973,
      dataReceived: 3785765,
      callsIn: 81235,
      callsInDuration: 134629,
      callsOut: 84109,
      callsOutDuration: 104529,
      smses: 102335,
    },
    {
      date: "2017-08-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 857078,
      accounts: 64693,
      dataSent: 471872,
      dataReceived: 4009360,
      callsIn: 92434,
      callsInDuration: 142189,
      callsOut: 96284,
      callsOutDuration: 112934,
      smses: 107744,
    },
    {
      date: "2017-08-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 758994,
      accounts: 61542,
      dataSent: 409282,
      dataReceived: 3417780,
      callsIn: 75799,
      callsInDuration: 104132,
      callsOut: 82456,
      callsOutDuration: 85326,
      smses: 88646,
    },
    {
      date: "2017-08-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 743497,
      accounts: 61453,
      dataSent: 374047,
      dataReceived: 3114458,
      callsIn: 63399,
      callsInDuration: 86007,
      callsOut: 70196,
      callsOutDuration: 66802,
      smses: 83387,
    },
    {
      date: "2017-08-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 823149,
      accounts: 64928,
      dataSent: 417009,
      dataReceived: 3406252,
      callsIn: 82329,
      callsInDuration: 137225,
      callsOut: 85695,
      callsOutDuration: 108592,
      smses: 97544,
    },
    {
      date: "2017-08-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 856880,
      accounts: 66326,
      dataSent: 462027,
      dataReceived: 3807932,
      callsIn: 86519,
      callsInDuration: 141487,
      callsOut: 89671,
      callsOutDuration: 111972,
      smses: 99659,
    },
    {
      date: "2017-08-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 871887,
      accounts: 66804,
      dataSent: 389053,
      dataReceived: 3216669,
      callsIn: 88116,
      callsInDuration: 145442,
      callsOut: 90507,
      callsOutDuration: 114466,
      smses: 103330,
    },
    {
      date: "2017-08-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 964819,
      accounts: 70493,
      dataSent: 554681,
      dataReceived: 4537686,
      callsIn: 91371,
      callsInDuration: 142967,
      callsOut: 92839,
      callsOutDuration: 113934,
      smses: 101488,
    },
    {
      date: "2017-08-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 894327,
      accounts: 67541,
      dataSent: 503490,
      dataReceived: 4142852,
      callsIn: 102796,
      callsInDuration: 147321,
      callsOut: 105791,
      callsOutDuration: 120409,
      smses: 108828,
    },
    {
      date: "2017-08-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 772187,
      accounts: 63069,
      dataSent: 438964,
      dataReceived: 3499898,
      callsIn: 81792,
      callsInDuration: 107589,
      callsOut: 90799,
      callsOutDuration: 89658,
      smses: 91389,
    },
    {
      date: "2017-08-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 733168,
      accounts: 61132,
      dataSent: 391044,
      dataReceived: 3057994,
      callsIn: 61633,
      callsInDuration: 82936,
      callsOut: 69921,
      callsOutDuration: 67832,
      smses: 76970,
    },
    {
      date: "2017-08-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 775343,
      accounts: 65044,
      dataSent: 319824,
      dataReceived: 2575056,
      callsIn: 56221,
      callsInDuration: 83878,
      callsOut: 59932,
      callsOutDuration: 64470,
      smses: 74804,
    },
    {
      date: "2017-08-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 921923,
      accounts: 68639,
      dataSent: 444023,
      dataReceived: 3597983,
      callsIn: 88179,
      callsInDuration: 137487,
      callsOut: 88716,
      callsOutDuration: 111404,
      smses: 106656,
    },
    {
      date: "2017-08-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 855267,
      accounts: 66544,
      dataSent: 319864,
      dataReceived: 2639429,
      callsIn: 88001,
      callsInDuration: 142054,
      callsOut: 87349,
      callsOutDuration: 109716,
      smses: 101983,
    },
    {
      date: "2017-08-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 897646,
      accounts: 70874,
      dataSent: 489551,
      dataReceived: 4118042,
      callsIn: 88610,
      callsInDuration: 147124,
      callsOut: 88588,
      callsOutDuration: 110703,
      smses: 101960,
    },
    {
      date: "2017-08-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 893663,
      accounts: 67074,
      dataSent: 478358,
      dataReceived: 3921218,
      callsIn: 95714,
      callsInDuration: 147406,
      callsOut: 97298,
      callsOutDuration: 116130,
      smses: 104534,
    },
    {
      date: "2017-08-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 781344,
      accounts: 64695,
      dataSent: 442425,
      dataReceived: 3632000,
      callsIn: 78060,
      callsInDuration: 110924,
      callsOut: 85708,
      callsOutDuration: 87356,
      smses: 83054,
    },
    {
      date: "2017-08-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 722174,
      accounts: 62236,
      dataSent: 404384,
      dataReceived: 3297439,
      callsIn: 51688,
      callsInDuration: 83445,
      callsOut: 58429,
      callsOutDuration: 62561,
      smses: 72285,
    },
    {
      date: "2017-08-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 884405,
      accounts: 68294,
      dataSent: 468042,
      dataReceived: 3841099,
      callsIn: 85968,
      callsInDuration: 143489,
      callsOut: 86829,
      callsOutDuration: 110344,
      smses: 99023,
    },
    {
      date: "2017-08-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 890564,
      accounts: 68038,
      dataSent: 413562,
      dataReceived: 3411473,
      callsIn: 85191,
      callsInDuration: 142321,
      callsOut: 84427,
      callsOutDuration: 110808,
      smses: 99200,
    },
    {
      date: "2017-08-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 958782,
      accounts: 72958,
      dataSent: 548545,
      dataReceived: 4601440,
      callsIn: 87924,
      callsInDuration: 144695,
      callsOut: 87317,
      callsOutDuration: 112018,
      smses: 98832,
    },
    {
      date: "2017-08-31",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 887157,
      accounts: 68708,
      dataSent: 377199,
      dataReceived: 3091000,
      callsIn: 90038,
      callsInDuration: 142963,
      callsOut: 90457,
      callsOutDuration: 112371,
      smses: 102298,
    },
    {
      date: "2017-09-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 988296,
      accounts: 73312,
      dataSent: 571815,
      dataReceived: 4760602,
      callsIn: 101498,
      callsInDuration: 146974,
      callsOut: 102928,
      callsOutDuration: 118092,
      smses: 103805,
    },
    {
      date: "2017-09-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 829181,
      accounts: 67834,
      dataSent: 341474,
      dataReceived: 2788729,
      callsIn: 80325,
      callsInDuration: 107944,
      callsOut: 85226,
      callsOutDuration: 85904,
      smses: 87182,
    },
    {
      date: "2017-09-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 726047,
      accounts: 68028,
      dataSent: 419593,
      dataReceived: 3516792,
      callsIn: 49517,
      callsInDuration: 79354,
      callsOut: 54995,
      callsOutDuration: 58929,
      smses: 67128,
    },
    {
      date: "2017-09-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 847535,
      accounts: 65947,
      dataSent: 392404,
      dataReceived: 3121679,
      callsIn: 87859,
      callsInDuration: 144761,
      callsOut: 85534,
      callsOutDuration: 111824,
      smses: 95475,
    },
    {
      date: "2017-09-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 821376,
      accounts: 71363,
      dataSent: 376524,
      dataReceived: 3104041,
      callsIn: 88854,
      callsInDuration: 141384,
      callsOut: 85776,
      callsOutDuration: 107416,
      smses: 97567,
    },
    {
      date: "2017-09-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 913248,
      accounts: 71009,
      dataSent: 475763,
      dataReceived: 3882417,
      callsIn: 94393,
      callsInDuration: 153114,
      callsOut: 91495,
      callsOutDuration: 117246,
      smses: 96709,
    },
    {
      date: "2017-09-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 928196,
      accounts: 71234,
      dataSent: 501995,
      dataReceived: 4084296,
      callsIn: 93323,
      callsInDuration: 150865,
      callsOut: 93522,
      callsOutDuration: 117273,
      smses: 97768,
    },
    {
      date: "2017-09-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 929269,
      accounts: 71385,
      dataSent: 524639,
      dataReceived: 4248657,
      callsIn: 101960,
      callsInDuration: 149673,
      callsOut: 101316,
      callsOutDuration: 117121,
      smses: 100617,
    },
    {
      date: "2017-09-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 786037,
      accounts: 66426,
      dataSent: 449980,
      dataReceived: 3679422,
      callsIn: 78903,
      callsInDuration: 109652,
      callsOut: 84000,
      callsOutDuration: 86150,
      smses: 82588,
    },
    {
      date: "2017-09-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 685766,
      accounts: 61837,
      dataSent: 386917,
      dataReceived: 3260658,
      callsIn: 51661,
      callsInDuration: 85160,
      callsOut: 58405,
      callsOutDuration: 62811,
      smses: 72580,
    },
    {
      date: "2017-09-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 829850,
      accounts: 68017,
      dataSent: 427421,
      dataReceived: 3329957,
      callsIn: 88454,
      callsInDuration: 140697,
      callsOut: 88453,
      callsOutDuration: 113270,
      smses: 92238,
    },
    {
      date: "2017-09-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 879258,
      accounts: 70720,
      dataSent: 485916,
      dataReceived: 3831806,
      callsIn: 93588,
      callsInDuration: 151468,
      callsOut: 92406,
      callsOutDuration: 116826,
      smses: 95860,
    },
    {
      date: "2017-09-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 910267,
      accounts: 71524,
      dataSent: 502681,
      dataReceived: 3995789,
      callsIn: 94003,
      callsInDuration: 149939,
      callsOut: 91327,
      callsOutDuration: 113691,
      smses: 94469,
    },
    {
      date: "2017-09-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 879892,
      accounts: 70832,
      dataSent: 478723,
      dataReceived: 3841832,
      callsIn: 95910,
      callsInDuration: 152732,
      callsOut: 94122,
      callsOutDuration: 115775,
      smses: 97051,
    },
    {
      date: "2017-09-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 902949,
      accounts: 71697,
      dataSent: 541170,
      dataReceived: 4307659,
      callsIn: 100256,
      callsInDuration: 151441,
      callsOut: 99481,
      callsOutDuration: 118423,
      smses: 98714,
    },
    {
      date: "2017-09-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 782522,
      accounts: 67857,
      dataSent: 471847,
      dataReceived: 3690697,
      callsIn: 84647,
      callsInDuration: 113773,
      callsOut: 89519,
      callsOutDuration: 90175,
      smses: 78896,
    },
    {
      date: "2017-09-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 696783,
      accounts: 64690,
      dataSent: 418893,
      dataReceived: 3294323,
      callsIn: 59077,
      callsInDuration: 86620,
      callsOut: 64775,
      callsOutDuration: 64285,
      smses: 72233,
    },
    {
      date: "2017-09-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 859800,
      accounts: 70228,
      dataSent: 435990,
      dataReceived: 3415816,
      callsIn: 93121,
      callsInDuration: 148313,
      callsOut: 90396,
      callsOutDuration: 115041,
      smses: 93124,
    },
    {
      date: "2017-09-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 988872,
      accounts: 73916,
      dataSent: 527983,
      dataReceived: 4192112,
      callsIn: 92761,
      callsInDuration: 148606,
      callsOut: 90415,
      callsOutDuration: 113609,
      smses: 95246,
    },
    {
      date: "2017-09-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 831613,
      accounts: 70631,
      dataSent: 472457,
      dataReceived: 3697361,
      callsIn: 94305,
      callsInDuration: 149467,
      callsOut: 91759,
      callsOutDuration: 113057,
      smses: 95208,
    },
    {
      date: "2017-09-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 795066,
      accounts: 69315,
      dataSent: 500711,
      dataReceived: 4007406,
      callsIn: 89651,
      callsInDuration: 146670,
      callsOut: 85072,
      callsOutDuration: 105279,
      smses: 93994,
    },
    {
      date: "2017-09-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 788702,
      accounts: 70517,
      dataSent: 513369,
      dataReceived: 4008742,
      callsIn: 99020,
      callsInDuration: 149505,
      callsOut: 97232,
      callsOutDuration: 114327,
      smses: 94622,
    },
    {
      date: "2017-09-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 717797,
      accounts: 67247,
      dataSent: 451188,
      dataReceived: 3528052,
      callsIn: 81459,
      callsInDuration: 109547,
      callsOut: 86423,
      callsOutDuration: 87339,
      smses: 90195,
    },
    {
      date: "2017-09-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 652730,
      accounts: 63642,
      dataSent: 388659,
      dataReceived: 2950394,
      callsIn: 57473,
      callsInDuration: 85827,
      callsOut: 63316,
      callsOutDuration: 63065,
      smses: 74412,
    },
    {
      date: "2017-09-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 746711,
      accounts: 69646,
      dataSent: 388054,
      dataReceived: 3112995,
      callsIn: 76276,
      callsInDuration: 133324,
      callsOut: 77240,
      callsOutDuration: 99753,
      smses: 96766,
    },
    {
      date: "2017-09-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 767694,
      accounts: 72869,
      dataSent: 458411,
      dataReceived: 3831896,
      callsIn: 81252,
      callsInDuration: 137763,
      callsOut: 78899,
      callsOutDuration: 101870,
      smses: 102319,
    },
    {
      date: "2017-09-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 795529,
      accounts: 68875,
      dataSent: 470912,
      dataReceived: 3826477,
      callsIn: 90675,
      callsInDuration: 151982,
      callsOut: 86209,
      callsOutDuration: 110961,
      smses: 97082,
    },
    {
      date: "2017-09-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 826188,
      accounts: 70364,
      dataSent: 500491,
      dataReceived: 4058399,
      callsIn: 93024,
      callsInDuration: 151416,
      callsOut: 88237,
      callsOutDuration: 112956,
      smses: 96172,
    },
    {
      date: "2017-09-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1283815,
      accounts: 75598,
      dataSent: 447499,
      dataReceived: 3561724,
      callsIn: 103709,
      callsInDuration: 154651,
      callsOut: 100280,
      callsOutDuration: 117624,
      smses: 99806,
    },
    {
      date: "2017-09-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 800043,
      accounts: 71074,
      dataSent: 403725,
      dataReceived: 3147611,
      callsIn: 84392,
      callsInDuration: 113036,
      callsOut: 88701,
      callsOutDuration: 88327,
      smses: 81799,
    },
    {
      date: "2017-10-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 722859,
      accounts: 67400,
      dataSent: 468170,
      dataReceived: 3940555,
      callsIn: 54186,
      callsInDuration: 85757,
      callsOut: 60671,
      callsOutDuration: 66275,
      smses: 75895,
    },
    {
      date: "2017-10-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 762659,
      accounts: 63928,
      dataSent: 335193,
      dataReceived: 2666766,
      callsIn: 92361,
      callsInDuration: 152628,
      callsOut: 91334,
      callsOutDuration: 118049,
      smses: 96790,
    },
    {
      date: "2017-10-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 894357,
      accounts: 75277,
      dataSent: 505840,
      dataReceived: 4099149,
      callsIn: 94023,
      callsInDuration: 152879,
      callsOut: 92836,
      callsOutDuration: 119001,
      smses: 99211,
    },
    {
      date: "2017-10-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 891300,
      accounts: 73357,
      dataSent: 584186,
      dataReceived: 4623874,
      callsIn: 97974,
      callsInDuration: 159146,
      callsOut: 95049,
      callsOutDuration: 119428,
      smses: 97902,
    },
    {
      date: "2017-10-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 864390,
      accounts: 70503,
      dataSent: 494067,
      dataReceived: 4005262,
      callsIn: 99329,
      callsInDuration: 151365,
      callsOut: 97398,
      callsOutDuration: 117450,
      smses: 99408,
    },
    {
      date: "2017-10-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 775500,
      accounts: 71359,
      dataSent: 494583,
      dataReceived: 3964330,
      callsIn: 91074,
      callsInDuration: 133852,
      callsOut: 90700,
      callsOutDuration: 104285,
      smses: 87664,
    },
    {
      date: "2017-10-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 786178,
      accounts: 68965,
      dataSent: 476525,
      dataReceived: 3754471,
      callsIn: 91780,
      callsInDuration: 119438,
      callsOut: 97151,
      callsOutDuration: 95402,
      smses: 87119,
    },
    {
      date: "2017-10-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 733774,
      accounts: 67834,
      dataSent: 471964,
      dataReceived: 3824246,
      callsIn: 60348,
      callsInDuration: 89996,
      callsOut: 67195,
      callsOutDuration: 69127,
      smses: 74510,
    },
    {
      date: "2017-10-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 932879,
      accounts: 74167,
      dataSent: 532559,
      dataReceived: 4591999,
      callsIn: 97510,
      callsInDuration: 157826,
      callsOut: 95834,
      callsOutDuration: 121086,
      smses: 102642,
    },
    {
      date: "2017-10-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 873729,
      accounts: 72320,
      dataSent: 521444,
      dataReceived: 4430062,
      callsIn: 97556,
      callsInDuration: 152093,
      callsOut: 95456,
      callsOutDuration: 116763,
      smses: 99500,
    },
    {
      date: "2017-10-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 878236,
      accounts: 72968,
      dataSent: 529311,
      dataReceived: 4380002,
      callsIn: 98522,
      callsInDuration: 160890,
      callsOut: 95427,
      callsOutDuration: 120540,
      smses: 99582,
    },
    {
      date: "2017-10-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 865217,
      accounts: 72443,
      dataSent: 540098,
      dataReceived: 4473688,
      callsIn: 96725,
      callsInDuration: 160040,
      callsOut: 93742,
      callsOutDuration: 119113,
      smses: 102256,
    },
    {
      date: "2017-10-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 917665,
      accounts: 74276,
      dataSent: 549170,
      dataReceived: 4446909,
      callsIn: 113927,
      callsInDuration: 161368,
      callsOut: 112108,
      callsOutDuration: 126986,
      smses: 106956,
    },
    {
      date: "2017-10-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 861568,
      accounts: 71979,
      dataSent: 516339,
      dataReceived: 4069051,
      callsIn: 98654,
      callsInDuration: 119651,
      callsOut: 106133,
      callsOutDuration: 102772,
      smses: 90750,
    },
    {
      date: "2017-10-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 834044,
      accounts: 70688,
      dataSent: 539260,
      dataReceived: 4333295,
      callsIn: 70379,
      callsInDuration: 96131,
      callsOut: 82478,
      callsOutDuration: 92783,
      smses: 84028,
    },
    {
      date: "2017-10-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 745932,
      accounts: 67283,
      dataSent: 494691,
      dataReceived: 4067253,
      callsIn: 63570,
      callsInDuration: 97164,
      callsOut: 67623,
      callsOutDuration: 72122,
      smses: 78429,
    },
    {
      date: "2017-10-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 820296,
      accounts: 71907,
      dataSent: 478202,
      dataReceived: 3886411,
      callsIn: 91709,
      callsInDuration: 148764,
      callsOut: 89925,
      callsOutDuration: 113457,
      smses: 100745,
    },
    {
      date: "2017-10-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 862492,
      accounts: 74055,
      dataSent: 530595,
      dataReceived: 4273651,
      callsIn: 97052,
      callsInDuration: 149978,
      callsOut: 94070,
      callsOutDuration: 115296,
      smses: 108439,
    },
    {
      date: "2017-10-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 826145,
      accounts: 73633,
      dataSent: 488827,
      dataReceived: 3988778,
      callsIn: 94700,
      callsInDuration: 154858,
      callsOut: 92089,
      callsOutDuration: 115367,
      smses: 103988,
    },
    {
      date: "2017-10-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 833693,
      accounts: 74964,
      dataSent: 530785,
      dataReceived: 4272523,
      callsIn: 98959,
      callsInDuration: 148745,
      callsOut: 99573,
      callsOutDuration: 116792,
      smses: 106091,
    },
    {
      date: "2017-10-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 760779,
      accounts: 71106,
      dataSent: 467547,
      dataReceived: 3687547,
      callsIn: 80158,
      callsInDuration: 112056,
      callsOut: 84076,
      callsOutDuration: 86621,
      smses: 88337,
    },
    {
      date: "2017-10-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 714327,
      accounts: 69389,
      dataSent: 417685,
      dataReceived: 3412358,
      callsIn: 61043,
      callsInDuration: 89716,
      callsOut: 64967,
      callsOutDuration: 66514,
      smses: 77329,
    },
    {
      date: "2017-10-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 684638,
      accounts: 68501,
      dataSent: 331682,
      dataReceived: 2671443,
      callsIn: 77532,
      callsInDuration: 137350,
      callsOut: 78122,
      callsOutDuration: 105471,
      smses: 94929,
    },
    {
      date: "2017-10-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 864198,
      accounts: 74934,
      dataSent: 287129,
      dataReceived: 2345792,
      callsIn: 89631,
      callsInDuration: 143436,
      callsOut: 86745,
      callsOutDuration: 108017,
      smses: 98956,
    },
    {
      date: "2017-10-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 980410,
      accounts: 78693,
      dataSent: 618617,
      dataReceived: 5095128,
      callsIn: 97816,
      callsInDuration: 157329,
      callsOut: 93461,
      callsOutDuration: 117680,
      smses: 98750,
    },
    {
      date: "2017-10-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 953971,
      accounts: 76461,
      dataSent: 605788,
      dataReceived: 4908764,
      callsIn: 100328,
      callsInDuration: 160709,
      callsOut: 96012,
      callsOutDuration: 118518,
      smses: 99903,
    },
    {
      date: "2017-10-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 918068,
      accounts: 75123,
      dataSent: 574109,
      dataReceived: 4677470,
      callsIn: 107727,
      callsInDuration: 155104,
      callsOut: 105112,
      callsOutDuration: 122965,
      smses: 101629,
    },
    {
      date: "2017-10-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 829763,
      accounts: 73192,
      dataSent: 552785,
      dataReceived: 4473853,
      callsIn: 90622,
      callsInDuration: 119640,
      callsOut: 93689,
      callsOutDuration: 95130,
      smses: 88581,
    },
    {
      date: "2017-10-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 713087,
      accounts: 68007,
      dataSent: 454920,
      dataReceived: 3639973,
      callsIn: 60905,
      callsInDuration: 92397,
      callsOut: 66005,
      callsOutDuration: 68508,
      smses: 85124,
    },
    {
      date: "2017-10-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 905360,
      accounts: 76016,
      dataSent: 519761,
      dataReceived: 4124069,
      callsIn: 98416,
      callsInDuration: 162131,
      callsOut: 95576,
      callsOutDuration: 124670,
      smses: 97141,
    },
    {
      date: "2017-10-31",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1010847,
      accounts: 79057,
      dataSent: 514690,
      dataReceived: 4104293,
      callsIn: 102331,
      callsInDuration: 162901,
      callsOut: 102785,
      callsOutDuration: 132217,
      smses: 100872,
    },
    {
      date: "2017-11-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1071011,
      accounts: 80111,
      dataSent: 650255,
      dataReceived: 5301721,
      callsIn: 103045,
      callsInDuration: 163635,
      callsOut: 103105,
      callsOutDuration: 134953,
      smses: 100355,
    },
    {
      date: "2017-11-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 910241,
      accounts: 76412,
      dataSent: 574236,
      dataReceived: 4711582,
      callsIn: 101614,
      callsInDuration: 161440,
      callsOut: 106674,
      callsOutDuration: 143943,
      smses: 100659,
    },
    {
      date: "2017-11-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 950065,
      accounts: 77693,
      dataSent: 613869,
      dataReceived: 4957155,
      callsIn: 119502,
      callsInDuration: 170156,
      callsOut: 125722,
      callsOutDuration: 154189,
      smses: 111363,
    },
    {
      date: "2017-11-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 840919,
      accounts: 74825,
      dataSent: 603269,
      dataReceived: 4716615,
      callsIn: 96016,
      callsInDuration: 123175,
      callsOut: 107725,
      callsOutDuration: 114830,
      smses: 88903,
    },
    {
      date: "2017-11-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 779373,
      accounts: 72583,
      dataSent: 523392,
      dataReceived: 4288121,
      callsIn: 65986,
      callsInDuration: 94221,
      callsOut: 77495,
      callsOutDuration: 85661,
      smses: 77345,
    },
    {
      date: "2017-11-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 929955,
      accounts: 77554,
      dataSent: 520661,
      dataReceived: 4453053,
      callsIn: 101347,
      callsInDuration: 163723,
      callsOut: 104075,
      callsOutDuration: 137763,
      smses: 100657,
    },
    {
      date: "2017-11-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1204732,
      accounts: 81450,
      dataSent: 599354,
      dataReceived: 4958286,
      callsIn: 103169,
      callsInDuration: 164046,
      callsOut: 110309,
      callsOutDuration: 148274,
      smses: 102921,
    },
    {
      date: "2017-11-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 972307,
      accounts: 80420,
      dataSent: 671180,
      dataReceived: 5556310,
      callsIn: 108053,
      callsInDuration: 168289,
      callsOut: 112945,
      callsOutDuration: 151243,
      smses: 101675,
    },
    {
      date: "2017-11-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 947529,
      accounts: 78640,
      dataSent: 588458,
      dataReceived: 4837826,
      callsIn: 111216,
      callsInDuration: 173479,
      callsOut: 114001,
      callsOutDuration: 149106,
      smses: 101881,
    },
    {
      date: "2017-11-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 965158,
      accounts: 79505,
      dataSent: 607672,
      dataReceived: 4846254,
      callsIn: 122922,
      callsInDuration: 170486,
      callsOut: 128119,
      callsOutDuration: 151109,
      smses: 108328,
    },
    {
      date: "2017-11-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1851797,
      accounts: 81903,
      dataSent: 607740,
      dataReceived: 4821170,
      callsIn: 97210,
      callsInDuration: 122084,
      callsOut: 107801,
      callsOutDuration: 107682,
      smses: 91159,
    },
    {
      date: "2017-11-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 775721,
      accounts: 72992,
      dataSent: 540778,
      dataReceived: 4451447,
      callsIn: 66253,
      callsInDuration: 95342,
      callsOut: 77228,
      callsOutDuration: 82175,
      smses: 78450,
    },
    {
      date: "2017-11-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 896885,
      accounts: 77164,
      dataSent: 535144,
      dataReceived: 4438894,
      callsIn: 104715,
      callsInDuration: 163831,
      callsOut: 108031,
      callsOutDuration: 141948,
      smses: 102792,
    },
    {
      date: "2017-11-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1047234,
      accounts: 81169,
      dataSent: 609578,
      dataReceived: 5158874,
      callsIn: 108822,
      callsInDuration: 164335,
      callsOut: 114171,
      callsOutDuration: 147374,
      smses: 104371,
    },
    {
      date: "2017-11-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1015238,
      accounts: 81380,
      dataSent: 671341,
      dataReceived: 5589912,
      callsIn: 112295,
      callsInDuration: 174762,
      callsOut: 115325,
      callsOutDuration: 150270,
      smses: 108877,
    },
    {
      date: "2017-11-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 974478,
      accounts: 80195,
      dataSent: 656888,
      dataReceived: 5467582,
      callsIn: 112222,
      callsInDuration: 176125,
      callsOut: 117469,
      callsOutDuration: 155079,
      smses: 104327,
    },
    {
      date: "2017-11-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 973849,
      accounts: 80416,
      dataSent: 677890,
      dataReceived: 5595391,
      callsIn: 123318,
      callsInDuration: 175734,
      callsOut: 130448,
      callsOutDuration: 155831,
      smses: 111253,
    },
    {
      date: "2017-11-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 837098,
      accounts: 76051,
      dataSent: 608613,
      dataReceived: 4901608,
      callsIn: 97586,
      callsInDuration: 125928,
      callsOut: 109678,
      callsOutDuration: 117106,
      smses: 90821,
    },
    {
      date: "2017-11-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 805948,
      accounts: 74551,
      dataSent: 609780,
      dataReceived: 4912444,
      callsIn: 70539,
      callsInDuration: 99434,
      callsOut: 82641,
      callsOutDuration: 86702,
      smses: 80355,
    },
    {
      date: "2017-11-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 729014,
      accounts: 70359,
      dataSent: 440883,
      dataReceived: 3733122,
      callsIn: 62642,
      callsInDuration: 103204,
      callsOut: 69516,
      callsOutDuration: 84975,
      smses: 81002,
    },
    {
      date: "2017-11-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 990427,
      accounts: 83720,
      dataSent: 594287,
      dataReceived: 4971674,
      callsIn: 94617,
      callsInDuration: 150103,
      callsOut: 99455,
      callsOutDuration: 128905,
      smses: 92840,
    },
    {
      date: "2017-11-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1015224,
      accounts: 82267,
      dataSent: 584385,
      dataReceived: 4749476,
      callsIn: 111289,
      callsInDuration: 173870,
      callsOut: 114685,
      callsOutDuration: 152943,
      smses: 101531,
    },
    {
      date: "2017-11-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1026350,
      accounts: 83949,
      dataSent: 752597,
      dataReceived: 6169009,
      callsIn: 111195,
      callsInDuration: 173641,
      callsOut: 114636,
      callsOutDuration: 147965,
      smses: 101222,
    },
    {
      date: "2017-11-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 946516,
      accounts: 80715,
      dataSent: 684058,
      dataReceived: 5474371,
      callsIn: 121438,
      callsInDuration: 172634,
      callsOut: 125769,
      callsOutDuration: 153053,
      smses: 105116,
    },
    {
      date: "2017-11-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 833600,
      accounts: 76813,
      dataSent: 609927,
      dataReceived: 4864135,
      callsIn: 97719,
      callsInDuration: 128618,
      callsOut: 107539,
      callsOutDuration: 116101,
      smses: 88802,
    },
    {
      date: "2017-11-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 783094,
      accounts: 75042,
      dataSent: 588774,
      dataReceived: 4818466,
      callsIn: 67877,
      callsInDuration: 100138,
      callsOut: 78092,
      callsOutDuration: 85444,
      smses: 77686,
    },
    {
      date: "2017-11-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 898410,
      accounts: 79853,
      dataSent: 601788,
      dataReceived: 4798138,
      callsIn: 105576,
      callsInDuration: 170281,
      callsOut: 108303,
      callsOutDuration: 147055,
      smses: 100737,
    },
    {
      date: "2017-11-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1030705,
      accounts: 83167,
      dataSent: 557945,
      dataReceived: 4530793,
      callsIn: 107987,
      callsInDuration: 177765,
      callsOut: 106218,
      callsOutDuration: 141806,
      smses: 100789,
    },
    {
      date: "2017-11-29",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1004184,
      accounts: 82105,
      dataSent: 504571,
      dataReceived: 4042980,
      callsIn: 107909,
      callsInDuration: 172353,
      callsOut: 105101,
      callsOutDuration: 138186,
      smses: 100468,
    },
    {
      date: "2017-11-30",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1127216,
      accounts: 85423,
      dataSent: 513376,
      dataReceived: 4099955,
      callsIn: 119243,
      callsInDuration: 179732,
      callsOut: 117026,
      callsOutDuration: 145942,
      smses: 116679,
    },
    {
      date: "2017-12-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1057885,
      accounts: 85379,
      dataSent: 827708,
      dataReceived: 6834190,
      callsIn: 125452,
      callsInDuration: 178924,
      callsOut: 125945,
      callsOutDuration: 149230,
      smses: 108449,
    },
    {
      date: "2017-12-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 889464,
      accounts: 79269,
      dataSent: 660623,
      dataReceived: 5425863,
      callsIn: 97634,
      callsInDuration: 127835,
      callsOut: 104502,
      callsOutDuration: 107818,
      smses: 90936,
    },
    {
      date: "2017-12-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 778175,
      accounts: 74636,
      dataSent: 546394,
      dataReceived: 4696049,
      callsIn: 63881,
      callsInDuration: 98884,
      callsOut: 72081,
      callsOutDuration: 78939,
      smses: 79869,
    },
    {
      date: "2017-12-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 959259,
      accounts: 81495,
      dataSent: 646110,
      dataReceived: 5427044,
      callsIn: 113820,
      callsInDuration: 179383,
      callsOut: 111264,
      callsOutDuration: 145596,
      smses: 102541,
    },
    {
      date: "2017-12-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 942538,
      accounts: 81275,
      dataSent: 624662,
      dataReceived: 5213874,
      callsIn: 117281,
      callsInDuration: 179790,
      callsOut: 116031,
      callsOutDuration: 150040,
      smses: 107766,
    },
    {
      date: "2017-12-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1034503,
      accounts: 83165,
      dataSent: 662456,
      dataReceived: 5570749,
      callsIn: 123441,
      callsInDuration: 184838,
      callsOut: 123113,
      callsOutDuration: 152430,
      smses: 106812,
    },
    {
      date: "2017-12-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1093545,
      accounts: 85161,
      dataSent: 715698,
      dataReceived: 5969695,
      callsIn: 140412,
      callsInDuration: 190288,
      callsOut: 139502,
      callsOutDuration: 158703,
      smses: 110788,
    },
    {
      date: "2017-12-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 899028,
      accounts: 80069,
      dataSent: 646775,
      dataReceived: 5416800,
      callsIn: 91717,
      callsInDuration: 127910,
      callsOut: 99182,
      callsOutDuration: 106236,
      smses: 89916,
    },
    {
      date: "2017-12-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 903408,
      accounts: 79149,
      dataSent: 561328,
      dataReceived: 4689894,
      callsIn: 100701,
      callsInDuration: 125578,
      callsOut: 107294,
      callsOutDuration: 107824,
      smses: 93897,
    },
    {
      date: "2017-12-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 908842,
      accounts: 81192,
      dataSent: 634035,
      dataReceived: 5345610,
      callsIn: 70558,
      callsInDuration: 98923,
      callsOut: 78329,
      callsOutDuration: 80058,
      smses: 82731,
    },
    {
      date: "2017-12-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1045088,
      accounts: 85438,
      dataSent: 649089,
      dataReceived: 5372430,
      callsIn: 122145,
      callsInDuration: 190326,
      callsOut: 119607,
      callsOutDuration: 153572,
      smses: 104760,
    },
    {
      date: "2017-12-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1104970,
      accounts: 85818,
      dataSent: 714342,
      dataReceived: 5835292,
      callsIn: 119932,
      callsInDuration: 185592,
      callsOut: 119270,
      callsOutDuration: 147541,
      smses: 104684,
    },
    {
      date: "2017-12-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1004968,
      accounts: 83098,
      dataSent: 694117,
      dataReceived: 5744698,
      callsIn: 121143,
      callsInDuration: 189652,
      callsOut: 118970,
      callsOutDuration: 149962,
      smses: 108299,
    },
    {
      date: "2017-12-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1002426,
      accounts: 82852,
      dataSent: 688548,
      dataReceived: 5620921,
      callsIn: 126312,
      callsInDuration: 192774,
      callsOut: 124788,
      callsOutDuration: 149813,
      smses: 105851,
    },
    {
      date: "2017-12-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 993328,
      accounts: 82931,
      dataSent: 702239,
      dataReceived: 5611128,
      callsIn: 133172,
      callsInDuration: 185659,
      callsOut: 134214,
      callsOutDuration: 152627,
      smses: 104455,
    },
    {
      date: "2017-12-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 969190,
      accounts: 82531,
      dataSent: 683275,
      dataReceived: 5517402,
      callsIn: 111075,
      callsInDuration: 143790,
      callsOut: 119194,
      callsOutDuration: 117496,
      smses: 97376,
    },
    {
      date: "2017-12-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 917772,
      accounts: 80073,
      dataSent: 655203,
      dataReceived: 5388908,
      callsIn: 77252,
      callsInDuration: 110978,
      callsOut: 86280,
      callsOutDuration: 89701,
      smses: 84786,
    },
    {
      date: "2017-12-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 969621,
      accounts: 82426,
      dataSent: 703375,
      dataReceived: 5767107,
      callsIn: 125803,
      callsInDuration: 196814,
      callsOut: 125480,
      callsOutDuration: 158947,
      smses: 107028,
    },
    {
      date: "2017-12-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 956280,
      accounts: 81663,
      dataSent: 639038,
      dataReceived: 5429539,
      callsIn: 114896,
      callsInDuration: 182975,
      callsOut: 114546,
      callsOutDuration: 145198,
      smses: 101007,
    },
    {
      date: "2017-12-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1068525,
      accounts: 84901,
      dataSent: 704122,
      dataReceived: 5842024,
      callsIn: 135277,
      callsInDuration: 191758,
      callsOut: 132483,
      callsOutDuration: 156580,
      smses: 108226,
    },
    {
      date: "2017-12-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1059562,
      accounts: 84281,
      dataSent: 681741,
      dataReceived: 5606072,
      callsIn: 137431,
      callsInDuration: 195120,
      callsOut: 135967,
      callsOutDuration: 158875,
      smses: 106238,
    },
    {
      date: "2017-12-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1097745,
      accounts: 85424,
      dataSent: 721599,
      dataReceived: 5891842,
      callsIn: 150598,
      callsInDuration: 194546,
      callsOut: 152721,
      callsOutDuration: 161736,
      smses: 113594,
    },
    {
      date: "2017-12-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 980115,
      accounts: 81869,
      dataSent: 709694,
      dataReceived: 6069023,
      callsIn: 122315,
      callsInDuration: 150960,
      callsOut: 129456,
      callsOutDuration: 124679,
      smses: 98499,
    },
    {
      date: "2017-12-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 889102,
      accounts: 78720,
      dataSent: 650045,
      dataReceived: 5420337,
      callsIn: 91940,
      callsInDuration: 119598,
      callsOut: 98131,
      callsOutDuration: 104805,
      smses: 77364,
    },
    {
      date: "2017-12-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 888879,
      accounts: 76966,
      dataSent: 614262,
      dataReceived: 5209848,
      callsIn: 77511,
      callsInDuration: 107413,
      callsOut: 85607,
      callsOutDuration: 91486,
      smses: 86807,
    },
    {
      date: "2017-12-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 940151,
      accounts: 81578,
      dataSent: 545359,
      dataReceived: 4601103,
      callsIn: 111077,
      callsInDuration: 169109,
      callsOut: 106962,
      callsOutDuration: 130570,
      smses: 96721,
    },
    {
      date: "2017-12-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1134775,
      accounts: 85960,
      dataSent: 764485,
      dataReceived: 6315451,
      callsIn: 117036,
      callsInDuration: 177702,
      callsOut: 112140,
      callsOutDuration: 137914,
      smses: 99988,
    },
    {
      date: "2017-12-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 962724,
      accounts: 81375,
      dataSent: 650446,
      dataReceived: 5471999,
      callsIn: 123582,
      callsInDuration: 183761,
      callsOut: 116678,
      callsOutDuration: 140376,
      smses: 101014,
    },
    {
      date: "2017-12-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 969635,
      accounts: 81714,
      dataSent: 659863,
      dataReceived: 5498992,
      callsIn: 127038,
      callsInDuration: 174206,
      callsOut: 125761,
      callsOutDuration: 142035,
      smses: 100746,
    },
    {
      date: "2017-12-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 913087,
      accounts: 79771,
      dataSent: 631286,
      dataReceived: 5445379,
      callsIn: 104213,
      callsInDuration: 138052,
      callsOut: 110040,
      callsOutDuration: 113619,
      smses: 91523,
    },
    {
      date: "2017-12-31",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 904067,
      accounts: 78597,
      dataSent: 516336,
      dataReceived: 4387792,
      callsIn: 85606,
      callsInDuration: 118686,
      callsOut: 91946,
      callsOutDuration: 102744,
      smses: 94245,
    },
    {
      date: "2018-01-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 994159,
      accounts: 81204,
      dataSent: 805875,
      dataReceived: 6807378,
      callsIn: 77965,
      callsInDuration: 109972,
      callsOut: 86364,
      callsOutDuration: 93551,
      smses: 90783,
    },
    {
      date: "2018-01-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 868955,
      accounts: 78047,
      dataSent: 535910,
      dataReceived: 4559079,
      callsIn: 101146,
      callsInDuration: 160350,
      callsOut: 99350,
      callsOutDuration: 130353,
      smses: 90829,
    },
    {
      date: "2018-01-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1013433,
      accounts: 84446,
      dataSent: 627626,
      dataReceived: 5439708,
      callsIn: 105053,
      callsInDuration: 169247,
      callsOut: 103402,
      callsOutDuration: 135038,
      smses: 92989,
    },
    {
      date: "2018-01-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1278095,
      accounts: 85011,
      dataSent: 573706,
      dataReceived: 4890776,
      callsIn: 110477,
      callsInDuration: 168608,
      callsOut: 105229,
      callsOutDuration: 134141,
      smses: 93154,
    },
    {
      date: "2018-01-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1178339,
      accounts: 84931,
      dataSent: 754208,
      dataReceived: 6407977,
      callsIn: 118383,
      callsInDuration: 174981,
      callsOut: 114746,
      callsOutDuration: 141643,
      smses: 99809,
    },
    {
      date: "2018-01-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 974763,
      accounts: 80190,
      dataSent: 648343,
      dataReceived: 5406426,
      callsIn: 94515,
      callsInDuration: 129320,
      callsOut: 98438,
      callsOutDuration: 102498,
      smses: 89769,
    },
    {
      date: "2018-01-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 953913,
      accounts: 77268,
      dataSent: 578858,
      dataReceived: 4919170,
      callsIn: 67749,
      callsInDuration: 97578,
      callsOut: 73988,
      callsOutDuration: 75185,
      smses: 77202,
    },
    {
      date: "2018-01-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1019686,
      accounts: 81043,
      dataSent: 614121,
      dataReceived: 5076763,
      callsIn: 109298,
      callsInDuration: 174797,
      callsOut: 108625,
      callsOutDuration: 140190,
      smses: 94922,
    },
    {
      date: "2018-01-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1046461,
      accounts: 85106,
      dataSent: 689302,
      dataReceived: 5911434,
      callsIn: 109437,
      callsInDuration: 174830,
      callsOut: 106974,
      callsOutDuration: 138562,
      smses: 98553,
    },
    {
      date: "2018-01-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1012008,
      accounts: 84487,
      dataSent: 569612,
      dataReceived: 4833035,
      callsIn: 110157,
      callsInDuration: 174114,
      callsOut: 107921,
      callsOutDuration: 136231,
      smses: 96161,
    },
    {
      date: "2018-01-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1103635,
      accounts: 85117,
      dataSent: 700238,
      dataReceived: 6083709,
      callsIn: 110413,
      callsInDuration: 174034,
      callsOut: 106897,
      callsOutDuration: 135400,
      smses: 97367,
    },
    {
      date: "2018-01-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1177471,
      accounts: 86048,
      dataSent: 738256,
      dataReceived: 6471254,
      callsIn: 114863,
      callsInDuration: 171537,
      callsOut: 112999,
      callsOutDuration: 138735,
      smses: 99621,
    },
    {
      date: "2018-01-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 936665,
      accounts: 82424,
      dataSent: 739253,
      dataReceived: 6415673,
      callsIn: 90792,
      callsInDuration: 126411,
      callsOut: 96416,
      callsOutDuration: 104483,
      smses: 91952,
    },
    {
      date: "2018-01-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 826339,
      accounts: 77174,
      dataSent: 593675,
      dataReceived: 5206616,
      callsIn: 64669,
      callsInDuration: 100929,
      callsOut: 71384,
      callsOutDuration: 78844,
      smses: 80268,
    },
    {
      date: "2018-01-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 971584,
      accounts: 83589,
      dataSent: 707271,
      dataReceived: 6040889,
      callsIn: 106725,
      callsInDuration: 172984,
      callsOut: 106828,
      callsOutDuration: 143276,
      smses: 96062,
    },
    {
      date: "2018-01-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 970078,
      accounts: 82776,
      dataSent: 668824,
      dataReceived: 5731055,
      callsIn: 105474,
      callsInDuration: 173291,
      callsOut: 105083,
      callsOutDuration: 136260,
      smses: 95848,
    },
    {
      date: "2018-01-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1010975,
      accounts: 84296,
      dataSent: 675091,
      dataReceived: 5836433,
      callsIn: 105399,
      callsInDuration: 177267,
      callsOut: 103662,
      callsOutDuration: 138437,
      smses: 95715,
    },
    {
      date: "2018-01-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1052269,
      accounts: 86065,
      dataSent: 745902,
      dataReceived: 6357328,
      callsIn: 106998,
      callsInDuration: 177376,
      callsOut: 104691,
      callsOutDuration: 135708,
      smses: 98527,
    },
    {
      date: "2018-01-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1104034,
      accounts: 86067,
      dataSent: 552723,
      dataReceived: 4617449,
      callsIn: 116658,
      callsInDuration: 178069,
      callsOut: 114020,
      callsOutDuration: 140645,
      smses: 99805,
    },
    {
      date: "2018-01-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1867069,
      accounts: 87682,
      dataSent: 809570,
      dataReceived: 6909408,
      callsIn: 96866,
      callsInDuration: 135064,
      callsOut: 101931,
      callsOutDuration: 107482,
      smses: 91616,
    },
    {
      date: "2018-01-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 850572,
      accounts: 78605,
      dataSent: 630913,
      dataReceived: 5390492,
      callsIn: 69568,
      callsInDuration: 99662,
      callsOut: 77020,
      callsOutDuration: 80208,
      smses: 88194,
    },
    {
      date: "2018-01-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1146109,
      accounts: 86172,
      dataSent: 690577,
      dataReceived: 5878311,
      callsIn: 106812,
      callsInDuration: 177892,
      callsOut: 105823,
      callsOutDuration: 141709,
      smses: 97224,
    },
    {
      date: "2018-01-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 958696,
      accounts: 82628,
      dataSent: 673468,
      dataReceived: 5869199,
      callsIn: 107703,
      callsInDuration: 177312,
      callsOut: 104985,
      callsOutDuration: 140958,
      smses: 96165,
    },
    {
      date: "2018-01-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 988643,
      accounts: 82840,
      dataSent: 672369,
      dataReceived: 5786173,
      callsIn: 108467,
      callsInDuration: 182588,
      callsOut: 102930,
      callsOutDuration: 138745,
      smses: 96096,
    },
    {
      date: "2018-01-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 978911,
      accounts: 83573,
      dataSent: 707919,
      dataReceived: 6107546,
      callsIn: 107467,
      callsInDuration: 182014,
      callsOut: 104774,
      callsOutDuration: 141343,
      smses: 93956,
    },
    {
      date: "2018-01-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1003209,
      accounts: 84414,
      dataSent: 751053,
      dataReceived: 6500156,
      callsIn: 115929,
      callsInDuration: 182321,
      callsOut: 111629,
      callsOutDuration: 141218,
      smses: 95901,
    },
    {
      date: "2018-01-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 893358,
      accounts: 81225,
      dataSent: 645468,
      dataReceived: 5515491,
      callsIn: 96091,
      callsInDuration: 134377,
      callsOut: 97524,
      callsOutDuration: 105297,
      smses: 86918,
    },
    {
      date: "2018-01-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 853752,
      accounts: 79731,
      dataSent: 667613,
      dataReceived: 5692372,
      callsIn: 69047,
      callsInDuration: 106199,
      callsOut: 74522,
      callsOutDuration: 80806,
      smses: 79779,
    },
    {
      date: "2018-01-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 934254,
      accounts: 82490,
      dataSent: 698301,
      dataReceived: 5982841,
      callsIn: 107670,
      callsInDuration: 182667,
      callsOut: 104092,
      callsOutDuration: 141133,
      smses: 91435,
    },
    {
      date: "2018-01-30",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 947903,
      accounts: 82526,
      dataSent: 674856,
      dataReceived: 5845173,
      callsIn: 109281,
      callsInDuration: 184592,
      callsOut: 105538,
      callsOutDuration: 142307,
      smses: 92288,
    },
    {
      date: "2018-01-31",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1005910,
      accounts: 83046,
      dataSent: 609731,
      dataReceived: 5197020,
      callsIn: 112675,
      callsInDuration: 187827,
      callsOut: 108184,
      callsOutDuration: 146075,
      smses: 95442,
    },
    {
      date: "2018-02-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1156393,
      accounts: 88414,
      dataSent: 807640,
      dataReceived: 7022848,
      callsIn: 114171,
      callsInDuration: 183325,
      callsOut: 112898,
      callsOutDuration: 149873,
      smses: 92136,
    },
    {
      date: "2018-02-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1004471,
      accounts: 86064,
      dataSent: 776065,
      dataReceived: 6574803,
      callsIn: 123351,
      callsInDuration: 187981,
      callsOut: 121681,
      callsOutDuration: 151658,
      smses: 93804,
    },
    {
      date: "2018-02-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 926331,
      accounts: 83088,
      dataSent: 686203,
      dataReceived: 5873419,
      callsIn: 101202,
      callsInDuration: 140346,
      callsOut: 106418,
      callsOutDuration: 116332,
      smses: 86228,
    },
    {
      date: "2018-02-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 885682,
      accounts: 81139,
      dataSent: 668197,
      dataReceived: 5873316,
      callsIn: 72677,
      callsInDuration: 109811,
      callsOut: 79357,
      callsOutDuration: 87794,
      smses: 78433,
    },
    {
      date: "2018-02-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1015906,
      accounts: 85937,
      dataSent: 677053,
      dataReceived: 5650435,
      callsIn: 116587,
      callsInDuration: 190893,
      callsOut: 116201,
      callsOutDuration: 156793,
      smses: 95472,
    },
    {
      date: "2018-02-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1553760,
      accounts: 90603,
      dataSent: 694757,
      dataReceived: 6079488,
      callsIn: 112237,
      callsInDuration: 181454,
      callsOut: 109535,
      callsOutDuration: 146742,
      smses: 92457,
    },
    {
      date: "2018-02-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 988164,
      accounts: 85889,
      dataSent: 638722,
      dataReceived: 5600346,
      callsIn: 123949,
      callsInDuration: 201555,
      callsOut: 120533,
      callsOutDuration: 161900,
      smses: 98423,
    },
    {
      date: "2018-02-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1402742,
      accounts: 91033,
      dataSent: 734978,
      dataReceived: 6384262,
      callsIn: 131206,
      callsInDuration: 202978,
      callsOut: 130406,
      callsOutDuration: 169680,
      smses: 126812,
    },
    {
      date: "2018-02-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1129869,
      accounts: 90549,
      dataSent: 771449,
      dataReceived: 6724497,
      callsIn: 134095,
      callsInDuration: 202507,
      callsOut: 131684,
      callsOutDuration: 165203,
      smses: 109845,
    },
    {
      date: "2018-02-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 981646,
      accounts: 86125,
      dataSent: 784546,
      dataReceived: 6680503,
      callsIn: 110588,
      callsInDuration: 146917,
      callsOut: 116991,
      callsOutDuration: 123478,
      smses: 98524,
    },
    {
      date: "2018-02-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 918376,
      accounts: 82903,
      dataSent: 729748,
      dataReceived: 6118672,
      callsIn: 77760,
      callsInDuration: 111207,
      callsOut: 86048,
      callsOutDuration: 92938,
      smses: 99247,
    },
    {
      date: "2018-02-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 876646,
      accounts: 81725,
      dataSent: 718215,
      dataReceived: 6017950,
      callsIn: 78314,
      callsInDuration: 121144,
      callsOut: 83177,
      callsOutDuration: 94875,
      smses: 87862,
    },
    {
      date: "2018-02-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 844107,
      accounts: 79772,
      dataSent: 625472,
      dataReceived: 5279018,
      callsIn: 77928,
      callsInDuration: 125065,
      callsOut: 79912,
      callsOutDuration: 94779,
      smses: 84327,
    },
    {
      date: "2018-02-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1033771,
      accounts: 88006,
      dataSent: 783978,
      dataReceived: 6634980,
      callsIn: 126933,
      callsInDuration: 195134,
      callsOut: 124990,
      callsOutDuration: 163968,
      smses: 105177,
    },
    {
      date: "2018-02-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 973074,
      accounts: 86096,
      dataSent: 707013,
      dataReceived: 5978952,
      callsIn: 125999,
      callsInDuration: 203879,
      callsOut: 122459,
      callsOutDuration: 166096,
      smses: 105198,
    },
    {
      date: "2018-02-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1058023,
      accounts: 88961,
      dataSent: 767844,
      dataReceived: 6540577,
      callsIn: 136963,
      callsInDuration: 209429,
      callsOut: 132894,
      callsOutDuration: 170516,
      smses: 106485,
    },
    {
      date: "2018-02-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 946886,
      accounts: 85826,
      dataSent: 733468,
      dataReceived: 6265832,
      callsIn: 103422,
      callsInDuration: 149076,
      callsOut: 106966,
      callsOutDuration: 119126,
      smses: 90465,
    },
    {
      date: "2018-02-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 857989,
      accounts: 81969,
      dataSent: 642624,
      dataReceived: 5579807,
      callsIn: 74350,
      callsInDuration: 114584,
      callsOut: 79937,
      callsOutDuration: 89929,
      smses: 83208,
    },
    {
      date: "2018-02-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1037833,
      accounts: 88596,
      dataSent: 657625,
      dataReceived: 5595177,
      callsIn: 122526,
      callsInDuration: 207129,
      callsOut: 119614,
      callsOutDuration: 164013,
      smses: 101671,
    },
    {
      date: "2018-02-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1108956,
      accounts: 91056,
      dataSent: 812986,
      dataReceived: 6931398,
      callsIn: 120502,
      callsInDuration: 206255,
      callsOut: 115142,
      callsOutDuration: 162565,
      smses: 101290,
    },
    {
      date: "2018-02-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1054033,
      accounts: 89824,
      dataSent: 657050,
      dataReceived: 5685623,
      callsIn: 123101,
      callsInDuration: 208637,
      callsOut: 118920,
      callsOutDuration: 166359,
      smses: 101213,
    },
    {
      date: "2018-02-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1466672,
      accounts: 93594,
      dataSent: 695486,
      dataReceived: 5983961,
      callsIn: 128095,
      callsInDuration: 219048,
      callsOut: 121803,
      callsOutDuration: 166382,
      smses: 102207,
    },
    {
      date: "2018-02-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 796432,
      accounts: 76353,
      dataSent: 834726,
      dataReceived: 7293231,
      callsIn: 140236,
      callsInDuration: 219261,
      callsOut: 140138,
      callsOutDuration: 179191,
      smses: 144326,
    },
    {
      date: "2018-02-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 863199,
      accounts: 78141,
      dataSent: 672857,
      dataReceived: 5911570,
      callsIn: 107505,
      callsInDuration: 153666,
      callsOut: 111036,
      callsOutDuration: 124390,
      smses: 100262,
    },
    {
      date: "2018-02-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 860161,
      accounts: 80197,
      dataSent: 690573,
      dataReceived: 5956259,
      callsIn: 75629,
      callsInDuration: 119824,
      callsOut: 82047,
      callsOutDuration: 92875,
      smses: 85893,
    },
    {
      date: "2018-02-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1030477,
      accounts: 87398,
      dataSent: 743378,
      dataReceived: 6336288,
      callsIn: 125444,
      callsInDuration: 215743,
      callsOut: 123154,
      callsOutDuration: 172278,
      smses: 104801,
    },
    {
      date: "2018-02-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1015633,
      accounts: 87535,
      dataSent: 729622,
      dataReceived: 6194343,
      callsIn: 127604,
      callsInDuration: 223781,
      callsOut: 124531,
      callsOutDuration: 173787,
      smses: 106295,
    },
    {
      date: "2018-02-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1088055,
      accounts: 90542,
      dataSent: 672128,
      dataReceived: 5915491,
      callsIn: 134479,
      callsInDuration: 222628,
      callsOut: 130967,
      callsOutDuration: 180686,
      smses: 109546,
    },
    {
      date: "2018-03-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1201791,
      accounts: 94971,
      dataSent: 891244,
      dataReceived: 7787325,
      callsIn: 134793,
      callsInDuration: 226287,
      callsOut: 134069,
      callsOutDuration: 183482,
      smses: 106732,
    },
    {
      date: "2018-03-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1976906,
      accounts: 97315,
      dataSent: 827381,
      dataReceived: 7244983,
      callsIn: 147449,
      callsInDuration: 225160,
      callsOut: 147600,
      callsOutDuration: 184727,
      smses: 113633,
    },
    {
      date: "2018-03-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 986290,
      accounts: 89529,
      dataSent: 769460,
      dataReceived: 6711172,
      callsIn: 116813,
      callsInDuration: 162469,
      callsOut: 125102,
      callsOutDuration: 135923,
      smses: 96029,
    },
    {
      date: "2018-03-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 938136,
      accounts: 88327,
      dataSent: 729623,
      dataReceived: 6363051,
      callsIn: 81878,
      callsInDuration: 125715,
      callsOut: 90351,
      callsOutDuration: 101585,
      smses: 87876,
    },
    {
      date: "2018-03-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1156336,
      accounts: 94980,
      dataSent: 730333,
      dataReceived: 6321995,
      callsIn: 134908,
      callsInDuration: 227970,
      callsOut: 137211,
      callsOutDuration: 188403,
      smses: 108729,
    },
    {
      date: "2018-03-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1416166,
      accounts: 97858,
      dataSent: 786496,
      dataReceived: 6806906,
      callsIn: 136072,
      callsInDuration: 235111,
      callsOut: 134233,
      callsOutDuration: 189220,
      smses: 108426,
    },
    {
      date: "2018-03-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1214740,
      accounts: 97245,
      dataSent: 827666,
      dataReceived: 7187553,
      callsIn: 140539,
      callsInDuration: 235428,
      callsOut: 138252,
      callsOutDuration: 190714,
      smses: 106025,
    },
    {
      date: "2018-03-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1925573,
      accounts: 99451,
      dataSent: 593170,
      dataReceived: 5027653,
      callsIn: 143427,
      callsInDuration: 230460,
      callsOut: 140316,
      callsOutDuration: 185015,
      smses: 111984,
    },
    {
      date: "2018-03-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1531332,
      accounts: 100402,
      dataSent: 790562,
      dataReceived: 6934728,
      callsIn: 154233,
      callsInDuration: 234600,
      callsOut: 152156,
      callsOutDuration: 192395,
      smses: 109268,
    },
    {
      date: "2018-03-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1584026,
      accounts: 99074,
      dataSent: 940948,
      dataReceived: 8372687,
      callsIn: 120837,
      callsInDuration: 165939,
      callsOut: 126333,
      callsOutDuration: 136703,
      smses: 92877,
    },
    {
      date: "2018-03-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 969945,
      accounts: 90991,
      dataSent: 731052,
      dataReceived: 6469706,
      callsIn: 79349,
      callsInDuration: 127807,
      callsOut: 86808,
      callsOutDuration: 98819,
      smses: 81178,
    },
    {
      date: "2018-03-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1163671,
      accounts: 97444,
      dataSent: 719454,
      dataReceived: 6293335,
      callsIn: 140259,
      callsInDuration: 231515,
      callsOut: 140272,
      callsOutDuration: 192878,
      smses: 106839,
    },
    {
      date: "2018-03-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 4816109,
      accounts: 103978,
      dataSent: 277636,
      dataReceived: 2297116,
      callsIn: 139340,
      callsInDuration: 232739,
      callsOut: 135888,
      callsOutDuration: 187304,
      smses: 108775,
    },
    {
      date: "2018-03-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1576489,
      accounts: 102414,
      dataSent: 914934,
      dataReceived: 8378074,
      callsIn: 142487,
      callsInDuration: 232832,
      callsOut: 137790,
      callsOutDuration: 186640,
      smses: 108093,
    },
    {
      date: "2018-03-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1346142,
      accounts: 101915,
      dataSent: 992100,
      dataReceived: 9081959,
      callsIn: 140268,
      callsInDuration: 237156,
      callsOut: 134679,
      callsOutDuration: 187230,
      smses: 102639,
    },
    {
      date: "2018-03-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1297781,
      accounts: 101335,
      dataSent: 964453,
      dataReceived: 8835593,
      callsIn: 153269,
      callsInDuration: 239294,
      callsOut: 148268,
      callsOutDuration: 189696,
      smses: 106177,
    },
    {
      date: "2018-03-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1086929,
      accounts: 96288,
      dataSent: 890305,
      dataReceived: 8200612,
      callsIn: 118827,
      callsInDuration: 165887,
      callsOut: 121845,
      callsOutDuration: 133596,
      smses: 92137,
    },
    {
      date: "2018-03-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 994705,
      accounts: 92429,
      dataSent: 795471,
      dataReceived: 7734611,
      callsIn: 77843,
      callsInDuration: 131937,
      callsOut: 83190,
      callsOutDuration: 102053,
      smses: 77784,
    },
    {
      date: "2018-03-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1221792,
      accounts: 99997,
      dataSent: 754279,
      dataReceived: 6857314,
      callsIn: 138350,
      callsInDuration: 234258,
      callsOut: 130568,
      callsOutDuration: 183462,
      smses: 99856,
    },
    {
      date: "2018-03-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3724221,
      accounts: 104264,
      dataSent: 543681,
      dataReceived: 5000889,
      callsIn: 138933,
      callsInDuration: 237755,
      callsOut: 126675,
      callsOutDuration: 177102,
      smses: 98496,
    },
    {
      date: "2018-03-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2272849,
      accounts: 102410,
      dataSent: 646063,
      dataReceived: 5875730,
      callsIn: 141534,
      callsInDuration: 248039,
      callsOut: 127888,
      callsOutDuration: 183417,
      smses: 97101,
    },
    {
      date: "2018-03-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3275993,
      accounts: 103975,
      dataSent: 592479,
      dataReceived: 5291437,
      callsIn: 143324,
      callsInDuration: 245800,
      callsOut: 128158,
      callsOutDuration: 180179,
      smses: 102479,
    },
    {
      date: "2018-03-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 3879336,
      accounts: 104983,
      dataSent: 520673,
      dataReceived: 4585093,
      callsIn: 155703,
      callsInDuration: 240137,
      callsOut: 143967,
      callsOutDuration: 183632,
      smses: 105379,
    },
    {
      date: "2018-03-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1343698,
      accounts: 99442,
      dataSent: 1020296,
      dataReceived: 9743984,
      callsIn: 116126,
      callsInDuration: 165114,
      callsOut: 113870,
      callsOutDuration: 131117,
      smses: 84776,
    },
    {
      date: "2018-03-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 935303,
      accounts: 91564,
      dataSent: 826146,
      dataReceived: 8019193,
      callsIn: 81321,
      callsInDuration: 133498,
      callsOut: 81203,
      callsOutDuration: 98169,
      smses: 73703,
    },
    {
      date: "2018-03-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1128302,
      accounts: 98060,
      dataSent: 878186,
      dataReceived: 8063967,
      callsIn: 139362,
      callsInDuration: 241024,
      callsOut: 126734,
      callsOutDuration: 182844,
      smses: 101986,
    },
    {
      date: "2018-03-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1238059,
      accounts: 99980,
      dataSent: 920750,
      dataReceived: 8666671,
      callsIn: 141056,
      callsInDuration: 240305,
      callsOut: 128167,
      callsOutDuration: 182204,
      smses: 99750,
    },
    {
      date: "2018-03-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1299809,
      accounts: 102384,
      dataSent: 956268,
      dataReceived: 9080949,
      callsIn: 156662,
      callsInDuration: 244735,
      callsOut: 146759,
      callsOutDuration: 191736,
      smses: 108532,
    },
    {
      date: "2018-03-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1171126,
      accounts: 98980,
      dataSent: 1024432,
      dataReceived: 10038931,
      callsIn: 122431,
      callsInDuration: 186625,
      callsOut: 118621,
      callsOutDuration: 148089,
      smses: 90695,
    },
    {
      date: "2018-03-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1017699,
      accounts: 93422,
      dataSent: 864617,
      dataReceived: 8412255,
      callsIn: 91364,
      callsInDuration: 141251,
      callsOut: 93154,
      callsOutDuration: 110555,
      smses: 81352,
    },
    {
      date: "2018-03-31",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1076429,
      accounts: 95827,
      dataSent: 777444,
      dataReceived: 7406455,
      callsIn: 109385,
      callsInDuration: 152335,
      callsOut: 108883,
      callsOutDuration: 121270,
      smses: 88984,
    },
    {
      date: "2018-04-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1161678,
      accounts: 96881,
      dataSent: 1005344,
      dataReceived: 9674591,
      callsIn: 82917,
      callsInDuration: 126420,
      callsOut: 85478,
      callsOutDuration: 99080,
      smses: 86074,
    },
    {
      date: "2018-04-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 944978,
      accounts: 92449,
      dataSent: 838305,
      dataReceived: 8117356,
      callsIn: 80724,
      callsInDuration: 140612,
      callsOut: 77028,
      callsOutDuration: 99783,
      smses: 80027,
    },
    {
      date: "2018-04-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1180451,
      accounts: 99875,
      dataSent: 844856,
      dataReceived: 7859166,
      callsIn: 142657,
      callsInDuration: 241749,
      callsOut: 132317,
      callsOutDuration: 189850,
      smses: 104435,
    },
    {
      date: "2018-04-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4002415,
      accounts: 105819,
      dataSent: 394991,
      dataReceived: 3515973,
      callsIn: 145053,
      callsInDuration: 248508,
      callsOut: 132556,
      callsOutDuration: 189754,
      smses: 111880,
    },
    {
      date: "2018-04-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2893524,
      accounts: 105627,
      dataSent: 686714,
      dataReceived: 6315004,
      callsIn: 149407,
      callsInDuration: 252647,
      callsOut: 133084,
      callsOutDuration: 191167,
      smses: 109902,
    },
    {
      date: "2018-04-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1841786,
      accounts: 104950,
      dataSent: 1082467,
      dataReceived: 10325664,
      callsIn: 156442,
      callsInDuration: 248103,
      callsOut: 141228,
      callsOutDuration: 188895,
      smses: 108399,
    },
    {
      date: "2018-04-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1243685,
      accounts: 101357,
      dataSent: 963938,
      dataReceived: 9326823,
      callsIn: 123090,
      callsInDuration: 180008,
      callsOut: 117239,
      callsOutDuration: 138283,
      smses: 91470,
    },
    {
      date: "2018-04-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 926338,
      accounts: 92042,
      dataSent: 746775,
      dataReceived: 7381594,
      callsIn: 82330,
      callsInDuration: 140708,
      callsOut: 80738,
      callsOutDuration: 100384,
      smses: 79706,
    },
    {
      date: "2018-04-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2767325,
      accounts: 105656,
      dataSent: 700791,
      dataReceived: 6218529,
      callsIn: 150745,
      callsInDuration: 247078,
      callsOut: 136330,
      callsOutDuration: 194915,
      smses: 110426,
    },
    {
      date: "2018-04-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2730416,
      accounts: 105859,
      dataSent: 743776,
      dataReceived: 6804337,
      callsIn: 152492,
      callsInDuration: 256736,
      callsOut: 136898,
      callsOutDuration: 196304,
      smses: 111524,
    },
    {
      date: "2018-04-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2722615,
      accounts: 105971,
      dataSent: 751463,
      dataReceived: 6798514,
      callsIn: 152067,
      callsInDuration: 251034,
      callsOut: 130600,
      callsOutDuration: 187606,
      smses: 106623,
    },
    {
      date: "2018-04-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3468176,
      accounts: 106646,
      dataSent: 774823,
      dataReceived: 7231625,
      callsIn: 147583,
      callsInDuration: 250561,
      callsOut: 131173,
      callsOutDuration: 189983,
      smses: 110006,
    },
    {
      date: "2018-04-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 4983566,
      accounts: 104557,
      dataSent: 830432,
      dataReceived: 7638196,
      callsIn: 160450,
      callsInDuration: 251054,
      callsOut: 145943,
      callsOutDuration: 196745,
      smses: 107301,
    },
    {
      date: "2018-04-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 6116316,
      accounts: 101917,
      dataSent: 730310,
      dataReceived: 7093220,
      callsIn: 128111,
      callsInDuration: 182190,
      callsOut: 124612,
      callsOutDuration: 143440,
      smses: 88133,
    },
    {
      date: "2018-04-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 5954245,
      accounts: 98607,
      dataSent: 700904,
      dataReceived: 6836672,
      callsIn: 87412,
      callsInDuration: 139658,
      callsOut: 87844,
      callsOutDuration: 105765,
      smses: 77612,
    },
    {
      date: "2018-04-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 6200738,
      accounts: 102960,
      dataSent: 765875,
      dataReceived: 7266351,
      callsIn: 146479,
      callsInDuration: 250983,
      callsOut: 133886,
      callsOutDuration: 197351,
      smses: 102527,
    },
    {
      date: "2018-04-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 6242623,
      accounts: 104056,
      dataSent: 740846,
      dataReceived: 7188560,
      callsIn: 141662,
      callsInDuration: 247035,
      callsOut: 128467,
      callsOutDuration: 187943,
      smses: 104527,
    },
    {
      date: "2018-04-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6182163,
      accounts: 107001,
      dataSent: 818462,
      dataReceived: 7884239,
      callsIn: 147303,
      callsInDuration: 247255,
      callsOut: 130613,
      callsOutDuration: 189362,
      smses: 103387,
    },
    {
      date: "2018-04-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6410785,
      accounts: 105195,
      dataSent: 808860,
      dataReceived: 7765274,
      callsIn: 151520,
      callsInDuration: 255604,
      callsOut: 134243,
      callsOutDuration: 194742,
      smses: 104913,
    },
    {
      date: "2018-04-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6407123,
      accounts: 105540,
      dataSent: 841320,
      dataReceived: 8000381,
      callsIn: 163462,
      callsInDuration: 251741,
      callsOut: 147301,
      callsOutDuration: 198282,
      smses: 113482,
    },
    {
      date: "2018-04-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 6248947,
      accounts: 103202,
      dataSent: 756156,
      dataReceived: 7347433,
      callsIn: 132391,
      callsInDuration: 186842,
      callsOut: 123922,
      callsOutDuration: 146440,
      smses: 92184,
    },
    {
      date: "2018-04-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6026944,
      accounts: 99275,
      dataSent: 696470,
      dataReceived: 6857032,
      callsIn: 88584,
      callsInDuration: 145670,
      callsOut: 88865,
      callsOutDuration: 112182,
      smses: 82832,
    },
    {
      date: "2018-04-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 6249682,
      accounts: 104163,
      dataSent: 696888,
      dataReceived: 6616636,
      callsIn: 146713,
      callsInDuration: 255392,
      callsOut: 130875,
      callsOutDuration: 199800,
      smses: 110061,
    },
    {
      date: "2018-04-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 6287822,
      accounts: 107137,
      dataSent: 807505,
      dataReceived: 7880513,
      callsIn: 148507,
      callsInDuration: 257743,
      callsOut: 129807,
      callsOutDuration: 198388,
      smses: 108185,
    },
    {
      date: "2018-04-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6648884,
      accounts: 107293,
      dataSent: 834505,
      dataReceived: 8226455,
      callsIn: 152871,
      callsInDuration: 259531,
      callsOut: 132784,
      callsOutDuration: 195235,
      smses: 106039,
    },
    {
      date: "2018-04-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6370736,
      accounts: 106267,
      dataSent: 830401,
      dataReceived: 8065242,
      callsIn: 158229,
      callsInDuration: 261067,
      callsOut: 138926,
      callsOutDuration: 199632,
      smses: 109582,
    },
    {
      date: "2018-04-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6698796,
      accounts: 107898,
      dataSent: 863374,
      dataReceived: 8230327,
      callsIn: 173981,
      callsInDuration: 259156,
      callsOut: 156927,
      callsOutDuration: 207024,
      smses: 112711,
    },
    {
      date: "2018-04-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 6298830,
      accounts: 103869,
      dataSent: 779021,
      dataReceived: 7795023,
      callsIn: 133006,
      callsInDuration: 191578,
      callsOut: 129691,
      callsOutDuration: 151484,
      smses: 97565,
    },
    {
      date: "2018-04-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6019805,
      accounts: 99984,
      dataSent: 751627,
      dataReceived: 7744366,
      callsIn: 94979,
      callsInDuration: 154489,
      callsOut: 97841,
      callsOutDuration: 122335,
      smses: 90497,
    },
    {
      date: "2018-04-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 6030200,
      accounts: 101194,
      dataSent: 728122,
      dataReceived: 7267694,
      callsIn: 123494,
      callsInDuration: 180936,
      callsOut: 115777,
      callsOutDuration: 146426,
      smses: 98181,
    },
    {
      date: "2018-05-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5895663,
      accounts: 98020,
      dataSent: 713664,
      dataReceived: 7409348,
      callsIn: 80248,
      callsInDuration: 145308,
      callsOut: 79949,
      callsOutDuration: 107148,
      smses: 85671,
    },
    {
      date: "2018-05-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6269189,
      accounts: 104893,
      dataSent: 801511,
      dataReceived: 7738190,
      callsIn: 151236,
      callsInDuration: 253461,
      callsOut: 136957,
      callsOutDuration: 202845,
      smses: 113642,
    },
    {
      date: "2018-05-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6478551,
      accounts: 106539,
      dataSent: 817479,
      dataReceived: 7803316,
      callsIn: 160025,
      callsInDuration: 262709,
      callsOut: 142134,
      callsOutDuration: 208197,
      smses: 115092,
    },
    {
      date: "2018-05-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6473347,
      accounts: 107086,
      dataSent: 854091,
      dataReceived: 8276983,
      callsIn: 168634,
      callsInDuration: 267083,
      callsOut: 153082,
      callsOutDuration: 214542,
      smses: 117967,
    },
    {
      date: "2018-05-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 6281313,
      accounts: 104512,
      dataSent: 778740,
      dataReceived: 7695196,
      callsIn: 130291,
      callsInDuration: 197412,
      callsOut: 125078,
      callsOutDuration: 153107,
      smses: 96487,
    },
    {
      date: "2018-05-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6101807,
      accounts: 100828,
      dataSent: 739113,
      dataReceived: 7533223,
      callsIn: 87488,
      callsInDuration: 150727,
      callsOut: 87671,
      callsOutDuration: 116895,
      smses: 86477,
    },
    {
      date: "2018-05-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 6417814,
      accounts: 106971,
      dataSent: 831083,
      dataReceived: 8030726,
      callsIn: 157296,
      callsInDuration: 263466,
      callsOut: 143623,
      callsOutDuration: 213137,
      smses: 113687,
    },
    {
      date: "2018-05-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 6351626,
      accounts: 104500,
      dataSent: 802886,
      dataReceived: 7795212,
      callsIn: 157613,
      callsInDuration: 269095,
      callsOut: 137970,
      callsOutDuration: 204697,
      smses: 110834,
    },
    {
      date: "2018-05-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6295333,
      accounts: 106242,
      dataSent: 834953,
      dataReceived: 8341776,
      callsIn: 162046,
      callsInDuration: 273412,
      callsOut: 142222,
      callsOutDuration: 210514,
      smses: 111237,
    },
    {
      date: "2018-05-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6460588,
      accounts: 106802,
      dataSent: 851733,
      dataReceived: 8632396,
      callsIn: 160784,
      callsInDuration: 271546,
      callsOut: 141531,
      callsOutDuration: 209158,
      smses: 114700,
    },
    {
      date: "2018-05-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6550719,
      accounts: 108290,
      dataSent: 886490,
      dataReceived: 8519596,
      callsIn: 177108,
      callsInDuration: 272230,
      callsOut: 162241,
      callsOutDuration: 221915,
      smses: 112814,
    },
    {
      date: "2018-05-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 6450008,
      accounts: 106328,
      dataSent: 805591,
      dataReceived: 7856099,
      callsIn: 142039,
      callsInDuration: 198324,
      callsOut: 138912,
      callsOutDuration: 160117,
      smses: 96245,
    },
    {
      date: "2018-05-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6166246,
      accounts: 103343,
      dataSent: 743131,
      dataReceived: 7259885,
      callsIn: 92626,
      callsInDuration: 154299,
      callsOut: 94817,
      callsOutDuration: 119045,
      smses: 85058,
    },
    {
      date: "2018-05-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 6453161,
      accounts: 108007,
      dataSent: 862185,
      dataReceived: 8109089,
      callsIn: 157521,
      callsInDuration: 269707,
      callsOut: 140826,
      callsOutDuration: 217952,
      smses: 108520,
    },
    {
      date: "2018-05-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 6644743,
      accounts: 108802,
      dataSent: 887314,
      dataReceived: 8508524,
      callsIn: 157097,
      callsInDuration: 270107,
      callsOut: 141163,
      callsOutDuration: 216434,
      smses: 112188,
    },
    {
      date: "2018-05-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6726753,
      accounts: 109655,
      dataSent: 890264,
      dataReceived: 8632008,
      callsIn: 158098,
      callsInDuration: 276587,
      callsOut: 142399,
      callsOutDuration: 214800,
      smses: 109534,
    },
    {
      date: "2018-05-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6795687,
      accounts: 110169,
      dataSent: 903327,
      dataReceived: 9021212,
      callsIn: 156542,
      callsInDuration: 272643,
      callsOut: 140081,
      callsOutDuration: 212016,
      smses: 108713,
    },
    {
      date: "2018-05-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6835070,
      accounts: 111306,
      dataSent: 928928,
      dataReceived: 9073815,
      callsIn: 176456,
      callsInDuration: 272914,
      callsOut: 161930,
      callsOutDuration: 219271,
      smses: 117401,
    },
    {
      date: "2018-05-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 6765964,
      accounts: 109465,
      dataSent: 828875,
      dataReceived: 8299240,
      callsIn: 136396,
      callsInDuration: 195800,
      callsOut: 133705,
      callsOutDuration: 157092,
      smses: 94100,
    },
    {
      date: "2018-05-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6534845,
      accounts: 105288,
      dataSent: 765469,
      dataReceived: 7855782,
      callsIn: 91574,
      callsInDuration: 157345,
      callsOut: 94209,
      callsOutDuration: 120558,
      smses: 83963,
    },
    {
      date: "2018-05-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 6751041,
      accounts: 110283,
      dataSent: 808305,
      dataReceived: 7919309,
      callsIn: 159407,
      callsInDuration: 271827,
      callsOut: 144083,
      callsOutDuration: 215583,
      smses: 107974,
    },
    {
      date: "2018-05-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 12498680,
      accounts: 116673,
      dataSent: 945602,
      dataReceived: 9206979,
      callsIn: 162489,
      callsInDuration: 278475,
      callsOut: 146329,
      callsOutDuration: 221146,
      smses: 127994,
    },
    {
      date: "2018-05-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 11993261,
      accounts: 114183,
      dataSent: 915330,
      dataReceived: 8960310,
      callsIn: 166971,
      callsInDuration: 281050,
      callsOut: 150450,
      callsOutDuration: 222953,
      smses: 124144,
    },
    {
      date: "2018-05-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 11798342,
      accounts: 115545,
      dataSent: 1010580,
      dataReceived: 9237598,
      callsIn: 183947,
      callsInDuration: 283144,
      callsOut: 170947,
      callsOutDuration: 229088,
      smses: 131084,
    },
    {
      date: "2018-05-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10797452,
      accounts: 110791,
      dataSent: 859646,
      dataReceived: 8570984,
      callsIn: 118515,
      callsInDuration: 175129,
      callsOut: 118506,
      callsOutDuration: 143926,
      smses: 103229,
    },
    {
      date: "2018-05-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10531293,
      accounts: 110706,
      dataSent: 852464,
      dataReceived: 8481116,
      callsIn: 132999,
      callsInDuration: 190997,
      callsOut: 130296,
      callsOutDuration: 150608,
      smses: 106751,
    },
    {
      date: "2018-05-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9963893,
      accounts: 108137,
      dataSent: 792493,
      dataReceived: 7969225,
      callsIn: 92980,
      callsInDuration: 154432,
      callsOut: 94817,
      callsOutDuration: 121515,
      smses: 97166,
    },
    {
      date: "2018-05-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 8519238,
      accounts: 111312,
      dataSent: 912087,
      dataReceived: 8600054,
      callsIn: 157020,
      callsInDuration: 275853,
      callsOut: 143122,
      callsOutDuration: 220069,
      smses: 117075,
    },
    {
      date: "2018-05-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 6816085,
      accounts: 110414,
      dataSent: 913178,
      dataReceived: 8855687,
      callsIn: 156955,
      callsInDuration: 275722,
      callsOut: 141887,
      callsOutDuration: 215243,
      smses: 111871,
    },
    {
      date: "2018-05-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6872799,
      accounts: 110718,
      dataSent: 915335,
      dataReceived: 8901319,
      callsIn: 158533,
      callsInDuration: 284708,
      callsOut: 140428,
      callsOutDuration: 218749,
      smses: 112634,
    },
    {
      date: "2018-05-31",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6921069,
      accounts: 111272,
      dataSent: 910660,
      dataReceived: 8949529,
      callsIn: 160538,
      callsInDuration: 285153,
      callsOut: 142428,
      callsOutDuration: 218520,
      smses: 115610,
    },
    {
      date: "2018-06-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2433166,
      accounts: 111171,
      dataSent: 962402,
      dataReceived: 9246706,
      callsIn: 173675,
      callsInDuration: 284969,
      callsOut: 159546,
      callsOutDuration: 226395,
      smses: 119972,
    },
    {
      date: "2018-06-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2084947,
      accounts: 107248,
      dataSent: 830500,
      dataReceived: 8271780,
      callsIn: 138589,
      callsInDuration: 207501,
      callsOut: 135717,
      callsOutDuration: 165781,
      smses: 97806,
    },
    {
      date: "2018-06-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1836949,
      accounts: 101999,
      dataSent: 708082,
      dataReceived: 7352313,
      callsIn: 90339,
      callsInDuration: 157087,
      callsOut: 92645,
      callsOutDuration: 121369,
      smses: 83298,
    },
    {
      date: "2018-06-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2410802,
      accounts: 112026,
      dataSent: 791759,
      dataReceived: 7702768,
      callsIn: 159200,
      callsInDuration: 269520,
      callsOut: 145225,
      callsOutDuration: 217194,
      smses: 110295,
    },
    {
      date: "2018-06-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2424521,
      accounts: 113207,
      dataSent: 944919,
      dataReceived: 9038537,
      callsIn: 161911,
      callsInDuration: 272692,
      callsOut: 147140,
      callsOutDuration: 217460,
      smses: 114432,
    },
    {
      date: "2018-06-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2410677,
      accounts: 112492,
      dataSent: 942217,
      dataReceived: 9118488,
      callsIn: 165053,
      callsInDuration: 286180,
      callsOut: 153735,
      callsOutDuration: 229657,
      smses: 115700,
    },
    {
      date: "2018-06-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2412279,
      accounts: 112775,
      dataSent: 945294,
      dataReceived: 9202907,
      callsIn: 161644,
      callsInDuration: 292894,
      callsOut: 153114,
      callsOutDuration: 229401,
      smses: 114691,
    },
    {
      date: "2018-06-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2472763,
      accounts: 114042,
      dataSent: 981468,
      dataReceived: 9384524,
      callsIn: 183944,
      callsInDuration: 292543,
      callsOut: 173792,
      callsOutDuration: 239034,
      smses: 116660,
    },
    {
      date: "2018-06-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2228112,
      accounts: 111380,
      dataSent: 911471,
      dataReceived: 8964675,
      callsIn: 149070,
      callsInDuration: 213515,
      callsOut: 148795,
      callsOutDuration: 172129,
      smses: 96944,
    },
    {
      date: "2018-06-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1937539,
      accounts: 105545,
      dataSent: 843121,
      dataReceived: 8561523,
      callsIn: 110829,
      callsInDuration: 206657,
      callsOut: 114141,
      callsOutDuration: 156346,
      smses: 101182,
    },
    {
      date: "2018-06-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2370577,
      accounts: 112940,
      dataSent: 946308,
      dataReceived: 9338800,
      callsIn: 194080,
      callsInDuration: 382304,
      callsOut: 180214,
      callsOutDuration: 292732,
      smses: 138479,
    },
    {
      date: "2018-06-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2367429,
      accounts: 112763,
      dataSent: 953625,
      dataReceived: 9538734,
      callsIn: 192658,
      callsInDuration: 380195,
      callsOut: 176926,
      callsOutDuration: 283417,
      smses: 137696,
    },
    {
      date: "2018-06-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2433780,
      accounts: 113919,
      dataSent: 959987,
      dataReceived: 9427615,
      callsIn: 201556,
      callsInDuration: 381891,
      callsOut: 184069,
      callsOutDuration: 291582,
      smses: 143015,
    },
    {
      date: "2018-06-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2387099,
      accounts: 113749,
      dataSent: 967184,
      dataReceived: 9909816,
      callsIn: 194853,
      callsInDuration: 380090,
      callsOut: 179924,
      callsOutDuration: 292101,
      smses: 138862,
    },
    {
      date: "2018-06-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2427175,
      accounts: 114860,
      dataSent: 944343,
      dataReceived: 9678148,
      callsIn: 223683,
      callsInDuration: 374854,
      callsOut: 210903,
      callsOutDuration: 295849,
      smses: 144129,
    },
    {
      date: "2018-06-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2809265,
      accounts: 130980,
      dataSent: 1112201,
      dataReceived: 14305162,
      callsIn: 170805,
      callsInDuration: 253886,
      callsOut: 176232,
      callsOutDuration: 210847,
      smses: 123528,
    },
    {
      date: "2018-06-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2326355,
      accounts: 126440,
      dataSent: 969046,
      dataReceived: 10315847,
      callsIn: 125794,
      callsInDuration: 242058,
      callsOut: 134830,
      callsOutDuration: 203262,
      smses: 120068,
    },
    {
      date: "2018-06-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2326801,
      accounts: 113880,
      dataSent: 943623,
      dataReceived: 9552728,
      callsIn: 195002,
      callsInDuration: 374732,
      callsOut: 178009,
      callsOutDuration: 284268,
      smses: 138336,
    },
    {
      date: "2018-06-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2427473,
      accounts: 114176,
      dataSent: 907665,
      dataReceived: 9004351,
      callsIn: 211510,
      callsInDuration: 373948,
      callsOut: 195265,
      callsOutDuration: 293247,
      smses: 153003,
    },
    {
      date: "2018-06-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2040541,
      accounts: 110054,
      dataSent: 850389,
      dataReceived: 8963599,
      callsIn: 137177,
      callsInDuration: 263550,
      callsOut: 131815,
      callsOutDuration: 197383,
      smses: 115197,
    },
    {
      date: "2018-06-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2945438,
      accounts: 135812,
      dataSent: 1467511,
      dataReceived: 18856320,
      callsIn: 185500,
      callsInDuration: 345777,
      callsOut: 172907,
      callsOutDuration: 262419,
      smses: 137577,
    },
    {
      date: "2018-06-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2706205,
      accounts: 124313,
      dataSent: 1116740,
      dataReceived: 11880311,
      callsIn: 214729,
      callsInDuration: 362523,
      callsOut: 198440,
      callsOutDuration: 283971,
      smses: 140732,
    },
    {
      date: "2018-06-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2174117,
      accounts: 112555,
      dataSent: 969083,
      dataReceived: 10083596,
      callsIn: 175940,
      callsInDuration: 284485,
      callsOut: 174255,
      callsOutDuration: 219402,
      smses: 122983,
    },
    {
      date: "2018-06-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1925348,
      accounts: 106676,
      dataSent: 885772,
      dataReceived: 9263388,
      callsIn: 124942,
      callsInDuration: 244420,
      callsOut: 128679,
      callsOutDuration: 183557,
      smses: 114739,
    },
    {
      date: "2018-06-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1905157,
      accounts: 105296,
      dataSent: 766788,
      dataReceived: 8226347,
      callsIn: 153304,
      callsInDuration: 328695,
      callsOut: 141392,
      callsOutDuration: 241146,
      smses: 118592,
    },
    {
      date: "2018-06-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2764797,
      accounts: 137086,
      dataSent: 1411827,
      dataReceived: 18584051,
      callsIn: 184581,
      callsInDuration: 344302,
      callsOut: 171240,
      callsOutDuration: 268249,
      smses: 136268,
    },
    {
      date: "2018-06-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2356647,
      accounts: 123441,
      dataSent: 967203,
      dataReceived: 10468682,
      callsIn: 195732,
      callsInDuration: 380606,
      callsOut: 174482,
      callsOutDuration: 290117,
      smses: 134780,
    },
    {
      date: "2018-06-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2374385,
      accounts: 117284,
      dataSent: 1004508,
      dataReceived: 10162406,
      callsIn: 175442,
      callsInDuration: 304454,
      callsOut: 154311,
      callsOutDuration: 237511,
      smses: 111545,
    },
    {
      date: "2018-06-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2297124,
      accounts: 116428,
      dataSent: 929900,
      dataReceived: 9389770,
      callsIn: 192746,
      callsInDuration: 310231,
      callsOut: 174291,
      callsOutDuration: 247732,
      smses: 115085,
    },
    {
      date: "2018-06-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2567556,
      accounts: 135502,
      dataSent: 1202193,
      dataReceived: 16522894,
      callsIn: 128321,
      callsInDuration: 186652,
      callsOut: 126727,
      callsOutDuration: 149859,
      smses: 92363,
    },
    {
      date: "2018-07-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2063891,
      accounts: 121129,
      dataSent: 881736,
      dataReceived: 10123596,
      callsIn: 93054,
      callsInDuration: 167831,
      callsOut: 94669,
      callsOutDuration: 128609,
      smses: 85599,
    },
    {
      date: "2018-07-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2205099,
      accounts: 115194,
      dataSent: 944189,
      dataReceived: 9896363,
      callsIn: 165334,
      callsInDuration: 294977,
      callsOut: 150015,
      callsOutDuration: 239570,
      smses: 113182,
    },
    {
      date: "2018-07-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2162347,
      accounts: 114122,
      dataSent: 912639,
      dataReceived: 9445936,
      callsIn: 165976,
      callsInDuration: 298335,
      callsOut: 147677,
      callsOutDuration: 237381,
      smses: 105074,
    },
    {
      date: "2018-07-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2232209,
      accounts: 115713,
      dataSent: 909588,
      dataReceived: 9167916,
      callsIn: 173362,
      callsInDuration: 307049,
      callsOut: 155064,
      callsOutDuration: 242477,
      smses: 110447,
    },
    {
      date: "2018-07-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2311894,
      accounts: 117241,
      dataSent: 973526,
      dataReceived: 9616864,
      callsIn: 179026,
      callsInDuration: 307562,
      callsOut: 162207,
      callsOutDuration: 247287,
      smses: 114768,
    },
    {
      date: "2018-07-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2306975,
      accounts: 117544,
      dataSent: 991275,
      dataReceived: 10110005,
      callsIn: 190720,
      callsInDuration: 303323,
      callsOut: 176041,
      callsOutDuration: 242890,
      smses: 114614,
    },
    {
      date: "2018-07-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2031132,
      accounts: 113167,
      dataSent: 896158,
      dataReceived: 9318831,
      callsIn: 149436,
      callsInDuration: 222503,
      callsOut: 144818,
      callsOutDuration: 176436,
      smses: 100190,
    },
    {
      date: "2018-07-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1903720,
      accounts: 109964,
      dataSent: 874510,
      dataReceived: 8967098,
      callsIn: 106607,
      callsInDuration: 171624,
      callsOut: 110592,
      callsOutDuration: 138018,
      smses: 90388,
    },
    {
      date: "2018-07-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1810099,
      accounts: 107228,
      dataSent: 828913,
      dataReceived: 8545193,
      callsIn: 108180,
      callsInDuration: 181954,
      callsOut: 103165,
      callsOutDuration: 137025,
      smses: 89028,
    },
    {
      date: "2018-07-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2172308,
      accounts: 115394,
      dataSent: 941544,
      dataReceived: 9310518,
      callsIn: 177952,
      callsInDuration: 301251,
      callsOut: 163007,
      callsOutDuration: 246222,
      smses: 111764,
    },
    {
      date: "2018-07-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2165637,
      accounts: 116023,
      dataSent: 828104,
      dataReceived: 8203789,
      callsIn: 180547,
      callsInDuration: 308817,
      callsOut: 163540,
      callsOutDuration: 247499,
      smses: 112542,
    },
    {
      date: "2018-07-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2310149,
      accounts: 120382,
      dataSent: 983707,
      dataReceived: 9655280,
      callsIn: 183921,
      callsInDuration: 317572,
      callsOut: 166685,
      callsOutDuration: 253707,
      smses: 116794,
    },
    {
      date: "2018-07-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2351414,
      accounts: 119305,
      dataSent: 1020358,
      dataReceived: 10303611,
      callsIn: 196189,
      callsInDuration: 313200,
      callsOut: 180602,
      callsOutDuration: 251175,
      smses: 114976,
    },
    {
      date: "2018-07-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2152028,
      accounts: 116480,
      dataSent: 951004,
      dataReceived: 9613532,
      callsIn: 158675,
      callsInDuration: 230739,
      callsOut: 153756,
      callsOutDuration: 186197,
      smses: 101813,
    },
    {
      date: "2018-07-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1921320,
      accounts: 110853,
      dataSent: 852978,
      dataReceived: 9049316,
      callsIn: 104716,
      callsInDuration: 166270,
      callsOut: 105819,
      callsOutDuration: 132310,
      smses: 92179,
    },
    {
      date: "2018-07-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2090381,
      accounts: 114220,
      dataSent: 808739,
      dataReceived: 8113370,
      callsIn: 171218,
      callsInDuration: 297327,
      callsOut: 155144,
      callsOutDuration: 238922,
      smses: 107940,
    },
    {
      date: "2018-07-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2257439,
      accounts: 118302,
      dataSent: 918547,
      dataReceived: 9183115,
      callsIn: 172320,
      callsInDuration: 308836,
      callsOut: 155936,
      callsOutDuration: 240743,
      smses: 109714,
    },
    {
      date: "2018-07-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2367659,
      accounts: 119351,
      dataSent: 1005581,
      dataReceived: 10133333,
      callsIn: 168809,
      callsInDuration: 301409,
      callsOut: 152236,
      callsOutDuration: 238651,
      smses: 108989,
    },
    {
      date: "2018-07-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2234379,
      accounts: 116595,
      dataSent: 985101,
      dataReceived: 9995450,
      callsIn: 170685,
      callsInDuration: 308646,
      callsOut: 153486,
      callsOutDuration: 238588,
      smses: 111301,
    },
    {
      date: "2018-07-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2401781,
      accounts: 119579,
      dataSent: 1054836,
      dataReceived: 10051271,
      callsIn: 200496,
      callsInDuration: 312014,
      callsOut: 186953,
      callsOutDuration: 250307,
      smses: 122283,
    },
    {
      date: "2018-07-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2204707,
      accounts: 117839,
      dataSent: 979461,
      dataReceived: 9539497,
      callsIn: 164262,
      callsInDuration: 237266,
      callsOut: 164500,
      callsOutDuration: 192414,
      smses: 110601,
    },
    {
      date: "2018-07-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1963642,
      accounts: 112216,
      dataSent: 886758,
      dataReceived: 9041976,
      callsIn: 107501,
      callsInDuration: 177623,
      callsOut: 110933,
      callsOutDuration: 141515,
      smses: 92519,
    },
    {
      date: "2018-07-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2211457,
      accounts: 117816,
      dataSent: 957703,
      dataReceived: 9578981,
      callsIn: 172948,
      callsInDuration: 310572,
      callsOut: 154736,
      callsOutDuration: 241380,
      smses: 111033,
    },
    {
      date: "2018-07-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2227121,
      accounts: 118477,
      dataSent: 965039,
      dataReceived: 9779955,
      callsIn: 176589,
      callsInDuration: 316014,
      callsOut: 157536,
      callsOutDuration: 246193,
      smses: 113509,
    },
    {
      date: "2018-07-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2250019,
      accounts: 118489,
      dataSent: 971821,
      dataReceived: 9886199,
      callsIn: 172876,
      callsInDuration: 310499,
      callsOut: 153225,
      callsOutDuration: 237214,
      smses: 112430,
    },
    {
      date: "2018-07-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2239517,
      accounts: 118865,
      dataSent: 996902,
      dataReceived: 10297407,
      callsIn: 177824,
      callsInDuration: 316848,
      callsOut: 156839,
      callsOutDuration: 245805,
      smses: 112467,
    },
    {
      date: "2018-07-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2288135,
      accounts: 120341,
      dataSent: 1037642,
      dataReceived: 10575585,
      callsIn: 189066,
      callsInDuration: 313791,
      callsOut: 169659,
      callsOutDuration: 244892,
      smses: 115499,
    },
    {
      date: "2018-07-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2075758,
      accounts: 117213,
      dataSent: 957592,
      dataReceived: 9898602,
      callsIn: 147470,
      callsInDuration: 231185,
      callsOut: 143374,
      callsOutDuration: 178729,
      smses: 98147,
    },
    {
      date: "2018-07-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1898533,
      accounts: 112803,
      dataSent: 881861,
      dataReceived: 9335448,
      callsIn: 103507,
      callsInDuration: 182650,
      callsOut: 103316,
      callsOutDuration: 136697,
      smses: 89430,
    },
    {
      date: "2018-07-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2275312,
      accounts: 120956,
      dataSent: 984605,
      dataReceived: 9943866,
      callsIn: 185037,
      callsInDuration: 332389,
      callsOut: 166410,
      callsOutDuration: 261638,
      smses: 115188,
    },
    {
      date: "2018-07-31",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2290637,
      accounts: 121234,
      dataSent: 964810,
      dataReceived: 9700027,
      callsIn: 184474,
      callsInDuration: 338474,
      callsOut: 163812,
      callsOutDuration: 259770,
      smses: 115141,
    },
    {
      date: "2018-08-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1376918,
      accounts: 124725,
      dataSent: 1893955,
      dataReceived: 19009711,
      callsIn: 167734,
      callsInDuration: 339149,
      callsOut: 159827,
      callsOutDuration: 261572,
      smses: 114383,
    },
    {
      date: "2018-08-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 966115,
      accounts: 112427,
      dataSent: 1457949,
      dataReceived: 14354457,
      callsIn: 190913,
      callsInDuration: 347356,
      callsOut: 170568,
      callsOutDuration: 266466,
      smses: 118225,
    },
    {
      date: "2018-08-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 988995,
      accounts: 113572,
      dataSent: 1549108,
      dataReceived: 15248654,
      callsIn: 204784,
      callsInDuration: 338810,
      callsOut: 183295,
      callsOutDuration: 265518,
      smses: 120439,
    },
    {
      date: "2018-08-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 900877,
      accounts: 109624,
      dataSent: 1446732,
      dataReceived: 13931046,
      callsIn: 166956,
      callsInDuration: 244424,
      callsOut: 160516,
      callsOutDuration: 193624,
      smses: 106277,
    },
    {
      date: "2018-08-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 834937,
      accounts: 105158,
      dataSent: 1328403,
      dataReceived: 13113671,
      callsIn: 117290,
      callsInDuration: 194160,
      callsOut: 114981,
      callsOutDuration: 144233,
      smses: 93157,
    },
    {
      date: "2018-08-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 961893,
      accounts: 112499,
      dataSent: 1445833,
      dataReceived: 14010540,
      callsIn: 184261,
      callsInDuration: 341200,
      callsOut: 168596,
      callsOutDuration: 267502,
      smses: 117188,
    },
    {
      date: "2018-08-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 988348,
      accounts: 113370,
      dataSent: 1482819,
      dataReceived: 14557889,
      callsIn: 194703,
      callsInDuration: 340662,
      callsOut: 172074,
      callsOutDuration: 266298,
      smses: 122775,
    },
    {
      date: "2018-08-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1016346,
      accounts: 114064,
      dataSent: 1525614,
      dataReceived: 15149202,
      callsIn: 196090,
      callsInDuration: 341069,
      callsOut: 173425,
      callsOutDuration: 264054,
      smses: 124549,
    },
    {
      date: "2018-08-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 993892,
      accounts: 114911,
      dataSent: 1497766,
      dataReceived: 14565361,
      callsIn: 180328,
      callsInDuration: 350133,
      callsOut: 168520,
      callsOutDuration: 270466,
      smses: 119440,
    },
    {
      date: "2018-08-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1029684,
      accounts: 116923,
      dataSent: 1568580,
      dataReceived: 14754108,
      callsIn: 217568,
      callsInDuration: 352223,
      callsOut: 195458,
      callsOutDuration: 276102,
      smses: 125345,
    },
    {
      date: "2018-08-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 932327,
      accounts: 112162,
      dataSent: 1391553,
      dataReceived: 13445054,
      callsIn: 173022,
      callsInDuration: 251213,
      callsOut: 163444,
      callsOutDuration: 199424,
      smses: 106563,
    },
    {
      date: "2018-08-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 862682,
      accounts: 107476,
      dataSent: 1250666,
      dataReceived: 12098563,
      callsIn: 119233,
      callsInDuration: 195897,
      callsOut: 120247,
      callsOutDuration: 149819,
      smses: 93249,
    },
    {
      date: "2018-08-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1136393,
      accounts: 128832,
      dataSent: 1606442,
      dataReceived: 15445462,
      callsIn: 194600,
      callsInDuration: 349864,
      callsOut: 175677,
      callsOutDuration: 274279,
      smses: 127699,
    },
    {
      date: "2018-08-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1009296,
      accounts: 118078,
      dataSent: 1504369,
      dataReceived: 14251836,
      callsIn: 197908,
      callsInDuration: 358466,
      callsOut: 174809,
      callsOutDuration: 274288,
      smses: 123076,
    },
    {
      date: "2018-08-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1020322,
      accounts: 116916,
      dataSent: 1510709,
      dataReceived: 14769152,
      callsIn: 203850,
      callsInDuration: 366595,
      callsOut: 179857,
      callsOutDuration: 282272,
      smses: 124158,
    },
    {
      date: "2018-08-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1030565,
      accounts: 117679,
      dataSent: 1506111,
      dataReceived: 14339815,
      callsIn: 207580,
      callsInDuration: 362092,
      callsOut: 182097,
      callsOutDuration: 283735,
      smses: 121830,
    },
    {
      date: "2018-08-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1075817,
      accounts: 119142,
      dataSent: 1587090,
      dataReceived: 14712919,
      callsIn: 227465,
      callsInDuration: 357736,
      callsOut: 202783,
      callsOutDuration: 281827,
      smses: 125342,
    },
    {
      date: "2018-08-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 968692,
      accounts: 113744,
      dataSent: 1451433,
      dataReceived: 13919215,
      callsIn: 175175,
      callsInDuration: 260106,
      callsOut: 167635,
      callsOutDuration: 205084,
      smses: 110308,
    },
    {
      date: "2018-08-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 885787,
      accounts: 109230,
      dataSent: 1373058,
      dataReceived: 13138052,
      callsIn: 126021,
      callsInDuration: 198406,
      callsOut: 124977,
      callsOutDuration: 152270,
      smses: 95216,
    },
    {
      date: "2018-08-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 834516,
      accounts: 106343,
      dataSent: 1254991,
      dataReceived: 12142958,
      callsIn: 119931,
      callsInDuration: 221826,
      callsOut: 111270,
      callsOutDuration: 155924,
      smses: 94717,
    },
    {
      date: "2018-08-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 996461,
      accounts: 117109,
      dataSent: 1455753,
      dataReceived: 13647154,
      callsIn: 196279,
      callsInDuration: 355128,
      callsOut: 175386,
      callsOutDuration: 275928,
      smses: 124195,
    },
    {
      date: "2018-08-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1023223,
      accounts: 118037,
      dataSent: 1475068,
      dataReceived: 13876716,
      callsIn: 201182,
      callsInDuration: 361084,
      callsOut: 177101,
      callsOutDuration: 277701,
      smses: 120772,
    },
    {
      date: "2018-08-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1032151,
      accounts: 118828,
      dataSent: 1459622,
      dataReceived: 13814973,
      callsIn: 200674,
      callsInDuration: 361928,
      callsOut: 175589,
      callsOutDuration: 277138,
      smses: 120593,
    },
    {
      date: "2018-08-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1052582,
      accounts: 119704,
      dataSent: 1491044,
      dataReceived: 14071187,
      callsIn: 213946,
      callsInDuration: 360994,
      callsOut: 188690,
      callsOutDuration: 278299,
      smses: 126752,
    },
    {
      date: "2018-08-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 943743,
      accounts: 114565,
      dataSent: 1321407,
      dataReceived: 12406623,
      callsIn: 173104,
      callsInDuration: 259437,
      callsOut: 161409,
      callsOutDuration: 202253,
      smses: 109310,
    },
    {
      date: "2018-08-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 861873,
      accounts: 109323,
      dataSent: 1031588,
      dataReceived: 9799357,
      callsIn: 121459,
      callsInDuration: 203661,
      callsOut: 119148,
      callsOutDuration: 154219,
      smses: 97230,
    },
    {
      date: "2018-08-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1124544,
      accounts: 131923,
      dataSent: 1852282,
      dataReceived: 17650755,
      callsIn: 200236,
      callsInDuration: 361379,
      callsOut: 175880,
      callsOutDuration: 283500,
      smses: 123571,
    },
    {
      date: "2018-08-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1022674,
      accounts: 118707,
      dataSent: 1327369,
      dataReceived: 12361889,
      callsIn: 203189,
      callsInDuration: 371743,
      callsOut: 176545,
      callsOutDuration: 281907,
      smses: 123949,
    },
    {
      date: "2018-08-29",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1100592,
      accounts: 123276,
      dataSent: 1629227,
      dataReceived: 15325575,
      callsIn: 203469,
      callsInDuration: 370709,
      callsOut: 176389,
      callsOutDuration: 280252,
      smses: 124536,
    },
    {
      date: "2018-08-30",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1047997,
      accounts: 119731,
      dataSent: 1470129,
      dataReceived: 14023655,
      callsIn: 204005,
      callsInDuration: 382803,
      callsOut: 176218,
      callsOutDuration: 288584,
      smses: 126909,
    },
    {
      date: "2018-08-31",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1080066,
      accounts: 121538,
      dataSent: 1407470,
      dataReceived: 13247244,
      callsIn: 218736,
      callsInDuration: 371159,
      callsOut: 191977,
      callsOutDuration: 287870,
      smses: 129919,
    },
    {
      date: "2018-09-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1020262,
      accounts: 121623,
      dataSent: 1406647,
      dataReceived: 13495737,
      callsIn: 170168,
      callsInDuration: 271316,
      callsOut: 158631,
      callsOutDuration: 206697,
      smses: 106621,
    },
    {
      date: "2018-09-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 916590,
      accounts: 116088,
      dataSent: 1435759,
      dataReceived: 13720867,
      callsIn: 120729,
      callsInDuration: 207415,
      callsOut: 116939,
      callsOutDuration: 153654,
      smses: 93569,
    },
    {
      date: "2018-09-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1077918,
      accounts: 121583,
      dataSent: 1483636,
      dataReceived: 13492496,
      callsIn: 201328,
      callsInDuration: 367261,
      callsOut: 179108,
      callsOutDuration: 289271,
      smses: 124897,
    },
    {
      date: "2018-09-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1088283,
      accounts: 122069,
      dataSent: 1467093,
      dataReceived: 13684738,
      callsIn: 207071,
      callsInDuration: 376130,
      callsOut: 180675,
      callsOutDuration: 290510,
      smses: 123991,
    },
    {
      date: "2018-09-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1116755,
      accounts: 124020,
      dataSent: 1578952,
      dataReceived: 14666685,
      callsIn: 210746,
      callsInDuration: 380806,
      callsOut: 183327,
      callsOutDuration: 291091,
      smses: 128571,
    },
    {
      date: "2018-09-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1136648,
      accounts: 124718,
      dataSent: 1541972,
      dataReceived: 14579076,
      callsIn: 217937,
      callsInDuration: 379857,
      callsOut: 191059,
      callsOutDuration: 294086,
      smses: 129013,
    },
    {
      date: "2018-09-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1156529,
      accounts: 126235,
      dataSent: 1617324,
      dataReceived: 15057032,
      callsIn: 238529,
      callsInDuration: 380821,
      callsOut: 209934,
      callsOutDuration: 297520,
      smses: 132237,
    },
    {
      date: "2018-09-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1036876,
      accounts: 121449,
      dataSent: 1513046,
      dataReceived: 13927298,
      callsIn: 191121,
      callsInDuration: 277438,
      callsOut: 177643,
      callsOutDuration: 216319,
      smses: 110420,
    },
    {
      date: "2018-09-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 931346,
      accounts: 115998,
      dataSent: 1422735,
      dataReceived: 13590453,
      callsIn: 124705,
      callsInDuration: 213674,
      callsOut: 122722,
      callsOutDuration: 161263,
      smses: 97704,
    },
    {
      date: "2018-09-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1095191,
      accounts: 124838,
      dataSent: 1516346,
      dataReceived: 14643267,
      callsIn: 213286,
      callsInDuration: 379176,
      callsOut: 187675,
      callsOutDuration: 297299,
      smses: 129867,
    },
    {
      date: "2018-09-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1092653,
      accounts: 124656,
      dataSent: 1529113,
      dataReceived: 14914666,
      callsIn: 212687,
      callsInDuration: 378698,
      callsOut: 183157,
      callsOutDuration: 288603,
      smses: 125467,
    },
    {
      date: "2018-09-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1157628,
      accounts: 128362,
      dataSent: 1527666,
      dataReceived: 14769831,
      callsIn: 218705,
      callsInDuration: 394930,
      callsOut: 188421,
      callsOutDuration: 300881,
      smses: 135186,
    },
    {
      date: "2018-09-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1349656,
      accounts: 133024,
      dataSent: 1676556,
      dataReceived: 15868626,
      callsIn: 220340,
      callsInDuration: 390540,
      callsOut: 191862,
      callsOutDuration: 299608,
      smses: 131947,
    },
    {
      date: "2018-09-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1234317,
      accounts: 131856,
      dataSent: 1795311,
      dataReceived: 16714328,
      callsIn: 237895,
      callsInDuration: 385996,
      callsOut: 211111,
      callsOutDuration: 300005,
      smses: 136270,
    },
    {
      date: "2018-09-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1068443,
      accounts: 124534,
      dataSent: 1563972,
      dataReceived: 14796454,
      callsIn: 186094,
      callsInDuration: 281069,
      callsOut: 174825,
      callsOutDuration: 220006,
      smses: 120265,
    },
    {
      date: "2018-09-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 976173,
      accounts: 118226,
      dataSent: 1384263,
      dataReceived: 13383759,
      callsIn: 125379,
      callsInDuration: 219079,
      callsOut: 123714,
      callsOutDuration: 163921,
      smses: 112779,
    },
    {
      date: "2018-09-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1179115,
      accounts: 129407,
      dataSent: 1389196,
      dataReceived: 12876804,
      callsIn: 214255,
      callsInDuration: 384439,
      callsOut: 191891,
      callsOutDuration: 302845,
      smses: 136132,
    },
    {
      date: "2018-09-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1275647,
      accounts: 138542,
      dataSent: 1972895,
      dataReceived: 18208674,
      callsIn: 222838,
      callsInDuration: 389146,
      callsOut: 193511,
      callsOutDuration: 300430,
      smses: 143361,
    },
    {
      date: "2018-09-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1194588,
      accounts: 130282,
      dataSent: 1614841,
      dataReceived: 14839980,
      callsIn: 223329,
      callsInDuration: 396777,
      callsOut: 195814,
      callsOutDuration: 303637,
      smses: 138257,
    },
    {
      date: "2018-09-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1211300,
      accounts: 131440,
      dataSent: 1540539,
      dataReceived: 13999884,
      callsIn: 240433,
      callsInDuration: 408425,
      callsOut: 208870,
      callsOutDuration: 311622,
      smses: 143401,
    },
    {
      date: "2018-09-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1407602,
      accounts: 143632,
      dataSent: 1792588,
      dataReceived: 16240832,
      callsIn: 249470,
      callsInDuration: 385938,
      callsOut: 223587,
      callsOutDuration: 299703,
      smses: 140390,
    },
    {
      date: "2018-09-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1174462,
      accounts: 133159,
      dataSent: 1540016,
      dataReceived: 14013183,
      callsIn: 196738,
      callsInDuration: 291933,
      callsOut: 185697,
      callsOutDuration: 228383,
      smses: 128126,
    },
    {
      date: "2018-09-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1086783,
      accounts: 128498,
      dataSent: 1664554,
      dataReceived: 15748376,
      callsIn: 134676,
      callsInDuration: 222475,
      callsOut: 135383,
      callsOutDuration: 172780,
      smses: 113180,
    },
    {
      date: "2018-09-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1156842,
      accounts: 129377,
      dataSent: 1590357,
      dataReceived: 14875660,
      callsIn: 210053,
      callsInDuration: 376735,
      callsOut: 188527,
      callsOutDuration: 296792,
      smses: 134435,
    },
    {
      date: "2018-09-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 962112,
      accounts: 118833,
      dataSent: 1318852,
      dataReceived: 12548187,
      callsIn: 168436,
      callsInDuration: 341979,
      callsOut: 146481,
      callsOutDuration: 242478,
      smses: 110241,
    },
    {
      date: "2018-09-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1206252,
      accounts: 131704,
      dataSent: 1654522,
      dataReceived: 14880408,
      callsIn: 226308,
      callsInDuration: 405543,
      callsOut: 196634,
      callsOutDuration: 311537,
      smses: 140196,
    },
    {
      date: "2018-09-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1206913,
      accounts: 131979,
      dataSent: 1637914,
      dataReceived: 15041779,
      callsIn: 223489,
      callsInDuration: 406401,
      callsOut: 191992,
      callsOutDuration: 303209,
      smses: 138749,
    },
    {
      date: "2018-09-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1262284,
      accounts: 134596,
      dataSent: 1713080,
      dataReceived: 15902600,
      callsIn: 247239,
      callsInDuration: 408230,
      callsOut: 214524,
      callsOutDuration: 312230,
      smses: 144932,
    },
    {
      date: "2018-09-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1101578,
      accounts: 127315,
      dataSent: 1525043,
      dataReceived: 14177043,
      callsIn: 193262,
      callsInDuration: 305434,
      callsOut: 177520,
      callsOutDuration: 229756,
      smses: 120344,
    },
    {
      date: "2018-09-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1018230,
      accounts: 123247,
      dataSent: 1410764,
      dataReceived: 13095134,
      callsIn: 131513,
      callsInDuration: 227961,
      callsOut: 126727,
      callsOutDuration: 170927,
      smses: 108868,
    },
    {
      date: "2018-10-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1233793,
      accounts: 137400,
      dataSent: 1789954,
      dataReceived: 16339723,
      callsIn: 220310,
      callsInDuration: 402869,
      callsOut: 193164,
      callsOutDuration: 310353,
      smses: 136445,
    },
    {
      date: "2018-10-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1207843,
      accounts: 133326,
      dataSent: 1627213,
      dataReceived: 14917542,
      callsIn: 223455,
      callsInDuration: 402242,
      callsOut: 194101,
      callsOutDuration: 311815,
      smses: 133247,
    },
    {
      date: "2018-10-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1216509,
      accounts: 134690,
      dataSent: 1461639,
      dataReceived: 13443450,
      callsIn: 231828,
      callsInDuration: 421212,
      callsOut: 198488,
      callsOutDuration: 321055,
      smses: 133804,
    },
    {
      date: "2018-10-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1374042,
      accounts: 144737,
      dataSent: 2027269,
      dataReceived: 18709501,
      callsIn: 236657,
      callsInDuration: 418229,
      callsOut: 203253,
      callsOutDuration: 320488,
      smses: 132866,
    },
    {
      date: "2018-10-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1321690,
      accounts: 139418,
      dataSent: 1862752,
      dataReceived: 16844767,
      callsIn: 258626,
      callsInDuration: 418268,
      callsOut: 226635,
      callsOutDuration: 328091,
      smses: 135442,
    },
    {
      date: "2018-10-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1191248,
      accounts: 133382,
      dataSent: 1750745,
      dataReceived: 15419414,
      callsIn: 216600,
      callsInDuration: 307215,
      callsOut: 201969,
      callsOutDuration: 241273,
      smses: 116448,
    },
    {
      date: "2018-10-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1089815,
      accounts: 127570,
      dataSent: 1580473,
      dataReceived: 14281450,
      callsIn: 145136,
      callsInDuration: 234278,
      callsOut: 142499,
      callsOutDuration: 180667,
      smses: 105970,
    },
    {
      date: "2018-10-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1267387,
      accounts: 136613,
      dataSent: 1581561,
      dataReceived: 13923153,
      callsIn: 231273,
      callsInDuration: 411104,
      callsOut: 203737,
      callsOutDuration: 322676,
      smses: 132790,
    },
    {
      date: "2018-10-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1379306,
      accounts: 141838,
      dataSent: 1840792,
      dataReceived: 16812882,
      callsIn: 232588,
      callsInDuration: 419513,
      callsOut: 202062,
      callsOutDuration: 329915,
      smses: 132638,
    },
    {
      date: "2018-10-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1278374,
      accounts: 138264,
      dataSent: 1370449,
      dataReceived: 12825900,
      callsIn: 240666,
      callsInDuration: 429276,
      callsOut: 207909,
      callsOutDuration: 328762,
      smses: 128740,
    },
    {
      date: "2018-10-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1371901,
      accounts: 153062,
      dataSent: 2261792,
      dataReceived: 21062328,
      callsIn: 235597,
      callsInDuration: 421851,
      callsOut: 203845,
      callsOutDuration: 325783,
      smses: 130881,
    },
    {
      date: "2018-10-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1305630,
      accounts: 139376,
      dataSent: 1817476,
      dataReceived: 16443923,
      callsIn: 257998,
      callsInDuration: 416999,
      callsOut: 227391,
      callsOutDuration: 329402,
      smses: 133984,
    },
    {
      date: "2018-10-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1201374,
      accounts: 133225,
      dataSent: 1680458,
      dataReceived: 14901918,
      callsIn: 210362,
      callsInDuration: 306284,
      callsOut: 196678,
      callsOutDuration: 240558,
      smses: 114688,
    },
    {
      date: "2018-10-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1111779,
      accounts: 124544,
      dataSent: 1453310,
      dataReceived: 12763246,
      callsIn: 153647,
      callsInDuration: 229862,
      callsOut: 151145,
      callsOutDuration: 184551,
      smses: 103860,
    },
    {
      date: "2018-10-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 980487,
      accounts: 110602,
      dataSent: 932585,
      dataReceived: 8509821,
      callsIn: 152482,
      callsInDuration: 260125,
      callsOut: 139258,
      callsOutDuration: 190986,
      smses: 107051,
    },
    {
      date: "2018-10-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1376666,
      accounts: 152547,
      dataSent: 2174794,
      dataReceived: 20399304,
      callsIn: 240757,
      callsInDuration: 423180,
      callsOut: 207875,
      callsOutDuration: 332367,
      smses: 134405,
    },
    {
      date: "2018-10-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1320699,
      accounts: 141617,
      dataSent: 1829989,
      dataReceived: 16838274,
      callsIn: 245093,
      callsInDuration: 432011,
      callsOut: 210870,
      callsOutDuration: 333276,
      smses: 133616,
    },
    {
      date: "2018-10-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1298936,
      accounts: 138899,
      dataSent: 1637580,
      dataReceived: 14973961,
      callsIn: 248280,
      callsInDuration: 435317,
      callsOut: 209482,
      callsOutDuration: 330430,
      smses: 132577,
    },
    {
      date: "2018-10-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1438867,
      accounts: 150687,
      dataSent: 1696422,
      dataReceived: 15231936,
      callsIn: 260835,
      callsInDuration: 426157,
      callsOut: 226052,
      callsOutDuration: 328525,
      smses: 136048,
    },
    {
      date: "2018-10-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1377925,
      accounts: 144400,
      dataSent: 1907538,
      dataReceived: 17425256,
      callsIn: 218419,
      callsInDuration: 319075,
      callsOut: 202298,
      callsOutDuration: 249294,
      smses: 115450,
    },
    {
      date: "2018-10-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1217825,
      accounts: 134639,
      dataSent: 1504990,
      dataReceived: 13616995,
      callsIn: 151466,
      callsInDuration: 257361,
      callsOut: 150662,
      callsOutDuration: 221217,
      smses: 109492,
    },
    {
      date: "2018-10-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1375465,
      accounts: 144252,
      dataSent: 1424621,
      dataReceived: 13020773,
      callsIn: 228633,
      callsInDuration: 416404,
      callsOut: 194141,
      callsOutDuration: 314252,
      smses: 129668,
    },
    {
      date: "2018-10-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1522899,
      accounts: 155948,
      dataSent: 2129349,
      dataReceived: 19632476,
      callsIn: 228358,
      callsInDuration: 418724,
      callsOut: 194682,
      callsOutDuration: 315607,
      smses: 128214,
    },
    {
      date: "2018-10-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1289249,
      accounts: 141356,
      dataSent: 1699002,
      dataReceived: 16086361,
      callsIn: 225293,
      callsInDuration: 414763,
      callsOut: 188737,
      callsOutDuration: 307236,
      smses: 124533,
    },
    {
      date: "2018-10-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1396186,
      accounts: 146678,
      dataSent: 1818638,
      dataReceived: 16824554,
      callsIn: 234521,
      callsInDuration: 418733,
      callsOut: 198561,
      callsOutDuration: 311501,
      smses: 126659,
    },
    {
      date: "2018-10-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1383649,
      accounts: 143630,
      dataSent: 1833329,
      dataReceived: 16550842,
      callsIn: 261996,
      callsInDuration: 424897,
      callsOut: 226186,
      callsOutDuration: 327471,
      smses: 131799,
    },
    {
      date: "2018-10-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1214615,
      accounts: 132246,
      dataSent: 1464217,
      dataReceived: 13296777,
      callsIn: 211948,
      callsInDuration: 307229,
      callsOut: 193465,
      callsOutDuration: 238371,
      smses: 113652,
    },
    {
      date: "2018-10-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 873648,
      accounts: 109136,
      dataSent: 769880,
      dataReceived: 7106934,
      callsIn: 143945,
      callsInDuration: 242665,
      callsOut: 137574,
      callsOutDuration: 176630,
      smses: 85078,
    },
    {
      date: "2018-10-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 420897,
      accounts: 134822,
      dataSent: 873845,
      dataReceived: 8247420,
      callsIn: 237261,
      callsInDuration: 433204,
      callsOut: 199419,
      callsOutDuration: 329656,
      smses: 20726,
    },
    {
      date: "2018-10-30",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1411362,
      accounts: 157677,
      dataSent: 2150295,
      dataReceived: 20201885,
      callsIn: 234401,
      callsInDuration: 436876,
      callsOut: 196181,
      callsOutDuration: 329275,
      smses: 135754,
    },
    {
      date: "2018-10-31",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1349454,
      accounts: 144387,
      dataSent: 1731374,
      dataReceived: 16208814,
      callsIn: 245596,
      callsInDuration: 443598,
      callsOut: 210095,
      callsOutDuration: 339625,
      smses: 135678,
    },
    {
      date: "2018-11-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1343362,
      accounts: 148156,
      dataSent: 1973658,
      dataReceived: 18085328,
      callsIn: 252337,
      callsInDuration: 453784,
      callsOut: 216273,
      callsOutDuration: 348484,
      smses: 134566,
    },
    {
      date: "2018-11-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1353098,
      accounts: 146200,
      dataSent: 1908254,
      dataReceived: 17697937,
      callsIn: 270105,
      callsInDuration: 440818,
      callsOut: 233775,
      callsOutDuration: 345672,
      smses: 135458,
    },
    {
      date: "2018-11-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1231951,
      accounts: 141465,
      dataSent: 1748284,
      dataReceived: 15945860,
      callsIn: 224543,
      callsInDuration: 319794,
      callsOut: 207058,
      callsOutDuration: 252444,
      smses: 118900,
    },
    {
      date: "2018-11-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1134325,
      accounts: 134963,
      dataSent: 1490512,
      dataReceived: 13805648,
      callsIn: 153779,
      callsInDuration: 251253,
      callsOut: 149478,
      callsOutDuration: 190559,
      smses: 108230,
    },
    {
      date: "2018-11-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1356944,
      accounts: 150220,
      dataSent: 1809138,
      dataReceived: 16853215,
      callsIn: 250772,
      callsInDuration: 441755,
      callsOut: 214889,
      callsOutDuration: 346219,
      smses: 136083,
    },
    {
      date: "2018-11-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1404641,
      accounts: 151969,
      dataSent: 1932913,
      dataReceived: 17930968,
      callsIn: 250075,
      callsInDuration: 451135,
      callsOut: 213019,
      callsOutDuration: 340842,
      smses: 134634,
    },
    {
      date: "2018-11-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1436124,
      accounts: 155460,
      dataSent: 2196712,
      dataReceived: 20463668,
      callsIn: 262388,
      callsInDuration: 460576,
      callsOut: 224508,
      callsOutDuration: 359095,
      smses: 134638,
    },
    {
      date: "2018-11-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1348248,
      accounts: 148814,
      dataSent: 1923992,
      dataReceived: 17862020,
      callsIn: 268113,
      callsInDuration: 458059,
      callsOut: 232618,
      callsOutDuration: 359291,
      smses: 137582,
    },
    {
      date: "2018-11-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1434186,
      accounts: 152531,
      dataSent: 2130255,
      dataReceived: 20073707,
      callsIn: 294802,
      callsInDuration: 448101,
      callsOut: 258140,
      callsOutDuration: 357905,
      smses: 141769,
    },
    {
      date: "2018-11-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1252693,
      accounts: 142583,
      dataSent: 1868771,
      dataReceived: 18038932,
      callsIn: 224369,
      callsInDuration: 340931,
      callsOut: 209077,
      callsOutDuration: 267343,
      smses: 121242,
    },
    {
      date: "2018-11-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1161002,
      accounts: 138126,
      dataSent: 1769010,
      dataReceived: 17408566,
      callsIn: 149755,
      callsInDuration: 260512,
      callsOut: 144432,
      callsOutDuration: 197903,
      smses: 107184,
    },
    {
      date: "2018-11-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1387696,
      accounts: 149517,
      dataSent: 1996494,
      dataReceived: 19308700,
      callsIn: 254958,
      callsInDuration: 458017,
      callsOut: 220817,
      callsOutDuration: 358132,
      smses: 141019,
    },
    {
      date: "2018-11-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1393497,
      accounts: 150705,
      dataSent: 1968644,
      dataReceived: 19012793,
      callsIn: 264317,
      callsInDuration: 471421,
      callsOut: 222223,
      callsOutDuration: 358585,
      smses: 137634,
    },
    {
      date: "2018-11-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1440407,
      accounts: 152881,
      dataSent: 1880052,
      dataReceived: 18145105,
      callsIn: 273513,
      callsInDuration: 480531,
      callsOut: 231945,
      callsOutDuration: 367966,
      smses: 137288,
    },
    {
      date: "2018-11-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1495575,
      accounts: 157845,
      dataSent: 2296625,
      dataReceived: 22104924,
      callsIn: 282421,
      callsInDuration: 477933,
      callsOut: 241396,
      callsOutDuration: 369724,
      smses: 138047,
    },
    {
      date: "2018-11-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1446188,
      accounts: 154146,
      dataSent: 2167353,
      dataReceived: 20497331,
      callsIn: 297845,
      callsInDuration: 468402,
      callsOut: 256959,
      callsOutDuration: 366678,
      smses: 141619,
    },
    {
      date: "2018-11-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1318184,
      accounts: 147679,
      dataSent: 2064823,
      dataReceived: 19383600,
      callsIn: 230259,
      callsInDuration: 332862,
      callsOut: 213337,
      callsOutDuration: 259074,
      smses: 122317,
    },
    {
      date: "2018-11-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1213175,
      accounts: 142188,
      dataSent: 1955329,
      dataReceived: 18254876,
      callsIn: 167570,
      callsInDuration: 256281,
      callsOut: 163172,
      callsOutDuration: 201700,
      smses: 110632,
    },
    {
      date: "2018-11-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1159481,
      accounts: 138795,
      dataSent: 1790271,
      dataReceived: 16946876,
      callsIn: 168221,
      callsInDuration: 285585,
      callsOut: 152328,
      callsOutDuration: 210361,
      smses: 111190,
    },
    {
      date: "2018-11-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1358949,
      accounts: 150540,
      dataSent: 1960646,
      dataReceived: 18366697,
      callsIn: 270414,
      callsInDuration: 463453,
      callsOut: 229911,
      callsOutDuration: 365173,
      smses: 138029,
    },
    {
      date: "2018-11-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1414623,
      accounts: 152176,
      dataSent: 2059464,
      dataReceived: 19486819,
      callsIn: 275828,
      callsInDuration: 468921,
      callsOut: 232302,
      callsOutDuration: 358600,
      smses: 139716,
    },
    {
      date: "2018-11-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1408245,
      accounts: 151984,
      dataSent: 2066223,
      dataReceived: 19426619,
      callsIn: 275752,
      callsInDuration: 484122,
      callsOut: 232335,
      callsOutDuration: 369765,
      smses: 140722,
    },
    {
      date: "2018-11-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1413061,
      accounts: 153790,
      dataSent: 2197864,
      dataReceived: 20468464,
      callsIn: 297378,
      callsInDuration: 480031,
      callsOut: 253221,
      callsOutDuration: 376685,
      smses: 140012,
    },
    {
      date: "2018-11-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1254110,
      accounts: 146826,
      dataSent: 2001071,
      dataReceived: 18964103,
      callsIn: 229666,
      callsInDuration: 341736,
      callsOut: 206446,
      callsOutDuration: 266883,
      smses: 125446,
    },
    {
      date: "2018-11-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1130689,
      accounts: 139512,
      dataSent: 1809146,
      dataReceived: 17096138,
      callsIn: 154655,
      callsInDuration: 267692,
      callsOut: 146878,
      callsOutDuration: 198001,
      smses: 107840,
    },
    {
      date: "2018-11-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1338861,
      accounts: 151379,
      dataSent: 1971229,
      dataReceived: 18732224,
      callsIn: 265833,
      callsInDuration: 474438,
      callsOut: 222644,
      callsOutDuration: 367677,
      smses: 134581,
    },
    {
      date: "2018-11-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1346268,
      accounts: 152063,
      dataSent: 2049286,
      dataReceived: 19891829,
      callsIn: 268837,
      callsInDuration: 486818,
      callsOut: 223701,
      callsOutDuration: 372659,
      smses: 135168,
    },
    {
      date: "2018-11-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1400468,
      accounts: 155688,
      dataSent: 2146198,
      dataReceived: 20433277,
      callsIn: 279743,
      callsInDuration: 487812,
      callsOut: 232174,
      callsOutDuration: 369073,
      smses: 137150,
    },
    {
      date: "2018-11-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1385852,
      accounts: 154256,
      dataSent: 2106831,
      dataReceived: 19679856,
      callsIn: 286546,
      callsInDuration: 497265,
      callsOut: 240072,
      callsOutDuration: 376789,
      smses: 140425,
    },
    {
      date: "2018-11-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1346009,
      accounts: 151326,
      dataSent: 1939453,
      dataReceived: 18258168,
      callsIn: 284547,
      callsInDuration: 455303,
      callsOut: 247504,
      callsOutDuration: 348525,
      smses: 136044,
    },
    {
      date: "2018-12-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1287836,
      accounts: 153167,
      dataSent: 2181183,
      dataReceived: 20580894,
      callsIn: 223609,
      callsInDuration: 342620,
      callsOut: 203047,
      callsOutDuration: 260072,
      smses: 119465,
    },
    {
      date: "2018-12-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1139067,
      accounts: 141950,
      dataSent: 1838198,
      dataReceived: 17233418,
      callsIn: 156568,
      callsInDuration: 271591,
      callsOut: 147017,
      callsOutDuration: 201128,
      smses: 108595,
    },
    {
      date: "2018-12-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1318267,
      accounts: 152326,
      dataSent: 1825181,
      dataReceived: 17383318,
      callsIn: 281199,
      callsInDuration: 499867,
      callsOut: 238272,
      callsOutDuration: 392064,
      smses: 134731,
    },
    {
      date: "2018-12-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1956174,
      accounts: 160261,
      dataSent: 2212195,
      dataReceived: 21094152,
      callsIn: 286810,
      callsInDuration: 510762,
      callsOut: 241853,
      callsOutDuration: 394563,
      smses: 138675,
    },
    {
      date: "2018-12-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1420183,
      accounts: 159742,
      dataSent: 2176153,
      dataReceived: 20506998,
      callsIn: 294863,
      callsInDuration: 513894,
      callsOut: 248940,
      callsOutDuration: 400211,
      smses: 137032,
    },
    {
      date: "2018-12-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1385733,
      accounts: 156881,
      dataSent: 2011094,
      dataReceived: 18862188,
      callsIn: 298377,
      callsInDuration: 505134,
      callsOut: 252770,
      callsOutDuration: 391986,
      smses: 139028,
    },
    {
      date: "2018-12-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1585797,
      accounts: 167718,
      dataSent: 2518588,
      dataReceived: 23101572,
      callsIn: 332697,
      callsInDuration: 500944,
      callsOut: 289439,
      callsOutDuration: 401692,
      smses: 141577,
    },
    {
      date: "2018-12-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1307531,
      accounts: 153364,
      dataSent: 2119619,
      dataReceived: 19484191,
      callsIn: 245337,
      callsInDuration: 347260,
      callsOut: 233505,
      callsOutDuration: 276339,
      smses: 124469,
    },
    {
      date: "2018-12-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1191227,
      accounts: 144888,
      dataSent: 1951411,
      dataReceived: 18838885,
      callsIn: 171173,
      callsInDuration: 273531,
      callsOut: 164685,
      callsOutDuration: 210430,
      smses: 112427,
    },
    {
      date: "2018-12-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1381218,
      accounts: 155963,
      dataSent: 1951407,
      dataReceived: 18852276,
      callsIn: 300102,
      callsInDuration: 502687,
      callsOut: 258277,
      callsOutDuration: 401052,
      smses: 142178,
    },
    {
      date: "2018-12-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1352600,
      accounts: 153244,
      dataSent: 1768469,
      dataReceived: 16861243,
      callsIn: 283608,
      callsInDuration: 495050,
      callsOut: 242289,
      callsOutDuration: 390445,
      smses: 137349,
    },
    {
      date: "2018-12-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1435681,
      accounts: 167427,
      dataSent: 1484962,
      dataReceived: 14014119,
      callsIn: 303774,
      callsInDuration: 482470,
      callsOut: 260059,
      callsOutDuration: 378053,
      smses: 143485,
    },
    {
      date: "2018-12-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1563324,
      accounts: 171969,
      dataSent: 2677023,
      dataReceived: 26236611,
      callsIn: 299211,
      callsInDuration: 506186,
      callsOut: 251466,
      callsOutDuration: 391252,
      smses: 136656,
    },
    {
      date: "2018-12-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1510768,
      accounts: 162993,
      dataSent: 2318230,
      dataReceived: 21807920,
      callsIn: 338170,
      callsInDuration: 519860,
      callsOut: 294353,
      callsOutDuration: 411807,
      smses: 146104,
    },
    {
      date: "2018-12-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1357166,
      accounts: 155966,
      dataSent: 2112881,
      dataReceived: 19508245,
      callsIn: 266417,
      callsInDuration: 370211,
      callsOut: 245921,
      callsOutDuration: 295967,
      smses: 122670,
    },
    {
      date: "2018-12-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1234123,
      accounts: 148418,
      dataSent: 1884421,
      dataReceived: 17876177,
      callsIn: 179836,
      callsInDuration: 287638,
      callsOut: 174927,
      callsOutDuration: 219736,
      smses: 111995,
    },
    {
      date: "2018-12-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1377017,
      accounts: 156416,
      dataSent: 1654733,
      dataReceived: 16098471,
      callsIn: 299916,
      callsInDuration: 520621,
      callsOut: 258203,
      callsOutDuration: 407486,
      smses: 139083,
    },
    {
      date: "2018-12-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1578829,
      accounts: 177139,
      dataSent: 2833182,
      dataReceived: 27701147,
      callsIn: 313661,
      callsInDuration: 523600,
      callsOut: 266841,
      callsOutDuration: 410824,
      smses: 139077,
    },
    {
      date: "2018-12-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1428734,
      accounts: 160392,
      dataSent: 2124133,
      dataReceived: 20151180,
      callsIn: 331284,
      callsInDuration: 542921,
      callsOut: 284000,
      callsOutDuration: 426857,
      smses: 141317,
    },
    {
      date: "2018-12-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1459708,
      accounts: 161920,
      dataSent: 2144370,
      dataReceived: 20608447,
      callsIn: 346628,
      callsInDuration: 553839,
      callsOut: 298065,
      callsOutDuration: 437351,
      smses: 144966,
    },
    {
      date: "2018-12-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1520546,
      accounts: 164577,
      dataSent: 2250026,
      dataReceived: 21321781,
      callsIn: 376936,
      callsInDuration: 547116,
      callsOut: 332636,
      callsOutDuration: 445891,
      smses: 145429,
    },
    {
      date: "2018-12-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1407636,
      accounts: 160582,
      dataSent: 2115195,
      dataReceived: 20127789,
      callsIn: 312934,
      callsInDuration: 413852,
      callsOut: 290783,
      callsOutDuration: 330058,
      smses: 129513,
    },
    {
      date: "2018-12-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1290790,
      accounts: 153873,
      dataSent: 1954732,
      dataReceived: 19163306,
      callsIn: 227369,
      callsInDuration: 323301,
      callsOut: 219193,
      callsOutDuration: 257746,
      smses: 117707,
    },
    {
      date: "2018-12-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1348048,
      accounts: 156086,
      dataSent: 1966748,
      dataReceived: 18484289,
      callsIn: 281011,
      callsInDuration: 378928,
      callsOut: 262228,
      callsOutDuration: 313359,
      smses: 116041,
    },
    {
      date: "2018-12-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1339866,
      accounts: 151989,
      dataSent: 1925346,
      dataReceived: 18941360,
      callsIn: 191426,
      callsInDuration: 290443,
      callsOut: 186279,
      callsOutDuration: 236430,
      smses: 123590,
    },
    {
      date: "2018-12-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1359040,
      accounts: 158150,
      dataSent: 1956203,
      dataReceived: 19330272,
      callsIn: 289428,
      callsInDuration: 465355,
      callsOut: 252268,
      callsOutDuration: 380172,
      smses: 130480,
    },
    {
      date: "2018-12-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1371836,
      accounts: 158441,
      dataSent: 2006518,
      dataReceived: 19539357,
      callsIn: 301725,
      callsInDuration: 499175,
      callsOut: 259653,
      callsOutDuration: 398774,
      smses: 133327,
    },
    {
      date: "2018-12-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1422042,
      accounts: 160898,
      dataSent: 2124055,
      dataReceived: 21102886,
      callsIn: 330752,
      callsInDuration: 510240,
      callsOut: 286362,
      callsOutDuration: 410165,
      smses: 141925,
    },
    {
      date: "2018-12-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1317343,
      accounts: 157078,
      dataSent: 2083549,
      dataReceived: 21516002,
      callsIn: 261581,
      callsInDuration: 378763,
      callsOut: 241248,
      callsOutDuration: 299277,
      smses: 128699,
    },
    {
      date: "2018-12-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1258597,
      accounts: 152025,
      dataSent: 2036413,
      dataReceived: 21063928,
      callsIn: 197665,
      callsInDuration: 315643,
      callsOut: 191229,
      callsOutDuration: 248428,
      smses: 121460,
    },
    {
      date: "2018-12-31",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1277937,
      accounts: 153620,
      dataSent: 2045917,
      dataReceived: 19849904,
      callsIn: 253225,
      callsInDuration: 367903,
      callsOut: 236269,
      callsOutDuration: 299978,
      smses: 116593,
    },
    {
      date: "2019-01-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1377099,
      accounts: 156789,
      dataSent: 2385048,
      dataReceived: 24614947,
      callsIn: 199262,
      callsInDuration: 294011,
      callsOut: 195884,
      callsOutDuration: 244700,
      smses: 128158,
    },
    {
      date: "2019-01-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1321094,
      accounts: 156405,
      dataSent: 2047979,
      dataReceived: 21537435,
      callsIn: 258039,
      callsInDuration: 446842,
      callsOut: 229322,
      callsOutDuration: 372141,
      smses: 127908,
    },
    {
      date: "2019-01-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1350848,
      accounts: 158250,
      dataSent: 2085265,
      dataReceived: 21267491,
      callsIn: 272689,
      callsInDuration: 479278,
      callsOut: 239917,
      callsOutDuration: 389996,
      smses: 128142,
    },
    {
      date: "2019-01-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1408330,
      accounts: 161162,
      dataSent: 2177040,
      dataReceived: 21490508,
      callsIn: 297775,
      callsInDuration: 483742,
      callsOut: 263996,
      callsOutDuration: 396647,
      smses: 136773,
    },
    {
      date: "2019-01-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1354693,
      accounts: 158008,
      dataSent: 2072084,
      dataReceived: 20500544,
      callsIn: 244174,
      callsInDuration: 361721,
      callsOut: 225357,
      callsOutDuration: 283887,
      smses: 123022,
    },
    {
      date: "2019-01-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1235568,
      accounts: 150486,
      dataSent: 1874526,
      dataReceived: 18973167,
      callsIn: 163308,
      callsInDuration: 281616,
      callsOut: 154382,
      callsOutDuration: 212924,
      smses: 116285,
    },
    {
      date: "2019-01-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1382163,
      accounts: 160455,
      dataSent: 2131375,
      dataReceived: 21266785,
      callsIn: 277601,
      callsInDuration: 485052,
      callsOut: 244662,
      callsOutDuration: 399717,
      smses: 136352,
    },
    {
      date: "2019-01-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1401857,
      accounts: 159027,
      dataSent: 2075648,
      dataReceived: 20707630,
      callsIn: 280410,
      callsInDuration: 489130,
      callsOut: 243433,
      callsOutDuration: 394975,
      smses: 133919,
    },
    {
      date: "2019-01-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1416272,
      accounts: 159814,
      dataSent: 2088585,
      dataReceived: 21125850,
      callsIn: 280848,
      callsInDuration: 496235,
      callsOut: 240697,
      callsOutDuration: 392198,
      smses: 135842,
    },
    {
      date: "2019-01-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1403100,
      accounts: 160150,
      dataSent: 2123242,
      dataReceived: 21662170,
      callsIn: 277983,
      callsInDuration: 493752,
      callsOut: 236650,
      callsOutDuration: 386998,
      smses: 133098,
    },
    {
      date: "2019-01-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1423313,
      accounts: 162077,
      dataSent: 2203553,
      dataReceived: 21695689,
      callsIn: 297945,
      callsInDuration: 487971,
      callsOut: 259361,
      callsOutDuration: 385944,
      smses: 132595,
    },
    {
      date: "2019-01-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1357952,
      accounts: 159077,
      dataSent: 2133841,
      dataReceived: 21030013,
      callsIn: 236896,
      callsInDuration: 352125,
      callsOut: 217633,
      callsOutDuration: 273433,
      smses: 125071,
    },
    {
      date: "2019-01-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1240789,
      accounts: 150407,
      dataSent: 1885623,
      dataReceived: 19271296,
      callsIn: 162366,
      callsInDuration: 283879,
      callsOut: 156331,
      callsOutDuration: 212159,
      smses: 117016,
    },
    {
      date: "2019-01-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1355601,
      accounts: 156862,
      dataSent: 1958372,
      dataReceived: 19704720,
      callsIn: 275669,
      callsInDuration: 487854,
      callsOut: 240779,
      callsOutDuration: 393884,
      smses: 132571,
    },
    {
      date: "2019-01-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1194200,
      accounts: 158177,
      dataSent: 1927415,
      dataReceived: 19428259,
      callsIn: 265091,
      callsInDuration: 485178,
      callsOut: 227808,
      callsOutDuration: 376900,
      smses: 132784,
    },
    {
      date: "2019-01-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1447606,
      accounts: 163717,
      dataSent: 2147312,
      dataReceived: 21566508,
      callsIn: 265832,
      callsInDuration: 490938,
      callsOut: 238224,
      callsOutDuration: 383631,
      smses: 134478,
    },
    {
      date: "2019-01-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1425820,
      accounts: 162466,
      dataSent: 2009148,
      dataReceived: 19993195,
      callsIn: 267502,
      callsInDuration: 492024,
      callsOut: 232562,
      callsOutDuration: 382104,
      smses: 131837,
    },
    {
      date: "2019-01-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1518178,
      accounts: 170734,
      dataSent: 2515880,
      dataReceived: 24730768,
      callsIn: 297688,
      callsInDuration: 494774,
      callsOut: 254722,
      callsOutDuration: 389281,
      smses: 133750,
    },
    {
      date: "2019-01-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1376683,
      accounts: 161643,
      dataSent: 2210651,
      dataReceived: 21480334,
      callsIn: 247016,
      callsInDuration: 361550,
      callsOut: 221866,
      callsOutDuration: 278784,
      smses: 126791,
    },
    {
      date: "2019-01-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1297189,
      accounts: 155079,
      dataSent: 2066804,
      dataReceived: 19999731,
      callsIn: 176483,
      callsInDuration: 279094,
      callsOut: 167902,
      callsOutDuration: 211904,
      smses: 120450,
    },
    {
      date: "2019-01-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1400386,
      accounts: 162222,
      dataSent: 2052790,
      dataReceived: 20277427,
      callsIn: 282872,
      callsInDuration: 492537,
      callsOut: 242505,
      callsOutDuration: 392220,
      smses: 134661,
    },
    {
      date: "2019-01-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1495641,
      accounts: 167126,
      dataSent: 2310613,
      dataReceived: 23411321,
      callsIn: 290833,
      callsInDuration: 492208,
      callsOut: 247137,
      callsOutDuration: 387418,
      smses: 148937,
    },
    {
      date: "2019-01-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1418841,
      accounts: 163446,
      dataSent: 2218982,
      dataReceived: 22331219,
      callsIn: 289136,
      callsInDuration: 493180,
      callsOut: 242578,
      callsOutDuration: 383833,
      smses: 140275,
    },
    {
      date: "2019-01-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1424174,
      accounts: 164035,
      dataSent: 2119020,
      dataReceived: 21192638,
      callsIn: 289165,
      callsInDuration: 499392,
      callsOut: 243754,
      callsOutDuration: 387254,
      smses: 138614,
    },
    {
      date: "2019-01-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1486598,
      accounts: 167300,
      dataSent: 2272744,
      dataReceived: 22687196,
      callsIn: 297653,
      callsInDuration: 501922,
      callsOut: 253582,
      callsOutDuration: 387756,
      smses: 146730,
    },
    {
      date: "2019-01-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1393684,
      accounts: 163365,
      dataSent: 2124441,
      dataReceived: 20695973,
      callsIn: 248789,
      callsInDuration: 373927,
      callsOut: 223618,
      callsOutDuration: 288300,
      smses: 132262,
    },
    {
      date: "2019-01-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1300254,
      accounts: 157240,
      dataSent: 1920672,
      dataReceived: 18734745,
      callsIn: 177690,
      callsInDuration: 292121,
      callsOut: 170251,
      callsOutDuration: 220347,
      smses: 120744,
    },
    {
      date: "2019-01-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1420992,
      accounts: 165442,
      dataSent: 1681836,
      dataReceived: 16456448,
      callsIn: 296850,
      callsInDuration: 508903,
      callsOut: 253881,
      callsOutDuration: 404713,
      smses: 139521,
    },
    {
      date: "2019-01-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1618011,
      accounts: 185987,
      dataSent: 2422327,
      dataReceived: 23788967,
      callsIn: 300431,
      callsInDuration: 516426,
      callsOut: 252511,
      callsOutDuration: 402386,
      smses: 140420,
    },
    {
      date: "2019-01-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1806790,
      accounts: 187101,
      dataSent: 2181273,
      dataReceived: 21945601,
      callsIn: 300113,
      callsInDuration: 525156,
      callsOut: 250879,
      callsOutDuration: 411324,
      smses: 148152,
    },
    {
      date: "2019-01-31",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1415817,
      accounts: 163513,
      dataSent: 1763105,
      dataReceived: 17678058,
      callsIn: 270077,
      callsInDuration: 469176,
      callsOut: 221016,
      callsOutDuration: 361895,
      smses: 144074,
    },
    {
      date: "2019-02-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1046593,
      accounts: 171858,
      dataSent: 2097461,
      dataReceived: 20754051,
      callsIn: 325335,
      callsInDuration: 534775,
      callsOut: 281178,
      callsOutDuration: 426103,
      smses: 150463,
    },
    {
      date: "2019-02-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1435892,
      accounts: 168073,
      dataSent: 2252408,
      dataReceived: 21854477,
      callsIn: 251832,
      callsInDuration: 391955,
      callsOut: 230481,
      callsOutDuration: 306405,
      smses: 130152,
    },
    {
      date: "2019-02-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1311552,
      accounts: 160837,
      dataSent: 2074145,
      dataReceived: 20228529,
      callsIn: 177376,
      callsInDuration: 302634,
      callsOut: 169916,
      callsOutDuration: 228968,
      smses: 118889,
    },
    {
      date: "2019-02-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1754345,
      accounts: 170528,
      dataSent: 1941239,
      dataReceived: 19158254,
      callsIn: 300027,
      callsInDuration: 545705,
      callsOut: 260871,
      callsOutDuration: 441436,
      smses: 141381,
    },
    {
      date: "2019-02-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1990310,
      accounts: 177613,
      dataSent: 1847037,
      dataReceived: 18467997,
      callsIn: 304884,
      callsInDuration: 546471,
      callsOut: 262998,
      callsOutDuration: 438938,
      smses: 143796,
    },
    {
      date: "2019-02-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1971315,
      accounts: 194714,
      dataSent: 2449870,
      dataReceived: 24558317,
      callsIn: 310843,
      callsInDuration: 547246,
      callsOut: 266789,
      callsOutDuration: 437863,
      smses: 147460,
    },
    {
      date: "2019-02-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2097038,
      accounts: 183457,
      dataSent: 2426630,
      dataReceived: 24134092,
      callsIn: 318518,
      callsInDuration: 556809,
      callsOut: 273871,
      callsOutDuration: 447372,
      smses: 147518,
    },
    {
      date: "2019-02-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2176037,
      accounts: 185826,
      dataSent: 2482153,
      dataReceived: 24931668,
      callsIn: 340192,
      callsInDuration: 544144,
      callsOut: 299160,
      callsOutDuration: 447458,
      smses: 154233,
    },
    {
      date: "2019-02-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1999488,
      accounts: 180823,
      dataSent: 2325953,
      dataReceived: 23227090,
      callsIn: 265276,
      callsInDuration: 392712,
      callsOut: 246290,
      callsOutDuration: 315790,
      smses: 134452,
    },
    {
      date: "2019-02-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1854547,
      accounts: 173745,
      dataSent: 2188833,
      dataReceived: 21921675,
      callsIn: 187129,
      callsInDuration: 316221,
      callsOut: 179657,
      callsOutDuration: 246234,
      smses: 128683,
    },
    {
      date: "2019-02-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2101633,
      accounts: 184901,
      dataSent: 2468199,
      dataReceived: 24785519,
      callsIn: 308582,
      callsInDuration: 559405,
      callsOut: 270411,
      callsOutDuration: 460423,
      smses: 148213,
    },
    {
      date: "2019-02-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2038346,
      accounts: 182727,
      dataSent: 2010955,
      dataReceived: 20176636,
      callsIn: 313160,
      callsInDuration: 579704,
      callsOut: 269938,
      callsOutDuration: 464425,
      smses: 145150,
    },
    {
      date: "2019-02-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2260423,
      accounts: 198855,
      dataSent: 3106396,
      dataReceived: 31636016,
      callsIn: 326185,
      callsInDuration: 581763,
      callsOut: 276271,
      callsOutDuration: 463235,
      smses: 142737,
    },
    {
      date: "2019-02-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2084457,
      accounts: 184743,
      dataSent: 2493121,
      dataReceived: 25219700,
      callsIn: 319279,
      callsInDuration: 550944,
      callsOut: 279563,
      callsOutDuration: 451063,
      smses: 149644,
    },
    {
      date: "2019-02-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2147893,
      accounts: 187404,
      dataSent: 2491092,
      dataReceived: 24805833,
      callsIn: 347309,
      callsInDuration: 568207,
      callsOut: 301340,
      callsOutDuration: 462453,
      smses: 150899,
    },
    {
      date: "2019-02-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2015365,
      accounts: 183762,
      dataSent: 1976777,
      dataReceived: 19030322,
      callsIn: 267131,
      callsInDuration: 398710,
      callsOut: 245514,
      callsOutDuration: 319814,
      smses: 132365,
    },
    {
      date: "2019-02-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2143185,
      accounts: 193154,
      dataSent: 3131542,
      dataReceived: 32150932,
      callsIn: 187874,
      callsInDuration: 310197,
      callsOut: 180774,
      callsOutDuration: 243216,
      smses: 120825,
    },
    {
      date: "2019-02-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2101324,
      accounts: 186657,
      dataSent: 2546229,
      dataReceived: 25888937,
      callsIn: 323645,
      callsInDuration: 580683,
      callsOut: 282794,
      callsOutDuration: 477063,
      smses: 146420,
    },
    {
      date: "2019-02-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2116742,
      accounts: 186189,
      dataSent: 2566752,
      dataReceived: 26131286,
      callsIn: 325666,
      callsInDuration: 586095,
      callsOut: 280743,
      callsOutDuration: 472343,
      smses: 146138,
    },
    {
      date: "2019-02-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2128233,
      accounts: 186844,
      dataSent: 2607954,
      dataReceived: 26646189,
      callsIn: 325930,
      callsInDuration: 574547,
      callsOut: 283139,
      callsOutDuration: 471417,
      smses: 148643,
    },
    {
      date: "2019-02-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2177761,
      accounts: 188481,
      dataSent: 2613885,
      dataReceived: 27037903,
      callsIn: 332125,
      callsInDuration: 597322,
      callsOut: 288423,
      callsOutDuration: 485442,
      smses: 153513,
    },
    {
      date: "2019-02-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2247608,
      accounts: 190647,
      dataSent: 2843375,
      dataReceived: 29726765,
      callsIn: 359760,
      callsInDuration: 588230,
      callsOut: 316735,
      callsOutDuration: 488607,
      smses: 165752,
    },
    {
      date: "2019-02-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2079115,
      accounts: 185795,
      dataSent: 2676234,
      dataReceived: 27780308,
      callsIn: 265169,
      callsInDuration: 424875,
      callsOut: 246196,
      callsOutDuration: 343036,
      smses: 147133,
    },
    {
      date: "2019-02-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1939715,
      accounts: 180030,
      dataSent: 2532069,
      dataReceived: 25960276,
      callsIn: 184070,
      callsInDuration: 333247,
      callsOut: 178335,
      callsOutDuration: 264749,
      smses: 128646,
    },
    {
      date: "2019-02-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2185295,
      accounts: 190229,
      dataSent: 2758850,
      dataReceived: 28326370,
      callsIn: 326952,
      callsInDuration: 595407,
      callsOut: 285017,
      callsOutDuration: 500212,
      smses: 154678,
    },
    {
      date: "2019-02-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2123264,
      accounts: 188688,
      dataSent: 2654034,
      dataReceived: 27086598,
      callsIn: 327518,
      callsInDuration: 613210,
      callsOut: 283303,
      callsOutDuration: 492352,
      smses: 151868,
    },
    {
      date: "2019-02-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2149463,
      accounts: 189911,
      dataSent: 2682844,
      dataReceived: 27225011,
      callsIn: 336808,
      callsInDuration: 610764,
      callsOut: 290160,
      callsOutDuration: 502154,
      smses: 152142,
    },
    {
      date: "2019-02-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2177355,
      accounts: 191565,
      dataSent: 2582000,
      dataReceived: 26120645,
      callsIn: 353828,
      callsInDuration: 622953,
      callsOut: 309433,
      callsOutDuration: 513040,
      smses: 154981,
    },
    {
      date: "2019-03-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2399047,
      accounts: 199124,
      dataSent: 3032144,
      dataReceived: 30667204,
      callsIn: 386097,
      callsInDuration: 622390,
      callsOut: 347265,
      callsOutDuration: 532050,
      smses: 163221,
    },
    {
      date: "2019-03-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2169280,
      accounts: 192536,
      dataSent: 2894275,
      dataReceived: 28394961,
      callsIn: 290191,
      callsInDuration: 432453,
      callsOut: 276998,
      callsOutDuration: 359907,
      smses: 143843,
    },
    {
      date: "2019-03-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2004732,
      accounts: 184661,
      dataSent: 2666002,
      dataReceived: 26535743,
      callsIn: 210388,
      callsInDuration: 319284,
      callsOut: 205004,
      callsOutDuration: 263715,
      smses: 131581,
    },
    {
      date: "2019-03-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1920946,
      accounts: 180511,
      dataSent: 2427928,
      dataReceived: 24568978,
      callsIn: 209467,
      callsInDuration: 363207,
      callsOut: 197297,
      callsOutDuration: 288144,
      smses: 136661,
    },
    {
      date: "2019-03-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1908259,
      accounts: 177527,
      dataSent: 1867799,
      dataReceived: 18654932,
      callsIn: 217010,
      callsInDuration: 392409,
      callsOut: 198549,
      callsOutDuration: 304290,
      smses: 142689,
    },
    {
      date: "2019-03-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2501259,
      accounts: 208505,
      dataSent: 3104789,
      dataReceived: 31709152,
      callsIn: 337575,
      callsInDuration: 595422,
      callsOut: 306087,
      callsOutDuration: 513126,
      smses: 166870,
    },
    {
      date: "2019-03-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2215424,
      accounts: 194868,
      dataSent: 2546400,
      dataReceived: 25626476,
      callsIn: 375782,
      callsInDuration: 653227,
      callsOut: 332990,
      callsOutDuration: 548212,
      smses: 166549,
    },
    {
      date: "2019-03-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2496141,
      accounts: 201369,
      dataSent: 2906427,
      dataReceived: 28291825,
      callsIn: 400606,
      callsInDuration: 648238,
      callsOut: 359649,
      callsOutDuration: 557962,
      smses: 171471,
    },
    {
      date: "2019-03-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2163825,
      accounts: 192569,
      dataSent: 2661283,
      dataReceived: 26521283,
      callsIn: 290337,
      callsInDuration: 456542,
      callsOut: 270614,
      callsOutDuration: 373777,
      smses: 139270,
    },
    {
      date: "2019-03-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1979187,
      accounts: 184137,
      dataSent: 2428232,
      dataReceived: 23792020,
      callsIn: 199573,
      callsInDuration: 350637,
      callsOut: 190854,
      callsOutDuration: 277472,
      smses: 129186,
    },
    {
      date: "2019-03-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2412629,
      accounts: 201990,
      dataSent: 2875933,
      dataReceived: 28277480,
      callsIn: 372530,
      callsInDuration: 651367,
      callsOut: 330948,
      callsOutDuration: 558641,
      smses: 163674,
    },
    {
      date: "2019-03-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2361778,
      accounts: 200108,
      dataSent: 2730007,
      dataReceived: 26788670,
      callsIn: 357507,
      callsInDuration: 647287,
      callsOut: 314448,
      callsOutDuration: 544665,
      smses: 161816,
    },
    {
      date: "2019-03-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2653581,
      accounts: 208043,
      dataSent: 3871911,
      dataReceived: 26568753,
      callsIn: 394092,
      callsInDuration: 715783,
      callsOut: 342087,
      callsOutDuration: 596975,
      smses: 182655,
    },
    {
      date: "2019-03-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2493372,
      accounts: 205878,
      dataSent: 2824495,
      dataReceived: 25799822,
      callsIn: 371651,
      callsInDuration: 673827,
      callsOut: 323248,
      callsOutDuration: 553730,
      smses: 169007,
    },
    {
      date: "2019-03-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2618155,
      accounts: 209576,
      dataSent: 3006123,
      dataReceived: 29995525,
      callsIn: 398159,
      callsInDuration: 654100,
      callsOut: 349095,
      callsOutDuration: 546065,
      smses: 169754,
    },
    {
      date: "2019-03-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2367681,
      accounts: 204469,
      dataSent: 2935215,
      dataReceived: 29560402,
      callsIn: 297485,
      callsInDuration: 469128,
      callsOut: 272452,
      callsOutDuration: 374859,
      smses: 143757,
    },
    {
      date: "2019-03-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2100807,
      accounts: 192811,
      dataSent: 2557008,
      dataReceived: 25690609,
      callsIn: 202608,
      callsInDuration: 368967,
      callsOut: 192163,
      callsOutDuration: 288429,
      smses: 132399,
    },
    {
      date: "2019-03-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2467976,
      accounts: 206869,
      dataSent: 2723474,
      dataReceived: 26736502,
      callsIn: 372440,
      callsInDuration: 664328,
      callsOut: 323290,
      callsOutDuration: 557788,
      smses: 162688,
    },
    {
      date: "2019-03-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2457623,
      accounts: 206226,
      dataSent: 2275184,
      dataReceived: 21794204,
      callsIn: 360797,
      callsInDuration: 656845,
      callsOut: 308410,
      callsOutDuration: 536593,
      smses: 167378,
    },
    {
      date: "2019-03-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2855705,
      accounts: 225081,
      dataSent: 3602751,
      dataReceived: 36257626,
      callsIn: 366892,
      callsInDuration: 659582,
      callsOut: 315201,
      callsOutDuration: 541901,
      smses: 163243,
    },
    {
      date: "2019-03-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2510596,
      accounts: 210611,
      dataSent: 2772892,
      dataReceived: 27506642,
      callsIn: 370668,
      callsInDuration: 669272,
      callsOut: 318306,
      callsOutDuration: 541725,
      smses: 164984,
    },
    {
      date: "2019-03-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2595696,
      accounts: 213891,
      dataSent: 2870950,
      dataReceived: 28662401,
      callsIn: 400435,
      callsInDuration: 660114,
      callsOut: 346168,
      callsOutDuration: 543554,
      smses: 167085,
    },
    {
      date: "2019-03-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2442621,
      accounts: 210791,
      dataSent: 2983063,
      dataReceived: 29079848,
      callsIn: 314455,
      callsInDuration: 475320,
      callsOut: 285474,
      callsOutDuration: 379867,
      smses: 146519,
    },
    {
      date: "2019-03-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2123583,
      accounts: 197348,
      dataSent: 2583902,
      dataReceived: 25055967,
      callsIn: 204952,
      callsInDuration: 364826,
      callsOut: 197026,
      callsOutDuration: 282211,
      smses: 130693,
    },
    {
      date: "2019-03-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2468620,
      accounts: 211286,
      dataSent: 2793927,
      dataReceived: 27779581,
      callsIn: 363024,
      callsInDuration: 669706,
      callsOut: 313543,
      callsOutDuration: 550265,
      smses: 166737,
    },
    {
      date: "2019-03-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1776182,
      accounts: 210416,
      dataSent: 3115994,
      dataReceived: 31931252,
      callsIn: 365092,
      callsInDuration: 673303,
      callsOut: 308052,
      callsOutDuration: 543289,
      smses: 164058,
    },
    {
      date: "2019-03-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1660157,
      accounts: 208503,
      dataSent: 2940862,
      dataReceived: 29994812,
      callsIn: 373559,
      callsInDuration: 681623,
      callsOut: 313154,
      callsOutDuration: 549063,
      smses: 162914,
    },
    {
      date: "2019-03-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1672044,
      accounts: 208643,
      dataSent: 3150896,
      dataReceived: 31444061,
      callsIn: 383032,
      callsInDuration: 692005,
      callsOut: 326039,
      callsOutDuration: 562960,
      smses: 169027,
    },
    {
      date: "2019-03-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1680567,
      accounts: 206140,
      dataSent: 3070411,
      dataReceived: 30499550,
      callsIn: 417568,
      callsInDuration: 679319,
      callsOut: 361288,
      callsOutDuration: 567859,
      smses: 174351,
    },
    {
      date: "2019-03-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1548262,
      accounts: 198651,
      dataSent: 2784950,
      dataReceived: 27854262,
      callsIn: 326249,
      callsInDuration: 492773,
      callsOut: 297691,
      callsOutDuration: 401768,
      smses: 155106,
    },
    {
      date: "2019-03-31",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1425277,
      accounts: 189598,
      dataSent: 2472010,
      dataReceived: 24229407,
      callsIn: 220275,
      callsInDuration: 391538,
      callsOut: 212663,
      callsOutDuration: 303690,
      smses: 143677,
    },
    {
      date: "2019-04-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1742912,
      accounts: 211997,
      dataSent: 3118180,
      dataReceived: 30607695,
      callsIn: 408103,
      callsInDuration: 693907,
      callsOut: 353179,
      callsOutDuration: 586146,
      smses: 177793,
    },
    {
      date: "2019-04-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1372612,
      accounts: 195529,
      dataSent: 2637040,
      dataReceived: 27010253,
      callsIn: 246777,
      callsInDuration: 445121,
      callsOut: 220797,
      callsOutDuration: 349167,
      smses: 138495,
    },
    {
      date: "2019-04-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1714273,
      accounts: 210450,
      dataSent: 2977524,
      dataReceived: 29595272,
      callsIn: 401432,
      callsInDuration: 716179,
      callsOut: 345887,
      callsOutDuration: 598644,
      smses: 182514,
    },
    {
      date: "2019-04-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1708543,
      accounts: 210733,
      dataSent: 3271306,
      dataReceived: 32137052,
      callsIn: 403118,
      callsInDuration: 725991,
      callsOut: 347322,
      callsOutDuration: 595323,
      smses: 184450,
    },
    {
      date: "2019-04-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1738740,
      accounts: 213055,
      dataSent: 3163122,
      dataReceived: 30535006,
      callsIn: 435381,
      callsInDuration: 724868,
      callsOut: 381990,
      callsOutDuration: 605064,
      smses: 179907,
    },
    {
      date: "2019-04-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1493862,
      accounts: 203603,
      dataSent: 2933882,
      dataReceived: 28331598,
      callsIn: 341096,
      callsInDuration: 515333,
      callsOut: 312067,
      callsOutDuration: 413927,
      smses: 152816,
    },
    {
      date: "2019-04-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1357894,
      accounts: 193208,
      dataSent: 2587764,
      dataReceived: 25336084,
      callsIn: 226697,
      callsInDuration: 394344,
      callsOut: 217168,
      callsOutDuration: 307695,
      smses: 140656,
    },
    {
      date: "2019-04-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1713642,
      accounts: 214339,
      dataSent: 2813839,
      dataReceived: 27233508,
      callsIn: 383179,
      callsInDuration: 710583,
      callsOut: 340209,
      callsOutDuration: 601540,
      smses: 180947,
    },
    {
      date: "2019-04-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2006021,
      accounts: 228545,
      dataSent: 3716121,
      dataReceived: 36636172,
      callsIn: 400918,
      callsInDuration: 726876,
      callsOut: 347344,
      callsOutDuration: 608851,
      smses: 182800,
    },
    {
      date: "2019-04-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1826607,
      accounts: 216899,
      dataSent: 3093348,
      dataReceived: 30405772,
      callsIn: 411999,
      callsInDuration: 735485,
      callsOut: 351615,
      callsOutDuration: 602968,
      smses: 182893,
    },
    {
      date: "2019-04-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1834661,
      accounts: 217442,
      dataSent: 3113530,
      dataReceived: 30751719,
      callsIn: 402173,
      callsInDuration: 726254,
      callsOut: 347785,
      callsOutDuration: 604193,
      smses: 179320,
    },
    {
      date: "2019-04-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1878390,
      accounts: 219930,
      dataSent: 2899805,
      dataReceived: 27727270,
      callsIn: 439281,
      callsInDuration: 729247,
      callsOut: 384670,
      callsOutDuration: 602890,
      smses: 183681,
    },
    {
      date: "2019-04-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1213811,
      accounts: 223653,
      dataSent: 864454,
      dataReceived: 8195053,
      callsIn: 346119,
      callsInDuration: 525165,
      callsOut: 318014,
      callsOutDuration: 423763,
      smses: 173727,
    },
    {
      date: "2019-04-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1830245,
      accounts: 227201,
      dataSent: 97123,
      dataReceived: 817304,
      callsIn: 223887,
      callsInDuration: 406791,
      callsOut: 218306,
      callsOutDuration: 314042,
      smses: 152123,
    },
    {
      date: "2019-04-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2004442,
      accounts: 234641,
      dataSent: 107069,
      dataReceived: 857377,
      callsIn: 396445,
      callsInDuration: 724251,
      callsOut: 341127,
      callsOutDuration: 595117,
      smses: 197636,
    },
    {
      date: "2019-04-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2116036,
      accounts: 248043,
      dataSent: 4644238,
      dataReceived: 46845399,
      callsIn: 410867,
      callsInDuration: 738773,
      callsOut: 353587,
      callsOutDuration: 612696,
      smses: 176173,
    },
    {
      date: "2019-04-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1895559,
      accounts: 222101,
      dataSent: 3550217,
      dataReceived: 35696134,
      callsIn: 432462,
      callsInDuration: 749349,
      callsOut: 383042,
      callsOutDuration: 621307,
      smses: 186781,
    },
    {
      date: "2019-04-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1713804,
      accounts: 211820,
      dataSent: 3322366,
      dataReceived: 32726004,
      callsIn: 355416,
      callsInDuration: 582087,
      callsOut: 317668,
      callsOutDuration: 474789,
      smses: 163657,
    },
    {
      date: "2019-04-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1574357,
      accounts: 202098,
      dataSent: 3044566,
      dataReceived: 30181590,
      callsIn: 241498,
      callsInDuration: 421456,
      callsOut: 238831,
      callsOutDuration: 344149,
      smses: 149504,
    },
    {
      date: "2019-04-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1674390,
      accounts: 208895,
      dataSent: 3242337,
      dataReceived: 30976687,
      callsIn: 290283,
      callsInDuration: 466361,
      callsOut: 281892,
      callsOutDuration: 380742,
      smses: 153831,
    },
    {
      date: "2019-04-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1573373,
      accounts: 200691,
      dataSent: 2837168,
      dataReceived: 27714236,
      callsIn: 210241,
      callsInDuration: 393470,
      callsOut: 210460,
      callsOutDuration: 310444,
      smses: 151416,
    },
    {
      date: "2019-04-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1780987,
      accounts: 213674,
      dataSent: 2915127,
      dataReceived: 28585840,
      callsIn: 377373,
      callsInDuration: 716831,
      callsOut: 334827,
      callsOutDuration: 594845,
      smses: 183551,
    },
    {
      date: "2019-04-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1671181,
      accounts: 197611,
      dataSent: 2103957,
      dataReceived: 20048540,
      callsIn: 401269,
      callsInDuration: 741382,
      callsOut: 347314,
      callsOutDuration: 611851,
      smses: 181457,
    },
    {
      date: "2019-04-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2016586,
      accounts: 254072,
      dataSent: 4858677,
      dataReceived: 48467341,
      callsIn: 406265,
      callsInDuration: 752779,
      callsOut: 349470,
      callsOutDuration: 615980,
      smses: 183667,
    },
    {
      date: "2019-04-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1942968,
      accounts: 227671,
      dataSent: 3656885,
      dataReceived: 36005997,
      callsIn: 411259,
      callsInDuration: 760141,
      callsOut: 353309,
      callsOutDuration: 619158,
      smses: 185522,
    },
    {
      date: "2019-04-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1912311,
      accounts: 226547,
      dataSent: 3683678,
      dataReceived: 36249772,
      callsIn: 440005,
      callsInDuration: 755495,
      callsOut: 383891,
      callsOutDuration: 623084,
      smses: 190947,
    },
    {
      date: "2019-04-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1678568,
      accounts: 217590,
      dataSent: 3371186,
      dataReceived: 32759428,
      callsIn: 345030,
      callsInDuration: 557252,
      callsOut: 320115,
      callsOutDuration: 452126,
      smses: 157333,
    },
    {
      date: "2019-04-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1534098,
      accounts: 207740,
      dataSent: 3100717,
      dataReceived: 30587621,
      callsIn: 235669,
      callsInDuration: 436478,
      callsOut: 232078,
      callsOutDuration: 346688,
      smses: 147184,
    },
    {
      date: "2019-04-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1894172,
      accounts: 227896,
      dataSent: 3579896,
      dataReceived: 34649230,
      callsIn: 437661,
      callsInDuration: 782600,
      callsOut: 384552,
      callsOutDuration: 665501,
      smses: 196420,
    },
    {
      date: "2019-04-30",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 1698874,
      accounts: 219197,
      dataSent: 3173828,
      dataReceived: 32233684,
      callsIn: 404231,
      callsInDuration: 738717,
      callsOut: 360637,
      callsOutDuration: 601436,
      smses: 171710,
    },
    {
      date: "2019-05-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1505730,
      accounts: 210157,
      dataSent: 3163969,
      dataReceived: 32554376,
      callsIn: 225352,
      callsInDuration: 460860,
      callsOut: 220760,
      callsOutDuration: 361447,
      smses: 146845,
    },
    {
      date: "2019-05-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 1941912,
      accounts: 232064,
      dataSent: 3624987,
      dataReceived: 36314025,
      callsIn: 435066,
      callsInDuration: 770158,
      callsOut: 389088,
      callsOutDuration: 666588,
      smses: 197804,
    },
    {
      date: "2019-05-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2079491,
      accounts: 238397,
      dataSent: 4059852,
      dataReceived: 40271581,
      callsIn: 468408,
      callsInDuration: 774301,
      callsOut: 426052,
      callsOutDuration: 672566,
      smses: 198452,
    },
    {
      date: "2019-05-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1773060,
      accounts: 225466,
      dataSent: 3547667,
      dataReceived: 35622089,
      callsIn: 360596,
      callsInDuration: 556729,
      callsOut: 343349,
      callsOutDuration: 470760,
      smses: 169282,
    },
    {
      date: "2019-05-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1537952,
      accounts: 210208,
      dataSent: 2963918,
      dataReceived: 30835320,
      callsIn: 223236,
      callsInDuration: 437878,
      callsOut: 222525,
      callsOutDuration: 351237,
      smses: 151967,
    },
    {
      date: "2019-05-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2038072,
      accounts: 244077,
      dataSent: 4188054,
      dataReceived: 42377913,
      callsIn: 432084,
      callsInDuration: 791080,
      callsOut: 390143,
      callsOutDuration: 696444,
      smses: 201356,
    },
    {
      date: "2019-05-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2022591,
      accounts: 238413,
      dataSent: 3780060,
      dataReceived: 38275101,
      callsIn: 438116,
      callsInDuration: 807953,
      callsOut: 392101,
      callsOutDuration: 691255,
      smses: 204766,
    },
    {
      date: "2019-05-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2056093,
      accounts: 241790,
      dataSent: 3865179,
      dataReceived: 40189599,
      callsIn: 450884,
      callsInDuration: 824482,
      callsOut: 402250,
      callsOutDuration: 708837,
      smses: 200142,
    },
    {
      date: "2019-05-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2065855,
      accounts: 243341,
      dataSent: 3882211,
      dataReceived: 38956270,
      callsIn: 455292,
      callsInDuration: 818749,
      callsOut: 403327,
      callsOutDuration: 688597,
      smses: 205335,
    },
    {
      date: "2019-05-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2190427,
      accounts: 248973,
      dataSent: 3803671,
      dataReceived: 37391921,
      callsIn: 492319,
      callsInDuration: 813167,
      callsOut: 446560,
      callsOutDuration: 701571,
      smses: 207074,
    },
    {
      date: "2019-05-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2288982,
      accounts: 273875,
      dataSent: 4744494,
      dataReceived: 47863279,
      callsIn: 385779,
      callsInDuration: 570671,
      callsOut: 368095,
      callsOutDuration: 484412,
      smses: 172696,
    },
    {
      date: "2019-05-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1664182,
      accounts: 228048,
      dataSent: 3702329,
      dataReceived: 36847779,
      callsIn: 255678,
      callsInDuration: 459366,
      callsOut: 257735,
      callsOutDuration: 373412,
      smses: 167408,
    },
    {
      date: "2019-05-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2014409,
      accounts: 245590,
      dataSent: 3911175,
      dataReceived: 39590134,
      callsIn: 449913,
      callsInDuration: 823783,
      callsOut: 401130,
      callsOutDuration: 717234,
      smses: 205505,
    },
    {
      date: "2019-05-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2226198,
      accounts: 256781,
      dataSent: 4325516,
      dataReceived: 43955551,
      callsIn: 457012,
      callsInDuration: 832925,
      callsOut: 403718,
      callsOutDuration: 715422,
      smses: 208575,
    },
    {
      date: "2019-05-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2101442,
      accounts: 254069,
      dataSent: 4122690,
      dataReceived: 41069970,
      callsIn: 471877,
      callsInDuration: 857401,
      callsOut: 418291,
      callsOutDuration: 733705,
      smses: 207715,
    },
    {
      date: "2019-05-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2120337,
      accounts: 255049,
      dataSent: 4184978,
      dataReceived: 42837875,
      callsIn: 470850,
      callsInDuration: 849370,
      callsOut: 415780,
      callsOutDuration: 720265,
      smses: 204791,
    },
    {
      date: "2019-05-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2218825,
      accounts: 261465,
      dataSent: 4583815,
      dataReceived: 45655971,
      callsIn: 504729,
      callsInDuration: 843923,
      callsOut: 455250,
      callsOutDuration: 722461,
      smses: 210254,
    },
    {
      date: "2019-05-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1928359,
      accounts: 247727,
      dataSent: 4131993,
      dataReceived: 41038873,
      callsIn: 388115,
      callsInDuration: 606582,
      callsOut: 370131,
      callsOutDuration: 507899,
      smses: 181408,
    },
    {
      date: "2019-05-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1705624,
      accounts: 233900,
      dataSent: 3684120,
      dataReceived: 37625832,
      callsIn: 248175,
      callsInDuration: 472967,
      callsOut: 251197,
      callsOutDuration: 381101,
      smses: 165392,
    },
    {
      date: "2019-05-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2086424,
      accounts: 255519,
      dataSent: 3958203,
      dataReceived: 40239393,
      callsIn: 449772,
      callsInDuration: 842744,
      callsOut: 400430,
      callsOutDuration: 715549,
      smses: 209614,
    },
    {
      date: "2019-05-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2112682,
      accounts: 255204,
      dataSent: 3202414,
      dataReceived: 32519042,
      callsIn: 451995,
      callsInDuration: 858980,
      callsOut: 394166,
      callsOutDuration: 711562,
      smses: 207010,
    },
    {
      date: "2019-05-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2510484,
      accounts: 300081,
      dataSent: 5960056,
      dataReceived: 61260480,
      callsIn: 483229,
      callsInDuration: 868557,
      callsOut: 420022,
      callsOutDuration: 732878,
      smses: 206782,
    },
    {
      date: "2019-05-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2092471,
      accounts: 259521,
      dataSent: 4340936,
      dataReceived: 42339713,
      callsIn: 493945,
      callsInDuration: 879862,
      callsOut: 431021,
      callsOutDuration: 740053,
      smses: 205220,
    },
    {
      date: "2019-05-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2277551,
      accounts: 267950,
      dataSent: 4813968,
      dataReceived: 45531194,
      callsIn: 545380,
      callsInDuration: 878475,
      callsOut: 492123,
      callsOutDuration: 749433,
      smses: 212422,
    },
    {
      date: "2019-05-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 1886631,
      accounts: 248911,
      dataSent: 4058411,
      dataReceived: 39794358,
      callsIn: 361481,
      callsInDuration: 561591,
      callsOut: 353317,
      callsOutDuration: 472423,
      smses: 172508,
    },
    {
      date: "2019-05-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1738547,
      accounts: 239368,
      dataSent: 3710568,
      dataReceived: 37409344,
      callsIn: 254370,
      callsInDuration: 479986,
      callsOut: 259697,
      callsOutDuration: 393208,
      smses: 167272,
    },
    {
      date: "2019-05-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2236408,
      accounts: 266110,
      dataSent: 3962364,
      dataReceived: 39134262,
      callsIn: 474607,
      callsInDuration: 877747,
      callsOut: 420337,
      callsOutDuration: 754371,
      smses: 207161,
    },
    {
      date: "2019-05-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2361062,
      accounts: 280662,
      dataSent: 5038261,
      dataReceived: 50681708,
      callsIn: 510616,
      callsInDuration: 914267,
      callsOut: 450444,
      callsOutDuration: 773440,
      smses: 219703,
    },
    {
      date: "2019-05-29",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 1840115,
      accounts: 253214,
      dataSent: 3932833,
      dataReceived: 40377936,
      callsIn: 378670,
      callsInDuration: 784470,
      callsOut: 330486,
      callsOutDuration: 606358,
      smses: 181377,
    },
    {
      date: "2019-05-30",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2191466,
      accounts: 268882,
      dataSent: 4441348,
      dataReceived: 45544150,
      callsIn: 499274,
      callsInDuration: 900100,
      callsOut: 427261,
      callsOutDuration: 743007,
      smses: 220786,
    },
    {
      date: "2019-05-31",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2270771,
      accounts: 273715,
      dataSent: 4646131,
      dataReceived: 46335649,
      callsIn: 550810,
      callsInDuration: 915786,
      callsOut: 486484,
      callsOutDuration: 776346,
      smses: 225209,
    },
    {
      date: "2019-06-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2048905,
      accounts: 269575,
      dataSent: 4643771,
      dataReceived: 46768492,
      callsIn: 420879,
      callsInDuration: 651908,
      callsOut: 400862,
      callsOutDuration: 545886,
      smses: 191530,
    },
    {
      date: "2019-06-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1810734,
      accounts: 249716,
      dataSent: 3927195,
      dataReceived: 39539735,
      callsIn: 277991,
      callsInDuration: 514158,
      callsOut: 278888,
      callsOutDuration: 411397,
      smses: 180565,
    },
    {
      date: "2019-06-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2262425,
      accounts: 278241,
      dataSent: 4763769,
      dataReceived: 47543584,
      callsIn: 503850,
      callsInDuration: 932225,
      callsOut: 452069,
      callsOutDuration: 800558,
      smses: 224537,
    },
    {
      date: "2019-06-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2274214,
      accounts: 276942,
      dataSent: 4601736,
      dataReceived: 45812622,
      callsIn: 509596,
      callsInDuration: 942634,
      callsOut: 446970,
      callsOutDuration: 802278,
      smses: 223601,
    },
    {
      date: "2019-06-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2333447,
      accounts: 280297,
      dataSent: 4636405,
      dataReceived: 46084453,
      callsIn: 520617,
      callsInDuration: 959456,
      callsOut: 461260,
      callsOutDuration: 813301,
      smses: 226504,
    },
    {
      date: "2019-06-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2430815,
      accounts: 284935,
      dataSent: 4801927,
      dataReceived: 47324379,
      callsIn: 537227,
      callsInDuration: 978534,
      callsOut: 477126,
      callsOutDuration: 824708,
      smses: 229824,
    },
    {
      date: "2019-06-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2530883,
      accounts: 290474,
      dataSent: 5071114,
      dataReceived: 49766077,
      callsIn: 585771,
      callsInDuration: 961215,
      callsOut: 527580,
      callsOutDuration: 825753,
      smses: 239280,
    },
    {
      date: "2019-06-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2235996,
      accounts: 278588,
      dataSent: 4539609,
      dataReceived: 43903021,
      callsIn: 465542,
      callsInDuration: 700739,
      callsOut: 447347,
      callsOutDuration: 592252,
      smses: 201244,
    },
    {
      date: "2019-06-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 1996311,
      accounts: 265017,
      dataSent: 3956199,
      dataReceived: 39421807,
      callsIn: 318933,
      callsInDuration: 558310,
      callsOut: 324447,
      callsOutDuration: 450997,
      smses: 193359,
    },
    {
      date: "2019-06-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2442654,
      accounts: 295852,
      dataSent: 5133394,
      dataReceived: 52121068,
      callsIn: 526973,
      callsInDuration: 972420,
      callsOut: 469246,
      callsOutDuration: 829122,
      smses: 238303,
    },
    {
      date: "2019-06-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2430243,
      accounts: 289394,
      dataSent: 4590359,
      dataReceived: 46908318,
      callsIn: 532100,
      callsInDuration: 984645,
      callsOut: 468802,
      callsOutDuration: 835302,
      smses: 235955,
    },
    {
      date: "2019-06-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2580194,
      accounts: 302236,
      dataSent: 4973544,
      dataReceived: 51382556,
      callsIn: 510833,
      callsInDuration: 966874,
      callsOut: 455641,
      callsOutDuration: 812263,
      smses: 235223,
    },
    {
      date: "2019-06-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2532372,
      accounts: 295795,
      dataSent: 4684042,
      dataReceived: 48138137,
      callsIn: 546228,
      callsInDuration: 1007003,
      callsOut: 478184,
      callsOutDuration: 833452,
      smses: 243061,
    },
    {
      date: "2019-06-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2729617,
      accounts: 306877,
      dataSent: 5119232,
      dataReceived: 52912671,
      callsIn: 613082,
      callsInDuration: 1000265,
      callsOut: 548272,
      callsOutDuration: 853151,
      smses: 251306,
    },
    {
      date: "2019-06-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2249056,
      accounts: 284887,
      dataSent: 3895704,
      dataReceived: 40462968,
      callsIn: 453992,
      callsInDuration: 708397,
      callsOut: 440894,
      callsOutDuration: 599067,
      smses: 211455,
    },
    {
      date: "2019-06-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3724698,
      accounts: 333477,
      dataSent: 5767047,
      dataReceived: 60619432,
      callsIn: 448766,
      callsInDuration: 611777,
      callsOut: 499387,
      callsOutDuration: 598301,
      smses: 417466,
    },
    {
      date: "2019-06-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 1928518,
      accounts: 264886,
      dataSent: 3561335,
      dataReceived: 38716537,
      callsIn: 305240,
      callsInDuration: 621198,
      callsOut: 287183,
      callsOutDuration: 486061,
      smses: 208817,
    },
    {
      date: "2019-06-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2475703,
      accounts: 297146,
      dataSent: 3624283,
      dataReceived: 37244428,
      callsIn: 548691,
      callsInDuration: 987254,
      callsOut: 490294,
      callsOutDuration: 847463,
      smses: 254810,
    },
    {
      date: "2019-06-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2951978,
      accounts: 342134,
      dataSent: 6557769,
      dataReceived: 67525751,
      callsIn: 608179,
      callsInDuration: 998683,
      callsOut: 544929,
      callsOutDuration: 864229,
      smses: 257008,
    },
    {
      date: "2019-06-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2089109,
      accounts: 288218,
      dataSent: 4528847,
      dataReceived: 46617726,
      callsIn: 375751,
      callsInDuration: 703065,
      callsOut: 350776,
      callsOutDuration: 562285,
      smses: 200597,
    },
    {
      date: "2019-06-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2600210,
      accounts: 308510,
      dataSent: 5203315,
      dataReceived: 51608617,
      callsIn: 620660,
      callsInDuration: 1022215,
      callsOut: 557184,
      callsOutDuration: 883451,
      smses: 254140,
    },
    {
      date: "2019-06-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2304401,
      accounts: 294528,
      dataSent: 4652816,
      dataReceived: 46793507,
      callsIn: 470253,
      callsInDuration: 725976,
      callsOut: 442909,
      callsOutDuration: 600601,
      smses: 215774,
    },
    {
      date: "2019-06-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2005371,
      accounts: 276231,
      dataSent: 3891221,
      dataReceived: 41426114,
      callsIn: 290864,
      callsInDuration: 566511,
      callsOut: 296196,
      callsOutDuration: 451158,
      smses: 205687,
    },
    {
      date: "2019-06-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2582710,
      accounts: 312639,
      dataSent: 5073438,
      dataReceived: 52588937,
      callsIn: 560491,
      callsInDuration: 1042013,
      callsOut: 501556,
      callsOutDuration: 899291,
      smses: 258471,
    },
    {
      date: "2019-06-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2600725,
      accounts: 314732,
      dataSent: 5088273,
      dataReceived: 53267731,
      callsIn: 566125,
      callsInDuration: 1045946,
      callsOut: 494947,
      callsOutDuration: 886011,
      smses: 254323,
    },
    {
      date: "2019-06-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2541457,
      accounts: 310557,
      dataSent: 4633232,
      dataReceived: 48589306,
      callsIn: 583908,
      callsInDuration: 1076964,
      callsOut: 513433,
      callsOutDuration: 901578,
      smses: 246103,
    },
    {
      date: "2019-06-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2618421,
      accounts: 324867,
      dataSent: 5068133,
      dataReceived: 53274624,
      callsIn: 576831,
      callsInDuration: 1034612,
      callsOut: 507300,
      callsOutDuration: 876311,
      smses: 242472,
    },
    {
      date: "2019-06-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2566614,
      accounts: 324508,
      dataSent: 5355313,
      dataReceived: 56782809,
      callsIn: 560739,
      callsInDuration: 935893,
      callsOut: 508956,
      callsOutDuration: 803721,
      smses: 256718,
    },
    {
      date: "2019-06-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2323163,
      accounts: 302983,
      dataSent: 4540779,
      dataReceived: 46568290,
      callsIn: 483083,
      callsInDuration: 764539,
      callsOut: 463645,
      callsOutDuration: 638560,
      smses: 214989,
    },
    {
      date: "2019-06-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2070546,
      accounts: 286664,
      dataSent: 3752265,
      dataReceived: 38255409,
      callsIn: 308861,
      callsInDuration: 598193,
      callsOut: 315306,
      callsOutDuration: 477919,
      smses: 206318,
    },
    {
      date: "2019-07-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2772499,
      accounts: 335961,
      dataSent: 5115474,
      dataReceived: 52272589,
      callsIn: 581326,
      callsInDuration: 1074349,
      callsOut: 534973,
      callsOutDuration: 951836,
      smses: 253895,
    },
    {
      date: "2019-07-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2703971,
      accounts: 331161,
      dataSent: 4620845,
      dataReceived: 47451523,
      callsIn: 596549,
      callsInDuration: 1052565,
      callsOut: 535386,
      callsOutDuration: 913676,
      smses: 254564,
    },
    {
      date: "2019-07-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2952397,
      accounts: 350009,
      dataSent: 5747852,
      dataReceived: 55867278,
      callsIn: 677041,
      callsInDuration: 1286646,
      callsOut: 602375,
      callsOutDuration: 1087401,
      smses: 284241,
    },
    {
      date: "2019-07-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3003877,
      accounts: 338399,
      dataSent: 5676726,
      dataReceived: 57437457,
      callsIn: 629553,
      callsInDuration: 1119676,
      callsOut: 564478,
      callsOutDuration: 963435,
      smses: 257295,
    },
    {
      date: "2019-07-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2725880,
      accounts: 329815,
      dataSent: 5495388,
      dataReceived: 54359333,
      callsIn: 700247,
      callsInDuration: 1132384,
      callsOut: 645177,
      callsOutDuration: 998597,
      smses: 261934,
    },
    {
      date: "2019-07-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2423341,
      accounts: 315141,
      dataSent: 5057123,
      dataReceived: 51992010,
      callsIn: 525726,
      callsInDuration: 787761,
      callsOut: 518335,
      callsOutDuration: 691013,
      smses: 224455,
    },
    {
      date: "2019-07-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2177916,
      accounts: 299270,
      dataSent: 4663437,
      dataReceived: 48021332,
      callsIn: 349296,
      callsInDuration: 595312,
      callsOut: 362163,
      callsOutDuration: 496927,
      smses: 209603,
    },
    {
      date: "2019-07-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2236635,
      accounts: 309309,
      dataSent: 4676659,
      dataReceived: 48233160,
      callsIn: 490298,
      callsInDuration: 801635,
      callsOut: 439770,
      callsOutDuration: 656963,
      smses: 225258,
    },
    {
      date: "2019-07-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2180011,
      accounts: 297880,
      dataSent: 4466828,
      dataReceived: 46002108,
      callsIn: 367506,
      callsInDuration: 667122,
      callsOut: 350519,
      callsOutDuration: 535216,
      smses: 217021,
    },
    {
      date: "2019-07-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2772624,
      accounts: 331455,
      dataSent: 5450402,
      dataReceived: 55729005,
      callsIn: 648039,
      callsInDuration: 1132609,
      callsOut: 592539,
      callsOutDuration: 1013581,
      smses: 268278,
    },
    {
      date: "2019-07-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2736608,
      accounts: 331153,
      dataSent: 5430486,
      dataReceived: 55655597,
      callsIn: 663406,
      callsInDuration: 1162689,
      callsOut: 596351,
      callsOutDuration: 1016694,
      smses: 264222,
    },
    {
      date: "2019-07-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2822248,
      accounts: 334243,
      dataSent: 5643590,
      dataReceived: 57224587,
      callsIn: 677991,
      callsInDuration: 1144119,
      callsOut: 610108,
      callsOutDuration: 994041,
      smses: 260742,
    },
    {
      date: "2019-07-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2508347,
      accounts: 321990,
      dataSent: 5248864,
      dataReceived: 51866511,
      callsIn: 527113,
      callsInDuration: 828182,
      callsOut: 501869,
      callsOutDuration: 684274,
      smses: 227892,
    },
    {
      date: "2019-07-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2236138,
      accounts: 306851,
      dataSent: 4851800,
      dataReceived: 49100638,
      callsIn: 344731,
      callsInDuration: 629292,
      callsOut: 351469,
      callsOutDuration: 506655,
      smses: 216244,
    },
    {
      date: "2019-07-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2683942,
      accounts: 332384,
      dataSent: 5475534,
      dataReceived: 55053791,
      callsIn: 626482,
      callsInDuration: 1152535,
      callsOut: 562455,
      callsOutDuration: 998957,
      smses: 261926,
    },
    {
      date: "2019-07-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2698672,
      accounts: 334343,
      dataSent: 5472887,
      dataReceived: 55628245,
      callsIn: 636457,
      callsInDuration: 1161741,
      callsOut: 559309,
      callsOutDuration: 982755,
      smses: 258002,
    },
    {
      date: "2019-07-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2770422,
      accounts: 337430,
      dataSent: 5473449,
      dataReceived: 56285909,
      callsIn: 659704,
      callsInDuration: 1186215,
      callsOut: 576927,
      callsOutDuration: 1002346,
      smses: 260502,
    },
    {
      date: "2019-07-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2769763,
      accounts: 338235,
      dataSent: 5481618,
      dataReceived: 55905615,
      callsIn: 659892,
      callsInDuration: 1192638,
      callsOut: 573476,
      callsOutDuration: 994201,
      smses: 259630,
    },
    {
      date: "2019-07-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2927971,
      accounts: 346619,
      dataSent: 5825984,
      dataReceived: 58350736,
      callsIn: 721149,
      callsInDuration: 1178846,
      callsOut: 644413,
      callsOutDuration: 995772,
      smses: 272543,
    },
    {
      date: "2019-07-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2797208,
      accounts: 339318,
      dataSent: 5531360,
      dataReceived: 54467216,
      callsIn: 605929,
      callsInDuration: 895699,
      callsOut: 586176,
      callsOutDuration: 748779,
      smses: 260415,
    },
    {
      date: "2019-07-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2535826,
      accounts: 323460,
      dataSent: 4705769,
      dataReceived: 48934676,
      callsIn: 400156,
      callsInDuration: 679646,
      callsOut: 420170,
      callsOutDuration: 567572,
      smses: 253546,
    },
    {
      date: "2019-07-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2630888,
      accounts: 334071,
      dataSent: 3718364,
      dataReceived: 39019517,
      callsIn: 625908,
      callsInDuration: 1137513,
      callsOut: 545966,
      callsOutDuration: 970176,
      smses: 264691,
    },
    {
      date: "2019-07-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 2679839,
      accounts: 333826,
      dataSent: 3499999,
      dataReceived: 36837718,
      callsIn: 631735,
      callsInDuration: 1147543,
      callsOut: 542303,
      callsOutDuration: 960359,
      smses: 269515,
    },
    {
      date: "2019-07-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 3092105,
      accounts: 397945,
      dataSent: 7584858,
      dataReceived: 81254117,
      callsIn: 654923,
      callsInDuration: 1174876,
      callsOut: 570406,
      callsOutDuration: 987913,
      smses: 266876,
    },
    {
      date: "2019-07-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 2721901,
      accounts: 348022,
      dataSent: 5682884,
      dataReceived: 59947332,
      callsIn: 623780,
      callsInDuration: 1139758,
      callsOut: 539116,
      callsOutDuration: 945814,
      smses: 264470,
    },
    {
      date: "2019-07-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 2855708,
      accounts: 350061,
      dataSent: 5801919,
      dataReceived: 59326233,
      callsIn: 686537,
      callsInDuration: 1153758,
      callsOut: 599726,
      callsOutDuration: 987688,
      smses: 269679,
    },
    {
      date: "2019-07-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2667895,
      accounts: 341850,
      dataSent: 5510777,
      dataReceived: 55308689,
      callsIn: 545859,
      callsInDuration: 844857,
      callsOut: 520120,
      callsOutDuration: 700064,
      smses: 245259,
    },
    {
      date: "2019-07-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2415048,
      accounts: 326095,
      dataSent: 4973417,
      dataReceived: 50836105,
      callsIn: 360671,
      callsInDuration: 655602,
      callsOut: 364670,
      callsOutDuration: 535846,
      smses: 228176,
    },
    {
      date: "2019-07-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2744671,
      accounts: 346711,
      dataSent: 5018940,
      dataReceived: 51588626,
      callsIn: 648896,
      callsInDuration: 1197337,
      callsOut: 564409,
      callsOutDuration: 1014350,
      smses: 269280,
    },
    {
      date: "2019-07-30",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3442467,
      accounts: 360927,
      dataSent: 5406499,
      dataReceived: 56493814,
      callsIn: 664468,
      callsInDuration: 1210647,
      callsOut: 573541,
      callsOutDuration: 1012955,
      smses: 273716,
    },
    {
      date: "2019-07-31",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 2907973,
      accounts: 354469,
      dataSent: 3919524,
      dataReceived: 39778391,
      callsIn: 690589,
      callsInDuration: 1232201,
      callsOut: 595536,
      callsOutDuration: 1028029,
      smses: 270676,
    },
    {
      date: "2019-08-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3692526,
      accounts: 418325,
      dataSent: 8728550,
      dataReceived: 90093972,
      callsIn: 683978,
      callsInDuration: 1245610,
      callsOut: 601336,
      callsOutDuration: 1048339,
      smses: 272485,
    },
    {
      date: "2019-08-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 3017867,
      accounts: 365521,
      dataSent: 5932434,
      dataReceived: 59899662,
      callsIn: 743378,
      callsInDuration: 1215172,
      callsOut: 660035,
      callsOutDuration: 1051671,
      smses: 265756,
    },
    {
      date: "2019-08-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2844693,
      accounts: 362265,
      dataSent: 5985039,
      dataReceived: 58762516,
      callsIn: 582429,
      callsInDuration: 892803,
      callsOut: 556792,
      callsOutDuration: 756776,
      smses: 248193,
    },
    {
      date: "2019-08-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2596688,
      accounts: 341455,
      dataSent: 5465077,
      dataReceived: 55167659,
      callsIn: 389725,
      callsInDuration: 695725,
      callsOut: 397207,
      callsOutDuration: 563176,
      smses: 242582,
    },
    {
      date: "2019-08-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2965921,
      accounts: 365723,
      dataSent: 5879505,
      dataReceived: 60489337,
      callsIn: 701474,
      callsInDuration: 1285120,
      callsOut: 621541,
      callsOutDuration: 1119986,
      smses: 289151,
    },
    {
      date: "2019-08-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3062193,
      accounts: 371016,
      dataSent: 6134665,
      dataReceived: 63012930,
      callsIn: 717716,
      callsInDuration: 1294956,
      callsOut: 629150,
      callsOutDuration: 1117154,
      smses: 291849,
    },
    {
      date: "2019-08-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 3083556,
      accounts: 371571,
      dataSent: 6073279,
      dataReceived: 62194589,
      callsIn: 737786,
      callsInDuration: 1328992,
      callsOut: 643201,
      callsOutDuration: 1127298,
      smses: 297427,
    },
    {
      date: "2019-08-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3064580,
      accounts: 376562,
      dataSent: 6349918,
      dataReceived: 65055499,
      callsIn: 736927,
      callsInDuration: 1336874,
      callsOut: 638680,
      callsOutDuration: 1128259,
      smses: 289681,
    },
    {
      date: "2019-08-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 3097535,
      accounts: 376934,
      dataSent: 6288765,
      dataReceived: 63980419,
      callsIn: 779561,
      callsInDuration: 1287612,
      callsOut: 682889,
      callsOutDuration: 1100205,
      smses: 298788,
    },
    {
      date: "2019-08-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 2917054,
      accounts: 368295,
      dataSent: 5778876,
      dataReceived: 57216058,
      callsIn: 632094,
      callsInDuration: 948004,
      callsOut: 606017,
      callsOutDuration: 813276,
      smses: 261790,
    },
    {
      date: "2019-08-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2847532,
      accounts: 363403,
      dataSent: 5083993,
      dataReceived: 52041078,
      callsIn: 471507,
      callsInDuration: 770871,
      callsOut: 483200,
      callsOutDuration: 645748,
      smses: 264183,
    },
    {
      date: "2019-08-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 3230868,
      accounts: 399918,
      dataSent: 6541428,
      dataReceived: 67411491,
      callsIn: 722099,
      callsInDuration: 1373079,
      callsOut: 636337,
      callsOutDuration: 1178899,
      smses: 305908,
    },
    {
      date: "2019-08-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3226292,
      accounts: 399351,
      dataSent: 7074253,
      dataReceived: 73932626,
      callsIn: 733770,
      callsInDuration: 1372130,
      callsOut: 638801,
      callsOutDuration: 1155078,
      smses: 303552,
    },
    {
      date: "2019-08-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 3173262,
      accounts: 386786,
      dataSent: 6429207,
      dataReceived: 66020878,
      callsIn: 760660,
      callsInDuration: 1404230,
      callsOut: 656618,
      callsOutDuration: 1179747,
      smses: 298081,
    },
    {
      date: "2019-08-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3252572,
      accounts: 391904,
      dataSent: 6642593,
      dataReceived: 66858544,
      callsIn: 785795,
      callsInDuration: 1403336,
      callsOut: 682795,
      callsOutDuration: 1183451,
      smses: 300676,
    },
    {
      date: "2019-08-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 3433086,
      accounts: 399609,
      dataSent: 7157933,
      dataReceived: 71290262,
      callsIn: 853636,
      callsInDuration: 1375632,
      callsOut: 763507,
      callsOutDuration: 1176300,
      smses: 309864,
    },
    {
      date: "2019-08-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3008532,
      accounts: 379135,
      dataSent: 6217515,
      dataReceived: 61769150,
      callsIn: 615925,
      callsInDuration: 942681,
      callsOut: 602839,
      callsOutDuration: 799548,
      smses: 273378,
    },
    {
      date: "2019-08-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2909860,
      accounts: 369392,
      dataSent: 5914037,
      dataReceived: 59226466,
      callsIn: 441048,
      callsInDuration: 734890,
      callsOut: 451622,
      callsOutDuration: 610375,
      smses: 261539,
    },
    {
      date: "2019-08-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 2758782,
      accounts: 367576,
      dataSent: 5522890,
      dataReceived: 56806482,
      callsIn: 540413,
      callsInDuration: 988089,
      callsOut: 461333,
      callsOutDuration: 767008,
      smses: 265874,
    },
    {
      date: "2019-08-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3229608,
      accounts: 395966,
      dataSent: 5876017,
      dataReceived: 60027912,
      callsIn: 749102,
      callsInDuration: 1368042,
      callsOut: 656296,
      callsOutDuration: 1169493,
      smses: 317004,
    },
    {
      date: "2019-08-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4284219,
      accounts: 461740,
      dataSent: 6958011,
      dataReceived: 71841471,
      callsIn: 769871,
      callsInDuration: 1399479,
      callsOut: 668890,
      callsOutDuration: 1181066,
      smses: 319023,
    },
    {
      date: "2019-08-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3356254,
      accounts: 412925,
      dataSent: 5780673,
      dataReceived: 58771774,
      callsIn: 782298,
      callsInDuration: 1411003,
      callsOut: 666789,
      callsOutDuration: 1171674,
      smses: 309057,
    },
    {
      date: "2019-08-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 3852693,
      accounts: 456189,
      dataSent: 9205723,
      dataReceived: 94156087,
      callsIn: 846066,
      callsInDuration: 1391778,
      callsOut: 733047,
      callsOutDuration: 1184120,
      smses: 321300,
    },
    {
      date: "2019-08-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3161880,
      accounts: 395167,
      dataSent: 6615216,
      dataReceived: 64935373,
      callsIn: 650049,
      callsInDuration: 990635,
      callsOut: 606756,
      callsOutDuration: 822467,
      smses: 272064,
    },
    {
      date: "2019-08-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2840491,
      accounts: 376891,
      dataSent: 6114705,
      dataReceived: 61960403,
      callsIn: 420090,
      callsInDuration: 782497,
      callsOut: 425225,
      callsOutDuration: 615647,
      smses: 260782,
    },
    {
      date: "2019-08-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 3399884,
      accounts: 408307,
      dataSent: 6848593,
      dataReceived: 70617638,
      callsIn: 771578,
      callsInDuration: 1412254,
      callsOut: 658580,
      callsOutDuration: 1190723,
      smses: 316783,
    },
    {
      date: "2019-08-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3550695,
      accounts: 414342,
      dataSent: 6916357,
      dataReceived: 71951094,
      callsIn: 777473,
      callsInDuration: 1403187,
      callsOut: 653787,
      callsOutDuration: 1169391,
      smses: 321844,
    },
    {
      date: "2019-08-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 3606503,
      accounts: 415951,
      dataSent: 6876635,
      dataReceived: 71643375,
      callsIn: 812346,
      callsInDuration: 1450263,
      callsOut: 681947,
      callsOutDuration: 1201616,
      smses: 317576,
    },
    {
      date: "2019-08-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3588869,
      accounts: 419896,
      dataSent: 7022973,
      dataReceived: 72831271,
      callsIn: 795763,
      callsInDuration: 1438498,
      callsOut: 672092,
      callsOutDuration: 1192302,
      smses: 322656,
    },
    {
      date: "2019-08-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 3753892,
      accounts: 428959,
      dataSent: 7429971,
      dataReceived: 74464178,
      callsIn: 860479,
      callsInDuration: 1460725,
      callsOut: 755424,
      callsOutDuration: 1234296,
      smses: 329839,
    },
    {
      date: "2019-08-31",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3282453,
      accounts: 405988,
      dataSent: 6470261,
      dataReceived: 63381024,
      callsIn: 675480,
      callsInDuration: 1040582,
      callsOut: 641339,
      callsOutDuration: 875165,
      smses: 281596,
    },
    {
      date: "2019-09-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 2990474,
      accounts: 395722,
      dataSent: 6303542,
      dataReceived: 65203151,
      callsIn: 433799,
      callsInDuration: 811399,
      callsOut: 442446,
      callsOutDuration: 657148,
      smses: 267085,
    },
    {
      date: "2019-09-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 3411793,
      accounts: 418340,
      dataSent: 6525284,
      dataReceived: 66173126,
      callsIn: 767858,
      callsInDuration: 1453445,
      callsOut: 677919,
      callsOutDuration: 1247946,
      smses: 323817,
    },
    {
      date: "2019-09-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3613529,
      accounts: 431983,
      dataSent: 6892070,
      dataReceived: 70980959,
      callsIn: 793284,
      callsInDuration: 1480582,
      callsOut: 688311,
      callsOutDuration: 1246063,
      smses: 325602,
    },
    {
      date: "2019-09-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 3657760,
      accounts: 433895,
      dataSent: 6746566,
      dataReceived: 68844884,
      callsIn: 812213,
      callsInDuration: 1516533,
      callsOut: 703678,
      callsOutDuration: 1263699,
      smses: 335187,
    },
    {
      date: "2019-09-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 3721980,
      accounts: 442728,
      dataSent: 7057432,
      dataReceived: 70471159,
      callsIn: 823344,
      callsInDuration: 1522883,
      callsOut: 714493,
      callsOutDuration: 1274787,
      smses: 332869,
    },
    {
      date: "2019-09-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 3909195,
      accounts: 453930,
      dataSent: 8186553,
      dataReceived: 82585023,
      callsIn: 900953,
      callsInDuration: 1492765,
      callsOut: 800162,
      callsOutDuration: 1273506,
      smses: 346687,
    },
    {
      date: "2019-09-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3432688,
      accounts: 424219,
      dataSent: 7068732,
      dataReceived: 70183448,
      callsIn: 696872,
      callsInDuration: 1052417,
      callsOut: 669821,
      callsOutDuration: 886390,
      smses: 288769,
    },
    {
      date: "2019-09-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3101766,
      accounts: 404295,
      dataSent: 6382644,
      dataReceived: 63698706,
      callsIn: 459648,
      callsInDuration: 824806,
      callsOut: 466594,
      callsOutDuration: 663153,
      smses: 278624,
    },
    {
      date: "2019-09-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 3717041,
      accounts: 441300,
      dataSent: 7188911,
      dataReceived: 74594222,
      callsIn: 776778,
      callsInDuration: 1470927,
      callsOut: 685928,
      callsOutDuration: 1267175,
      smses: 347600,
    },
    {
      date: "2019-09-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3945044,
      accounts: 452442,
      dataSent: 7534517,
      dataReceived: 78203502,
      callsIn: 836546,
      callsInDuration: 1531525,
      callsOut: 739063,
      callsOutDuration: 1296925,
      smses: 348565,
    },
    {
      date: "2019-09-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 3744432,
      accounts: 446518,
      dataSent: 7033461,
      dataReceived: 72517732,
      callsIn: 828037,
      callsInDuration: 1526030,
      callsOut: 713747,
      callsOutDuration: 1267944,
      smses: 341461,
    },
    {
      date: "2019-09-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 4089397,
      accounts: 461153,
      dataSent: 7957307,
      dataReceived: 81405343,
      callsIn: 879495,
      callsInDuration: 1580086,
      callsOut: 762595,
      callsOutDuration: 1314590,
      smses: 352878,
    },
    {
      date: "2019-09-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 4085242,
      accounts: 464439,
      dataSent: 8225805,
      dataReceived: 83780539,
      callsIn: 929454,
      callsInDuration: 1518795,
      callsOut: 821267,
      callsOutDuration: 1287138,
      smses: 356032,
    },
    {
      date: "2019-09-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3612910,
      accounts: 437325,
      dataSent: 7365471,
      dataReceived: 72222115,
      callsIn: 728644,
      callsInDuration: 1086601,
      callsOut: 693160,
      callsOutDuration: 910595,
      smses: 308258,
    },
    {
      date: "2019-09-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3347270,
      accounts: 419679,
      dataSent: 6695487,
      dataReceived: 67110184,
      callsIn: 481596,
      callsInDuration: 833173,
      callsOut: 486193,
      callsOutDuration: 675065,
      smses: 295963,
    },
    {
      date: "2019-09-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 3855849,
      accounts: 453452,
      dataSent: 7153491,
      dataReceived: 73818801,
      callsIn: 830442,
      callsInDuration: 1541923,
      callsOut: 727815,
      callsOutDuration: 1314122,
      smses: 352367,
    },
    {
      date: "2019-09-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 3893299,
      accounts: 452078,
      dataSent: 6546629,
      dataReceived: 66568211,
      callsIn: 858037,
      callsInDuration: 1569342,
      callsOut: 741942,
      callsOutDuration: 1301920,
      smses: 347402,
    },
    {
      date: "2019-09-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 3970338,
      accounts: 449976,
      dataSent: 6237349,
      dataReceived: 63610053,
      callsIn: 891110,
      callsInDuration: 1600747,
      callsOut: 771964,
      callsOutDuration: 1329683,
      smses: 362166,
    },
    {
      date: "2019-09-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 4276944,
      accounts: 499493,
      dataSent: 8894858,
      dataReceived: 90527236,
      callsIn: 881613,
      callsInDuration: 1586149,
      callsOut: 755971,
      callsOutDuration: 1323333,
      smses: 352055,
    },
    {
      date: "2019-09-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 4332532,
      accounts: 478231,
      dataSent: 8018138,
      dataReceived: 78460889,
      callsIn: 982870,
      callsInDuration: 1575213,
      callsOut: 867118,
      callsOutDuration: 1342036,
      smses: 376848,
    },
    {
      date: "2019-09-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3823446,
      accounts: 447034,
      dataSent: 6832336,
      dataReceived: 66154887,
      callsIn: 768414,
      callsInDuration: 1101609,
      callsOut: 738556,
      callsOutDuration: 930335,
      smses: 343129,
    },
    {
      date: "2019-09-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3296052,
      accounts: 403570,
      dataSent: 4546211,
      dataReceived: 43911793,
      callsIn: 504737,
      callsInDuration: 858359,
      callsOut: 520444,
      callsOutDuration: 705094,
      smses: 316442,
    },
    {
      date: "2019-09-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4710595,
      accounts: 531649,
      dataSent: 7412872,
      dataReceived: 75915884,
      callsIn: 820826,
      callsInDuration: 1531851,
      callsOut: 727317,
      callsOutDuration: 1304128,
      smses: 368076,
    },
    {
      date: "2019-09-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 4835672,
      accounts: 523931,
      dataSent: 6899992,
      dataReceived: 69793944,
      callsIn: 877341,
      callsInDuration: 1587638,
      callsOut: 759174,
      callsOutDuration: 1332531,
      smses: 375976,
    },
    {
      date: "2019-09-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4662755,
      accounts: 535679,
      dataSent: 9371797,
      dataReceived: 95517711,
      callsIn: 899509,
      callsInDuration: 1611590,
      callsOut: 773958,
      callsOutDuration: 1331359,
      smses: 378524,
    },
    {
      date: "2019-09-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 5994736,
      accounts: 543173,
      dataSent: 4650880,
      dataReceived: 44754171,
      callsIn: 922909,
      callsInDuration: 1633511,
      callsOut: 784413,
      callsOutDuration: 1349746,
      smses: 397531,
    },
    {
      date: "2019-09-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5619721,
      accounts: 546014,
      dataSent: 10869179,
      dataReceived: 110397154,
      callsIn: 989516,
      callsInDuration: 1612733,
      callsOut: 852093,
      callsOutDuration: 1355826,
      smses: 388678,
    },
    {
      date: "2019-09-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3807575,
      accounts: 451708,
      dataSent: 7094887,
      dataReceived: 68885797,
      callsIn: 767338,
      callsInDuration: 1152958,
      callsOut: 715824,
      callsOutDuration: 949331,
      smses: 331683,
    },
    {
      date: "2019-09-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3353123,
      accounts: 407227,
      dataSent: 5318930,
      dataReceived: 51254603,
      callsIn: 519919,
      callsInDuration: 893443,
      callsOut: 519001,
      callsOutDuration: 716000,
      smses: 318659,
    },
    {
      date: "2019-09-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4351012,
      accounts: 495153,
      dataSent: 7687006,
      dataReceived: 77211132,
      callsIn: 882790,
      callsInDuration: 1615506,
      callsOut: 751225,
      callsOutDuration: 1362613,
      smses: 391007,
    },
    {
      date: "2019-10-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 4120319,
      accounts: 449625,
      dataSent: 5284519,
      dataReceived: 53129904,
      callsIn: 867740,
      callsInDuration: 1635088,
      callsOut: 755760,
      callsOutDuration: 1371275,
      smses: 381263,
    },
    {
      date: "2019-10-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4966013,
      accounts: 555836,
      dataSent: 11051540,
      dataReceived: 108344924,
      callsIn: 903856,
      callsInDuration: 1692352,
      callsOut: 784844,
      callsOutDuration: 1418817,
      smses: 391406,
    },
    {
      date: "2019-10-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 4566968,
      accounts: 502462,
      dataSent: 8545270,
      dataReceived: 81843308,
      callsIn: 917252,
      callsInDuration: 1690480,
      callsOut: 796136,
      callsOutDuration: 1405619,
      smses: 387016,
    },
    {
      date: "2019-10-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 4720650,
      accounts: 507311,
      dataSent: 8824294,
      dataReceived: 84145475,
      callsIn: 987270,
      callsInDuration: 1655216,
      callsOut: 862768,
      callsOutDuration: 1399049,
      smses: 399182,
    },
    {
      date: "2019-10-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 4440632,
      accounts: 493674,
      dataSent: 8180096,
      dataReceived: 75873627,
      callsIn: 813171,
      callsInDuration: 1195526,
      callsOut: 767289,
      callsOutDuration: 996703,
      smses: 346984,
    },
    {
      date: "2019-10-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4084706,
      accounts: 476609,
      dataSent: 7518651,
      dataReceived: 69681822,
      callsIn: 543829,
      callsInDuration: 919969,
      callsOut: 544852,
      callsOutDuration: 743302,
      smses: 335330,
    },
    {
      date: "2019-10-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4664605,
      accounts: 508377,
      dataSent: 7999570,
      dataReceived: 73278550,
      callsIn: 933902,
      callsInDuration: 1687663,
      callsOut: 815277,
      callsOutDuration: 1430242,
      smses: 399333,
    },
    {
      date: "2019-10-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5020493,
      accounts: 531991,
      dataSent: 9255731,
      dataReceived: 85189163,
      callsIn: 971652,
      callsInDuration: 1712504,
      callsOut: 829020,
      callsOutDuration: 1428705,
      smses: 407326,
    },
    {
      date: "2019-10-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4796743,
      accounts: 514727,
      dataSent: 7961319,
      dataReceived: 73434571,
      callsIn: 982403,
      callsInDuration: 1740377,
      callsOut: 844003,
      callsOutDuration: 1445926,
      smses: 407523,
    },
    {
      date: "2019-10-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 5439657,
      accounts: 551524,
      dataSent: 9410525,
      dataReceived: 86403514,
      callsIn: 1008860,
      callsInDuration: 1733015,
      callsOut: 863151,
      callsOutDuration: 1435448,
      smses: 418636,
    },
    {
      date: "2019-10-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5331345,
      accounts: 538756,
      dataSent: 9907855,
      dataReceived: 91097456,
      callsIn: 1066140,
      callsInDuration: 1698768,
      callsOut: 933186,
      callsOutDuration: 1419564,
      smses: 427105,
    },
    {
      date: "2019-10-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 4601769,
      accounts: 497241,
      dataSent: 8335880,
      dataReceived: 74562092,
      callsIn: 773500,
      callsInDuration: 1208644,
      callsOut: 744551,
      callsOutDuration: 1008094,
      smses: 369574,
    },
    {
      date: "2019-10-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4372970,
      accounts: 485634,
      dataSent: 8026060,
      dataReceived: 72028832,
      callsIn: 563807,
      callsInDuration: 903390,
      callsOut: 566663,
      callsOutDuration: 740936,
      smses: 352379,
    },
    {
      date: "2019-10-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 3775543,
      accounts: 472853,
      dataSent: 6835444,
      dataReceived: 64543226,
      callsIn: 688187,
      callsInDuration: 1233570,
      callsOut: 578759,
      callsOutDuration: 944993,
      smses: 360326,
    },
    {
      date: "2019-10-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 4501325,
      accounts: 518081,
      dataSent: 8283580,
      dataReceived: 77969889,
      callsIn: 931273,
      callsInDuration: 1716268,
      callsOut: 808024,
      callsOutDuration: 1425884,
      smses: 429578,
    },
    {
      date: "2019-10-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 5017950,
      accounts: 541976,
      dataSent: 9124956,
      dataReceived: 85855355,
      callsIn: 964370,
      callsInDuration: 1752388,
      callsOut: 825153,
      callsOutDuration: 1449911,
      smses: 427929,
    },
    {
      date: "2019-10-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 4707859,
      accounts: 521494,
      dataSent: 8070545,
      dataReceived: 75616925,
      callsIn: 969044,
      callsInDuration: 1771309,
      callsOut: 818644,
      callsOutDuration: 1427960,
      smses: 422291,
    },
    {
      date: "2019-10-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6178186,
      accounts: 576792,
      dataSent: 10410874,
      dataReceived: 94304742,
      callsIn: 1132056,
      callsInDuration: 1758792,
      callsOut: 984717,
      callsOutDuration: 1471478,
      smses: 444135,
    },
    {
      date: "2019-10-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 4716647,
      accounts: 527294,
      dataSent: 9000362,
      dataReceived: 80147065,
      callsIn: 928554,
      callsInDuration: 1289598,
      callsOut: 865657,
      callsOutDuration: 1075624,
      smses: 376548,
    },
    {
      date: "2019-10-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4301430,
      accounts: 501352,
      dataSent: 7705730,
      dataReceived: 71715849,
      callsIn: 576309,
      callsInDuration: 997051,
      callsOut: 591888,
      callsOutDuration: 887845,
      smses: 367111,
    },
    {
      date: "2019-10-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4747298,
      accounts: 530520,
      dataSent: 8032083,
      dataReceived: 74909338,
      callsIn: 938134,
      callsInDuration: 1713971,
      callsOut: 796252,
      callsOutDuration: 1395889,
      smses: 417134,
    },
    {
      date: "2019-10-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5561961,
      accounts: 565503,
      dataSent: 8723452,
      dataReceived: 82839663,
      callsIn: 962143,
      callsInDuration: 1672450,
      callsOut: 809140,
      callsOutDuration: 1359551,
      smses: 419754,
    },
    {
      date: "2019-10-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6008247,
      accounts: 575718,
      dataSent: 8637727,
      dataReceived: 80419278,
      callsIn: 1001751,
      callsInDuration: 1769571,
      callsOut: 843457,
      callsOutDuration: 1430099,
      smses: 434195,
    },
    {
      date: "2019-10-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6276390,
      accounts: 581174,
      dataSent: 8875276,
      dataReceived: 81628042,
      callsIn: 1026466,
      callsInDuration: 1782127,
      callsOut: 864648,
      callsOutDuration: 1445091,
      smses: 432669,
    },
    {
      date: "2019-10-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6445980,
      accounts: 583659,
      dataSent: 7779987,
      dataReceived: 70634146,
      callsIn: 1121480,
      callsInDuration: 1761872,
      callsOut: 968049,
      callsOutDuration: 1457896,
      smses: 446604,
    },
    {
      date: "2019-10-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 5174716,
      accounts: 583829,
      dataSent: 9749019,
      dataReceived: 90333991,
      callsIn: 838927,
      callsInDuration: 1228023,
      callsOut: 769564,
      callsOutDuration: 990887,
      smses: 359237,
    },
    {
      date: "2019-10-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4718710,
      accounts: 531753,
      dataSent: 7431557,
      dataReceived: 70320343,
      callsIn: 621751,
      callsInDuration: 982132,
      callsOut: 605370,
      callsOutDuration: 774420,
      smses: 372971,
    },
    {
      date: "2019-10-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 5564661,
      accounts: 564759,
      dataSent: 8621935,
      dataReceived: 81953554,
      callsIn: 910163,
      callsInDuration: 1739601,
      callsOut: 765103,
      callsOutDuration: 1403094,
      smses: 428590,
    },
    {
      date: "2019-10-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5367500,
      accounts: 559770,
      dataSent: 8688467,
      dataReceived: 80909177,
      callsIn: 973808,
      callsInDuration: 1770963,
      callsOut: 821109,
      callsOutDuration: 1423412,
      smses: 434026,
    },
    {
      date: "2019-10-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 5441230,
      accounts: 565094,
      dataSent: 8678539,
      dataReceived: 79334622,
      callsIn: 998012,
      callsInDuration: 1825828,
      callsOut: 831385,
      callsOutDuration: 1450713,
      smses: 424091,
    },
    {
      date: "2019-10-31",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 5632745,
      accounts: 571034,
      dataSent: 8729190,
      dataReceived: 78972830,
      callsIn: 1036779,
      callsInDuration: 1831127,
      callsOut: 881881,
      callsOutDuration: 1482087,
      smses: 427028,
    },
    {
      date: "2019-11-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5935546,
      accounts: 582618,
      dataSent: 10288381,
      dataReceived: 93692185,
      callsIn: 1100042,
      callsInDuration: 1800140,
      callsOut: 959584,
      callsOutDuration: 1478776,
      smses: 440191,
    },
    {
      date: "2019-11-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 4970100,
      accounts: 546755,
      dataSent: 8966407,
      dataReceived: 79979357,
      callsIn: 877113,
      callsInDuration: 1270704,
      callsOut: 820906,
      callsOutDuration: 1029550,
      smses: 379161,
    },
    {
      date: "2019-11-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4336950,
      accounts: 515422,
      dataSent: 7748606,
      dataReceived: 69277718,
      callsIn: 588847,
      callsInDuration: 1001575,
      callsOut: 574690,
      callsOutDuration: 770621,
      smses: 357747,
    },
    {
      date: "2019-11-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 5197530,
      accounts: 558929,
      dataSent: 8492921,
      dataReceived: 77303086,
      callsIn: 968141,
      callsInDuration: 1812290,
      callsOut: 835092,
      callsOutDuration: 1513382,
      smses: 433035,
    },
    {
      date: "2019-11-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5853285,
      accounts: 581165,
      dataSent: 8992467,
      dataReceived: 80243675,
      callsIn: 1035287,
      callsInDuration: 1866162,
      callsOut: 885815,
      callsOutDuration: 1561694,
      smses: 434268,
    },
    {
      date: "2019-11-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 5870072,
      accounts: 583581,
      dataSent: 7853482,
      dataReceived: 70310707,
      callsIn: 1044175,
      callsInDuration: 1867715,
      callsOut: 893477,
      callsOutDuration: 1527847,
      smses: 434511,
    },
    {
      date: "2019-11-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6759960,
      accounts: 602334,
      dataSent: 8670683,
      dataReceived: 76918261,
      callsIn: 1085475,
      callsInDuration: 1876810,
      callsOut: 937231,
      callsOutDuration: 1552784,
      smses: 444543,
    },
    {
      date: "2019-11-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6980630,
      accounts: 609333,
      dataSent: 10474970,
      dataReceived: 91454486,
      callsIn: 1163634,
      callsInDuration: 1825544,
      callsOut: 1011476,
      callsOutDuration: 1532948,
      smses: 443859,
    },
    {
      date: "2019-11-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 4224808,
      accounts: 576514,
      dataSent: 3478987,
      dataReceived: 30076866,
      callsIn: 905091,
      callsInDuration: 1306143,
      callsOut: 828682,
      callsOutDuration: 1061313,
      smses: 430127,
    },
    {
      date: "2019-11-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3320405,
      accounts: 572655,
      dataSent: 7034586,
      dataReceived: 62883895,
      callsIn: 601861,
      callsInDuration: 997604,
      callsOut: 580890,
      callsOutDuration: 782339,
      smses: 361916,
    },
    {
      date: "2019-11-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 6021240,
      accounts: 590727,
      dataSent: 9020048,
      dataReceived: 82289831,
      callsIn: 1040618,
      callsInDuration: 1850908,
      callsOut: 888979,
      callsOutDuration: 1539251,
      smses: 444101,
    },
    {
      date: "2019-11-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 6306629,
      accounts: 597018,
      dataSent: 7721179,
      dataReceived: 70691143,
      callsIn: 1042958,
      callsInDuration: 1862424,
      callsOut: 886307,
      callsOutDuration: 1521609,
      smses: 442400,
    },
    {
      date: "2019-11-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 5350779,
      accounts: 608085,
      dataSent: 9011613,
      dataReceived: 82952152,
      callsIn: 1065599,
      callsInDuration: 1866353,
      callsOut: 901686,
      callsOutDuration: 1519948,
      smses: 444919,
    },
    {
      date: "2019-11-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 5111559,
      accounts: 608449,
      dataSent: 10837035,
      dataReceived: 98402740,
      callsIn: 1105895,
      callsInDuration: 1872720,
      callsOut: 941024,
      callsOutDuration: 1530221,
      smses: 437575,
    },
    {
      date: "2019-11-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5056267,
      accounts: 587194,
      dataSent: 9772636,
      dataReceived: 88285541,
      callsIn: 1190640,
      callsInDuration: 1822909,
      callsOut: 1027726,
      callsOutDuration: 1519947,
      smses: 453134,
    },
    {
      date: "2019-11-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3797738,
      accounts: 523450,
      dataSent: 7901588,
      dataReceived: 69958648,
      callsIn: 874875,
      callsInDuration: 1290679,
      callsOut: 811644,
      callsOutDuration: 1049009,
      smses: 387526,
    },
    {
      date: "2019-11-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3385440,
      accounts: 470223,
      dataSent: 5600074,
      dataReceived: 48877086,
      callsIn: 635007,
      callsInDuration: 960481,
      callsOut: 625769,
      callsOutDuration: 780148,
      smses: 367671,
    },
    {
      date: "2019-11-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4531691,
      accounts: 571894,
      dataSent: 6426214,
      dataReceived: 60518379,
      callsIn: 632847,
      callsInDuration: 1111688,
      callsOut: 565401,
      callsOutDuration: 843423,
      smses: 414815,
    },
    {
      date: "2019-11-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5016666,
      accounts: 599560,
      dataSent: 10812544,
      dataReceived: 99960648,
      callsIn: 1064200,
      callsInDuration: 1841329,
      callsOut: 907630,
      callsOutDuration: 1538768,
      smses: 444306,
    },
    {
      date: "2019-11-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4366767,
      accounts: 560223,
      dataSent: 8786857,
      dataReceived: 80455893,
      callsIn: 1100623,
      callsInDuration: 1878691,
      callsOut: 923427,
      callsOutDuration: 1549383,
      smses: 449716,
    },
    {
      date: "2019-11-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 4520520,
      accounts: 570594,
      dataSent: 8820175,
      dataReceived: 80644561,
      callsIn: 1105080,
      callsInDuration: 1913499,
      callsOut: 915046,
      callsOutDuration: 1538293,
      smses: 445229,
    },
    {
      date: "2019-11-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5117230,
      accounts: 590483,
      dataSent: 10319519,
      dataReceived: 93301649,
      callsIn: 1167276,
      callsInDuration: 1882823,
      callsOut: 985363,
      callsOutDuration: 1525820,
      smses: 459046,
    },
    {
      date: "2019-11-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 3911024,
      accounts: 543337,
      dataSent: 8875179,
      dataReceived: 81561061,
      callsIn: 874241,
      callsInDuration: 1312796,
      callsOut: 786490,
      callsOutDuration: 1052706,
      smses: 387612,
    },
    {
      date: "2019-11-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3367989,
      accounts: 505444,
      dataSent: 7434670,
      dataReceived: 69211141,
      callsIn: 573258,
      callsInDuration: 1031457,
      callsOut: 544388,
      callsOutDuration: 795198,
      smses: 359921,
    },
    {
      date: "2019-11-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4061827,
      accounts: 548718,
      dataSent: 7702340,
      dataReceived: 72805871,
      callsIn: 1018976,
      callsInDuration: 1890724,
      callsOut: 841925,
      callsOutDuration: 1529383,
      smses: 442675,
    },
    {
      date: "2019-11-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 4612328,
      accounts: 587982,
      dataSent: 9603079,
      dataReceived: 88624811,
      callsIn: 1060139,
      callsInDuration: 1937027,
      callsOut: 870775,
      callsOutDuration: 1561999,
      smses: 445743,
    },
    {
      date: "2019-11-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4626180,
      accounts: 580661,
      dataSent: 8873630,
      dataReceived: 80505371,
      callsIn: 1100361,
      callsInDuration: 1956822,
      callsOut: 913194,
      callsOutDuration: 1591271,
      smses: 442068,
    },
    {
      date: "2019-11-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 4747396,
      accounts: 586008,
      dataSent: 9329917,
      dataReceived: 82288566,
      callsIn: 1130374,
      callsInDuration: 1949174,
      callsOut: 940499,
      callsOutDuration: 1587563,
      smses: 450720,
    },
    {
      date: "2019-11-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5119318,
      accounts: 595963,
      dataSent: 10238776,
      dataReceived: 90294048,
      callsIn: 1267450,
      callsInDuration: 1933048,
      callsOut: 1087628,
      callsOutDuration: 1640676,
      smses: 475877,
    },
    {
      date: "2019-11-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 4126054,
      accounts: 558634,
      dataSent: 9184751,
      dataReceived: 81634468,
      callsIn: 941421,
      callsInDuration: 1374358,
      callsOut: 866753,
      callsOutDuration: 1129847,
      smses: 404181,
    },
    {
      date: "2019-12-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3561716,
      accounts: 524432,
      dataSent: 7766493,
      dataReceived: 69110128,
      callsIn: 615415,
      callsInDuration: 1057665,
      callsOut: 603288,
      callsOutDuration: 843814,
      smses: 371622,
    },
    {
      date: "2019-12-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4366541,
      accounts: 573391,
      dataSent: 7065717,
      dataReceived: 64116142,
      callsIn: 1093937,
      callsInDuration: 1957903,
      callsOut: 944887,
      callsOutDuration: 1681082,
      smses: 447900,
    },
    {
      date: "2019-12-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5389608,
      accounts: 617092,
      dataSent: 10374672,
      dataReceived: 96215513,
      callsIn: 1091938,
      callsInDuration: 1972239,
      callsOut: 926434,
      callsOutDuration: 1648714,
      smses: 438655,
    },
    {
      date: "2019-12-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4777505,
      accounts: 594044,
      dataSent: 7661732,
      dataReceived: 70107647,
      callsIn: 1120309,
      callsInDuration: 2001699,
      callsOut: 946338,
      callsOutDuration: 1660392,
      smses: 444123,
    },
    {
      date: "2019-12-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 5506664,
      accounts: 624976,
      dataSent: 10488289,
      dataReceived: 92731605,
      callsIn: 1191777,
      callsInDuration: 2019224,
      callsOut: 1018642,
      callsOutDuration: 1692168,
      smses: 457471,
    },
    {
      date: "2019-12-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5401929,
      accounts: 610603,
      dataSent: 10500613,
      dataReceived: 91496485,
      callsIn: 1266154,
      callsInDuration: 1932385,
      callsOut: 1105054,
      callsOutDuration: 1653198,
      smses: 466683,
    },
    {
      date: "2019-12-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 4078244,
      accounts: 562357,
      dataSent: 9509704,
      dataReceived: 82613680,
      callsIn: 979409,
      callsInDuration: 1401387,
      callsOut: 903364,
      callsOutDuration: 1161956,
      smses: 397632,
    },
    {
      date: "2019-12-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3508075,
      accounts: 522020,
      dataSent: 7549638,
      dataReceived: 66863694,
      callsIn: 654378,
      callsInDuration: 1051435,
      callsOut: 642418,
      callsOutDuration: 853135,
      smses: 382700,
    },
    {
      date: "2019-12-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4512250,
      accounts: 583969,
      dataSent: 9017760,
      dataReceived: 78749176,
      callsIn: 1143542,
      callsInDuration: 1944087,
      callsOut: 989656,
      callsOutDuration: 1668904,
      smses: 456557,
    },
    {
      date: "2019-12-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 4543576,
      accounts: 579245,
      dataSent: 8432299,
      dataReceived: 73544162,
      callsIn: 1166129,
      callsInDuration: 1896514,
      callsOut: 1000138,
      callsOutDuration: 1625520,
      smses: 467210,
    },
    {
      date: "2019-12-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6875350,
      accounts: 624403,
      dataSent: 9528478,
      dataReceived: 84691693,
      callsIn: 1197319,
      callsInDuration: 1982262,
      callsOut: 1033601,
      callsOutDuration: 1687715,
      smses: 477056,
    },
    {
      date: "2019-12-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 8428465,
      accounts: 629857,
      dataSent: 10699520,
      dataReceived: 91320397,
      callsIn: 1198438,
      callsInDuration: 1987641,
      callsOut: 1033789,
      callsOutDuration: 1687152,
      smses: 472163,
    },
    {
      date: "2019-12-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 7633545,
      accounts: 629345,
      dataSent: 11440748,
      dataReceived: 96322364,
      callsIn: 1253372,
      callsInDuration: 1951828,
      callsOut: 1096116,
      callsOutDuration: 1664689,
      smses: 457871,
    },
    {
      date: "2019-12-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 5678057,
      accounts: 597598,
      dataSent: 10627237,
      dataReceived: 89401416,
      callsIn: 969744,
      callsInDuration: 1409240,
      callsOut: 896640,
      callsOutDuration: 1165880,
      smses: 404755,
    },
    {
      date: "2019-12-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4598246,
      accounts: 545585,
      dataSent: 8424066,
      dataReceived: 72227684,
      callsIn: 619194,
      callsInDuration: 1072220,
      callsOut: 606346,
      callsOutDuration: 872652,
      smses: 372341,
    },
    {
      date: "2019-12-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 5944359,
      accounts: 604291,
      dataSent: 9307092,
      dataReceived: 81986743,
      callsIn: 1149569,
      callsInDuration: 2024124,
      callsOut: 995644,
      callsOutDuration: 1734364,
      smses: 451747,
    },
    {
      date: "2019-12-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 6189257,
      accounts: 608602,
      dataSent: 7621532,
      dataReceived: 66932748,
      callsIn: 1172803,
      callsInDuration: 2018618,
      callsOut: 1003712,
      callsOutDuration: 1708141,
      smses: 449323,
    },
    {
      date: "2019-12-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 7299863,
      accounts: 627308,
      dataSent: 11260962,
      dataReceived: 100051249,
      callsIn: 1205371,
      callsInDuration: 1991786,
      callsOut: 1038430,
      callsOutDuration: 1712780,
      smses: 459101,
    },
    {
      date: "2019-12-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 7137506,
      accounts: 623388,
      dataSent: 9277604,
      dataReceived: 80962676,
      callsIn: 1265364,
      callsInDuration: 2040582,
      callsOut: 1103990,
      callsOutDuration: 1764919,
      smses: 460657,
    },
    {
      date: "2019-12-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 7473544,
      accounts: 629827,
      dataSent: 10922667,
      dataReceived: 95027270,
      callsIn: 1324512,
      callsInDuration: 2031445,
      callsOut: 1168038,
      callsOutDuration: 1764098,
      smses: 471679,
    },
    {
      date: "2019-12-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 6215049,
      accounts: 613488,
      dataSent: 10350853,
      dataReceived: 88657411,
      callsIn: 1062586,
      callsInDuration: 1483835,
      callsOut: 1001903,
      callsOutDuration: 1265206,
      smses: 408468,
    },
    {
      date: "2019-12-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 5043120,
      accounts: 573205,
      dataSent: 8571729,
      dataReceived: 74391232,
      callsIn: 720682,
      callsInDuration: 1130097,
      callsOut: 715915,
      callsOutDuration: 947638,
      smses: 370714,
    },
    {
      date: "2019-12-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 7064293,
      accounts: 623461,
      dataSent: 10230820,
      dataReceived: 90497982,
      callsIn: 1389826,
      callsInDuration: 2031629,
      callsOut: 1258138,
      callsOutDuration: 1826390,
      smses: 466109,
    },
    {
      date: "2019-12-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5355994,
      accounts: 586349,
      dataSent: 9135060,
      dataReceived: 80175714,
      callsIn: 1058413,
      callsInDuration: 1453414,
      callsOut: 982849,
      callsOutDuration: 1270746,
      smses: 384542,
    },
    {
      date: "2019-12-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4910859,
      accounts: 550621,
      dataSent: 8500237,
      dataReceived: 79215430,
      callsIn: 657551,
      callsInDuration: 1073355,
      callsOut: 645334,
      callsOutDuration: 904338,
      smses: 392014,
    },
    {
      date: "2019-12-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 5434602,
      accounts: 590967,
      dataSent: 9239929,
      dataReceived: 85504332,
      callsIn: 1082646,
      callsInDuration: 1813869,
      callsOut: 932641,
      callsOutDuration: 1576351,
      smses: 427403,
    },
    {
      date: "2019-12-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5912267,
      accounts: 601784,
      dataSent: 9804029,
      dataReceived: 89063309,
      callsIn: 1197720,
      callsInDuration: 1823922,
      callsOut: 1037788,
      callsOutDuration: 1590549,
      smses: 461541,
    },
    {
      date: "2019-12-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 5395946,
      accounts: 584572,
      dataSent: 9369344,
      dataReceived: 84810291,
      callsIn: 904544,
      callsInDuration: 1357612,
      callsOut: 831948,
      callsOutDuration: 1129183,
      smses: 402761,
    },
    {
      date: "2019-12-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4840505,
      accounts: 558240,
      dataSent: 7943616,
      dataReceived: 73395745,
      callsIn: 623349,
      callsInDuration: 1057360,
      callsOut: 608654,
      callsOutDuration: 861582,
      smses: 378051,
    },
    {
      date: "2019-12-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 5954772,
      accounts: 597518,
      dataSent: 9593572,
      dataReceived: 88513548,
      callsIn: 1146771,
      callsInDuration: 1857846,
      callsOut: 1023846,
      callsOutDuration: 1654540,
      smses: 447467,
    },
    {
      date: "2019-12-31",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5772543,
      accounts: 593543,
      dataSent: 8891825,
      dataReceived: 77009056,
      callsIn: 943084,
      callsInDuration: 1378333,
      callsOut: 875495,
      callsOutDuration: 1195665,
      smses: 388989,
    },
    {
      date: "2020-01-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 5947317,
      accounts: 584427,
      dataSent: 9952350,
      dataReceived: 88893243,
      callsIn: 704367,
      callsInDuration: 1066766,
      callsOut: 690163,
      callsOutDuration: 913829,
      smses: 421855,
    },
    {
      date: "2020-01-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6104768,
      accounts: 599986,
      dataSent: 9554966,
      dataReceived: 88033222,
      callsIn: 993888,
      callsInDuration: 1714613,
      callsOut: 884499,
      callsOutDuration: 1541689,
      smses: 417303,
    },
    {
      date: "2020-01-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 5726849,
      accounts: 593988,
      dataSent: 10234774,
      dataReceived: 92589897,
      callsIn: 1070687,
      callsInDuration: 1734563,
      callsOut: 958129,
      callsOutDuration: 1556159,
      smses: 431441,
    },
    {
      date: "2020-01-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 5101997,
      accounts: 569971,
      dataSent: 9488066,
      dataReceived: 85077076,
      callsIn: 838280,
      callsInDuration: 1265287,
      callsOut: 780574,
      callsOutDuration: 1063318,
      smses: 395929,
    },
    {
      date: "2020-01-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4609225,
      accounts: 539904,
      dataSent: 8372526,
      dataReceived: 75161552,
      callsIn: 589198,
      callsInDuration: 1004346,
      callsOut: 569765,
      callsOutDuration: 821528,
      smses: 387563,
    },
    {
      date: "2020-01-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 5376417,
      accounts: 584481,
      dataSent: 9443213,
      dataReceived: 84401681,
      callsIn: 1023122,
      callsInDuration: 1818090,
      callsOut: 921790,
      callsOutDuration: 1646108,
      smses: 439591,
    },
    {
      date: "2020-01-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5010407,
      accounts: 581929,
      dataSent: 6363953,
      dataReceived: 55837886,
      callsIn: 687940,
      callsInDuration: 1110588,
      callsOut: 596100,
      callsOutDuration: 1013383,
      smses: 423021,
    },
    {
      date: "2020-01-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4756354,
      accounts: 611601,
      dataSent: 9019402,
      dataReceived: 84251388,
      callsIn: 998476,
      callsInDuration: 1708229,
      callsOut: 874841,
      callsOutDuration: 1514816,
      smses: 444883,
    },
    {
      date: "2020-01-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 5544828,
      accounts: 593434,
      dataSent: 9642298,
      dataReceived: 88719396,
      callsIn: 1026111,
      callsInDuration: 1800595,
      callsOut: 905348,
      callsOutDuration: 1571417,
      smses: 419597,
    },
    {
      date: "2020-01-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6109281,
      accounts: 608451,
      dataSent: 10327691,
      dataReceived: 93789336,
      callsIn: 1102879,
      callsInDuration: 1788024,
      callsOut: 973936,
      callsOutDuration: 1569847,
      smses: 434244,
    },
    {
      date: "2020-01-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 5714274,
      accounts: 599536,
      dataSent: 9923711,
      dataReceived: 87910499,
      callsIn: 853262,
      callsInDuration: 1265282,
      callsOut: 790365,
      callsOutDuration: 1064225,
      smses: 398358,
    },
    {
      date: "2020-01-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 5232624,
      accounts: 578894,
      dataSent: 8632178,
      dataReceived: 77907623,
      callsIn: 589013,
      callsInDuration: 986828,
      callsOut: 573071,
      callsOutDuration: 812329,
      smses: 383653,
    },
    {
      date: "2020-01-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 6099229,
      accounts: 606040,
      dataSent: 9642253,
      dataReceived: 89924396,
      callsIn: 1019380,
      callsInDuration: 1793439,
      callsOut: 886885,
      callsOutDuration: 1578555,
      smses: 435380,
    },
    {
      date: "2020-01-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5869963,
      accounts: 602228,
      dataSent: 9359849,
      dataReceived: 86389872,
      callsIn: 1015760,
      callsInDuration: 1794476,
      callsOut: 868021,
      callsOutDuration: 1533553,
      smses: 432545,
    },
    {
      date: "2020-01-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6440242,
      accounts: 610659,
      dataSent: 9373697,
      dataReceived: 86824031,
      callsIn: 1041856,
      callsInDuration: 1834266,
      callsOut: 894455,
      callsOutDuration: 1580909,
      smses: 454550,
    },
    {
      date: "2020-01-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 6555428,
      accounts: 614708,
      dataSent: 9983991,
      dataReceived: 90488747,
      callsIn: 1057726,
      callsInDuration: 1850872,
      callsOut: 904503,
      callsOutDuration: 1574621,
      smses: 431092,
    },
    {
      date: "2020-01-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6191686,
      accounts: 612202,
      dataSent: 10741711,
      dataReceived: 96888215,
      callsIn: 1100609,
      callsInDuration: 1808220,
      callsOut: 951445,
      callsOutDuration: 1549649,
      smses: 446917,
    },
    {
      date: "2020-01-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 5391364,
      accounts: 587782,
      dataSent: 10160907,
      dataReceived: 90151501,
      callsIn: 855911,
      callsInDuration: 1266307,
      callsOut: 783121,
      callsOutDuration: 1065888,
      smses: 414107,
    },
    {
      date: "2020-01-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4957539,
      accounts: 559374,
      dataSent: 9012294,
      dataReceived: 80836943,
      callsIn: 616193,
      callsInDuration: 977174,
      callsOut: 599105,
      callsOutDuration: 820504,
      smses: 406808,
    },
    {
      date: "2020-01-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 5525390,
      accounts: 587907,
      dataSent: 9391011,
      dataReceived: 86484145,
      callsIn: 1028341,
      callsInDuration: 1774084,
      callsOut: 879989,
      callsOutDuration: 1533424,
      smses: 456556,
    },
    {
      date: "2020-01-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 5344046,
      accounts: 586135,
      dataSent: 7695282,
      dataReceived: 71807993,
      callsIn: 1010292,
      callsInDuration: 1804755,
      callsOut: 855783,
      callsOutDuration: 1531285,
      smses: 445069,
    },
    {
      date: "2020-01-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 7731908,
      accounts: 624503,
      dataSent: 10012364,
      dataReceived: 90578480,
      callsIn: 1036946,
      callsInDuration: 1808027,
      callsOut: 879826,
      callsOutDuration: 1535585,
      smses: 443513,
    },
    {
      date: "2020-01-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 7415136,
      accounts: 621275,
      dataSent: 9248143,
      dataReceived: 85245768,
      callsIn: 1044790,
      callsInDuration: 1807411,
      callsOut: 886460,
      callsOutDuration: 1521317,
      smses: 434269,
    },
    {
      date: "2020-01-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 7808985,
      accounts: 624580,
      dataSent: 10009013,
      dataReceived: 91501710,
      callsIn: 1091117,
      callsInDuration: 1783675,
      callsOut: 941334,
      callsOutDuration: 1519125,
      smses: 446043,
    },
    {
      date: "2020-01-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 6616540,
      accounts: 615688,
      dataSent: 9837440,
      dataReceived: 88814673,
      callsIn: 856294,
      callsInDuration: 1285006,
      callsOut: 790656,
      callsOutDuration: 1076210,
      smses: 409509,
    },
    {
      date: "2020-01-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 5659451,
      accounts: 593285,
      dataSent: 8653976,
      dataReceived: 79202960,
      callsIn: 594127,
      callsInDuration: 992742,
      callsOut: 583119,
      callsOutDuration: 822215,
      smses: 397945,
    },
    {
      date: "2020-01-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 7107812,
      accounts: 616440,
      dataSent: 8985913,
      dataReceived: 83853203,
      callsIn: 1018636,
      callsInDuration: 1844200,
      callsOut: 868147,
      callsOutDuration: 1589012,
      smses: 440774,
    },
    {
      date: "2020-01-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 7272663,
      accounts: 617640,
      dataSent: 8709372,
      dataReceived: 81212750,
      callsIn: 1017379,
      callsInDuration: 1839763,
      callsOut: 856514,
      callsOutDuration: 1559456,
      smses: 441840,
    },
    {
      date: "2020-01-29",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 7305062,
      accounts: 617497,
      dataSent: 9019766,
      dataReceived: 84933864,
      callsIn: 1005027,
      callsInDuration: 1834078,
      callsOut: 836087,
      callsOutDuration: 1527787,
      smses: 436527,
    },
    {
      date: "2020-01-30",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 7480332,
      accounts: 620107,
      dataSent: 8825010,
      dataReceived: 81262730,
      callsIn: 1033813,
      callsInDuration: 1862509,
      callsOut: 870252,
      callsOutDuration: 1558175,
      smses: 441797,
    },
    {
      date: "2020-01-31",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 7887366,
      accounts: 627094,
      dataSent: 8885503,
      dataReceived: 82235274,
      callsIn: 1074875,
      callsInDuration: 1775977,
      callsOut: 922818,
      callsOutDuration: 1509970,
      smses: 440117,
    },
    {
      date: "2020-02-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 7136206,
      accounts: 623830,
      dataSent: 9402331,
      dataReceived: 85951433,
      callsIn: 864306,
      callsInDuration: 1305719,
      callsOut: 798248,
      callsOutDuration: 1091387,
      smses: 417933,
    },
    {
      date: "2020-02-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 5730260,
      accounts: 599431,
      dataSent: 8245615,
      dataReceived: 78542031,
      callsIn: 601781,
      callsInDuration: 1014160,
      callsOut: 581162,
      callsOutDuration: 834770,
      smses: 397673,
    },
    {
      date: "2020-02-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 7542980,
      accounts: 623616,
      dataSent: 8641741,
      dataReceived: 83102987,
      callsIn: 1037905,
      callsInDuration: 1857461,
      callsOut: 913986,
      callsOutDuration: 1658486,
      smses: 462189,
    },
    {
      date: "2020-02-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 7889305,
      accounts: 625798,
      dataSent: 8072083,
      dataReceived: 77751445,
      callsIn: 1056056,
      callsInDuration: 1872323,
      callsOut: 917224,
      callsOutDuration: 1650602,
      smses: 459560,
    },
    {
      date: "2020-02-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 8550042,
      accounts: 629441,
      dataSent: 9672320,
      dataReceived: 92035564,
      callsIn: 1065056,
      callsInDuration: 1910345,
      callsOut: 930132,
      callsOutDuration: 1681335,
      smses: 466721,
    },
    {
      date: "2020-02-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 8489605,
      accounts: 634418,
      dataSent: 9116668,
      dataReceived: 86959860,
      callsIn: 1089445,
      callsInDuration: 1930771,
      callsOut: 959922,
      callsOutDuration: 1701288,
      smses: 464771,
    },
    {
      date: "2020-02-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 8988931,
      accounts: 638708,
      dataSent: 9561159,
      dataReceived: 90863244,
      callsIn: 1148279,
      callsInDuration: 1911807,
      callsOut: 1012055,
      callsOutDuration: 1697382,
      smses: 477049,
    },
    {
      date: "2020-02-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 8053704,
      accounts: 635198,
      dataSent: 9508394,
      dataReceived: 89554084,
      callsIn: 889060,
      callsInDuration: 1362837,
      callsOut: 823376,
      callsOutDuration: 1159108,
      smses: 438796,
    },
    {
      date: "2020-02-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6629263,
      accounts: 616000,
      dataSent: 7961932,
      dataReceived: 76276073,
      callsIn: 607525,
      callsInDuration: 1062460,
      callsOut: 598429,
      callsOutDuration: 887440,
      smses: 413052,
    },
    {
      date: "2020-02-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 9347888,
      accounts: 639162,
      dataSent: 9328644,
      dataReceived: 88062942,
      callsIn: 1071172,
      callsInDuration: 1936005,
      callsOut: 956177,
      callsOutDuration: 1746590,
      smses: 480659,
    },
    {
      date: "2020-02-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 8829894,
      accounts: 640472,
      dataSent: 7663352,
      dataReceived: 72210561,
      callsIn: 1080862,
      callsInDuration: 1964032,
      callsOut: 951910,
      callsOutDuration: 1732116,
      smses: 498322,
    },
    {
      date: "2020-02-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 9354498,
      accounts: 645555,
      dataSent: 9891146,
      dataReceived: 93686532,
      callsIn: 1092390,
      callsInDuration: 1933994,
      callsOut: 951626,
      callsOutDuration: 1695815,
      smses: 563894,
    },
    {
      date: "2020-02-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 9324436,
      accounts: 646951,
      dataSent: 9348392,
      dataReceived: 89935065,
      callsIn: 1110665,
      callsInDuration: 1968370,
      callsOut: 965783,
      callsOutDuration: 1703028,
      smses: 575844,
    },
    {
      date: "2020-02-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10258435,
      accounts: 652849,
      dataSent: 9911240,
      dataReceived: 92809651,
      callsIn: 1182945,
      callsInDuration: 1885043,
      callsOut: 1039251,
      callsOutDuration: 1639229,
      smses: 600449,
    },
    {
      date: "2020-02-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 8041806,
      accounts: 644891,
      dataSent: 9821795,
      dataReceived: 92161292,
      callsIn: 893431,
      callsInDuration: 1331009,
      callsOut: 827770,
      callsOutDuration: 1131194,
      smses: 518400,
    },
    {
      date: "2020-02-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6477008,
      accounts: 622368,
      dataSent: 8859203,
      dataReceived: 87069654,
      callsIn: 617804,
      callsInDuration: 1056684,
      callsOut: 610318,
      callsOutDuration: 878637,
      smses: 504593,
    },
    {
      date: "2020-02-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 8158519,
      accounts: 637544,
      dataSent: 7165342,
      dataReceived: 70778051,
      callsIn: 149277,
      callsInDuration: 257309,
      callsOut: 136490,
      callsOutDuration: 215675,
      smses: 601573,
    },
    {
      date: "2020-02-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 9759440,
      accounts: 649104,
      dataSent: 9297040,
      dataReceived: 90447282,
      callsIn: 1116336,
      callsInDuration: 2020761,
      callsOut: 963079,
      callsOutDuration: 1748485,
      smses: 581780,
    },
    {
      date: "2020-02-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10090731,
      accounts: 653929,
      dataSent: 9125893,
      dataReceived: 88513421,
      callsIn: 1147660,
      callsInDuration: 2067247,
      callsOut: 983506,
      callsOutDuration: 1772314,
      smses: 592463,
    },
    {
      date: "2020-02-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10183698,
      accounts: 654731,
      dataSent: 9223201,
      dataReceived: 89808670,
      callsIn: 1144894,
      callsInDuration: 2071589,
      callsOut: 989473,
      callsOutDuration: 1771565,
      smses: 578522,
    },
    {
      date: "2020-02-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10835314,
      accounts: 658710,
      dataSent: 9103779,
      dataReceived: 87757196,
      callsIn: 1212823,
      callsInDuration: 2020900,
      callsOut: 1061251,
      callsOutDuration: 1754481,
      smses: 588884,
    },
    {
      date: "2020-02-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 8983838,
      accounts: 652184,
      dataSent: 10320789,
      dataReceived: 98198446,
      callsIn: 901274,
      callsInDuration: 1376060,
      callsOut: 842406,
      callsOutDuration: 1164381,
      smses: 518766,
    },
    {
      date: "2020-02-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 7574189,
      accounts: 636045,
      dataSent: 10253579,
      dataReceived: 97765152,
      callsIn: 631888,
      callsInDuration: 1017736,
      callsOut: 629519,
      callsOutDuration: 854610,
      smses: 505887,
    },
    {
      date: "2020-02-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 7422184,
      accounts: 629741,
      dataSent: 8826529,
      dataReceived: 83071208,
      callsIn: 683612,
      callsInDuration: 1162176,
      callsOut: 640192,
      callsOutDuration: 956403,
      smses: 510869,
    },
    {
      date: "2020-02-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 7548473,
      accounts: 631342,
      dataSent: 8458815,
      dataReceived: 83428128,
      callsIn: 669606,
      callsInDuration: 1249313,
      callsOut: 606904,
      callsOutDuration: 998162,
      smses: 495571,
    },
    {
      date: "2020-02-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10270820,
      accounts: 651600,
      dataSent: 9701179,
      dataReceived: 93155495,
      callsIn: 1146989,
      callsInDuration: 2059740,
      callsOut: 1018569,
      callsOutDuration: 1858385,
      smses: 582020,
    },
    {
      date: "2020-02-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 9958507,
      accounts: 654792,
      dataSent: 9145194,
      dataReceived: 87969640,
      callsIn: 1160629,
      callsInDuration: 2116243,
      callsOut: 1013756,
      callsOutDuration: 1853986,
      smses: 587003,
    },
    {
      date: "2020-02-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10912760,
      accounts: 662623,
      dataSent: 9208316,
      dataReceived: 88325160,
      callsIn: 1235733,
      callsInDuration: 2087969,
      callsOut: 1102191,
      callsOutDuration: 1854617,
      smses: 600634,
    },
    {
      date: "2020-02-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 8690653,
      accounts: 659443,
      dataSent: 10072859,
      dataReceived: 97220550,
      callsIn: 925437,
      callsInDuration: 1446955,
      callsOut: 866510,
      callsOutDuration: 1234612,
      smses: 530606,
    },
    {
      date: "2020-03-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6851197,
      accounts: 640724,
      dataSent: 8903235,
      dataReceived: 89068880,
      callsIn: 639836,
      callsInDuration: 1149446,
      callsOut: 629386,
      callsOutDuration: 949202,
      smses: 513683,
    },
    {
      date: "2020-03-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 10480446,
      accounts: 662268,
      dataSent: 7941188,
      dataReceived: 82608476,
      callsIn: 1168316,
      callsInDuration: 2128962,
      callsOut: 1066126,
      callsOutDuration: 1946532,
      smses: 605671,
    },
    {
      date: "2020-03-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10743947,
      accounts: 663601,
      dataSent: 7952111,
      dataReceived: 84179304,
      callsIn: 1171231,
      callsInDuration: 2152039,
      callsOut: 1043672,
      callsOutDuration: 1927880,
      smses: 603268,
    },
    {
      date: "2020-03-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10735510,
      accounts: 666696,
      dataSent: 8955485,
      dataReceived: 96465204,
      callsIn: 1182797,
      callsInDuration: 2181439,
      callsOut: 1038544,
      callsOutDuration: 1923342,
      smses: 604246,
    },
    {
      date: "2020-03-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 11145304,
      accounts: 670382,
      dataSent: 8532997,
      dataReceived: 93114260,
      callsIn: 1206252,
      callsInDuration: 2173623,
      callsOut: 1067869,
      callsOutDuration: 1916851,
      smses: 609848,
    },
    {
      date: "2020-03-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 11444924,
      accounts: 674764,
      dataSent: 8776268,
      dataReceived: 98505770,
      callsIn: 1283784,
      callsInDuration: 2134024,
      callsOut: 1137429,
      callsOutDuration: 1886509,
      smses: 606068,
    },
    {
      date: "2020-03-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 9089820,
      accounts: 669201,
      dataSent: 9727956,
      dataReceived: 109423803,
      callsIn: 958801,
      callsInDuration: 1450141,
      callsOut: 899181,
      callsOutDuration: 1238971,
      smses: 537081,
    },
    {
      date: "2020-03-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 6757491,
      accounts: 648588,
      dataSent: 8925818,
      dataReceived: 100638250,
      callsIn: 651728,
      callsInDuration: 1163156,
      callsOut: 638732,
      callsOutDuration: 953133,
      smses: 515489,
    },
    {
      date: "2020-03-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 10207705,
      accounts: 669338,
      dataSent: 7882161,
      dataReceived: 89137660,
      callsIn: 1167274,
      callsInDuration: 2139429,
      callsOut: 1033706,
      callsOutDuration: 1897156,
      smses: 605934,
    },
    {
      date: "2020-03-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11274469,
      accounts: 673604,
      dataSent: 9012294,
      dataReceived: 87303288,
      callsIn: 1181774,
      callsInDuration: 2164851,
      callsOut: 1043659,
      callsOutDuration: 1954621,
      smses: 599436,
    },
    {
      date: "2020-03-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10099451,
      accounts: 673510,
      dataSent: 9156838,
      dataReceived: 90990020,
      callsIn: 1164767,
      callsInDuration: 2183093,
      callsOut: 1016256,
      callsOutDuration: 1957490,
      smses: 597480,
    },
    {
      date: "2020-03-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10054108,
      accounts: 679912,
      dataSent: 8658200,
      dataReceived: 81137304,
      callsIn: 1221118,
      callsInDuration: 2256195,
      callsOut: 1080541,
      callsOutDuration: 2067919,
      smses: 621238,
    },
    {
      date: "2020-03-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 11835748,
      accounts: 682395,
      dataSent: 9251085,
      dataReceived: 88266578,
      callsIn: 1272923,
      callsInDuration: 2220560,
      callsOut: 1139017,
      callsOutDuration: 2057729,
      smses: 619949,
    },
    {
      date: "2020-03-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 7242185,
      accounts: 662919,
      dataSent: 9487009,
      dataReceived: 94626148,
      callsIn: 867591,
      callsInDuration: 1563485,
      callsOut: 805016,
      callsOutDuration: 1401041,
      smses: 518819,
    },
    {
      date: "2020-03-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 5911869,
      accounts: 638787,
      dataSent: 8243477,
      dataReceived: 81673455,
      callsIn: 636275,
      callsInDuration: 1531481,
      callsOut: 612849,
      callsOutDuration: 1345334,
      smses: 509156,
    },
    {
      date: "2020-03-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 8174911,
      accounts: 660560,
      dataSent: 9204569,
      dataReceived: 88539014,
      callsIn: 1145905,
      callsInDuration: 2592391,
      callsOut: 1021995,
      callsOutDuration: 2404204,
      smses: 590613,
    },
    {
      date: "2020-03-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 6893764,
      accounts: 649846,
      dataSent: 9257838,
      dataReceived: 88048312,
      callsIn: 1067502,
      callsInDuration: 2655228,
      callsOut: 926035,
      callsOutDuration: 2392456,
      smses: 561298,
    },
    {
      date: "2020-03-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6655040,
      accounts: 646627,
      dataSent: 9095742,
      dataReceived: 84249453,
      callsIn: 1047302,
      callsInDuration: 2690625,
      callsOut: 902141,
      callsOutDuration: 2393326,
      smses: 552139,
    },
    {
      date: "2020-03-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 7300406,
      accounts: 650209,
      dataSent: 9987335,
      dataReceived: 87575382,
      callsIn: 1235690,
      callsInDuration: 3055463,
      callsOut: 1103295,
      callsOutDuration: 2773112,
      smses: 572045,
    },
    {
      date: "2020-03-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 6549083,
      accounts: 616526,
      dataSent: 10091020,
      dataReceived: 85158604,
      callsIn: 773964,
      callsInDuration: 2617043,
      callsOut: 718193,
      callsOutDuration: 2276043,
      smses: 479078,
    },
    {
      date: "2020-03-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 5611055,
      accounts: 586576,
      dataSent: 10773141,
      dataReceived: 89536399,
      callsIn: 569274,
      callsInDuration: 2210078,
      callsOut: 516898,
      callsOutDuration: 1869949,
      smses: 442745,
    },
    {
      date: "2020-03-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4779050,
      accounts: 542671,
      dataSent: 9477492,
      dataReceived: 79966657,
      callsIn: 411414,
      callsInDuration: 2043216,
      callsOut: 376457,
      callsOutDuration: 1689731,
      smses: 417063,
    },
    {
      date: "2020-03-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4935778,
      accounts: 546807,
      dataSent: 9907642,
      dataReceived: 81324236,
      callsIn: 504766,
      callsInDuration: 2304619,
      callsOut: 446725,
      callsOutDuration: 1881352,
      smses: 427381,
    },
    {
      date: "2020-03-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 4862639,
      accounts: 536357,
      dataSent: 8216021,
      dataReceived: 67728187,
      callsIn: 498703,
      callsInDuration: 2346425,
      callsOut: 441281,
      callsOutDuration: 1915648,
      smses: 429743,
    },
    {
      date: "2020-03-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 6002347,
      accounts: 577062,
      dataSent: 9256221,
      dataReceived: 74134375,
      callsIn: 693125,
      callsInDuration: 2808085,
      callsOut: 636791,
      callsOutDuration: 2401950,
      smses: 464332,
    },
    {
      date: "2020-03-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 5503616,
      accounts: 573776,
      dataSent: 9905688,
      dataReceived: 78711980,
      callsIn: 471785,
      callsInDuration: 2161459,
      callsOut: 413653,
      callsOutDuration: 1795156,
      smses: 372804,
    },
    {
      date: "2020-03-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 12730260,
      accounts: 576648,
      dataSent: 7087282,
      dataReceived: 54562225,
      callsIn: 731378,
      callsInDuration: 2872646,
      callsOut: 656995,
      callsOutDuration: 2426533,
      smses: 201189,
    },
    {
      date: "2020-03-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11955985,
      accounts: 564611,
      dataSent: 7985654,
      dataReceived: 64403569,
      callsIn: 550074,
      callsInDuration: 2226563,
      callsOut: 496884,
      callsOutDuration: 1864415,
      smses: 150667,
    },
    {
      date: "2020-03-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10025252,
      accounts: 541786,
      dataSent: 7329194,
      dataReceived: 62070766,
      callsIn: 392054,
      callsInDuration: 2040305,
      callsOut: 356333,
      callsOutDuration: 1676518,
      smses: 144524,
    },
    {
      date: "2020-03-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 13264488,
      accounts: 701674,
      dataSent: 8932740,
      dataReceived: 79108650,
      callsIn: 675450,
      callsInDuration: 2769999,
      callsOut: 608222,
      callsOutDuration: 2316670,
      smses: 160163,
    },
    {
      date: "2020-03-31",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 12185342,
      accounts: 704093,
      dataSent: 8663577,
      dataReceived: 80254774,
      callsIn: 519913,
      callsInDuration: 2406301,
      callsOut: 467405,
      callsOutDuration: 1977849,
      smses: 146798,
    },
    {
      date: "2020-04-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 13720921,
      accounts: 731048,
      dataSent: 8525913,
      dataReceived: 74588524,
      callsIn: 707890,
      callsInDuration: 2832086,
      callsOut: 645497,
      callsOutDuration: 2383309,
      smses: 160703,
    },
    {
      date: "2020-04-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 13499971,
      accounts: 755038,
      dataSent: 8645579,
      dataReceived: 73898994,
      callsIn: 700789,
      callsInDuration: 2807115,
      callsOut: 614232,
      callsOutDuration: 2321068,
      smses: 146069,
    },
    {
      date: "2020-04-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 13632452,
      accounts: 765084,
      dataSent: 8281760,
      dataReceived: 71050903,
      callsIn: 757211,
      callsInDuration: 2850562,
      callsOut: 673772,
      callsOutDuration: 2398914,
      smses: 143874,
    },
    {
      date: "2020-04-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 12361053,
      accounts: 766062,
      dataSent: 8163361,
      dataReceived: 71396560,
      callsIn: 562035,
      callsInDuration: 2214057,
      callsOut: 503921,
      callsOutDuration: 1810471,
      smses: 133748,
    },
    {
      date: "2020-04-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10783060,
      accounts: 760937,
      dataSent: 8080661,
      dataReceived: 72770758,
      callsIn: 385629,
      callsInDuration: 1937648,
      callsOut: 347498,
      callsOutDuration: 1548004,
      smses: 124504,
    },
    {
      date: "2020-04-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 13466583,
      accounts: 772357,
      dataSent: 7090533,
      dataReceived: 57561756,
      callsIn: 730627,
      callsInDuration: 2773080,
      callsOut: 641746,
      callsOutDuration: 2284222,
      smses: 147941,
    },
    {
      date: "2020-04-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 13268649,
      accounts: 774107,
      dataSent: 7828226,
      dataReceived: 64325225,
      callsIn: 728617,
      callsInDuration: 2795812,
      callsOut: 636532,
      callsOutDuration: 2278417,
      smses: 141777,
    },
    {
      date: "2020-04-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 13455070,
      accounts: 777070,
      dataSent: 8349981,
      dataReceived: 67777310,
      callsIn: 773473,
      callsInDuration: 2942817,
      callsOut: 674195,
      callsOutDuration: 2391544,
      smses: 138383,
    },
    {
      date: "2020-04-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 12663780,
      accounts: 776288,
      dataSent: 8517499,
      dataReceived: 68737973,
      callsIn: 700469,
      callsInDuration: 2640398,
      callsOut: 599514,
      callsOutDuration: 2146588,
      smses: 137188,
    },
    {
      date: "2020-04-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 11065234,
      accounts: 768491,
      dataSent: 8258882,
      dataReceived: 68246033,
      callsIn: 504486,
      callsInDuration: 2217905,
      callsOut: 447870,
      callsOutDuration: 1756419,
      smses: 126052,
    },
    {
      date: "2020-04-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 12051492,
      accounts: 772831,
      dataSent: 8354119,
      dataReceived: 67671377,
      callsIn: 586841,
      callsInDuration: 2269895,
      callsOut: 510400,
      callsOutDuration: 1785361,
      smses: 131945,
    },
    {
      date: "2020-04-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10635134,
      accounts: 765749,
      dataSent: 7918487,
      dataReceived: 64202813,
      callsIn: 435669,
      callsInDuration: 2127902,
      callsOut: 375866,
      callsOutDuration: 1659056,
      smses: 127822,
    },
    {
      date: "2020-04-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 13346739,
      accounts: 771225,
      dataSent: 7376686,
      dataReceived: 58027152,
      callsIn: 803586,
      callsInDuration: 2875344,
      callsOut: 759545,
      callsOutDuration: 2520149,
      smses: 195225,
    },
    {
      date: "2020-04-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 13923783,
      accounts: 778393,
      dataSent: 7803534,
      dataReceived: 60425578,
      callsIn: 831504,
      callsInDuration: 3016229,
      callsOut: 773227,
      callsOutDuration: 2631043,
      smses: 198743,
    },
    {
      date: "2020-04-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14270773,
      accounts: 781167,
      dataSent: 8084057,
      dataReceived: 63040839,
      callsIn: 838457,
      callsInDuration: 3027919,
      callsOut: 782257,
      callsOutDuration: 2663426,
      smses: 202515,
    },
    {
      date: "2020-04-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 14217515,
      accounts: 781896,
      dataSent: 8161986,
      dataReceived: 62750707,
      callsIn: 830328,
      callsInDuration: 3030494,
      callsOut: 768543,
      callsOutDuration: 2658916,
      smses: 200886,
    },
    {
      date: "2020-04-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14578712,
      accounts: 783542,
      dataSent: 8240965,
      dataReceived: 62327274,
      callsIn: 853492,
      callsInDuration: 2999261,
      callsOut: 788198,
      callsOutDuration: 2634805,
      smses: 200346,
    },
    {
      date: "2020-04-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 12922040,
      accounts: 779312,
      dataSent: 8172055,
      dataReceived: 64529011,
      callsIn: 626739,
      callsInDuration: 2322902,
      callsOut: 595840,
      callsOutDuration: 1975765,
      smses: 187755,
    },
    {
      date: "2020-04-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 11047205,
      accounts: 769679,
      dataSent: 8137119,
      dataReceived: 66847732,
      callsIn: 412452,
      callsInDuration: 1995388,
      callsOut: 401556,
      callsOutDuration: 1682656,
      smses: 174384,
    },
    {
      date: "2020-04-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14221369,
      accounts: 781689,
      dataSent: 7810217,
      dataReceived: 61217978,
      callsIn: 856056,
      callsInDuration: 3011671,
      callsOut: 801638,
      callsOutDuration: 2677812,
      smses: 209492,
    },
    {
      date: "2020-04-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14399681,
      accounts: 782155,
      dataSent: 7232536,
      dataReceived: 56330104,
      callsIn: 833604,
      callsInDuration: 3012389,
      callsOut: 773681,
      callsOutDuration: 2644354,
      smses: 198373,
    },
    {
      date: "2020-04-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 15127984,
      accounts: 782791,
      dataSent: 7465288,
      dataReceived: 60134420,
      callsIn: 843775,
      callsInDuration: 3057423,
      callsOut: 781915,
      callsOutDuration: 2665890,
      smses: 199254,
    },
    {
      date: "2020-04-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 14328917,
      accounts: 783273,
      dataSent: 7286096,
      dataReceived: 58774117,
      callsIn: 845962,
      callsInDuration: 3064524,
      callsOut: 779213,
      callsOutDuration: 2662948,
      smses: 203225,
    },
    {
      date: "2020-04-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 12866703,
      accounts: 784678,
      dataSent: 8111934,
      dataReceived: 65550481,
      callsIn: 855186,
      callsInDuration: 2945256,
      callsOut: 786571,
      callsOutDuration: 2560868,
      smses: 206676,
    },
    {
      date: "2020-04-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11345769,
      accounts: 777772,
      dataSent: 7887718,
      dataReceived: 67048023,
      callsIn: 595693,
      callsInDuration: 2193226,
      callsOut: 563633,
      callsOutDuration: 1881117,
      smses: 187564,
    },
    {
      date: "2020-04-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10236785,
      accounts: 769929,
      dataSent: 7809011,
      dataReceived: 68128424,
      callsIn: 409100,
      callsInDuration: 1958395,
      callsOut: 396914,
      callsOutDuration: 1648944,
      smses: 173552,
    },
    {
      date: "2020-04-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 12415586,
      accounts: 780675,
      dataSent: 7715966,
      dataReceived: 64149220,
      callsIn: 839518,
      callsInDuration: 2981488,
      callsOut: 771202,
      callsOutDuration: 2627981,
      smses: 217518,
    },
    {
      date: "2020-04-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 12818169,
      accounts: 782025,
      dataSent: 7679332,
      dataReceived: 64451855,
      callsIn: 832306,
      callsInDuration: 2912736,
      callsOut: 750685,
      callsOutDuration: 2557750,
      smses: 223210,
    },
    {
      date: "2020-04-29",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10877558,
      accounts: 660075,
      dataSent: 6908996,
      dataReceived: 53939937,
      callsIn: 915648,
      callsInDuration: 3081353,
      callsOut: 852233,
      callsOutDuration: 2727471,
      smses: 204519,
    },
    {
      date: "2020-04-30",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 9574134,
      accounts: 534494,
      dataSent: 6354842,
      dataReceived: 47885578,
      callsIn: 975805,
      callsInDuration: 3137400,
      callsOut: 917272,
      callsOutDuration: 2811606,
      smses: 211530,
    },
    {
      date: "2020-05-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 8531579,
      accounts: 524770,
      dataSent: 6252342,
      dataReceived: 49173376,
      callsIn: 513360,
      callsInDuration: 2136583,
      callsOut: 518323,
      callsOutDuration: 1850994,
      smses: 172006,
    },
    {
      date: "2020-05-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 8712131,
      accounts: 528246,
      dataSent: 6174214,
      dataReceived: 49116147,
      callsIn: 666196,
      callsInDuration: 2209907,
      callsOut: 665557,
      callsOutDuration: 1943764,
      smses: 186556,
    },
    {
      date: "2020-05-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 7827027,
      accounts: 517260,
      dataSent: 6021532,
      dataReceived: 50467937,
      callsIn: 418656,
      callsInDuration: 1858079,
      callsOut: 414408,
      callsOutDuration: 1572196,
      smses: 160714,
    },
    {
      date: "2020-05-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 9265157,
      accounts: 529826,
      dataSent: 6114076,
      dataReceived: 47561796,
      callsIn: 910779,
      callsInDuration: 3034545,
      callsOut: 882249,
      callsOutDuration: 2763944,
      smses: 203888,
    },
    {
      date: "2020-05-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 9560075,
      accounts: 533806,
      dataSent: 5788287,
      dataReceived: 44730984,
      callsIn: 912705,
      callsInDuration: 3061539,
      callsOut: 860608,
      callsOutDuration: 2763061,
      smses: 201991,
    },
    {
      date: "2020-05-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 9637885,
      accounts: 536475,
      dataSent: 6064834,
      dataReceived: 46496653,
      callsIn: 948837,
      callsInDuration: 3122373,
      callsOut: 910820,
      callsOutDuration: 2829062,
      smses: 204048,
    },
    {
      date: "2020-05-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 9639621,
      accounts: 541984,
      dataSent: 6156043,
      dataReceived: 47172892,
      callsIn: 973554,
      callsInDuration: 3170089,
      callsOut: 925358,
      callsOutDuration: 2856991,
      smses: 205509,
    },
    {
      date: "2020-05-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 9934456,
      accounts: 545618,
      dataSent: 6132399,
      dataReceived: 46497891,
      callsIn: 1019115,
      callsInDuration: 3037239,
      callsOut: 979117,
      callsOutDuration: 2801688,
      smses: 209843,
    },
    {
      date: "2020-05-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 9157992,
      accounts: 541758,
      dataSent: 6022983,
      dataReceived: 47975572,
      callsIn: 749664,
      callsInDuration: 2310921,
      callsOut: 738788,
      callsOutDuration: 2055121,
      smses: 185580,
    },
    {
      date: "2020-05-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 8157002,
      accounts: 530041,
      dataSent: 5812513,
      dataReceived: 48125253,
      callsIn: 470486,
      callsInDuration: 1888849,
      callsOut: 474361,
      callsOutDuration: 1645691,
      smses: 160362,
    },
    {
      date: "2020-05-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 9809290,
      accounts: 542047,
      dataSent: 5826959,
      dataReceived: 45417677,
      callsIn: 977206,
      callsInDuration: 3048580,
      callsOut: 946257,
      callsOutDuration: 2844101,
      smses: 206957,
    },
    {
      date: "2020-05-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 8567270,
      accounts: 540250,
      dataSent: 4802453,
      dataReceived: 35938106,
      callsIn: 974847,
      callsInDuration: 2983478,
      callsOut: 917794,
      callsOutDuration: 2739820,
      smses: 189158,
    },
    {
      date: "2020-05-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 7097808,
      accounts: 536343,
      dataSent: 4219258,
      dataReceived: 31915587,
      callsIn: 681646,
      callsInDuration: 2357897,
      callsOut: 583228,
      callsOutDuration: 1942998,
      smses: 107770,
    },
    {
      date: "2020-05-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 9918418,
      accounts: 560289,
      dataSent: 6138931,
      dataReceived: 47352709,
      callsIn: 976503,
      callsInDuration: 3054928,
      callsOut: 831365,
      callsOutDuration: 2564474,
      smses: 140440,
    },
    {
      date: "2020-05-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10123226,
      accounts: 569477,
      dataSent: 6084645,
      dataReceived: 47158836,
      callsIn: 1016622,
      callsInDuration: 2993027,
      callsOut: 894737,
      callsOutDuration: 2564598,
      smses: 142158,
    },
    {
      date: "2020-05-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 9683487,
      accounts: 568096,
      dataSent: 5841403,
      dataReceived: 47493479,
      callsIn: 739050,
      callsInDuration: 2155970,
      callsOut: 670234,
      callsOutDuration: 1810688,
      smses: 127385,
    },
    {
      date: "2020-05-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 8996595,
      accounts: 559288,
      dataSent: 6248663,
      dataReceived: 54630739,
      callsIn: 452302,
      callsInDuration: 1780179,
      callsOut: 423213,
      callsOutDuration: 1471489,
      smses: 105860,
    },
    {
      date: "2020-05-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 3715698,
      accounts: 503788,
      dataSent: 2905854,
      dataReceived: 24463348,
      callsIn: 961944,
      callsInDuration: 2968974,
      callsOut: 860697,
      callsOutDuration: 2596382,
      smses: 140250,
    },
    {
      date: "2020-05-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 0,
      accounts: 380228,
      dataSent: 0,
      dataReceived: 0,
      callsIn: 963014,
      callsInDuration: 3038240,
      callsOut: 835904,
      callsOutDuration: 2594347,
      smses: 138796,
    },
    {
      date: "2020-05-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 4934540,
      accounts: 531515,
      dataSent: 3557904,
      dataReceived: 27594218,
      callsIn: 968071,
      callsInDuration: 3008220,
      callsOut: 827159,
      callsOutDuration: 2554732,
      smses: 140399,
    },
    {
      date: "2020-05-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10573929,
      accounts: 622899,
      dataSent: 6202533,
      dataReceived: 48857523,
      callsIn: 949193,
      callsInDuration: 3015871,
      callsOut: 809678,
      callsOutDuration: 2573384,
      smses: 139678,
    },
    {
      date: "2020-05-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10943910,
      accounts: 625386,
      dataSent: 6583274,
      dataReceived: 51242365,
      callsIn: 1005933,
      callsInDuration: 2973056,
      callsOut: 882377,
      callsOutDuration: 2584014,
      smses: 144270,
    },
    {
      date: "2020-05-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 5890052,
      accounts: 596036,
      dataSent: 6207655,
      dataReceived: 49836077,
      callsIn: 749183,
      callsInDuration: 2122798,
      callsOut: 680947,
      callsOutDuration: 1829845,
      smses: 129166,
    },
    {
      date: "2020-05-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 4162417,
      accounts: 551865,
      dataSent: 4955286,
      dataReceived: 42432606,
      callsIn: 463772,
      callsInDuration: 1776022,
      callsOut: 433128,
      callsOutDuration: 1488411,
      smses: 110659,
    },
    {
      date: "2020-05-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4226681,
      accounts: 551495,
      dataSent: 4938479,
      dataReceived: 41617542,
      callsIn: 538083,
      callsInDuration: 1947289,
      callsOut: 478296,
      callsOutDuration: 1610074,
      smses: 113528,
    },
    {
      date: "2020-05-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 4864859,
      accounts: 571916,
      dataSent: 5698036,
      dataReceived: 44115601,
      callsIn: 1003917,
      callsInDuration: 3021461,
      callsOut: 878332,
      callsOutDuration: 2673497,
      smses: 140874,
    },
    {
      date: "2020-05-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10290357,
      accounts: 623325,
      dataSent: 5811148,
      dataReceived: 45085477,
      callsIn: 1012440,
      callsInDuration: 3083887,
      callsOut: 877169,
      callsOutDuration: 2692649,
      smses: 139008,
    },
    {
      date: "2020-05-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10621618,
      accounts: 631736,
      dataSent: 5913171,
      dataReceived: 47101062,
      callsIn: 1011400,
      callsInDuration: 3098335,
      callsOut: 890947,
      callsOutDuration: 2724022,
      smses: 142280,
    },
    {
      date: "2020-05-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 9847811,
      accounts: 627452,
      dataSent: 5135625,
      dataReceived: 39581075,
      callsIn: 1035555,
      callsInDuration: 2996807,
      callsOut: 932960,
      callsOutDuration: 2656847,
      smses: 144403,
    },
    {
      date: "2020-05-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10109516,
      accounts: 617469,
      dataSent: 6045473,
      dataReceived: 51193634,
      callsIn: 724559,
      callsInDuration: 2161626,
      callsOut: 666776,
      callsOutDuration: 1864704,
      smses: 124198,
    },
    {
      date: "2020-05-31",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 8682365,
      accounts: 578969,
      dataSent: 6012057,
      dataReceived: 53112565,
      callsIn: 457394,
      callsInDuration: 1782801,
      callsOut: 423563,
      callsOutDuration: 1488886,
      smses: 109190,
    },
    {
      date: "2020-06-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4532360,
      accounts: 542591,
      dataSent: 5441195,
      dataReceived: 42970233,
      callsIn: 954011,
      callsInDuration: 2966817,
      callsOut: 877641,
      callsOutDuration: 2693004,
      smses: 133906,
    },
    {
      date: "2020-06-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 9509155,
      accounts: 605503,
      dataSent: 11082599,
      dataReceived: 88513668,
      callsIn: 991365,
      callsInDuration: 3042597,
      callsOut: 894781,
      callsOutDuration: 2721533,
      smses: 136594,
    },
    {
      date: "2020-06-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10047498,
      accounts: 612302,
      dataSent: 10780515,
      dataReceived: 87661731,
      callsIn: 992887,
      callsInDuration: 3060846,
      callsOut: 891917,
      callsOutDuration: 2724243,
      smses: 138522,
    },
    {
      date: "2020-06-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 4948135,
      accounts: 574996,
      dataSent: 4202095,
      dataReceived: 33383908,
      callsIn: 1008419,
      callsInDuration: 3041010,
      callsOut: 902078,
      callsOutDuration: 2691365,
      smses: 137932,
    },
    {
      date: "2020-06-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 7679366,
      accounts: 608058,
      dataSent: 7904468,
      dataReceived: 65003937,
      callsIn: 1070304,
      callsInDuration: 3029039,
      callsOut: 969497,
      callsOutDuration: 2709074,
      smses: 145132,
    },
    {
      date: "2020-06-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 4217939,
      accounts: 561579,
      dataSent: 5456464,
      dataReceived: 46058765,
      callsIn: 785865,
      callsInDuration: 2148688,
      callsOut: 721538,
      callsOutDuration: 1886207,
      smses: 125476,
    },
    {
      date: "2020-06-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 3798170,
      accounts: 543142,
      dataSent: 4817043,
      dataReceived: 43795411,
      callsIn: 478563,
      callsInDuration: 1726127,
      callsOut: 446367,
      callsOutDuration: 1457223,
      smses: 117554,
    },
    {
      date: "2020-06-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 4865827,
      accounts: 578073,
      dataSent: 5275463,
      dataReceived: 43139290,
      callsIn: 1019498,
      callsInDuration: 3020105,
      callsOut: 916872,
      callsOutDuration: 2707775,
      smses: 150238,
    },
    {
      date: "2020-06-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 9444938,
      accounts: 625327,
      dataSent: 12120056,
      dataReceived: 100877680,
      callsIn: 1057921,
      callsInDuration: 3095573,
      callsOut: 938524,
      callsOutDuration: 2734766,
      smses: 155634,
    },
    {
      date: "2020-06-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 9454019,
      accounts: 632743,
      dataSent: 12032979,
      dataReceived: 98557680,
      callsIn: 1081113,
      callsInDuration: 3136894,
      callsOut: 963521,
      callsOutDuration: 2794609,
      smses: 152917,
    },
    {
      date: "2020-06-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10392436,
      accounts: 638507,
      dataSent: 12099491,
      dataReceived: 99978200,
      callsIn: 1084113,
      callsInDuration: 3141652,
      callsOut: 964480,
      callsOutDuration: 2798707,
      smses: 150317,
    },
    {
      date: "2020-06-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10635889,
      accounts: 645556,
      dataSent: 12867484,
      dataReceived: 102585672,
      callsIn: 1139710,
      callsInDuration: 3056137,
      callsOut: 1025333,
      callsOutDuration: 2752605,
      smses: 154201,
    },
    {
      date: "2020-06-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 9905108,
      accounts: 643160,
      dataSent: 11355432,
      dataReceived: 95439540,
      callsIn: 833330,
      callsInDuration: 2144296,
      callsOut: 771975,
      callsOutDuration: 1887742,
      smses: 136231,
    },
    {
      date: "2020-06-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 8929270,
      accounts: 629275,
      dataSent: 10176746,
      dataReceived: 89645897,
      callsIn: 519464,
      callsInDuration: 1726000,
      callsOut: 493304,
      callsOutDuration: 1490715,
      smses: 122499,
    },
    {
      date: "2020-06-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 9791449,
      accounts: 627079,
      dataSent: 10155161,
      dataReceived: 87223102,
      callsIn: 547238,
      callsInDuration: 1936766,
      callsOut: 496477,
      callsOutDuration: 1634295,
      smses: 112135,
    },
    {
      date: "2020-06-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10757141,
      accounts: 642121,
      dataSent: 12297246,
      dataReceived: 97428759,
      callsIn: 1101209,
      callsInDuration: 3071083,
      callsOut: 997537,
      callsOutDuration: 2812215,
      smses: 150856,
    },
    {
      date: "2020-06-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10602281,
      accounts: 647226,
      dataSent: 12619755,
      dataReceived: 100168920,
      callsIn: 1096399,
      callsInDuration: 3170591,
      callsOut: 967680,
      callsOutDuration: 2808779,
      smses: 154773,
    },
    {
      date: "2020-06-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10719310,
      accounts: 651958,
      dataSent: 12589832,
      dataReceived: 98837519,
      callsIn: 1101980,
      callsInDuration: 3205657,
      callsOut: 977382,
      callsOutDuration: 2842902,
      smses: 148505,
    },
    {
      date: "2020-06-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10837804,
      accounts: 656951,
      dataSent: 13030765,
      dataReceived: 97603120,
      callsIn: 1186590,
      callsInDuration: 3149739,
      callsOut: 1065869,
      callsOutDuration: 2823859,
      smses: 153296,
    },
    {
      date: "2020-06-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10176173,
      accounts: 653541,
      dataSent: 11651977,
      dataReceived: 92012721,
      callsIn: 848152,
      callsInDuration: 2195344,
      callsOut: 811257,
      callsOutDuration: 1915134,
      smses: 126857,
    },
    {
      date: "2020-06-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9714493,
      accounts: 644020,
      dataSent: 10976148,
      dataReceived: 87366797,
      callsIn: 600986,
      callsInDuration: 1912595,
      callsOut: 607975,
      callsOutDuration: 1851847,
      smses: 136592,
    },
    {
      date: "2020-06-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 10958502,
      accounts: 650359,
      dataSent: 10950007,
      dataReceived: 85554591,
      callsIn: 879134,
      callsInDuration: 2409482,
      callsOut: 789935,
      callsOutDuration: 2161941,
      smses: 129541,
    },
    {
      date: "2020-06-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10889744,
      accounts: 652041,
      dataSent: 11848662,
      dataReceived: 92590713,
      callsIn: 950224,
      callsInDuration: 2846539,
      callsOut: 829632,
      callsOutDuration: 2507858,
      smses: 133546,
    },
    {
      date: "2020-06-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10395355,
      accounts: 655530,
      dataSent: 12149538,
      dataReceived: 94234441,
      callsIn: 1067320,
      callsInDuration: 3116431,
      callsOut: 937747,
      callsOutDuration: 2784502,
      smses: 149474,
    },
    {
      date: "2020-06-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10649143,
      accounts: 659436,
      dataSent: 12296247,
      dataReceived: 93179540,
      callsIn: 1105074,
      callsInDuration: 3122567,
      callsOut: 965824,
      callsOutDuration: 2804247,
      smses: 144779,
    },
    {
      date: "2020-06-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10871140,
      accounts: 665104,
      dataSent: 12655719,
      dataReceived: 95195590,
      callsIn: 1168834,
      callsInDuration: 3078470,
      callsOut: 1035112,
      callsOutDuration: 2782172,
      smses: 148192,
    },
    {
      date: "2020-06-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10221437,
      accounts: 661779,
      dataSent: 11300128,
      dataReceived: 89192554,
      callsIn: 848277,
      callsInDuration: 2215334,
      callsOut: 786364,
      callsOutDuration: 1969591,
      smses: 133330,
    },
    {
      date: "2020-06-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9268986,
      accounts: 648713,
      dataSent: 9971099,
      dataReceived: 83201120,
      callsIn: 514794,
      callsInDuration: 1812894,
      callsOut: 492998,
      callsOutDuration: 1553501,
      smses: 119811,
    },
    {
      date: "2020-06-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11287760,
      accounts: 662453,
      dataSent: 11833863,
      dataReceived: 87846534,
      callsIn: 1130543,
      callsInDuration: 3109321,
      callsOut: 999482,
      callsOutDuration: 2851904,
      smses: 152084,
    },
    {
      date: "2020-06-30",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10675764,
      accounts: 663418,
      dataSent: 12150919,
      dataReceived: 91862646,
      callsIn: 1120015,
      callsInDuration: 3123940,
      callsOut: 968161,
      callsOutDuration: 2818335,
      smses: 157485,
    },
    {
      date: "2020-07-01",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10687725,
      accounts: 668525,
      dataSent: 12154632,
      dataReceived: 91516231,
      callsIn: 1098529,
      callsInDuration: 3141376,
      callsOut: 973230,
      callsOutDuration: 2895417,
      smses: 152183,
    },
    {
      date: "2020-07-02",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10696591,
      accounts: 670935,
      dataSent: 12342553,
      dataReceived: 92952861,
      callsIn: 1119340,
      callsInDuration: 3138350,
      callsOut: 983179,
      callsOutDuration: 2883200,
      smses: 149457,
    },
    {
      date: "2020-07-03",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10855658,
      accounts: 675074,
      dataSent: 12571258,
      dataReceived: 93886322,
      callsIn: 1195158,
      callsInDuration: 3075001,
      callsOut: 1064305,
      callsOutDuration: 2819393,
      smses: 153104,
    },
    {
      date: "2020-07-04",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10227540,
      accounts: 670950,
      dataSent: 11312177,
      dataReceived: 90076673,
      callsIn: 842424,
      callsInDuration: 2197564,
      callsOut: 797430,
      callsOutDuration: 1986040,
      smses: 139225,
    },
    {
      date: "2020-07-05",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9405462,
      accounts: 658742,
      dataSent: 10352617,
      dataReceived: 86990283,
      callsIn: 522116,
      callsInDuration: 1802506,
      callsOut: 509645,
      callsOutDuration: 1609108,
      smses: 122911,
    },
    {
      date: "2020-07-06",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 10500264,
      accounts: 670337,
      dataSent: 11852326,
      dataReceived: 91181658,
      callsIn: 1080649,
      callsInDuration: 3094935,
      callsOut: 994676,
      callsOutDuration: 2886745,
      smses: 155724,
    },
    {
      date: "2020-07-07",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10695770,
      accounts: 672830,
      dataSent: 12152117,
      dataReceived: 93352657,
      callsIn: 1098710,
      callsInDuration: 3129277,
      callsOut: 1001655,
      callsOutDuration: 2901199,
      smses: 154440,
    },
    {
      date: "2020-07-08",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10864600,
      accounts: 679354,
      dataSent: 12461402,
      dataReceived: 95320894,
      callsIn: 1169801,
      callsInDuration: 3162570,
      callsOut: 1086872,
      callsOutDuration: 2932708,
      smses: 157712,
    },
    {
      date: "2020-07-09",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 9989732,
      accounts: 671598,
      dataSent: 11114153,
      dataReceived: 89893842,
      callsIn: 714381,
      callsInDuration: 2199827,
      callsOut: 685545,
      callsOutDuration: 1965566,
      smses: 142151,
    },
    {
      date: "2020-07-10",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 9931977,
      accounts: 668755,
      dataSent: 11158775,
      dataReceived: 91779397,
      callsIn: 782590,
      callsInDuration: 2284606,
      callsOut: 755285,
      callsOutDuration: 2036396,
      smses: 144636,
    },
    {
      date: "2020-07-11",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10024254,
      accounts: 669478,
      dataSent: 11291589,
      dataReceived: 92072499,
      callsIn: 810222,
      callsInDuration: 2095162,
      callsOut: 766076,
      callsOutDuration: 1869875,
      smses: 143899,
    },
    {
      date: "2020-07-12",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9116215,
      accounts: 655037,
      dataSent: 10009929,
      dataReceived: 87086327,
      callsIn: 507069,
      callsInDuration: 1765651,
      callsOut: 489728,
      callsOutDuration: 1561364,
      smses: 122518,
    },
    {
      date: "2020-07-13",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 10307473,
      accounts: 668786,
      dataSent: 11531523,
      dataReceived: 91543097,
      callsIn: 1086323,
      callsInDuration: 3111123,
      callsOut: 1038694,
      callsOutDuration: 2950921,
      smses: 153125,
    },
    {
      date: "2020-07-14",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10731727,
      accounts: 673533,
      dataSent: 12094001,
      dataReceived: 93561827,
      callsIn: 1122175,
      callsInDuration: 3219673,
      callsOut: 1047466,
      callsOutDuration: 2972117,
      smses: 188116,
    },
    {
      date: "2020-07-15",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10584782,
      accounts: 677739,
      dataSent: 12202246,
      dataReceived: 95129620,
      callsIn: 1113451,
      callsInDuration: 3232920,
      callsOut: 1025199,
      callsOutDuration: 2961436,
      smses: 153717,
    },
    {
      date: "2020-07-16",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10587742,
      accounts: 679055,
      dataSent: 12405771,
      dataReceived: 96940968,
      callsIn: 1100780,
      callsInDuration: 3200793,
      callsOut: 1010520,
      callsOutDuration: 2912058,
      smses: 152253,
    },
    {
      date: "2020-07-17",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10988969,
      accounts: 686610,
      dataSent: 12754703,
      dataReceived: 99471702,
      callsIn: 1179534,
      callsInDuration: 3129300,
      callsOut: 1108051,
      callsOutDuration: 2866058,
      smses: 156048,
    },
    {
      date: "2020-07-18",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10377214,
      accounts: 682324,
      dataSent: 11731440,
      dataReceived: 96540482,
      callsIn: 867937,
      callsInDuration: 2245107,
      callsOut: 825165,
      callsOutDuration: 2006484,
      smses: 143382,
    },
    {
      date: "2020-07-19",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9698589,
      accounts: 671124,
      dataSent: 10801804,
      dataReceived: 93186741,
      callsIn: 568423,
      callsInDuration: 1824380,
      callsOut: 557358,
      callsOutDuration: 1621817,
      smses: 124896,
    },
    {
      date: "2020-07-20",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11232981,
      accounts: 686526,
      dataSent: 13453004,
      dataReceived: 100894671,
      callsIn: 1190143,
      callsInDuration: 3314008,
      callsOut: 1099900,
      callsOutDuration: 3018384,
      smses: 175398,
    },
    {
      date: "2020-07-21",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10967118,
      accounts: 686737,
      dataSent: 12469410,
      dataReceived: 95829446,
      callsIn: 1091171,
      callsInDuration: 3189273,
      callsOut: 993916,
      callsOutDuration: 2880219,
      smses: 167882,
    },
    {
      date: "2020-07-22",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10927734,
      accounts: 687390,
      dataSent: 12341629,
      dataReceived: 95928888,
      callsIn: 1078946,
      callsInDuration: 3185272,
      callsOut: 979020,
      callsOutDuration: 2900390,
      smses: 158051,
    },
    {
      date: "2020-07-23",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10803732,
      accounts: 687559,
      dataSent: 12312941,
      dataReceived: 98856462,
      callsIn: 1073068,
      callsInDuration: 3179146,
      callsOut: 962652,
      callsOutDuration: 2871784,
      smses: 163448,
    },
    {
      date: "2020-07-24",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 10980919,
      accounts: 693268,
      dataSent: 12423191,
      dataReceived: 99053023,
      callsIn: 1173190,
      callsInDuration: 3115076,
      callsOut: 1060834,
      callsOutDuration: 2831060,
      smses: 155901,
    },
    {
      date: "2020-07-25",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10460307,
      accounts: 692625,
      dataSent: 11508484,
      dataReceived: 94514669,
      callsIn: 878943,
      callsInDuration: 2230084,
      callsOut: 831555,
      callsOutDuration: 2006968,
      smses: 141232,
    },
    {
      date: "2020-07-26",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9578409,
      accounts: 680711,
      dataSent: 10400492,
      dataReceived: 89647720,
      callsIn: 553649,
      callsInDuration: 1830926,
      callsOut: 556651,
      callsOutDuration: 1719443,
      smses: 125422,
    },
    {
      date: "2020-07-27",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 10810563,
      accounts: 692113,
      dataSent: 12035928,
      dataReceived: 95065095,
      callsIn: 1120253,
      callsInDuration: 3190820,
      callsOut: 1046630,
      callsOutDuration: 3003615,
      smses: 155436,
    },
    {
      date: "2020-07-28",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10738339,
      accounts: 694663,
      dataSent: 12100966,
      dataReceived: 93789289,
      callsIn: 1142846,
      callsInDuration: 3256034,
      callsOut: 1053284,
      callsOutDuration: 3005600,
      smses: 152412,
    },
    {
      date: "2020-07-29",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 10957893,
      accounts: 697922,
      dataSent: 12379820,
      dataReceived: 97345917,
      callsIn: 1169991,
      callsInDuration: 3268148,
      callsOut: 1067649,
      callsOutDuration: 3016723,
      smses: 148574,
    },
    {
      date: "2020-07-30",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 10919333,
      accounts: 700111,
      dataSent: 12369808,
      dataReceived: 96163213,
      callsIn: 1157889,
      callsInDuration: 3231646,
      callsOut: 1048302,
      callsOutDuration: 2960198,
      smses: 147067,
    },
    {
      date: "2020-07-31",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 11158541,
      accounts: 709122,
      dataSent: 12675723,
      dataReceived: 101301863,
      callsIn: 1223899,
      callsInDuration: 3161324,
      callsOut: 1124581,
      callsOutDuration: 2943002,
      smses: 151868,
    },
    {
      date: "2020-08-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10924012,
      accounts: 710244,
      dataSent: 11787625,
      dataReceived: 97007921,
      callsIn: 907566,
      callsInDuration: 2256435,
      callsOut: 871054,
      callsOutDuration: 2042722,
      smses: 136834,
    },
    {
      date: "2020-08-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9957136,
      accounts: 695992,
      dataSent: 10597991,
      dataReceived: 90933969,
      callsIn: 569664,
      callsInDuration: 1839184,
      callsOut: 561329,
      callsOutDuration: 1651730,
      smses: 125541,
    },
    {
      date: "2020-08-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11240506,
      accounts: 708631,
      dataSent: 12125979,
      dataReceived: 95367648,
      callsIn: 1150034,
      callsInDuration: 3244691,
      callsOut: 1095875,
      callsOutDuration: 3079129,
      smses: 153231,
    },
    {
      date: "2020-08-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11361236,
      accounts: 713902,
      dataSent: 12745055,
      dataReceived: 99058983,
      callsIn: 1170325,
      callsInDuration: 3281339,
      callsOut: 1091758,
      callsOutDuration: 3065765,
      smses: 156292,
    },
    {
      date: "2020-08-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 11544053,
      accounts: 718150,
      dataSent: 12700763,
      dataReceived: 99399463,
      callsIn: 1172822,
      callsInDuration: 3309598,
      callsOut: 1101355,
      callsOutDuration: 3078714,
      smses: 154679,
    },
    {
      date: "2020-08-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 11267368,
      accounts: 720790,
      dataSent: 12914646,
      dataReceived: 101504450,
      callsIn: 1147120,
      callsInDuration: 3328061,
      callsOut: 1082642,
      callsOutDuration: 3090058,
      smses: 164860,
    },
    {
      date: "2020-08-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 11458678,
      accounts: 724529,
      dataSent: 13183140,
      dataReceived: 103064959,
      callsIn: 1199656,
      callsInDuration: 3238673,
      callsOut: 1135037,
      callsOutDuration: 3026274,
      smses: 145264,
    },
    {
      date: "2020-08-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10887088,
      accounts: 723269,
      dataSent: 11860519,
      dataReceived: 98473806,
      callsIn: 910708,
      callsInDuration: 2330174,
      callsOut: 886045,
      callsOutDuration: 2127144,
      smses: 127112,
    },
    {
      date: "2020-08-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9817185,
      accounts: 707781,
      dataSent: 10548824,
      dataReceived: 92652621,
      callsIn: 557509,
      callsInDuration: 1889672,
      callsOut: 561738,
      callsOutDuration: 1695319,
      smses: 113689,
    },
    {
      date: "2020-08-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11183919,
      accounts: 724747,
      dataSent: 12368288,
      dataReceived: 96601464,
      callsIn: 1185577,
      callsInDuration: 3313121,
      callsOut: 1138749,
      callsOutDuration: 3158291,
      smses: 156946,
    },
    {
      date: "2020-08-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 10980497,
      accounts: 730537,
      dataSent: 12188612,
      dataReceived: 94021733,
      callsIn: 1182946,
      callsInDuration: 3323370,
      callsOut: 1112113,
      callsOutDuration: 3128984,
      smses: 157804,
    },
    {
      date: "2020-08-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 11363733,
      accounts: 742350,
      dataSent: 12613529,
      dataReceived: 97293601,
      callsIn: 1195279,
      callsInDuration: 3333650,
      callsOut: 1120110,
      callsOutDuration: 3112701,
      smses: 152681,
    },
    {
      date: "2020-08-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 11583263,
      accounts: 755557,
      dataSent: 13032468,
      dataReceived: 100410096,
      callsIn: 1210130,
      callsInDuration: 3362804,
      callsOut: 1118412,
      callsOutDuration: 3094068,
      smses: 152040,
    },
    {
      date: "2020-08-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 11944940,
      accounts: 765599,
      dataSent: 13448959,
      dataReceived: 105596481,
      callsIn: 1276623,
      callsInDuration: 3233022,
      callsOut: 1184673,
      callsOutDuration: 3005702,
      smses: 157685,
    },
    {
      date: "2020-08-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11335769,
      accounts: 762089,
      dataSent: 12031576,
      dataReceived: 100348781,
      callsIn: 991886,
      callsInDuration: 2328447,
      callsOut: 945076,
      callsOutDuration: 2122678,
      smses: 143147,
    },
    {
      date: "2020-08-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10613281,
      accounts: 748020,
      dataSent: 11482242,
      dataReceived: 95127899,
      callsIn: 649027,
      callsInDuration: 1856289,
      callsOut: 631506,
      callsOutDuration: 1657694,
      smses: 130435,
    },
    {
      date: "2020-08-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 10260951,
      accounts: 742092,
      dataSent: 10894949,
      dataReceived: 91956626,
      callsIn: 707761,
      callsInDuration: 2103518,
      callsOut: 651589,
      callsOutDuration: 1853403,
      smses: 133012,
    },
    {
      date: "2020-08-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11379196,
      accounts: 753276,
      dataSent: 12670383,
      dataReceived: 101345432,
      callsIn: 1183972,
      callsInDuration: 3239430,
      callsOut: 1108718,
      callsOutDuration: 3072244,
      smses: 159427,
    },
    {
      date: "2020-08-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 11279695,
      accounts: 746285,
      dataSent: 12746526,
      dataReceived: 102705426,
      callsIn: 1161437,
      callsInDuration: 3303457,
      callsOut: 1059550,
      callsOutDuration: 3058683,
      smses: 157204,
    },
    {
      date: "2020-08-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 11105223,
      accounts: 743998,
      dataSent: 12871580,
      dataReceived: 99547404,
      callsIn: 1207318,
      callsInDuration: 3372594,
      callsOut: 1090465,
      callsOutDuration: 3118149,
      smses: 154126,
    },
    {
      date: "2020-08-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 11442053,
      accounts: 741014,
      dataSent: 13475253,
      dataReceived: 106514573,
      callsIn: 1271266,
      callsInDuration: 3285053,
      callsOut: 1167631,
      callsOutDuration: 3045115,
      smses: 166459,
    },
    {
      date: "2020-08-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10878786,
      accounts: 736679,
      dataSent: 12167845,
      dataReceived: 100847660,
      callsIn: 926049,
      callsInDuration: 2331111,
      callsOut: 880837,
      callsOutDuration: 2119612,
      smses: 136464,
    },
    {
      date: "2020-08-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 9913242,
      accounts: 722479,
      dataSent: 10933913,
      dataReceived: 95488244,
      callsIn: 577769,
      callsInDuration: 1886227,
      callsOut: 567053,
      callsOutDuration: 1705308,
      smses: 123052,
    },
    {
      date: "2020-08-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11178518,
      accounts: 735802,
      dataSent: 12835493,
      dataReceived: 101284515,
      callsIn: 1168534,
      callsInDuration: 3335727,
      callsOut: 1079814,
      callsOutDuration: 3126395,
      smses: 161515,
    },
    {
      date: "2020-08-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11191977,
      accounts: 734850,
      dataSent: 12749208,
      dataReceived: 102907457,
      callsIn: 1136672,
      callsInDuration: 3344447,
      callsOut: 1020123,
      callsOutDuration: 3066396,
      smses: 159714,
    },
    {
      date: "2020-08-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 12048542,
      accounts: 737849,
      dataSent: 13162452,
      dataReceived: 105486592,
      callsIn: 1150213,
      callsInDuration: 3400912,
      callsOut: 1033596,
      callsOutDuration: 3112238,
      smses: 162692,
    },
    {
      date: "2020-08-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 11564118,
      accounts: 742472,
      dataSent: 13389088,
      dataReceived: 105351687,
      callsIn: 1186594,
      callsInDuration: 3395531,
      callsOut: 1072567,
      callsOutDuration: 3135079,
      smses: 152007,
    },
    {
      date: "2020-08-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 11815192,
      accounts: 746863,
      dataSent: 13817244,
      dataReceived: 109281581,
      callsIn: 1225778,
      callsInDuration: 3336339,
      callsOut: 1128419,
      callsOutDuration: 3093787,
      smses: 162721,
    },
    {
      date: "2020-08-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11023958,
      accounts: 739705,
      dataSent: 12293937,
      dataReceived: 105486147,
      callsIn: 882624,
      callsInDuration: 2376023,
      callsOut: 830406,
      callsOutDuration: 2133326,
      smses: 144618,
    },
    {
      date: "2020-08-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10143735,
      accounts: 728721,
      dataSent: 11120524,
      dataReceived: 96869799,
      callsIn: 578826,
      callsInDuration: 1946732,
      callsOut: 563052,
      callsOutDuration: 1756114,
      smses: 126248,
    },
    {
      date: "2020-08-31",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14897694,
      accounts: 740305,
      dataSent: 11575468,
      dataReceived: 92380674,
      callsIn: 1169060,
      callsInDuration: 3394135,
      callsOut: 1091864,
      callsOutDuration: 3228256,
      smses: 162760,
    },
    {
      date: "2020-09-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11210088,
      accounts: 742108,
      dataSent: 12548026,
      dataReceived: 103270536,
      callsIn: 1146060,
      callsInDuration: 3391079,
      callsOut: 1067177,
      callsOutDuration: 3195712,
      smses: 156940,
    },
    {
      date: "2020-09-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 11338411,
      accounts: 741162,
      dataSent: 12939631,
      dataReceived: 109182680,
      callsIn: 1108642,
      callsInDuration: 3383490,
      callsOut: 1035844,
      callsOutDuration: 3163799,
      smses: 163358,
    },
    {
      date: "2020-09-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 17340359,
      accounts: 745878,
      dataSent: 10728849,
      dataReceived: 83637753,
      callsIn: 1236859,
      callsInDuration: 3447888,
      callsOut: 1154006,
      callsOutDuration: 3237082,
      smses: 187615,
    },
    {
      date: "2020-09-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 13069906,
      accounts: 754951,
      dataSent: 13787761,
      dataReceived: 109273206,
      callsIn: 1289369,
      callsInDuration: 3384202,
      callsOut: 1202355,
      callsOutDuration: 3162109,
      smses: 164505,
    },
    {
      date: "2020-09-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11535035,
      accounts: 752576,
      dataSent: 12409263,
      dataReceived: 103051156,
      callsIn: 962361,
      callsInDuration: 2411580,
      callsOut: 927756,
      callsOutDuration: 2227969,
      smses: 143432,
    },
    {
      date: "2020-09-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10041931,
      accounts: 735845,
      dataSent: 10587707,
      dataReceived: 93198156,
      callsIn: 587663,
      callsInDuration: 1962213,
      callsOut: 581603,
      callsOutDuration: 1778972,
      smses: 124710,
    },
    {
      date: "2020-09-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11408377,
      accounts: 748698,
      dataSent: 12400341,
      dataReceived: 98714594,
      callsIn: 1214007,
      callsInDuration: 3392209,
      callsOut: 1137239,
      callsOutDuration: 3245209,
      smses: 156658,
    },
    {
      date: "2020-09-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11758157,
      accounts: 752538,
      dataSent: 13304309,
      dataReceived: 104825811,
      callsIn: 1227150,
      callsInDuration: 3459369,
      callsOut: 1126919,
      callsOutDuration: 3243040,
      smses: 160158,
    },
    {
      date: "2020-09-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 11921774,
      accounts: 754633,
      dataSent: 13648928,
      dataReceived: 107800421,
      callsIn: 1227667,
      callsInDuration: 3459160,
      callsOut: 1115915,
      callsOutDuration: 3226612,
      smses: 159056,
    },
    {
      date: "2020-09-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 12161832,
      accounts: 758650,
      dataSent: 13910258,
      dataReceived: 110336246,
      callsIn: 1258167,
      callsInDuration: 3416794,
      callsOut: 1129766,
      callsOutDuration: 3195328,
      smses: 153838,
    },
    {
      date: "2020-09-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 12273890,
      accounts: 761374,
      dataSent: 13863334,
      dataReceived: 112641404,
      callsIn: 1296489,
      callsInDuration: 3305271,
      callsOut: 1170966,
      callsOutDuration: 3101973,
      smses: 158531,
    },
    {
      date: "2020-09-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11562049,
      accounts: 757840,
      dataSent: 12589377,
      dataReceived: 106923459,
      callsIn: 959757,
      callsInDuration: 2363715,
      callsOut: 906614,
      callsOutDuration: 2183717,
      smses: 139052,
    },
    {
      date: "2020-09-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10689979,
      accounts: 744689,
      dataSent: 11346050,
      dataReceived: 100582225,
      callsIn: 597922,
      callsInDuration: 1920303,
      callsOut: 586375,
      callsOutDuration: 1751880,
      smses: 127796,
    },
    {
      date: "2020-09-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11658535,
      accounts: 755428,
      dataSent: 12981209,
      dataReceived: 104699359,
      callsIn: 1220163,
      callsInDuration: 3424518,
      callsOut: 1119503,
      callsOutDuration: 3217887,
      smses: 153456,
    },
    {
      date: "2020-09-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11703629,
      accounts: 757410,
      dataSent: 13157536,
      dataReceived: 105735564,
      callsIn: 1216309,
      callsInDuration: 3457491,
      callsOut: 1112185,
      callsOutDuration: 3228547,
      smses: 154612,
    },
    {
      date: "2020-09-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 11382777,
      accounts: 760953,
      dataSent: 12665615,
      dataReceived: 98342689,
      callsIn: 1244780,
      callsInDuration: 3501002,
      callsOut: 1144231,
      callsOutDuration: 3292314,
      smses: 157576,
    },
    {
      date: "2020-09-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 12686092,
      accounts: 767824,
      dataSent: 13703713,
      dataReceived: 109116659,
      callsIn: 1253936,
      callsInDuration: 3293274,
      callsOut: 1132464,
      callsOutDuration: 3056347,
      smses: 162172,
    },
    {
      date: "2020-09-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 12447505,
      accounts: 773947,
      dataSent: 14218806,
      dataReceived: 114106157,
      callsIn: 1297208,
      callsInDuration: 3298092,
      callsOut: 1194490,
      callsOutDuration: 3067846,
      smses: 158622,
    },
    {
      date: "2020-09-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11697610,
      accounts: 769340,
      dataSent: 12703410,
      dataReceived: 107947773,
      callsIn: 953619,
      callsInDuration: 2341611,
      callsOut: 907746,
      callsOutDuration: 2141717,
      smses: 143642,
    },
    {
      date: "2020-09-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10674589,
      accounts: 755018,
      dataSent: 11450486,
      dataReceived: 101367231,
      callsIn: 597428,
      callsInDuration: 1902804,
      callsOut: 585122,
      callsOutDuration: 1737673,
      smses: 127336,
    },
    {
      date: "2020-09-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11727681,
      accounts: 764373,
      dataSent: 12968394,
      dataReceived: 106700400,
      callsIn: 1200323,
      callsInDuration: 3248350,
      callsOut: 1081003,
      callsOutDuration: 3010578,
      smses: 153493,
    },
    {
      date: "2020-09-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11959787,
      accounts: 766673,
      dataSent: 13497406,
      dataReceived: 109363136,
      callsIn: 1242027,
      callsInDuration: 3359175,
      callsOut: 1116832,
      callsOutDuration: 3139699,
      smses: 159128,
    },
    {
      date: "2020-09-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 12122199,
      accounts: 769014,
      dataSent: 13566774,
      dataReceived: 112110016,
      callsIn: 1251229,
      callsInDuration: 3379361,
      callsOut: 1127899,
      callsOutDuration: 3148452,
      smses: 160613,
    },
    {
      date: "2020-09-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 12184286,
      accounts: 770733,
      dataSent: 13533962,
      dataReceived: 111283956,
      callsIn: 1243593,
      callsInDuration: 3319209,
      callsOut: 1112213,
      callsOutDuration: 3060182,
      smses: 155954,
    },
    {
      date: "2020-09-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 20070200,
      accounts: 766520,
      dataSent: 10478819,
      dataReceived: 87834513,
      callsIn: 1332337,
      callsInDuration: 3280246,
      callsOut: 1200661,
      callsOutDuration: 3073239,
      smses: 211806,
    },
    {
      date: "2020-09-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11506494,
      accounts: 761460,
      dataSent: 12125110,
      dataReceived: 109923546,
      callsIn: 902953,
      callsInDuration: 2330541,
      callsOut: 853816,
      callsOutDuration: 2127889,
      smses: 150603,
    },
    {
      date: "2020-09-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10538833,
      accounts: 747082,
      dataSent: 10670868,
      dataReceived: 100615756,
      callsIn: 569971,
      callsInDuration: 1896860,
      callsOut: 563531,
      callsOutDuration: 1727864,
      smses: 133683,
    },
    {
      date: "2020-09-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11588468,
      accounts: 757812,
      dataSent: 10843008,
      dataReceived: 89793720,
      callsIn: 1204851,
      callsInDuration: 3254440,
      callsOut: 1105936,
      callsOutDuration: 3103807,
      smses: 156523,
    },
    {
      date: "2020-09-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11928921,
      accounts: 766517,
      dataSent: 12955847,
      dataReceived: 107812114,
      callsIn: 1265564,
      callsInDuration: 3405327,
      callsOut: 1155163,
      callsOutDuration: 3207750,
      smses: 162850,
    },
    {
      date: "2020-09-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 12160551,
      accounts: 770893,
      dataSent: 13256949,
      dataReceived: 110278197,
      callsIn: 1282832,
      callsInDuration: 3409715,
      callsOut: 1166928,
      callsOutDuration: 3222528,
      smses: 163234,
    },
    {
      date: "2020-10-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 12222240,
      accounts: 773962,
      dataSent: 13385277,
      dataReceived: 110742650,
      callsIn: 1259091,
      callsInDuration: 3485718,
      callsOut: 1153727,
      callsOutDuration: 3305850,
      smses: 156731,
    },
    {
      date: "2020-10-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 12441829,
      accounts: 777944,
      dataSent: 13671957,
      dataReceived: 113617879,
      callsIn: 1318850,
      callsInDuration: 3392965,
      callsOut: 1236228,
      callsOutDuration: 3227273,
      smses: 164754,
    },
    {
      date: "2020-10-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11720058,
      accounts: 774374,
      dataSent: 12230489,
      dataReceived: 109295813,
      callsIn: 950800,
      callsInDuration: 2341188,
      callsOut: 920098,
      callsOutDuration: 2183677,
      smses: 142806,
    },
    {
      date: "2020-10-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10406819,
      accounts: 757242,
      dataSent: 10622959,
      dataReceived: 101951754,
      callsIn: 578985,
      callsInDuration: 1893379,
      callsOut: 574359,
      callsOutDuration: 1725579,
      smses: 131995,
    },
    {
      date: "2020-10-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 12358950,
      accounts: 772561,
      dataSent: 12851628,
      dataReceived: 108549666,
      callsIn: 1232804,
      callsInDuration: 3320021,
      callsOut: 1165033,
      callsOutDuration: 3243217,
      smses: 163176,
    },
    {
      date: "2020-10-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 12206281,
      accounts: 776481,
      dataSent: 13324631,
      dataReceived: 111822707,
      callsIn: 1261280,
      callsInDuration: 3394489,
      callsOut: 1169976,
      callsOutDuration: 3246404,
      smses: 157194,
    },
    {
      date: "2020-10-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 12454238,
      accounts: 780950,
      dataSent: 13576506,
      dataReceived: 113150706,
      callsIn: 1282633,
      callsInDuration: 3412974,
      callsOut: 1183691,
      callsOutDuration: 3249713,
      smses: 157649,
    },
    {
      date: "2020-10-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 12478877,
      accounts: 785037,
      dataSent: 13670912,
      dataReceived: 115237153,
      callsIn: 1301691,
      callsInDuration: 3362631,
      callsOut: 1195726,
      callsOutDuration: 3212187,
      smses: 162233,
    },
    {
      date: "2020-10-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 12818550,
      accounts: 789633,
      dataSent: 14126230,
      dataReceived: 117739185,
      callsIn: 1384446,
      callsInDuration: 3311874,
      callsOut: 1272865,
      callsOutDuration: 3165339,
      smses: 163587,
    },
    {
      date: "2020-10-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 15662633,
      accounts: 781732,
      dataSent: 7713533,
      dataReceived: 67340913,
      callsIn: 1089491,
      callsInDuration: 2359807,
      callsOut: 1052555,
      callsOutDuration: 2245704,
      smses: 174980,
    },
    {
      date: "2020-10-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10950062,
      accounts: 771138,
      dataSent: 11490286,
      dataReceived: 108779518,
      callsIn: 633015,
      callsInDuration: 1801002,
      callsOut: 624449,
      callsOutDuration: 1669963,
      smses: 132505,
    },
    {
      date: "2020-10-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11265427,
      accounts: 771225,
      dataSent: 11870665,
      dataReceived: 105918487,
      callsIn: 805625,
      callsInDuration: 2209801,
      callsOut: 731153,
      callsOutDuration: 2012006,
      smses: 142734,
    },
    {
      date: "2020-10-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 12069903,
      accounts: 782114,
      dataSent: 12834711,
      dataReceived: 113387591,
      callsIn: 1327367,
      callsInDuration: 3361477,
      callsOut: 1219442,
      callsOutDuration: 3273591,
      smses: 164919,
    },
    {
      date: "2020-10-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 12426655,
      accounts: 785720,
      dataSent: 13172633,
      dataReceived: 115157557,
      callsIn: 1345017,
      callsInDuration: 3423919,
      callsOut: 1223324,
      callsOutDuration: 3290549,
      smses: 162924,
    },
    {
      date: "2020-10-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 12600961,
      accounts: 789184,
      dataSent: 13550278,
      dataReceived: 119262346,
      callsIn: 1345695,
      callsInDuration: 3468389,
      callsOut: 1217688,
      callsOutDuration: 3274963,
      smses: 159404,
    },
    {
      date: "2020-10-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 13273267,
      accounts: 795636,
      dataSent: 14270487,
      dataReceived: 125548856,
      callsIn: 1458567,
      callsInDuration: 3408203,
      callsOut: 1345696,
      callsOutDuration: 3224071,
      smses: 174224,
    },
    {
      date: "2020-10-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 10857631,
      accounts: 786962,
      dataSent: 10490545,
      dataReceived: 97845617,
      callsIn: 1142049,
      callsInDuration: 2397429,
      callsOut: 1095881,
      callsOutDuration: 2240909,
      smses: 149266,
    },
    {
      date: "2020-10-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 11993482,
      accounts: 782703,
      dataSent: 12104080,
      dataReceived: 112419381,
      callsIn: 728413,
      callsInDuration: 1969958,
      callsOut: 815275,
      callsOutDuration: 2107230,
      smses: 154658,
    },
    {
      date: "2020-10-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 12268421,
      accounts: 784992,
      dataSent: 12567596,
      dataReceived: 115450408,
      callsIn: 1247242,
      callsInDuration: 3326075,
      callsOut: 1130886,
      callsOutDuration: 3125661,
      smses: 168645,
    },
    {
      date: "2020-10-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 11957921,
      accounts: 783808,
      dataSent: 12475764,
      dataReceived: 113010033,
      callsIn: 1253844,
      callsInDuration: 3377057,
      callsOut: 1110299,
      callsOutDuration: 3134485,
      smses: 165177,
    },
    {
      date: "2020-10-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 12364862,
      accounts: 785936,
      dataSent: 13042411,
      dataReceived: 116943360,
      callsIn: 1265751,
      callsInDuration: 3373651,
      callsOut: 1126453,
      callsOutDuration: 3167385,
      smses: 164608,
    },
    {
      date: "2020-10-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 12383653,
      accounts: 785878,
      dataSent: 13265870,
      dataReceived: 122019799,
      callsIn: 1239681,
      callsInDuration: 3384342,
      callsOut: 1095549,
      callsOutDuration: 3147011,
      smses: 166943,
    },
    {
      date: "2020-10-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 12992910,
      accounts: 793411,
      dataSent: 13856935,
      dataReceived: 124282336,
      callsIn: 1372650,
      callsInDuration: 3286149,
      callsOut: 1220672,
      callsOutDuration: 3091900,
      smses: 165772,
    },
    {
      date: "2020-10-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 12524838,
      accounts: 791992,
      dataSent: 12669555,
      dataReceived: 118593545,
      callsIn: 1019493,
      callsInDuration: 2286159,
      callsOut: 957659,
      callsOutDuration: 2114108,
      smses: 150805,
    },
    {
      date: "2020-10-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 10882092,
      accounts: 771232,
      dataSent: 10706175,
      dataReceived: 107100599,
      callsIn: 567991,
      callsInDuration: 1860359,
      callsOut: 561827,
      callsOutDuration: 1701879,
      smses: 135462,
    },
    {
      date: "2020-10-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 12583896,
      accounts: 787200,
      dataSent: 12769600,
      dataReceived: 112735661,
      callsIn: 1309809,
      callsInDuration: 3361741,
      callsOut: 1188121,
      callsOutDuration: 3230026,
      smses: 160943,
    },
    {
      date: "2020-10-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 12715694,
      accounts: 791512,
      dataSent: 13224785,
      dataReceived: 116017220,
      callsIn: 1304490,
      callsInDuration: 3385963,
      callsOut: 1166531,
      callsOutDuration: 3215081,
      smses: 163610,
    },
    {
      date: "2020-10-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14050163,
      accounts: 790953,
      dataSent: 11609077,
      dataReceived: 101366560,
      callsIn: 1296303,
      callsInDuration: 3304369,
      callsOut: 1130998,
      callsOutDuration: 3121579,
      smses: 159140,
    },
    {
      date: "2020-10-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 11910383,
      accounts: 792071,
      dataSent: 12295198,
      dataReceived: 107859633,
      callsIn: 1315181,
      callsInDuration: 3182080,
      callsOut: 1138104,
      callsOutDuration: 3031974,
      smses: 136976,
    },
    {
      date: "2020-10-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 13546297,
      accounts: 801771,
      dataSent: 13872040,
      dataReceived: 120824736,
      callsIn: 1419676,
      callsInDuration: 3207778,
      callsOut: 1250648,
      callsOutDuration: 3088825,
      smses: 157283,
    },
    {
      date: "2020-10-31",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 13708074,
      accounts: 802985,
      dataSent: 12866233,
      dataReceived: 118264751,
      callsIn: 1069967,
      callsInDuration: 2196945,
      callsOut: 1011066,
      callsOutDuration: 2093741,
      smses: 144204,
    },
    {
      date: "2020-11-01",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 11872899,
      accounts: 790275,
      dataSent: 11605505,
      dataReceived: 111898544,
      callsIn: 680545,
      callsInDuration: 1760649,
      callsOut: 676364,
      callsOutDuration: 1664020,
      smses: 130179,
    },
    {
      date: "2020-11-02",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 12697857,
      accounts: 799482,
      dataSent: 13195783,
      dataReceived: 115426283,
      callsIn: 1324846,
      callsInDuration: 3311744,
      callsOut: 1212442,
      callsOutDuration: 3287009,
      smses: 161519,
    },
    {
      date: "2020-11-03",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 12860675,
      accounts: 801018,
      dataSent: 13482869,
      dataReceived: 119769499,
      callsIn: 1346856,
      callsInDuration: 3312687,
      callsOut: 1200024,
      callsOutDuration: 3245022,
      smses: 166029,
    },
    {
      date: "2020-11-04",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 13017206,
      accounts: 803280,
      dataSent: 13593659,
      dataReceived: 118810166,
      callsIn: 1329924,
      callsInDuration: 3358181,
      callsOut: 1206723,
      callsOutDuration: 3253982,
      smses: 161674,
    },
    {
      date: "2020-11-05",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 13231829,
      accounts: 808107,
      dataSent: 14130914,
      dataReceived: 123680062,
      callsIn: 1366014,
      callsInDuration: 3386156,
      callsOut: 1241766,
      callsOutDuration: 3277904,
      smses: 163182,
    },
    {
      date: "2020-11-06",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 13506192,
      accounts: 814502,
      dataSent: 14479024,
      dataReceived: 126037837,
      callsIn: 1438320,
      callsInDuration: 3253282,
      callsOut: 1309268,
      callsOutDuration: 3134257,
      smses: 164940,
    },
    {
      date: "2020-11-07",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11194795,
      accounts: 808597,
      dataSent: 10620861,
      dataReceived: 99264834,
      callsIn: 1096986,
      callsInDuration: 2242776,
      callsOut: 1054385,
      callsOutDuration: 2135714,
      smses: 156609,
    },
    {
      date: "2020-11-08",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 11858725,
      accounts: 801336,
      dataSent: 12049499,
      dataReceived: 117963989,
      callsIn: 689374,
      callsInDuration: 1766950,
      callsOut: 693108,
      callsOutDuration: 1690300,
      smses: 138617,
    },
    {
      date: "2020-11-09",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 12883217,
      accounts: 810626,
      dataSent: 13156518,
      dataReceived: 120023700,
      callsIn: 1312954,
      callsInDuration: 3315572,
      callsOut: 1223916,
      callsOutDuration: 3290966,
      smses: 169227,
    },
    {
      date: "2020-11-10",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 19466566,
      accounts: 810490,
      dataSent: 9347763,
      dataReceived: 84585000,
      callsIn: 1352045,
      callsInDuration: 3326640,
      callsOut: 1245792,
      callsOutDuration: 3241235,
      smses: 174147,
    },
    {
      date: "2020-11-11",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 16435274,
      accounts: 817249,
      dataSent: 13135937,
      dataReceived: 116931038,
      callsIn: 1369472,
      callsInDuration: 3352243,
      callsOut: 1256876,
      callsOutDuration: 3272443,
      smses: 171869,
    },
    {
      date: "2020-11-12",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 13420149,
      accounts: 820659,
      dataSent: 14040631,
      dataReceived: 127973666,
      callsIn: 1387191,
      callsInDuration: 3326714,
      callsOut: 1261237,
      callsOutDuration: 3212083,
      smses: 170127,
    },
    {
      date: "2020-11-13",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14158012,
      accounts: 835176,
      dataSent: 14664790,
      dataReceived: 131806614,
      callsIn: 1449872,
      callsInDuration: 3239545,
      callsOut: 1320371,
      callsOutDuration: 3137078,
      smses: 175882,
    },
    {
      date: "2020-11-14",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 13694455,
      accounts: 830489,
      dataSent: 13456716,
      dataReceived: 130756318,
      callsIn: 1054012,
      callsInDuration: 2243105,
      callsOut: 996913,
      callsOutDuration: 2137173,
      smses: 151682,
    },
    {
      date: "2020-11-15",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 12377612,
      accounts: 814852,
      dataSent: 12228601,
      dataReceived: 120274638,
      callsIn: 700877,
      callsInDuration: 1738591,
      callsOut: 686599,
      callsOutDuration: 1654712,
      smses: 140588,
    },
    {
      date: "2020-11-16",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 12982074,
      accounts: 820067,
      dataSent: 13430307,
      dataReceived: 121684239,
      callsIn: 1354214,
      callsInDuration: 3289954,
      callsOut: 1230308,
      callsOutDuration: 3223674,
      smses: 169025,
    },
    {
      date: "2020-11-17",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14446450,
      accounts: 810249,
      dataSent: 6391157,
      dataReceived: 61773336,
      callsIn: 1344109,
      callsInDuration: 3200775,
      callsOut: 1212326,
      callsOutDuration: 3112465,
      smses: 171999,
    },
    {
      date: "2020-11-18",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 13086968,
      accounts: 809192,
      dataSent: 7906000,
      dataReceived: 73409142,
      callsIn: 1397940,
      callsInDuration: 3320773,
      callsOut: 1236482,
      callsOutDuration: 3214658,
      smses: 173829,
    },
    {
      date: "2020-11-19",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 17205030,
      accounts: 819968,
      dataSent: 11286075,
      dataReceived: 104410211,
      callsIn: 1416381,
      callsInDuration: 3320734,
      callsOut: 1256550,
      callsOutDuration: 3216814,
      smses: 180110,
    },
    {
      date: "2020-11-20",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 13652563,
      accounts: 830370,
      dataSent: 14162943,
      dataReceived: 132004780,
      callsIn: 1495441,
      callsInDuration: 3207342,
      callsOut: 1359745,
      callsOutDuration: 3102716,
      smses: 174242,
    },
    {
      date: "2020-11-21",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 13675105,
      accounts: 839566,
      dataSent: 13969240,
      dataReceived: 134238724,
      callsIn: 1111735,
      callsInDuration: 2205692,
      callsOut: 1056141,
      callsOutDuration: 2088578,
      smses: 162570,
    },
    {
      date: "2020-11-22",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 12756869,
      accounts: 823582,
      dataSent: 13051844,
      dataReceived: 128472252,
      callsIn: 746831,
      callsInDuration: 1646275,
      callsOut: 748895,
      callsOutDuration: 1592711,
      smses: 147343,
    },
    {
      date: "2020-11-23",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 12201803,
      accounts: 813343,
      dataSent: 11548819,
      dataReceived: 114240316,
      callsIn: 820084,
      callsInDuration: 2001080,
      callsOut: 767568,
      callsOutDuration: 1870808,
      smses: 154580,
    },
    {
      date: "2020-11-24",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 13607866,
      accounts: 823196,
      dataSent: 14032105,
      dataReceived: 131841843,
      callsIn: 1356234,
      callsInDuration: 3251178,
      callsOut: 1255145,
      callsOutDuration: 3233940,
      smses: 184911,
    },
    {
      date: "2020-11-25",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 13186142,
      accounts: 825051,
      dataSent: 13791227,
      dataReceived: 126935837,
      callsIn: 1365501,
      callsInDuration: 3262234,
      callsOut: 1232117,
      callsOutDuration: 3180246,
      smses: 181173,
    },
    {
      date: "2020-11-26",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 13500036,
      accounts: 828545,
      dataSent: 14593909,
      dataReceived: 135529880,
      callsIn: 1360199,
      callsInDuration: 3240257,
      callsOut: 1225570,
      callsOutDuration: 3125007,
      smses: 167162,
    },
    {
      date: "2020-11-27",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 13682385,
      accounts: 833953,
      dataSent: 14660968,
      dataReceived: 133231176,
      callsIn: 1467693,
      callsInDuration: 3220225,
      callsOut: 1337405,
      callsOutDuration: 3130371,
      smses: 171842,
    },
    {
      date: "2020-11-28",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 13161656,
      accounts: 830778,
      dataSent: 13557472,
      dataReceived: 134326722,
      callsIn: 1089186,
      callsInDuration: 2252961,
      callsOut: 1037742,
      callsOutDuration: 2150790,
      smses: 160670,
    },
    {
      date: "2020-11-29",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 12319817,
      accounts: 819893,
      dataSent: 12168921,
      dataReceived: 122838244,
      callsIn: 714377,
      callsInDuration: 1750632,
      callsOut: 719612,
      callsOutDuration: 1687410,
      smses: 137531,
    },
    {
      date: "2020-11-30",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14093455,
      accounts: 830349,
      dataSent: 12820781,
      dataReceived: 116569541,
      callsIn: 1398702,
      callsInDuration: 3339696,
      callsOut: 1308029,
      callsOutDuration: 3347988,
      smses: 172281,
    },
    {
      date: "2020-12-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14576078,
      accounts: 970702,
      dataSent: 14316195,
      dataReceived: 131829121,
      callsIn: 1407621,
      callsInDuration: 3305062,
      callsOut: 1305581,
      callsOutDuration: 3292550,
      smses: 171643,
    },
    {
      date: "2020-12-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 13913599,
      accounts: 972672,
      dataSent: 14092879,
      dataReceived: 132025728,
      callsIn: 1417978,
      callsInDuration: 3374354,
      callsOut: 1303268,
      callsOutDuration: 3340623,
      smses: 177256,
    },
    {
      date: "2020-12-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 14247090,
      accounts: 977299,
      dataSent: 14331496,
      dataReceived: 130811717,
      callsIn: 1474746,
      callsInDuration: 3380082,
      callsOut: 1352051,
      callsOutDuration: 3348976,
      smses: 171276,
    },
    {
      date: "2020-12-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 15123226,
      accounts: 983613,
      dataSent: 14989211,
      dataReceived: 137405135,
      callsIn: 1514659,
      callsInDuration: 3172939,
      callsOut: 1399057,
      callsOutDuration: 3132848,
      smses: 173624,
    },
    {
      date: "2020-12-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 14365135,
      accounts: 978298,
      dataSent: 14426896,
      dataReceived: 139197411,
      callsIn: 1204861,
      callsInDuration: 2266430,
      callsOut: 1178574,
      callsOutDuration: 2210671,
      smses: 160247,
    },
    {
      date: "2020-12-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 13661309,
      accounts: 964851,
      dataSent: 13766497,
      dataReceived: 137520597,
      callsIn: 798487,
      callsInDuration: 1667627,
      callsOut: 808639,
      callsOutDuration: 1616472,
      smses: 143653,
    },
    {
      date: "2020-12-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 13522809,
      accounts: 966000,
      dataSent: 13598729,
      dataReceived: 136880412,
      callsIn: 939469,
      callsInDuration: 1993148,
      callsOut: 901175,
      callsOutDuration: 1901183,
      smses: 159923,
    },
    {
      date: "2020-12-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 13029617,
      accounts: 962282,
      dataSent: 12849577,
      dataReceived: 128334985,
      callsIn: 852532,
      callsInDuration: 2003118,
      callsOut: 813598,
      callsOutDuration: 1894646,
      smses: 155282,
    },
    {
      date: "2020-12-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14063359,
      accounts: 979405,
      dataSent: 14349278,
      dataReceived: 135534671,
      callsIn: 1515367,
      callsInDuration: 3275646,
      callsOut: 1431647,
      callsOutDuration: 3373603,
      smses: 184610,
    },
    {
      date: "2020-12-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 14669598,
      accounts: 982882,
      dataSent: 14810319,
      dataReceived: 138194323,
      callsIn: 1516002,
      callsInDuration: 3296115,
      callsOut: 1406567,
      callsOutDuration: 3280290,
      smses: 191954,
    },
    {
      date: "2020-12-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 15155475,
      accounts: 986360,
      dataSent: 15252219,
      dataReceived: 143071479,
      callsIn: 1591670,
      callsInDuration: 3293052,
      callsOut: 1462655,
      callsOutDuration: 3282351,
      smses: 179054,
    },
    {
      date: "2020-12-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 14762502,
      accounts: 980550,
      dataSent: 14542797,
      dataReceived: 143258814,
      callsIn: 1211728,
      callsInDuration: 2286746,
      callsOut: 1137762,
      callsOutDuration: 2213063,
      smses: 161737,
    },
    {
      date: "2020-12-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 13249429,
      accounts: 964974,
      dataSent: 12718429,
      dataReceived: 130253941,
      callsIn: 752432,
      callsInDuration: 1814974,
      callsOut: 741392,
      callsOutDuration: 1723925,
      smses: 143142,
    },
    {
      date: "2020-12-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14601649,
      accounts: 983898,
      dataSent: 14521281,
      dataReceived: 134971524,
      callsIn: 1503085,
      callsInDuration: 3365825,
      callsOut: 1390874,
      callsOutDuration: 3374659,
      smses: 185838,
    },
    {
      date: "2020-12-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14770958,
      accounts: 986794,
      dataSent: 14594959,
      dataReceived: 136478741,
      callsIn: 1514547,
      callsInDuration: 3358142,
      callsOut: 1365354,
      callsOutDuration: 3331754,
      smses: 181606,
    },
    {
      date: "2020-12-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14795403,
      accounts: 988424,
      dataSent: 14928074,
      dataReceived: 138715771,
      callsIn: 1536720,
      callsInDuration: 3357295,
      callsOut: 1403662,
      callsOutDuration: 3302180,
      smses: 177340,
    },
    {
      date: "2020-12-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 15018736,
      accounts: 990775,
      dataSent: 14959682,
      dataReceived: 138873028,
      callsIn: 1568390,
      callsInDuration: 3291532,
      callsOut: 1419423,
      callsOutDuration: 3233164,
      smses: 180141,
    },
    {
      date: "2020-12-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 15777328,
      accounts: 995985,
      dataSent: 15600993,
      dataReceived: 141934688,
      callsIn: 1693235,
      callsInDuration: 3220874,
      callsOut: 1577873,
      callsOutDuration: 3213976,
      smses: 191758,
    },
    {
      date: "2020-12-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 15783304,
      accounts: 993067,
      dataSent: 14943396,
      dataReceived: 142563396,
      callsIn: 1324984,
      callsInDuration: 2377816,
      callsOut: 1277102,
      callsOutDuration: 2324609,
      smses: 177764,
    },
    {
      date: "2020-12-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 14051773,
      accounts: 977887,
      dataSent: 13722796,
      dataReceived: 140216919,
      callsIn: 830373,
      callsInDuration: 1845850,
      callsOut: 835611,
      callsOutDuration: 1786447,
      smses: 151319,
    },
    {
      date: "2020-12-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 15351853,
      accounts: 993906,
      dataSent: 14714731,
      dataReceived: 138148387,
      callsIn: 1664808,
      callsInDuration: 3353097,
      callsOut: 1564332,
      callsOutDuration: 3401688,
      smses: 186207,
    },
    {
      date: "2020-12-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 15924120,
      accounts: 997573,
      dataSent: 14829183,
      dataReceived: 138465559,
      callsIn: 1763558,
      callsInDuration: 3327689,
      callsOut: 1619624,
      callsOutDuration: 3331694,
      smses: 199704,
    },
    {
      date: "2020-12-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 16033239,
      accounts: 999343,
      dataSent: 14783880,
      dataReceived: 141641875,
      callsIn: 1851123,
      callsInDuration: 3256555,
      callsOut: 1710079,
      callsOutDuration: 3261021,
      smses: 184547,
    },
    {
      date: "2020-12-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 14832415,
      accounts: 995381,
      dataSent: 14866637,
      dataReceived: 135490997,
      callsIn: 1476156,
      callsInDuration: 2425447,
      callsOut: 1378286,
      callsOutDuration: 2444708,
      smses: 155303,
    },
    {
      date: "2020-12-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14435318,
      accounts: 980903,
      dataSent: 14168090,
      dataReceived: 140317884,
      callsIn: 875347,
      callsInDuration: 1697537,
      callsOut: 879606,
      callsOutDuration: 1719172,
      smses: 165211,
    },
    {
      date: "2020-12-26",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 13997041,
      accounts: 980222,
      dataSent: 13849489,
      dataReceived: 138768394,
      callsIn: 1022139,
      callsInDuration: 1962454,
      callsOut: 970693,
      callsOutDuration: 1905771,
      smses: 158336,
    },
    {
      date: "2020-12-27",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 13546053,
      accounts: 972134,
      dataSent: 13212568,
      dataReceived: 137375726,
      callsIn: 741018,
      callsInDuration: 1638265,
      callsOut: 730858,
      callsOutDuration: 1578699,
      smses: 160467,
    },
    {
      date: "2020-12-28",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14618463,
      accounts: 990503,
      dataSent: 14140640,
      dataReceived: 138191899,
      callsIn: 1519828,
      callsInDuration: 3131772,
      callsOut: 1411298,
      callsOutDuration: 3290765,
      smses: 199844,
    },
    {
      date: "2020-12-29",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14872988,
      accounts: 993181,
      dataSent: 14770184,
      dataReceived: 141562191,
      callsIn: 1514329,
      callsInDuration: 3145110,
      callsOut: 1416867,
      callsOutDuration: 3222254,
      smses: 192724,
    },
    {
      date: "2020-12-30",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 15621448,
      accounts: 997517,
      dataSent: 15278143,
      dataReceived: 144829828,
      callsIn: 1615143,
      callsInDuration: 3236703,
      callsOut: 1493820,
      callsOutDuration: 3284041,
      smses: 193201,
    },
    {
      date: "2020-12-31",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 14869968,
      accounts: 994852,
      dataSent: 15751368,
      dataReceived: 141529173,
      callsIn: 1330097,
      callsInDuration: 2418854,
      callsOut: 1251386,
      callsOutDuration: 2413709,
      smses: 158676,
    },
    {
      date: "2021-01-01",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14942411,
      accounts: 981721,
      dataSent: 15234386,
      dataReceived: 147965814,
      callsIn: 902592,
      callsInDuration: 1702209,
      callsOut: 901172,
      callsOutDuration: 1706315,
      smses: 169566,
    },
    {
      date: "2021-01-02",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 14131923,
      accounts: 977544,
      dataSent: 14168541,
      dataReceived: 148046150,
      callsIn: 921110,
      callsInDuration: 1806223,
      callsOut: 916725,
      callsOutDuration: 1833579,
      smses: 153208,
    },
    {
      date: "2021-01-03",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 13740932,
      accounts: 970155,
      dataSent: 13037738,
      dataReceived: 133761543,
      callsIn: 698374,
      callsInDuration: 1580440,
      callsOut: 707986,
      callsOutDuration: 1577714,
      smses: 163911,
    },
    {
      date: "2021-01-04",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 13877223,
      accounts: 875492,
      dataSent: 14108113,
      dataReceived: 136968209,
      callsIn: 1364029,
      callsInDuration: 2995185,
      callsOut: 1336299,
      callsOutDuration: 3294659,
      smses: 191551,
    },
    {
      date: "2021-01-05",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 13983946,
      accounts: 853344,
      dataSent: 14387353,
      dataReceived: 141536523,
      callsIn: 1374587,
      callsInDuration: 3052258,
      callsOut: 1315300,
      callsOutDuration: 3245588,
      smses: 191564,
    },
    {
      date: "2021-01-06",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14198854,
      accounts: 857813,
      dataSent: 14926400,
      dataReceived: 145144748,
      callsIn: 1367600,
      callsInDuration: 3026134,
      callsOut: 1276846,
      callsOutDuration: 3158800,
      smses: 181081,
    },
    {
      date: "2021-01-07",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 14706357,
      accounts: 875067,
      dataSent: 15052416,
      dataReceived: 144237381,
      callsIn: 1398495,
      callsInDuration: 3064106,
      callsOut: 1318210,
      callsOutDuration: 3198000,
      smses: 189123,
    },
    {
      date: "2021-01-08",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 15216866,
      accounts: 885620,
      dataSent: 15640628,
      dataReceived: 149805952,
      callsIn: 1450141,
      callsInDuration: 2984960,
      callsOut: 1376087,
      callsOutDuration: 3099024,
      smses: 193968,
    },
    {
      date: "2021-01-09",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 14514124,
      accounts: 872477,
      dataSent: 15346299,
      dataReceived: 155045904,
      callsIn: 1091124,
      callsInDuration: 2084547,
      callsOut: 1068845,
      callsOutDuration: 2094801,
      smses: 179224,
    },
    {
      date: "2021-01-10",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 13679444,
      accounts: 860549,
      dataSent: 13931049,
      dataReceived: 142784183,
      callsIn: 738319,
      callsInDuration: 1627208,
      callsOut: 759339,
      callsOutDuration: 1620546,
      smses: 168796,
    },
    {
      date: "2021-01-11",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14489525,
      accounts: 869574,
      dataSent: 14957450,
      dataReceived: 152229965,
      callsIn: 1343114,
      callsInDuration: 2999058,
      callsOut: 1284916,
      callsOutDuration: 3209611,
      smses: 199594,
    },
    {
      date: "2021-01-12",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14588211,
      accounts: 874946,
      dataSent: 15416026,
      dataReceived: 140298890,
      callsIn: 1388407,
      callsInDuration: 3001254,
      callsOut: 1293515,
      callsOutDuration: 3135941,
      smses: 193087,
    },
    {
      date: "2021-01-13",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14594260,
      accounts: 877169,
      dataSent: 15543249,
      dataReceived: 139713339,
      callsIn: 1379313,
      callsInDuration: 2977689,
      callsOut: 1275481,
      callsOutDuration: 3084183,
      smses: 195962,
    },
    {
      date: "2021-01-14",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 14840449,
      accounts: 881684,
      dataSent: 15895864,
      dataReceived: 140226984,
      callsIn: 1372609,
      callsInDuration: 2973924,
      callsOut: 1277449,
      callsOutDuration: 3053017,
      smses: 196714,
    },
    {
      date: "2021-01-15",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14672620,
      accounts: 883387,
      dataSent: 15934567,
      dataReceived: 142228291,
      callsIn: 1393634,
      callsInDuration: 2926312,
      callsOut: 1301074,
      callsOutDuration: 2991959,
      smses: 199046,
    },
    {
      date: "2021-01-16",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 14368049,
      accounts: 880205,
      dataSent: 14994667,
      dataReceived: 135326192,
      callsIn: 1064047,
      callsInDuration: 2107708,
      callsOut: 1015049,
      callsOutDuration: 2078235,
      smses: 181442,
    },
    {
      date: "2021-01-17",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 13771991,
      accounts: 870503,
      dataSent: 14505199,
      dataReceived: 135526987,
      callsIn: 718115,
      callsInDuration: 1627049,
      callsOut: 723996,
      callsOutDuration: 1610571,
      smses: 161385,
    },
    {
      date: "2021-01-18",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14511197,
      accounts: 880831,
      dataSent: 15314845,
      dataReceived: 134241942,
      callsIn: 1378392,
      callsInDuration: 2988570,
      callsOut: 1318512,
      callsOutDuration: 3127321,
      smses: 195791,
    },
    {
      date: "2021-01-19",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14630029,
      accounts: 884027,
      dataSent: 15663683,
      dataReceived: 136984353,
      callsIn: 1386257,
      callsInDuration: 3045607,
      callsOut: 1289708,
      callsOutDuration: 3110772,
      smses: 199670,
    },
    {
      date: "2021-01-20",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14854809,
      accounts: 887304,
      dataSent: 16091849,
      dataReceived: 139728477,
      callsIn: 1372553,
      callsInDuration: 2946998,
      callsOut: 1281351,
      callsOutDuration: 3008845,
      smses: 201050,
    },
    {
      date: "2021-01-21",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 15061437,
      accounts: 896285,
      dataSent: 16220735,
      dataReceived: 142123620,
      callsIn: 1365706,
      callsInDuration: 2966370,
      callsOut: 1283982,
      callsOutDuration: 3006805,
      smses: 194560,
    },
    {
      date: "2021-01-22",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 15278731,
      accounts: 895659,
      dataSent: 16601740,
      dataReceived: 144774740,
      callsIn: 1427198,
      callsInDuration: 2852823,
      callsOut: 1395001,
      callsOutDuration: 2915180,
      smses: 195118,
    },
    {
      date: "2021-01-23",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 15019630,
      accounts: 893094,
      dataSent: 16123338,
      dataReceived: 141521274,
      callsIn: 1109606,
      callsInDuration: 2027870,
      callsOut: 1080912,
      callsOutDuration: 2021824,
      smses: 182039,
    },
    {
      date: "2021-01-24",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 14193513,
      accounts: 881941,
      dataSent: 14769196,
      dataReceived: 132422850,
      callsIn: 753180,
      callsInDuration: 1619154,
      callsOut: 784565,
      callsOutDuration: 1638365,
      smses: 168331,
    },
    {
      date: "2021-01-25",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14775420,
      accounts: 890666,
      dataSent: 15801657,
      dataReceived: 139799733,
      callsIn: 1356051,
      callsInDuration: 2938307,
      callsOut: 1309241,
      callsOutDuration: 3117078,
      smses: 199446,
    },
    {
      date: "2021-01-26",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14501308,
      accounts: 891186,
      dataSent: 15895645,
      dataReceived: 140979929,
      callsIn: 1345110,
      callsInDuration: 3023398,
      callsOut: 1272550,
      callsOutDuration: 3134011,
      smses: 197606,
    },
    {
      date: "2021-01-27",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14878183,
      accounts: 892722,
      dataSent: 15654909,
      dataReceived: 136347462,
      callsIn: 1366383,
      callsInDuration: 3035933,
      callsOut: 1285602,
      callsOutDuration: 3129496,
      smses: 195609,
    },
    {
      date: "2021-01-28",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 15147181,
      accounts: 892857,
      dataSent: 16112012,
      dataReceived: 142270478,
      callsIn: 1352936,
      callsInDuration: 3044328,
      callsOut: 1255626,
      callsOutDuration: 3100194,
      smses: 200299,
    },
    {
      date: "2021-01-29",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14787558,
      accounts: 894816,
      dataSent: 16418515,
      dataReceived: 143479748,
      callsIn: 1420652,
      callsInDuration: 2972747,
      callsOut: 1343710,
      callsOutDuration: 3056362,
      smses: 200126,
    },
    {
      date: "2021-01-30",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 11540048,
      accounts: 878589,
      dataSent: 11227260,
      dataReceived: 102839360,
      callsIn: 1024672,
      callsInDuration: 2140277,
      callsOut: 1009208,
      callsOutDuration: 2132311,
      smses: 179672,
    },
    {
      date: "2021-01-31",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 12345621,
      accounts: 872075,
      dataSent: 13109865,
      dataReceived: 123864561,
      callsIn: 677267,
      callsInDuration: 1700182,
      callsOut: 709263,
      callsOutDuration: 1700001,
      smses: 159626,
    },
    {
      date: "2021-02-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 13819085,
      accounts: 885114,
      dataSent: 14361126,
      dataReceived: 129260092,
      callsIn: 1295550,
      callsInDuration: 3049204,
      callsOut: 1290722,
      callsOutDuration: 3301192,
      smses: 193186,
    },
    {
      date: "2021-02-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14607348,
      accounts: 891035,
      dataSent: 15448775,
      dataReceived: 136170720,
      callsIn: 1332014,
      callsInDuration: 3103283,
      callsOut: 1303677,
      callsOutDuration: 3273128,
      smses: 189089,
    },
    {
      date: "2021-02-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14712074,
      accounts: 894781,
      dataSent: 15675030,
      dataReceived: 136321578,
      callsIn: 1368964,
      callsInDuration: 3126425,
      callsOut: 1326305,
      callsOutDuration: 3275533,
      smses: 184352,
    },
    {
      date: "2021-02-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 15016005,
      accounts: 898100,
      dataSent: 15816204,
      dataReceived: 137328360,
      callsIn: 1414495,
      callsInDuration: 3133630,
      callsOut: 1363286,
      callsOutDuration: 3239782,
      smses: 179968,
    },
    {
      date: "2021-02-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 17223480,
      accounts: 902093,
      dataSent: 16108815,
      dataReceived: 136915177,
      callsIn: 1492244,
      callsInDuration: 3044588,
      callsOut: 1443966,
      callsOutDuration: 3152791,
      smses: 185477,
    },
    {
      date: "2021-02-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 15480890,
      accounts: 901568,
      dataSent: 16123565,
      dataReceived: 138640859,
      callsIn: 1149602,
      callsInDuration: 2112451,
      callsOut: 1136722,
      callsOutDuration: 2115405,
      smses: 175254,
    },
    {
      date: "2021-02-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 14239563,
      accounts: 890656,
      dataSent: 14996587,
      dataReceived: 134071136,
      callsIn: 754700,
      callsInDuration: 1652987,
      callsOut: 787846,
      callsOutDuration: 1666404,
      smses: 162248,
    },
    {
      date: "2021-02-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14902952,
      accounts: 897917,
      dataSent: 15715523,
      dataReceived: 136966422,
      callsIn: 1345776,
      callsInDuration: 3144264,
      callsOut: 1328247,
      callsOutDuration: 3324241,
      smses: 190456,
    },
    {
      date: "2021-02-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 15541249,
      accounts: 900396,
      dataSent: 15723013,
      dataReceived: 136859720,
      callsIn: 1368368,
      callsInDuration: 3159309,
      callsOut: 1329731,
      callsOutDuration: 3303806,
      smses: 180112,
    },
    {
      date: "2021-02-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 15955248,
      accounts: 899743,
      dataSent: 15795951,
      dataReceived: 137499860,
      callsIn: 1411138,
      callsInDuration: 3193830,
      callsOut: 1350012,
      callsOutDuration: 3304735,
      smses: 190098,
    },
    {
      date: "2021-02-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 15960746,
      accounts: 902024,
      dataSent: 16042484,
      dataReceived: 139906791,
      callsIn: 1446627,
      callsInDuration: 3188520,
      callsOut: 1358042,
      callsOutDuration: 3253124,
      smses: 190045,
    },
    {
      date: "2021-02-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 17278922,
      accounts: 906081,
      dataSent: 16471052,
      dataReceived: 141985659,
      callsIn: 1536452,
      callsInDuration: 3125276,
      callsOut: 1458370,
      callsOutDuration: 3200284,
      smses: 202287,
    },
    {
      date: "2021-02-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 16050038,
      accounts: 915914,
      dataSent: 16516060,
      dataReceived: 143526749,
      callsIn: 1153537,
      callsInDuration: 2169744,
      callsOut: 1155942,
      callsOutDuration: 2179964,
      smses: 179046,
    },
    {
      date: "2021-02-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 14626997,
      accounts: 892728,
      dataSent: 16357816,
      dataReceived: 145679049,
      callsIn: 789215,
      callsInDuration: 1603080,
      callsOut: 841617,
      callsOutDuration: 1631328,
      smses: 168682,
    },
    {
      date: "2021-02-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14398503,
      accounts: 888486,
      dataSent: 15885825,
      dataReceived: 140015613,
      callsIn: 861442,
      callsInDuration: 1864905,
      callsOut: 861632,
      callsOutDuration: 1863424,
      smses: 166073,
    },
    {
      date: "2021-02-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14107432,
      accounts: 886263,
      dataSent: 14925449,
      dataReceived: 134153401,
      callsIn: 863238,
      callsInDuration: 2067795,
      callsOut: 849428,
      callsOutDuration: 2033289,
      smses: 178674,
    },
    {
      date: "2021-02-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 16645184,
      accounts: 901760,
      dataSent: 15634543,
      dataReceived: 132807436,
      callsIn: 1466134,
      callsInDuration: 3281643,
      callsOut: 1444436,
      callsOutDuration: 3475276,
      smses: 202161,
    },
    {
      date: "2021-02-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 16208686,
      accounts: 904288,
      dataSent: 16050581,
      dataReceived: 135738701,
      callsIn: 1479832,
      callsInDuration: 3269429,
      callsOut: 1411160,
      callsOutDuration: 3386241,
      smses: 197138,
    },
    {
      date: "2021-02-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 18044327,
      accounts: 908890,
      dataSent: 15797655,
      dataReceived: 132462429,
      callsIn: 1535439,
      callsInDuration: 3195615,
      callsOut: 1480951,
      callsOutDuration: 3313375,
      smses: 196271,
    },
    {
      date: "2021-02-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 15448290,
      accounts: 906527,
      dataSent: 16119371,
      dataReceived: 139411101,
      callsIn: 1133153,
      callsInDuration: 2193605,
      callsOut: 1106011,
      callsOutDuration: 2174691,
      smses: 171534,
    },
    {
      date: "2021-02-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 14087990,
      accounts: 891530,
      dataSent: 14774448,
      dataReceived: 132433601,
      callsIn: 761007,
      callsInDuration: 1706068,
      callsOut: 783512,
      callsOutDuration: 1690771,
      smses: 160346,
    },
    {
      date: "2021-02-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 16745362,
      accounts: 902756,
      dataSent: 14827329,
      dataReceived: 128906581,
      callsIn: 1424908,
      callsInDuration: 3132073,
      callsOut: 1368603,
      callsOutDuration: 3295733,
      smses: 192259,
    },
    {
      date: "2021-02-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 16070175,
      accounts: 906344,
      dataSent: 15442279,
      dataReceived: 132713152,
      callsIn: 1475443,
      callsInDuration: 3274158,
      callsOut: 1385537,
      callsOutDuration: 3383215,
      smses: 191223,
    },
    {
      date: "2021-02-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 16444951,
      accounts: 907918,
      dataSent: 15522452,
      dataReceived: 132631196,
      callsIn: 1483058,
      callsInDuration: 3299496,
      callsOut: 1386688,
      callsOutDuration: 3387215,
      smses: 192444,
    },
    {
      date: "2021-02-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 16771660,
      accounts: 908749,
      dataSent: 15743501,
      dataReceived: 136522692,
      callsIn: 1504141,
      callsInDuration: 3274903,
      callsOut: 1406540,
      callsOutDuration: 3367781,
      smses: 195597,
    },
    {
      date: "2021-02-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 18830642,
      accounts: 913592,
      dataSent: 15355834,
      dataReceived: 136687119,
      callsIn: 1576559,
      callsInDuration: 3183453,
      callsOut: 1504071,
      callsOutDuration: 3294907,
      smses: 194868,
    },
    {
      date: "2021-02-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 16364321,
      accounts: 913096,
      dataSent: 15849186,
      dataReceived: 136481995,
      callsIn: 1189697,
      callsInDuration: 2179810,
      callsOut: 1182077,
      callsOutDuration: 2200501,
      smses: 177509,
    },
    {
      date: "2021-02-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 14255266,
      accounts: 902438,
      dataSent: 14720092,
      dataReceived: 135913185,
      callsIn: 800666,
      callsInDuration: 1757605,
      callsOut: 838100,
      callsOutDuration: 1771000,
      smses: 167179,
    },
    {
      date: "2021-03-01",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 16996427,
      accounts: 911636,
      dataSent: 14109407,
      dataReceived: 120963797,
      callsIn: 1512275,
      callsInDuration: 3286895,
      callsOut: 1459225,
      callsOutDuration: 3534965,
      smses: 201555,
    },
    {
      date: "2021-03-02",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 17348788,
      accounts: 913170,
      dataSent: 14092345,
      dataReceived: 120217937,
      callsIn: 1469968,
      callsInDuration: 3319235,
      callsOut: 1414676,
      callsOutDuration: 3468449,
      smses: 197410,
    },
    {
      date: "2021-03-03",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 17143725,
      accounts: 917688,
      dataSent: 14880999,
      dataReceived: 128681033,
      callsIn: 1482451,
      callsInDuration: 3350554,
      callsOut: 1430695,
      callsOutDuration: 3488860,
      smses: 203521,
    },
    {
      date: "2021-03-04",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 16834004,
      accounts: 925063,
      dataSent: 15532981,
      dataReceived: 134722506,
      callsIn: 1507784,
      callsInDuration: 3329482,
      callsOut: 1438342,
      callsOutDuration: 3451995,
      smses: 193743,
    },
    {
      date: "2021-03-05",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 19631353,
      accounts: 931623,
      dataSent: 15171614,
      dataReceived: 129737179,
      callsIn: 1577113,
      callsInDuration: 3220485,
      callsOut: 1529426,
      callsOutDuration: 3326532,
      smses: 200363,
    },
    {
      date: "2021-03-06",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 13347658,
      accounts: 921654,
      dataSent: 12717781,
      dataReceived: 109613066,
      callsIn: 1209786,
      callsInDuration: 2215400,
      callsOut: 1206285,
      callsOutDuration: 2229472,
      smses: 177455,
    },
    {
      date: "2021-03-07",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 13959723,
      accounts: 916617,
      dataSent: 14608487,
      dataReceived: 134113921,
      callsIn: 806896,
      callsInDuration: 1734856,
      callsOut: 849114,
      callsOutDuration: 1725410,
      smses: 161311,
    },
    {
      date: "2021-03-08",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 20432274,
      accounts: 919248,
      dataSent: 10365623,
      dataReceived: 88130663,
      callsIn: 1476490,
      callsInDuration: 3194233,
      callsOut: 1458125,
      callsOutDuration: 3434343,
      smses: 205372,
    },
    {
      date: "2021-03-09",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 20439218,
      accounts: 919120,
      dataSent: 10147957,
      dataReceived: 87127792,
      callsIn: 1479095,
      callsInDuration: 3319286,
      callsOut: 1440386,
      callsOutDuration: 3477523,
      smses: 198155,
    },
    {
      date: "2021-03-10",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 20531699,
      accounts: 923639,
      dataSent: 10364960,
      dataReceived: 89933154,
      callsIn: 1505113,
      callsInDuration: 3327872,
      callsOut: 1449730,
      callsOutDuration: 3461059,
      smses: 200549,
    },
    {
      date: "2021-03-11",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 19342944,
      accounts: 927194,
      dataSent: 10635975,
      dataReceived: 90837390,
      callsIn: 1498857,
      callsInDuration: 3323507,
      callsOut: 1426041,
      callsOutDuration: 3411246,
      smses: 198825,
    },
    {
      date: "2021-03-12",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 19734117,
      accounts: 931454,
      dataSent: 10936627,
      dataReceived: 92827074,
      callsIn: 1586725,
      callsInDuration: 3201066,
      callsOut: 1503648,
      callsOutDuration: 3302897,
      smses: 202855,
    },
    {
      date: "2021-03-13",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 19116933,
      accounts: 926746,
      dataSent: 10562515,
      dataReceived: 90591061,
      callsIn: 1206333,
      callsInDuration: 2215123,
      callsOut: 1177073,
      callsOutDuration: 2228038,
      smses: 177045,
    },
    {
      date: "2021-03-14",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 17531773,
      accounts: 909231,
      dataSent: 9684604,
      dataReceived: 90123529,
      callsIn: 776541,
      callsInDuration: 1710539,
      callsOut: 811653,
      callsOutDuration: 1725841,
      smses: 162075,
    },
    {
      date: "2021-03-15",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 18840767,
      accounts: 926617,
      dataSent: 10050126,
      dataReceived: 87732466,
      callsIn: 1471258,
      callsInDuration: 3269962,
      callsOut: 1410572,
      callsOutDuration: 3454728,
      smses: 203164,
    },
    {
      date: "2021-03-16",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 17160555,
      accounts: 921038,
      dataSent: 9898253,
      dataReceived: 87927199,
      callsIn: 1448856,
      callsInDuration: 3267980,
      callsOut: 1348374,
      callsOutDuration: 3390087,
      smses: 201913,
    },
    {
      date: "2021-03-17",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 18711596,
      accounts: 927414,
      dataSent: 10578278,
      dataReceived: 93172633,
      callsIn: 1420302,
      callsInDuration: 3233672,
      callsOut: 1338924,
      callsOutDuration: 3322762,
      smses: 191757,
    },
    {
      date: "2021-03-18",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 18709197,
      accounts: 930165,
      dataSent: 10636182,
      dataReceived: 91446069,
      callsIn: 1447829,
      callsInDuration: 3240213,
      callsOut: 1368089,
      callsOutDuration: 3313622,
      smses: 188191,
    },
    {
      date: "2021-03-19",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 20050521,
      accounts: 941796,
      dataSent: 11393758,
      dataReceived: 95374570,
      callsIn: 1646083,
      callsInDuration: 3281708,
      callsOut: 1550126,
      callsOutDuration: 3338789,
      smses: 426145,
    },
    {
      date: "2021-03-20",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 17822961,
      accounts: 925964,
      dataSent: 9919594,
      dataReceived: 91411013,
      callsIn: 1106890,
      callsInDuration: 2182030,
      callsOut: 1069167,
      callsOutDuration: 2158208,
      smses: 173254,
    },
    {
      date: "2021-03-21",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 16856804,
      accounts: 911810,
      dataSent: 9417600,
      dataReceived: 86201178,
      callsIn: 767894,
      callsInDuration: 1717514,
      callsOut: 789346,
      callsOutDuration: 1719148,
      smses: 159697,
    },
    {
      date: "2021-03-22",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 18600344,
      accounts: 930632,
      dataSent: 10438580,
      dataReceived: 91583003,
      callsIn: 1473440,
      callsInDuration: 3273107,
      callsOut: 1382762,
      callsOutDuration: 3382717,
      smses: 196299,
    },
    {
      date: "2021-03-23",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 19213951,
      accounts: 932923,
      dataSent: 10814762,
      dataReceived: 94886116,
      callsIn: 1544267,
      callsInDuration: 3245833,
      callsOut: 1444195,
      callsOutDuration: 3343652,
      smses: 192263,
    },
    {
      date: "2021-03-24",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 16884816,
      accounts: 911847,
      dataSent: 9316320,
      dataReceived: 85757799,
      callsIn: 953727,
      callsInDuration: 2250712,
      callsOut: 927854,
      callsOutDuration: 2209245,
      smses: 176218,
    },
    {
      date: "2021-03-25",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 18559638,
      accounts: 922230,
      dataSent: 10551895,
      dataReceived: 95364458,
      callsIn: 1421652,
      callsInDuration: 3305435,
      callsOut: 1336068,
      callsOutDuration: 3395231,
      smses: 200297,
    },
    {
      date: "2021-03-26",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 19355166,
      accounts: 931557,
      dataSent: 11338039,
      dataReceived: 99585193,
      callsIn: 1527725,
      callsInDuration: 3197084,
      callsOut: 1440206,
      callsOutDuration: 3313703,
      smses: 202558,
    },
    {
      date: "2021-03-27",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 15139082,
      accounts: 911726,
      dataSent: 7958687,
      dataReceived: 70638189,
      callsIn: 1118830,
      callsInDuration: 2243335,
      callsOut: 1088889,
      callsOutDuration: 2259965,
      smses: 168741,
    },
    {
      date: "2021-03-28",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 15594551,
      accounts: 901498,
      dataSent: 9011952,
      dataReceived: 87547105,
      callsIn: 754612,
      callsInDuration: 1797020,
      callsOut: 768849,
      callsOutDuration: 1776102,
      smses: 157453,
    },
    {
      date: "2021-03-29",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 18548519,
      accounts: 926163,
      dataSent: 10542886,
      dataReceived: 93964794,
      callsIn: 1504701,
      callsInDuration: 3372887,
      callsOut: 1416412,
      callsOutDuration: 3537825,
      smses: 192655,
    },
    {
      date: "2021-03-30",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 18682018,
      accounts: 928814,
      dataSent: 10598659,
      dataReceived: 93563742,
      callsIn: 1532461,
      callsInDuration: 3419938,
      callsOut: 1435630,
      callsOutDuration: 3535686,
      smses: 186057,
    },
    {
      date: "2021-03-31",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 19319109,
      accounts: 934398,
      dataSent: 11275234,
      dataReceived: 96948961,
      callsIn: 1615330,
      callsInDuration: 3413987,
      callsOut: 1518572,
      callsOutDuration: 3554944,
      smses: 194724,
    },
    {
      date: "2021-04-01",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 18499315,
      accounts: 928968,
      dataSent: 10742581,
      dataReceived: 93251278,
      callsIn: 1352827,
      callsInDuration: 2661735,
      callsOut: 1236278,
      callsOutDuration: 2687069,
      smses: 178754,
    },
    {
      date: "2021-04-02",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 17485072,
      accounts: 915495,
      dataSent: 10563246,
      dataReceived: 92322075,
      callsIn: 892754,
      callsInDuration: 1950224,
      callsOut: 902258,
      callsOutDuration: 1969463,
      smses: 162752,
    },
    {
      date: "2021-04-03",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 18326405,
      accounts: 923321,
      dataSent: 11160428,
      dataReceived: 98951517,
      callsIn: 1098641,
      callsInDuration: 2073620,
      callsOut: 1084606,
      callsOutDuration: 2087719,
      smses: 171113,
    },
    {
      date: "2021-04-04",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 17148592,
      accounts: 909835,
      dataSent: 9875876,
      dataReceived: 89729423,
      callsIn: 792981,
      callsInDuration: 1800918,
      callsOut: 811720,
      callsOutDuration: 1799257,
      smses: 168761,
    },
    {
      date: "2021-04-05",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 18388506,
      accounts: 930242,
      dataSent: 10549935,
      dataReceived: 93565277,
      callsIn: 1528744,
      callsInDuration: 3407092,
      callsOut: 1476571,
      callsOutDuration: 3717208,
      smses: 200854,
    },
    {
      date: "2021-04-06",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 18536795,
      accounts: 933304,
      dataSent: 10682662,
      dataReceived: 95977232,
      callsIn: 1495530,
      callsInDuration: 3473250,
      callsOut: 1439590,
      callsOutDuration: 3694186,
      smses: 195671,
    },
    {
      date: "2021-04-07",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 18873767,
      accounts: 936970,
      dataSent: 10967956,
      dataReceived: 97538857,
      callsIn: 1517633,
      callsInDuration: 3558265,
      callsOut: 1459675,
      callsOutDuration: 3761063,
      smses: 194019,
    },
    {
      date: "2021-04-08",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 19076723,
      accounts: 939666,
      dataSent: 11505246,
      dataReceived: 97236558,
      callsIn: 1525144,
      callsInDuration: 3602518,
      callsOut: 1452676,
      callsOutDuration: 3758146,
      smses: 197104,
    },
    {
      date: "2021-04-09",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 18759250,
      accounts: 937695,
      dataSent: 11696862,
      dataReceived: 100766720,
      callsIn: 1533042,
      callsInDuration: 3495882,
      callsOut: 1455464,
      callsOutDuration: 3622958,
      smses: 195198,
    },
    {
      date: "2021-04-10",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 17207197,
      accounts: 925389,
      dataSent: 10326271,
      dataReceived: 95994077,
      callsIn: 1086390,
      callsInDuration: 2486087,
      callsOut: 1058000,
      callsOutDuration: 2461309,
      smses: 172274,
    },
    {
      date: "2021-04-11",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 15706655,
      accounts: 908050,
      dataSent: 9335612,
      dataReceived: 89584525,
      callsIn: 715010,
      callsInDuration: 2002830,
      callsOut: 729947,
      callsOutDuration: 1976371,
      smses: 151799,
    },
    {
      date: "2021-04-12",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 17668710,
      accounts: 939555,
      dataSent: 16426122,
      dataReceived: 140612594,
      callsIn: 1469323,
      callsInDuration: 3309033,
      callsOut: 1397429,
      callsOutDuration: 3503997,
      smses: 164943,
    },
    {
      date: "2021-04-13",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14210151,
      accounts: 945860,
      dataSent: 18170769,
      dataReceived: 156843831,
      callsIn: 1491405,
      callsInDuration: 3589082,
      callsOut: 1397845,
      callsOutDuration: 3673366,
      smses: 181644,
    },
    {
      date: "2021-04-14",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 15761933,
      accounts: 948659,
      dataSent: 18279347,
      dataReceived: 160487231,
      callsIn: 1503763,
      callsInDuration: 3671726,
      callsOut: 1403426,
      callsOutDuration: 3750383,
      smses: 177742,
    },
    {
      date: "2021-04-15",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 16502131,
      accounts: 949697,
      dataSent: 17826098,
      dataReceived: 159192123,
      callsIn: 1479688,
      callsInDuration: 3585998,
      callsOut: 1380364,
      callsOutDuration: 3606719,
      smses: 181318,
    },
    {
      date: "2021-04-16",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 17686999,
      accounts: 951292,
      dataSent: 17422708,
      dataReceived: 160152103,
      callsIn: 1533832,
      callsInDuration: 3455171,
      callsOut: 1454521,
      callsOutDuration: 3489851,
      smses: 188047,
    },
    {
      date: "2021-04-17",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 17244128,
      accounts: 943133,
      dataSent: 15998077,
      dataReceived: 154147280,
      callsIn: 1127893,
      callsInDuration: 2484124,
      callsOut: 1097053,
      callsOutDuration: 2428469,
      smses: 161894,
    },
    {
      date: "2021-04-18",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 17026542,
      accounts: 926155,
      dataSent: 13866199,
      dataReceived: 139011292,
      callsIn: 701552,
      callsInDuration: 1958931,
      callsOut: 717928,
      callsOutDuration: 1906736,
      smses: 145284,
    },
    {
      date: "2021-04-19",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 18911522,
      accounts: 940516,
      dataSent: 16460813,
      dataReceived: 150139045,
      callsIn: 1432297,
      callsInDuration: 3509882,
      callsOut: 1342742,
      callsOutDuration: 3617825,
      smses: 177479,
    },
    {
      date: "2021-04-20",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 19446939,
      accounts: 941071,
      dataSent: 16911635,
      dataReceived: 152794121,
      callsIn: 1445018,
      callsInDuration: 3556808,
      callsOut: 1322352,
      callsOutDuration: 3587650,
      smses: 182188,
    },
    {
      date: "2021-04-21",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 19747181,
      accounts: 941852,
      dataSent: 16783064,
      dataReceived: 152686390,
      callsIn: 1432583,
      callsInDuration: 3543600,
      callsOut: 1300708,
      callsOutDuration: 3562990,
      smses: 178749,
    },
    {
      date: "2021-04-22",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 19700819,
      accounts: 942745,
      dataSent: 16736871,
      dataReceived: 152048021,
      callsIn: 1448344,
      callsInDuration: 3540239,
      callsOut: 1290235,
      callsOutDuration: 3501438,
      smses: 172822,
    },
    {
      date: "2021-04-23",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 19200032,
      accounts: 943459,
      dataSent: 16742848,
      dataReceived: 149577824,
      callsIn: 1425554,
      callsInDuration: 3226061,
      callsOut: 1293948,
      callsOutDuration: 3216648,
      smses: 182899,
    },
    {
      date: "2021-04-24",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 19594634,
      accounts: 936933,
      dataSent: 15438719,
      dataReceived: 144310169,
      callsIn: 1078824,
      callsInDuration: 2428126,
      callsOut: 1030694,
      callsOutDuration: 2376270,
      smses: 155129,
    },
    {
      date: "2021-04-25",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 18700288,
      accounts: 922231,
      dataSent: 13665805,
      dataReceived: 132242014,
      callsIn: 692185,
      callsInDuration: 1945409,
      callsOut: 695731,
      callsOutDuration: 1891160,
      smses: 135613,
    },
    {
      date: "2021-04-26",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 20009576,
      accounts: 935875,
      dataSent: 16129031,
      dataReceived: 141149311,
      callsIn: 1433342,
      callsInDuration: 3502256,
      callsOut: 1308118,
      callsOutDuration: 3575786,
      smses: 175237,
    },
    {
      date: "2021-04-27",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 21303031,
      accounts: 937430,
      dataSent: 16845132,
      dataReceived: 144818580,
      callsIn: 1420476,
      callsInDuration: 3511600,
      callsOut: 1288618,
      callsOutDuration: 3542833,
      smses: 172919,
    },
    {
      date: "2021-04-28",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 23428968,
      accounts: 936507,
      dataSent: 14972116,
      dataReceived: 132478053,
      callsIn: 1432322,
      callsInDuration: 3490489,
      callsOut: 1298833,
      callsOutDuration: 3516186,
      smses: 173131,
    },
    {
      date: "2021-04-29",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 21476651,
      accounts: 939075,
      dataSent: 16286689,
      dataReceived: 143535749,
      callsIn: 1443686,
      callsInDuration: 3516538,
      callsOut: 1308583,
      callsOutDuration: 3503561,
      smses: 167949,
    },
    {
      date: "2021-04-30",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 22104922,
      accounts: 933218,
      dataSent: 10417288,
      dataReceived: 90226679,
      callsIn: 1600832,
      callsInDuration: 3448750,
      callsOut: 1467837,
      callsOutDuration: 3480090,
      smses: 178047,
    },
    {
      date: "2021-05-01",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 19128740,
      accounts: 911417,
      dataSent: 8734071,
      dataReceived: 79690803,
      callsIn: 907845,
      callsInDuration: 2103123,
      callsOut: 917071,
      callsOutDuration: 2091968,
      smses: 151417,
    },
    {
      date: "2021-05-02",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 18404546,
      accounts: 897816,
      dataSent: 8274101,
      dataReceived: 83722861,
      callsIn: 712654,
      callsInDuration: 1892424,
      callsOut: 715614,
      callsOutDuration: 1860091,
      smses: 138863,
    },
    {
      date: "2021-05-03",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 20265124,
      accounts: 916659,
      dataSent: 9772638,
      dataReceived: 88717324,
      callsIn: 1392440,
      callsInDuration: 3404516,
      callsOut: 1294334,
      callsOutDuration: 3559719,
      smses: 179283,
    },
    {
      date: "2021-05-04",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 20550768,
      accounts: 920570,
      dataSent: 9914032,
      dataReceived: 88354776,
      callsIn: 1402479,
      callsInDuration: 3451897,
      callsOut: 1294179,
      callsOutDuration: 3539243,
      smses: 173407,
    },
    {
      date: "2021-05-05",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 19501011,
      accounts: 922163,
      dataSent: 9240924,
      dataReceived: 80733598,
      callsIn: 1435136,
      callsInDuration: 3467789,
      callsOut: 1325055,
      callsOutDuration: 3566388,
      smses: 170091,
    },
    {
      date: "2021-05-06",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 20288568,
      accounts: 927432,
      dataSent: 10042604,
      dataReceived: 86752491,
      callsIn: 1469203,
      callsInDuration: 3469449,
      callsOut: 1341855,
      callsOutDuration: 3527445,
      smses: 165505,
    },
    {
      date: "2021-05-07",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 20736122,
      accounts: 933392,
      dataSent: 10114017,
      dataReceived: 88211412,
      callsIn: 1538892,
      callsInDuration: 3391623,
      callsOut: 1432918,
      callsOutDuration: 3448979,
      smses: 169474,
    },
    {
      date: "2021-05-08",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 19812178,
      accounts: 926874,
      dataSent: 9179721,
      dataReceived: 86403156,
      callsIn: 1151725,
      callsInDuration: 2361673,
      callsOut: 1107481,
      callsOutDuration: 2313344,
      smses: 150946,
    },
    {
      date: "2021-05-09",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 17720081,
      accounts: 900038,
      dataSent: 8005092,
      dataReceived: 81013976,
      callsIn: 689635,
      callsInDuration: 1862440,
      callsOut: 691747,
      callsOutDuration: 1816289,
      smses: 131501,
    },
    {
      date: "2021-05-10",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 19985548,
      accounts: 923266,
      dataSent: 9716092,
      dataReceived: 87161965,
      callsIn: 1422513,
      callsInDuration: 3395332,
      callsOut: 1348342,
      callsOutDuration: 3541393,
      smses: 173219,
    },
    {
      date: "2021-05-11",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 20229871,
      accounts: 925553,
      dataSent: 9854255,
      dataReceived: 90235511,
      callsIn: 1414303,
      callsInDuration: 3433561,
      callsOut: 1335434,
      callsOutDuration: 3525588,
      smses: 165673,
    },
    {
      date: "2021-05-12",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 19757984,
      accounts: 927444,
      dataSent: 9721500,
      dataReceived: 88226367,
      callsIn: 1451526,
      callsInDuration: 3495327,
      callsOut: 1348376,
      callsOutDuration: 3542366,
      smses: 166411,
    },
    {
      date: "2021-05-13",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 20554064,
      accounts: 929227,
      dataSent: 10114470,
      dataReceived: 92206451,
      callsIn: 1451526,
      callsInDuration: 3501770,
      callsOut: 1333538,
      callsOutDuration: 3520851,
      smses: 167588,
    },
    {
      date: "2021-05-14",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 20778979,
      accounts: 935073,
      dataSent: 10334909,
      dataReceived: 93269872,
      callsIn: 1542130,
      callsInDuration: 3416619,
      callsOut: 1418048,
      callsOutDuration: 3424615,
      smses: 166554,
    },
    {
      date: "2021-05-15",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 19410247,
      accounts: 927411,
      dataSent: 9417047,
      dataReceived: 88547749,
      callsIn: 1146612,
      callsInDuration: 2405142,
      callsOut: 1097502,
      callsOutDuration: 2339310,
      smses: 153126,
    },
    {
      date: "2021-05-16",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 16995172,
      accounts: 901081,
      dataSent: 8290982,
      dataReceived: 87919116,
      callsIn: 701189,
      callsInDuration: 1887442,
      callsOut: 704760,
      callsOutDuration: 1836798,
      smses: 139182,
    },
    {
      date: "2021-05-17",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 18665716,
      accounts: 920435,
      dataSent: 9574533,
      dataReceived: 87728202,
      callsIn: 1413213,
      callsInDuration: 3475706,
      callsOut: 1298425,
      callsOutDuration: 3458200,
      smses: 170243,
    },
    {
      date: "2021-05-18",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 19053947,
      accounts: 921676,
      dataSent: 9894620,
      dataReceived: 90090616,
      callsIn: 1428146,
      callsInDuration: 3540297,
      callsOut: 1288958,
      callsOutDuration: 3478079,
      smses: 163867,
    },
    {
      date: "2021-05-19",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 18965258,
      accounts: 923003,
      dataSent: 10070989,
      dataReceived: 92886776,
      callsIn: 1429371,
      callsInDuration: 3521173,
      callsOut: 1283750,
      callsOutDuration: 3447759,
      smses: 168508,
    },
    {
      date: "2021-05-20",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 18836932,
      accounts: 922951,
      dataSent: 10244968,
      dataReceived: 93780330,
      callsIn: 1439413,
      callsInDuration: 3601244,
      callsOut: 1301054,
      callsOutDuration: 3533497,
      smses: 170883,
    },
    {
      date: "2021-05-21",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 19162426,
      accounts: 923882,
      dataSent: 10485288,
      dataReceived: 96482197,
      callsIn: 1567133,
      callsInDuration: 3619921,
      callsOut: 1495241,
      callsOutDuration: 3575098,
      smses: 177720,
    },
    {
      date: "2021-05-22",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 15851393,
      accounts: 889341,
      dataSent: 8113733,
      dataReceived: 82811803,
      callsIn: 924287,
      callsInDuration: 2629718,
      callsOut: 880482,
      callsOutDuration: 2508326,
      smses: 137639,
    },
    {
      date: "2021-05-23",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 14225377,
      accounts: 860294,
      dataSent: 7175458,
      dataReceived: 76698048,
      callsIn: 607062,
      callsInDuration: 2116975,
      callsOut: 592944,
      callsOutDuration: 1992740,
      smses: 125892,
    },
    {
      date: "2021-05-24",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 14933246,
      accounts: 865433,
      dataSent: 7856429,
      dataReceived: 78022955,
      callsIn: 858559,
      callsInDuration: 2543886,
      callsOut: 801393,
      callsOutDuration: 2402206,
      smses: 140957,
    },
    {
      date: "2021-05-25",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 14719013,
      accounts: 855647,
      dataSent: 7693052,
      dataReceived: 78733693,
      callsIn: 756482,
      callsInDuration: 2352862,
      callsOut: 688371,
      callsOutDuration: 2173993,
      smses: 136093,
    },
    {
      date: "2021-05-26",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 16830200,
      accounts: 881147,
      dataSent: 9562220,
      dataReceived: 88399062,
      callsIn: 1350225,
      callsInDuration: 3661229,
      callsOut: 1240526,
      callsOutDuration: 3674659,
      smses: 167112,
    },
    {
      date: "2021-05-27",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 16800907,
      accounts: 883561,
      dataSent: 9708291,
      dataReceived: 87446094,
      callsIn: 1343370,
      callsInDuration: 3659515,
      callsOut: 1201047,
      callsOutDuration: 3585182,
      smses: 160027,
    },
    {
      date: "2021-05-28",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 17170235,
      accounts: 886908,
      dataSent: 10018158,
      dataReceived: 91595396,
      callsIn: 1391238,
      callsInDuration: 3605861,
      callsOut: 1248600,
      callsOutDuration: 3496540,
      smses: 155534,
    },
    {
      date: "2021-05-29",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 15469440,
      accounts: 875313,
      dataSent: 8409217,
      dataReceived: 83845467,
      callsIn: 978535,
      callsInDuration: 2560656,
      callsOut: 906293,
      callsOutDuration: 2396128,
      smses: 134708,
    },
    {
      date: "2021-05-30",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 13840163,
      accounts: 852488,
      dataSent: 7445783,
      dataReceived: 76408460,
      callsIn: 638047,
      callsInDuration: 2132751,
      callsOut: 620759,
      callsOutDuration: 1991366,
      smses: 125960,
    },
    {
      date: "2021-05-31",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 17913132,
      accounts: 890069,
      dataSent: 9464454,
      dataReceived: 91449711,
      callsIn: 1425741,
      callsInDuration: 3527370,
      callsOut: 1292164,
      callsOutDuration: 3566241,
      smses: 167585,
    },
    {
      date: "2021-06-01",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 17550273,
      accounts: 896292,
      dataSent: 10066894,
      dataReceived: 92487586,
      callsIn: 1409634,
      callsInDuration: 3600669,
      callsOut: 1279110,
      callsOutDuration: 3600006,
      smses: 163179,
    },
    {
      date: "2021-06-02",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 15966854,
      accounts: 913937,
      dataSent: 14482107,
      dataReceived: 133975863,
      callsIn: 1437095,
      callsInDuration: 3610035,
      callsOut: 1292634,
      callsOutDuration: 3577911,
      smses: 159006,
    },
    {
      date: "2021-06-03",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 13957113,
      accounts: 921208,
      dataSent: 16371761,
      dataReceived: 152250470,
      callsIn: 1471202,
      callsInDuration: 3589083,
      callsOut: 1298245,
      callsOutDuration: 3522570,
      smses: 169312,
    },
    {
      date: "2021-06-04",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14190918,
      accounts: 927112,
      dataSent: 16929512,
      dataReceived: 159267874,
      callsIn: 1548943,
      callsInDuration: 3491162,
      callsOut: 1363006,
      callsOutDuration: 3419016,
      smses: 171778,
    },
    {
      date: "2021-06-05",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 12672385,
      accounts: 917361,
      dataSent: 13896225,
      dataReceived: 138681098,
      callsIn: 1047528,
      callsInDuration: 2515835,
      callsOut: 970118,
      callsOutDuration: 2372294,
      smses: 140722,
    },
    {
      date: "2021-06-06",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 11374075,
      accounts: 898192,
      dataSent: 12056432,
      dataReceived: 124591440,
      callsIn: 668266,
      callsInDuration: 2025644,
      callsOut: 643327,
      callsOutDuration: 1874315,
      smses: 129026,
    },
    {
      date: "2021-06-07",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 13321281,
      accounts: 921227,
      dataSent: 15181138,
      dataReceived: 141211732,
      callsIn: 1435832,
      callsInDuration: 3518746,
      callsOut: 1299749,
      callsOutDuration: 3541885,
      smses: 161363,
    },
    {
      date: "2021-06-08",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 13466805,
      accounts: 926155,
      dataSent: 15795682,
      dataReceived: 151823586,
      callsIn: 1454377,
      callsInDuration: 3496994,
      callsOut: 1279077,
      callsOutDuration: 3439033,
      smses: 158191,
    },
    {
      date: "2021-06-09",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 14254548,
      accounts: 931480,
      dataSent: 16098367,
      dataReceived: 146672076,
      callsIn: 1497880,
      callsInDuration: 3682616,
      callsOut: 1308249,
      callsOutDuration: 3597270,
      smses: 207752,
    },
    {
      date: "2021-06-10",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 13784292,
      accounts: 934948,
      dataSent: 16073255,
      dataReceived: 145995578,
      callsIn: 1457653,
      callsInDuration: 3541585,
      callsOut: 1287301,
      callsOutDuration: 3450135,
      smses: 160900,
    },
    {
      date: "2021-06-11",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14016032,
      accounts: 939060,
      dataSent: 16578759,
      dataReceived: 150238232,
      callsIn: 1542167,
      callsInDuration: 3459671,
      callsOut: 1372549,
      callsOutDuration: 3361957,
      smses: 162765,
    },
    {
      date: "2021-06-12",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 13287788,
      accounts: 935298,
      dataSent: 14697683,
      dataReceived: 141438471,
      callsIn: 1133532,
      callsInDuration: 2422186,
      callsOut: 1063672,
      callsOutDuration: 2299078,
      smses: 139161,
    },
    {
      date: "2021-06-13",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 12130621,
      accounts: 919668,
      dataSent: 13000924,
      dataReceived: 129571407,
      callsIn: 703299,
      callsInDuration: 1924019,
      callsOut: 695220,
      callsOutDuration: 1809186,
      smses: 128825,
    },
    {
      date: "2021-06-14",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 13288265,
      accounts: 932871,
      dataSent: 15220324,
      dataReceived: 144928122,
      callsIn: 1415630,
      callsInDuration: 3378280,
      callsOut: 1257162,
      callsOutDuration: 3349803,
      smses: 162436,
    },
    {
      date: "2021-06-15",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 13415013,
      accounts: 935241,
      dataSent: 15369341,
      dataReceived: 139838354,
      callsIn: 1429413,
      callsInDuration: 3513414,
      callsOut: 1275581,
      callsOutDuration: 3414041,
      smses: 175490,
    },
    {
      date: "2021-06-16",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 13834210,
      accounts: 940283,
      dataSent: 15671379,
      dataReceived: 143906949,
      callsIn: 1439967,
      callsInDuration: 3496434,
      callsOut: 1284787,
      callsOutDuration: 3373322,
      smses: 167505,
    },
    {
      date: "2021-06-17",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 13653715,
      accounts: 941893,
      dataSent: 15366891,
      dataReceived: 142728382,
      callsIn: 1453775,
      callsInDuration: 3467414,
      callsOut: 1296217,
      callsOutDuration: 3350366,
      smses: 160953,
    },
    {
      date: "2021-06-18",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 14124718,
      accounts: 947831,
      dataSent: 15868079,
      dataReceived: 148235607,
      callsIn: 1584615,
      callsInDuration: 3336443,
      callsOut: 1422461,
      callsOutDuration: 3237389,
      smses: 161982,
    },
    {
      date: "2021-06-19",
      dayOfWeek: "Saturday",
      clientId: 1,
      dataSessions: 13338719,
      accounts: 945492,
      dataSent: 14171775,
      dataReceived: 136295856,
      callsIn: 1235032,
      callsInDuration: 2427857,
      callsOut: 1168149,
      callsOutDuration: 2300414,
      smses: 144200,
    },
    {
      date: "2021-06-20",
      dayOfWeek: "Sunday",
      clientId: 1,
      dataSessions: 12593183,
      accounts: 930469,
      dataSent: 12882068,
      dataReceived: 127772155,
      callsIn: 799732,
      callsInDuration: 1869608,
      callsOut: 823758,
      callsOutDuration: 1912931,
      smses: 140658,
    },
    {
      date: "2021-06-21",
      dayOfWeek: "Monday",
      clientId: 1,
      dataSessions: 11610348,
      accounts: 916766,
      dataSent: 11668715,
      dataReceived: 119876844,
      callsIn: 809121,
      callsInDuration: 2106548,
      callsOut: 739241,
      callsOutDuration: 1917015,
      smses: 135556,
    },
    {
      date: "2021-06-22",
      dayOfWeek: "Tuesday",
      clientId: 1,
      dataSessions: 13580568,
      accounts: 940334,
      dataSent: 15523145,
      dataReceived: 139479657,
      callsIn: 1466921,
      callsInDuration: 3391544,
      callsOut: 1303202,
      callsOutDuration: 3354604,
      smses: 167031,
    },
    {
      date: "2021-06-23",
      dayOfWeek: "Wednesday",
      clientId: 1,
      dataSessions: 13652526,
      accounts: 941689,
      dataSent: 15271597,
      dataReceived: 143723347,
      callsIn: 1472407,
      callsInDuration: 3458130,
      callsOut: 1276141,
      callsOutDuration: 3334693,
      smses: 166281,
    },
    {
      date: "2021-06-24",
      dayOfWeek: "Thursday",
      clientId: 1,
      dataSessions: 13823853,
      accounts: 944807,
      dataSent: 15361208,
      dataReceived: 142544904,
      callsIn: 1485617,
      callsInDuration: 3471884,
      callsOut: 1283130,
      callsOutDuration: 3365490,
      smses: 160871,
    },
    {
      date: "2021-06-25",
      dayOfWeek: "Friday",
      clientId: 1,
      dataSessions: 1183311,
      accounts: 364689,
      dataSent: 670770,
      dataReceived: 7063723,
      callsIn: 33825,
      callsInDuration: 142242,
      callsOut: 34205,
      callsOutDuration: 117984,
      smses: 15040,
    },
  ],
};
