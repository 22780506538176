import TwoFactorAuthenticator from "../pages/authentication/TwoFactorAuth";
import MfaToken from "../pages/authentication/MfaToken";

import Login from "../pages/authentication/Login";

import Homepage from "../pages/homepage/Homepage";
import Calendar from "../pages/calendar/Calendar";
import Reports from "../pages/reports/Reports";
import TuentiAR from "../pages/reports/TuentiArgentina";
import Team from "../pages/team/Team";
import DailyStatus from "../pages/daily_status/DailyStatus";
import TuentiEcuador from "../pages/clients/TuentiEcuador";
import TuentiArgentina from "../pages/clients/TuentiArgentina";
import UiTypography from "../pages/UI/UiTypography";
import BasicTables from "../pages/UI/Tables/BasicTables";
import UiColors from "../pages/UI/UiColors";
import UiButtons from "../pages/UI/UiButtons";

const UserRoutes = [
  { path: "/homepage", component: Homepage },
  { path: "/calendar", component: Calendar },
  { path: "/reports/overview", component: Reports },
  { path: "/reports/tuenti-argentina", component: TuentiAR },
  { path: "/team", component: Team },
  { path: "/daily-status", component: DailyStatus },
  { path: "/tuenti-ecuador", component: TuentiEcuador },
  { path: "/tuenti-argentina", component: TuentiArgentina },
  { path: "/typography", component: UiTypography },
  { path: "/basic-tables", component: BasicTables },
  { path: "/colors", component: UiColors },
  { path: "/buttons", component: UiButtons },
];

const AuthRoutes = [
  //   { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/login/two-factor", component: TwoFactorAuthenticator },
  { path: "/login/mfa-token", component: MfaToken },
];

export { UserRoutes, AuthRoutes };
