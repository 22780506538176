import React from "react";
import { IconType } from "react-icons";
import { BiHomeCircle } from "react-icons/bi";
import { NavLink } from "react-router-dom";

interface ContentItem {
  title: string;
  link: string;
  icon: JSX.Element;
}

interface Props extends ContentItem {
  sublist: ContentItem[];
}

const SidebarContentItem = ({ title, link, icon, sublist }: Props) => {
  return (
    <li>
      <NavLink to={link} className="nav--link">
        <span>{icon}</span>
        {title}
      </NavLink>
      {sublist ? (
        <ul aria-expanded="false">
          {sublist.map((item) => (
            <li>
              <NavLink to={item.link} className="nav--link">
                <span>{item.icon}</span>
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </li>
  );
};

export default SidebarContentItem;
