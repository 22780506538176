import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import {
  BiHomeCircle,
  BiCalendar,
  BiBarChart,
  BiPulse,
  BiTask,
  BiBomb,
  BiCollection,
  BiHome,
  BiRectangle,
} from "react-icons/bi";

import MetisMenu from "@metismenu/react";

import SidebarContentItem from "./SidebarContentItem";
import SidebarContentHeader from "./SidebarContentHeader";

const Content = [
  { title: "Menu" },
  { title: "Homepage", link: "/homepage", icon: <BiHomeCircle /> },
  { title: "Apps" },
  { title: "Calendar", link: "/calendar", icon: <BiCalendar /> },

  {
    title: "Reports",
    link: "/#",
    icon: <BiBarChart />,
    sublist: [
      {
        title: "Overview",
        link: "/reports/overview",
        icon: <FaIcons.FaChevronRight />,
      },
      {
        title: "Tuenti Argentina",
        link: "/reports/tuenti-argentina",
        icon: <FaIcons.FaChevronRight />,
      },
      {
        title: "Tuenti Ecuador",
        link: "/reports/tuenti-ecuador",
        icon: <FaIcons.FaChevronRight />,
      },
    ],
  },

  { title: "Daily Status", link: "/daily-status", icon: <BiPulse /> },
  { title: "Kanban", link: "/kanban", icon: <BiTask /> },

  { title: "Clients" },
  {
    title: "Tuenti AR",
    link: "/tuenti-argentina",
    icon: <FaIcons.FaChevronRight />,
  },
  {
    title: "Tuenti EC",
    link: "/tuenti-ecuador",
    icon: <FaIcons.FaChevronRight />,
  },
  {
    title: "48 Ireland",
    link: "/48-ireland",
    icon: <FaIcons.FaChevronRight />,
  },
  { title: "Company" },
  {
    title: "Team",
    link: "/team",
    icon: <IoIcons.IoMdPeople />,
  },
  {
    title: "Surprise",
    link: "/surprise",
    icon: <BiBomb />,
  },
  { title: "UI Features" },
  {
    title: "Typography",
    link: "/typography",
    icon: <FaIcons.FaFont />,
  },
  {
    title: "Tables",
    link: "/basic-tables",
    icon: <FaIcons.FaTable />,
  },
  {
    title: "Colors",
    link: "/colors",
    icon: <FaIcons.FaCircle />,
  },
  {
    title: "Buttons",
    link: "/buttons",
    icon: <FaIcons.FaSquare />,
  },
];

export const SidebarContent = () => {
  // useEffect(() => {
  //   const pathName = window.location.pathname;
  // }, [window.location.pathname]);

  console.log("sdfsd", Content);

  return (
    <section className="sidebar--nav pt-2">
      <MetisMenu>
        {Content.map((item) => {
          return item.link ? (
            <SidebarContentItem
              title={item.title}
              link={item.link}
              icon={item.icon}
              sublist={item.sublist ? item.sublist : null}
            />
          ) : (
            <SidebarContentHeader title={item.title} />
          );
        })}
      </MetisMenu>
    </section>
  );
};

export default SidebarContent;
