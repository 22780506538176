import React from "react";
import { Line, Scatter } from "react-chartjs-2";
import { sample_data } from "./tuenti_argentina_data";
import { ecuador_data } from "./ecuador_data";

const Reports = () => {
  const data = {
    datasets: [
      {
        label: "Tuenti Argentina",
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
        data: sample_data.payload,
        spanGaps: true,
        showLine: false,
        pointRadius: 1.5,
      },

      {
        label: "Tuenti Ecuador",
        fill: false,
        backgroundColor: "rgb(110, 60, 132)",
        borderColor: "rgba(110, 99, 132, 0.2)",
        data: ecuador_data.payload,
        spanGaps: true,
        showLine: false,
        pointRadius: 1.5,
      },
    ],
  };

  const options_accounts = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "accounts",
    },
    animation: false,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };

  const options_smses = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "smses",
    },
    animation: false,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };

  const options_callsin = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "callsIn",
    },
    animation: false,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };

  const options_callsout = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "callsOut",
    },
    animation: false,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };

  const options_callsin_duration = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "callsInDuration",
    },
    animation: false,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };

  const options_callsout_duration = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "callsOutDuration",
    },
    animation: false,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };
  const options_datasent = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "dataSent",
    },
    animation: false,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };

  const options_datareceived = {
    parsing: {
      xAxisKey: "date",
      yAxisKey: "dataReceived",
    },
    animation: false,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "probability",
          },
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
  };

  return (
    <section className="reports">
      <div className="row p-3">
        <div className="col-6">
          <h2>Accounts</h2>
          <Line data={data} options={options_accounts} />
        </div>
        <div className="col-6">
          <h2>Smses</h2>

          <Line data={data} options={options_smses} />
        </div>
      </div>

      <div className="row p-3">
        <div className="col-6">
          <h2>Calls In</h2>

          <Line data={data} options={options_callsin} />
        </div>
        <div className="col-6">
          <h2>Calls Out</h2>

          <Line data={data} options={options_callsout} />
        </div>
      </div>

      <div className="row p-3">
        <div className="col-6">
          <h2>Calls In Duration</h2>

          <Line data={data} options={options_callsin_duration} />
        </div>
        <div className="col-6">
          <h2>Calls Out Duration</h2>

          <Line data={data} options={options_callsout_duration} />
        </div>
      </div>

      <div className="row p-3">
        <div className="col-6">
          <h2>Data Sent</h2>

          <Line data={data} options={options_datasent} />
        </div>
        <div className="col-6">
          <h2>Data Recieved</h2>

          <Line data={data} options={options_datareceived} />
        </div>
      </div>
    </section>
  );
};

export default Reports;
