import { useState, useEffect } from "react";
import axios from "axios";

import { Table, Row, Col, Card } from "react-bootstrap";

const Team = () => {
  const [team, setTeam] = useState({});

  // Call inital data
  useEffect(() => {
    axios
      .get(`https://ceresapi.datakraken.net/api/v1/users/all`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Team res, ", response);
        setTeam(response.data.payload);
      })

      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <section className="team">
      <Card>
        <Card.Body>
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(team).map((key) => (
                <tr>
                  <td>{team[key].userForename}</td>
                  <td>{team[key].userSurname}</td>
                  <td>{team[key].userEmail}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </section>
  );
};

export default Team;
