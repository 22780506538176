import { SET_LOGGED_IN_USER, CLEAR_USER } from "./actionTypes";

const initialState = {
  email: "[EMAIL]",
  firstname: "[FIRSTNAME]",
  lastname: "[LASTNAME]",
  profileImage:
    "https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png",
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_IN_USER:
      console.log("action: ", action);
      state = {
        ...state,
        email: action.payload.user.userEmail,
        firstname: action.payload.user.userForename,
        lastname: action.payload.user.userSurname,
        profileImage: action.payload.user.google_photo_url,
      };
      break;
    case CLEAR_USER:
      state = { ...initialState };
      break;
    default:
      state = state;
      break;
  }
  return state;
};

export default profile;
