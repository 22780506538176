import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const EventView = ({ selectedEvent, show, setShow }) => {
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} className="modal--calendar">
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <h2>
            <b>{selectedEvent.title}</b>
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>
          <b>Start Date: </b>
        </h6>
        <p>
          {selectedEvent.start &&
            String(
              selectedEvent.start.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            )}{" "}
          {selectedEvent.start &&
            String(
              selectedEvent.start.toLocaleTimeString("en-GB", {
                timeStyle: "short",
                hourCycle: "h12",
              })
            )}
        </p>

        <h6>
          <b>End Date: </b>
        </h6>
        <p>
          {selectedEvent.end &&
            String(
              selectedEvent.end.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            )}{" "}
          {selectedEvent.end &&
            String(
              selectedEvent.end.toLocaleTimeString("en-GB", {
                timeStyle: "short",
                hourCycle: "h12",
              })
            )}
        </p>
        <h6>
          <b>Event Type: </b>
        </h6>
        <p>
          {String(
            selectedEvent.extendedProps && selectedEvent.extendedProps.eventType
          )}
        </p>
        <h6>
          <b>Description: </b>
        </h6>
        <p>
          <div
            dangerouslySetInnerHTML={{
              __html:
                selectedEvent.extendedProps &&
                selectedEvent.extendedProps.eventDescription,
            }}
          ></div>
        </p>
        <h6>
          <b>Attendees:</b>
        </h6>
        <p>
          <ul>
            {selectedEvent.extendedProps &&
              selectedEvent.extendedProps.eventAttendees &&
              selectedEvent.extendedProps.eventAttendees.map((item) => (
                <li>{item}</li>
              ))}
          </ul>
        </p>

        {/* <h6>
            <b>Full Event Details Response:</b>
          </h6>
          <small>{JSON.stringify(selectedEvent)}</small> */}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn--red btn-block" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventView;
