import {
  SIDEBAR_LINK_CHANGE,
  ROUTE_CHANGE,
  SIDEBAR_COLLAPSE_TOGGLE,
} from "./actionTypes";

export const sidebarLinkChange = (user, history) => {
  return {
    type: SIDEBAR_LINK_CHANGE,
    payload: { user, history },
  };
};

export const sidebarCollapseToggle = (user, history) => {
  return {
    type: SIDEBAR_COLLAPSE_TOGGLE,
    payload: { user, history },
  };
};
