import React, { useState, useEffect } from "react";
import SideBar from "../components/sidebar/Sidebar";
import TopBar from "../components/Topbar";

import { UserRoutes } from "../routes/AllRoutes";
import { Route, Redirect } from "react-router-dom";

import Container from "react-bootstrap/Container";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const MainLayout = () => {
  const isLoggedIn = useSelector((state) => state.Login.isLoggedIn);
  const expiryTime = useSelector((state) => state.Login.expiry);

  const currentPath = window.location.pathname.split("/").at(-1);
  const collapsed = useSelector((state) => state.Sidebar.collapsed);

  const dispatchEvent = useDispatch();

  useEffect(() => {
    console.log("current path ", currentPath);
    dispatchEvent({ type: "SIDEBAR_LINK_CHANGE", payload: currentPath });
  }, [window.location.pathname]);

  // Auth Wall
  if (!isLoggedIn) return <Redirect to="/login" />;
  if (currentPath == "") return <Redirect to="/homepage" />;

  return (
    <section className="main">
      <SideBar />
      <TopBar />

      <div className={`main--content ${collapsed ? "collapsed" : ""}`}>
        <Container fluid>
          <div className="d-flex justify-content-between d-inline py-4">
            <h5 className="px-3">
              {currentPath.replace("-", " ").toUpperCase()}
            </h5>
            <h6 className="px-3">
              Ceres /{" "}
              {currentPath.charAt(0).toUpperCase() +
                currentPath.slice(1).replace("-", " ")}
            </h6>
          </div>

          {UserRoutes.map((route, idx) => (
            <Route
              path={route.path}
              exact
              component={route.component}
              key={idx}
            />
          ))}

          <h6 className="py-5 ml-auto">2022 © Data Kraken.</h6>
        </Container>
      </div>
    </section>
  );
};

export default MainLayout;
