import React from "react";
import { Card } from "react-bootstrap";

interface Props {
  figureName: string;
  figureValue: number;
}

const ClientFigureTab = ({ figureName, figureValue }: Props) => {
  return (
    <Card>
      <Card.Body>
        <div className="d-flex align-items-center">
          <h5 className="mb-0">{figureName}</h5>
        </div>
        <div className="text-muted mt-4">
          <h4>
            {String(figureValue)}
            <i className="mdi mdi-chevron-up ms-1 text-success" />
          </h4>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ClientFigureTab;
