import { combineReducers } from "redux";

// Authentication
import Login from "./authentication/login/reducer";
import Profile from "./authentication/profile/reducer";

import Sidebar from "./sidebar/reducer";
import Reports from "./reports/reducer";

const rootReducer = combineReducers({
  Login,
  Profile,
  Sidebar,
  Reports,
});

export default rootReducer;
